import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import ArticlesTableWrapper from './table/ArticlesTableWrapper';
import { getArticleCount } from '../redux/actions';
import { useDispatch } from 'react-redux';

export function ArticlesCard() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getArticleCount({ status: true }));
    getArticleCount({ status: true });
  }, []);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Articles</h3>
        </Col>
      </Row>
      <Row>
        <ArticlesTableWrapper />
      </Row>
    </Container>
  );
}
