import { sendRequest } from '@/utils/api/base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const CP_ENDPOINT = process.env.REACT_APP_ROOT_URL + process.env.REACT_APP_CONTROL_PANEL_ENDPOINT;

const baselineThresholdEndpoints = {
  GET_ORGANIZATION_LIST: `${CP_ENDPOINT}/getOrganizationListWithBaselineThreshold`,
  SET_BASELINE_THRESHOLD: `${CP_ENDPOINT}/setBaselineThresholdForOrganizationList`,
};

const getOrganizationsWithBaselineThreshold = (filter) => {
  return sendRequest({
    fullUrl: baselineThresholdEndpoints.GET_ORGANIZATION_LIST,
    method: httpMethod.GET,
    params: filter,
  });
};

const setOrganizationsBaselineThreshold = (orgIds, baselineThreshold) => {
  return sendRequest({
    fullUrl: baselineThresholdEndpoints.SET_BASELINE_THRESHOLD,
    method: httpMethod.POST,
    data: {
      orgIds,
      baselineThreshold,
    },
  });
};

export default {
  getOrganizationsWithBaselineThreshold,
  setOrganizationsBaselineThreshold,
};
