import * as Constants from '@/shared/helpers/constants';
import { handleActions } from 'redux-actions';
import { setAssignedActiveTab, toggleCheckedItem, uncheckAllItem } from './actions';

const initialState = {
  checkedItemIds: [],
  activeTab: Constants.USERS,
};

export default handleActions(
  {
    [toggleCheckedItem](state, { payload: itemId }) {
      const checkedIds = state.checkedItemIds;
      return {
        ...state,
        checkedItemIds: checkedIds.includes(itemId)
          ? checkedIds.filter((id) => id !== itemId)
          : [...checkedIds, itemId],
      };
    },
    [uncheckAllItem](state) {
      return {
        ...state,
        checkedItemIds: [],
      };
    },
    [setAssignedActiveTab](state, { payload: activeTab }) {
      return {
        ...state,
        activeTab,
      };
    },
  },
  initialState,
);
