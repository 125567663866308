import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import SponsorshipTableWrapper from './table/SponsorshipTableWrapper';

export function SponsorshipCard() {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Sponsorship</h3>
        </Col>
      </Row>
      <Row>
        <SponsorshipTableWrapper />
      </Row>
    </Container>
  );
}
