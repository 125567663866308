import React, { useMemo } from 'react';
import { Route, Switch } from 'react-router';
import { Col, Container, Row } from 'reactstrap';
import { jobsBaseUrl } from '..';
import JobDetailsPage from './job-view-dialog/job-details-page/JobDetailsPage';
import { useJobUIContext } from './JobUIProvider';
import JobTableWrapper from './table/JobTableWrapper';

export function JobCard() {
  const jobUIContext = useJobUIContext();
  const jobUIProps = useMemo(() => {
    return {
      openNewJobDialog: jobUIContext.openNewJobDialog,
    };
  }, [jobUIContext]);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Job List</h3>
          <h3 className="page-subhead subhead">
            Here you can manage all the jobs under cadsAI opportunities.
          </h3>
        </Col>
      </Row>
      <Row>
        <Switch>
          <Route path={`${jobsBaseUrl}/:id/view`}>
            {({ history, match }) => (
              <JobDetailsPage
                show={match != null}
                id={match?.params?.id}
                onHide={() => {
                  history.push(jobsBaseUrl);
                }}
              />
            )}
          </Route>
          <Route path="">
            <JobTableWrapper />
          </Route>
        </Switch>
      </Row>
    </Container>
  );
}
