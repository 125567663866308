import { sendRequest } from '../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const tpBaseUrl = process.env.REACT_APP_CADS_AI_API + '/trainingProvider';

export const trainingProviderEndpoints = {
  GET_ALL_TRAINING_PROVIDERS: `${tpBaseUrl}/getAllTrainingProviders`,
  GET_TRAINING_PROVIDER_BY_ID: `${tpBaseUrl}/getTrainingProviderById`,
  ADD_TRAINING_PROVIDER: `${tpBaseUrl}/insertTrainingProvider`,
  EDIT_TRAINING_PROVIDER: `${tpBaseUrl}/editTrainingProvider`,
  DELETE_TRAINING_PROVIDER: `${tpBaseUrl}/deleteTrainingProvider`,
};

const getTrainingProviderList = (filter) => {
  return sendRequest({
    fullUrl: trainingProviderEndpoints.GET_ALL_TRAINING_PROVIDERS,
    method: httpMethod.GET,
    params: filter,
  });
};

const getTrainingProviderById = (id) => {
  return sendRequest({
    fullUrl: trainingProviderEndpoints.GET_TRAINING_PROVIDER_BY_ID,
    method: httpMethod.GET,
    params: { id: id },
  });
};

const deleteTrainingProvider = (id) => {
  return sendRequest({
    fullUrl: trainingProviderEndpoints.DELETE_TRAINING_PROVIDER,
    method: httpMethod.DELETE,
    params: { id: id },
  });
};

const updateTrainingProvider = (data) => {
  // return updateMockTrainingProvider();
  return sendRequest({
    fullUrl: trainingProviderEndpoints.EDIT_TRAINING_PROVIDER,
    method: httpMethod.PUT,
    data: data,
  });
};

const addTrainingProvider = (data) => {
  // return addMockTrainingProvider();
  return sendRequest({
    fullUrl: trainingProviderEndpoints.ADD_TRAINING_PROVIDER,
    method: httpMethod.POST,
    data: data,
  });
};

const updateMockTrainingProvider = (data) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          status: 200,
          messages: ['Training Provider updated successfully'],
        },
      });
    }, 500);
  });
};

const addMockTrainingProvider = (data) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          status: 200,
          messages: ['Training Provider added successfully'],
        },
      });
    }, 500);
  });
};

export default {
  getTrainingProviderList,
  getTrainingProviderById,
  deleteTrainingProvider,
  addTrainingProvider,
  updateTrainingProvider,
};
