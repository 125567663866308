import { useEffect } from 'react';
import Highcharts from 'highcharts';
import Tree from 'highcharts/modules/treemap';
import exporting from 'highcharts/modules/exporting';
import data from 'highcharts/modules/data';
import Heatmap from 'highcharts/modules/heatmap';

Tree(Highcharts);
data(Highcharts);
Heatmap(Highcharts);
exporting(Highcharts);

const AspirationParticipation = () => {
  const initialChart = () => {
    Highcharts.chart('AspirationParticipation', {
      chart: {
        type: 'column',
      },

      title: {
        text: 'Aspiration for MOOC Participation',
      },

      xAxis: {
        type: 'category',
        labels: {
          rotation: -45,
          style: {
            fontSize: '13px',
            fontFamily: 'Verdana, sans-serif',
          },
        },
      },

      yAxis: {
        allowDecimals: true,
        min: 0,
        stackLabels: {
          enabled: true,
        },
        labels: {
          formatter: function () {
            return this.value + '%';
          },
        },
        title: {
          text: null,
        },
      },
      credits: {
        enabled: false,
      },

      legend: {
        enabled: false,
      },

      tooltip: { enabled: false },

      colors: ['#4f81bd'],

      plotOptions: {
        column: {
          stacking: 'normal',
        },
        series: {
          enableMouseTracking: false,
        },
      },

      series: [
        {
          name: '',
          data: [
            ['Learn new things', 95],
            ['Try online education', 41],
            ['To get a certificate', 33],
            ['Improve my career', 32],
            ['See what Moocs are', 20],
            ['Meet new people', 12],
            ['Browse Edinburgh', 7],
            ['Unsure', 4],
          ],
        },
      ],
    });
  };

  useEffect(() => {
    initialChart();
  }, []);

  return <div id="AspirationParticipation" />;
};

export default AspirationParticipation;
