export const renderEmployeeDetail = (data) => {
    return [
    {
        label: 'Photo',
        name: <img src={data?.photoUrl} alt="Employee-img" />
    },
    {
        label: 'First Name',
        name: data?.firstName
    },
        {
            label: 'Last Name',
            name: data?.lastName
        },
        {
            label: 'Email',
            name: data?.email
        },
        {
            label: 'Organization Level',
            name: data?.organizationLevel
        },
        {
            label: 'Mobile',
            name: data?.mobile
        },
        {
            label: 'Phone',
            name: data?.phone
        },
        {
            label: 'Facebook',
            name: <a href={data?.facebook}>Facebook Link</a>
        },
        {
            label: 'Instagram',
            name: <a href={data?.instagram}>Instagram Link</a>
        },
        {
            label: 'LinkedIn',
            name: <a href={data?.linkedin}>LinkedIn Link</a>
        },
        {
            label: 'Youtube',
            name: <a href={data?.youtube}>Youtube Link</a>
        },
    ]
}
