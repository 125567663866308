import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import OrganizationEditForm from './OrganizationEditForm';
import OrganizationEditDialogHeader from './OrganizationEditDialogHeader';

const OrganizationEditDialog = ({ show, onHide, id, isEdit, rtl }) => {
  const [modal, setModal] = useState({
    isOpen: show,
    title: `${isEdit ? 'Edit' : 'Add'} Organization`,
    hide: onHide,
  });

  return (
    <Modal
      isOpen={show}
      toggle={modal.hide}
      modalClassName={`${rtl.direction}-support`}
      className="modal-dialog--primary modal-dialog--header modal-edit-dialog"
    >
      <OrganizationEditDialogHeader modal={modal} />
      <OrganizationEditForm modal={modal} isEdit={isEdit} id={id} />
    </Modal>
  );
};

export default connect((state) => ({
  rtl: state.rtl,
}))(OrganizationEditDialog);
