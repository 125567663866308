import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const fetchSkillList = createAction('FETCH_SKILL_LIST');
export const setSkillList = createAction('SET_SKILL_LIST');
export const updateSkillInList = createAction('UPDATE_SKILL_IN_LIST');
export const setSkillError = createAction('SET_SKILL_ERROR');
export const setSkillFilters = createAction('SET_SKILL_FILTERS');
export const deleteSkillFromList = createAction('DELETE_SKILL_FROM_LIST');

export const getSkillsList = (filter) => async (dispatch) => {
  const defaultError = 'Error occurred getting skills list';
  try {
    dispatch(fetchSkillList());
    const { data, isSuccess } = await api.skills.getSkillList(filter);
    if (isSuccess) dispatch(setSkillList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setSkillError(error));
    console.error(defaultError, error.message);
  }
};

export const updateSkillInSkillList = (skill) => (dispatch) => {
  try {
    dispatch(updateSkillInList(skill.data));
  } catch (error) {
    console.error('Unable to update the skill in skill list');
  }
};

export const deleteSkill = (skillId) => (dispatch) => {
  try {
    dispatch(deleteSkillFromList(skillId));
  } catch (error) {
    console.error('Unable to update the skill in skill list');
  }
};
