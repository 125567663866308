import React from 'react';
import {
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import UserAssignedModuleTable from './UserAssignedModuleTable';
import OrganizationAssignedModuleTable from '@/containers/Control-Panel/Assigned/components/module/OrganizationAssignedModuleTable';
import { useLocation } from 'react-router-dom';
import { ORGANIZATION, USERS, assignedModuleORLPTabs } from '@/shared/helpers/constants';

const AssignedModuleTabs = () => {
  const [activeTab, setActiveTab] = React.useState(USERS);
  const location = useLocation();

  const toggle = (tab) => {
    setActiveTab(tab);
  };

  React.useEffect(() => {
    const activeTabParam = new URLSearchParams(location.search)?.get('activeTab');
    if (
      activeTabParam &&
      activeTabParam !== activeTab &&
      [USERS, ORGANIZATION].includes(activeTab)
    ) {
      toggle(activeTabParam);
    }
  }, [location.search]);

  return (
    <Col className="card-body">
      <div className="tabs tabs--bordered-bottom">
        <div className="tabs__wrap">
          <Nav tabs>
            {Object.keys(assignedModuleORLPTabs).map((key) => (
              <NavItem key={assignedModuleORLPTabs[key].id}>
                <NavLink
                  className={classnames({ active: activeTab === assignedModuleORLPTabs[key].id })}
                  onClick={() => toggle(assignedModuleORLPTabs[key].id)}
                >
                  {assignedModuleORLPTabs[key].label}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={activeTab}>
              <Container>
                <Row>
                  <CardBody>
                    {activeTab === USERS && <UserAssignedModuleTable />}
                    {activeTab === ORGANIZATION && <OrganizationAssignedModuleTable />}
                  </CardBody>
                </Row>
              </Container>
            </TabPane>
          </TabContent>
        </div>
      </div>
    </Col>
  );
};

export default AssignedModuleTabs;
