import React from 'react';
import api from '@/utils/apiClient';
import JobTabs from './job-tabs';
import { ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { useJobUIContext } from '../../JobUIProvider';

const JobDetailsPage = ({ id }) => {
  const jobEvent = useJobUIContext();
  const [jobStage, setJobStage] = React.useState([]);
  const [state, setState] = React.useState({
    loading: false,
    job: {},
    error: '',
  });

  React.useEffect(() => {
    if (id) {
      getJobById(+id);
      getJobStageOptions();
    }
  }, [id]);

  const getJobById = async (jobId) => {
    try {
      setState({ ...state, loading: true });
      const { data: res, isSuccess } = await api.cadsAIJob.getJobById(jobId);
      if (isSuccess) {
        setState({ ...state, loading: false, job: res.data, error: '' });
      } else {
        throw new Error(res?.messages?.[0] ?? 'Error while getting job details');
      }
    } catch ({ message }) {
      setState({ ...state, loading: false, job: {}, error: message });
      console.error(message);
    }
  };

  const getJobStageOptions = async () => {
    const { data: res, isSuccess } = await api.cadsAIJob.getJobStageOptions();
    if (isSuccess) {
      setJobStage(res?.data?.map((stage) => ({ value: stage.id, label: stage.title })));
    }
  };

  const { loading, job } = state;
  return (
    <Col md={12} lg={12} className="applicant-details-page">
      {loading && (
        <div className={'text-center overflow-hidden'}>
          <div className="panel__refresh position-relative" style={{ height: 75 }}>
            <LoadingIcon />
          </div>
        </div>
      )}
      <Card>
        <CardBody className="products-list">
          <div>
            <div>
              <ButtonToolbar className="button-placement">
                <ArrowLeftIcon color="primary" outline onClick={() => jobEvent.openViewAllJobs()}>
                  Back To Job List
                </ArrowLeftIcon>
              </ButtonToolbar>
              <div className="dashboard__info-title">
                <h2 className="page-title mb-2">{job?.title} </h2>
                <span className="bread-crumb-title">{`${job?.organizationName}`}</span>
              </div>
            </div>
            <div className="mb-2">
              <div className="card__supporting-text" style={{ overflowX: 'auto' }}>
                <div className="stepper-horizontal-alternative">
                  {jobStage?.map((stage, idx) => (
                    <div
                      className={`stepper-step ${
                        idx <= jobStage.findIndex((stage) => stage.value === job?.finalStage?.id)
                          ? 'active-step'
                          : ''
                      }`}
                    >
                      <div className="stepper-title">{stage?.label}</div>
                      <div className="stepper-circle">
                        <span>{idx + 1}</span>
                      </div>
                      <div className="stepper-bar-left" />
                      <div className="stepper-bar-right" />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <JobTabs jobDetails={job} />
        </CardBody>
      </Card>
    </Col>
  );
};

export default JobDetailsPage;
