import React from 'react';
import { useDispatch } from 'react-redux';
import { paths } from '@/shared/helpers/paths';
import { LearningPathCard } from './LearningPathCard';
import { LearningPathUIProvider } from './LearningPathUIProvider';
import LearningPathEditDialog from './learning-path-add-edit-dialog/LearningPathEditDialog';
import LearningPathViewDialog from './learning-path-view-dialog/LearningPathViewDialog';
import { Route, Switch } from 'react-router-dom';
import { setCadsAiLearningPathFilters, uncheckAllSelectedLearningPath } from '../redux/actions';
import { LearningPathUpdateStatusDialog } from './learning-path-update-status-dialog/LearningPathUpdateStatusDialog';
import { useEffect } from 'react';

const learningPathBaseUrl = paths.CADS_AI.LEARNING.LEARNING_PATH;

export function LearningPathPage({ history, match, isForAssignment = false }) {
  const cadsAilearningPathUIEvents = {
    openViewLearningPathDialog: (id) => {
      history.push(`${learningPathBaseUrl}/${id}/view`);
    },
    openNewLearningPathDialog: () => {
      history.push(`${learningPathBaseUrl}/new`);
    },
    openEditLearningPathDialog: (id) => {
      history.push(`${learningPathBaseUrl}/${id}/edit`);
    },
    openDeleteLearningPathDialog: (id) => {
      history.push(`${learningPathBaseUrl}/${id}/delete`);
    },
    openUpdateStatusDialog: () => {
      history.push(`${learningPathBaseUrl}/update-status`);
    },
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(uncheckAllSelectedLearningPath());
  }, []);

  return (
    <LearningPathUIProvider
      isForAssignment={isForAssignment}
      cadsAilearningPathUIEvents={cadsAilearningPathUIEvents}
    >
      <Switch>
        <Route path={`${learningPathBaseUrl}/new`}>
          {({ history, match }) => (
            <LearningPathEditDialog
              show={match != null}
              isEdit={false}
              onHide={() => {
                dispatch(setCadsAiLearningPathFilters());
                history.push(learningPathBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${learningPathBaseUrl}/update-status`}>
          {({ history, match }) => (
            <LearningPathUpdateStatusDialog
              show={match != null}
              isEdit={false}
              onHide={() => {
                dispatch(setCadsAiLearningPathFilters());
                history.push(learningPathBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${learningPathBaseUrl}/:id/edit`}>
          {({ history, match }) => (
            <LearningPathEditDialog
              show={match != null}
              isEdit={true}
              id={match?.params?.id}
              onHide={() => {
                history.push(learningPathBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${learningPathBaseUrl}/:id/view`}>
          {({ history, match }) => (
            <LearningPathViewDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                history.push(learningPathBaseUrl);
              }}
            />
          )}
        </Route>
      </Switch>
      <LearningPathCard />
    </LearningPathUIProvider>
  );
}
