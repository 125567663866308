import React from 'react';
import { useDispatch } from 'react-redux';
import { paths } from '@/shared/helpers/paths';
import { Route, Switch, useHistory } from 'react-router-dom';
import { DomainCard } from './DomainCard';
import { setDomainFilters } from '../redux/actions';
import { DomainUIProvider } from './DomainUIProvider';
import DomainEditDialog from './domain-add-edit-dialog/DomainEditDialog';
import DomainDeleteDialog from './domain-delete-dialog/DomainDeleteDialog';
import DomainViewDialog from './domain-view-dialog/DomainViewDialog';
import DomainManageSubcategoryDialog from './domain-manageSubcategories-dialog/DomainManageSubcategoryDialog';

const domainBaseUrl = paths.SKILL_BANK.DOMAINS;

export function DomainPage() {
  const history = useHistory();

  const domainUIEvents = {
    openManageSubcategoriesDialog: (id) => {
      history.push(`${domainBaseUrl}/${id}/manageSubcategories`);
    },
    openViewDomainDialog: (id) => {
      history.push(`${domainBaseUrl}/${id}/view`);
    },
    openNewDomainDialog: () => {
      history.push(`${domainBaseUrl}/new`);
    },
    openEditDomainDialog: (id) => {
      history.push(`${domainBaseUrl}/${id}/edit`);
    },
    openDeleteDomainDialog: (id) => {
      history.push(`${domainBaseUrl}/${id}/delete`);
    },
  };

  const dispatch = useDispatch();

  return (
    <DomainUIProvider domainUIEvents={domainUIEvents}>
      <Switch>
        <Route path={`${domainBaseUrl}/new`}>
          {({ history, match }) => (
            <DomainEditDialog
              show={match != null}
              isEdit={false}
              onHide={() => {
                dispatch(setDomainFilters());
                history.push(domainBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${domainBaseUrl}/:id/edit`}>
          {({ history, match }) => (
            <DomainEditDialog
              show={match != null}
              isEdit={true}
              id={match?.params?.id}
              onHide={() => {
                history.push(domainBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${domainBaseUrl}/:id/delete`}>
          {({ history, match }) => (
            <DomainDeleteDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                history.push(domainBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${domainBaseUrl}/:id/view`}>
          {({ history, match }) => (
            <DomainViewDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                history.push(domainBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${domainBaseUrl}/:id/manageSubcategories`}>
          {({ history, match }) => (
            <DomainManageSubcategoryDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                history.push(domainBaseUrl);
              }}
            />
          )}
        </Route>
      </Switch>
      <DomainCard />
    </DomainUIProvider>
  );
}
