import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { cadsAiSpamRequestsBaseUrl } from '../index';
import { SpamRequestsUIProvider } from './SpamRequestsUIProvider';
import { SpamRequestsCard } from './SpamRequestsCard';
import { setSpamRequestFilters, uncheckAllSpamRequest } from '../redux/actions';
import { useDispatch } from 'react-redux';
import SpamRequestUpdateDialog from './spam-update-status-dialog/SpamUpdateStatusDialog';
import SpamRequestViewDialog from './spam-view-dialog/SpamRequestViewDialog';

export function SpamRequestsPage({ history }) {
  const dispatch = useDispatch();

  //clean up
  const spamRequestUIEvents = {
    openViewSpamDialog: (id) => {
      history.push(`${cadsAiSpamRequestsBaseUrl}/${id}/view`);
    },
    openUpdateSpamDialog: () => {
      history.push(`${cadsAiSpamRequestsBaseUrl}/update-status`);
    },
  };

  return (
    <SpamRequestsUIProvider spamRequestUIEvents={spamRequestUIEvents}>
      <Switch>
        <Route path={`${cadsAiSpamRequestsBaseUrl}/:id/view`}>
          {({ history, match }) => (
            <SpamRequestViewDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                dispatch(setSpamRequestFilters());
                history.push(cadsAiSpamRequestsBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${cadsAiSpamRequestsBaseUrl}/update-status`}>
          {({ history, match }) => (
            <SpamRequestUpdateDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                dispatch(setSpamRequestFilters());
                history.push(cadsAiSpamRequestsBaseUrl);
              }}
            />
          )}
        </Route>
      </Switch>
      <SpamRequestsCard />
    </SpamRequestsUIProvider>
  );
}
