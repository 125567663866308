import { sendRequest } from '../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const dashboardEndpoints = {
  GET_USER_STATS: '/',
  GET_ORG_STATS: '/',
  GET_SKILL_STATS: '/',
  GET_JOB_STATS: '/',
  GET_ASSESSMENT_STATS: '/',
  GET_PROGRAM_STATS: '/',
};

const getUserStats = (year, month) => {
  return getMockUserStats(year, month);
  return sendRequest({
    url: dashboardEndpoints.GET_USER_STATS,
    method: httpMethod.GET,
  });
};

const getOrgStats = (year, month) => {
  return getMockOrgStats(year, month);
  return sendRequest({
    url: dashboardEndpoints.GET_ORG_STATS,
    method: httpMethod.GET,
  });
};

const getSkillStats = (year, month) => {
  return getMockSkillStats(year, month);
  return sendRequest({
    url: dashboardEndpoints.GET_SKILL_STATS,
    method: httpMethod.GET,
  });
};

const getWorldMap = () => {
  return sendRequest({
    fullUrl: 'https://code.highcharts.com/mapdata/custom/world.topo.json',
    method: httpMethod.GET,
  });
};

const getAssessmentStats = (year, month) => {
  return getMockAssessmentStats(year, month);
  return sendRequest({
    url: dashboardEndpoints.GET_ASSESSMENT_STATS,
    method: httpMethod.GET,
  });
};

const getProgramStats = (year, month) => {
  return getMockProgramStats(year, month);
  return sendRequest({
    url: dashboardEndpoints.GET_PROGRAM_STATS,
    method: httpMethod.GET,
  });
};

const getJobStats = (year, month) => {
  return getMockJobStats(year, month);
  return sendRequest({
    url: dashboardEndpoints.GET_JOB_STATS,
    method: httpMethod.GET,
  });
};

const getMockUserStats = (year, month) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          data: { registered: 9546 },
          status: 200,
          messages: [],
        },
      });
    }, 500);
  });
};

const getMockOrgStats = (year, month) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          data: { organizations: 564 },
          status: 200,
          messages: [],
        },
      });
    }, 500);
  });
};

const getMockSkillStats = (year, month) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          data: { skills: 48 },
          status: 200,
          messages: [],
        },
      });
    }, 500);
  });
};

const getMockAssessmentStats = (value) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          data: { accomplished: 321 },
          status: 200,
          messages: [],
        },
      });
    }, 500);
  });
};

const getMockJobStats = (value) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          data: { posted: 252, applied: 100 },
          status: 200,
          messages: [],
        },
      });
    }, 500);
  });
};

const getMockProgramStats = (value) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          data: { posted: 52, applied: 20 },
          status: 200,
          messages: [],
        },
      });
    }, 500);
  });
};

export default {
  getUserStats,
  getOrgStats,
  getSkillStats,
  getAssessmentStats,
  getJobStats,
  getProgramStats,
  getWorldMap,
};
