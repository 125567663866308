import React from 'react';
import { Route, Switch } from 'react-router';
import { Col, Container, Row } from 'reactstrap';
import { cadsAiQABaseUrl } from '..';
import QAViewDialog from './qa-view-dialog/QAViewDialog';
import QATableWrapper from './table/QATableWrapper';
import PinnedQuestions from "./pinned-questions";

export function QACard() {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Q&A</h3>
        </Col>
      </Row>
      <Row>
        <Switch>
          <Route path={`${cadsAiQABaseUrl}/:id/view`}>
            {({history, match}) => (
                <QAViewDialog
                    show={match != null}
                    id={match?.params?.id}
                    onHide={() => {
                      history.push(cadsAiQABaseUrl);
                    }}
                />
            )}
          </Route>
          <Route path={`${cadsAiQABaseUrl}/pins`}>
            {({history, match}) => (
                <PinnedQuestions
                    show={match != null}
                    id={match?.params?.id}
                    onBack={() => {
                      history.push(cadsAiQABaseUrl);
                    }}
                />
            )}
          </Route>
          <Route path="">
            <QATableWrapper/>
          </Route>
        </Switch>
      </Row>
    </Container>
  );
}
