import { sendRequest } from '../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const ddoBaseUrl =
    process.env.REACT_APP_CADS_AI_API + process.env.REACT_APP_CADS_AI_DDO_ENDPOINT;

export const ddoEndpoints = {
  GET_ALL_DDO: `${ddoBaseUrl}/getAllOrganizationDdoPrograms`,
  GET_DDO_BY_ID: `${ddoBaseUrl}/`, // API is not available
  DELETE_DDO: `${ddoBaseUrl}/deleteDdoProgram`,
  ADD_DDO: `${ddoBaseUrl}/addDdoProgram`,
  UPDATE_DDO: `${ddoBaseUrl}/editDdoProgram`,
};

const getDdoList = (filter) => {
  // return getMockDdoList();
  return sendRequest({
    fullUrl: ddoEndpoints.GET_ALL_DDO,
    method: httpMethod.GET,
    params: filter,
  });
};

const getDdoById = (id) => {
    return getMockDdoById();
    return sendRequest({
      fullUrl: ddoEndpoints.GET_DDO_BY_ID,
      method: httpMethod.GET,
      params: {id},
    });
  };

const deleteDdo = (id) => {
  // return deleteMockDdo();
  return sendRequest({
    fullUrl: ddoEndpoints.DELETE_DDO,
    method: httpMethod.DELETE,
    params: {id},
  });
};

const addDdo = (data) => {
    return addMockDdo();
    return sendRequest({
      fullUrl: ddoEndpoints.ADD_DDO,
      method: httpMethod.POST,
      data: data,
    });
  };

const updateDdo = (data) => {
return updateMockDdo();
return sendRequest({
    fullUrl: ddoEndpoints.UPDATE_DDO,
    method: httpMethod.PUT,
    data: data,
});
};

const getMockDdoList = () => {
    return new Promise((resolve, reject) => {
      setTimeout(
        () =>
          resolve({
            isSuccess: true,
            data: {
              status: 200,
              messages: [],
              data: {
                totalRecords: 3,
                records: [
                  {
                    id: 1,
                    programmeTitle: 'Mock-Data Star for Yayasan Peneraju',
                    organization: { id:4 , title: 'EPS Malaysia' },
                    startDate: '2022-04-29T00:00:00',
                    endDate: '2024-09-09T00:00:00',
                    status: true,
                  },
                  {
                    id: 2,
                    programmeTitle: 'Mock-Data Star for Yayasan Peneraju',
                    organization: { id:4 , title: 'EPS Malaysia' },
                    startDate: '2022-04-29T00:00:00',
                    endDate: '2024-09-09T00:00:00',
                    status: true,
                  },
                  {
                    id: 3,
                    programmeTitle: 'Mock-Data Star for Yayasan Peneraju',
                    organization: { id:4 , title: 'EPS Malaysia' },
                    startDate: '2022-04-29T00:00:00',
                    endDate: '2024-09-09T00:00:00',
                    status: false,
                  },
                ],
              },
            },
          }),
        500,
      );
    });
  };

  const getMockDdoById = () => {
    return new Promise((resolve, reject) => {
      setTimeout(
        () =>
          resolve({
            isdeleteMockDdoSuccess: true,
            data: {
              status: 200,
              messages: [],
              data:  {
                id: 1,
                programmeTitle: 'Mock-Data Star for Yayasan Peneraju',
                organization: { id:4 , title: 'EPS Malaysia' },
                price: 522,
                startDate: '2022-04-29T00:00:00',
                endDate: '2024-09-09T00:00:00',
                participants: 125,
                finalPrice: 4861,
                discount: 20,
                voucherCode: 'Mock Voucher',
                voucherValue: 21,
                status: true,
              },
            },
          }),
        500,
      );
    });
  };

  const deleteMockDdo = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: ['DDO deleted successfully'],
          },
        });
      }, 500);
    });
  };

const addMockDdo = () => {
return new Promise((resolve, reject) => {
    setTimeout(() => {
    resolve({
        isSuccess: true,
        data: {
        status: 200,
        messages: ['DDO added successfully'],
        },
    });
    }, 500);
});
};

const updateMockDdo = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: ['DDO updated successfully'],
          },
        });
      }, 500);
    });
  };

  export default {
      getDdoList,
      getDdoById,
      deleteDdo,
      addDdo,
      updateDdo,
  }
