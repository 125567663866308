import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import AnnouncementsTableWrapper from './table/AnnouncementsTableWrapper';
import { getAnnouncementCount } from '../redux/actions';
import { useDispatch } from 'react-redux';

export function AnnouncementsCard() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAnnouncementCount({ status: true }));
    getAnnouncementCount({ status: true });
  }, []);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Announcements</h3>
        </Col>
      </Row>
      <Row>
        <AnnouncementsTableWrapper />
      </Row>
    </Container>
  );
}
