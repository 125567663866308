import { handleActions } from 'redux-actions';
import {
  fetchAnnouncementList,
  setAnnouncementCount,
  setAnnouncementError,
  setAnnouncementFilters,
  setAnnouncementList,
  toggleCheckedAnnouncement,
  uncheckAllAnnouncement,
} from './actions';

const defaultState = {
  loading: false,
  checkedAnnouncementIds: [],
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    searchText: '',
    isSortOrderDescending: false,
    status: null,
  },
  totalRecords: 0,
  data: [],
  options: [],
  announcementCount: 0,
  error: null,
};

export default handleActions(
  {
    [fetchAnnouncementList](state) {
      return {
        ...state,
        loading: true,
        data: [],
      };
    },
    [setAnnouncementList](state, { payload }) {
      return {
        ...state,
        loading: false,
        data: payload.data.records,
        totalRecords: payload.data.totalRecords,
        error: null,
      };
    },
    [setAnnouncementError](state, { payload }) {
      return {
        ...state,
        data: [],
        loading: false,
        totalPages: defaultState.totalPages,
        error: payload?.message,
      };
    },
    [setAnnouncementFilters](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
        checkedAnnouncementIds: [],
      };
    },
    [toggleCheckedAnnouncement](state, { payload: announcementId }) {
      const checkedIds = state.checkedAnnouncementIds;
      return {
        ...state,
        checkedAnnouncementIds: checkedIds.includes(announcementId)
          ? checkedIds.filter((id) => id !== announcementId)
          : [...checkedIds, announcementId],
      };
    },
    [uncheckAllAnnouncement](state) {
      return {
        ...state,
        checkedAnnouncementIds: [],
      };
    },
    [setAnnouncementCount](state, { payload }) {
      return {
        ...state,
        loading: false,
        announcementCount: payload,
        error: null,
      };
    },
  },
  defaultState,
);
