import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const fetchCadsAIUsersList = createAction('FETCH_CADS_AI_USER_LIST');
export const setCadsAIUsersList = createAction('SET_CADS_AI_USER_LIST');
export const setCadsAIUsersError = createAction('SET_CADS_AI_USER_ERROR');
export const setUsersFilters = createAction('SET_CADS_AI_USER_FILTERS');
export const toggleCheckedUsers = createAction('TOGGLE_CADS_AI_CHECKED_USER');
export const uncheckAllUsers = createAction('UNCHECK_CADS_AI_ALL_USER');
export const setCohortId = createAction('CADS_AI_SET_USER_COHORT_ID');
export const setOrganizationId = createAction('SET_ORGANIZATION_ID_FOR_CADS_AI_USER');

export const toggleCheckedItemById = (userId) => (dispatch) => {
  dispatch(toggleCheckedUsers(userId));
};

export const getCadsAIUsersList = (filter) => async (dispatch) => {
  const defaultError = `Error occurred getting user list`;
  dispatch(fetchCadsAIUsersList());
  try {
    const { data, isSuccess } = await api.cadsAIUsers.getCadsAIUsersList(filter);
    if (isSuccess) dispatch(setCadsAIUsersList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setCadsAIUsersError(error.message));
    console.error(defaultError, error.message);
  }
};

export const setSelectedCohortId = (cohortId) => (dispatch) => {
  dispatch(setCohortId(cohortId));
};

export const setSelectedOrganizationId = (orgId) => (dispatch) => {
  dispatch(setOrganizationId(orgId));
};
