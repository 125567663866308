import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

export const getTrainingProviderColumns = (isForAssignment) => {
  const cols = [
    {
      id: 'imageUrl',
      disablePadding: true,
      label: 'Training Provider Image',
    },
    {
      id: 'fullName',
      disablePadding: true,
      label: 'Name',
    },
  ];
  return isForAssignment
    ? [{ id: 'checkbox', disablePadding: true, label: '' }, ...cols]
    : [...cols, { id: 'Action', disablePadding: true, label: 'Action' }];
};

const TrainingProviderTableColumns = ({ order, orderBy, onRequestSort, isForAssignment }) => (
  <Fragment>
    <TableRow>
      {getTrainingProviderColumns(isForAssignment).map((row) => (
        <TableCell
          className="material-table__cell material-table__cell--sort material-table__cell-center"
          key={row.id}
          align={'center'}
          padding={row.disablePadding ? 'none' : 'default'}
          sortDirection={orderBy === row.id ? order : false}
        >
          <b>{row.label}</b>
        </TableCell>
      ))}
    </TableRow>
  </Fragment>
);

TrainingProviderTableColumns.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default connect((state) => ({
  rtl: state.rtl,
}))(TrainingProviderTableColumns);
