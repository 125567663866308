import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { useForm } from 'react-hook-form';
import FormField from '@/shared/components/form/hook/FormField';
import { StepPropTypes, StepDefaultProps } from './Props';
import { extractFieldData, getStepFields } from '../index';
import TextEditor from '@/shared/components/text-editor/TextEditor';

const StepThree = ({ onSubmit, page, onClear, previousPage, data }) => {
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm(extractFieldData(page, data));

  React.useEffect(() => {
    getStepFields(page).forEach((key) => setValue(key, data[key]));
  }, [data]);

  return (
    <form className="form wizard__form" onSubmit={handleSubmit(onSubmit)}>
      <div className="form__form-group">
        <span className="form__form-group-label" style={{ fontSize: '1rem', fontWeight: 500 }}>
          Introduction Text
        </span>
        <div className="form__form-group-field">
          <FormField
            control={control}
            name="introduction"
            component={() => (
              <TextEditor
                defaultContent={data?.introduction}
                onChange={(value) => setValue('introduction', value)}
              />
            )}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label" style={{ fontSize: '1rem', fontWeight: 500 }}>
          Thank You Page
        </span>
        <div className="form__form-group-field">
          <FormField
            control={control}
            name="thankYouPage"
            component={() => (
              <TextEditor
                defaultContent={data?.thankYouPage}
                onChange={(value) => setValue('thankYouPage', value)}
              />
            )}
          />
        </div>
      </div>
      <ButtonToolbar className="form__button-toolbar wizard__toolbar">
        <Button
          color="primary"
          type="button"
          className="previous"
          onClick={() => previousPage(getValues())}
        >
          Back
        </Button>
        <Button color="secondary" type="button" onClick={onClear} outline>
          Clear
        </Button>
        <Button color="primary" type="submit">
          Submit
        </Button>
      </ButtonToolbar>
    </form>
  );
};

StepThree.propTypes = StepPropTypes;
StepThree.defaultProps = StepDefaultProps;

export default StepThree;
