import React from 'react';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import { Card, CardBody, Col } from 'reactstrap';
import HeartOutlineIcon from 'mdi-react/HeartOutlineIcon';

const StatsCard = ({
  title,
  subTitle,
  stats,
  statsTitle,
  footerText,
  colorFill,
  icon: Icon = HeartOutlineIcon,
}) => {
  return (
    <Col md={12} xl={4} lg={6} sm={12} xs={12}>
      <Card>
        <CardBody className="dashboard__health-chart-card">
          <div className="card__title">
            <h5 className="bold-text card__title-center">{title}</h5>
          </div>
          <div className="dashboard__health-chart">
            <ResponsiveContainer height={180}>
              <PieChart>
                <Pie
                  data={[
                    { value: stats, fill: colorFill ?? '#ff4861' },
                    { value: 100 - stats, fill: '#eeeeee' },
                  ]}
                  dataKey="value"
                  cy={85}
                  innerRadius={80}
                  outerRadius={90}
                />
              </PieChart>
            </ResponsiveContainer>
            <div className="dashboard__health-chart-info">
              <Icon style={{ fill: colorFill }} />
              <p className="dashboard__health-chart-number">{statsTitle}</p>
              <p className="dashboard__health-chart-units">{subTitle}</p>
            </div>
          </div>
          {footerText.map((text) => (
            <p className="dashboard__goal">{text}</p>
          ))}
        </CardBody>
      </Card>
    </Col>
  );
};

export default StatsCard;
