import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const setLearningPathFetching = createAction('SET_LEARNING_PATH_FETCHING');
export const setLearningPathError = createAction('SET_LEARNING_PATH_ERROR');
export const setAssignLearningPathList = createAction('SET_ASSIGN_LEARNING_PATH_LIST');
export const setLearningPathFilters = createAction('SET_LEARNING_PATH_LIST_FILTER');
export const toggleCheckedLearningPath = createAction('TOGGLE_CHECKED_LEARNING_PATH');
export const clearCheckedLearningPath = createAction('CLEAR_SELECTED_LEARNING_PATH');
export const setLearningPathGroupOptions = createAction('SET_LEARNING_PATH_GROUP_OPTIONS');
export const clearLearningPathFilters = createAction('CLEAR_LEARNING_PATH_FILTERS');
export const updateLearningPathInList = createAction('UPDATE_LEARNING_PATH_IN_LIST');

export const getCPLearningPathsList = (filter) => async (dispatch) => {
  const defaultError = `Error occurred getting learning path list`;
  dispatch(setLearningPathFetching());
  try {
    const { data, isSuccess } = await api.learningPaths.getLearningPathList(filter);
    if (isSuccess) dispatch(setAssignLearningPathList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setLearningPathError(error.message));
    console.error(defaultError, error.message);
  }
};

export const getLearningPathGroupOptionList = () => async (dispatch) => {
  const defaultError = 'Error occurred getting learning path group option list';
  try {
    const { data, isSuccess } = await api.learningPaths.getGroupOptionList();
    if (isSuccess) dispatch(setLearningPathGroupOptions(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    console.error(defaultError, error.message);
  }
};
