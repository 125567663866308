import React, { createContext, useContext } from 'react';

const DomainUIContext = createContext();

export function useDomainUIContext() {
  return useContext(DomainUIContext);
}

export function DomainUIProvider({ domainUIEvents, children }) {
  const value = {
    openViewDomainDialog: domainUIEvents.openViewDomainDialog,
    openNewDomainDialog: domainUIEvents.openNewDomainDialog,
    openEditDomainDialog: domainUIEvents.openEditDomainDialog,
    openDeleteDomainDialog: domainUIEvents.openDeleteDomainDialog,
    openManageSubcategoriesDialog: domainUIEvents.openManageSubcategoriesDialog,
  };

  return <DomainUIContext.Provider value={value}>{children}</DomainUIContext.Provider>;
}
