import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import SpamRequestsTable from './SpamRequestsTable';

const SpamRequestsTableWrapper = () => {

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="products-list">
          <div className="card__title">
            <h5 className="bold-text">Spam Requests List</h5>
          </div>
          <SpamRequestsTable />
        </CardBody>
      </Card>
    </Col>
  );
};

export default SpamRequestsTableWrapper;
