import React from 'react';
import AssignedAssessment from './assessments/AssignedAssessment';
import CompletedAssessments from './assessments/CompletedAssessments';

export const AssessmentTab = ({ userDetails, activeTab }) => {
  return (
    <div className="applicant_panel_card">
      {activeTab === 'assessmentTo' && <AssignedAssessment id={userDetails?.id} />}
      {activeTab === 'completed' && <CompletedAssessments userDetails={userDetails} />}
    </div>
  );
};
