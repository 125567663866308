import moment from 'moment';
import React from 'react';
import { ACCESS_TOKEN, REFRESH_TOKEN, USER } from './auth.enum';
import { rolesByPath } from './paths';

const ligthTheme = {
  backgroundColor: 'white',
  color: '#646777',
};

const darkTheme = {
  backgroundColor: '#2e2c34',
  color: '#dddddd',
};

export const themes = {
  ligthTheme,
  darkTheme,
};

export const removeUserData = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  localStorage.removeItem(USER);
};

export const extractUserDataFromToken = (token) => {
  if (!token) return null;
  const payload = token.split('.')[1];
  const jsonPayload = JSON.parse(atob(payload));
  const userDataFromToken = {
    userName: jsonPayload['UserName'],
    userData: JSON.parse(jsonPayload['UserData']),
  };
  localStorage.setItem(USER, JSON.stringify(userDataFromToken));
  return userDataFromToken;
};

export const isAllowedToAccess = (user, path) => {
  const currentUserRoles = user?.userData?.Roles;
  const allowedRoles = rolesByPath[path];
  return !!allowedRoles?.filter((allowedRole) => !!currentUserRoles?.includes(allowedRole)).length;
};

export const defaultPaginationProps = {
  backIconButton: { 'aria-label': 'Previous Page' },
  nextIconButton: { 'aria-label': 'Next Page' },
  rowsPerPage: [5, 7, 10, 15, 20],
  component: 'div',
  className: 'material-table__pagination',
  selectProps: {
    inputProps: { 'aria-label': 'rows per page' },
    native: true,
  },
  dir: 'ltr',
};

export const getModalIcon = (color) => {
  switch (color) {
    case 'primary':
      return <span className="lnr lnr-pushpin modal__title-icon" />;
    case 'success':
      return <span className="lnr lnr-thumbs-up modal__title-icon" />;
    case 'warning':
      return <span className="lnr lnr-flag modal__title-icon" />;
    case 'danger':
      return <span className="lnr lnr-cross-circle modal__title-icon" />;
    default:
      return <></>;
  }
};

export const getColorForWeight = (options = { weight: 0, cssClass: false, random: false }) => {
  const { weight, cssClass: colorClass, random } = options;
  switch (random ? +Math.random().toFixed(1) + 0.1 : weight) {
    case 0.1:
    case 0.2:
      return colorClass ? 'secondary' : '#6c757d';
    case 0.3:
    case 0.4:
      return colorClass ? 'primary' : '#007bff';
    case 0.5:
    case 0.6:
      return colorClass ? 'success' : '#28a745';
    case 0.7:
    case 0.8:
      return colorClass ? 'warning' : '#ffc107';
    case 0.9:
    case 1:
      return colorClass ? 'danger' : '#dc3545';
    default:
      return colorClass ? 'success' : '#28a745';
  }
};

export const emailPattern = {
  value: /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/,
  message: 'Please enter valid email'
}
export const urlPattern = {
  value: /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/,
  message: "Link is not well formatted"
}
export const urlPatternWithProtocol = {
  value: /((http(s?)):\/\/)(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/,
  message: "Please enter link in expected format. e.g https://www.google.com"
}

export const mobileNumberPattern = {
  value: /(\+)(\d){1,3}-(\d){8,10}$/gm,
  message: "Please enter number in expected format. e.g +91-1234567890"
}

export const phoneNumberPattern = {
  value: /(\+)(\d){1,3}-(\d){10}$/gm,
  message: "Please enter number in expected format. e.g +91-1234567890"
}

export const emptyStringPattern = {value: /^(?!\s*$).+/, message: 'This field can not be empty'};

export const toPercent = (decimal, fixed = 0) => `${decimal.toFixed(fixed)}%`;

const getTooltipStyles = (themeName, type) => {
  switch (themeName) {
    case 'theme-dark': {
      const {backgroundColor, color} = darkTheme;
      return {
        contentStyle: { backgroundColor },
        itemStyle: type === 'defaultItems' ? null : { color },
      };
    }
    case 'theme-light': {
      return ligthTheme;
    }
    default:
      return ligthTheme;
  }
};

export const convertUrlToFile = async (url) => {
  const response = await fetch(url);
  const contentType = response.headers.get('content-type');
  const blob = await response.blob();
  const file = new File([blob], 'image', { type: contentType });
  Object.assign(file, { preview: URL.createObjectURL(file) });
  return file;
};

export const convertImageFileToBase64 = async (imageFile) => {
  if (!imageFile || !imageFile?.type?.match(/image.*|application\/octet-stream/)) return '';
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(imageFile);
  });
};

export const base64StringToFile = (base64String, filename = 'image.png') => {
  if (!base64String) return base64String;
  let arr = base64String.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  const file = new File([u8arr], filename, { type: mime });
  Object.assign(file, { preview: URL.createObjectURL(file), path: filename });
  return file;
};

const readFile = (file) => {
  return new Promise((resolve, reject) => {
    // Create file reader
    let reader = new FileReader();
    // Register event listeners
    reader.addEventListener('loadend', (e) => resolve(e.target.result));
    reader.addEventListener('error', reject);
    // Read file
    reader.readAsArrayBuffer(file);
  });
};

export const getAsByteArray = async (imageFile) => {
  if (!imageFile || !imageFile.type.match('image.*')) return '';
  return new Uint8Array(await readFile(imageFile));
};

//TODO: make use this everywhere possible
export const YesNoOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

export const calulateYearsAndMonths = (startDate, endDate) => {
  const formatedStartDate = moment(startDate).format('DD-MM-YYYY');
  const formatedEndDate = moment(endDate).format('DD-MM-YYYY');
  const beginDate = moment(formatedStartDate, 'DD-MM-YYYY');
  const expireDate = moment(formatedEndDate, 'DD-MM-YYYY');

  const monthDiff = expireDate?._pf?.parsedDateParts[1] - beginDate?._pf?.parsedDateParts[1];
  const yearDiff = expireDate.diff(formatedStartDate, 'years');
  return `${yearDiff > 1 ? `${yearDiff} years` : `${yearDiff} year`} ${
    monthDiff > 1 ? `${monthDiff} months` : `${monthDiff} month`
  }`;
};

export const downloadFileFromUrl = (fileLink) => {
  const link = document.createElement('a');
  link.href = fileLink;
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const getAppItem = (key) => {
  return localStorage.getItem(key);
};

export const setAppItem = (key, item) => {
  localStorage.setItem(key, item);
};

export const findDataInArray = (arr, val, key = '') => {
  return arr.find((item) => (key ? item[key] === val : item.value === val));
};

export default getTooltipStyles;
