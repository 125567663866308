import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { SkillUIProvider } from './SkillUIProvider';
import { SkillCard } from './SkillCard';
import SkillEditDialog from './skill-add-edit-dialog/SkillEditDialog';
import SkillDeleteDialog from './skill-delete-dialog/SkillDeleteDialog';
import SkillViewDialog from './skill-view-dialog/SkillViewDialog';
import { setSkillFilters } from '../redux/actions';
import { useDispatch } from 'react-redux';
import { paths } from '@/shared/helpers/paths';

const skillBankSkillsBaseUrl = paths.SKILL_BANK.SKILLS;

export function SkillsPage() {
  const history = useHistory();

  const skillUIEvents = {
    openViewSkillDialog: (id) => {
      history.push(`${skillBankSkillsBaseUrl}/${id}/view`);
    },
    openNewSkillDialog: () => {
      history.push(`${skillBankSkillsBaseUrl}/new`);
    },
    openEditSkillDialog: (id) => {
      history.push(`${skillBankSkillsBaseUrl}/${id}/edit`);
    },
    openDeleteSkillDialog: (id) => {
      history.push(`${skillBankSkillsBaseUrl}/${id}/delete`);
    },
  };

  //TODO: remove this once we have add and edit api returning newly created data
  const dispatch = useDispatch();

  return (
    <SkillUIProvider skillUIEvents={skillUIEvents}>
      <Switch>
        <Route path={`${skillBankSkillsBaseUrl}/new`}>
          {({ history, match }) => (
            <SkillEditDialog
              show={match != null}
              isEdit={false}
              onHide={() => {
                dispatch(setSkillFilters());
                history.push(skillBankSkillsBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${skillBankSkillsBaseUrl}/:id/edit`}>
          {({ history, match }) => (
            <SkillEditDialog
              show={match != null}
              isEdit={true}
              id={match?.params?.id}
              onHide={() => {
                history.push(skillBankSkillsBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${skillBankSkillsBaseUrl}/:id/delete`}>
          {({ history, match }) => (
            <SkillDeleteDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                history.push(skillBankSkillsBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${skillBankSkillsBaseUrl}/:id/view`}>
          {({ history, match }) => (
            <SkillViewDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                history.push(skillBankSkillsBaseUrl);
              }}
            />
          )}
        </Route>
      </Switch>
      <SkillCard />
    </SkillUIProvider>
  );
}
