import React, { createContext, useContext } from 'react';

const AssignUIContext = createContext();

export function useAssignUIContext() {
  return useContext(AssignUIContext);
}

const CadsAIAssignUIProvider = ({ assignUIEvents, children }) => {
  const value = {
    openAssignDialog: assignUIEvents.openAssignDialog,
  };
  return <AssignUIContext.Provider value={value}>{children}</AssignUIContext.Provider>;
};

export default CadsAIAssignUIProvider;
