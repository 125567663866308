import React from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import DSCohortTable from './DSCohortTable';
import { useDSCohortUIContext } from '../DSCohortUIProvider';
import { shallowEqual, useSelector } from 'react-redux';

const DSCohortTableWrapper = () => {
  const dsCohortsUIContext = useDSCohortUIContext();
  const { checkedDSCohortIds } = useSelector((state) => state.cadsAIDSCohorts, shallowEqual);

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="products-list">
          <div className="card__title">
            <ButtonToolbar className="products-list__btn-toolbar-top">
              <Button color="primary" onClick={dsCohortsUIContext.openNewDSCohortDialog}>
                Add new
              </Button>
              <Button color="success" onClick={null} outline>
                Export DS Cohorts
              </Button>
              <Button
                color="danger"
                onClick={dsCohortsUIContext.openDeleteDSCohortDialog}
                outline
                disabled={checkedDSCohortIds.length === 0}
              >
                Deactivate
              </Button>
            </ButtonToolbar>
          </div>
          <DSCohortTable />
        </CardBody>
      </Card>
    </Col>
  );
};

export default DSCohortTableWrapper;
