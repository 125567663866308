import { handleActions } from 'redux-actions';
import {
  setBTOrganizationFilter,
  fetchOrganizationListForBT,
  setOrganizationListForBT,
  toggleCheckedOrgs,
  uncheckAllBTOrgs,
  setBTOrganizationError,
} from './actions';

const initialState = {
  checkedOrgIds: [],
  loading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    search: '',
    isSortOrderDescending: false,
  },
  totalRecords: 0,
  data: [],
  error: null,
};

export default handleActions(
  {
    [fetchOrganizationListForBT](state) {
      return {
        ...state,
        loading: true,
        data: [],
      };
    },
    [setOrganizationListForBT](state, { payload }) {
      return {
        ...state,
        loading: false,
        data: payload.data.records,
        totalRecords: payload.data.totalRecords,
        error: null,
      };
    },
    [setBTOrganizationFilter](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
      };
    },
    [setBTOrganizationError](state, { payload }) {
      return {
        ...state,
        data: [],
        loading: false,
        totalPages: initialState.totalPages,
        error: payload?.message,
      };
    },
    [toggleCheckedOrgs](state, { payload: orgId }) {
      const checkedIds = state.checkedOrgIds;
      return {
        ...state,
        checkedOrgIds: checkedIds.includes(orgId)
          ? checkedIds.filter((id) => id !== orgId)
          : [...checkedIds, orgId],
      };
    },
    [uncheckAllBTOrgs](state) {
      return {
        ...state,
        checkedOrgIds: [],
      };
    },
  },
  initialState,
);
