import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import StepOne from './steps/StepOne';
import StepTwo from './steps/StepTwo';
import StepThree from './steps/StepThree';
import StepFour from './steps/StepFour';
import LoadingIcon from 'mdi-react/LoadingIcon';
import Preview from './steps/Preview';
import StepFive from './steps/StepFive';
import './index.scss';
import StepSix from "@/containers/Cads-AI/ManageDataStar/Programs/components/program-add-edit-dialog/Program-Wizard-Form/steps/StepSix";

export function getStepFields(page) {
  switch (page) {
    case 1:
      return [
        'title',
        'code',
        'description',
        'activationStatus',
        'startDate',
        'endDate',
        'tags',
        'limitations',
        'logoUrl',
        'backgroundUrl',
        'posterUrl',
      ];
    case 2:
      return ['assessmentList'];
    case 3:
      return ['learningList'];
    case 4:
      return ['sponsorList'];
    case 5:
      return ['programFields']
    case 6:
      return ['cohortList']
    default:
      return [];
  }
}

export function extractFieldData(page, data) {
  const obj = {};
  getStepFields(page).forEach((key) => (obj[key] = data[key]));
  return obj;
}

const ProgramWizardForm = ({
  onSubmit,
  data,
  setData,
  initialValues,
  isEdit,
  programId,
  state,
  setState,
}) => {
  const [page, setPage] = useState(1);
  const [tooglePreviewPage, setTooglePreviewPage] = useState(false);
  const [sponsorLists, setSponsorsList] = useState([]);
  const [confirmationModalData, setConfirmationModalData] = React.useState({
    isOpen: false,
    message: '',
    onConfirm: null,
    confirmBtnText: 'Yes',
    onCancel: () => setConfirmationModalData({ ...confirmationModalData, isOpen: false }),
  });

  const nextPage = (newData) => {
    setData({ ...data, ...newData });
    setPage(page + 1);
  };

  const previousPage = (newData) => {
    setData({ ...data, ...newData });
    setPage(page - 1);
  };

  const clearForm = () => {
    setData(initialValues);
    setPage(1);
  };

  const submitHandler = (newData) => {
    const dataToSubmit = { ...data, ...newData };
    setData(dataToSubmit);
    onSubmit(dataToSubmit, clearForm);
  };

  return (
    <Row>
      <Col md={12} lg={12}>
        <Card className="pb-0">
          {!confirmationModalData.isOpen ? (
            <CardBody className="wizard program-wizard">
              <div>
                {tooglePreviewPage && (
                  <Preview
                    setTooglePreviewPage={setTooglePreviewPage}
                    data={data}
                  />
                )}
              </div>
              {tooglePreviewPage || (
                <div className="wizard__steps">
                  <div className={`wizard__step${page === 1 ? ' wizard__step--active' : ''}`}>
                    <p>Program Details</p>
                  </div>
                  <div className={`wizard__step${page === 2 ? ' wizard__step--active' : ''}`}>
                    <p>Assessment Details</p>
                  </div>
                  <div className={`wizard__step${page === 3 ? ' wizard__step--active' : ''}`}>
                    <p>Learning Details</p>
                  </div>
                  <div className={`wizard__step${page === 4 ? ' wizard__step--active' : ''}`}>
                    <p>Sponsor Details</p>
                  </div>
                  <div className={`wizard__step${page === 5 ? ' wizard__step--active' : ''}`}>
                    <p>Program Form</p>
                  </div>
                  <div className={`wizard__step${page === 6 ? ' wizard__step--active' : ''}`}>
                    <p>Cohorts</p>
                  </div>
                </div>
              )}
              {tooglePreviewPage || (
                <div className="wizard__form-wrapper">
                  {page === 1 && (
                    <StepOne isEdit={isEdit} page={page} onSubmit={nextPage} data={data} />
                  )}
                  {page === 2 && (
                    <StepTwo
                      page={page}
                      previousPage={previousPage}
                      onSubmit={nextPage}
                      data={data}
                      isEdit={isEdit}
                    />
                  )}
                  {page === 3 && (
                    <StepThree
                      page={page}
                      previousPage={previousPage}
                      onSubmit={nextPage}
                      data={data}
                      isEdit={isEdit}
                    />
                  )}
                  {page === 4 && (
                    <StepFour
                        page={page}
                        previousPage={previousPage}
                        onSubmit={nextPage}
                        isEdit={isEdit}
                        data={data}
                        state={state}
                        setState={setState}
                        setTooglePreviewPage={setTooglePreviewPage}
                        setSponsorsList={setSponsorsList}
                    />
                  )}
                  {page === 5 && (
                      <StepFive
                          page={page}
                          previousPage={previousPage}
                          onSubmit={nextPage}
                          data={data}
                          isEdit={isEdit}
                      />
                  )}
                  {page === 6 && (
                      <StepSix
                          page={page}
                          previousPage={previousPage}
                          onSubmit={submitHandler}
                          data={data}
                          isEdit={isEdit}
                          confirmationModal={confirmationModalData}
                          setConfirmationModal={setConfirmationModalData}
                          setTooglePreviewPage={setTooglePreviewPage}
                      />
                  )}
                </div>
              )}
            </CardBody>
          ) : (
            <div className="text-center">
              <div className="modal__body">
                <span className="lnr lnr-pushpin modal__title-icon" />
                {state.newProgramAdded && (
                  <span className="lnr lnr-thumbs-up modal__title-icon success" />
                )}
                {state.errorMessage && (
                  <span className="lnr lnr-cross-circle modal__title-icon error" />
                )}
                {state.loading && (
                  <div className="panel__refresh position-relative" style={{ height: 75 }}>
                    <LoadingIcon />
                  </div>
                )}
                <h4 className="text-modal">Are you sure ?</h4>
                <p style={{ fontSize: 16 }}>{confirmationModalData.message}</p>
              </div>
              <div>
                <Button className="modal_cancel" onClick={confirmationModalData.onCancel}>
                  Cancel
                </Button>
                <Button
                  className="modal_ok"
                  outline
                  color="success"
                  onClick={confirmationModalData.onConfirm}
                >
                  {confirmationModalData.confirmBtnText}
                </Button>
              </div>
            </div>
          )}
        </Card>
      </Col>
    </Row>
  );
};

ProgramWizardForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ProgramWizardForm;
