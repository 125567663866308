import { httpMethod } from '@/shared/helpers/httpMethod.enum';
import { sendRequest } from '../base/ApiService';

const CADS_API_ENDPOINT =
  process.env.REACT_APP_CADS_AI_API + process.env.REACT_APP_CADS_AI_CONTROL_PANEL_ENDPOINT;

const applicantEndPoints = {
  GET_ALL_PROGRAM_APPLICANT: '/getAllProgramApplicants',
  GET_APPLICANT_STATUS_OPTIONS: '/getProgramApplicationStatusOptions',
  GET_APPLICANT_STAGE_OPTIONS: '/getProgramApplicationStageOptions',
  UPDATE_APPLICANT_STATUS: '/updateStatusForProgramApplicants',
  UPDATE_APPLICANT_STAGE: '/updateStageForProgramApplicants',
  EXPORT_ALL_APPLICANT: '/exportAllProgramApplicantDtlsToCsv',
  GET_PROGRAM_APPLICANT_BY_ID: '/getProgramApplicantDetailsById',
  GET_APPLICANT_HISTORY: '/getProgramApplicantHistoryById',
};
const getApplicantList = (filter) => {
  // return getMockApplicant()
  return sendRequest({
    fullUrl: CADS_API_ENDPOINT + applicantEndPoints.GET_ALL_PROGRAM_APPLICANT,
    method: httpMethod.GET,
    params: { ...filter },
  });
};

const getApplicantStageOptions = (filter) => {
  // return getMockApplicantStage()
  return sendRequest({
    fullUrl: CADS_API_ENDPOINT + applicantEndPoints.GET_APPLICANT_STAGE_OPTIONS,
    method: httpMethod.GET,
    params: filter,
  });
};

const updateStageForProgramApplicant = (data) => {
  console.log(data);
  // return updateMockStageForProgramApplicant(data)
  return sendRequest({
    fullUrl: CADS_API_ENDPOINT + applicantEndPoints.UPDATE_APPLICANT_STAGE,
    method: httpMethod.PUT,
    data: data,
  });
};

const updateStatusForProgramApplicant = (data) => {
  // return updateMockStatusForProgramApplicant(data)
  return sendRequest({
    fullUrl: CADS_API_ENDPOINT + applicantEndPoints.UPDATE_APPLICANT_STATUS,
    method: httpMethod.PUT,
    data: data,
  });
};

const getApplicantListCSV = (filter) => {
  return sendRequest({
    fullUrl: CADS_API_ENDPOINT + applicantEndPoints.EXPORT_ALL_APPLICANT,
    method: httpMethod.GET,
    params: filter,
  });
};

const getApplicantById = (id) => {
  return sendRequest({
    fullUrl: CADS_API_ENDPOINT + applicantEndPoints.GET_PROGRAM_APPLICANT_BY_ID,
    method: httpMethod.GET,
    params: { id: id },
  });
};

const getApplicantStatusOptions = (filter) => {
  // return getMockApplicantStatusOption()
  return sendRequest({
    fullUrl: CADS_API_ENDPOINT + applicantEndPoints.GET_APPLICANT_STATUS_OPTIONS,
    method: httpMethod.GET,
    params: filter,
  });
};

const getApplicantHistory = (id) => {
  // return getMockApplicantHistory();
  return sendRequest({
    fullUrl: CADS_API_ENDPOINT + applicantEndPoints.GET_APPLICANT_HISTORY,
    method: httpMethod.GET,
    params: { enrollmentId: id },
  });
};

const getMockApplicantHistory = () =>
  new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [''],
            data: {
              id: 0,
              enrollmentDate: '2022-02-22T22:06:34.952Z',
              enrollmentStatus: { id: 0, title: 'string' },
              history: [
                {
                  changeDate: '2022-04-22T22:06:34.952Z',
                  fromStageId: {
                    id: 1,
                    title: 'Registration',
                  },
                  toStageId: {
                    id: 2,
                    title: 'Pre-Assessment',
                  },
                  fromStatus: {
                    id: 0,
                    title: 'Completed',
                  },
                  toStatus: {
                    id: 0,
                    title: 'Completed',
                  },
                },
                {
                  changeDate: '2022-05-22T22:06:34.952Z',
                  fromStageId: {
                    id: 2,
                    title: 'Pre-Assessment',
                  },
                  toStageId: {
                    id: 3,
                    title: 'Smart Skill',
                  },
                  fromStatus: {
                    id: 0,
                    title: 'Completed',
                  },
                  toStatus: {
                    id: 0,
                    title: 'In-Review',
                  },
                },
              ],
            },
          },
        }),
      500,
    );
  });

const getMockApplicant = () =>
  new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [''],
            data: {
              totalRecords: 2,
              currentPage: 1,
              pageSize: 10,
              records: [
                {
                  id: 5,
                  userLogoUrl:
                    'https://general-assets.sgp1.digitaloceanspaces.com/datastar-general/yayasan-peneraju-logo.png',
                  userName: 'Mock UserName',
                  userEmail: 'mock@gmail.com',
                  icNumber: 1231215,
                  isBhumiputra: true,
                  mobileNumber: 5656545,
                  highestQualification: 'BTech',
                  university: 'Mock Uni',
                  yearsOfWorkingExp: 12,
                  isAgeBelowLimit: false,
                  hearFromProgramSource: '',
                  applicantFinalStatus: { id: 1, title: 'Mock Status 1' },
                  applicantFinalStage: { id: 1, title: 'Mock Stage 1' },
                  status: true,
                  program: 'Datastar',
                  enrollmentDate: '2023-06-27T00:00:00',
                  pdpaDate: '2023-06-27T00:00:00',
                  preAssessmentDate: '2023-06-27T00:00:00',
                  smartSkillDate: '2023-06-27T00:00:00',
                  first_level_date: '2023-06-27T00:00:00',
                  interview_date: '2023-06-27T00:00:00',
                  hr_approval_date: '2023-06-27T00:00:00',
                  sponsor_approval_date: '2023-06-27T00:00:00',
                },
                {
                  id: 8,
                  userLogoUrl:
                    'https://general-assets.sgp1.digitaloceanspaces.com/datastar-general/yayasan-peneraju-logo.png',
                  userName: 'Mock UserName 2',
                  userEmail: 'mock2@gmail.com',
                  icNumber: 78932323,
                  isBhumiputra: true,
                  mobileNumber: 999999999,
                  highestQualification: 'BTech',
                  university: 'Mock Uni 2',
                  yearsOfWorkingExp: 12,
                  isAgeBelowLimit: true,
                  hearFromProgramSource: '',
                  applicantFinalStatus: { id: 1, title: 'Mock Status 2' },
                  applicantFinalStage: { id: 1, title: 'Mock Stage 2' },
                  status: false,
                  program: 'Datastar',
                  enrollmentDate: '2023-06-27T00:00:00',
                  pdpaDate: '2023-06-27T00:00:00',
                  preAssessmentDate: '2023-06-27T00:00:00',
                  smartSkillDate: '2023-06-27T00:00:00',
                  first_level_date: '2023-06-27T00:00:00',
                  interview_date: '2023-06-27T00:00:00',
                  hr_approval_date: '2023-06-27T00:00:00',
                  sponsor_approval_date: '2023-06-27T00:00:00',
                },
              ],
            },
          },
        }),
      500,
    );
  });

const getMockApplicantStage = () =>
  new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [''],
            data: [
              {
                id: 1,
                title: 'Mock Stage 1',
              },
              {
                id: 2,
                title: 'Mock Stage 2',
              },
              {
                id: 3,
                title: 'Mock Stage 3',
              },
            ],
          },
        }),
      500,
    );
  });

const getMockApplicantStatusOption = () =>
  new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [''],
            data: [
              {
                id: 1,
                title: 'Mock Status 1',
              },
              {
                id: 2,
                title: 'Mock Status 2',
              },
              {
                id: 3,
                title: 'Mock Status 3',
              },
            ],
          },
        }),
      500,
    );
  });

const updateMockStageForProgramApplicant = (responseData) => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          body: responseData,
        }),
      500,
    );
  });
};

const updateMockStatusForProgramApplicant = (responseData) => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          body: responseData,
        }),
      500,
    );
  });
};

export default {
  getApplicantList,
  getApplicantStageOptions,
  getApplicantStatusOptions,
  updateStageForProgramApplicant,
  updateStatusForProgramApplicant,
  getApplicantListCSV,
  getApplicantById,
  getApplicantHistory,
};
