import React from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import CourseTable from './CourseTable';
import { useCourseUIContext } from '../CourseUIProvider';
import { shallowEqual, useSelector } from 'react-redux';

const CourseTableWrapper = () => {
  const courseUIContext = useCourseUIContext();
  const checkedCourseIds = useSelector(
    (state) => state.cadsAICourse.checkedCourseIds,
    shallowEqual,
  );
  return courseUIContext.isForAssignment ? (
    <CourseTable />
  ) : (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="products-list">
          <div className="card__title">
            <h5 className="bold-text">Course List</h5>
            <ButtonToolbar className="products-list__btn-toolbar-top">
              <Button color="primary" onClick={courseUIContext.openAddCourseDialog}>
                Add new Course
              </Button>
              <Button
                color="danger"
                outline
                onClick={courseUIContext.openUpdateCourseDialog}
                outline
                disabled={checkedCourseIds.length === 0}
              >
                Deactivate
              </Button>
            </ButtonToolbar>
          </div>
          <CourseTable />
        </CardBody>
      </Card>
    </Col>
  );
};

export default CourseTableWrapper;
