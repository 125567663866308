import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { shallowEqual, useSelector } from 'react-redux';
import { CoursePage } from '@/containers/Cads-AI/ManageLearning/Course/components/CoursePage';

const StepOne = ({ onSubmit }) => {
  const { checkedCourse } = useSelector((state) => state.cadsAICourse, shallowEqual);

  return (
    <div className="step-one">
      <br />

      <CoursePage isForAssignment={true} />

      <br />

      <ButtonToolbar className="form__button-toolbar wizard__toolbar">
        <Button color="primary" disabled className="previous">
          Back
        </Button>
        <Button
          color="primary"
          className="next"
          disabled={checkedCourse.id === -1}
          onClick={onSubmit}
        >
          Next
        </Button>
      </ButtonToolbar>
    </div>
  );
};

export default StepOne;
