import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import LearningPathViewDialogHeader from './LearningPathViewDialogHeader';
import api from '@/utils/apiClient';
import LearningPathViewTabs from './LearningPathViewTabs';
import LoadingIcon from 'mdi-react/LoadingIcon';

const LearningPathViewDialog = ({ show, onHide, id, rtl }) => {
  const [modal, setModal] = useState({
    isOpen: show,
    title: 'View Learning Path',
    hide: onHide,
  });

  const [state, setState] = React.useState({
    loading: false,
    learningPath: {
      name: 'Test Learning Path 1',
      description: 'any',
    },
    error: '',
  });

  React.useEffect(() => {
    if (id) getCadsAiLearningPathById(+id);
  }, [id]);

  const getCadsAiLearningPathById = async (id) => {
    try {
      setState({ ...state, loading: true });
      const { data: res, isSuccess } = await api.cadsAILearningPathApi.getCadsAiLearningPath(id);
      if (isSuccess) {
        setState({ ...state, loading: false, learningPath: res.data, error: '' });
      } else {
        throw new Error(res?.messages?.[0] ?? 'Error while getting learning Path details');
      }
    } catch ({ message }) {
      setState({ ...state, loading: false, learningPath: {}, error: message });
      console.error(message);
    }
  };

  const { error, loading, learningPath } = state;

  return (
    <Modal
      isOpen={show}
      toggle={modal.hide}
      modalClassName={`${rtl.direction}-support`}
      className="modal-dialog--primary modal-dialog--header modal-view-dialog"
    >
      <LearningPathViewDialogHeader modal={modal} learningPath={learningPath} />
      <div style={{ padding: 15 }}>
        {loading || !!error ? (
          <>
            {loading && (
              <div className="panel__refresh position-relative" style={{ height: 75 }}>
                <LoadingIcon />
              </div>
            )}
            <div className="text-center">
              {error && (
                <div>
                  <span className="lnr lnr-cross-circle modal__title-icon error" />
                </div>
              )}
              {loading ? 'Getting learning path details...' : error}
            </div>
          </>
        ) : (
          <LearningPathViewTabs learningPath={state.learningPath} />
        )}
      </div>
    </Modal>
  );
};

export default connect((state) => ({
  rtl: state.rtl,
}))(LearningPathViewDialog);
