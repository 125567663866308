import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import EmailsTableWrapper from './table/EmailsTableWrapper';

export function EmailCard() {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Emails</h3>
        </Col>
      </Row>
      <Row>
        <EmailsTableWrapper />
      </Row>
    </Container>
  );
}
