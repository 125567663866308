import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { RTLProps } from '@/shared/prop-types/ReducerProps';
import StatsSection from './components/stats';
import UserSection from './components/user';
import SkillSection from './components/skills';
import JobsSection from './components/jobs';
import LearningSection from './components/learning';
import AssessmentSection from './components/assessment';
import QASection from './components/qna';

const CadsAIDashboard = ({ rtl }) => {
  return (
    <Container className="dashboard">
      <h3 className="page-title">Cads AI Dashboard</h3>
      <StatsSection />
      <UserSection />
      <SkillSection />
      <AssessmentSection />
      <JobsSection />
      <LearningSection />
      <QASection />
    </Container>
  );
};

CadsAIDashboard.propTypes = {
  rtl: RTLProps.isRequired,
};

export default compose(
  connect((state) => ({
    rtl: state.rtl,
  })),
)(CadsAIDashboard);
