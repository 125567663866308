import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import ArticleViewDialogHeader from './ArticleViewDialogHeader';
import api from '@/utils/apiClient';
import LoadingIcon from 'mdi-react/LoadingIcon';
import SanitizeHTML from '@/shared/components/SanitizeHTML';
import moment from 'moment';

const ArticleViewDialog = ({ show, onHide, id, rtl }) => {
  const [modal] = useState({
    isOpen: show,
    title: 'View Article',
    hide: onHide,
  });

  const [state, setState] = useState({
    loading: true,
    article: {
      articleHtml: '',
    },
    error: '',
  });

  useEffect(() => {
    if (id) getArticleById(+id);
  }, [id]);

  const getArticleById = async (articleId) => {
    try {
      const { data: res, isSuccess } = await api.cadsAIArticle.getArticleById(articleId);
      if (id) {
        setState({ ...state, loading: false, article: res.data, error: '' });
      } else {
        throw new Error(res?.messages?.[0] ?? 'Error while getting article details');
      }
    } catch ({ message }) {
      setState({ ...state, loading: false, article: {}, error: message });
      console.error(message);
    }
  };

  const { error, loading, article } = state;

  return (
    <Modal
      isOpen={show}
      toggle={modal.hide}
      modalClassName={`${rtl.direction}-support`}
      className="modal-dialog--primary modal-dialog--header modal-view-dialog"
    >
      <ArticleViewDialogHeader modal={modal} article={article} />
      {/* Article Modal Body */}
      <div style={{ padding: 15 }}>
        {loading || !!error ? (
          <>
            {loading && (
              <div className="panel__refresh position-relative" style={{ height: 75 }}>
                <LoadingIcon />
              </div>
            )}
            <div className="text-center">
              {error && (
                <div>
                  <span className="lnr lnr-cross-circle modal__title-icon error" />
                </div>
              )}
              {loading ? 'Getting article details...' : error}
            </div>
          </>
        ) : (
          <table className="project-summary__info">
            <tr>
              <th>Image</th>
              <td>
                <img
                  style={{ height: 100, width: 100 }}
                  alt={'article-img'}
                  src={
                    article.imageThumbnail ??
                    'https://unified.dev.cads.ai/image/sample/rectangle-34.png'
                  }
                />
              </td>
            </tr>
            <tr>
              <th>Title</th>
              <td>{article?.title}</td>
            </tr>
            <tr>
              <th>Slug</th>
              <td>{article?.slug}</td>
            </tr>
            <tr>
              <th>Description</th>
              <td>
                <div className="question-extra-html">
                  <SanitizeHTML html={article.description ?? '<p/>'} />
                </div>
              </td>
            </tr>
            <tr>
              <th>Tags</th>
              <td>{article?.tag ?? '-'}</td>
            </tr>
            <tr>
              <th>Boost Score</th>
              <td>{article?.boostScore ?? '-'}</td>
            </tr>
            <tr>
              <th>Read Count</th>
              <td>{article?.readCount ?? '-'}</td>
            </tr>
            <tr>
              <th>Post Type</th>
              <td>{article?.postType?.title ?? '-'}</td>
            </tr>
            <tr>
              <th>Link</th>
              <td>
                <a href={article?.link} target="_blank">
                  {article?.link}
                </a>
              </td>
            </tr>
            <tr>
              <th>Is Published</th>
              <td>{article?.is_published ? 'Yes' : 'No'}</td>
            </tr>
            <tr>
              <th>Publish Date</th>
              <td>
                {article?.publishDate ? moment(article?.publishDate).format('DD/MM/yyyy') : '-'}
              </td>
            </tr>
            <tr>
              <th>Status</th>
              <td>{article.status ? 'Active' : 'Inactive'}</td>
            </tr>
          </table>
        )}
      </div>
    </Modal>
  );
};

export default connect((state) => ({
  rtl: state.rtl,
}))(ArticleViewDialog);
