import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { useForm } from 'react-hook-form';
import FormField from '@/shared/components/form/hook/FormField';
import { StepDefaultProps, StepPropTypes } from './Props';
import renderDropZoneField from '@/shared/components/form/DropZone';
import { extractFieldData, getStepFields } from '../index';
import { convertUrlToFile } from '@/shared/helpers';
import TextEditor from '@/shared/components/text-editor/TextEditor';

const StepTwo = ({ onSubmit, page, previousPage, data }) => {
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm(extractFieldData(page, data));

  const setImage = async () => {
    if (data?.imageUrl) {
      const imgFile = await convertUrlToFile(data?.imageUrl);
      setValue('image', imgFile ? [imgFile] : '');
    }
  };

  React.useEffect(() => {
    getStepFields(page).forEach((key) => setValue(key, data[key]));
    setImage();
  }, [data]);

  return (
    <form className="form wizard__form" onSubmit={handleSubmit(onSubmit)}>
      <div className="form__form-group">
        <span className="form__form-group-label">Question</span>
        <div className="form__form-group-field">
          <FormField
            name="question"
            component="textarea"
            placeholder="Enter your question"
            control={control}
            defaultValue=""
            errors={errors}
            rules={{ required: 'This is required field' }}
            isAboveError
          />
        </div>
      </div>
      {/*TODO: upgrade the following component with with Image upload feature */}
      <div className="form__form-group">
        <span className="form__form-group-label">Additional text (Some HTML allowed)</span>
        <div className="form__form-group-field">
          {/*TODO: set html after sanitizing for all text editor*/}
          <FormField
            control={control}
            name="additionalText"
            component={() => (
              <TextEditor
                defaultContent={data?.additionalText}
                onChange={(value) => setValue('additionalText', value)}
              />
            )}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label w-25">Image (optional)</span>
        <span className="form__form-group-label text-right w-75">
          only JPG, JPEG, GIF or PNG accepted, max size: 2MB
        </span>

        <div className="form__form-group-field">
          <FormField name="image" control={control} component={renderDropZoneField} />
        </div>
      </div>

      <ButtonToolbar className="form__button-toolbar wizard__toolbar">
        <Button
          color="primary"
          type="button"
          className="previous"
          onClick={() => previousPage(getValues())}
        >
          Back
        </Button>
        <Button color="primary" type="submit" className="next">
          Next
        </Button>
      </ButtonToolbar>
    </form>
  );
};

StepTwo.propTypes = StepPropTypes;
StepTwo.defaultProps = StepDefaultProps;

export default StepTwo;
