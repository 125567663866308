import React from 'react';
import { Col } from 'reactstrap';
import moment from 'moment';
import { Field } from '@/shared/components/Field';;

const ApplicationDetails = ({ applicantDetail }) => {
  return (
    <>
      <div className="pt-5">
        <h4 className="pl-2">Application Details</h4>
        <div className="row pt-2">
          <Col md={6} xl={4} lg={6}>
            <Field
              title={'Program'}
              iconDisable={true}
              fieldValue={applicantDetail?.program ? applicantDetail?.program : '-'}
            />
            <Field
              iconDisable={true}
              title={'Enrollment Date'}
              fieldValue={
                applicantDetail?.enrollmentDate
                  ? moment(applicantDetail?.enrollmentDate).format('DD/MM/yyyy')
                  : '-'
              }
            />
            <Field
              title={'Highest Qualification'}
              iconDisable={true}
              fieldValue={
                applicantDetail?.highestQualification ? applicantDetail?.highestQualification : '-'
              }
            />
            <Field
              title={'University Title'}
              iconDisable={true}
              fieldValue={applicantDetail?.university ? applicantDetail?.university : '-'}
            />
          </Col>
          <Col md={6} xl={4} lg={6}>
            <Field
              title={'Age is below limit'}
              iconDisable={true}
              fieldValue={applicantDetail?.isAgeBelowLimit ? 'Yes' : 'No'}
            />
            <Field
              title={'IC Number'}
              iconDisable={true}
              fieldValue={applicantDetail?.icNumber ? applicantDetail?.icNumber : '-'}
            />
            <Field
              title={'Program Source'}
              iconDisable={true}
              fieldValue={
                applicantDetail?.hearFromProgramSource
                  ? applicantDetail?.hearFromProgramSource
                  : '-'
              }
            />
          </Col>
          <Col md={6} xl={4} lg={6}>
            <Field
              title={'Is Bumiputera'}
              iconDisable={true}
              fieldValue={applicantDetail?.isBhumiputra ? 'Yes' : 'No'}
            />
            {/*)}*/}
            <Field
              title={'Experience in Years'}
              iconDisable={true}
              fieldValue={
                applicantDetail?.yearsOfWorkingExp ? applicantDetail?.yearsOfWorkingExp : '-'
              }
            />
          </Col>
        </div>
      </div>
    </>
  );
};

export default ApplicationDetails;
