import React from 'react';
import api from '@/utils/apiClient';
import { updateJobInList } from '../../redux/actions';
import LoadingIcon from 'mdi-react/LoadingIcon';
import JobWizardForm from './job-wizard-form';
import { useDispatch } from 'react-redux';

const JobEditForm = ({ isEdit, id }) => {
  const initialValues = {
    title: '',
    description: '',
    organization: '',
    salaryNegotiable: 'true',
    jobLevel: '',
    jobType: '',
    salaryBegin: '',
    smartSkillsRequired: 'true',
    salaryEnd: '',
    currency: '',
    location: '',
    locationStatus: '',
    country: '',
    industry: '',
    educationAttainment: '',
    requiredCgpa: 0,
    requiredSkills: [],
    otherSkills: [],
  };

  //states
  const [state, setState] = React.useState({
    loading: false,
    fetchingJob: false,
    newJobAdded: false,
    errorMessage: '',
  });
  const [data, setData] = React.useState(initialValues);
  const dispatch = useDispatch();

  // prefill the form in case of edit job
  React.useEffect(() => {
    if (isEdit && id) {
      setDefaultData(+id);
    }
  }, [isEdit, id]);

  const setDefaultData = async (jobId) => {
    try {
      const { data: res, isSuccess } = await api.cadsAIJob.getJobById(jobId);
      if (isSuccess) {
        setData({
          ...res?.data,
          jobLevel: { label: res?.data?.jobLevelName },
          organization: { value: res?.data?.orgId, label: res?.data?.organizationName },
          industry: { value: res?.data?.industryId, label: res?.data?.industry },
          smartSkillsRequired: res?.data?.isSmartSkillRequired ? 'true' : 'false',
          salaryNegotiable: res?.data?.salaryNegotiable ? 'true' : 'false',
          country: { label: res?.data?.country },
          requiredSkills: res?.data?.requiredSkills?.map((m) => ({ value: m.id, label: m.title })),
        });
      } else {
        throw new Error(res?.messages?.[0]);
      }
    } catch ({ message }) {
      console.error('Job edit error : ', message);
      setState({ ...state, errorMessage: 'Error while getting existing job details' });
    }
  };

  // job form related functions
  const resetForm = () => {
    setData(initialValues);
  };

  const onSubmit = async () => {
    //TODO: remove following 2 line when actual api is integrated
    setState({ ...state, newJobAdded: true, loading: false });
    const requestPayload = {
      id: id,
      title: data?.title,
      description: data?.description,
      organizationId: data?.organization?.value,
      jobLevelId: data?.jobLevel?.value,
      jobTypeId: data?.jobType?.value,
      jobTags: [{ jobTags: '' }],
      salaryBegin: parseInt(data?.salaryBegin),
      salaryEnd: parseInt(data?.salaryEnd),
      isSalaryNegotiable: data?.salaryNegotiable === 'true',
      currencyId: data?.currency?.value,
      location: data?.location,
      locationStatus: data?.locationStatus,
      countryId: data?.country?.value,
      industryId: data?.industry?.value,
      educationAttainment: data?.educationAttainment?.value,
      isSmartSkillRequired: data?.smartSkillsRequired === 'true',
      requiredSkills: data?.requiredSkills?.map((skill) => skill.value),
      otherSkills: data?.otherSkills?.map((skill) => skill.value),
      cgpa: parseInt(data?.requiredCgpa),
    };
    setState({ ...state, loading: true });
    const defaultError = `Error occurred while ${isEdit ? 'updating' : 'adding new'} job`;
    try {
      const { data, isSuccess } = isEdit
        ? await api.cadsAIJob.editJob(requestPayload)
        : await api.cadsAIJob.addJob(requestPayload);
      if (isSuccess) {
        setState({ ...state, newJobAdded: true, loading: false });

        if (isEdit) dispatch(updateJobInList(data));
        else resetForm();
      } else {
        throw new Error(data?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({ ...state, loading: false, newJobAdded: false, errorMessage: error.message });
    }
  };

  return (
    <div>
      {/*Add/Edit Job Form Status*/}
      <div className="ml-4">
        {state.loading && (
          <div>
            <div className="panel__refresh position-relative" style={{ height: 75, width: 20 }}>
              <LoadingIcon />
              <div style={{ width: 200, padding: '23px 40px' }}>
                {isEdit ? 'Updating' : 'Adding new'} job...
              </div>
            </div>
          </div>
        )}
        {(state.newJobAdded || !!state.errorMessage) && (
          <table className="mt-3">
            <tr>
              <td>
                {state.newJobAdded ? (
                  <span className="lnr lnr-thumbs-up modal__title-icon success" />
                ) : (
                  <span className="lnr lnr-cross-circle modal__title-icon error" />
                )}
              </td>
              <td className="pl-2">
                {state.newJobAdded
                  ? `${isEdit ? 'Job Updated' : 'New Job Added'} Successfully`
                  : state.errorMessage}
              </td>
            </tr>
          </table>
        )}
      </div>
      <JobWizardForm
        onSubmit={onSubmit}
        setData={setData}
        id={id}
        data={data}
        initialValues={initialValues}
        state={state}
      />
    </div>
  );
};

export default JobEditForm;
