import React, { useRef } from 'react';
import { Button, ButtonToolbar, CardBody, FormGroup, Input } from 'reactstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getCPModuleSchedulesList,
  setModuleScheduleFilters,
  toggleCheckedModuleSchedule,
} from '@/containers/Control-Panel/redux/moduleSchedule/actions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import LoadingIcon from 'mdi-react/LoadingIcon';
import TablePagination from '@material-ui/core/TablePagination';
import { defaultPaginationProps } from '@/shared/helpers';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import MagnifyIcon from 'mdi-react/MagnifyIcon';

const ModuleScheduleColumns = [
  {
    id: 'checkbox',
    disablePadding: true,
    label: '',
  },
  {
    id: 'name',
    disablePadding: true,
    label: 'Schedule Name',
  },
  {
    id: 'trainingProvider.name',
    disablePadding: true,
    label: 'Training Provider',
  },
  {
    id: 'contentProvider.name',
    disablePadding: true,
    label: 'Content Type',
  },
  {
    id: 'isMainVariant',
    disablePadding: true,
    label: 'Main Variant',
  },
  {
    id: 'isPlatformWide',
    disablePadding: true,
    label: 'Platform Wide',
  },
  /*  {
    id: 'description', disablePadding: true, label: 'Schedule Description',
  },*/
];

const ModuleScheduleTableSearchBar = React.memo(({ dispatch, totalModuleSchedule }) => {
  const [isSearched, setIsSearched] = React.useState(false);
  const searchRef = useRef(null);

  const handleSearch = () => {
    setIsSearched(true);
    dispatch(setModuleScheduleFilters({ search: searchRef?.current?.value, pageNumber: 1 }));
  };

  const handleClear = () => {
    if (searchRef?.current?.value) searchRef.current.value = '';
    setIsSearched(false);
    dispatch(setModuleScheduleFilters({ search: '' }));
  };

  return (
    <TableHead>
      <div className="table__search">
        <div className="table__search_item">
          <b>Module Schedule Name</b>
          <div className="form">
            <div className="form__form-group">
              <div className="form__form-group-field">
                <div className="form__form-group-icon" style={{ height: 37 }}>
                  <MagnifyIcon />
                </div>
                <input
                  ref={searchRef}
                  className="search-bar-search-text"
                  name="searchText"
                  type="text"
                  placeholder="Search by Module Schedule Name"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table__search_item mt-3">
          <Button color="primary" type="button" onClick={handleSearch} outline>
            {' '}
            Search{' '}
          </Button>
        </div>
        <div className="table__search_item mt-3">
          <Button color="secondary" onClick={handleClear} outline>
            Clear
          </Button>
        </div>
      </div>

      {isSearched && (
        <div className="ml-1">
          <span>Found {totalModuleSchedule} matches</span>
        </div>
      )}
    </TableHead>
  );
});

const ModuleScheduleTableColumns = React.memo(({ order, orderBy, onRequestSort }) => {
  return (
    <TableRow>
      {ModuleScheduleColumns.map((row) => (
        <TableCell
          className="material-table__cell material-table__cell--sort material-table__cell-center"
          key={row.id}
          align={'center'}
          padding={row.disablePadding ? 'none' : 'default'}
          sortDirection={orderBy === row.id ? order : false}
        >
          <TableSortLabel
            active={orderBy === row.id}
            direction={order}
            onClick={(event) => onRequestSort(event, row.id)}
            className="material-table__sort-label"
            dir="ltr"
          >
            <b>{row.label}</b>
          </TableSortLabel>
        </TableCell>
      ))}
    </TableRow>
  );
});
const ModuleScheduleTableRows = React.memo(
  ({ moduleSchedules, checkedModuleSchedule, dispatch }) => {
    return moduleSchedules?.map((moduleSchedule) => (
      <TableRow className="material-table" tabIndex={-1} key={moduleSchedule.id}>
        <TableCell align="center" className="material-table__cell">
          <FormGroup>
            <Input
              className="table-checkbox"
              type="checkbox"
              value={moduleSchedule.id}
              checked={checkedModuleSchedule.includes(moduleSchedule.id)}
              onChange={() => dispatch(toggleCheckedModuleSchedule(moduleSchedule.id))}
            />
          </FormGroup>
        </TableCell>
        <TableCell align="center" className="material-table__cell">
          {moduleSchedule?.name}
        </TableCell>
        <TableCell align="center" className="material-table__cell">
          {moduleSchedule?.trainingProvider?.name}
        </TableCell>
        <TableCell align="center" className="material-table__cell">
          {moduleSchedule?.contentProvider?.name ?? '-'}
        </TableCell>
        <TableCell align="center" className="material-table__cell">
          {moduleSchedule?.isMainVariant === true
            ? 'Yes'
            : moduleSchedule?.isMainVariant === false
            ? 'No'
            : '-'}
        </TableCell>
        <TableCell align="center" className="material-table__cell">
          {moduleSchedule?.isPlatformWide === true
            ? 'Yes'
            : moduleSchedule?.isPlatformWide === false
            ? 'No'
            : '-'}
        </TableCell>
        {/*<TableCell align="center" className="material-table__cell">{moduleSchedule.description}</TableCell>*/}
      </TableRow>
    ));
  },
);

const StepTwo = ({ nextPage, previousPage }) => {
  const dispatch = useDispatch();
  const { checkedModule: selectedModule } = useSelector((state) => state.cpModules, shallowEqual);
  const {
    loading,
    filters,
    totalRecords,
    assignModuleSchedules: moduleSchedules,
    checkedModuleSchedule,
    error,
  } = useSelector((state) => state.cpModuleSchedules, shallowEqual);
  const {
    pageSize: rowsPerPage,
    pageNumber: page,
    sortBy: orderBy,
    isSortOrderDescending,
  } = filters;

  React.useEffect(() => {
    if (selectedModule.id && selectedModule.id !== -1)
      dispatch(getCPModuleSchedulesList({ ...filters, id: selectedModule.id }));
  }, [filters]);

  const handleRequestSort = (event, property) => {
    dispatch(
      setModuleScheduleFilters({ sortBy: property, isSortOrderDescending: !isSortOrderDescending }),
    );
  };

  const handleChangePage = (event, currentPage) => {
    dispatch(setModuleScheduleFilters({ pageSize: rowsPerPage, pageNumber: currentPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setModuleScheduleFilters({ pageSize: +event.target.value, pageNumber: 1 }));
  };

  const emptyRows =
    page === Math.ceil(totalRecords / rowsPerPage) ? rowsPerPage - moduleSchedules.length : 0;

  return (
    <form className="form step-one" onSubmit={nextPage}>
      {/*ModuleSchedule Table*/}
      <CardBody className="col mt-4">
        <div className="material-table__wrap">
          <ModuleScheduleTableSearchBar
            dispatch={dispatch}
            totalModuleSchedule={moduleSchedules?.length ?? 0}
          />
          <Table className="material-table">
            <TableBody>
              <ModuleScheduleTableColumns
                order={isSortOrderDescending ? 'desc' : 'asc'}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              {(loading || !!error) && (
                <TableRow>
                  <TableCell colSpan={ModuleScheduleColumns.length}>
                    {loading && (
                      <div className={'text-center overflow-hidden'}>
                        <div className="panel__refresh position-relative" style={{ height: 75 }}>
                          <LoadingIcon />
                        </div>
                      </div>
                    )}
                    <div className={'text-center'}>
                      <p className={!!error ? 'm-5' : ''}>
                        {error || 'Loading Module Schedules...'}
                      </p>
                    </div>
                  </TableCell>
                </TableRow>
              )}
              <ModuleScheduleTableRows
                moduleSchedules={moduleSchedules}
                checkedModuleSchedule={checkedModuleSchedule}
                dispatch={dispatch}
              />
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={ModuleScheduleColumns.length} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          count={totalRecords}
          rowsPerPage={totalRecords < 20 ? totalRecords : 20} //rowsPerPage
          page={page - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          component={defaultPaginationProps.component}
          backIconButtonProps={defaultPaginationProps.backIconButton}
          nextIconButtonProps={defaultPaginationProps.nextIconButton}
          rowsPerPageOptions={[totalRecords, ...defaultPaginationProps.rowsPerPage]}
          className={defaultPaginationProps.className}
          dir={defaultPaginationProps.dir}
        />
      </CardBody>

      <br />

      <ButtonToolbar className="form__button-toolbar wizard__toolbar">
        <Button color="primary" type="button" onClick={previousPage} className="previous">
          Back
        </Button>
        <Button
          color="primary"
          type="submit"
          className="next"
          disabled={checkedModuleSchedule.length === 0}
        >
          Next
        </Button>
      </ButtonToolbar>
    </form>
  );
};

export default StepTwo;
