import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const setModuleScheduleFetching = createAction('SET_ModuleSchedule_FETCHING');
export const setModuleScheduleError = createAction('SET_ModuleSchedule_ERROR');
export const setAssignModuleScheduleList = createAction('SET_ASSIGN_ModuleSchedule_LIST');
export const setModuleScheduleFilters = createAction('SET_ModuleSchedule_LIST_FILTER');
export const toggleCheckedModuleSchedule = createAction('TOGGLE_CHECKED_MODULE_SCHEDULE');
export const clearCheckedModuleSchedule = createAction('CLEAR_CHECKED_MODULE_SCHEDULE');

export const getCPModuleSchedulesList = (filter) => async (dispatch) => {
  const defaultError = `Error occurred getting module's schedule list`;
  dispatch(setModuleScheduleFetching());
  try {
    const { data, isSuccess } = await api.moduleSchedules.getModuleScheduleList(filter);
    if (isSuccess) dispatch(setAssignModuleScheduleList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setModuleScheduleError(error.message));
    console.error(defaultError, error.message);
  }
};
