import React from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import { paths } from '@/shared/helpers/paths';

function SidebarContent({ onClick, sidebarCollapse, user }) {
  const props = { onClick, user };

  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        {/*Assessment*/}
        <SidebarLink title="Dashboard" icon="home" route={paths.DASHBOARD} {...props} />
        <SidebarLink title="Customer" icon="home" route={paths.CUSTOMER} {...props} />
        <SidebarLink title="Assessments" icon="layers" route={paths.ASSESSMENTS.BASE} {...props} />
        <SidebarLink title="Assignments" icon="layers" route={paths.ASSIGNMENTS.BASE} {...props} />
        <SidebarCategory
          title="Skill Bank"
          icon="layers"
          route={paths.SKILL_BANK.BASE}
          user={props.user}
          sidebarCollapse={sidebarCollapse}
        >
          <SidebarLink title="Skills" route={paths.SKILL_BANK.SKILLS} {...props} />
          <SidebarLink title="Subcategories" route={paths.SKILL_BANK.SUB_CATEGORIES} {...props} />
          <SidebarLink title="Domains" route={paths.SKILL_BANK.DOMAINS} {...props} />
          <SidebarLink title="Questions" route={paths.SKILL_BANK.QUESTIONS} {...props} />
        </SidebarCategory>
        {/*<SidebarLink title="Data Bank" icon="database" route={paths.DATA_BANK} {...props} />*/}

        {/*Campaign*/}
        <SidebarCategory
          title="Campaign"
          icon="layers"
          route={paths.CAMPAIGN.BASE}
          user={props.user}
          sidebarCollapse={sidebarCollapse}
        >
          <SidebarLink title="Users" route={paths.CAMPAIGN.USERS} {...props} />
          <SidebarLink title="Sunrise 25 Users" route={paths.CAMPAIGN.SUNRISE25USERS} {...props} />
        </SidebarCategory>
        {/*Control Panel*/}
        {/* <SidebarCategory title="Control Panel" icon="layers" user={props.user} route={paths.CONTROL_PANEL.BASE} sidebarCollapse={sidebarCollapse}> */}
        <SidebarLink title="Assign" route={paths.CONTROL_PANEL.ASSIGN} {...props} />
        <SidebarLink title="Assigned" route={paths.CONTROL_PANEL.ASSIGNED} {...props} />
        <SidebarLink title="Modules" route={paths.CONTROL_PANEL.MODULE} {...props} />
        <SidebarLink title="Learning Paths" route={paths.CONTROL_PANEL.LEARNING_PATH} {...props} />
        <SidebarLink
          title="Baseline Threshold"
          route={paths.CONTROL_PANEL.BASELINE_THRESHOLD}
          {...props}
        />
        {/* </SidebarCategory> */}
        {/* <SidebarCategory title="Data Star" icon="layers" user={props.user} route={paths.DATA_STAR.BASE} sidebarCollapse={sidebarCollapse}> */}
        <SidebarLink title="Dashboard" route={paths.DATA_STAR.DASHBOARD} {...props} />
        <SidebarLink title="Programs" route={paths.DATA_STAR.PROGRAMS} {...props} />
        <SidebarLink title="Applicants" route={paths.DATA_STAR.APPLICANTS} {...props} />
        {/* </SidebarCategory> */}
        <SidebarCategory
          title="Cads AI"
          icon="layers"
          route={paths.CADS_AI.BASE}
          user={props.user}
          sidebarCollapse={sidebarCollapse}
        >
          {/*Base*/}
          <SidebarLink title="Dashboard" route={paths.CADS_AI.DASHBOARD} {...props} />
          <SidebarLink title="Users" route={paths.CADS_AI.USERS} {...props} />
          <SidebarLink title="Organization" route={paths.CADS_AI.ORGANIZATION} {...props} />
          <SidebarLink title="Employees" route={paths.CADS_AI.EMPLOYEES} {...props} />
          <SidebarLink title="Order History" route={paths.CADS_AI.ORDER_HISTORY} {...props} />
          <SidebarLink title="DDO" route={paths.CADS_AI.DDO} {...props} />
          <SidebarLink title="Help" route={paths.CADS_AI.HELP} {...props} />
          <SidebarLink title="Manage Email" route={paths.CADS_AI.EMAIL} {...props} />
          {/*Manage Data Star*/}
          <SidebarCategory
            title="Manage Data Star"
            route={paths.CADS_AI.DATA_STAR.BASE}
            user={props.user}
            sidebarCollapse={sidebarCollapse}
          >
            <SidebarLink title="Dashboard" route={paths.CADS_AI.DATA_STAR.DASHBOARD} {...props} />
            <SidebarLink title="Programs" route={paths.CADS_AI.DATA_STAR.PROGRAMS} {...props} />
            <SidebarLink title="Applicants" route={paths.CADS_AI.DATA_STAR.APPLICANTS} {...props} />
            <SidebarLink title="Sponsors" route={paths.CADS_AI.DATA_STAR.SPONSORS} {...props} />
            <SidebarLink title="DS Cohort" route={paths.CADS_AI.DATA_STAR.DS_COHORT} {...props} />
          </SidebarCategory>
          {/*Manage Learning */}
          <SidebarCategory
            title="Manage Learning"
            route={paths.CADS_AI.LEARNING.BASE}
            user={props.user}
            sidebarCollapse={sidebarCollapse}
          >
            <SidebarLink
              title="Learning Paths"
              route={paths.CADS_AI.LEARNING.LEARNING_PATH}
              {...props}
            />
            <SidebarLink title="Modules" route={paths.CADS_AI.LEARNING.MODULE} {...props} />
            <SidebarLink title="Courses" route={paths.CADS_AI.LEARNING.COURSE} {...props} />
            <SidebarLink
              title="Training Providers"
              route={paths.CADS_AI.LEARNING.TRAINING_PROVIDERS}
              {...props}
            />
            <SidebarLink title="Assign" route={paths.CADS_AI.LEARNING.ASSIGN} {...props} />
            <SidebarLink title="Assigned" route={paths.CADS_AI.LEARNING.ASSIGNED} {...props} />
          </SidebarCategory>
          {/*Mange Home*/}
          <SidebarCategory
            title="Manage Home"
            route={paths.CADS_AI.HOME.BASE}
            user={props.user}
            sidebarCollapse={sidebarCollapse}
          >
            <SidebarLink title="Announcements" route={paths.CADS_AI.HOME.ANNOUNCEMENT} {...props} />
            <SidebarLink title="Notifications" route={paths.CADS_AI.HOME.NOTIFICATION} {...props} />
            <SidebarLink title="Articles" route={paths.CADS_AI.HOME.ARTICLE} {...props} />
            <SidebarLink title="Events" route={paths.CADS_AI.HOME.EVENTS} {...props} />
            <SidebarLink title="Sponsorship" route={paths.CADS_AI.HOME.SPONSORSHIP} {...props} />
            <SidebarLink title="Q&A" route={paths.CADS_AI.HOME.QA} {...props} />
            <SidebarLink title="Spam Requests" route={paths.CADS_AI.HOME.SPAM_REQUESTS} {...props} />
          </SidebarCategory>
          {/*Mange Opportunities*/}
          <SidebarCategory
            title="Manage Opportunities"
            route={paths.CADS_AI.OPPORTUNITIES.BASE}
            user={props.user}
            sidebarCollapse={sidebarCollapse}
          >
            <SidebarLink title="Jobs" route={paths.CADS_AI.OPPORTUNITIES.JOBS} {...props} />
            <SidebarLink title="Projects" route={paths.CADS_AI.OPPORTUNITIES.PROJECTS} {...props} />
          </SidebarCategory>
        </SidebarCategory>
      </ul>
    </div>
  );
}

SidebarContent.propTypes = {
  onClick: PropTypes.func,
  sidebarCollapse: PropTypes.bool,
  user: PropTypes.object.isRequired,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
  onClick: () => {},
};

export default SidebarContent;
