import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonToolbar,
  Card,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import { Profile } from './user-details/ProfileCard';
import { HardSkills } from './user-details/HardSkills';
import { downloadFileFromUrl } from '@/shared/helpers';
import { AssessmentTab } from './user-details/AssessmentTab';
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon';
import { useUsersUIContext } from '../UsersUIProvider';
import api from '@/utils/apiClient';
import SmartSkillsTabs from './user-details/SmartSkillsTab';
import DownloadIcon from 'mdi-react/DownloadIcon';
import { LearningPath } from './user-details/learning-path/LearningPath';

const skillList = [
  {
    skills: [
      { level: -1, skillId: 2, skillName: 'Python Programming' },
      { level: -1, skillId: 5, skillName: 'Excel' },
      { level: -1, skillId: 8, skillName: 'Statistics' },
      { level: -1, skillId: 11, skillName: 'SQL' },
      { level: -1, skillId: 15, skillName: 'Data Storytelling & Presentation Skill' },
      { level: -1, skillId: 18, skillName: 'Dashboards' },
      { level: -1, skillId: 21, skillName: 'Visualizations' },
    ],
  },
  {
    skills: [
      { level: -1, skillId: 2, skillName: 'Python Programming' },
      { level: -1, skillId: 5, skillName: 'Excel' },
      { level: -1, skillId: 8, skillName: 'Statistics' },
      { level: -1, skillId: 11, skillName: 'SQL' },
      { level: -1, skillId: 15, skillName: 'Data Storytelling & Presentation Skill' },
      { level: -1, skillId: 18, skillName: 'Dashboards' },
      { level: -1, skillId: 21, skillName: 'Visualizations' },
      { level: -1, skillId: 30, skillName: 'Data Cleansing/Preparation' },
    ],
  },
];

const learningPaths = [
  {
    title: 'Associate Enterprise Data Analyst',
    imageUrl:
      'https://general-assets.sgp1.digitaloceanspaces.com/learning-path-images/AEDA/aeda.png',
    description:
      'Associate Enterprise Data Analysts Are Multilingual. Associate Enterprise Data Analyst is a 17-day training program that provides analysts with the skillsets and tools required for efficient data analysis using Python.',
    progress: '50%',
  },
  {
    title: 'Associate Enterprise Data Analyst',
    imageUrl:
      'https://general-assets.sgp1.digitaloceanspaces.com/learning-path-images/AEDA/aeda.png',
    description:
      'Associate Enterprise Data Analysts Are Multilingual. Associate Enterprise Data Analyst is a 17-day training program that provides analysts with the skillsets and tools required for efficient data analysis using Python.',
    progress: '47%',
  },
  {
    title: 'Associate Enterprise Data Analyst',
    imageUrl:
      'https://general-assets.sgp1.digitaloceanspaces.com/learning-path-images/AEDA/aeda.png',
    description:
      'Associate Enterprise Data Analysts Are Multilingual. Associate Enterprise Data Analyst is a 17-day training program that provides analysts with the skillsets and tools required for efficient data analysis using Python.',
    progress: '78%',
  },
  {
    title: 'Associate Enterprise Data Analyst',
    imageUrl:
      'https://general-assets.sgp1.digitaloceanspaces.com/learning-path-images/AEDA/aeda.png',
    description:
      'Associate Enterprise Data Analysts Are Multilingual. Associate Enterprise Data Analyst is a 17-day training program that provides analysts with the skillsets and tools required for efficient data analysis using Python.',
    progress: '78%',
  },
  {
    title: 'Associate Enterprise Data Analyst',
    imageUrl:
      'https://general-assets.sgp1.digitaloceanspaces.com/learning-path-images/AEDA/aeda.png',
    description:
      'Associate Enterprise Data Analysts Are Multilingual. Associate Enterprise Data Analyst is a 17-day training program that provides analysts with the skillsets and tools required for efficient data analysis using Python.',
    progress: '78%',
  },
  {
    title: 'Associate Enterprise Data Analyst',
    imageUrl:
      'https://general-assets.sgp1.digitaloceanspaces.com/learning-path-images/AEDA/aeda.png',
    description:
      'Associate Enterprise Data Analysts Are Multilingual. Associate Enterprise Data Analyst is a 17-day training program that provides analysts with the skillsets and tools required for efficient data analysis using Python.',
    progress: '78%',
  },
  {
    title: 'Associate Enterprise Data Analyst',
    imageUrl:
      'https://general-assets.sgp1.digitaloceanspaces.com/learning-path-images/AEDA/aeda.png',
    description:
      'Associate Enterprise Data Analysts Are Multilingual. Associate Enterprise Data Analyst is a 17-day training program that provides analysts with the skillsets and tools required for efficient data analysis using Python.',
    progress: '78%',
  },
  {
    title: 'Associate Enterprise Data Analyst',
    imageUrl:
      'https://general-assets.sgp1.digitaloceanspaces.com/learning-path-images/AEDA/aeda.png',
    description:
      'Associate Enterprise Data Analysts Are Multilingual. Associate Enterprise Data Analyst is a 17-day training program that provides analysts with the skillsets and tools required for efficient data analysis using Python.',
    progress: '78%',
  },
  {
    title: 'Associate Enterprise Data Analyst',
    imageUrl:
      'https://general-assets.sgp1.digitaloceanspaces.com/learning-path-images/AEDA/aeda.png',
    description:
      'Associate Enterprise Data Analysts Are Multilingual. Associate Enterprise Data Analyst is a 17-day training program that provides analysts with the skillsets and tools required for efficient data analysis using Python dfsafdsaf.',
    progress: '78%',
  },
  {
    title: 'Associate Enterprise Data Analyst',
    imageUrl:
      'https://general-assets.sgp1.digitaloceanspaces.com/learning-path-images/AEDA/aeda.png',
    description:
      'Associate Enterprise Data Analysts Are Multilingual. Associate Enterprise Data Analyst is a 17-day training program that provides analysts with the skillsets and tools required for efficient data analysis using Python.',
    progress: '78%',
  },
];

const UserView = ({ id }) => {
  const [activeTab, setActiveTab] = useState('profile');
  const [activeTabAssessment, setActiveAssessmentTab] = useState('assessmentTo');
  const [isAssessmentsRefresh, setIsAssessmentsRefresh] = useState(true);
  const [userDetails, setUserDetails] = useState({});
  const [smartSkillsData, setSmartSkillsData] = useState({});
  const userUIContext = useUsersUIContext();

  const downloadResume = async () => {
    const { data: resumeData } = await api.cadsAIUsers.getCadsAIExportResume(id);
    if (resumeData.data?.resumeFile) downloadFileFromUrl(resumeData.data.resumeFile);
  };

  const getUserDetails = async (userId) => {
    try {
      const { data: res, isSuccess } = await api.cadsAIUsers.getCadsAIUsersById(userId);
      if (isSuccess) {
        setUserDetails({ ...res.data, learningPaths, skillList });
      } else {
        throw new Error('Error while getting user details');
      }
    } catch (e) {
      console.error(e.message ?? 'Error while getting user details');
    }
  };

  const getUserSmartSkillsResult = async (userId) => {
    try {
      const { data: res, isSuccess } = await api.cadsAIUsers.getUserSmartSkillsResult(userId);
      if (isSuccess) {
        setSmartSkillsData(res?.data?.factors);
      } else {
        throw new Error('Error while getting user details');
      }
    } catch (e) {
      console.error(e.message ?? 'Error while getting user details');
    }
  };

  useEffect(() => {
    setActiveTab('profile');
    getUserDetails(id);
    getUserSmartSkillsResult(id);
  }, []);

  const profileVisitTabs = [
    {
      name: 'Profile',
      value: 'profile',
    },
    {
      name: 'Hard Skills',
      value: 'hardSkills',
    },
    {
      name: 'Smart Skills',
      value: 'smartSkills',
    },
    {
      name: 'Assessments',
      value: 'assessment',
    },
    {
      name: 'Learning Path',
      value: 'learningPath',
    },
  ];

  const assessmentTabs = [
    {
      name: 'Assigned',
      value: 'assessmentTo',
    },
    {
      name: 'Completed',
      value: 'completed',
    },
  ];

  const toggleAssessment = (tab) => {
    if (activeTabAssessment !== tab) {
      let update = { activeTabAssessment: tab };
      setIsAssessmentsRefresh(false);
      setActiveAssessmentTab(update.activeTabAssessment);
    }
  };

  const toggle = (tab) => {
    if (activeTab !== tab) {
      let update = { activeTab: tab };
      if (tab === 'profile') {
        update.isProfileRefresh = true;
      } else {
        update.isSkillsRefresh = true;
      }
      setActiveTab(update.activeTab);
    }
  };
  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <div className="profile__card tabs tabs--bordered-bottom p-2">
          <div className="tabs__wrap mt-2">
            <div>
              <ButtonToolbar className="button-placement">
                <ArrowLeftIcon color="primary" outline onClick={() => userUIContext.openList()}>
                  Back To Users List
                </ArrowLeftIcon>
              </ButtonToolbar>
              <div className="dashboard__info-title">
                <h2 className="page-title mb-2">{userDetails?.name ?? 'Username'}</h2>
                <span className="bread-crumb-title">{`Users / ${
                  userDetails?.name ?? 'Username'
                }`}</span>
              </div>
            </div>
            <Nav tabs>
              {profileVisitTabs.map((opportunityTab, index) => {
                return (
                  <NavItem key={index}>
                    <NavLink
                      className={classnames({
                        active: activeTab === opportunityTab?.value,
                      })}
                      onClick={() => {
                        toggle(opportunityTab?.value);
                      }}
                    >
                      {opportunityTab.name}
                    </NavLink>
                  </NavItem>
                );
              })}
              <div className="ml-auto">
                <Button
                  onClick={downloadResume}
                  color="primary"
                  disabled={!userDetails.isResumePresent}
                  className="upload-resume curve-gray-button p-2"
                >
                  <DownloadIcon />
                  <span>Download Resume</span>
                </Button>
              </div>
            </Nav>
            <TabContent className="tabs__opportunities overflow-hidden" activeTab={activeTab}>
              <TabPane tabId="profile">
                {activeTab === 'profile' && <Profile userDetails={userDetails} />}
              </TabPane>

              <TabPane tabId="hardSkills">
                {activeTab === 'hardSkills' && <HardSkills userDetails={userDetails} />}
              </TabPane>

              <TabPane tabId="smartSkills">
                {activeTab === 'smartSkills' && smartSkillsData ? (
                  <SmartSkillsTabs smartSkillsData={smartSkillsData} />
                ): <h5 className='text-center mt-3'>No smart skills available !</h5>
              }
              </TabPane>

              <TabPane tabId="assessment">
                {activeTab === 'assessment' && (
                  <>
                    <Row className={'p-0 m-0 tabs-sub'}>
                      <div className="tabs__wrap pl-0 pr-0 upload-sub-tabs">
                        <Nav className={'pl-0 m-0 '}>
                          {assessmentTabs.map((tabs, index) => {
                            return (
                              <NavItem key={index} className={'p-2 cursor_point'}>
                                <NavLink
                                  className={classnames({
                                    active: activeTabAssessment === tabs.value,
                                  })}
                                  onClick={() => {
                                    toggleAssessment(tabs.value);
                                  }}
                                >
                                  {tabs.name}
                                </NavLink>
                              </NavItem>
                            );
                          })}
                        </Nav>
                      </div>
                    </Row>

                    {activeTabAssessment === 'completed' && (
                      <>
                        {isAssessmentsRefresh ? (
                          <div className="panel__refresh  loader_center">
                            <LoadingIcon />
                          </div>
                        ) : null}
                        <AssessmentTab userDetails={userDetails} activeTab={activeTabAssessment} />
                      </>
                    )}
                    {activeTabAssessment === 'assessmentTo' && (
                      <>
                        <AssessmentTab userDetails={userDetails} activeTab={activeTabAssessment} />
                      </>
                    )}
                  </>
                )}
              </TabPane>

              <TabPane tabId="learningPath">
                {activeTab === 'learningPath' && (
                  <LearningPath
                    skillList={userDetails?.skillList}
                    learningPaths={userDetails?.learningPaths}
                  />
                )}
              </TabPane>
            </TabContent>
          </div>
        </div>
      </Card>
    </Col>
  );
};

export default UserView;
