import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col, Modal, Row } from 'reactstrap';
import LoadingIcon from 'mdi-react/LoadingIcon';
import Select from 'react-select';
import { getApplicantCount, uncheckAllSelectedApplicants } from '../../redux/actions';
import api from '@/utils/apiClient';

export const ApplicantUpdateStatusDialog = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state, shallowEqual);
  const checkedApplicantIds = useSelector(
    (state) => state.applicants.checkedApplicantIds,
    shallowEqual,
  );

  const [status, setStatus] = useState({
    value: 1,
    label: 'Review',
  });
  const [modal, setModal] = useState({
    isOpen: show,
    hide: onHide,
    loading: false,
    updateStatusSuccess: false,
    updateStatusError: false,
  });

  const handleUpdateStatus = async () => {
    setModal({
      ...modal,
      loading: true,
    });
    const response = await api.applicants.updateStatusForProgramApplicant(
      checkedApplicantIds,
      status.value,
    );
    let updatedModal = {};
    if (response.isSuccess) {
      updatedModal = {
        loading: false,
        updateStatusSuccess: true,
      };
      dispatch(getApplicantCount({ status: true }));
    } else {
      updatedModal = {
        loading: false,
        updateStatusError: true,
      };
    }
    setModal({ ...modal, ...updatedModal });
  };

  const [applicantsStatusOption, setApplicantsStatusOption] = useState([]);

  const getProgramApplicationStatusOptions = async () => {
    try {
      const { data: res, isSuccess } = await api.applicants.getProgramApplicationStatusOptions();
      if (isSuccess) {
        setApplicantsStatusOption(
          res.data.map((option) => ({ value: option.id, label: option.name })),
        );
      } else {
        throw new Error('error while fetching applicant status');
      }
    } catch (error) {
      console.error('Error inside applicant type', error.message);
    }
  };

  useEffect(() => {
    getProgramApplicationStatusOptions();
  }, []);

  return (
    <Modal
      isOpen={show}
      toggle={modal.hide}
      onExit={() => dispatch(uncheckAllSelectedApplicants())}
      keyboard={false}
      backdrop={'static'}
      modalClassName={`${rtl.direction}-support`}
      className="modal-dialog--primary modal-dialog--header"
    >
      <div className="modal__header">
        <button
          className="lnr lnr-cross modal__close-btn"
          aria-label="modal__close-btn"
          type="button"
          onClick={modal.hide}
        />
        <h4 className="text-modal  modal__title">Edit status</h4>
      </div>
      <div>
        <div className="ml-4">
          {modal.loading && (
            <div>
              <div className="panel__refresh position-relative" style={{ height: 75, width: 20 }}>
                <LoadingIcon />
                <div style={{ width: 200, padding: '23px 30px' }}>Updating status...</div>
              </div>
            </div>
          )}
          {(modal.updateStatusSuccess || !!modal.updateStatusError) && (
            <>
              <table className="mt-3">
                <tr>
                  <td>
                    {modal.updateStatusSuccess ? (
                      <span className="lnr lnr-thumbs-up modal__title-icon success" />
                    ) : (
                      <span className="lnr lnr-cross-circle modal__title-icon error" />
                    )}
                  </td>
                  <td className="pl-2">
                    {modal.updateStatusSuccess
                      ? 'Applicant Status Updated successfully'
                      : 'Error Performing Applicant Status Update!'}
                  </td>
                </tr>
              </table>
            </>
          )}
        </div>
        <Row>
          <Col md={12} lg={12}>
            <Card className="pb-0">
              <CardBody>
                <div className="form-wrapper">
                  <div className="form">
                    <div className="form__form-group">
                      <span className="form__form-group-label">Status</span>
                      <div className="form__form-group-field">
                        <Select
                          name="activateOrDeactiveStatus"
                          value={status}
                          onChange={setStatus}
                          autosize={true}
                          options={applicantsStatusOption}
                          clearable={false}
                          className="react-select"
                          placeholder={'Select Status'}
                          classNamePrefix="react-select"
                        />
                      </div>
                    </div>
                    <ButtonToolbar className="modal__footer btn-toolbar">
                      <>
                        <Button color="primary" type="submit" onClick={handleUpdateStatus}>
                          Submit
                        </Button>
                        <Button
                          color="secondary"
                          type="button"
                          className="modal_cancel btn btn-secondary"
                          onClick={() => modal.hide()}
                        >
                          Close
                        </Button>
                      </>
                    </ButtonToolbar>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};
