import { useEffect } from 'react';
import Highcharts from 'highcharts';
import Tree from 'highcharts/modules/treemap';
import exporting from 'highcharts/modules/exporting';
import data from 'highcharts/modules/data';
import Heatmap from 'highcharts/modules/heatmap';

Tree(Highcharts);
data(Highcharts);
Heatmap(Highcharts);
exporting(Highcharts);

const SkillsTrend = ({ skillsTrendData, chartData }) => {
  const getEmployeeData = (skillName, color) => {
    let employeeLength = 0;
    let employeeData = '';
    skillsTrendData
      ?.filter((res) => res.skillName === skillName)
      ?.map((data) => {
        if (data.employeeVMs.length > 0) {
          employeeLength = data.employeeVMs.length;
          data.employeeVMs.forEach((emp) => {
            let jobTitle = emp.level !== null ? emp.level : '-';
            employeeData +=
              "<tr class='trTag'><td><b>" +
              emp.name +
              "</b></td><td class='position'>" +
              jobTitle +
              '</td></tr>';
          });
        }
        return '';
      });
    return (
      "<b class='trTag'>" +
      employeeLength +
      " People with <span class='series-name' style='color:" +
      color +
      "'>" +
      skillName +
      '</span></b><br/><table>' +
      employeeData +
      '</table>'
    );
  };

  const initialChart = () => {
    Highcharts.chart(
      'SkillsTrend',
      {
        series: [
          {
            color: '#42bd99',
            name: 'Data Literacy Skills',
            type: 'treemap',
            layoutAlgorithm: 'squarified',
            data: chartData,
          },
          {
            color: '#589bd6',
            name: 'Other Skills',
            type: 'treemap',
            layoutAlgorithm: 'squarified',
            data: chartData,
          },
        ],
        title: {
          text: null,
        },
        tooltip: {
          useHTML: true,
          formatter: function (e) {
            if (!this.point.isDept) {
              let empldata = getEmployeeData(this.point.name, this.point.color);
              return "<div class = 'cDiv'>" + empldata + '</div>';
            } else {
              return "<div class = 'cDiv'>" + this.point.name + '</div>';
            }
          },
          padding: 20,
          borderRadius: 10,
          backgroundColor: 'white',
          borderColor: '#fff',
          animation: false,
          snap: 40,
          outside: false,
        },
        credits: {
          enabled: false,
        },
        exporting: {
          enabled: true,
        },
        legend: {
          align: 'left',
          symbolHeight: 12,
          symbolWidth: 12,
          symbolRadius: 2,
        },

        plotOptions: {
          series: {
            cursor: 'pointer',
            dataLabels: {
              useHTML: true,
              align: 'center',
              enabled: true,
              overflow: 'none',
              formatter: function () {
                if (!this.point.isDept) {
                  return (
                    "<div><span style='font-size:15px;font-weight:100;'>" +
                    this.point.value +
                    '</span><br>' +
                    this.point.name +
                    '</div>'
                  );
                } else {
                  return this.point.name;
                }
              },
            },
          },
          treemap: {
            showInLegend: true,
            events: {
              legendItemClick: function (event) {
                if (!event.target.visible) {
                  series.forEach((s) => s.setVisible());
                }

                return false;
              },
            },
          },
        },
      },
      function (chart) {
        if (chart.series[0].data.length <= 0) {
          chart.renderer
            .text('<h6>No Data Available</h6>', 425, 200)
            .css({
              color: '#2e485c',
              fontSize: '12px',
            })
            .add();
        }
      },
    );
  };

  useEffect(() => {
    initialChart();
  }, [chartData]);

  return <div id="SkillsTrend" className="SkillsTrend" />;
};

export default SkillsTrend;
