import React, { useState } from 'react';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';

const renderOtherDetails = (learningPath) => (
  <table className="project-summary__info">
    <tr>
      <th>Summary</th>
      <td>{learningPath?.summary}</td>
    </tr>
    <tr>
      <th>Tags Json</th>
      <td>{learningPath?.tagsJson}</td>
    </tr>
    <tr>
      <th>Code</th>
      <td>{learningPath?.learningPathType.code}</td>
    </tr>
    <tr>
      <th>Notes</th>
      <td>{learningPath?.description}</td>
    </tr>
  </table>
);

const renderModuleView = (learningPath) => (
  <table className="project-summary__info table">
    <tr>
      <th>Order</th>
      <th className="pl-3">Module</th>
      <th className="pl-3">Is Optional</th>
    </tr>
    {learningPath?.modules
      ?.sort((a, b) => a.sortOrder - b.sortOrder)
      .map((item) => (
        <>
          <tr>
            <td>{item.sortOrder}</td>
            <td className="pl-3">{item.title}</td>
            <td className="pl-4">{item.isOptional ? 'Yes' : 'No'}</td>
          </tr>
        </>
      ))}
  </table>
);

const renderBasicDetails = (learningPath) => (
  <table className="project-summary__info">
    <tr>
      <th>Full Title</th>
      <td>{learningPath?.fullTitle}</td>
    </tr>
    <tr>
      <th style={{ width: 132 }}>Short Title</th>
      <td>{learningPath?.shortTitle}</td>
    </tr>
    <tr>
      <th>Groups</th>
      <td>{learningPath?.learningPathGroup.title}</td>
    </tr>
    <tr>
      <th>Skills</th>
      <td>{learningPath?.learningPathSkills?.map((item) => item.title).join(', ') || '-'}</td>
    </tr>
    <tr>
      <th>Other Skills</th>
      <td>{learningPath?.learningPathOtherSkills?.map((item) => item.title).join(', ') || '-'}</td>
    </tr>
  </table>
);

export default function ({ learningPath }) {
  const tabs = React.useMemo(
    () => ({
      BASIC_DEATILS: {
        id: 'BASIC_DEATILS',
        label: 'Basic Details',
        renderTab: () => renderBasicDetails(learningPath),
      },
      OTHER_DEATILS: {
        id: 'OTHER_DEATILS',
        label: 'Other Details',
        renderTab: () => renderOtherDetails(learningPath),
      },
      MODULES: {
        id: 'MODULES',
        label: 'Associated Modules',
        renderTab: () => renderModuleView(learningPath),
      },
    }),
    [learningPath],
  );

  const [activeTab, setActiveTab] = useState(tabs.BASIC_DEATILS.id);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Row className="p-1">
      <Col>
        <div className="tabs tabs--bordered-bottom">
          <div className="tabs__wrap">
            <Nav tabs>
              {Object.keys(tabs).map((key) => (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === tabs[key].id })}
                    onClick={() => toggle(tabs[key].id)}
                  >
                    {tabs[key].label}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={activeTab}>{tabs[activeTab].renderTab()}</TabPane>
            </TabContent>
          </div>
        </div>
      </Col>
    </Row>
  );
}
