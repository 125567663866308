import React, { createContext, useContext } from 'react';

const SpamRequestsUIContext = createContext();

export function useSpamRequestsUIContext() {
  return useContext(SpamRequestsUIContext);
}

export function SpamRequestsUIProvider({ spamRequestUIEvents, children }) {
  const value = {
    openViewSpamDialog: spamRequestUIEvents.openViewSpamDialog,
    openUpdateSpamDialog: spamRequestUIEvents.openUpdateSpamDialog,
  };

  return <SpamRequestsUIContext.Provider value={value}>{children}</SpamRequestsUIContext.Provider>;
}
