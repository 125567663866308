import React, { useRef } from 'react';
import { Button } from 'reactstrap';
import Select from 'react-select';
import TableHead from '@material-ui/core/TableHead';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setDomainFilters } from '../../redux/actions';

export function DomainTableSearchBar() {
  const dispatch = useDispatch();
  //TODO: replace data.length with totalRecords throughout the project
  const totalDomainCount = useSelector((state) => state.domains?.data?.length, shallowEqual);
  const [isSearched, setIsSearched] = React.useState(false);
  const [searchStatus, setSearchStatus] = React.useState([{ value: null, label: 'All' }]);
  const searchRef = useRef(null);

  const handleSearch = () => {
    setIsSearched(true);
    dispatch(
      setDomainFilters({
        searchText: searchRef?.current?.value,
        status: searchStatus.value,
        pageNumber: 1,
      }),
    );
  };

  const handleClear = () => {
    if (searchRef?.current?.value) searchRef.current.value = '';
    setIsSearched(false);
    dispatch(setDomainFilters({ searchText: '', status: null }));
  };

  return (
    <TableHead>
      <div className="table__search">
        <div className="table__search_item">
          <b>Domain Name</b>
          <div className="form">
            <div className="form__form-group">
              <div className="form__form-group-field">
                <div className="form__form-group-icon" style={{ height: 37 }}>
                  <MagnifyIcon />
                </div>
                <input
                  ref={searchRef}
                  className="search-bar-search-text"
                  name="searchText"
                  type="text"
                  placeholder="Search by Domain Name"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table__search_item">
          <b>Status</b>
          <div style={{ minWidth: 200 }}>
            <Select
              name="status"
              value={searchStatus}
              onChange={setSearchStatus}
              autosize={true}
              options={[
                { value: null, label: 'All' },
                { value: true, label: 'Active' },
                { value: false, label: 'Deactivated' },
              ]}
              clearable={false}
              className="react-select"
              placeholder={'Select Status'}
              classNamePrefix="react-select"
            />
          </div>
        </div>
        <div className="table__search_item mt-3">
          <Button color="primary" onClick={handleSearch}>
            {' '}
            Search{' '}
          </Button>
        </div>
        <div className="table__search_item mt-3">
          <Button color="secondary" onClick={handleClear} outline>
            Clear
          </Button>
        </div>
      </div>

      {isSearched && (
        <div className="ml-1">
          <span>Found {totalDomainCount} matches</span>
        </div>
      )}
    </TableHead>
  );
}
