import { paths } from '@/shared/helpers/paths';
import React, { Suspense } from 'react';
import Loading from '@/shared/components/Loading';
import { Redirect, Route, Switch } from 'react-router-dom';
import DSCohort from './DSCohort';
import Dashboard from './Dashboard';
import Applicants from './Applicants';
import Sponsors from './Sponsors';
import Programs from './Programs';

export default function ManageDataStar() {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        {
          <Redirect
            exact
            from={paths.CADS_AI.DATA_STAR.BASE}
            to={paths.CADS_AI.DATA_STAR.DASHBOARD}
          />
        }
        <Route path={paths.CADS_AI.DATA_STAR.SPONSORS} component={Sponsors} />
        <Route path={paths.CADS_AI.DATA_STAR.PROGRAMS} component={Programs} />
        <Route path={paths.CADS_AI.DATA_STAR.APPLICANTS} component={Applicants} />
        <Route path={paths.CADS_AI.DATA_STAR.DASHBOARD} component={Dashboard} />
        <Route path={paths.CADS_AI.DATA_STAR.DS_COHORT} component={DSCohort} />
      </Switch>
    </Suspense>
  );
}
