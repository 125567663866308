import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const fetchCustomerList = createAction('FETCH_CUSTOMER_LIST');
export const setCustomerList = createAction('SET_CUSTOMER_LIST');
export const setCustomerError = createAction('SET_CUSTOMER_ERROR');
export const setCustomerFilters = createAction('SET_CUSTOMER_FILTERS');

export const setCustomerOptionsList = createAction('SET_CUSTOMER_OPTIONS_LIST');

export const getCustomersList = (filter) => async (dispatch) => {
  const defaultError = 'Error occurred getting customers list';
  try {
    dispatch(fetchCustomerList());
    const { data, isSuccess } = await api.customers.getCustomerList(filter);
    if (isSuccess) dispatch(setCustomerList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setCustomerError(error));
    console.error(defaultError, error.message);
  }
};

export const getCustomersListOptions = () => async (dispatch) => {
  const defaultError = 'Error occurred getting customer options list';
  try {
    const { data, isSuccess } = await api.customers.getCustomerOptionsList();
    if (isSuccess) dispatch(setCustomerOptionsList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    console.error(defaultError, error.message);
  }
};
