import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const setCourseFetching = createAction('SET_COURSE_FETCHING');
export const setCourseError = createAction('SET_COURSE_ERROR');
export const setAssignCourseList = createAction('SET_ASSIGN_COURSE_LIST');
export const setAssignedCourseList = createAction('SET_ASSIGNED_COURSE_LIST');
export const toggleCheckedCourse = createAction('TOGGLE_CHECKED_COURSES');
export const uncheckAllCourse = createAction('UNCHECK_ALL_COURSES');

export const getCPCoursesList = (filter) => async (dispatch) => {
  const defaultError = `Error occurred getting course list`;
  dispatch(setCourseFetching());
  try {
    const { data, isSuccess } = await api.courses.getCourseList(filter);
    if (isSuccess)
      if (filter.assigned) dispatch(setAssignedCourseList(data));
      else dispatch(setAssignCourseList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setCourseError(error.message));
    console.error(defaultError, error.message);
  }
};

export const toggleCheckedCoursesById = (courseId) => (dispatch) => {
  dispatch(toggleCheckedCourse(courseId));
};

export const uncheckAllCourses = () => (dispatch) => {
  dispatch(uncheckAllCourse());
};
