import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, ButtonToolbar } from 'reactstrap';
import api from '@/utils/apiClient';
import { emptyStringPattern, YesNoOptions } from '@/shared/helpers';
import { FormField } from '@/shared/components/form/hook/FormField';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { SelectField } from '@/shared/components/form/Select';
import { CreatableSelectField } from '@/shared/components/form/CreatableSelectField';

const inputFieldsNames = [
  'id',
  'name',
  'description',
  'instructorEvaluationUrl',
  'meetingLinkUrl',
  'completionTimeInHours',
  'wooCommerceProductCode',
  'providerCourseCode',
  'slug',
  'ssoUrl',
  'tagline',
  'summary',
  'locationNotes',
];
const selectFieldsNames = [
  'trainingProvider',
  'contentProvider',
  'meetingType',
  'duration',
  'locationType',
  'method',
];
const yesNoFieldNames = [
  { name: 'isPlatformWide', value: false },
  { name: 'isMainVariant', value: false },
  { name: 'isPublic', value: true },
];
const dateFieldNames = ['startDate', 'endDate'];

const ModuleScheduleEditForm = ({ modal, isEdit, id }) => {
  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onChange' });
  //states
  const [state, setState] = React.useState({
    loading: false,
    fetchingModuleSchedule: false,
    newModuleScheduleAdded: false,
    errorMessage: '',
    isNew: true,
  });
  const [scheduleOptions, setScheduleOptions] = React.useState([]);

  const [trainingProviderOptions, setTrainingProviderOptions] = React.useState([]);
  const [contentProviderOptions, setContentProviderOptions] = React.useState([]);
  const [meetingTypeOptions, setMeetingTypeOptions] = React.useState([]);
  const [trainingDurationOptions, setTrainingDurationOptions] = React.useState([]);
  const [locationTypeOptions, setLocationTypeOptions] = React.useState([]);
  const [methodTypeOptions, setMethodTypeOptions] = React.useState([]);

  const [schedule = '', duration = ''] = watch(['schedule', 'duration']);
  React.useEffect(() => {
    if (duration.label === 'Not Set') setValue('durationDays', 0);
    else if (duration.__isNew__) {
      setValue('durationDays', duration?.value);
    } else setValue('durationDays', duration?.durationInDays);
  }, [duration]);

  const getTrainingProviderOptions = async () => {
    const { data: res, isSuccess } = await api.moduleSchedules.getTrainingProviderOptionList();
    if (isSuccess) {
      setTrainingProviderOptions(res?.data?.map((tp) => ({ value: tp.id, label: tp.name })) ?? []);
    }
  };

  const getContentProviderOptions = async () => {
    const { data: res, isSuccess } = await api.moduleSchedules.getContentProviderOptionList();
    if (isSuccess) {
      setContentProviderOptions(res?.data?.map((cp) => ({ value: cp.id, label: cp.name })) ?? []);
    }
  };

  const getMeetingTypeOptions = async () => {
    const { data: res, isSuccess } = await api.moduleSchedules.getMeetingTypeOptionList();
    if (isSuccess) {
      setMeetingTypeOptions(res?.data?.map((mt) => ({ value: mt.id, label: mt.name })) ?? []);
    }
  };

  const getTrainingDurationOptions = async () => {
    const { data: res, isSuccess } = await api.moduleSchedules.getTrainingDurationOptionList();
    if (isSuccess) {
      setTrainingDurationOptions(
        res?.data?.map((td) => ({ value: td.id, label: td.durationInDays ?? 0, ...td })) ?? [],
      );
    }
  };

  const getLocationTypeOptions = async () => {
    const { data: res, isSuccess } = await api.moduleSchedules.getDeliveryLocationTypeOptionList();
    if (isSuccess) {
      setLocationTypeOptions(res?.data?.map((lt) => ({ value: lt.id, label: lt.name })) ?? []);
    }
  };

  const getMethodTypeOptions = async () => {
    const { data: res, isSuccess } = await api.moduleSchedules.getDeliveryMethodTypeOptionList();
    if (isSuccess) {
      setMethodTypeOptions(res?.data?.map((mt) => ({ value: mt.id, label: mt.name })) ?? []);
    }
  };

  React.useEffect(() => {
    getTrainingProviderOptions();
    getContentProviderOptions();
    getMeetingTypeOptions();
    getTrainingDurationOptions();
    getLocationTypeOptions();
    getMethodTypeOptions();
  }, []);

  React.useEffect(() => {
    if (schedule?.__isNew__) {
      [...inputFieldsNames, ...selectFieldsNames, ...dateFieldNames].forEach((field) =>
        setValue(field, ''),
      );
      [...yesNoFieldNames].forEach((field) =>
        setValue(field.name, YesNoOptions[field.value ? 0 : 1]),
      );
      setValue('durationDays', 0);
      setValue('name', schedule.value);
    } else if (schedule?.id) {
      const { id: deliveriesId, name, notes, ...restOfDelivery } = schedule?.deliveries?.[0] ?? {};
      const selectedSchedule = { ...schedule, deliveriesId, ...restOfDelivery };
      inputFieldsNames.forEach((field) => setValue(field, selectedSchedule?.[field]));
      selectFieldsNames.forEach((field) =>
        field === 'duration'
          ? setValue(field, {
              value: selectedSchedule?.[field]?.id,
              label: selectedSchedule?.[field]?.durationInDays,
              ...selectedSchedule?.[field],
            })
          : setValue(field, {
              value: selectedSchedule?.[field]?.id,
              label: selectedSchedule?.[field]?.name,
              ...selectedSchedule?.[field],
            }),
      );
      yesNoFieldNames.forEach((field) =>
        setValue(field.name, YesNoOptions[selectedSchedule?.[field.name] ? 0 : 1]),
      );
      dateFieldNames.forEach((field) =>
        setValue(field, selectedSchedule?.[field]?.substr(0, 10) ?? ''),
      );
      setValue('durationDays', selectedSchedule?.duration?.durationInDays ?? 0);
    }
  }, [schedule]);

  const getScheduleOptions = async () => {
    const { data: res, isSuccess } = await api.moduleSchedules.getModuleScheduleList({ id });
    if (isSuccess) {
      setScheduleOptions(
        res.data?.map((schedule) => ({ value: schedule.id, label: schedule.name, ...schedule })) ??
          [],
      );
    } else {
      setScheduleOptions([]);
    }
  };

  React.useEffect(() => {
    if (id) {
      getScheduleOptions();
    }
  }, [id, isEdit]);

  const constructRequest = ({
    name,
    description,
    tagline,
    summary,
    slug,
    ssoUrl,
    instructorEvaluationUrl,
    meetingLinkUrl,
    wooCommerceProductCode,
    providerCourseCode,
    completionTimeInHours,
    locationNotes,
    trainingProvider,
    contentProvider,
    meetingType,
    duration,
    durationDays,
    locationType,
    method,
    isPlatformWide,
    isMainVariant,
    isPublic,
    startDate,
    endDate,
  }) => ({
    name,
    description,
    tagline,
    summary,
    slug,
    ssoUrl,
    instructorEvaluationUrl,
    meetingLinkUrl,
    wooCommerceProductCode,
    providerCourseCode,
    completionTimeInHours,
    trainingProviderId: trainingProvider.value,
    contentTypeId: contentProvider.value,
    meetingTypeId: meetingType.value,
    duration: {
      id: duration.__isNew__ ? null : duration.value,
      name: `${duration.label} day`,
      durationInDays: durationDays === 0 ? null : +durationDays,
    },
    isPlatformWide: isPlatformWide.value,
    isMainVariant: isMainVariant.value,
    salesStateId: 2,
    moduleDeliveryStateId: 2,
    moduleId: id,
    participantsMaterialsUrl: '',
    masterMaterialsUrl: '',
    delivery: {
      startDate,
      endDate,
      locationNotes,
      name: '',
      notes: '',
      isPublic: isEdit ? isPublic.value : true,
      testModeId: 1,
      method: { id: method.__isNew__ ? null : method.value, name: method.label },
      locationType: {
        id: locationType.__isNew__ ? null : locationType.value,
        name: locationType.label,
      },
    },
  });

  const onSubmit = async (moduleScheduleData, e) => {
    setState({ ...state, loading: true, isNew: schedule.__isNew__ });
    const reqData = constructRequest(moduleScheduleData);
    const defaultError = `Error occurred while ${
      !schedule.__isNew__ ? 'Updating' : 'Adding new'
    } schedule`;
    try {
      const { data: res, isSuccess } = !schedule.__isNew__
        ? await api.moduleSchedules.editModuleSchedule({
            id: schedule.id,
            ...reqData,
            delivery: {
              ...reqData.delivery,
              moduleVariantId: schedule.id,
              id: schedule.deliveries?.[0]?.id,
            },
          })
        : await api.moduleSchedules.addModuleSchedule(reqData);
      if (isSuccess) {
        setState({ ...state, newModuleScheduleAdded: true, loading: false });
        if (schedule.__isNew__) {
          setValue('schedule', '');
          getScheduleOptions();
        }
        if (duration.__isNew__ && res.data?.duration) {
          const duration = res.data.duration;
          setTrainingDurationOptions([
            ...trainingDurationOptions,
            { value: duration.id, label: duration.durationInDays, ...duration },
          ]);
        }
      } else {
        throw new Error(res?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({
        ...state,
        loading: false,
        newModuleScheduleAdded: false,
        errorMessage: error.message,
      });
    }
  };

  const onCancel = () => {
    modal.hide();
  };

  return (
    <div>
      {/*Add/Edit ModuleSchedule Form Status*/}
      <div className="ml-4">
        {state.loading && (
          <div>
            <div className="panel__refresh position-relative" style={{ height: 75, width: 20 }}>
              <LoadingIcon />
              <div style={{ width: 200, padding: '23px 40px' }}>
                {!state.isNew ? 'Updating' : 'Adding new'} schedule...
              </div>
            </div>
          </div>
        )}
        {(state.newModuleScheduleAdded || !!state.errorMessage) && (
          <table className="mt-3">
            <tr>
              <td>
                {state.newModuleScheduleAdded ? (
                  <span className="lnr lnr-thumbs-up modal__title-icon success" />
                ) : (
                  <span className="lnr lnr-cross-circle modal__title-icon error" />
                )}
              </td>
              <td className="pl-2">
                {state.newModuleScheduleAdded
                  ? `${
                      !schedule.__isNew__ ? 'Schedule Updated' : 'New Schedule Added'
                    } successfully`
                  : state.errorMessage}
              </td>
            </tr>
          </table>
        )}
      </div>

      {/*Add/Edit ModuleSchedule Form*/}
      <div className="modal__body add-edit-module-schedule">
        <form className={`form form--horizontal`} onSubmit={handleSubmit(onSubmit)}>
          <div className="form__form-group">
            <span className="form__form-group-label">Create/Select Schedule</span>
            <div className="form__form-group-field">
              <FormField
                control={control}
                name="schedule"
                component={CreatableSelectField}
                options={scheduleOptions}
                errors={errors}
                isAboveError
                rules={{ required: 'This is required field' }}
                placeholder="Please create or select schedule"
              />
            </div>
          </div>

          {schedule && (
            <>
              <div className="form__form-group">
                <span className="form__form-group-label">Name</span>
                <div className="form__form-group-field">
                  <FormField
                    name="name"
                    control={control}
                    component="input"
                    errors={errors}
                    rules={{
                      required: 'This is required field',
                      pattern: emptyStringPattern,
                    }}
                    defaultValue=""
                    isAboveError={true}
                    placeholder="Name of schedule"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Description</span>
                <div className="form__form-group-field">
                  <FormField
                    name="description"
                    control={control}
                    component="textarea"
                    defaultValue=""
                    placeholder="Write description about schedule"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Training Provider</span>
                <div className="form__form-group-field">
                  <FormField
                    control={control}
                    name="trainingProvider"
                    component={SelectField}
                    options={trainingProviderOptions}
                    placeholder="Select training provider"
                    rules={{ required: 'This is required field' }}
                    errors={errors}
                    isAboveError
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Content Provider</span>
                <div className="form__form-group-field">
                  <FormField
                    control={control}
                    name="contentProvider"
                    component={SelectField}
                    options={contentProviderOptions}
                    placeholder="Select content provider"
                    rules={{
                      validate: (cp) => (!cp?.value ? 'Please add at least one provider' : true),
                    }}
                    errors={errors}
                    isAboveError
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Is Platform Wide?</span>
                <div className="form__form-group-field">
                  <FormField
                    control={control}
                    name="isPlatformWide"
                    component={SelectField}
                    options={YesNoOptions}
                    defaultValue={YesNoOptions[1]}
                    rules={{ required: 'This is required field' }}
                    errors={errors}
                    isAboveError
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Instructor Evaluation URL</span>
                <div className="form__form-group-field">
                  <FormField
                    control={control}
                    name="instructorEvaluationUrl"
                    as="input"
                    placeholder="Enter instructor evaluation URL"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Meeting Link URL</span>
                <div className="form__form-group-field">
                  <FormField
                    name="meetingLinkUrl"
                    as="input"
                    placeholder="Enter meeting link URL"
                    control={control}
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Meeting Type</span>
                <div className="form__form-group-field">
                  <FormField
                    control={control}
                    name="meetingType"
                    component={SelectField}
                    options={meetingTypeOptions}
                    placeholder="Select meeting type"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Completion Time (In Hours)</span>
                <div className="form__form-group-field">
                  <FormField
                    name="completionTimeInHours"
                    control={control}
                    component="input"
                    type="number"
                    min={0}
                    defaultValue=""
                    placeholder="Enter completion time"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Woo Commerce Product Code</span>
                <div className="form__form-group-field">
                  <FormField
                    name="wooCommerceProductCode"
                    control={control}
                    component="input"
                    defaultValue=""
                    placeholder="Enter Product Code"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Provider Course Code</span>
                <div className="form__form-group-field">
                  <FormField
                    name="providerCourseCode"
                    control={control}
                    component="input"
                    defaultValue=""
                    placeholder="Enter Course Code"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Slug</span>
                <div className="form__form-group-field">
                  <FormField
                    name="slug"
                    control={control}
                    component="input"
                    defaultValue=""
                    placeholder="Enter slug"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">SSO URL</span>
                <div className="form__form-group-field">
                  <FormField
                    name="ssoUrl"
                    as="input"
                    placeholder="Enter sso url"
                    control={control}
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Tagline</span>
                <div className="form__form-group-field">
                  <FormField
                    name="tagline"
                    control={control}
                    component="input"
                    defaultValue=""
                    placeholder="Tagline for schedule"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Summary</span>
                <div className="form__form-group-field">
                  <FormField
                    name="summary"
                    control={control}
                    component="textarea"
                    defaultValue=""
                    placeholder="Write summary about schedule"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Is Main Variant?</span>
                <div className="form__form-group-field">
                  <FormField
                    control={control}
                    name="isMainVariant"
                    component={SelectField}
                    options={YesNoOptions}
                    defaultValue={YesNoOptions[1]}
                    rules={{ required: 'This is required field' }}
                    errors={errors}
                    isAboveError
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Create/Select Duration</span>
                <div className="form__form-group-field">
                  <FormField
                    control={control}
                    name="duration"
                    component={CreatableSelectField}
                    options={trainingDurationOptions}
                    errors={errors}
                    rules={{ required: 'This is required field' }}
                    isAboveError
                    placeholder="Create Or Select Duration"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Start Date</span>
                <div className="form__form-group-field">
                  <FormField
                    name="startDate"
                    type="date"
                    placeholder="Enter start date"
                    control={control}
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">End Date</span>
                <div className="form__form-group-field">
                  <FormField
                    name="endDate"
                    type="date"
                    placeholder="Enter end date"
                    control={control}
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Location Notes</span>
                <div className="form__form-group-field">
                  <FormField
                    name="locationNotes"
                    control={control}
                    component="input"
                    defaultValue=""
                    placeholder="Enter location notes"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Create/Select Location Type</span>
                <div className="form__form-group-field">
                  <FormField
                    control={control}
                    name="locationType"
                    component={CreatableSelectField}
                    options={locationTypeOptions}
                    placeholder="Create Or Select Location Type"
                    rules={{ required: 'This is required field' }}
                    errors={errors}
                    isAboveError
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Create/Select Method Type</span>
                <div className="form__form-group-field">
                  <FormField
                    control={control}
                    name="method"
                    component={CreatableSelectField}
                    options={methodTypeOptions}
                    placeholder="Create Or Select Method Type"
                    rules={{ required: 'This is required field' }}
                    errors={errors}
                    isAboveError
                  />
                </div>
              </div>
            </>
          )}

          <ButtonToolbar className="form__button-toolbar modal__footer">
            <Button disabled={state.loading} color="primary" type="submit">
              Submit
            </Button>
            <Button type="button" className="modal_cancel" onClick={onCancel}>
              Close
            </Button>
          </ButtonToolbar>
        </form>
      </div>
    </div>
  );
};

export default ModuleScheduleEditForm;
