import { httpMethod } from '@/shared/helpers/httpMethod.enum';
import { sendRequest } from '../base/ApiService';
import { cadsAITabs } from "@/shared/helpers/constants";

const CADS_API_ENDPOINT =
  process.env.REACT_APP_CADS_AI_API + process.env.REACT_APP_CADS_AI_LEARNING_PATH_ENDPOINT;

const assignEndPoints = {
  ASSIGN_LEARNING_PATH: `/assignLearningPath`,
  ASSIGN_COURSES: `/assignCourses`,
};

const assignmentLearningPathState = (learningPathIds, checkedItemIds, activeTab) => {
  const data = {
    learningPathId: learningPathIds,
    userDetailsIds: activeTab === cadsAITabs.USERS.id ? checkedItemIds : [],
    organizationIds: activeTab === cadsAITabs.ORGANIZATION.id ? checkedItemIds : [],
    organizationCohortIds: activeTab === cadsAITabs.COHORT.id ? checkedItemIds : []
  };
  return sendRequest({
    fullUrl: CADS_API_ENDPOINT + assignEndPoints.ASSIGN_LEARNING_PATH,
    method: httpMethod.POST,
    data: data,
  });
};

const assignmentCourseState = (courseId, checkedItemIds, activeTab) => {
  const data = {
    courseId: courseId,
    userDetailsIds: activeTab === cadsAITabs.USERS.id ? checkedItemIds : [],
    organizationIds: activeTab === cadsAITabs.ORGANIZATION.id ? checkedItemIds : [],
    organizationCohortIds: activeTab === cadsAITabs.COHORT.id ? checkedItemIds : [],
  };
  return sendRequest({
    fullUrl: CADS_API_ENDPOINT + assignEndPoints.ASSIGN_COURSES,
    method: httpMethod.POST,
    data: data,
  });
};

export default {
  assignmentLearningPathState,
  assignmentCourseState,
};
