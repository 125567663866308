import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { useForm } from 'react-hook-form';
import FormField from '@/shared/components/form/hook/FormField';
import { StepDefaultProps, StepPropTypes } from './Props';
import { extractFieldData, getStepFields } from '../index';

const StepThree = ({onSubmit, page, previousPage, data}) => {
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: {errors},
  } = useForm(extractFieldData(page, data));

  React.useEffect(() => {
    getStepFields(page).forEach((key) => setValue(key, data[key]));
  }, [data]);

  return (
      <form className="form wizard__form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form__form-group">
          <span className="form__form-group-label">Short Description</span>
          <div className="form__form-group-field">
            <FormField
                name="shortDescription"
                component="textarea"
                placeholder="You can start typing your note here"
                control={control}
                defaultValue=""
                errors={errors}
            rules={{ required: 'This is required field' }}
            isAboveError
          />
        </div>
        </div>
        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
          <Button
              color="primary"
              type="button"
              className="previous"
              onClick={() => previousPage(getValues())}
          >
            Back
          </Button>
          <Button color="primary" type="submit" className="next">
            Next
          </Button>
        </ButtonToolbar>
    </form>
  );
};

StepThree.propTypes = StepPropTypes;
StepThree.defaultProps = StepDefaultProps;

export default StepThree;
