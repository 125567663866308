import SmartSkills from '../SmartSkills';
import { Card, CardBody } from 'reactstrap';

const OverviewContainer = ({
  color,
  title,
  value,
  level,
  description,
  id,
  handleClick,
  isSelected,
}) => {
  return (
    <div className="col-lg-3 col-md-6">
      <Card className={'smart-skill-card'}>
        <CardBody className="smart-skill-container">
          <a
            style={{ cursor: 'pointer' }}
            onClick={() => {
              handleClick(id);
            }}
          >
            <div className="text-center">
              <div className="smart-skill-circle-center">
                <SmartSkills color={color} level={level} value={value} isSelected={isSelected} />
              </div>
              <div className="smart-skill-text" style={{ color: color }}>
                <b>{title}</b> <br />
                {description}
              </div>
            </div>
          </a>
        </CardBody>
      </Card>
    </div>
  );
};

export default OverviewContainer;
