import Select from 'react-select';
import React, { Fragment } from 'react';

const AssessmentStatsFilter = () => {
  return (
    <Fragment>
      <div className="table__search_item float-right pb-4">
        <span>User</span>
        <div style={{ width: '160px' }}>
          <Select
            name="user"
            value={{ value: -1, label: 'All Users' }}
            onChange={(selectedLevel) => console.log(selectedLevel)}
            options={[
              { value: 1, label: 'User 1' },
              { value: 2, label: 'User 2' },
            ]}
            className="react-select"
            placeholder={'Select User'}
            classNamePrefix="react-select"
          />
        </div>
      </div>
      <div className="table__search_item float-right pb-4">
        <span>Category</span>
        <div style={{ width: '160px' }}>
          <Select
            name="assessmentCategory"
            value={{ value: 1, label: 'Category 1' }}
            onChange={(selectedLevel) => console.log(selectedLevel)}
            options={[
              { value: 1, label: 'Category 1' },
              { value: 2, label: 'Category 2' },
            ]}
            className="react-select"
            placeholder={'Select Category'}
            classNamePrefix="react-select"
          />
        </div>
      </div>
      <div className="table__search_item float-right pb-4">
        <span>Assessment</span>
        <div style={{ width: '160px' }}>
          <Select
            name="assessmentName"
            value={{ value: 1, label: 'Assessment 1' }}
            onChange={(selectedLevel) => console.log(selectedLevel)}
            options={[
              { value: 1, label: 'Assessment 1' },
              { value: 2, label: 'Assessment 2' },
            ]}
            className="react-select"
            placeholder={'Select Assessment'}
            classNamePrefix="react-select"
          />
        </div>
      </div>
    </Fragment>
  );
};

export default AssessmentStatsFilter;
