import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { cadsAiDSCohortBaseUrl } from '../index';
import { DSCohortUIProvider } from './DSCohortUIProvider';
import { setDSCohortFilters, uncheckAllDSCohorts } from '../redux/actions';
import { useDispatch } from 'react-redux';
import { DSCohortCard } from './DSCohortCard';
import DSCohortEditDialog from './ds-cohort-add-edit-dialog/DSCohortEditDialog';
import DSCohortsViewDialog from './ds-cohort-view-dialog/DSCohortViewDialog';
import DSCohortDeleteDialog from './ds-cohort-delete-dialog/DSCohortDeleteDialog';

export function DSCohortPage({ history, match }) {
  const dispatch = useDispatch();

  //clean up
  React.useEffect(() => () => dispatch(uncheckAllDSCohorts()), []);

  const dsCohortUIEvents = {
    openViewDSCohortDialog: (id) => {
      history.push(`${cadsAiDSCohortBaseUrl}/${id}/view`);
    },
    openNewDSCohortDialog: () => {
      history.push(`${cadsAiDSCohortBaseUrl}/new`);
    },
    openEditDSCohortDialog: (id) => {
      history.push(`${cadsAiDSCohortBaseUrl}/${id}/edit`);
    },
    openDeleteDSCohortDialog: (id) => {
      history.push(`${cadsAiDSCohortBaseUrl}/${id}/delete`);
    },
  };

  return (
    <DSCohortUIProvider dsCohortUIEvents={dsCohortUIEvents}>
      <Switch>
        <Route path={`${cadsAiDSCohortBaseUrl}/new`}>
          {({ history, match }) => (
            <DSCohortEditDialog
              show={match != null}
              isEdit={false}
              onHide={() => {
                dispatch(setDSCohortFilters());
                history.push(cadsAiDSCohortBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${cadsAiDSCohortBaseUrl}/:id/edit`}>
          {({ history, match }) => (
            <DSCohortEditDialog
              show={match != null}
              isEdit={true}
              id={match?.params?.id}
              onHide={() => {
                dispatch(setDSCohortFilters());
                history.push(cadsAiDSCohortBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${cadsAiDSCohortBaseUrl}/:id/view`}>
          {({ history, match }) => (
            <DSCohortsViewDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                dispatch(setDSCohortFilters());
                history.push(cadsAiDSCohortBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${cadsAiDSCohortBaseUrl}/:id/delete`}>
          {({ history, match }) => (
            <DSCohortDeleteDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                dispatch(setDSCohortFilters());
                history.push(cadsAiDSCohortBaseUrl);
              }}
            />
          )}
        </Route>
      </Switch>
      <DSCohortCard />
    </DSCohortUIProvider>
  );
}
