import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loading from '@/shared/components/Loading';
import { paths } from '@/shared/helpers/paths';
import { SpamRequestsPage } from './components/SpamRequestsPage';

export const cadsAiSpamRequestsBaseUrl = paths.CADS_AI.HOME.SPAM_REQUESTS;

const SpamRequests = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path={cadsAiSpamRequestsBaseUrl} component={SpamRequestsPage} />
      </Switch>
    </Suspense>
  );
};

export default SpamRequests;
