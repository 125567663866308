import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { cadsAiTPBaseUrl } from '../index';
import { TrainingProviderUIProvider } from './TrainingProviderUIProvider';
import { TrainingProvidersCard } from './TrainingProvidersCard';
import TrainingProviderEditDialog from './training-provider-add-edit-dialog/TrainingProviderEditDialog';
import TrainingProviderViewDialog from './training-provider-view-dialog/TrainingProviderViewDialog';
import { setCadsAITrainingFilters, uncheckAllCadsAITraining } from '../redux/actions';
import { useDispatch } from 'react-redux';
import TrainingProviderDeactivateDialog from './training-provider-deactivate-dialog/TrainingProviderDeactivateDialog';

export function TrainingProvidersPage({ history }) {
  const dispatch = useDispatch();

  //clean up
  React.useEffect(() => () => dispatch(uncheckAllCadsAITraining()), []);

  const trainingProviderUIEvents = {
    openViewTrainingProviderDialog: (id) => {
      history.push(`${cadsAiTPBaseUrl}/${id}/view`);
    },
    openNewTrainingProviderDialog: () => {
      history.push(`${cadsAiTPBaseUrl}/new`);
    },
    openEditTrainingProviderDialog: (id) => {
      history.push(`${cadsAiTPBaseUrl}/${id}/edit`);
    },
    openDeactivateTrainingProviderDialog: (id) => {
      history.push(`${cadsAiTPBaseUrl}/${id}/deactivate`);
    },
  };

  return (
    <TrainingProviderUIProvider trainingProviderUIEvents={trainingProviderUIEvents}>
      <Switch>
        <Route path={`${cadsAiTPBaseUrl}/new`}>
          {({ history, match }) => (
            <TrainingProviderEditDialog
              show={match != null}
              isEdit={false}
              onHide={() => {
                dispatch(setCadsAITrainingFilters());
                history.push(cadsAiTPBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${cadsAiTPBaseUrl}/:id/edit`}>
          {({ history, match }) => (
            <TrainingProviderEditDialog
              show={match != null}
              isEdit={true}
              id={match?.params?.id}
              onHide={() => {
                dispatch(setCadsAITrainingFilters());
                history.push(cadsAiTPBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${cadsAiTPBaseUrl}/:id/deactivate`}>
          {({ history, match }) => (
            <TrainingProviderDeactivateDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                dispatch(setCadsAITrainingFilters());
                history.push(cadsAiTPBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${cadsAiTPBaseUrl}/:id/view`}>
          {({ history, match }) => (
            <TrainingProviderViewDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                history.push(cadsAiTPBaseUrl);
              }}
            />
          )}
        </Route>
      </Switch>
      <TrainingProvidersCard />
    </TrainingProviderUIProvider>
  );
}
