import React, { memo } from 'react';
import PageDetailsCollapse from './PageDetailsCollapse';
import PageQuestionsCollapse from './PageQuestionsCollapse';
import PageFooterCollapse from './PageFooterCollapse';

const PageContent = ({ page }) => {
  return (
    <>
      <PageDetailsCollapse {...page} />
      <PageQuestionsCollapse {...page} />
      <PageFooterCollapse {...page} />
    </>
  );
};

export default memo(PageContent);
