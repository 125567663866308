import React from 'react';
import { cadsAiApplicantsBaseUrl } from '../index';
import { ApplicantsUIProvider } from './ApplicantsUIProvider';
import { ApplicantsCard } from './ApplicantsCard';
import { setApplicantFilters, uncheckAllApplicants } from '../redux/actions';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router';
import { ApplicantUpdateStageDialog } from './applicant-update-stage-dialog/ApplicantUpdateStageDialog';
import { ApplicantUpdateStatusDialog } from './applicant-update-status-dialog/ApplicantUpdateStatusDialog';

export function ApplicantPage({ history }) {
  const dispatch = useDispatch();

  //clean up
  React.useEffect(() => () => dispatch(uncheckAllApplicants()), []);

  const applicantsUIEvents = {
    openViewApplicantDialog: (id) => {
      history.push(`${cadsAiApplicantsBaseUrl}/${id}/view`);
    },
    openViewApplicants: () => {
      history.push(`${cadsAiApplicantsBaseUrl}`);
    },
    openUpdateStageApplicants: () => {
      history.push(`${cadsAiApplicantsBaseUrl}/update-stage`);
    },
    openUpdateStatusApplicants: () => {
      history.push(`${cadsAiApplicantsBaseUrl}/update-status`);
    },
  };

  return (
    <ApplicantsUIProvider applicantsUIEvents={applicantsUIEvents}>
      <Switch>
        <Route path={`${cadsAiApplicantsBaseUrl}/update-stage`}>
          {({ history, match }) => (
            <ApplicantUpdateStageDialog
              show={match != null}
              onHide={() => {
                dispatch(setApplicantFilters());
                history.push(cadsAiApplicantsBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${cadsAiApplicantsBaseUrl}/update-status`}>
          {({ history, match }) => (
            <ApplicantUpdateStatusDialog
              show={match != null}
              onHide={() => {
                dispatch(setApplicantFilters());
                history.push(cadsAiApplicantsBaseUrl);
              }}
            />
          )}
        </Route>
      </Switch>
      <ApplicantsCard />
    </ApplicantsUIProvider>
  );
}
