import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import api from '@/utils/apiClient';
import { deleteAssessment } from '../../redux/actions';

const AssessmentDeleteDialog = ({ show, onHide, rtl, id }) => {
  const dispatch = useDispatch();

  const [modal, setModal] = useState({
    isOpen: show,
    title: 'Delete',
    message: 'Are you sure you want to delete ?',
    hide: onHide,
    isError: false,
  });

  const onDelete = async () => {
    try {
      const { isSuccess } = await api.assessments.deleteAssessment(+id);
      if (isSuccess) {
        dispatch(deleteAssessment(+id));
        modal.hide();
      } else {
        throw new Error('Error while deleting assessment');
      }
    } catch (error) {
      console.error('Error deleting assessment', error.message);
      setModal({
        ...modal,
        message: 'Error while deleting assessment',
        title: 'Error',
        isError: true,
      });
    }
  };

  return (
    <Modal
      isOpen={show}
      toggle={modal.hide}
      modalClassName={`${rtl.direction}-support`}
      className="modal-dialog--danger modal-dialog--colored"
    >
      <div className="modal__header">
        <button
          className="lnr lnr-cross modal__close-btn"
          aria-label="modal__close-btn"
          type="button"
          onClick={modal.hide}
        />
        <span className="lnr lnr-cross-circle modal__title-icon" />
        <h4 className="text-modal  modal__title">{modal.title}</h4>
        <div className="modal__body">{modal.message}</div>
        <ButtonToolbar className="modal__footer">
          <Button className="modal_cancel" onClick={modal.hide}>
            {modal.isError ? 'Okay' : 'No'}
          </Button>{' '}
          {!modal.isError && (
            <Button className="modal_ok" outline color="danger" onClick={onDelete}>
              Yes, Delete.
            </Button>
          )}
        </ButtonToolbar>
      </div>
    </Modal>
  );
};

export default connect((state) => ({
  rtl: state.rtl,
}))(AssessmentDeleteDialog);
