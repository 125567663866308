import React, { createContext, useContext } from 'react';

const UsersUIContext = createContext();

export function useUsersUIContext() {
  return useContext(UsersUIContext);
}

export function UsersUIProvider({ usersUIEvents, children }) {
  const value = {
    openViewUsersDialog: usersUIEvents.openViewUsersDialog,
    openList: usersUIEvents.openList,
  };

  return <UsersUIContext.Provider value={value}>{children}</UsersUIContext.Provider>;
}
