import React, { useState } from 'react';
import api from '@/utils/apiClient';
import LoadingIcon from 'mdi-react/LoadingIcon';
import AssessmentWizardForm from './AssessmentWizardForm';

const AssessmentEditForm = ({ modal, isEdit, id }) => {
  //states
  const [state, setState] = React.useState({
    loading: false,
    fetchingAssessment: false,
    newAssessmentAdded: false,
    errorMessage: '',
  });

  const initialValues = {
    title: '',
    description: '',
    assessmentCategory: null,
    assessmentType: null,
    customer: null,
    domain: null,
    subcategory: null,
    dataSkill: null,
    introduction: '<p></p>',
    thankYouPage: '<p></p>',
    tags:null,
  };

  const [data, setData] = useState(initialValues);

  // prefill the form in case of edit assessment
  React.useEffect(() => {
    if (isEdit && id) {
      setDefaultData(+id);
    }
  }, [isEdit, id]);

  const setDefaultData = async (assessmentId) => {
    try {
      const { data: res, isSuccess } = await api.assessments.getAssessmentById(assessmentId);
      const {
        title,
        description,
        assessmentCategory,
        assessmentType,
        customer,
        domain,
        subcategory,
        introduction,
        thankYouPage,
        assessmentDataSkills,
        assessmentTags,
      } = res.data;
      if (isSuccess) {
        setData({
          title,
          description,
          introduction,
          thankYouPage,
          assessmentCategory: { value: assessmentCategory?.id, label: assessmentCategory?.title },
          assessmentType: { value: assessmentType?.id, label: assessmentType?.title },
          customer: { value: customer?.id, label: customer?.title },
          domain: { value: domain?.id, label: domain?.title },
          subcategory: { value: subcategory?.id, label: subcategory?.title },
          dataSkill: { value: assessmentDataSkills?.id, label: assessmentDataSkills?.title },
          tags: assessmentTags?.map((tag) => ({value: tag.id, label: tag.name})),
        });
      } else {
        throw new Error(res?.messages?.[0]);
      }
    } catch ({ message }) {
      console.error('Assessment edit error : ', message);
      setState({ ...state, errorMessage: 'Error while getting existing assessment details' });
    }
  };
  const constructApiRequestData = (data) => {
    return {
      id: id ?? 0,
      title: data.title,
      description: data.description,
      assessmentCategoryId: data.assessmentCategory.value,
      assessmentTypeId: data.assessmentType.value,
      organizationId: data.customer.value,
      domainId: data?.domain?.value ?? null, // only for general assessment
      subcategoryId: data?.subcategory?.value ?? null, // only for general assessment
      DataSkillId: data.dataSkill?.value ?? null,
      introduction: data.introduction,
      thankYouPage: data.thankYouPage,
      tags: data?.tags?.map((tag) => ({
        id: tag?.value ?? "empty",
        name: tag?.label ?? "Empty Tag",
      })),
    };
  };

  const handleAddEditAssessment = async (assessmentData, clearForm) => {
    setState({ ...state, loading: true });
    const newAssessmentData = constructApiRequestData(assessmentData);
    const defaultError = `Error occurred while ${isEdit ? 'Updating' : 'Adding new'} assessment`;
    try {
      const { data, isSuccess } = isEdit
        ? await api.assessments.editAssessment(newAssessmentData)
        : await api.assessments.addAssessment(newAssessmentData);
      if (isSuccess) {
        setState({ ...state, newAssessmentAdded: true, loading: false });
        if (!isEdit) clearForm();
      } else {
        throw new Error(data?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({
        ...state,
        loading: false,
        newAssessmentAdded: false,
        errorMessage: error.message,
      });
    }
  };

  return (
    <div>
      {/*Add/Edit Assessment Form Status*/}
      <div className="ml-4">
        {state.loading && (
          <div>
            <div className="panel__refresh position-relative" style={{ height: 75, width: 20 }}>
              <LoadingIcon />
              <div style={{ width: 200, padding: '23px 40px' }}>
                {isEdit ? 'Updating' : 'Adding new'} assessment...
              </div>
            </div>
          </div>
        )}
        {(state.newAssessmentAdded || !!state.errorMessage) && (
          <table className="mt-3">
            <tr>
              <td>
                {state.newAssessmentAdded ? (
                  <span className="lnr lnr-thumbs-up modal__title-icon success" />
                ) : (
                  <span className="lnr lnr-cross-circle modal__title-icon error" />
                )}
              </td>
              <td className="pl-2">
                {state.newAssessmentAdded
                  ? `${isEdit ? 'Assessment Updated' : 'New Assessment Added'} successfully`
                  : state.errorMessage}
              </td>
            </tr>
          </table>
        )}
      </div>

      {/*Add/Edit Assessment Form*/}
      <AssessmentWizardForm
        onSubmit={handleAddEditAssessment}
        initialValues={initialValues}
        data={data}
        setData={setData}
      />
    </div>
  );
};

export default AssessmentEditForm;
