import React from 'react';

const SmartSkills = ({ color, level, value, detail, isSelected }) =>
  detail ? (
    <div
      className={`smart-skill-circle ss-detailed-${level?.toLowerCase()} ${
        isSelected ? ' selected-ss' : ''
      }`}
      style={{ backgroundColor: color }}
    >
      <div className="score" style={{ fontSize: '13px' }}>
        {Math.round(value)}
      </div>
      <div className="level" style={{ fontSize: '10px' }}>
        {level}
      </div>
    </div>
  ) : (
    <div
      className={`smart-skill-circle ss-${level?.toLowerCase()} 
        ${isSelected ? ' selected-ss' : ''}`}
      style={{ backgroundColor: color }}
    >
      <div className="score">{Math.round(value)}</div>
      <div className="level">{level}</div>
    </div>
  );

export default SmartSkills;
