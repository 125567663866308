import React, { useRef } from 'react';
import { Button } from 'reactstrap';
import Select from 'react-select';
import TableHead from '@material-ui/core/TableHead';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getOrganizationTypeList,
  setOrganizationFilters,
  uncheckAllSelectedOrganizations,
} from '@/containers/Cads-AI/redux/organization/actions';

export function OrganizationTableSearchBar() {
  const dispatch = useDispatch();
  const cadsAIOrgs = useSelector((state) => state.cadsAIOrgs, shallowEqual);
  const totalOrganizationCount = cadsAIOrgs?.data?.length;
  const [isSearched, setIsSearched] = React.useState(false);
  const [selectedOrgType, setSelectedOrgType] = React.useState({ value: null, label: 'All' });
  const [organizationTypeOptions, setOrganizationTypeOptions] = React.useState([]);
  const searchNameRef = useRef(null);

  React.useEffect(() => {
    dispatch(getOrganizationTypeList());
  }, []);

  React.useEffect(() => {
    setOrganizationTypeOptions(
      cadsAIOrgs.types?.map((type) => ({ value: type.id, label: type.title, ...type })),
    );
  }, [cadsAIOrgs.types]);

  React.useEffect(() => {
    handleSearch();
  }, [selectedOrgType]);

  const handleSearch = () => {
    dispatch(
      setOrganizationFilters({
        searchText: searchNameRef?.current?.value,
        orgTypeId: selectedOrgType?.value,
        pageNumber: 1,
      }),
    );
  };

  const handleClear = () => {
    if (searchNameRef?.current?.value) searchNameRef.current.value = '';
    setIsSearched(false);
    dispatch(setOrganizationFilters({ searchText: '' }));
    setSelectedOrgType({ value: null, label: 'All' });
    dispatch(uncheckAllSelectedOrganizations());
  };

  return (
    <TableHead>
      <div className="table__search">
        <div className="table__search_item">
          <b>Organization Name</b>
          <div className="form">
            <div className="form__form-group">
              <div className="form__form-group-field">
                <div className="form__form-group-icon" style={{ height: 37 }}>
                  <MagnifyIcon />
                </div>
                <input
                  ref={searchNameRef}
                  className="search-bar-search-text"
                  name="searchText"
                  type="text"
                  placeholder="Search by Organization Name"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table__search_item">
          <b>Organization Type</b>
          <div style={{ minWidth: 200 }}>
            <Select
              name="organizationType"
              value={selectedOrgType}
              onChange={setSelectedOrgType}
              autosize={true}
              options={organizationTypeOptions}
              clearable={false}
              className="react-select"
              placeholder={'Select Organization Type'}
              classNamePrefix="react-select"
            />
          </div>
        </div>
        <div className="table__search_item mt-3">
          <Button color="primary" onClick={handleSearch}>
            {' '}
            Search{' '}
          </Button>
        </div>
        <div className="table__search_item mt-3">
          <Button color="secondary" onClick={handleClear} outline>
            Clear
          </Button>
        </div>
      </div>

      {isSearched && (
        <div className="ml-1">
          <span>Found {totalOrganizationCount} matches</span>
        </div>
      )}
    </TableHead>
  );
}
