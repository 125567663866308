import { sendRequest } from '../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const countryBaseURL = process.env.REACT_APP_CADS_AI_API + process.env.REACT_APP_BASE_INFO_ENDPOINT;

export const countryEndpoints = {
  GET_ALL_COUNTRY_LIST: '/getAllCountry',
};

/**
 * get list for all countries
 */
const getCountryList = () => {
  return sendRequest({
    fullUrl: countryBaseURL + countryEndpoints.GET_ALL_COUNTRY_LIST,
    method: httpMethod.GET,
  });
};

export default {
  getCountryList,
};
