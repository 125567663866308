import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loading from '@/shared/components/Loading';
import { paths } from '@/shared/helpers/paths';
import { DdoPage } from './components/DdoPage';
import './index.scss';

export const cadsAiDdoBaseUrl = paths.CADS_AI.DDO;

const Ddo = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path={cadsAiDdoBaseUrl} component={DdoPage} />
      </Switch>
    </Suspense>
  );
};

export default Ddo;
