import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { BaselineThresholdUIProvider } from './BaselineThresholdUIProvider';
import { BaselineThresholdCard } from './BaselineThresholdCard';
import { setBaselineThresholdFilters } from '../redux/actions';
import { useDispatch } from 'react-redux';
import { BaselineThresholdConfigDialog } from './baseline-threshold-config/BaselineThresholdConfigDialog';
import { paths } from '@/shared/helpers/paths';

const controlPanelBaselineThresholdBaseUrl = paths.CONTROL_PANEL.BASELINE_THRESHOLD;

export function BaselineThresholdPage() {
  const history = useHistory();
  const baselineThresholdUIEvents = {
    openBaselineThresholdConfigDialog: () => {
      history.push(`${controlPanelBaselineThresholdBaseUrl}/config`);
    },
  };

  //TODO: remove this once we have add and edit api returning newly created data
  const dispatch = useDispatch();

  return (
    <BaselineThresholdUIProvider baselineThresholdUIEvents={baselineThresholdUIEvents}>
      <Switch>
        <Route path={`${controlPanelBaselineThresholdBaseUrl}/config`}>
          {({ history, match }) => (
            <BaselineThresholdConfigDialog
              show={match != null}
              onHide={() => {
                dispatch(setBaselineThresholdFilters());
                history.push(controlPanelBaselineThresholdBaseUrl);
              }}
            />
          )}
        </Route>
      </Switch>
      <BaselineThresholdCard />
    </BaselineThresholdUIProvider>
  );
}
