import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col, Modal, Row } from 'reactstrap';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { uncheckAllSelectedModule } from '../../redux/actions';
import api from '@/utils/apiClient';

export const ModuleUpdateStatusDialog = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state, shallowEqual);
  const checkedModuleIds = useSelector(
    (state) => state.cadsAIModule.checkedModuleIds,
    shallowEqual,
  );

  const [status, setStatus] = React.useState({
    value: true,
    label: 'Activate',
  });

  const [modal, setModal] = React.useState({
    isOpen: show,
    message: 'Select Module Status',
    hide: onHide,
    loading: false,
    updateStatusSuccess: false,
    updateStatusError: false,
  });

  const handleUpdateStatus = async () => {
    setModal({
      ...modal,
      loading: true,
      message: 'Module Status Update In Progress...',
    });
    const response = await api.cadsAIModule.deleteModule(checkedModuleIds, status.value);
    let updatedModal = {};
    if (response.isSuccess) {
      updatedModal = {
        loading: false,
        updateStatusSuccess: true,
        message: 'Module Deactivated Successfully!',
      };
      dispatch(uncheckAllSelectedModule());
    } else {
      updatedModal = {
        loading: false,
        updateStatusError: true,
        message: 'Error Performing Module Status Update!',
      };
    }
    setModal({ ...modal, ...updatedModal });
  };

  return (
    <Modal
      isOpen={show}
      toggle={modal.hide}
      keyboard={false}
      backdrop={'static'}
      modalClassName={`${rtl.direction}-support`}
      className="modal-dialog--primary modal-dialog"
    >
      <div className="modal__header">
        {modal.updateStatusSuccess || <span className="lnr lnr-unlink modal__title-icon" />}
        {modal.updateStatusSuccess && (
          <span className="lnr lnr-thumbs-up modal__title-icon success" />
        )}
        <button
          className="lnr lnr-cross modal__close-btn"
          aria-label="modal__close-btn"
          type="button"
          onClick={modal.hide}
        />
        {modal.updateStatusSuccess && <h4 className="text-modal  modal__title">Done</h4>}
        {modal.updateStatusSuccess || (
          <h4 className="text-modal  modal__title">Deactivate Module</h4>
        )}
      </div>
      <div>
        <div className="ml-4">
          {modal.loading && (
            <div>
              <div className="panel__refresh position-relative" style={{ height: 75, width: 20 }}>
                <LoadingIcon />
                <div style={{ width: 200, padding: '23px 30px' }}>Updating status...</div>
              </div>
            </div>
          )}
          {(modal.updateStatusSuccess || !!modal.updateStatusError) && (
            <>
              <table className="mt-3">
                <tr>
                  <td className="pl-3">
                    {modal.updateStatusSuccess ? 'Module Deactivated Successfully' : modal.message}
                  </td>
                </tr>
              </table>
            </>
          )}
        </div>
        <Row>
          <Col md={12} lg={12}>
            <Card className="pb-0">
              <CardBody>
                <div className="form-wrapper">
                  <div className="form">
                    <div className="form__form-group text-center">
                      {modal.updateStatusSuccess || (
                        <span className="form__form-group-label">
                          Are you sure you want to deactivate Module?
                        </span>
                      )}
                      <div className="form__form-group-field"></div>
                    </div>
                    <ButtonToolbar className="modal__footer m-auto">
                      <>
                        {modal.updateStatusSuccess && (
                          <Button
                            color="secondary"
                            outline
                            type="button"
                            onClick={() => modal.hide()}
                          >
                            Okay
                          </Button>
                        )}
                        {modal.updateStatusSuccess || (
                          <>
                            <Button
                              color="secondary"
                              type="button"
                              className="modal_cancel btn btn-secondary"
                              onClick={() => modal.hide()}
                            >
                              Cancel
                            </Button>
                            <Button
                              color="danger"
                              outline
                              type="submit"
                              onClick={handleUpdateStatus}
                            >
                              Yes, Deactivate
                            </Button>
                          </>
                        )}
                      </>
                    </ButtonToolbar>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};
