import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { cadsAiEventsBaseUrl } from '../index';
import { EventsUIProvider } from './EventsUIProvider';
import { EventsCard } from './EventsCard';
import EventEditDialog from './events-add-edit-dialog/EventEditDialog';
import EventViewDialog from './events-view-dialog/EventViewDialog';
import { setEventFilters, uncheckAllEvent } from '../redux/actions';
import { useDispatch } from 'react-redux';
import EventDeleteDialog from './events-delete-dialog/EventDeleteDialog';

export function EventsPage({ history, match }) {
  const dispatch = useDispatch();

  //clean up
  React.useEffect(() => () => dispatch(uncheckAllEvent()), []);

  const eventsUIEvents = {
    openViewEventDialog: (id) => {
      history.push(`${cadsAiEventsBaseUrl}/${id}/view`);
    },
    openNewEventDialog: () => {
      history.push(`${cadsAiEventsBaseUrl}/new`);
    },
    openEditEventDialog: (id) => {
      history.push(`${cadsAiEventsBaseUrl}/${id}/edit`);
    },
    openDeleteEventDialog: (id) => {
      history.push(`${cadsAiEventsBaseUrl}/${id}/delete`);
    },
  };

  return (
    <EventsUIProvider eventsUIEvents={eventsUIEvents}>
      <Switch>
        <Route path={`${cadsAiEventsBaseUrl}/new`}>
          {({ history, match }) => (
            <EventEditDialog
              show={match != null}
              isEdit={false}
              onHide={() => {
                dispatch(setEventFilters());
                history.push(cadsAiEventsBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${cadsAiEventsBaseUrl}/:id/edit`}>
          {({ history, match }) => (
            <EventEditDialog
              show={match != null}
              isEdit={true}
              id={match?.params?.id}
              onHide={() => {
                dispatch(setEventFilters());
                history.push(cadsAiEventsBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${cadsAiEventsBaseUrl}/:id/delete`}>
          {({ history, match }) => (
            <EventDeleteDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                dispatch(setEventFilters());
                history.push(cadsAiEventsBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${cadsAiEventsBaseUrl}/:id/view`}>
          {({ history, match }) => (
            <EventViewDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                history.push(cadsAiEventsBaseUrl);
              }}
            />
          )}
        </Route>
      </Switch>
      <EventsCard />
    </EventsUIProvider>
  );
}
