import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const fetchHelpList = createAction('CADS_AI_FETCH_HELP_LIST');
export const setHelpList = createAction('CADS_AI_SET_HELP_LIST');
export const setHelpError = createAction('CADS_AI_SET_HELP_ERROR');
export const setHelpFilters = createAction('CADS_AI_SET_HELP_FILTERS');
export const toggleCheckedHelp = createAction('CADS_AI_TOGGLE_CHECKED_HELP');
export const uncheckAllHelp = createAction('CADS_AI_UNCHECK_ALL_HELP');

export const toggleCheckedItemById = (notificationId) => (dispatch) => {
  dispatch(toggleCheckedHelp(notificationId));
};

export const getHelpList = (filter) => async (dispatch) => {
  const defaultError = `Error occurred getting help list`;
  dispatch(fetchHelpList());
  try {
    const { data, isSuccess } = await api.cadsAIHelp.getHelpList(filter);
    if (isSuccess) dispatch(setHelpList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setHelpError(error.message));
    console.error(defaultError, error.message);
  }
};
