import { Fragment } from 'react';

const RecommendationTab = ({ recommendation }) => {
  return (
    <Fragment>
      <div style={{ marginTop: '20px', color: '#1D3B55' }}>{recommendation}</div>
      <br />
      <br />
    </Fragment>
  );
};

export default RecommendationTab;
