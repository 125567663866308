import { handleActions } from 'redux-actions';
import {
  fetchQAList,
  setQAList,
  setQAError,
  setQAFilters,
  toggleCheckedQA,
  uncheckAllQA,
} from './actions';

const defaultState = {
  loading: false,
  checkedQAIds: [],
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    search: '',
    isSortOrderDescending: false,
    status: null,
  },
  totalRecords: 0,
  data: [],
  options: [],
  error: null,
};

export default handleActions(
  {
    [fetchQAList](state) {
      return {
        ...state,
        loading: true,
        data: [],
      };
    },
    [setQAList](state, { payload }) {
      return {
        ...state,
        loading: false,
        data: payload.data.records,
        totalRecords: payload.data.totalRecords,
        error: null,
      };
    },
    [setQAError](state, { payload }) {
      return {
        ...state,
        data: [],
        loading: false,
        totalPages: defaultState.totalPages,
        error: payload?.message,
      };
    },
    [setQAFilters](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
        checkedQAIds: [],
      };
    },
    [toggleCheckedQA](state, { payload: qaId }) {
      const checkedIds = state.checkedQAIds;
      return {
        ...state,
        checkedQAIds: checkedIds.includes(qaId)
          ? checkedIds.filter((id) => id !== qaId)
          : [...checkedIds, qaId],
      };
    },
    [uncheckAllQA](state) {
      return {
        ...state,
        checkedQAIds: [],
      };
    },
  },
  defaultState,
);
