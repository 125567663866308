import { Card, CardBody, Col, Row } from 'reactstrap';
import { Fragment, useEffect } from 'react';
import { useState } from 'react';
import SmartSkills from '../SmartSkills';
import DetailedViewDescription from './DetailedViewDescription';

const DetailedView = ({ smartSkillData }) => {
  const [currentDimension, setCurrentDimension] = useState([]);
  const [currentColor, setCurrentColor] = useState([]);

  useEffect(() => {
    if (smartSkillData) {
      setCurrentDimension(smartSkillData[0]?.smartSkillsResultFactorsDimensions[0]);
      setCurrentColor(smartSkillData[0]?.color);
    }
  }, []);

  const handleClick = (skill, color) => {
    setCurrentDimension(skill);
    setCurrentColor(color);
  };

  return (
    <Fragment>
      <Row>
        <Col md={12}>
          <div className="card-deck">
            {smartSkillData?.map((smartSkillsData) => {
              return (
                <Card className={'smart-skill-card'}>
                  <CardBody className="smart-skill-container">
                    <div className="container-smarts">
                      {smartSkillsData?.smartSkillsResultFactorsDimensions?.map((skill) => {
                        return (
                          <div
                            className="div-container-smart-skill"
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleClick(skill, smartSkillsData.color)}
                          >
                            <div className="ss-detail-circle-center">
                              <SmartSkills
                                color={smartSkillsData.color}
                                level={skill.scoreTitle}
                                value={skill.score}
                                detail={true}
                                isSelected={currentDimension.dimensionId === skill.dimensionId}
                              />
                            </div>
                            <div
                              className="smart-skill-text-detailed"
                              style={{
                                color: smartSkillsData.color,
                              }}
                            >
                              {skill.dimensionName}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </CardBody>
                </Card>
              );
            })}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={'12'}>
          <DetailedViewDescription currentDimension={currentDimension} color={currentColor} />
        </Col>
      </Row>
    </Fragment>
  );
};

export default DetailedView;
