import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { dataStarApplicantsBaseUrl } from '../index';
import { ApplicantsUIProvider } from './ApplicantsUIProvider';
import { ApplicantsCard } from './ApplicantsCard';
import ApplicantViewDialog from './applicants-view-dialog/ApplicantViewDialog';
import ApplicantEditDialog from './applicant-add-edit-dialog/ApplicantEditDialog';
import { ApplicantUpdateStatusDialog } from './applicant-update-status-dialog/ApplicantUpdateStatusDialog';
import { ApplicantUpdateStageDialog } from './applicant-update-stage-dialog/ApplicantUpdateStageDialog';
import { useDispatch } from 'react-redux';
import { clearApplicantFilters, setApplicantFilters, uncheckAllApplicants } from '../redux/actions';
import { ApplicantUpdateCohortDialog } from '@/containers/Data-Star/Applicants/components/applicant-update-cohort-dialog/ApplicantUpdateCohortDialog';

export function ApplicantsPage({ history, match }) {
  const dispatch = useDispatch();

  React.useEffect(() => {
    return () => {
      dispatch(uncheckAllApplicants());
      dispatch(clearApplicantFilters());
    };
  }, []);

  const applicantsUIEvents = {
    openViewApplicantsDialog: (id) => {
      history.push(`${dataStarApplicantsBaseUrl}/${id}/view`);
    },
    openNewApplicantsDialog: () => {
      history.push(`${dataStarApplicantsBaseUrl}/new`);
    },
    openEditApplicantsDialog: (id) => {
      history.push(`${dataStarApplicantsBaseUrl}/${id}/edit`);
    },
    openDeleteApplicantsDialog: (id) => {
      history.push(`${dataStarApplicantsBaseUrl}/${id}/delete`);
    },
    openUpdateStatusDialog: () => {
      history.push(`${dataStarApplicantsBaseUrl}/update-status`);
    },
    openUpdateStageDialog: () => {
      history.push(`${dataStarApplicantsBaseUrl}/update-stage`);
    },
    openUpdateCohortDialog: () => {
      history.push(`${dataStarApplicantsBaseUrl}/update-cohort`);
    },
  };

  return (
    <ApplicantsUIProvider applicantsUIEvents={applicantsUIEvents}>
      <Switch>
        <Route path={`${dataStarApplicantsBaseUrl}/:id/edit`}>
          {({ history, match }) => (
            <ApplicantEditDialog
              show={match != null}
              isEdit={true}
              id={match?.params?.id}
              onHide={() => {
                dispatch(setApplicantFilters());
                history.push(dataStarApplicantsBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${dataStarApplicantsBaseUrl}/:id/view`}>
          {({ history, match }) => (
            <ApplicantViewDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                history.push(dataStarApplicantsBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${dataStarApplicantsBaseUrl}/update-status`}>
          {({ history, match }) => (
            <ApplicantUpdateStatusDialog
              show={match != null}
              onHide={() => {
                dispatch(setApplicantFilters());
                history.push(dataStarApplicantsBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${dataStarApplicantsBaseUrl}/update-stage`}>
          {({ history, match }) => (
            <ApplicantUpdateStageDialog
              show={match != null}
              onHide={() => {
                dispatch(setApplicantFilters());
                history.push(dataStarApplicantsBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${dataStarApplicantsBaseUrl}/update-cohort`}>
          {({ history, match }) => (
            <ApplicantUpdateCohortDialog
              show={match != null}
              onHide={() => {
                dispatch(setApplicantFilters());
                history.push(dataStarApplicantsBaseUrl);
              }}
            />
          )}
        </Route>
      </Switch>
      <ApplicantsCard />
    </ApplicantsUIProvider>
  );
}
