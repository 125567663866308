import React from 'react';
import { Route, Switch } from 'react-router';
import { Col, Container, Row } from 'reactstrap';
import { cadsAiNotificationBaseUrl } from '..';
import NotificationViewPage from './notification-view-dialog/NotificationViewPage';
import NotificationTableWrapper from './table/NotificationTableWrapper';

export function NotificationCard() {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Notifications</h3>
        </Col>
      </Row>
      <Row>
        <Switch>
          <Route path={`${cadsAiNotificationBaseUrl}/:id/view`}>
            {({ history, match }) => (
              <NotificationViewPage
                show={match != null}
                id={match?.params?.id}
                onHide={() => {
                  history.push(cadsAiNotificationBaseUrl);
                }}
              />
            )}
          </Route>
          <Route path="">
            <NotificationTableWrapper />
          </Route>
        </Switch>
      </Row>
    </Container>
  );
}
