import React from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import HelpTable from './HelpTable';
import { useHelpUIContext } from '../HelpUIProvider';
import { shallowEqual, useSelector } from 'react-redux';

const HelpTableWrapper = () => {
  const helpUIContext = useHelpUIContext();
  const { checkedHelpIds } = useSelector((state) => state.cadsAIHelps, shallowEqual);

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="products-list">
          <div className="card__title">
            <h5 className="bold-text">Help List</h5>
            <ButtonToolbar className="products-list__btn-toolbar-top">
              <Button
                outline
                color="primary"
                disabled={checkedHelpIds.length === 0}
                onClick={helpUIContext.openDeactivateHelpDialog}
              >
                Update Status
              </Button>
            </ButtonToolbar>
          </div>
          <HelpTable />
        </CardBody>
      </Card>
    </Col>
  );
};

export default HelpTableWrapper;
