import { handleActions } from 'redux-actions';
import {
  fetchHelpList,
  setHelpError,
  setHelpFilters,
  setHelpList,
  toggleCheckedHelp,
  uncheckAllHelp,
} from './actions';

const defaultState = {
  loading: false,
  checkedHelpIds: [],
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    search: '',
    isSortOrderDescending: false,
    status: null,
  },
  totalRecords: 0,
  data: [],
  options: [],
  error: null,
};

export default handleActions(
  {
    [fetchHelpList](state) {
      return {
        ...state,
        loading: true,
        data: [],
      };
    },
    [setHelpList](state, { payload }) {
      return {
        ...state,
        loading: false,
        data: payload?.data?.records,
        totalRecords: payload?.data?.totalRecords,
        error: null,
      };
    },
    [setHelpError](state, { payload }) {
      return {
        ...state,
        data: [],
        loading: false,
        totalPages: defaultState.totalPages,
        error: payload?.message,
      };
    },
    [setHelpFilters](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
        checkedHelpIds: [],
      };
    },
    [toggleCheckedHelp](state, { payload: eventId }) {
      const checkedIds = state.checkedHelpIds;
      return {
        ...state,
        checkedHelpIds: checkedIds.includes(eventId)
          ? checkedIds.filter((id) => id !== eventId)
          : [...checkedIds, eventId],
      };
    },
    [uncheckAllHelp](state) {
      return {
        ...state,
        checkedHelpIds: [],
      };
    },
  },
  defaultState,
);
