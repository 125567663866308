import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, ButtonToolbar, Col, Row } from 'reactstrap';
import { FormField } from '@/shared/components/form/hook/FormField';
import { SelectField } from '@/shared/components/form/Select';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableCell } from '@material-ui/core';
import DeleteIcon from 'mdi-react/DeleteIcon';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { RadioButtonField } from '@/shared/components/form/RadioButton';
import { getAllAssessmentOptions } from '@/containers/Cads-AI/ManageDataStar/Programs/redux/actions';

const AssessmentForm = ({
  assessmentList,
  setAssessmentList,
  assessmentOpts,
  setAssessmentOpts,
}) => {
  const {
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const [assessmentType, setAssessmentType] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const assessment = watch('assessment');
  const preAssessment = assessmentList.filter(
    (item) => item.prePostStatus === 'pre' && item.action !== 'deleted',
  );
  const smartskillsAssessment = assessmentList.filter(
    (item) => item.prePostStatus === 'smartskills' && item.action !== 'deleted',
  );

  React.useEffect(() => {
    dispatch(getAllAssessmentOptions());
  }, []);

  React.useEffect(() => {
    if (preAssessment.length >= 1 && assessmentType === 'pre') {
      setErrorMessage('You can select atmost 1 baseline.');
    }
    if (smartskillsAssessment.length >= 1 && assessmentType === 'smartskills') {
      setErrorMessage('You can select atmost 1 smartskills.');
    }
  }, [assessmentType]);

  const onSubmit = (data) => {
    //adding new assessment to list
    if (
      (preAssessment.length < 1 && assessmentType === 'pre') ||
      (smartskillsAssessment.length < 1 && assessmentType === 'smartskills')
    ) {
      setAssessmentList([
        ...assessmentList,
        {
          id: data.assessment.value,
          assessment: {
            id: data.assessment.value,
            title: data.assessment.label,
            assessmentCategory: data.assessment.assessmentCategory,
          },
          code: data.code,
          prePostStatus: assessmentType.toLowerCase(),
          sortOrder: isEmpty(data.sortOrder) ? 0 : data.sortOrder,
          extraDescription: data.description,
          action: 'added',
        },
      ]);
      setAssessmentOpts(assessmentOpts.filter((item) => item?.value !== assessment?.value));
      setValue('assessment', '');
      setAssessmentType('');
      setErrorMessage('');
    }
  };

  return (
    <form className={`form`} onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col>
          <div className="form__form-group">
            <div className="error-message">{errorMessage}</div>
            <span className="form__form-group-label">Assessment</span>
            <div className="form__form-group-field">
              <FormField
                control={control}
                name="assessment"
                isMulti={false}
                component={SelectField}
                options={assessmentOpts}
                placeholder="Select Assessment"
                rules={{ required: 'This is required field' }}
                errors={errors}
                isAboveError
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="form__form-group">
            <span className="form__form-group-label">Assessment Type</span>
            <div className="form__form-group-field mt-2">
              <RadioButtonField
                radioValue={'pre'}
                name={`pre`}
                onChange={setAssessmentType}
                value={assessmentType}
                className="colored-click mr-4"
                label={'Baseline'}
              />
              <RadioButtonField
                radioValue={'smartskills'}
                name={`smartskills`}
                onChange={setAssessmentType}
                value={assessmentType}
                className="colored-click mr-4"
                label={'Smartskills'}
              />
            </div>
          </div>
        </Col>
        <Col>
          <div className="form__form-group ml-3">
            <span className="form__form-group-label">Sort Order</span>
            <div className="form__form-group-field">
              <FormField
                name="sortOrder"
                as="input"
                type="number"
                min={0}
                placeholder="Enter Sort Order"
                control={control}
                defaultValue="0"
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="form__form-group">
            <span className="form__form-group-label">Description</span>
            <div className="form__form-group-field">
              <FormField
                name="description"
                control={control}
                component="textarea"
                defaultValue=""
                placeholder="Enter Extra Description"
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <ButtonToolbar className="form__button-toolbar modal__footer w-100">
            <Button disabled={false} color="success" outline type="submit">
              Add Assessment
            </Button>
          </ButtonToolbar>
        </Col>
      </Row>
    </form>
  );
};

const AssessmentTable = ({
  assessmentList,
  setAssessmentList,
  assessmentOpts,
  setAssessmentOpts,
}) => {
  const removeAssessment = (assessments) => {
    setAssessmentOpts([
      {
        value: assessments?.assessment.id,
        label: assessments?.assessment.title,
        assessmentCategory: assessments?.assessment?.assessmentCategory,
      },
      ...assessmentOpts,
    ]);
    setAssessmentList(
      assessmentList.map((assessment) =>
        assessment.id !== assessments?.id ? assessment : { ...assessment, action: 'deleted' },
      ),
    );
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{ minWidth: 160 }}>Name</TableCell>
          <TableCell style={{ minWidth: 100 }}>Code</TableCell>
          <TableCell style={{ minWidth: 70 }}>Type</TableCell>
          <TableCell style={{ minWidth: 100 }}>Sort Order</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
      {!assessmentList.length && (
        <TableRow>
          <TableCell colSpan={6} align="center">
            <p className="mt-3 mb-3">
              There are no assessment added, fill out the about form and click on Add Assessment
              button to add new assessment.
            </p>
          </TableCell>
        </TableRow>
      )}
      {assessmentList
        ?.filter((a) => a.action !== 'deleted')
        ?.sort((a1, a2) => +(a1?.sortOrder ?? 0) - (a2?.sortOrder ?? 0))
        .map((a) => (
          <TableRow>
            <TableCell>{a?.assessment?.title ?? '-'}</TableCell>
            <TableCell>{a?.code ?? '-'}</TableCell>
            <TableCell style={{ textTransform: 'uppercase' }}>
              {a?.prePostStatus === 'pre' ? 'baseline' : a?.prePostStatus ?? '-'}
            </TableCell>
            <TableCell>{a.sortOrder ?? '-'}</TableCell>
            <TableCell>
              {a?.extraDescription?.trim() === '' ? '-' : a?.extraDescription?.trim() ?? '-'}
            </TableCell>
            <TableCell>
              <DeleteIcon
                style={{ color: '#ff4861' }}
                role="button"
                onClick={() => removeAssessment(a)}
              />
            </TableCell>
          </TableRow>
        ))}
    </Table>
  );
};

const StepTwo = ({ onSubmit, page, data, previousPage, isEdit }) => {
  const [assessmentList, setAssessmentList] = React.useState([]);
  const [assessmentOpts, setAssessmentOpts] = React.useState([]);
  const { assessmentOptions } = useSelector((state) => state.cadsAIPrograms, shallowEqual);

  React.useEffect(() => {
    setAssessmentList(data?.assessmentList ?? []);
  }, [data]);

  React.useEffect(() => {
    const selectedAssessmentIds = assessmentList.map((item) => item?.assessment?.id) ?? [];
    setAssessmentOpts(
      assessmentOptions
        ?.filter((s) => !selectedAssessmentIds.includes(s?.id))
        .map((item) => ({
          value: item?.id,
          label: item?.title,
          assessmentCategory: item.assessmentCategory.title,
        })),
    );
  }, [assessmentOptions]);

  return (
    <div className="modal__body add-edit-program-modal">
      <AssessmentForm
        assessmentList={assessmentList}
        setAssessmentList={setAssessmentList}
        setAssessmentOpts={setAssessmentOpts}
        assessmentOpts={assessmentOpts}
      />
      <AssessmentTable
        assessmentList={assessmentList}
        setAssessmentList={setAssessmentList}
        setAssessmentOpts={setAssessmentOpts}
        assessmentOpts={assessmentOpts}
      />
      <ButtonToolbar className="form__button-toolbar modal__footer">
        <Button type="button" className="previous" onClick={() => previousPage({ assessmentList })}>
          Back
        </Button>
        <Button
          disabled={false}
          color="primary"
          type="button"
          onClick={() => onSubmit({ assessmentList })}
        >
          Next
        </Button>
      </ButtonToolbar>
    </div>
  );
};

export default StepTwo;
