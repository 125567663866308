import React, { useRef, useState } from 'react';
import { Button } from 'reactstrap';
import TableHead from '@material-ui/core/TableHead';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import Select from 'react-select';
import api from '@/utils/apiClient';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setDSCohortFilters } from '../../redux/actions';
import { useEffect } from 'react';

export function DSCohortTableSearchBar() {
  const dispatch = useDispatch();
  const dsCohorts = useSelector((state) => state.cadsAIDSCohorts, shallowEqual);
  const [activationStatus, setActivationStatus] = useState(null);
  const totalDSCohortsCount = dsCohorts?.data?.length;
  const [isSearched, setIsSearched] = useState(false);
  const [programOpts, setProgramOpts] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState(null);

  const searchNameRef = useRef(null);

  const getProgramOptions = async () => {
    const { data: res, isSuccess } = await api.cadsAIPrograms.getProgramOptionsList();
    if (isSuccess) {
      const programOptions = res.data?.map((program) => ({
        value: program.id,
        label: program.title,
      }));
      setProgramOpts(programOptions);
    }
  };

  const handleSearch = () => {
    dispatch(
      setDSCohortFilters({
        searchText: searchNameRef?.current?.value,
        status: activationStatus?.value,
        programId: selectedProgram?.value,
      }),
    );
  };

  const handleClear = () => {
    if (searchNameRef?.current?.value) searchNameRef.current.value = '';
    setIsSearched(false);
    setSelectedProgram(null);
    setActivationStatus(null);
    dispatch(setDSCohortFilters({ searchText: '', status: null, programStatusId: null }));
  };

  useEffect(() => {
    getProgramOptions();
  }, []);

  return (
    <TableHead>
      <div className="table__search mt-4">
        <div className="table__search_item">
          <b>DS Cohort Name</b>
          <div className="form" style={{ width: 300 }}>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <div className="form__form-group-icon" style={{ height: 37 }}>
                  <MagnifyIcon />
                </div>
                <input
                  ref={searchNameRef}
                  className="search-bar-search-text"
                  name="searchText"
                  type="text"
                  placeholder="Search by DS Cohort Name"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table__search_item">
          <b>DS Cohort Activation Status</b>
          <div style={{ minWidth: 240 }}>
            <Select
              name="cohortActivationStatus"
              value={activationStatus}
              onChange={(selectedOption) => setActivationStatus(selectedOption)}
              autosize={true}
              options={[
                { label: 'Deactivated', value: false },
                { label: 'Activated', value: true },
              ]}
              clearable={false}
              className="react-select"
              placeholder={'Select Cohort Activation Status'}
              classNamePrefix="react-select"
            />
          </div>
        </div>

        <div className="table__search_item">
          <b>Program</b>
          <div style={{ minWidth: 240 }}>
            <Select
              name="program"
              value={selectedProgram}
              onChange={(selectedOption) => setSelectedProgram(selectedOption)}
              autosize={true}
              options={programOpts}
              clearable={false}
              className="react-select"
              placeholder={'Select Program'}
              classNamePrefix="react-select"
            />
          </div>
        </div>

        <div className="table__search_item mt-3">
          <Button color="primary" onClick={handleSearch}>
            {' '}
            Search{' '}
          </Button>
        </div>
        <div className="table__search_item mt-3">
          <Button color="secondary" onClick={handleClear} outline>
            Clear
          </Button>
        </div>
      </div>

      {isSearched && (
        <div className="ml-1">
          <span>Found {totalDSCohortsCount} matches</span>
        </div>
      )}
    </TableHead>
  );
}
