import { rolesEnum as roles } from './roles.enum';

const skillBank = '/skill-bank';
const campaign = '/campaign';
const controlPanel = '/control-panel';
const cadsAI = '/cads-ai';
const dataStar = '/data-star';
const assessments = '/assessments';
const assignment = '/assignments';
/*CADS AI*/
const cadsAILearning = `${cadsAI}/learning`;
const cadsAIHome = `${cadsAI}/home`;
const cadsAIDataStar = `${cadsAI}/data-star`;
const cadsAIOpportunities = `${cadsAI}/opportunities`;

export const paths = {
  LOGIN: '/login', // logout path will be same as login since we have code to handle logout inside login page itself
  FORGOT_PASSWORD: '/forgotPassword',
  RESET_PASSWORD: '/resetPassword',
  CHANGE_PASSWORD: '/changePassword',

  DASHBOARD: '/dashboard',

  CUSTOMER: '/customer',

  ASSESSMENTS: {
    BASE: assessments,
  },

  ASSIGNMENTS: {
    BASE: assignment,
  },

  SKILL_BANK: {
    BASE: skillBank,
    SKILLS: `${skillBank}/skills`,
    SUB_CATEGORIES: `${skillBank}/subcategories`,
    DOMAINS: `${skillBank}/domains`,
    QUESTIONS: `${skillBank}/questions`,
  },

  CAMPAIGN: {
    BASE: campaign,
    USERS: `${campaign}/users`,
    SUNRISE25USERS: `${campaign}/sunrise-25-users`,
  },
  DATA_BANK: '/data-bank',

  USER: '/user',
  CONTROL_PANEL: {
    BASE: controlPanel,
    ASSIGN: `${controlPanel}/assign`,
    ASSIGNED: `${controlPanel}/assigned`,
    LEARNING_PATH: `${controlPanel}/learning-paths`,
    TRAINING_PROVIDER: `${controlPanel}/training-providers`,
    MODULE: `${controlPanel}/modules`,
    BASELINE_THRESHOLD: `${controlPanel}/baseline-threshold`,
  },
  CADS_AI: {
    BASE: cadsAI,
    USERS: `${cadsAI}/users`,
    DASHBOARD: `${cadsAI}/dashboard`,
    ORGANIZATION: `${cadsAI}/organization`,
    EMPLOYEES: `${cadsAI}/employees`,
    ORDER_HISTORY: `${cadsAI}/order-history`,
    DDO: `${cadsAI}/ddo`,
    HELP: `${cadsAI}/help`,
    EMAIL: `${cadsAI}/emails`,

    HOME: {
      BASE: `${cadsAIHome}`,
      ANNOUNCEMENT: `${cadsAIHome}/announcements`,
      NOTIFICATION: `${cadsAIHome}/notifications`,
      ARTICLE: `${cadsAIHome}/articles`,
      EVENTS: `${cadsAIHome}/events`,
      SPONSORSHIP: `${cadsAIHome}/sponsorship`,
      QA: `${cadsAIHome}/qa`,
      SPAM_REQUESTS: `${cadsAIHome}/spam-requests`,
    },
    LEARNING: {
      BASE: `${cadsAILearning}`,
      LEARNING_PATH: `${cadsAILearning}/learning-paths`,
      MODULE: `${cadsAILearning}/modules`,
      COURSE: `${cadsAILearning}/courses`,
      TRAINING_PROVIDERS: `${cadsAILearning}/training-providers`,
      ASSIGN: `${cadsAILearning}/assign`,
      ASSIGNED: `${cadsAILearning}/assigned`,
    },
    DATA_STAR: {
      BASE: `${cadsAIDataStar}`,
      PROGRAMS: `${cadsAIDataStar}/programs`,
      SPONSORS: `${cadsAIDataStar}/sponsors`,
      APPLICANTS: `${cadsAIDataStar}/applicants`,
      DS_COHORT: `${cadsAIDataStar}/dsCohort`,
      DASHBOARD: `${cadsAIDataStar}/dashboard`,
    },
    OPPORTUNITIES: {
      BASE: `${cadsAIOpportunities}`,
      JOBS: `${cadsAIOpportunities}/jobs`,
      PROJECTS: `${cadsAIOpportunities}/projects`,
    },
  },
  DATA_STAR: {
    BASE: dataStar,
    DASHBOARD: `${dataStar}/dashboard`,
    PROGRAMS: `${dataStar}/programs`,
    APPLICANTS: `${dataStar}/applicants`,
  },
};

export const rolesByPath = {
  /*Common Paths*/
  [paths.LOGIN]: [roles.ADMIN, roles.CP_ADMIN, roles.DS_ADMIN, roles.CAMPAIGN_ADMIN], // login link is also acting as logout link, done this especially to logout feature in topbar.
  [paths.CHANGE_PASSWORD]: [roles.ADMIN, roles.CP_ADMIN, roles.DS_ADMIN, roles.CAMPAIGN_ADMIN],

  /*Assessment admin related paths*/
  // [paths.DASHBOARD]: [roles.ADMIN],

  // [paths.CUSTOMER]: [roles.ADMIN],

  [paths.ASSESSMENTS.BASE]: [roles.ADMIN],
  // [paths.ASSESSMENTS.QUESTIONS]: [roles.ADMIN],

  // [paths.ASSIGNMENTS.BASE]: [roles.ADMIN],

  [paths.SKILL_BANK.BASE]: [roles.ADMIN],
  [paths.SKILL_BANK.SKILLS]: [roles.ADMIN],
  [paths.SKILL_BANK.SUB_CATEGORIES]: [roles.ADMIN],
  [paths.SKILL_BANK.DOMAINS]: [roles.ADMIN],
  [paths.SKILL_BANK.QUESTIONS]: [roles.ADMIN],

  // [paths.DATA_BANK]: [roles.ADMIN],

  /*Admin control panel related paths*/
  [paths.CAMPAIGN.BASE]: [roles.CAMPAIGN_ADMIN],
  [paths.CAMPAIGN.USERS]: [roles.CAMPAIGN_ADMIN],
  [paths.CAMPAIGN.SUNRISE25USERS]: [roles.CAMPAIGN_ADMIN],

  [paths.CONTROL_PANEL.BASE]: [roles.CP_ADMIN],
  [paths.CONTROL_PANEL.TRAINING_PROVIDER]: [roles.CP_ADMIN],
  [paths.CONTROL_PANEL.LEARNING_PATH]: [roles.CP_ADMIN],
  [paths.CONTROL_PANEL.MODULE]: [roles.CP_ADMIN],
  [paths.CONTROL_PANEL.BASELINE_THRESHOLD]: [roles.CP_ADMIN],
  [paths.CONTROL_PANEL.ASSIGN]: [roles.CP_ADMIN],
  [paths.CONTROL_PANEL.ASSIGNED]: [roles.CP_ADMIN],

  [paths.CADS_AI.BASE]: [roles.ADMIN],
  [paths.CADS_AI.USERS]: [roles.ADMIN],
  [paths.CADS_AI.ORGANIZATION]: [roles.ADMIN],
  [paths.CADS_AI.EMPLOYEES]: [roles.ADMIN],
  [paths.CADS_AI.ORDER_HISTORY]: [roles.ADMIN],
  [paths.CADS_AI.DDO]: [roles.ADMIN],
  [paths.CADS_AI.HELP]: [roles.ADMIN],
  [paths.CADS_AI.DASHBOARD]: [roles.ADMIN],
  [paths.CADS_AI.EMAIL]: [roles.ADMIN],
  [paths.CADS_AI.HOME.BASE]: [roles.ADMIN],
  [paths.CADS_AI.HOME.ANNOUNCEMENT]: [roles.ADMIN],
  [paths.CADS_AI.HOME.NOTIFICATION]: [roles.ADMIN],
  [paths.CADS_AI.HOME.ARTICLE]: [roles.ADMIN],
  [paths.CADS_AI.HOME.EVENTS]: [roles.ADMIN],
  [paths.CADS_AI.HOME.SPONSORSHIP]: [roles.ADMIN],
  [paths.CADS_AI.HOME.QA]: [roles.ADMIN],
  [paths.CADS_AI.HOME.SPAM_REQUESTS]: [roles.ADMIN],
  [paths.CADS_AI.LEARNING.BASE]: [roles.ADMIN],
  [paths.CADS_AI.LEARNING.LEARNING_PATH]: [roles.ADMIN],
  [paths.CADS_AI.LEARNING.MODULE]: [roles.ADMIN],
  [paths.CADS_AI.LEARNING.COURSE]: [roles.ADMIN],
  [paths.CADS_AI.LEARNING.TRAINING_PROVIDERS]: [roles.ADMIN],
  [paths.CADS_AI.LEARNING.ASSIGN]: [roles.ADMIN],
  [paths.CADS_AI.LEARNING.ASSIGNED]: [roles.ADMIN],
  [paths.CADS_AI.DATA_STAR.BASE]: [roles.ADMIN],
  [paths.CADS_AI.DATA_STAR.PROGRAMS]: [roles.ADMIN],
  [paths.CADS_AI.DATA_STAR.APPLICANTS]: [roles.ADMIN],
  [paths.CADS_AI.DATA_STAR.SPONSORS]: [roles.ADMIN],
  [paths.CADS_AI.DATA_STAR.DS_COHORT]: [roles.ADMIN],
  [paths.CADS_AI.DATA_STAR.DASHBOARD]: [roles.ADMIN],
  [paths.CADS_AI.OPPORTUNITIES.BASE]: [roles.ADMIN],
  [paths.CADS_AI.OPPORTUNITIES.JOBS]: [roles.ADMIN],
  [paths.CADS_AI.OPPORTUNITIES.PROJECTS]: [roles.ADMIN],

  [paths.DATA_STAR.BASE]: [roles.DS_ADMIN],
  [paths.DATA_STAR.DASHBOARD]: [roles.DS_ADMIN],
  [paths.DATA_STAR.PROGRAMS]: [roles.DS_ADMIN],
  [paths.DATA_STAR.APPLICANTS]: [roles.DS_ADMIN],
};
