import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import api from '@/utils/apiClient';
import { useForm } from 'react-hook-form';
import KnowledgeBaseThresholdFields from './KnowledgeBaseThresholdFields';
import KnowledgeBaseQuestionFieldTable, {
  L1_PREFIX,
  L2_PREFIX,
  PS_PREFIX,
} from './KnowledgeBaseQuestionFieldTable';
import KnowledgeBaseAssessmentConfigFormStatus from './KnowledgeBaseAssessmentConfigFormStatus';

const KnowledgeBaseAssessmentConfigForm = ({ assessmentId, backToListView }) => {
  //states
  const [state, setState] = React.useState({
    loading: false,
    fetchingConfig: false,
    isConfigUpdated: false,
    errorMessage: '',
  });
  const [questionSkills, setQuestionSkills] = React.useState([]);

  // prefill the form
  React.useEffect(() => {
    if (assessmentId) {
      setDefaultData(+assessmentId);
    }
  }, [assessmentId]);

  const setDefaultData = async (assessmentId) => {
    try {
      const { data: res, isSuccess } = await api.assessments.getConfigsById(assessmentId);
      if (isSuccess) {
        ['timeLimit', 'scoreToQualify'].forEach((field) => setValue(field, res?.data?.[field]));
        setQuestionSkills(res?.data?.question?.skill);
      } else {
        throw new Error(res?.messages?.[0]);
      }
    } catch ({ message }) {
      console.error('Config edit error : ', message);
      setState({ ...state, errorMessage: 'Error while getting existing config details' });
    }
  };

  // config form related functions
  const {
    handleSubmit,
    reset,
    control,
    trigger,
    setValue,
    formState: { errors },
  } = useForm();

  const resetForm = () => {
    reset({ timeLimit: 0, scoreToQualify: 0 });
  };

  React.useEffect(() => {
    questionSkills?.forEach((skill) => {
      setValue(`${L1_PREFIX}${skill.skillId}`, skill.level1);
      setValue(`${L2_PREFIX}${skill.skillId}`, skill.level2);
      setValue(`${PS_PREFIX}${skill.skillId}`, skill.passingScore);
      trigger(); //async validating form values
    });
  }, [questionSkills]);

  const onSubmit = async ({ timeLimit, scoreToQualify }) => {
    setState({ ...state, loading: true });
    const defaultError = `Error occurred while updating configuration`;
    try {
      const { data, isSuccess } = await api.assessments.editConfigs({
        assessmentId,
        timeLimit: +timeLimit,
        scoreToQualify: +scoreToQualify,
        question: { skill: questionSkills },
      });
      if (isSuccess) {
        setState({ ...state, isConfigUpdated: true, loading: false });
      } else {
        throw new Error(data?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({ ...state, loading: false, isConfigUpdated: false, errorMessage: error.message });
    }
  };

  const onCancel = () => {
    resetForm();
  };

  const formFiledProps = {
    control,
    errors,
  };

  return (
    <div>
      {/*Add/Edit Config Form*/}
      <form className={`form`} onSubmit={handleSubmit(onSubmit)}>
        <KnowledgeBaseThresholdFields {...formFiledProps} />
        <KnowledgeBaseQuestionFieldTable
          {...formFiledProps}
          skills={questionSkills}
          setSkills={setQuestionSkills}
        />
        <KnowledgeBaseAssessmentConfigFormStatus formState={state} />
        <ButtonToolbar className="form__button-toolbar modal__footer">
          <Button disabled={state.loading} color="primary" type="submit">
            Submit
          </Button>
          <Button type="button" color="secondary" onClick={backToListView}>
            Back To Assessment List
          </Button>
        </ButtonToolbar>
      </form>
    </div>
  );
};

export default KnowledgeBaseAssessmentConfigForm;
