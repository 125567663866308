import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const setTrainingProviderFetching = createAction('SET_TRAINING_PROVIDER_FETCHING');
export const setTrainingProviderError = createAction('SET_TRAINING_PROVIDER_ERROR');
export const setAssignTrainingProviderList = createAction('SET_ASSIGN_TRAINING_PROVIDER_LIST');
export const setTrainingProviderFilters = createAction('SET_TRAINING_PROVIDER_LIST_FILTER');
export const toggleCheckedTrainingProvider = createAction('TOGGLE_CHECKED_TRAINING_PROVIDER');
export const clearCheckedTrainingProvider = createAction('CLEAR_SELECTED_TRAINING_PROVIDER');
export const setTrainingProviderGroupOptions = createAction('SET_TRAINING_PROVIDER_GROUP_OPTIONS');
export const clearTrainingProviderFilters = createAction('CLEAR_TRAINING_PROVIDER_FILTERS');
export const updateTrainingProviderInList = createAction('UPDATE_TRAINING_PROVIDER_IN_LIST');

export const getCPTrainingProvidersList = (filter) => async (dispatch) => {
  const defaultError = `Error occurred getting training provider list`;
  dispatch(setTrainingProviderFetching());
  try {
    const { data, isSuccess } = await api.moduleSchedules.getTrainingProviderOptionList();
    if (isSuccess) dispatch(setAssignTrainingProviderList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setTrainingProviderError(error.message));
    console.error(defaultError, error.message);
  }
};
