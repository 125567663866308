import React, { Fragment } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import { Button, FormGroup, Input } from 'reactstrap';
import ViewModuleIcon from 'mdi-react/ViewModuleIcon';
import PencilIcon from 'mdi-react/PencilIcon';
import { useTrainingProviderUIContext } from '../TrainingProviderUIProvider';
import TrainingProviderTableColumns, {
  getTrainingProviderColumns,
} from './TrainingProviderTableColumns';
import { TrainingProviderTableSearchBar } from './TrainingProviderTableSearchBar';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getCPTrainingProvidersList,
  setTrainingProviderFilters,
  toggleCheckedTrainingProvider,
} from '../../redux/actions';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { defaultPaginationProps } from '@/shared/helpers';
import { setActiveTab } from '../../../Assign/redux/actions';
import * as Constants from '@/shared/helpers/constants';

const TrainingProviderTable = () => {
  const trainingProviderUIContext = useTrainingProviderUIContext();
  const dispatch = useDispatch();
  const {
    loading,
    filters,
    totalRecords,
    assignTrainingProviders: trainingProviders,
    checkedTrainingProvider,
    error,
  } = useSelector((state) => state.cpTrainingProviders, shallowEqual);
  const {
    pageSize: rowsPerPage,
    pageNumber: page,
    sortBy: orderBy,
    isSortOrderDescending,
  } = filters;

  React.useEffect(() => {
    dispatch(getCPTrainingProvidersList(filters));
    dispatch(setActiveTab(Constants.ORGANIZATION));
  }, [filters]);

  const handleRequestSort = (event, property) => {
    dispatch(
      setTrainingProviderFilters({
        sortBy: property,
        isSortOrderDescending: !isSortOrderDescending,
      }),
    );
  };

  const handleChangePage = (event, currentPage) => {
    dispatch(setTrainingProviderFilters({ pageSize: rowsPerPage, pageNumber: currentPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setTrainingProviderFilters({ pageSize: +event.target.value, pageNumber: 1 }));
  };

  const emptyRows =
    page === Math.ceil(totalRecords / rowsPerPage) ? rowsPerPage - trainingProviders.length : 0;

  const TrainingProviderColumns = getTrainingProviderColumns(
    trainingProviderUIContext.isForAssignment,
  );

  const renderActionButtons = ({ id: trainingProviderId }) => (
    <div className="actions">
      <Button
        className="icon mb-0"
        color="primary"
        outline
        onClick={() => trainingProviderUIContext.openEditTrainingProviderDialog(trainingProviderId)}
      >
        <p>
          <PencilIcon /> Edit
        </p>
      </Button>
    </div>
  );

  return (
    <Fragment>
      <div className="material-table__wrap">
        <TrainingProviderTableSearchBar />
        <Table className="material-table">
          <TrainingProviderTableColumns
            isForAssignment={trainingProviderUIContext.isForAssignment}
            order={isSortOrderDescending ? 'desc' : 'asc'}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rowsPerPage}
          />
          <TableBody>
            {(loading || !!error) && (
              <TableRow>
                <TableCell colSpan={TrainingProviderColumns.length}>
                  {loading && (
                    <div className={'text-center overflow-hidden'}>
                      <div className="panel__refresh position-relative" style={{ height: 75 }}>
                        <LoadingIcon />
                      </div>
                    </div>
                  )}
                  <div className={'text-center'}>
                    <p className={!!error ? 'm-5' : ''}>
                      {error || 'Loading trainingProviders...'}
                    </p>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {!!!trainingProviders.length && !loading && !error && (
              <TableRow style={{ height: 120 }}>
                <TableCell colSpan={TrainingProviderColumns.length} align="center">
                  {filters.status === null && filters.search === ''
                    ? 'No training providers'
                    : 'No training providers with applied filters'}
                </TableCell>
              </TableRow>
            )}
            {trainingProviders?.map((trainingProvider) => {
              return (
                <TableRow className="material-table" tabIndex={-1} key={trainingProvider.id}>
                  {trainingProviderUIContext.isForAssignment && (
                    <TableCell align="center" className="material-table__cell">
                      <FormGroup>
                        <Input
                          className="table-checkbox"
                          type="checkbox"
                          value={trainingProvider.id}
                          checked={checkedTrainingProvider.id === trainingProvider.id}
                          onChange={() => dispatch(toggleCheckedTrainingProvider(trainingProvider))}
                        />
                      </FormGroup>
                    </TableCell>
                  )}
                  <TableCell
                    align="center"
                    className="material-table__cell"
                    className="tp_logo_image"
                  >
                    {trainingProvider?.logoUrl?.startsWith('http') ? (
                      <img
                        src={trainingProvider?.logoUrl}
                        style={{ width: '70%' }}
                        alt="training provider logo"
                      />
                    ) : (
                      <span className="lp_default_logo">
                        <ViewModuleIcon />
                      </span>
                    )}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {trainingProvider?.name}
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={TrainingProviderColumns.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        component={defaultPaginationProps.component}
        backIconButtonProps={defaultPaginationProps.backIconButton}
        nextIconButtonProps={defaultPaginationProps.nextIconButton}
        rowsPerPageOptions={defaultPaginationProps.rowsPerPage}
        className={defaultPaginationProps.className}
        dir={defaultPaginationProps.dir}
      />
    </Fragment>
  );
};

export default TrainingProviderTable;
