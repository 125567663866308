import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, ButtonToolbar } from 'reactstrap';
import { convertUrlToFile } from '@/shared/helpers';
import { FormField } from '@/shared/components/form/hook/FormField';
import { extractFieldData } from '../index';
import renderDropZoneField from '@/shared/components/form/DropZone';

const StepTwo = ({
  onSubmit,
  page,
  data,
  setPrerequisiteModulesList,
  prerequisiteModulesList,
  selectedModule,
  id,
  previousPage,
}) => {
  //states

  /*Commented out pre-requisite Logic*/
  /*

  const [prerequisiteModuleOptions, setPrerequisiteModuleOptions] = React.useState([]); // pre-req modules available to select
  const [prerequisiteModules, setPrerequisiteModules] = React.useState([]); // all pre-req modules

    const getPrerequisiteModuleOptions = async () => {
    const { data: res, isSuccess } = await api.cadsAIModule.getPrerequisiteModuleOptions();
    if (isSuccess) {
      setPrerequisiteModules(res.data.map((module) => ({ value: module.id, label: module.title, ...module })),);
      }
   };

  React.useEffect(() => {
      if (id) {
        setPrerequisiteModulesList(
          selectedModule?.prerequisiteModules?.map((module) => ({
            value: module.id,
            label: module.title,
            ...module,
          })) ?? [],
        );
        const selectedModuleIds =
          selectedModule?.prerequisiteModules?.map((module) => module.id) ?? [];
        setPrerequisiteModuleOptions(
          prerequisiteModules
            ?.filter((m) => !selectedModuleIds.includes(m.id))
            .map((module) => ({ label: module.title, value: module.id, ...module })),
        );
      } else {
        setPrerequisiteModuleOptions(
          prerequisiteModules?.map((module) => ({
            label: module.title,
            value: module.id,
            ...module,
          })),
        );
      }
    }, [prerequisiteModules, selectedModule]);

      React.useEffect(() => {
    getPrerequisiteModuleOptions();
  }, []);



      const addToPreReqModuleList = async () => {
      const { prerequisiteModule, isMandatory } = getValues();
      if (isEmpty(isMandatory) || isEmpty(prerequisiteModule)) return;
      setPrerequisiteModulesList([
        ...prerequisiteModulesList,
        { ...prerequisiteModule, isMandatory: isMandatory?.value },
      ]);
      setValue('prerequisiteModule', '');
      setValue('isMandatory', { value: false, label: 'No' });
      setPrerequisiteModuleOptions(
        prerequisiteModuleOptions.filter((opt) => opt.value !== prerequisiteModule?.value),
      );
    };

    const removePreReqModuleFromList = (module) => {
      setPrerequisiteModuleOptions([...prerequisiteModuleOptions, module]);
      setPrerequisiteModulesList([
        ...prerequisiteModulesList.filter(({ value }) => value !== module.value),
      ]);
    };
  */

  // learningPath form related functions
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    getValues,
  } = useForm(extractFieldData(page, data));

  const setImage = async () => {
    if (data?.imageUrl) {
      const imgFile = await convertUrlToFile(data?.imageUrl);
      setValue('imageUrl', imgFile ? [imgFile] : '');
    }
  };

  React.useEffect(() => {
    // getStepFields(page).forEach((key) => {
    //   setValue(key, data[key]);
    // });
    setImage();
  }, [data]);

  return (
    <div className="modal__body add-edit-learning-path">
      <form className={`form form--horizontal`} onSubmit={handleSubmit(onSubmit)}>
        <div className="form__form-group">
          <span className="form__form-group-label">Image</span>
          <div className="form__form-group-field w-75">
            <FormField
              name="imageUrl"
              as="input"
              placeholder="Enter Image"
              rules={{ required: 'This is required field' }}
              errors={errors}
              isAboveError
              control={control}
              component={renderDropZoneField}
              defaultValue=""
            />
          </div>
        </div>
        {/*Commented out pre-requisite UI*/}
        {/*<div className="form__form-group lp_edit_module_error" style={{ width: '60%' }}>
          <span className="form__form-group-label" style={{ minWidth: 'fit-content' }}>
            Pre-Requisite Modules
          </span>
          <FormField
            style={{ height: 40 }}
            name="prerequisiteModule"
            control={control}
            component={SelectField}
            options={prerequisiteModuleOptions}
            placeholder="Select pre-requisite module"
          />
        </div>
        <div className="form__form-group ml-2" style={{ width: '24%' }}>
          <span className="form__form-group-label">Is Mandatory?</span>
          <FormField
            name="isMandatory"
            style={{ height: 40 }}
            control={control}
            component={SelectField}
            errors={errors}
            options={[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' },
            ]}
            defaultValue={{ value: false, label: 'No' }}
            isAboveError
          />
        </div>
        <div className="form__form-group" style={{ width: '10%' }}>
          <div className="competency_add_button">
            <Button
              color="success"
              type="button"
              outline
              style={{ marginLeft: 20, height: 38 }}
              onClick={addToPreReqModuleList}
            >
              Add
            </Button>
          </div>
        </div>

        <div className="competency_add_section">
          {prerequisiteModulesList.map((module) => (
            <p key={module.value}>
              <span
                onClick={() => removePreReqModuleFromList(module)}
                onMouseEnter={(e) => (e.target.className = 'lnr lnr-trash')}
                onMouseOut={(e) => (e.target.className = 'lnr lnr-checkmark-circle')}
                style={{
                  color: getColorForWeight({ random: true }),
                  cursor: 'pointer',
                }}
                className="lnr lnr-checkmark-circle"
              />{' '}
              {module.label} {module?.isMandatory && '(mandatory)'}
            </p>
          ))}
        </div>*/}
        <ButtonToolbar className="form__button-toolbar modal__footer">
          <Button onClick={previousPage} type="button" className="previous">
            Back
          </Button>
          <Button color="primary" type="submit">
            Next
          </Button>
        </ButtonToolbar>
      </form>
    </div>
  );
};

export default StepTwo;
