import React from 'react';
import ForgotPasswordForm from './ForgotPasswordForm';

const ForgotPassword = (props) => (
  <div className="account account--not-photo">
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__head">
          <p className="topbar__logo mx-auto" style={{ height: '55px' }} />
        </div>
        <h4 className="account__subhead subhead text-center mb-4">Forget Password</h4>
        <ForgotPasswordForm form="log_in_form" />
      </div>
    </div>
  </div>
);

export default ForgotPassword;
