import React from 'react';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { FormField } from '@/shared/components/form/hook/FormField';
import { emptyStringPattern, getColorForWeight } from '@/shared/helpers';
import { Button } from 'reactstrap';
import api from '@/utils/apiClient';
import { CreatableSelectField } from '@/shared/components/form/CreatableSelectField';
import { isEmpty } from 'lodash/lang';
import { useForm } from 'react-hook-form';

const ProgramAssociatedCohortTab = ({ programId, formProps, data: { cohortList } }) => {
  const { control, setValue, errors, getValues } = useForm();

  //states
  const initialState = {
    loading: false,
    newCohortAdded: false,
    cohortDeleted: false,
    errorMessage: '',
  };
  const [state, setState] = React.useState(initialState);
  const [cohortsOptions, setCohortsOptions] = React.useState([]);
  const [visibleCohortOptions, setVisibleCohortOptions] = React.useState([]);
  const [associatedCohorts, setAssociatedCohorts] = React.useState([]);

  const getCohortOptionList = async () => {
    const { data: res, isSuccess } = await api.dsCohort.getDSCohortListOptions();
    if (isSuccess) {
      const cohortOptions = res?.data?.map((cohort) => ({ value: cohort.id, label: cohort.title }));
      setCohortsOptions(cohortOptions);
    } else {
      setCohortsOptions([]);
    }
  };

  React.useEffect(() => {
    getCohortOptionList();
  }, []);

  React.useEffect(() => {
    setAssociatedCohorts([...cohortList]);
  }, [cohortList]);

  React.useEffect(() => {
    formProps.setValue('cohortList', associatedCohorts);
    const associatedCohortIds = associatedCohorts.map((cohort) => +cohort.value);
    setVisibleCohortOptions(
      cohortsOptions.filter((cohort) => !associatedCohortIds.includes(+cohort.value)),
    );
  }, [associatedCohorts, cohortsOptions]);

  const addToAssociatedCohorts = async () => {
    const selectedCohort = getValues('cohort');
    if (isEmpty(selectedCohort)) return;
    const cohortData = {
      id: selectedCohort.__isNew__ ? null : selectedCohort.value,
      title: selectedCohort.label,
    };
    setState({ ...state, loading: true });
    const defaultError = `Error occurred while adding new cohort to program`;
    try {
      let res, isSuccess;
      if (programId) {
        const response = await api.programs.attachCohortToProgram(programId, cohortData);
        isSuccess = response.isSuccess;
        res = response.data;
      } else {
        isSuccess = true;
        res = { data: null };
      }
      if (isSuccess) {
        setState({ ...state, newCohortAdded: true, cohortDeleted: false, loading: false });
        setValue('cohort', []); // empty selected cohort
        const newCohort = { value: cohortData.id ?? res.data, label: cohortData?.title };
        const newAssociatedCohorts = [...associatedCohorts, newCohort];
        setAssociatedCohorts(newAssociatedCohorts); // add skill to category's associatedSkill
        if (!cohortData.id) {
          setCohortsOptions([...cohortsOptions, newCohort]);
        }
      } else {
        throw new Error(res?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({ ...state, loading: false, newCohortAdded: false, errorMessage: error.message });
    }
  };

  const deleteAssociatedCohort = async (cohortId) => {
    setState({ ...state, loading: true });
    const defaultError = `Error occurred removing cohort from program`;
    try {
      let res, isSuccess;
      if (programId) {
        const response = await api.programs.detachCohortFromProgram(programId, cohortId);
        isSuccess = response.isSuccess;
        res = response.data;
      } else {
        isSuccess = true;
        res = { data: null };
      }
      if (isSuccess) {
        setState({
          ...state,
          cohortDeleted: true,
          newCohortAdded: false,
          loading: false,
          errorMessage: '',
        });
        setValue('cohort', []); // empty selected cohort
        setAssociatedCohorts(associatedCohorts.filter((aCohort) => aCohort.value !== cohortId));
      } else {
        throw new Error(res?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({ ...state, loading: false, cohortDeleted: false, errorMessage: error.message });
    }
  };

  return (
    <div className={'form'} style={{ minHeight: 200 }}>
      <div>
        {state.loading && (
          <div className="panel__refresh position-relative" style={{ height: 75, width: 20 }}>
            <LoadingIcon />
            <div style={{ width: 200, padding: '23px 40px' }}>Adding new cohort</div>
          </div>
        )}
        {(state.newCohortAdded || state.cohortDeleted || !!state.errorMessage) && (
          <table className="mt-3">
            <tr>
              {!state.loading && (
                <td>
                  {state.newCohortAdded || state.cohortDeleted ? (
                    <span className="lnr lnr-thumbs-up modal__title-icon success" />
                  ) : (
                    <span className="lnr lnr-cross-circle modal__title-icon error" />
                  )}
                </td>
              )}
              <td className="pl-2">
                {state.newCohortAdded && !state.loading
                  ? 'New cohort Added Successfully'
                  : state.errorMessage}
                {state.cohortDeleted && !state.loading
                  ? 'Cohort removed Successfully'
                  : state.errorMessage}
              </td>
            </tr>
          </table>
        )}
      </div>

      <div className="form__form-group" style={{ width: '84%' }}>
        <span className="form__form-group-label">Select Cohort</span>
        <FormField
          style={{ height: 40 }}
          control={control}
          name="cohort"
          component={CreatableSelectField}
          options={visibleCohortOptions}
          rules={{
            required: 'This is required field',
            pattern: emptyStringPattern,
          }}
          errors={errors}
          defaultValue=""
          isAboveError={true}
          placeholder="Search and select cohort"
        />
      </div>
      <div className="form__form-group" style={{ width: '10%' }}>
        <div className="skill_add_button">
          <Button
            onClick={addToAssociatedCohorts}
            color="success"
            type="button"
            outline
            style={{ marginLeft: 20, height: 38 }}
          >
            Add
          </Button>
        </div>
      </div>
      <div className="skill_add_section">
        {associatedCohorts?.map(({ value: id, label: name }) => (
          <p key={id}>
            <span
              onClick={() => deleteAssociatedCohort(id)}
              onMouseEnter={(e) => (e.target.className = 'lnr lnr-trash')}
              onMouseOut={(e) => (e.target.className = 'lnr lnr-checkmark-circle')}
              style={{ color: getColorForWeight({ random: true }), cursor: 'pointer' }}
              className="lnr lnr-checkmark-circle"
            />{' '}
            {name}
          </p>
        ))}
      </div>
    </div>
  );
};

export default ProgramAssociatedCohortTab;
