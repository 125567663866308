import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const fetchEventList = createAction('CADS_AI_FETCH_EVENT_LIST');
export const setEventList = createAction('CADS_AI_SET_EVENT_LIST');
export const setEventError = createAction('CADS_AI_SET_EVENT_ERROR');
export const setEventFilters = createAction('CADS_AI_SET_EVENT_FILTERS');
export const toggleCheckedEvent = createAction('CADS_AI_TOGGLE_CHECKED_EVENT');
export const uncheckAllEvent = createAction('CADS_AI_UNCHECK_ALL_EVENT');

export const toggleCheckedItemById = (eventId) => (dispatch) => {
  dispatch(toggleCheckedEvent(eventId));
};

export const getEventList = (filter) => async (dispatch) => {
  const defaultError = `Error occurred getting event list`;
  dispatch(fetchEventList());
  try {
    const { data, isSuccess } = await api.cadsAIEvent.getEventList(filter);
    if (isSuccess) dispatch(setEventList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setEventError(error.message));
    console.error(defaultError, error.message);
  }
};
