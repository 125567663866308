import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon';
import React from 'react';
import { Card } from 'reactstrap';
import api from '@/utils/apiClient';
import { useNotificationUIContext } from '../NotificationUIProvider';

const NotificationViewPage = ({ id }) => {
  const notificationUIContext = useNotificationUIContext();
  const [state, setState] = React.useState({
    loading: false,
    notification: {},
    error: '',
  });

  const getNotificationById = async (id) => {
    try {
      setState({ ...state, loading: true });
      const { data: res, isSuccess } = await api.cadsAINotification.getNotificationById(id);
      if (isSuccess) {
        setState({ ...state, loading: false, notification: res.data, error: '' });
      } else {
        throw new Error(res?.messages?.[0] ?? 'Error while getting notification details');
      }
    } catch ({ message }) {
      setState({ ...state, loading: false, notification: {}, error: message });
      console.error(message);
    }
  };

  React.useEffect(() => {
    getNotificationById(id);
  }, [id]);

  const { notification } = state;

  const goToNotificationPage = () => {
    notificationUIContext.openNotifications();
  };

  return (
    <Card className="card-body">
      <div className="qa-arrow-icon">
        <ArrowLeftIcon style={{ width: '34px', height: '34px' }} onClick={goToNotificationPage} />
      </div>
      <div className="dashboard__info-title">
        <h2 className="page-title mb-2">{notification?.title} </h2>
        <span className="bread-crumb-title">{`${notification?.description}`}</span>
      </div>
    </Card>
  );
};

export default NotificationViewPage;
