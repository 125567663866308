import React, { useEffect, useState } from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import FormField from '@/shared/components/form/hook/FormField';
import { SelectField } from '@/shared/components/form/Select';
import api from '@/utils/apiClient';

export function getStepFields() {
  return ['id', 'program_application_status'];
}

export function extractFieldData(data) {
  const obj = {};
  getStepFields().forEach((key) => (obj[key] = data[key]));
  return obj;
}

const ApplicantEditForm = ({ onSubmit, data, modal }) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm(extractFieldData(data));

  React.useEffect(() => {
    getStepFields().forEach((key) => setValue(key, data[key]));
  }, [data]);

  const [applicantsStatusOption, setApplicantsStatusOption] = useState([]);

  const getProgramApplicationStatusOptions = async () => {
    try {
      const { data: data, isSuccess } = await api.applicants.getProgramApplicationStatusOptions();
      if (isSuccess) {
        data.data.map((item) => {
          setApplicantsStatusOption((applicantsStatusOption) => [
            ...applicantsStatusOption,
            { value: item.id, label: item.name },
          ]);
        });
      } else {
        throw new Error('error while fetching applicant dashboard status');
      }
    } catch (error) {
      console.error('Error inside dashboard applicant type', error.message);
    }
  };

  useEffect(() => {
    getProgramApplicationStatusOptions();
  }, []);

  return (
    <Row>
      <Col md={12} lg={12}>
        <Card className="pb-0">
          <CardBody>
            <div className="form-wrapper">
              <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form__form-group">
                  <span className="form__form-group-label">Status</span>
                  <div className="form__form-group-field">
                    <FormField
                      control={control}
                      name="program_application_status"
                      component={SelectField}
                      options={applicantsStatusOption}
                      placeholder="Select status"
                      rules={{ required: 'This is required field' }}
                      errors={errors}
                      isAboveError
                    />
                  </div>
                </div>
                <ButtonToolbar className="modal__footer btn-toolbar">
                  <Button color="primary" type="submit" className="btn btn-primary">
                    Submit
                  </Button>
                  <Button
                    color="secondary"
                    type="button"
                    className="modal_cancel btn btn-secondary"
                    onClick={() => modal.hide()}
                  >
                    Close
                  </Button>
                </ButtonToolbar>
              </form>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default ApplicantEditForm;
