import React from 'react';
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon';
import { useOrganizationUIContext } from '../../OrganizationUIProvider';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { ButtonToolbar, Card, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import CompanyBenefits from './CompanyBenefits';
import classnames from 'classnames';
import { getOrganizationsList } from '@/containers/Cads-AI/redux/organization/actions';
import { OrganizationDetails } from './OrganizationDetails';
import CompanyPhotos from './CompanyPhotos';

const PlatformDetails = ({ id }) => {
  const orgUIContext = useOrganizationUIContext();
  const [activeTab, setActiveTab] = React.useState('organization');
  const dispatch = useDispatch();
  const orgList = useSelector((state) => state.cadsAIOrgs?.data, shallowEqual);
  const [state, setState] = React.useState({
    loading: false,
    organization: {},
    error: '',
  });

  const organizationVisitTabs = [
    {
      name: 'Organization',
      value: 'organization',
    },
    {
      name: 'Company Benefits',
      value: 'companyBenefits',
    },
    {
      name: 'Company Photos',
      value: 'images',
    },
  ];

  React.useEffect(() => {
    dispatch(getOrganizationsList());
  }, []);

  const getOrganizationById = async (organizationId) => {
    setState({
      ...state,
      loading: false,
      organization: orgList.find((org) => org.id === organizationId) ?? {},
      error: '',
    });
  };

  React.useEffect(() => {
    getOrganizationById(id);
    setActiveTab('organization');
  }, [id]);

  const { organization } = state;

  const goToOrgPage = () => {
    orgUIContext.openViewAllOrganizationList();
  };

  const toggle = (tab) => {
    if (activeTab !== tab) {
      let update = { activeTab: tab };
      if (tab === 'organization') {
        update.isProfileRefresh = true;
      } else {
        update.isSkillsRefresh = true;
      }
      setActiveTab(update.activeTab);
    }
  };

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <div className="profile__card tabs tabs--bordered-bottom p-2">
          <div className="tabs__wrap mt-2">
            <div>
              <ButtonToolbar className="button-placement">
                <ArrowLeftIcon color="primary" outline onClick={goToOrgPage}>
                  Back To Users List
                </ArrowLeftIcon>
              </ButtonToolbar>
              <div className="dashboard__info-title">
                <h2 className="page-title mb-2">{organization?.title ?? 'Org'}</h2>
              </div>
            </div>
            <Nav tabs>
              {organizationVisitTabs.map((opportunityTab, index) => {
                return (
                  <NavItem key={index}>
                    <NavLink
                      className={classnames({
                        active: activeTab === opportunityTab?.value,
                      })}
                      onClick={() => {
                        toggle(opportunityTab?.value);
                      }}
                    >
                      {opportunityTab.name}
                    </NavLink>
                  </NavItem>
                );
              })}
            </Nav>
            <TabContent className="tabs__opportunities overflow-hidden" activeTab={activeTab}>
              <TabPane tabId="organization">
                {activeTab === 'organization' && (
                  <OrganizationDetails organization={organization} />
                )}
              </TabPane>
              <TabPane tabId="companyBenefits">
                {activeTab === 'companyBenefits' && <CompanyBenefits organization={organization} />}
              </TabPane>
              <TabPane tabId="images">
                {activeTab === 'images' && <CompanyPhotos organization={organization} />}
              </TabPane>
            </TabContent>
          </div>
        </div>
      </Card>
    </Col>
  );
};

export default PlatformDetails;
