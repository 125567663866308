import { useEffect } from 'react';
import Highcharts from 'highcharts';
import Tree from 'highcharts/modules/treemap';
import exporting from 'highcharts/modules/exporting';
import data from 'highcharts/modules/data';
import Heatmap from 'highcharts/modules/heatmap';

Tree(Highcharts);
data(Highcharts);
Heatmap(Highcharts);
exporting(Highcharts);

const JobsFollowersFunction = () => {
  const initialChart = () => {
    Highcharts.chart('functions', {
      chart: {
        type: 'pie',
      },
      title: {
        text: 'Followers by Job Function',
      },

      accessibility: {
        announceNewData: {
          enabled: true,
        },
        point: {
          valueSuffix: '%',
        },
      },

      credits: {
        enabled: false,
      },

      colors: ['#2d76a0', '#2779b8', '#4ca0cd', '#99c9e2'],

      plotOptions: {
        pie: {
          showInLegend: true,
        },
        series: {
          enableMouseTracking: false,
          dataLabels: {
            enabled: true,
            format: '{point.name}: {point.y:.1f}%',
          },
        },
      },

      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>',
      },

      series: [
        {
          name: '',
          colorByPoint: true,
          data: [
            {
              name: 'Advertising',
              y: 10,
            },
            {
              name: 'Consulting',
              y: 7,
            },
            {
              name: 'Financial',
              y: 7,
            },
            {
              name: 'Marketing',
              y: 76,
            },
          ],
        },
      ],
    });
  };

  useEffect(() => {
    initialChart();
  }, []);

  return <div id="functions" />;
};

export default JobsFollowersFunction;
