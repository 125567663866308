import React from 'react';
import GeneralAssessmentConfigTopbar from './topbar/GeneralAssessmentConfigTopbar';
import GeneralAssessmentConfigPages from './pages/GeneralAssessmentConfigPages';
import { memo } from 'react';
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  LikertViewOptions,
  logoPositions,
  logoSizeOptions,
  questionTypeOptions,
  questionTypes,
} from '@/containers/Admin/Assessments/components/assessment-config-card/general-assessment-config/options';
import api from '@/utils/apiClient';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { setAssessmentConfig } from '@/containers/Admin/Assessments/components/assessment-config-card/general-assessment-config/redux/actions';
import { base64StringToFile } from '@/shared/helpers';
import { defaultState } from '@/containers/Admin/Assessments/components/assessment-config-card/general-assessment-config/redux/reducer';
const GeneralAssessmentConfigForm = ({ assessmentId }) => {
  const dispatch = useDispatch();
  const assessmentConfig = useSelector((state) => state.assessmentConfig);
  const [state, setState] = React.useState({
    loading: false,
    fetchingConfig: false,
    isConfigUpdated: false,
    message: '',
  });

  React.useEffect(() => {
    if (assessmentId) {
      getAssessmentConfig(assessmentId);
    }
  }, [assessmentId]);

  React.useEffect(() => {
    return () => {
      dispatch(setAssessmentConfig(defaultState));
    };
  }, []);

  const getQuestionTypeData = (question) => {
    switch (question.typeId) {
      case questionTypes.MCQ_SINGLE_SELECT.value:
      case questionTypes.MCQ_MULTI_SELECT.value:
      case questionTypes.LIKERT.value:
      case questionTypes.DROPDOWN.value:
        return {
          mcqMultiple: {
            allowOther: question.allowOther,
            view:
              LikertViewOptions.HORIZONTAL === question.viewOfLinkert
                ? LikertViewOptions.HORIZONTAL
                : LikertViewOptions.VERTICAL,
            answers: JSON.parse(question.questionOptions),
          },
        };
      case questionTypes.CONTACT_INFO.value:
        return {
          contactInfo: {
            contactFields: JSON.parse(question.questionOptions),
          },
        };
      case questionTypes.MATRIX_RATING_QUESTION.value:
        return {
          matrixRatingQuestion: JSON.parse(question.questionOptions),
        };
      case questionTypes.SCALE_RATING_QUESTION.value:
        return {
          scalingRatingQuestion: {
            ratingStartFrom: question.scaleRateStartValue,
            ratingEndAt: question.scaleRateEndValue,
          },
        };
      case questionTypes.FILE_UPLOAD.value:
        return {
          fileUpload: {
            image: [base64StringToFile(question.filePath)],
            imageBase64: question.filePath,
          },
        };
      default:
        return '';
    }
  };

  const getAssessmentConfig = async (assessmentId) => {
    setState({ ...state, loading: true, message: 'Getting assessment configs...' });
    const { data: res, isSuccess } = await api.assessments.getGeneralAssessmentConfig(assessmentId);
    if (!isSuccess) {
      setState({ ...state, loading: false, message: 'Error while getting assessment configs...' });
    }
    const config = res.data?.[0];
    dispatch(
      setAssessmentConfig({
        timeLimit: config.timeLimit,
        logo: {
          image: [base64StringToFile(config.logo)], // arrays of image file (only one image)
          imageBase64: config.logo,
          size: logoSizeOptions.find((logo) => logo.value === config.logoSize),
          position: logoPositions.find((pos) => pos.value === config.logoAlignment),
        },
        pages: config.pages?.map((page) => ({
          id: page.id,
          title: page.pageTitle,
          description: page.description,
          footer: page.pageFooter,
          questions: page.questions?.map((que, idx) => ({
            id: new Date().getTime() + '-' + idx,
            questionText: que.title,
            questionType: questionTypeOptions.find((qType) => qType.value === que.typeId),
            skipAllowed: que.allowSkip,
            typeData: getQuestionTypeData(que),
          })),
        })),
      }),
    );
    setState({ ...state, loading: false, message: '' });
  };

  const getOptionsFromType = (questionTypeId, typeData) => {
    if (!questionTypeId || !typeData) return '';
    switch (questionTypeId) {
      case questionTypes.MCQ_SINGLE_SELECT.value:
      case questionTypes.MCQ_MULTI_SELECT.value:
      case questionTypes.LIKERT.value:
      case questionTypes.DROPDOWN.value:
        return JSON.stringify(typeData?.mcqMultiple?.answers ?? []);
      case questionTypes.CONTACT_INFO.value:
        return JSON.stringify(typeData?.contactInfo?.contactFields ?? []);
      case questionTypes.MATRIX_RATING_QUESTION.value:
        return JSON.stringify(typeData?.matrixRatingQuestion ?? {});
      default:
        return '';
    }
  };

  const getSavedAssessmentConfig = () => {
    return {
      assessmentId,
      timeLimit: assessmentConfig.timeLimit,
      logo: assessmentConfig?.logo?.imageBase64 ?? '',
      logoSize: assessmentConfig?.logo?.size?.value ?? '',
      logoAlignment: assessmentConfig?.logo?.position?.value ?? '',
      pages: assessmentConfig.pages?.map((page, idx) => ({
        id: page.id ?? idx + 1,
        pageIndex: idx,
        pageTitle: page.title,
        description: page.description,
        pageFooter: page.footer,
        questions: page.questions?.map((question, idxq) => ({
          id: new Date().getTime() + '-' + idx + '-' + idxq,
          title: question.questionText,
          typeId: question.questionType?.value,
          allowSkip: question.skipAllowed,
          allowOther: question?.typeData?.mcqMultiple?.allowOther ?? false,
          filePath: question?.typeData?.fileUpload?.imageBase64 ?? '',
          viewOfLinkert: question?.typeData?.mcqMultiple?.view ?? '',
          scaleRateStartValue: question?.typeData?.scalingRatingQuestion?.ratingStartFrom ?? 0,
          scaleRateEndValue: question?.typeData?.scalingRatingQuestion?.ratingEndAt ?? 0,
          questionOptions: getOptionsFromType(question.questionType?.value, question?.typeData),
        })),
      })),
    };
  };

  const saveAssessmentConfig = async () => {
    const reqData = getSavedAssessmentConfig();
    setState({ ...state, loading: true, message: 'Saving assessment config...' });
    const defaultError = `Error occurred while updating configuration`;
    try {
      const { data, isSuccess } = await api.assessments.editGeneralAssessmentConfig(reqData);
      if (isSuccess) {
        setState({
          ...state,
          isConfigUpdated: true,
          loading: false,
          message: 'Assessment config saved successfully!',
        });
      } else {
        throw new Error(data?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({ ...state, loading: false, isConfigUpdated: false, message: error.message });
    }
  };

  return (
    <div className="ga_config_form">
      {!state.loading && (
        <>
          <GeneralAssessmentConfigTopbar
            timeLimit={assessmentConfig.timeLimit}
            logo={assessmentConfig.logo}
          />
          <GeneralAssessmentConfigPages />
        </>
      )}
      {state.loading && (
        <div className={'text-center overflow-hidden'}>
          <div className="panel__refresh position-relative" style={{ height: 75 }}>
            <LoadingIcon />
          </div>
        </div>
      )}
      {!!state.message && (
        <div className={'text-center'}>
          <p>{state.message}</p>
        </div>
      )}
      <Button
        color="success"
        disabled={state.loading}
        className="float-right"
        type="button"
        onClick={saveAssessmentConfig}
      >
        Save Assessment Configuration
      </Button>
    </div>
  );
};

export default memo(GeneralAssessmentConfigForm);
