import LoadingIcon from 'mdi-react/LoadingIcon';
import React from 'react';

const KnowledgeBaseAssessmentConfigFormStatus = ({ formState }) => (
  <div className="ml-4 w-100">
    {formState.loading && (
      <div>
        <div className="panel__refresh position-relative" style={{ height: 75, width: 20 }}>
          <LoadingIcon />
          <div style={{ width: 500, padding: '23px 40px' }}>Updating Configuration...</div>
        </div>
      </div>
    )}
    {!formState.loading && (formState.isConfigUpdated || !!formState.errorMessage) && (
      <table className="mt-3">
        <tr>
          <td>
            {formState.isConfigUpdated ? (
              <span className="lnr lnr-thumbs-up modal__title-icon success" />
            ) : (
              <span className="lnr lnr-cross-circle modal__title-icon error" />
            )}
          </td>
          <td className="pl-2">
            {formState.isConfigUpdated
              ? 'Configuration Updated Successfully'
              : formState.errorMessage}
          </td>
        </tr>
      </table>
    )}
  </div>
);

export default KnowledgeBaseAssessmentConfigFormStatus;
