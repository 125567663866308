import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import TrainingProvidersTableWrapper from './table/TrainingProvidersTableWrapper';

export function TrainingProvidersCard() {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Training Providers</h3>
        </Col>
      </Row>
      <Row>
        <TrainingProvidersTableWrapper />
      </Row>
    </Container>
  );
}
