import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ControlPanelAssignedUIProvider from './ControlPanelAssignedUIProvider';
import ControlPanelAssignedTabs from './ControlPanelAssignedTabs';
import { Col, Container, FormGroup, Input, Row } from 'reactstrap';
import UnassignDialog from './unassign-dialog/UnassignDialog';
import { findDataInArray } from '@/shared/helpers';
import { setSelectedAssignProduct } from '@/containers/Control-Panel/redux/actions';
import { setUserState } from '@/containers/Control-Panel/redux/user/actions';
import * as Constants from '@/shared/helpers/constants';
import { CP_PRODUCTS } from '@/shared/helpers/constants';
import { isEmpty } from 'lodash/lang';
import { setActiveTab } from '@/containers/Control-Panel/Assigned/redux/actions';
import { paths } from '@/shared/helpers/paths';
import AssignedModuleTabs from '@/containers/Control-Panel/Assigned/components/module/AssignedModuleTabs';
import { useHistory } from 'react-router';
import AssignedLPTabs from '@/containers/Control-Panel/Assigned/components/learningPath/AssignedLearningPathTabs';
import AssignedTPTabs from '@/containers/Control-Panel/Assigned/components/trainingProvider/AssignedTrainingProviderTabs';
import { setCohortState } from '@/containers/Control-Panel/redux/cohort/actions';
import { setOrgState } from '@/containers/Control-Panel/redux/organization/actions';
import { defaultUserState } from '@/containers/Control-Panel/redux/user/reducer';
import { defaultCohortState } from '@/containers/Control-Panel/redux/cohort/reducer';
import { defaultOrgState } from '@/containers/Control-Panel/redux/organization/reducer';

const { CHATBOT, ACCELTIC, DDO, MODULE, LEARNING_PATH, TRAINING_PROVIDER } = CP_PRODUCTS;
const { COHORT, ORGANIZATION, USERS } = Constants;
const productList = [ACCELTIC, CHATBOT, DDO, MODULE, LEARNING_PATH, TRAINING_PROVIDER];
const controlPanelAssignedBaseUrl = paths.CONTROL_PANEL.ASSIGNED;

const AssignedPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const { checkedItemIds } = useSelector((state) => state.assignedPage, shallowEqual);
  const { activeTab } = useSelector((state) => state.assignedPage, shallowEqual);
  const { assignedOrgId, assignedProduct } = useSelector(
    (state) => state.controlPanel,
    shallowEqual,
  );
  const [selectedProduct, setSelectedProduct] = React.useState(assignedProduct);

  React.useEffect(() => {
    const activeTabParam = new URLSearchParams(location.search)?.get('activeTab');
    const assignedProductId = new URLSearchParams(location.search)?.get('assignmentFor');
    if (
      activeTabParam &&
      activeTabParam !== activeTab &&
      [USERS, COHORT, ORGANIZATION].includes(activeTab)
    ) {
      dispatch(setActiveTab(activeTab));
    }
    if (assignedProductId) {
      const productIdx = productList.findIndex((product) => product.id === +assignedProductId);
      if (productIdx !== -1 && productList[productIdx] !== selectedProduct)
        dispatch(setSelectedAssignProduct(productList[productIdx]));
    }
  }, [location.search]);

  const assignedUIEvents = {
    openUnassignDialog: () => {
      history.push(`${controlPanelAssignedBaseUrl}/confirm-unassign`);
    },
  };

  const handleChange = ({ target: { value } }) => {
    const productId = +value;
    setSelectedProduct(productList.find((product) => product.id === productId));
    const product = findDataInArray(productList, productId, 'id');
    dispatch(setSelectedAssignProduct(product));
    if ([ACCELTIC.id, DDO.id, CHATBOT.id].includes(productId)) {
      const filters = { assigned: true, feature: productId };
      switch (activeTab) {
        case USERS:
          return dispatch(
            setUserState({
              ...defaultUserState,
              ...filters,
              searchAssigned: { ...defaultUserState.searchAssigned, orgId: assignedOrgId },
            }),
          );
        case COHORT:
          return dispatch(
            setCohortState({
              ...defaultCohortState,
              ...filters,
              searchAssigned: { ...defaultCohortState.searchAssigned, orgId: assignedOrgId },
            }),
          );
        case ORGANIZATION:
          return dispatch(setOrgState({ ...defaultOrgState, ...filters }));
        default:
          return false;
      }
    }
  };

  return (
    <ControlPanelAssignedUIProvider assignedUIEvents={assignedUIEvents}>
      <Switch>
        <Route path={`${controlPanelAssignedBaseUrl}/confirm-unassign`}>
          {({ history, match }) => {
            if (isEmpty(checkedItemIds)) history.push(controlPanelAssignedBaseUrl);
            else
              return (
                <UnassignDialog
                  show={match != null}
                  isEdit={false}
                  onHide={() => {
                    history.push(controlPanelAssignedBaseUrl);
                  }}
                />
              );
          }}
        </Route>
      </Switch>

      <Col md={12} lg={12}>
        <Container>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Row>
                  <Col sm={9}>
                    <h3 className="page-title">{selectedProduct.name}</h3>
                    <h3 className="page-subhead subhead">
                      From here you can manage the unassignment for{' '}
                      {selectedProduct.name?.toLowerCase()}.
                    </h3>
                  </Col>
                  <Col sm={3}>
                    Unassignment For
                    <Input
                      id="assignProductSelect"
                      name="select"
                      type="select"
                      value={selectedProduct.id}
                      onChange={handleChange}
                    >
                      {productList.map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </Row>
              </FormGroup>
            </Col>
            {[CHATBOT.id, ACCELTIC.id, DDO.id].includes(selectedProduct.id) && (
              <ControlPanelAssignedTabs />
            )}
            {MODULE.id === selectedProduct.id && <AssignedModuleTabs />}
            {LEARNING_PATH.id === selectedProduct.id && <AssignedLPTabs />}
            {TRAINING_PROVIDER.id === selectedProduct.id && <AssignedTPTabs />}
          </Row>
        </Container>
      </Col>
    </ControlPanelAssignedUIProvider>
  );
};

export default AssignedPage;
