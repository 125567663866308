import React from 'react';
import moment from 'moment';
import { Button } from 'reactstrap';
import { getColorForWeight } from '@/shared/helpers';

export function JobViewBasicDetails({ job }) {
  return (
    <table className="project-summary__info">
      <tbody>
        {/*<tr>
                <th>Name</th>
                <td>{job?.name}</td>
            </tr>*/}
        <tr>
          {/*<th>Logo</th>*/}
          <td>
            {' '}
            <img className={'module_logo_image'} alt="Company Logo" src={job?.jobImgUrl} />
          </td>
        </tr>
        <tr>
          <th>Company Name</th>
          <td>{job?.companyName ?? '-'}</td>
        </tr>
        <tr>
          <th>Title</th>
          <td>{job?.title ?? '-'}</td>
        </tr>
        <tr>
          <th>Level</th>
          <td>{job?.level ?? '-'}</td>
        </tr>
        <tr>
          <th>Industry</th>
          <td>{job?.industry ?? '-'}</td>
        </tr>
        <tr>
          <th>Salary Range</th>
          <td>{job?.salaryRange ?? '-'}</td>
        </tr>
        <tr>
          <th>Location</th>
          <td>{job?.location ?? '-'}</td>
        </tr>
        <tr>
          <th>Posted By</th>
          <td>{job?.postedBy ?? '-'}</td>
        </tr>
        <tr>
          <th>Posted Date</th>
          <td>{job?.postedDate ? moment(job?.postedDate).format('DD/MM/yyyy') : '-'}</td>
        </tr>
        <tr>
          <th>Description</th>
          <td>{job?.description ?? '-'}</td>
        </tr>
        <tr>
          <th style={{ minWidth: 150 }}>Required Skills</th>
          <td>
            {job?.requiredSkills?.map((skill) => (
              <Button
                key={skill.id}
                color={getColorForWeight({ random: true, cssClass: true })}
                size="sm"
              >
                {skill.title}
              </Button>
            ))}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
