import React, { Fragment, useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import UsersTableColumns, { UsersColumn } from './UsersTableColumns';
import { UsersTableSearchBar } from './UsersTableSearchBar';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getCadsAIUsersList, setUsersFilters } from '../../redux/action';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { defaultPaginationProps } from '@/shared/helpers';
import moment from 'moment';
import { useUsersUIContext } from '../UsersUIProvider';

const UsersTable = () => {
  const dispatch = useDispatch();
  const userUIContext = useUsersUIContext();
  const {
    data: users,
    error,
    filters,
    loading,
    totalRecords,
  } = useSelector((state) => state.cadsAIUserData, shallowEqual);
  const {
    pageSize: rowsPerPage,
    pageNumber: page,
    sortBy: orderBy,
    isSortOrderDescending,
  } = filters;

  const [columns, setColumns] = useState(0);

  useEffect(() => {
    setColumns(UsersColumn.length);
  }, [UsersColumn.length]);

  React.useEffect(() => {
    dispatch(getCadsAIUsersList(filters));
  }, [filters]);

  const handleRequestSort = (event, property) => {
    dispatch(
      setUsersFilters({
        sortBy: property,
        isSortOrderDescending: !isSortOrderDescending,
      }),
    );
  };

  const handleChangePage = (event, currentPage) => {
    dispatch(setUsersFilters({ pageSize: rowsPerPage, pageNumber: currentPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setUsersFilters({ pageSize: +event.target.value, pageNumber: 1 }));
  };

  const emptyRows = page === Math.ceil(totalRecords / rowsPerPage) ? rowsPerPage - users.length : 0;

  return (
    <Fragment>
      <div className="material-table__wrap">
        <UsersTableSearchBar />
        <Table className="material-table">
          <UsersTableColumns
            order={isSortOrderDescending ? 'desc' : 'asc'}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rowsPerPage}
          />
          <TableBody>
            {(loading || !!error) && (
              <TableRow>
                <TableCell colSpan={columns}>
                  {loading && (
                    <div className={'text-center overflow-hidden'}>
                      <div className="panel__refresh position-relative" style={{ height: 75 }}>
                        <LoadingIcon />
                      </div>
                    </div>
                  )}
                  <div className={'text-center'}>
                    <p className={!!error ? 'm-5' : ''}>{error || 'Loading users...'}</p>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {!!!users?.length && !loading && !error && (
              <TableRow style={{ height: 120 }}>
                <TableCell colSpan={columns} align="center">
                  {filters.status === null && filters.search === ''
                    ? 'No users'
                    : 'No users with applied filters'}
                </TableCell>
              </TableRow>
            )}
            {users.map((user) => {
              return (
                <TableRow className="material-table" tabIndex={-1} key={user?.id}>
                  <TableCell align="center" className="material-table__cell">
                    <img
                      className="applicant_logo_img"
                      src={
                        user?.profilePic?.startsWith('https')
                          ? user?.profilePic
                          : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__480.png'
                      }
                      alt="applicant logo"
                    />
                  </TableCell>
                  <TableCell
                    className="material-table__cell"
                    component="th"
                    role="button"
                    align="left"
                    scope="row"
                    onClick={() => userUIContext.openViewUsersDialog(user?.id)}
                  >
                    {user?.name ?? '-'}
                  </TableCell>
                  <TableCell className="material-table__cell" align="center">
                    {user?.email ?? '-'}
                  </TableCell>
                  <TableCell className="material-table__cell" align="center">
                    {user?.openForJob ?? '-'}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {user?.dateJoined ? moment(user?.dateJoined).format('DD/MM/yyyy') : '-'}
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={columns} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        component={defaultPaginationProps.component}
        backIconButtonProps={defaultPaginationProps.backIconButton}
        nextIconButtonProps={defaultPaginationProps.nextIconButton}
        rowsPerPageOptions={defaultPaginationProps.rowsPerPage}
        className={defaultPaginationProps.className}
        dir={defaultPaginationProps.dir}
      />
    </Fragment>
  );
};

export default UsersTable;
