import React, { Fragment } from 'react';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { FormField } from '@/shared/components/form/hook/FormField';
import { emptyStringPattern } from '@/shared/helpers';

const renderQuestionSkillsColumns = () => (
  <TableRow>
    <TableCell align="center">
      {/*<h6 className="bold-text text-center">Skill Name</h6>*/}
      <b>Skill Name</b>
    </TableCell>
    <TableCell align="center">
      <b>Question Level 1</b>
    </TableCell>
    <TableCell align="center">
      <b>Question Level 2</b>
    </TableCell>
    <TableCell align="center">
      <b>Passing Score (%)</b>
    </TableCell>
  </TableRow>
);

const renderTotalFooter = ({ levelOneTotal, levelTwoTotal }) => (
  <Fragment>
    <TableRow style={{ background: 'rgb(169 207 241)' }}>
      <TableCell align="center">
        <b>Level wise total </b>
      </TableCell>
      <TableCell align="center">
        <b>{levelOneTotal}</b>
      </TableCell>
      <TableCell colSpan={1} align="center">
        <b>{levelTwoTotal}</b>
      </TableCell>
    </TableRow>
    <TableRow style={{ background: 'rgb(167 237 217)' }}>
      <TableCell align="center">
        <b>Total</b>
      </TableCell>
      <TableCell colSpan={2} align="center">
        <b>{levelOneTotal + levelTwoTotal}</b>
      </TableCell>
    </TableRow>
  </Fragment>
);

export const L1_PREFIX = 'Skill_L1_';
export const L2_PREFIX = 'Skill_L2_';
export const PS_PREFIX = 'SKILL_PS_';

const KnowledgeBaseQuestionFieldTable = ({ control, errors, skills, setSkills }) => {
  const [totals, setTotals] = React.useState({
    levelOneTotal: 0,
    levelTwoTotal: 0,
  });

  const recalculateTotal = (skills) => {
    let levelOneTotal = 0,
      levelTwoTotal = 0;
    skills?.forEach((skill) => {
      levelOneTotal += skill.level1 ?? 0;
      levelTwoTotal += skill.level2 ?? 0;
    });
    setTotals({ levelOneTotal, levelTwoTotal });
  };

  React.useEffect(() => {
    recalculateTotal(skills);
  }, [skills]);

  const handleChange = ({ target: { name, value } }) => {
    const skillId = +name.substr(name.lastIndexOf('_') + 1);
    let newSkills = [];
    skills.forEach((skill) => {
      if (skill.skillId === skillId) {
        if (name.startsWith(PS_PREFIX)) newSkills.push({ ...skill, passingScore: +value });
        else
          newSkills.push({
            ...skill,
            [`${name.startsWith(L1_PREFIX) ? 'level1' : 'level2'}`]: +value,
          });
      } else newSkills.push(skill);
    });
    setSkills(newSkills);
  };

  return (
    <Fragment>
      <h6 className="bold-text mb-2 w-100">Questions</h6>
      <Table className="material-table">
        {renderQuestionSkillsColumns()}
        {skills?.map((skill) => (
          <TableRow key={skill.skillId}>
            <TableCell align="center">{skill.skillName}</TableCell>
            <TableCell align="center">
              <div className="form__form-group mb-0">
                <div className="form__form-group-field">
                  <FormField
                    name={`${L1_PREFIX}${skill.skillId}`}
                    control={control}
                    onChange={handleChange}
                    type="number"
                    component="input"
                    className="text-center"
                    errors={errors}
                    rules={{
                      min: { value: 1, message: 'Needs to be more than 0' },
                      required: 'This is required field',
                      pattern: emptyStringPattern,
                    }}
                    defaultValue={skill.level1}
                    isAboveError={true}
                  />
                </div>
              </div>
            </TableCell>
            <TableCell align="center">
              <div className="form__form-group mb-0">
                <div className="form__form-group-field">
                  <FormField
                    name={`${L2_PREFIX}${skill.skillId}`}
                    control={control}
                    onChange={handleChange}
                    type="number"
                    className="text-center"
                    component="input"
/*                    errors={errors}
                    rules={{
                      min: { value: 1, message: 'Needs to be more than 0' },
                      required: 'This is required field',
                      pattern: emptyStringPattern,
                    }}*/
                    defaultValue={skill.level2}
                    isAboveError={true}
                  />
                </div>
              </div>
            </TableCell>
            <TableCell align="center">
              <div className="form__form-group mb-0">
                <div className="form__form-group-field">
                  <FormField
                    name={`${PS_PREFIX}${skill.skillId}`}
                    control={control}
                    onChange={handleChange}
                    type="number"
                    className="text-center"
                    component="input"
                    errors={errors}
                    rules={{
                      min: { value: 1, message: 'Needs to be more than 0' },
                      max: { value: 100, message: "Percentage can't be greater then 100" },
                      required: 'This is required field',
                      pattern: emptyStringPattern,
                    }}
                    defaultValue={skill.passingScore}
                    isAboveError={true}
                  />
                </div>
              </div>
            </TableCell>
          </TableRow>
        ))}
        {renderTotalFooter(totals)}
      </Table>
    </Fragment>
  );
};

export default KnowledgeBaseQuestionFieldTable;
