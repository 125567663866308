import React, { createContext, useContext } from 'react';

const AnnouncementsUIContext = createContext();

export function useAnnouncementsUIContext() {
  return useContext(AnnouncementsUIContext);
}

export function AnnouncementsUIProvider({ announcementsUIEvents, children }) {
  const value = {
    openViewAnnouncementsDialog: announcementsUIEvents.openViewAnnouncementsDialog,
    openNewAnnouncementsDialog: announcementsUIEvents.openNewAnnouncementsDialog,
    openEditAnnouncementsDialog: announcementsUIEvents.openEditAnnouncementsDialog,
    openAnnouncementDeactivationDialog: announcementsUIEvents.openAnnouncementDeactivationDialog,
  };

  return (
    <AnnouncementsUIContext.Provider value={value}>{children}</AnnouncementsUIContext.Provider>
  );
}
