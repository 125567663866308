import { paths } from '@/shared/helpers/paths';
import React, { Suspense } from 'react';
import Loading from '@/shared/components/Loading';
import { Redirect, Route, Switch } from 'react-router-dom';
import Users from '@/containers/Campaign/Users';
import Sunrise25Users from '@/containers/Campaign/Sunrise25Users';

export default function Campaigns() {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        {<Redirect exact from={paths.CAMPAIGN.BASE} to={paths.CAMPAIGN.SUNRISE25USERS} />}
        <Route path={paths.CAMPAIGN.SUNRISE25USERS} component={Sunrise25Users} />
        <Route path={paths.CAMPAIGN.USERS} component={Users} />
      </Switch>
    </Suspense>
  );
}
