import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Button, ButtonToolbar } from 'reactstrap';
import api from '@/utils/apiClient';
import { emptyStringPattern } from '@/shared/helpers';
import { SelectField } from '@/shared/components/form/Select';
import { FormField } from '@/shared/components/form/hook/FormField';
import { updateSkillInSkillList } from '../../redux/actions';
import LoadingIcon from 'mdi-react/LoadingIcon';

const getColorForWeight = (weight) => {
  switch (weight) {
    case 0.1:
    case 0.2:
      return 'grey';
    case 0.3:
    case 0.4:
      return 'blue';
    case 0.5:
    case 0.6:
      return 'green';
    case 0.7:
    case 0.8:
      return 'orange';
    case 0.9:
    case 1:
      return 'red';
    default:
      return 'green';
  }
};

const getCompetenciesImportanceOptions = () => [
  { value: '0.1', label: '1 (Lowest)' },
  { value: '0.2', label: '2' },
  { value: '0.3', label: '3' },
  { value: '0.4', label: '4' },
  { value: '0.5', label: '5 (Medium)' },
  { value: '0.6', label: '6' },
  { value: '0.7', label: '7' },
  { value: '0.8', label: '8' },
  { value: '0.9', label: '9' },
  { value: '1', label: '10 (Highest)' },
];

const SkillEditForm = ({ modal, isEdit, id }) => {
  const dispatch = useDispatch();

  //states
  const [skillCompetencies, setSkillCompetencies] = React.useState([]);
  const [state, setState] = React.useState({
    loading: false,
    fetchingSkill: false,
    newSkillAdded: false,
    errorMessage: '',
  });

  // prefill the form in case of edit skill
  React.useEffect(() => {
    if (isEdit && id) {
      setDefaultData(+id);
    }
  }, [isEdit, id]);

  const setDefaultData = async (skillId) => {
    try {
      const { data: res, isSuccess } = await api.skills.getSkill(skillId);
      if (isSuccess) {
        ['id', 'name', 'description'].forEach((field) => setValue(field, res?.data?.[field]));
        setSkillCompetencies(res?.data?.competencies ?? []);
      } else {
        throw new Error(res?.messages?.[0]);
      }
    } catch ({ message }) {
      console.error('Skill edit error : ', message);
      setState({ ...state, errorMessage: 'Error while getting existing skill details' });
    }
  };

  // skill form related functions
  const {
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();

  const resetForm = () => {
    reset({ id: 0, name: '', description: '', select: '', competency: '', importance: '' });
    setSkillCompetencies([]);
  };

  const onSubmit = async ({ name, description }) => {
    const newSkillWithCompetencies = { name, description, competencies: skillCompetencies };
    setState({ ...state, loading: true });
    const defaultError = `Error occurred while ${isEdit ? 'Updating' : 'Adding new'} skill`;
    try {
      const { data, isSuccess } = isEdit
        ? await api.skills.updateSkill({ id, ...newSkillWithCompetencies })
        : await api.skills.addSkill(newSkillWithCompetencies);
      if (isSuccess) {
        setState({ ...state, newSkillAdded: true, loading: false });

        if (isEdit) dispatch(updateSkillInSkillList(data));
        else resetForm();
      } else {
        throw new Error(data?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({ ...state, loading: false, newSkillAdded: false, errorMessage: error.message });
    }
  };

  const onCancel = () => {
    resetForm();
    modal.hide();
  };

  // skill competency related functions
  const addCompetency = () => {
    const {
      competency: competencyName = '',
      importance: { value: competencyLevel } = { value: '' },
    } = getValues();
    if (!competencyName || !competencyLevel) return;
    setSkillCompetencies([
      ...skillCompetencies,
      { name: competencyName, weight: competencyLevel, id: new Date().getTime() },
    ]);
    setValue('competency', '');
  };

  const deleteCompetency = (competencyId) =>
    setSkillCompetencies(skillCompetencies.filter((competency) => competency.id !== competencyId));

  const renderCompetenciesForm = () => (
    <>
      <div className="form__form-group" style={{ width: '60%' }}>
        <span className="form__form-group-label">Competencies</span>
        <FormField
          style={{ height: 40 }}
          name="competency"
          control={control}
          component="input"
          errors={errors}
          defaultValue=""
          isAboveError={true}
          placeholder="Name of competency"
        />
      </div>
      <div className="form__form-group ml-2" style={{ width: '24%' }}>
        <span className="form__form-group-label">Importance</span>
        <FormField
          name="importance"
          style={{ height: 40 }}
          control={control}
          component={SelectField}
          placeholder="Select Importance"
          errors={errors}
          options={getCompetenciesImportanceOptions()}
          defaultValue=""
          isAboveError
        />
      </div>
      <div className="form__form-group" style={{ width: '10%' }}>
        <div className="competency_add_button">
          <Button
            color="success"
            outline
            style={{ marginLeft: 20, height: 38 }}
            onClick={() => addCompetency()}
          >
            Add
          </Button>
        </div>
      </div>
      <div className="competency_add_section">
        {skillCompetencies.map(({ name, weight, id }) => (
          <p key={id}>
            <span
              onClick={() => deleteCompetency(id)}
              onMouseEnter={(e) => (e.target.className = 'lnr lnr-trash')}
              onMouseOut={(e) => (e.target.className = 'lnr lnr-checkmark-circle')}
              style={{ color: getColorForWeight(+weight), cursor: 'pointer' }}
              className="lnr lnr-checkmark-circle"
            />{' '}
            {name} ({weight * 100}%)
          </p>
        ))}
      </div>
    </>
  );

  return (
    <div>
      {/*Add/Edit Skill Form Status*/}
      <div className="ml-4">
        {state.loading && (
          <div>
            <div className="panel__refresh position-relative" style={{ height: 75, width: 20 }}>
              <LoadingIcon />
              <div style={{ width: 200, padding: '23px 40px' }}>
                {isEdit ? 'Updating' : 'Adding new'} skill...
              </div>
            </div>
          </div>
        )}
        {(state.newSkillAdded || !!state.errorMessage) && (
          <table className="mt-3">
            <tr>
              <td>
                {state.newSkillAdded ? (
                  <span className="lnr lnr-thumbs-up modal__title-icon success" />
                ) : (
                  <span className="lnr lnr-cross-circle modal__title-icon error" />
                )}
              </td>
              <td className="pl-2">
                {state.newSkillAdded
                  ? `${isEdit ? 'Skill Updated' : 'New Skill Added'} successfully`
                  : state.errorMessage}
              </td>
            </tr>
          </table>
        )}
      </div>

      {/*Add/Edit Skill Form*/}
      <div className="modal__body">
        <form className={`form`} onSubmit={handleSubmit(onSubmit)}>
          <div className="form__form-group">
            <span className="form__form-group-label">Name</span>
            <div className="form__form-group-field">
              <FormField
                name="name"
                control={control}
                component="input"
                errors={errors}
                rules={{
                  required: 'This is required field',
                  pattern: emptyStringPattern,
                }}
                defaultValue=""
                isAboveError={true}
                placeholder="Name of the skill"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Description</span>
            <div className="form__form-group-field">
              <FormField
                name="description"
                control={control}
                component="textarea"
                errors={errors}
                rules={{
                  required: 'This is required field',
                  pattern: emptyStringPattern,
                }}
                defaultValue=""
                isAboveError={true}
                placeholder="What does the skillset entail"
              />
            </div>
          </div>
          {renderCompetenciesForm()}
          <ButtonToolbar className="form__button-toolbar modal__footer">
            <Button disabled={state.loading} color="primary" type="submit">
              Submit
            </Button>
            <Button type="button" className="modal_cancel" onClick={onCancel}>
              Close
            </Button>
          </ButtonToolbar>
        </form>
      </div>
    </div>
  );
};

export default SkillEditForm;
