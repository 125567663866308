import React, { useState } from 'react';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import SanitizeHTML from '@/shared/components/SanitizeHTML';
import { getColorForWeight } from '@/shared/helpers';

const renderQuestionView = (question) => (
  <table className="project-summary__info">
    <tr>
      <th>Question</th>
      <td>{question?.questionMain}</td>
    </tr>
    {!!question?.questionExtra && (
      <tr>
        <th>Additional Text</th>
        <td>
          <div className="question-extra-html">
            <SanitizeHTML html={question.questionExtra ?? '<p/>'} />
          </div>
        </td>
      </tr>
    )}
    {!!question?.imageUrl && (
      <tr>
        <th>Image</th>
        <td className="question-row img">
          <img alt={'question-image'} src={question.imageUrl} />
        </td>
      </tr>
    )}

    <tr>
      <th>Answers</th>
      <td>
        <ul>
          {question?.answers?.map((ans) => (
            <li key={ans.id} style={{ fontWeight: ans.isCorrect ? 'bold' : 'normal' }}>
              {ans.text}
            </li>
          ))}
        </ul>
      </td>
    </tr>
    <tr>
      <th>Tags</th>
      <td className="pt-4">
        {question?.tags?.map((question) => (
            <button
                className={`btn btn-${getColorForWeight({random: true, cssClass: true})} btn-sm`}
            >
              {question?.title}
            </button>
        ))}
      </td>
    </tr>
  </table>
);

const renderQuestionDetailsView = (question) => (
  <table className="project-summary__info">
    <tr>
      <th>Skill</th>
      <td>{question?.skill?.name}</td>
    </tr>
    <tr>
      <th>Level</th>
      <td>{question?.questionLevel?.name}</td>
    </tr>
    <tr>
      <th style={{ width: 132 }}>Is skip allowed ?</th>
      <td>{question?.allowSkip ? 'Yes' : 'No'}</td>
    </tr>
    <tr>
      <th>Is other allowed ?</th>
      <td>{question?.allowOther ? 'Yes' : 'No'}</td>
    </tr>
  </table>
);
const renderQuestionStaticsView = (question) => (
  <table className="project-summary__info">
    <tr>
      <th style={{ width: 132 }}>Number of Answers</th>
      <td>{question?.totalAnswers}</td>
    </tr>
    <tr>
      <th>Correct</th>
      <td>{question?.correctAnswers}</td>
    </tr>
    <tr>
      <th>Incorrect</th>
      <td>{question?.incorrectAnswer}</td>
    </tr>
    <tr>
      <th>Skipped</th>
      <td>{question?.skipped}</td>
    </tr>
  </table>
);

const renderGraphView = (question) => (
  <table className="project-summary__info">
    <tr>
      <th>coming soon</th>
    </tr>
  </table>
);

const renderNotesView = (question) => <p>{question?.notes}</p>;

export default function ({ question }) {
  const tabs = React.useMemo(
    () => ({
      QUESTION: {
        id: 'QUESTION',
        label: 'Question',
        renderTab: () => renderQuestionView(question),
      },
      DETAILS: {
        id: 'DETAILS',
        label: 'Details',
        renderTab: () => renderQuestionDetailsView(question),
      },
      STATISTICS: {
        id: 'STATISTICS',
        label: 'Statistics',
        renderTab: () => renderQuestionStaticsView(question),
      },
      /*GRAPHS: {id: 'GRAPHS', label: 'Graphs', renderTab: () => renderGraphView(question)},*/
    }),
    [question],
  );

  const [activeTab, setActiveTab] = useState(tabs.QUESTION.id);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Row className="p-1">
      <Col>
        <div className="tabs tabs--bordered-bottom">
          <div className="tabs__wrap">
            <Nav tabs>
              {Object.keys(tabs).map((key) => (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === tabs[key].id })}
                    onClick={() => toggle(tabs[key].id)}
                  >
                    {tabs[key].label}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={activeTab}>{tabs[activeTab].renderTab()}</TabPane>
            </TabContent>
          </div>
        </div>
      </Col>
    </Row>
  );
}
