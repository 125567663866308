import { sendRequest } from '../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const eventBaseUrl =
  process.env.REACT_APP_CADS_AI_API + process.env.REACT_APP_CADS_AI_EVENTS_ENDPOINT;

export const eventEndpoints = {
  GET_ALL_EVENTS: '/getAllEvents',
  GET_EVENT_BY_ID: '/getEvent',
  ADD_EVENT: '/addEvent',
  EDIT_EVENT: '/editEvent',
  DEACTIVATE_EVENT: '/deactivateEvents',
};

const getEventList = (filter) => {
  return sendRequest({
    fullUrl: eventBaseUrl + eventEndpoints.GET_ALL_EVENTS,
    method: httpMethod.GET,
    params: filter,
  });
};

const addEvent = (data) => {
  return sendRequest({
    fullUrl: eventBaseUrl + eventEndpoints.ADD_EVENT,
    method: httpMethod.POST,
    data: data,
  });
};

const getEventById = (id) => {
  return sendRequest({
    fullUrl: eventBaseUrl + eventEndpoints.GET_EVENT_BY_ID,
    method: httpMethod.GET,
    params: { id: id },
  });
};

const updateEvent = (data) => {
  return sendRequest({
    fullUrl: eventBaseUrl + eventEndpoints.EDIT_EVENT,
    method: httpMethod.PUT,
    data: data,
  });
};

const deleteEvent = (eventId) => {
  return sendRequest({
    fullUrl: eventBaseUrl + eventEndpoints.DEACTIVATE_EVENT,
    method: httpMethod.DELETE,
    params: { id: eventId },
  });
};

export default {
  getEventList,
  addEvent,
  updateEvent,
  getEventById,
  deleteEvent,
};
