import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';

const LearningCard = ({ title, count }) => {
  return (
    <Col md={12} xl={4} lg={6} sm={12} xs={12}>
      <Card>
        <CardBody className="dashboard__health-chart-card p-5">
          <div className="card__title">
            <h5 className="bold-text card__title-center">{title}</h5>
          </div>
          <h2 className="bold-text text-center">{count}</h2>
        </CardBody>
      </Card>
    </Col>
  );
};

export default LearningCard;
