import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { Card, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
// import { smartSkillData } from './smart-skills/constants/constant';
import Overview from './smart-skills/components/Overview/Overview';
import DetailedView from './smart-skills/components/DetailedView/DetailedView';

const SmartSkillsTabs = ({ smartSkillsData }) => {
  const [activeTab, setActiveTab] = useState('overview');

  useEffect(() => {
    setActiveTab('overview');
  }, []);

  const ssTabs = [
    {
      name: 'Overview',
      value: 'overview',
    },
    {
      name: 'Detailed View',
      value: 'detailedView',
    },
  ];

  const toggle = (tab) => {
    if (activeTab !== tab) {
      let update = { activeTab: tab };
      if (tab === 'overview') {
        update.isProfileRefresh = true;
      } else {
        update.isSkillsRefresh = true;
      }
      setActiveTab(update.activeTab);
    }
  };

  return (
    <React.Fragment>
      <div className="profile__card tabs--bordered-bottom">
        <div className="tabs__wrap smartskill-tabs">
          <Nav tabs className={''} style={{ padding: '0 100px', border: '0' }}>
            {ssTabs.map((profileTab, index) => {
              return (
                <NavItem key={index}>
                  <NavLink
                    className={classnames({
                      active: activeTab === profileTab.value,
                    })}
                    onClick={() => {
                      toggle(profileTab.value);
                    }}
                  >
                    {profileTab.name}
                  </NavLink>
                </NavItem>
              );
            })}
          </Nav>
        </div>
      </div>
      <Container>
        <Row className={'mt-3'}>
          <Col md={12} lg={12} xl={12}>
            <Card>
              <div className="profile__card tabs tabs--bordered-bottom">
                <div className="tabs__wrap">
                  <TabContent className="p-4" activeTab={activeTab}>
                    {activeTab === 'overview' ? (
                      <TabPane tabId="overview">
                        <Overview smartSkillData={smartSkillsData} />
                      </TabPane>
                    ) : activeTab === 'detailedView' ? (
                      <TabPane tabId="detailedView">
                        <DetailedView smartSkillData={smartSkillsData} />
                      </TabPane>
                    ) : null}
                  </TabContent>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default SmartSkillsTabs;
