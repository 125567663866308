import { handleActions } from 'redux-actions';
import {
  setApplicantList,
  fetchApplicantList,
  setApplicantError,
  setApplicantFilters,
  setApplicantOptionsList,
  toggleCheckedApplicants,
  uncheckAllApplicants,
  setApplicantCount,
  clearApplicantFilters,
} from './actions';

const defaultState = {
  loading: false,
  checkedApplicantIds: [],
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    searchByName: '',
    isSortOrderDescending: false,
    status: null,
  },
  totalRecords: 0,
  data: [],
  options: [],
  error: null,
  applicantCount: 0,
};

export default handleActions(
  {
    [fetchApplicantList](state) {
      return {
        ...state,
        loading: true,
        data: [],
      };
    },
    [setApplicantList](state, { payload }) {
      return {
        ...state,
        loading: false,
        data: payload.data.records,
        totalRecords: payload.data.totalRecords,
        error: null,
      };
    },
    [setApplicantOptionsList](state, { payload }) {
      return {
        ...state,
        options: payload.data,
      };
    },
    [setApplicantError](state, { payload }) {
      return {
        ...state,
        data: [],
        loading: false,
        totalPages: defaultState.totalPages,
        error: payload?.message,
      };
    },
    [setApplicantFilters](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
        checkedApplicantIds: [],
      };
    },
    [clearApplicantFilters](state) {
      return {
        ...state,
        filters: { ...defaultState.filters },
        checkedApplicantIds: [],
      };
    },
    [toggleCheckedApplicants](state, { payload: applicantId }) {
      const checkedIds = state.checkedApplicantIds;
      return {
        ...state,
        checkedApplicantIds: checkedIds.includes(applicantId)
          ? checkedIds.filter((id) => id !== applicantId)
          : [...checkedIds, applicantId],
      };
    },
    [uncheckAllApplicants](state) {
      return {
        ...state,
        checkedApplicantIds: [],
      };
    },
    [setApplicantCount](state, { payload }) {
      return {
        ...state,
        loading: false,
        applicantCount: payload,
        error: null,
      };
    },
  },
  defaultState,
);
