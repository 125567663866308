import { handleActions } from 'redux-actions';
import {
  setCohortFetching,
  setAssignCohortList,
  setCohortError,
  setCohortFilters,
  setCohortState,
  setCohortOptionsList,
} from './actions';
import { CADS_AI_PRODUCTS } from '@/shared/helpers/constants';

export const defaultCohortState = {
  loading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    isSortOrderDescending: false,
  },
  searchAssign: {
    searchText: '',
    orgId: '',
  },
  searchAssigned: {
    searchText: '',
    orgId: '',
  },
  totalRecords: 0,
  assignCohorts: [],
  assigned: false,
  options: [],
  feature: CADS_AI_PRODUCTS.LEARNING_PATH.id,
  error: null,
};

export default handleActions(
  {
    [setCohortFetching](state) {
      return {
        ...state,
        assignCohorts: [],
        totalRecords: 0,
        loading: true,
        error: null,
      };
    },
    [setCohortError](state, { payload }) {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    },
    [setAssignCohortList](state, { payload }) {
      const assignCohortList = payload.data;
      return {
        ...state,
        assignCohorts: assignCohortList?.records,
        totalRecords: assignCohortList?.totalRecords,
        loading: false,
        error: null,
      };
    },
    [setCohortOptionsList](state, { payload }) {
      return {
        ...state,
        options: payload.data,
      };
    },
    [setCohortFilters](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
      };
    },
    [setCohortState](state, { payload }) {
      return payload;
    },
  },
  defaultCohortState,
);
