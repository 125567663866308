import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import { useApplicantUIContext } from '../ApplicantsUIProvider';
import ApplicantTable from './ApplicantTable';
import api from '@/utils/apiClient';

const ApplicantsTableWrapper = () => {
  const { checkedApplicantIds, filters } = useSelector(
    (state) => state.cadsAIApplicants,
    shallowEqual,
  );
  const applicantUIContext = useApplicantUIContext();

  const downloadCSV = async () => {
    const applicantCsvFilter = { ...filters, pageSize: null, pageNumber: null };
    const csvData = await api.cadsAIApplicant.getApplicantListCSV(applicantCsvFilter);
    require('js-file-download')(
      csvData,
      `Applicant_List__${new Date().toLocaleString().replace(/[\/:,\s]/gi, '_')}.csv`,
    );
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="products-list">
          <div className="card__title">
            <ButtonToolbar className="products-list__btn-toolbar-top">
              <Button
                color="primary"
                onClick={applicantUIContext.openUpdateStatusApplicants}
                disabled={checkedApplicantIds.length === 0}
              >
                Update Status
              </Button>
              <Button
                color="primary"
                onClick={applicantUIContext.openUpdateStageApplicants}
                disabled={checkedApplicantIds.length === 0}
              >
                Update Stage
              </Button>
              <Button color="success" onClick={downloadCSV} outline>
                Export Applicants
              </Button>
            </ButtonToolbar>
          </div>
          <ApplicantTable />
        </CardBody>
      </Card>
    </Col>
  );
};

export default ApplicantsTableWrapper;
