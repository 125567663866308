import React, { useState } from 'react';
import { Button, ButtonToolbar, FormGroup, Input } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { StepDefaultProps, StepPropTypes } from './Props';
import { extractFieldData, getStepFields } from '../index';

const StepFour = ({onSubmit, page, onClear, previousPage, data}) => {
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: {errors},
  } = useForm(extractFieldData(page, data));

  const [state, setState] = useState({
    isDDO: false,
    isBA: false,
    isDLE: false
  });

  const toggleCheckbox = key => {
    setValue(key, !state[key]);
    setState({...state, [key]: !state[key]});
  }

  React.useEffect(() => {
    getStepFields(page).forEach((key) => {
      setValue(key, data[key]);
      setState((state) => ({...state, [key]: data[key]}));
    });
  }, [data]);

  return (
      <form className="form form--horizontal wizard__form" onSubmit={handleSubmit(onSubmit)}>
        <p className="form__form-group form-group" style={{fontSize: 14, marginBottom: 20}}>Select modules to activate</p>
        <FormGroup className="form__form-group">
          <Input
              className="w-auto h-auto m-0 mr-2 ml-2 position-relative"
              type="checkbox"
              value={'isDDO'}
              checked={state.isDDO}
              onChange={(e) => toggleCheckbox(e.target.value)}
          /> DDO
        </FormGroup>
        <FormGroup className="form__form-group">
          <Input
              className="w-auto h-auto m-0 mr-2 ml-2 position-relative"
              type="checkbox"
              value={'isBA'}
              checked={state.isBA}
              onChange={(e) => toggleCheckbox(e.target.value)}
          /> Baseline Assessment
        </FormGroup>
        <FormGroup className="form__form-group">
          <Input
              className="w-auto h-auto m-0 mr-2 ml-2 position-relative"
              type="checkbox"
              value={'isDLE'}
              checked={state.isDLE}
              onChange={(e) => toggleCheckbox(e.target.value)}
          /> Data Literacy Exam
        </FormGroup>
        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
          <Button
              color="primary"
              type="button"
              className="previous"
              onClick={() => previousPage(getValues())}
          >
            Back
          </Button>
          <Button color="secondary" type="button" onClick={onClear} outline>
            Clear
          </Button>
          <Button color="primary" type="submit">
            Submit
          </Button>
        </ButtonToolbar>
      </form>
  );
};

StepFour.propTypes = StepPropTypes;
StepFour.defaultProps = StepDefaultProps;

export default StepFour;
