import React, { Fragment } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import { Button, Input, Badge, FormGroup } from 'reactstrap';
import EyeIcon from 'mdi-react/EyeIcon';
import PencilIcon from 'mdi-react/PencilIcon';
// import BinIcon from 'mdi-react/BinIcon';
import { useOrganizationUIContext } from '../OrganizationUIProvider';
import { OrganizationTableSearchBar } from './OrganizationTableSearchBar';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getOrganizationsList,
  setOrganizationFilters,
  toggleCheckedItemById,
} from '@/containers/Cads-AI/redux/organization/actions';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { defaultPaginationProps } from '@/shared/helpers';
import OrganizationTableColumns from '@/containers/Cads-AI/Organizations/components/table/OrganizationTableColumns';
export const OrganizationColumns = [
  {
    id: 'checkbox',
    label: 'Select',
  },
  {
    id: 'title',
    label: 'Name',
  },
  {
    id: 'isVerified',
    label: 'Status',
  },
  {
    id: 'organizationType.title',
    label: 'Organization Type',
  },
  // {
  //     id: 'individuals', label: 'Individuals'
  // },
  {
    id: 'action',
    label: 'Action',
  },
];
const OrganizationTable = () => {
  const organizationUIContext = useOrganizationUIContext();
  const dispatch = useDispatch();
  const {
    data: organizations,
    error,
    filters,
    loading,
    totalRecords,
    checkedOrganizationIds,
  } = useSelector((state) => state.cadsAIOrgs, shallowEqual);
  const {
    pageSize: rowsPerPage,
    pageNumber: page,
    sortBy: orderBy,
    isSortOrderDescending,
  } = filters;

  React.useEffect(() => {
    dispatch(getOrganizationsList(filters));
  }, [filters]);

  const handleRequestSort = (event, property) => {
    dispatch(
      setOrganizationFilters({ sortBy: property, isSortOrderDescending: !isSortOrderDescending }),
    );
  };

  const handleChangePage = (event, currentPage) => {
    dispatch(setOrganizationFilters({ pageSize: rowsPerPage, pageNumber: currentPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setOrganizationFilters({ pageSize: +event.target.value, pageNumber: 1 }));
  };

  const emptyRows =
    page === Math.ceil(totalRecords / rowsPerPage) ? rowsPerPage - organizations.length : 0;

  const renderActionButtons = ({ id }) => (
    <div className="actions">
      <Button
        className="icon mb-0"
        color="primary"
        outline
        onClick={() => organizationUIContext.openEditOrganizationDialog(id)}
      >
        <p>
          <PencilIcon /> Edit
        </p>
      </Button>
      {/*            <Button
                className="icon mb-0"
                color="danger" outline
                onClick={()=>organizationUIContext.openDeleteOrganizationDialog(id)}
            >
                <p><BinIcon /> Delete</p>
            </Button>*/}
    </div>
  );

  return (
    <Fragment>
      <div className="material-table__wrap">
        <OrganizationTableSearchBar />
        <Table className="material-table">
          <OrganizationTableColumns
            order={isSortOrderDescending ? 'desc' : 'asc'}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rowsPerPage}
          />
          <TableBody>
            {(loading || !!error) && (
              <TableRow>
                <TableCell colSpan={OrganizationColumns.length}>
                  {loading && (
                    <div className={'text-center overflow-hidden'}>
                      <div className="panel__refresh position-relative" style={{ height: 75 }}>
                        <LoadingIcon />
                      </div>
                    </div>
                  )}
                  <div className={'text-center'}>
                    <p className={!!error ? 'm-5' : ''}>{error || 'Loading organizations...'}</p>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {!!!organizations?.length && !loading && !error && (
              <TableRow style={{ height: 120 }}>
                <TableCell colSpan={OrganizationColumns.length} align="center">
                  {filters.status === null && filters.search === ''
                    ? 'No Organizations'
                    : 'No Organizations with applied filters'}
                </TableCell>
              </TableRow>
            )}
            {organizations?.map((organization) => {
              return (
                <TableRow className="material-table" tabIndex={-1} key={organization?.id}>
                  <TableCell align="center" className="material-table__cell">
                    <FormGroup>
                      <Input
                        className="table-checkbox"
                        type="checkbox"
                        value={organization?.id}
                        checked={checkedOrganizationIds.includes(organization.id)}
                        onChange={(e) => dispatch(toggleCheckedItemById(+e.target.value))}
                      />
                    </FormGroup>
                  </TableCell>
                  <TableCell
                    className="material-table__cell"
                    component="th"
                    align="center"
                    scope="row"
                    role="button"
                    onClick={() =>
                      organizationUIContext.openViewOrganizationDialog(organization?.id)
                    }
                  >
                    {organization?.title}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    <Badge
                      color={`${organization?.isVerified ? 'success' : 'danger'}`}
                      style={{ marginRight: 24 }}
                    >
                      {organization?.isVerified ? 'Verified' : 'Not Verified'}
                    </Badge>
                  </TableCell>
                  <TableCell
                    className="material-table__cell"
                    component="th"
                    align="center"
                    scope="row"
                    role="button"
                    onClick={() =>
                      organizationUIContext.openViewOrganizationDialog(organization?.id)
                    }
                  >
                    {organization?.organizationType?.title}
                  </TableCell>
                  {/*<TableCell
                                            align="center"
                                            className="material-table__cell"
                                        >
                                          {organization?.usersCount}
                                        </TableCell>*/}
                  <TableCell
                    align="center"
                    className="material-table__cell"
                    style={{ minWidth: '295px' }}
                  >
                    {renderActionButtons(organization)}
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={OrganizationColumns.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        component={defaultPaginationProps.component}
        backIconButtonProps={defaultPaginationProps.backIconButton}
        nextIconButtonProps={defaultPaginationProps.nextIconButton}
        rowsPerPageOptions={defaultPaginationProps.rowsPerPage}
        className={defaultPaginationProps.className}
        dir={defaultPaginationProps.dir}
      />
    </Fragment>
  );
};

export default OrganizationTable;
