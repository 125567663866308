import React, { useState } from 'react';
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import SmartSkillsTabs from '@/containers/Cads-AI/Users/components/users-config/user-details/SmartSkillsTab';
import { downloadFileFromUrl } from '@/shared/helpers';
import api from '@/utils/apiClient';
import classnames from 'classnames';
import DownloadIcon from 'mdi-react/DownloadIcon';
import UserDetails from './UserDetails';
import UserJourney from './UserJourney';

const UserTabs = ({ state, enrollmentId, firstStage, smartSkillsData }) => {
  const [activeTab, setActiveTab] = useState('1');

  const { applicant } = state;

  const downloadResume = async () => {
    const { data: resumeData } = await api.cadsAIUsers.getCadsAIExportResume(
      applicant?.userDetails?.id,
    );
    if (resumeData.data?.resumeFile) downloadFileFromUrl(resumeData.data.resumeFile);
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="tabs tabs--justify tabs--bordered-top overflow-hidden">
      <div className="tabs__wrap">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => toggle('1')}
            >
              User Details
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => toggle('2')}
            >
              User Journey
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '3' })}
              onClick={() => toggle('3')}
            >
              Smart Skills
            </NavLink>
          </NavItem>
          <div className="ml-auto">
            <Button
              onClick={downloadResume}
              color="primary"
              disabled={!applicant?.isResumePresent}
              className="upload-resume curve-gray-button p-2"
            >
              <DownloadIcon />
              <span>Download Resume</span>
            </Button>
          </div>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <div className="row">
              <UserDetails userDetail={applicant?.userDetails} state={state} toggle={toggle} />
            </div>
          </TabPane>
          <TabPane tabId="2">
            <UserJourney
              id={applicant?.userDetails?.id}
              state={state}
              toggle={toggle}
              enrollmentId={enrollmentId}
              firstStage={firstStage}
            />
          </TabPane>
          <TabPane tabId="3" className="users-view-page">
            {activeTab == '3' && smartSkillsData ? (
              <SmartSkillsTabs smartSkillsData={smartSkillsData ? smartSkillsData : null} />
            ) : <h5 className='text-center mt-3'>No smart skills available !</h5>
          }
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default UserTabs;
