import React, { createContext, useContext } from 'react';

const HelpUIContext = createContext();

export function useHelpUIContext() {
  return useContext(HelpUIContext);
}

export function HelpUIProvider({ helpUIEvents, children }) {
  const value = {
    openViewHelpDialog: helpUIEvents.openViewHelpDialog,
    openDeactivateHelpDialog: helpUIEvents.openDeactivateHelpDialog,
  };

  return <HelpUIContext.Provider value={value}>{children}</HelpUIContext.Provider>;
}
