import React, { useRef } from 'react';
import { Button } from 'reactstrap';
import TableHead from '@material-ui/core/TableHead';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setCadsAiCourseFilters, setSelectedModuleId } from '../../redux/actions';
import Select from 'react-select';
import api from '@/utils/apiClient';

export function CourseTableSearchBar() {
  const dispatch = useDispatch();
  const totalModuleCount = useSelector((state) => state.cpModules.totalRecords, shallowEqual);
  const [isSearched, setIsSearched] = React.useState(false);
  const [selectedModuleOpt, setSelectedModuleOpt] = React.useState({});
  const [skillOptions, setSkillOptions] = React.useState([]);
  const [skillOpts, setSkillOpts] = React.useState([]);
  const searchRef = useRef(null);

  const getSkillOptions = async () => {
    const { data: res, isSuccess } = await api.cadsAICourse.getCadsAiCoursesSkills();
    if (isSuccess) {
      setSkillOptions(res?.data?.map((skill) => ({ value: skill.id, label: skill.title })));
    }
  };

  React.useEffect(() => {
    getSkillOptions();
  }, []);

  React.useEffect(() => {
    const firstSkill = skillOptions?.[0] ?? null;
    if (firstSkill) {
      setSelectedModuleOpt({ value: firstSkill?.id, label: firstSkill?.title, ...firstSkill });
      dispatch(setSelectedModuleId(selectedModuleOpt?.value));
      setSkillOpts(skillOptions?.map((skill) => ({ value: skill.id, label: skill.title })) ?? []);
    }
  }, [skillOptions]);

  React.useEffect(() => {
    if (selectedModuleOpt.value) dispatch(setSelectedModuleId(selectedModuleOpt?.value));
  }, [selectedModuleOpt]);

  const handleSearch = () => {
    setIsSearched(true);
    dispatch(setCadsAiCourseFilters({ search: searchRef?.current?.value, pageNumber: 1 }));
  };

  const handleClear = () => {
    if (searchRef?.current?.value) searchRef.current.value = '';
    setIsSearched(false);
    dispatch(setCadsAiCourseFilters({ search: '', pageNumber: 1 }));
  };

  return (
    <TableHead>
      <div className="table__search">
        <div className="table__search_item">
          <b>Course Name</b>
          <div className="form">
            <div className="form__form-group">
              <div className="form__form-group-field">
                <div className="form__form-group-icon" style={{ height: 37 }}>
                  <MagnifyIcon />
                </div>
                <input
                  ref={searchRef}
                  className="search-bar-search-text"
                  name="searchText"
                  type="text"
                  placeholder="Search by Course Name"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table__search_item">
          <b>Module</b>
          <div style={{ minWidth: 200 }}>
            <Select
              name="organization"
              options={skillOptions}
              value={selectedModuleOpt}
              onChange={setSelectedModuleOpt}
              autosize={true}
              clearable={false}
              className="react-select"
              placeholder={'Select organization'}
              classNamePrefix="react-select"
            />
          </div>
        </div>
        <div className="table__search_item mt-3">
          <Button color="primary" onClick={handleSearch}>
            {' '}
            Search{' '}
          </Button>
        </div>
        <div className="table__search_item mt-3">
          <Button color="secondary" onClick={handleClear} outline>
            Clear
          </Button>
        </div>
      </div>

      {isSearched && (
        <div className="ml-1">
          <span>Found {totalModuleCount} matches</span>
        </div>
      )}
    </TableHead>
  );
}
