import { sendRequest } from '../../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const unifiedAPIServiceBase =
  process.env.REACT_APP_CADS_AI_API + process.env.REACT_APP_ADMIN_ENDPOINT;

const skillsEndpoints = {
  ADD_SKILL: `${unifiedAPIServiceBase}/addSkill`,
  EDIT_SKILL: `${unifiedAPIServiceBase}/editSkill`,
  GET_SKILL: `${unifiedAPIServiceBase}/getSkill`,
  GET_SKILL_LIST: `${unifiedAPIServiceBase}/getSkillList`,
  DELETE_SKILL: `${unifiedAPIServiceBase}/deleteSkill`,
};

/**
 * @param skillData Information required for adding skills.
 * @param {string} skillData.name
 * @param {string} skillData.description
 * @param {array} skillData.competencies
 */
const addSkill = (skillData) => {
  return sendRequest({
    fullUrl: skillsEndpoints.ADD_SKILL,
    method: httpMethod.POST,
    data: skillData,
  });
};

/**
 * @param skillData updated skills data.
 * @param {number} skillData.id existing skill id
 * @param {string} skillData.name
 * @param {string} skillData.description
 * @param {array} skillData.competencies
 */
const updateSkill = (skillData) => {
  return sendRequest({
    fullUrl: skillsEndpoints.EDIT_SKILL,
    method: httpMethod.PUT,
    data: skillData,
  });
};

/**
 * @param {number} skillId id of skill to fetch more details about the skill
 */
const getSkill = (skillId) => {
  return sendRequest({
    fullUrl: skillsEndpoints.GET_SKILL,
    method: httpMethod.GET,
    params: { request: skillId },
  });
};

/**
 * @param filter to filter data based on some parameters
 * @param {number} filter.profile profile id to filter
 * @param {Array<number>} filter.domains array of domain id to filter
 * @param {string} filter.search to filter skills by search text
 * @param {string} filter.sortBy to sort based on any field
 * @param {boolean} filter.isSortOrderDescending true if search direction is descending
 **/
const getSkillList = (filter) => {
  return sendRequest({
    fullUrl: skillsEndpoints.GET_SKILL_LIST,
    method: httpMethod.GET,
    params: filter,
  });
};

/**
 * @param {number} skillId id of skill to be deleted
 */
const deleteSkill = (skillId) => {
  return sendRequest({
    fullUrl: skillsEndpoints.DELETE_SKILL,
    method: httpMethod.DELETE,
    params: { id: skillId },
  });
};

export default {
  addSkill,
  updateSkill,
  getSkill,
  getSkillList,
  deleteSkill,
};

const getMockSkillList = () =>
  new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [''],
            data: {
              totalRecords: 2,
              currentPage: 1,
              pageSize: 10,
              records: [
                {
                  id: 1,
                  name: 'Skill 1',
                  description: 'This is some description about Skill 1',
                },
                {
                  id: 2,
                  name: 'SKill 2',
                  description: 'This is some description about skill 2',
                },
              ],
            },
          },
        }),
      2000,
    );
  });
