import React, { useEffect, useState } from 'react';
import { connect, shallowEqual, useSelector } from 'react-redux';
import { Modal } from 'reactstrap';
import DdoViewDialogHeader from './DdoViewDialogHeader';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { renderDdoDetail } from './helper';

const DdoViewDialog = ({show, onHide, id, rtl}) => {

  const {data: ddoList} = useSelector((state) => state.cadsAIDdo, shallowEqual);

  const [modal] = useState({
    isOpen: show,
    title: 'View DDO',
    hide: onHide,
  });

  const [state, setState] = useState({
    loading: true,
    ddo: {},
    error: '',
  });

  useEffect(() => {
    if (id) getDdoById(id);
  }, [id]);

  const getDdoById = (id) => {
    if (ddoList?.length) {
      const ddo = ddoList.filter(ddo => ddo.id === +id)?.[0] ?? {}
      setState({...state, loading: false, ddo, error: ''});
    } else {
      setState({...state, loading: false, ddo: {}, error: "Error while getting ddo details."});
    }

  };

  const {error, loading, ddo} = state;

  return (
      <Modal
          isOpen={show}
          toggle={modal.hide}
          modalClassName={`${rtl.direction}-support`}
      className="modal-dialog--primary modal-dialog--header modal-view-dialog"
    >
      <DdoViewDialogHeader modal={modal} ddo={ddo} />
      {/* Ddo Modal Body */}
      <div style={{ padding: 15 }}>
        {loading || !!error ? (
          <>
            {loading && (
              <div className="panel__refresh position-relative" style={{ height: 75 }}>
                <LoadingIcon />
              </div>
            )}
            <div className="text-center">
              {error && (
                <div>
                  <span className="lnr lnr-cross-circle modal__title-icon error" />
                </div>
              )}
              {loading ? 'Getting ddo details...' : error}
            </div>
          </>
        ) : (
          <div className="table-wrapper">
            <table className="ddo-summary__info">
              {renderDdoDetail(ddo).map(item => 
               <tr>
               <th className="w-50">{item.label}</th>
               <td>{item?.name}</td>
             </tr>
                )}
            </table>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default connect((state) => ({
  rtl: state.rtl,
}))(DdoViewDialog);
