import React from 'react';
import {
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import OrganizationAssignedTPTable from './OrganizationAssignedTPTable';
import { useLocation } from 'react-router-dom';
import { ORGANIZATION, assignedModuleORTPTabs } from '@/shared/helpers/constants';

const AssignedTPTabs = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = React.useState(ORGANIZATION);

  const toggle = (tab) => {
    setActiveTab(tab);
  };

  React.useEffect(() => {
    const activeTabParam = new URLSearchParams(location.search)?.get('activeTab');
    if (activeTabParam && activeTabParam !== activeTab && [ORGANIZATION].includes(activeTab)) {
      toggle(activeTabParam);
    }
  }, [location.search]);

  return (
    <Col className="card-body">
      <div className="tabs tabs--bordered-bottom">
        <div className="tabs__wrap">
          <Nav tabs>
            {Object.keys(assignedModuleORTPTabs).map((key) => (
              <NavItem key={assignedModuleORTPTabs[key].id}>
                <NavLink
                  className={classnames({ active: activeTab === assignedModuleORTPTabs[key].id })}
                  onClick={() => toggle(assignedModuleORTPTabs[key].id)}
                >
                  {assignedModuleORTPTabs[key].label}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={activeTab}>
              <Container>
                <Row>
                  <CardBody>
                    {activeTab === ORGANIZATION && <OrganizationAssignedTPTable />}
                  </CardBody>
                </Row>
              </Container>
            </TabPane>
          </TabContent>
        </div>
      </div>
    </Col>
  );
};

export default AssignedTPTabs;
