import { useEffect } from 'react';
import Highcharts from 'highcharts';
import Tree from 'highcharts/modules/treemap';
import exporting from 'highcharts/modules/exporting';
import data from 'highcharts/modules/data';
import Heatmap from 'highcharts/modules/heatmap';

Tree(Highcharts);
data(Highcharts);
Heatmap(Highcharts);
exporting(Highcharts);

const QAViews = () => {
  const initialChart = () => {
    Highcharts.chart('QAViews', {
      chart: {
        type: 'column',
      },

      title: {
        text: 'Views',
      },

      xAxis: {
        type: 'category',
      },

      yAxis: {
        allowDecimals: true,
        min: 0,
        stackLabels: {
          enabled: true,
        },
        labels: {
          formatter: function () {
            return this.value;
          },
        },
        title: {
          text: null,
        },
      },

      credits: {
        enabled: false,
      },

      tooltip: { enabled: false },

      colors: ['#005cb1'],

      plotOptions: {
        column: {
          stacking: 'normal',
          groupPadding: 0,
          pointPadding: 0,
          shadow: false,
          borderWidth: 0,
        },
        series: {
          enableMouseTracking: false,
        },
      },

      series: [
        {
          name: 'Views',
          data: [
            ['7 Oct', 95],
            ['8 Oct', 90],
            ['9 Oct', 50],
            ['10 Oct', 91],
            ['11 Oct', 66],
            ['12 Oct', 97],
            ['13 Oct', 81],
          ],
        },
      ],
    });
  };

  useEffect(() => {
    initialChart();
  }, []);

  return <div id="QAViews" className="col-lg-3" />;
};

export default QAViews;
