import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'reactstrap';
import TableHead from '@material-ui/core/TableHead';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  setSelectedCohortId,
  setSelectedOrganizationId,
  setUsersFilters,
} from '../../redux/action';
import Select from 'react-select';
import { getCohortOptionsList } from '../../../redux/cohort/actions';
import { getOrganizationOptionsList } from '../../../redux/organization/actions';
import { isEmpty } from 'lodash';

export function UsersTableSearchBar() {
  const dispatch = useDispatch();
  const totalUserCount = useSelector((state) => state.cadsAIUserData?.data?.length, shallowEqual);
  const { options: cohortOpts } = useSelector(
    (state) => state.cadsAIAssignmentCohort,
    shallowEqual,
  );
  const { options: organizationOpts } = useSelector((state) => state.cadsAIOrgs, shallowEqual);

  /*Use States*/
  const [isSearched, setIsSearched] = useState(false);
  const [cohortOptions, setCohortOptions] = React.useState([]);
  const [selectedCohortOpt, setSelectedCohortOpt] = React.useState(null);
  const [organizationOptions, setOrganizationOptions] = React.useState([]);
  const [selectedOrgOpt, setSelectedOrgOpt] = React.useState(null);

  /*REFs*/
  const searchNameRef = useRef(null);
  const searchEmailRef = useRef(null);

  const handleSearch = () => {
    dispatch(
      setUsersFilters({
        name: searchNameRef?.current?.value,
        email: searchEmailRef?.current?.value,
      }),
    );
  };

  const handleClear = () => {
    if (searchNameRef?.current?.value) searchNameRef.current.value = '';
    if (searchEmailRef?.current?.value) searchEmailRef.current.value = '';
    setIsSearched(false);
    dispatch(setUsersFilters({ name: '', email: '' }));
    setSelectedCohortOpt(null);
    dispatch(setSelectedCohortId(null));
    setSelectedOrganizationId(null);
    setSelectedOrgOpt(null);
  };

  useEffect(() => {
    dispatch(getOrganizationOptionsList());
  }, []);

  useEffect(() => {
    if (selectedOrgOpt?.value) {
      dispatch(getCohortOptionsList(selectedOrgOpt?.value));
    }
  }, [selectedOrgOpt?.value]);

  useEffect(() => {
    dispatch(setSelectedCohortId(selectedCohortOpt?.value));
  }, [selectedCohortOpt]);

  useEffect(() => {
    dispatch(setSelectedOrganizationId(selectedOrgOpt?.value));
  }, [selectedOrgOpt]);

  useEffect(() => {
    if (!cohortOpts?.length) {
      setSelectedCohortOpt(null);
      dispatch(setSelectedCohortId(null));
    }
    setCohortOptions(
      cohortOpts?.map((org) => ({
        value: org.id,
        label: org.title,
      })) ?? [],
    );
  }, [cohortOpts]);

  useEffect(() => {
    if (!isEmpty(organizationOpts))
      setOrganizationOptions(
        organizationOpts?.map((org) => ({ value: org.id, label: org.title })) ?? [],
      );
  }, [organizationOpts]);

  return (
    <TableHead>
      <div className="table__search">
        <div className="table__search_item">
          <b>User Name</b>
          <div className="form" style={{ width: 300 }}>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <div className="form__form-group-icon" style={{ height: 37 }}>
                  <MagnifyIcon />
                </div>
                <input
                  ref={searchNameRef}
                  className="search-bar-search-text"
                  name="name"
                  type="text"
                  placeholder="Search by User Name"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table__search_item">
          <b>User Email</b>
          <div className="form" style={{ width: 300 }}>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <div className="form__form-group-icon" style={{ height: 37 }}>
                  <MagnifyIcon />
                </div>
                <input
                  ref={searchEmailRef}
                  className="search-bar-search-text"
                  name="email"
                  type="text"
                  placeholder="Search by User Email"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table__search_item">
          <b>Cohort</b>
          <div style={{ minWidth: 200 }}>
            <Select
              name="cohort"
              options={cohortOptions}
              value={selectedCohortOpt}
              onChange={setSelectedCohortOpt}
              autosize={true}
              clearable={false}
              className="react-select"
              placeholder={'Select Cohort'}
              classNamePrefix="react-select"
            />
          </div>
        </div>
        <div className="table__search_item">
          <b>Organization</b>
          <div style={{ minWidth: 200 }}>
            <Select
              name="organization"
              options={organizationOptions}
              value={selectedOrgOpt}
              onChange={setSelectedOrgOpt}
              autosize={true}
              clearable={false}
              className="react-select"
              placeholder={'Select Organization'}
              classNamePrefix="react-select"
            />
          </div>
        </div>
        <div className="table__search_item mt-3">
          <Button color="primary" onClick={handleSearch}>
            {' '}
            Search{' '}
          </Button>
          <Button color="secondary" onClick={handleClear} outline>
            Clear
          </Button>
        </div>
      </div>

      {isSearched && (
        <div className="ml-1">
          <span>Found {totalUserCount} matches</span>
        </div>
      )}
    </TableHead>
  );
}
