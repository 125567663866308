import React, { createContext, useContext } from 'react';

const DSCohortUIContext = createContext();

export function useDSCohortUIContext() {
  return useContext(DSCohortUIContext);
}

export function DSCohortUIProvider({ dsCohortUIEvents, children }) {
  const value = {
    openViewDSCohortDialog: dsCohortUIEvents.openViewDSCohortDialog,
    openNewDSCohortDialog: dsCohortUIEvents.openNewDSCohortDialog,
    openEditDSCohortDialog: dsCohortUIEvents.openEditDSCohortDialog,
    openDeleteDSCohortDialog: dsCohortUIEvents.openDeleteDSCohortDialog,
  };

  return <DSCohortUIContext.Provider value={value}>{children}</DSCohortUIContext.Provider>;
}
