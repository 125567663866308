import { sendRequest } from '../../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const unifiedAPIServiceBase =
  process.env.REACT_APP_CADS_AI_API + process.env.REACT_APP_ADMIN_ENDPOINT;

const domainEndpoints = {
  GET_DOMAINS_LIST: `${unifiedAPIServiceBase}/getDomainsList`,
  GET_DOMAIN_BY_ID: `${unifiedAPIServiceBase}/getDomainById`,
  ADD_DOMAIN: `${unifiedAPIServiceBase}/addDomain`,
  EDIT_DOMAIN: `${unifiedAPIServiceBase}/editDomain`,
  DELETE_DOMAIN: `${unifiedAPIServiceBase}/deleteDomain`,
  DELETE_SUB_CATEGORY_FROM_DOMAIN: `${unifiedAPIServiceBase}/unlinkSubCategoryFromDomain`,
  ADD_SUB_CATEGORY_TO_DOMAIN: `${unifiedAPIServiceBase}/addSubCategoriesToDomain`,

  GET_DOMAIN_OPTIONS_LIST: `${unifiedAPIServiceBase}/getAssessmentDomainList`,
};

/**
 * @param filter to filter data based on some parameters
 * @param {number} filter.pageNumber page number
 * @param {number} filter.pageSize page size
 * @param {string} filter.searchText to filter domains by search text
 * @param {string} filter.sortBy to sort based on any field
 * @param {string} filter.status to search based on status
 * @param {boolean} filter.isSortOrderDescending true if search direction is descending
 **/
const getDomainsList = (filter) => {
  return sendRequest({
    fullUrl: domainEndpoints.GET_DOMAINS_LIST,
    method: httpMethod.GET,
    params: filter,
  });
};

/**
 * @param domainId - id of domain to be fetched
 * */
const getDomainById = (domainId) => {
  return sendRequest({
    fullUrl: domainEndpoints.GET_DOMAIN_BY_ID,
    method: httpMethod.GET,
    params: { id: domainId },
  });
};

/**
 * @Param domain - domain to be added
 * @Param {string} domain.name - name of domain
 * @param {string} domain.description - description of domain
 * */
const addDomain = (domain) => {
  return sendRequest({
    fullUrl: domainEndpoints.ADD_DOMAIN,
    method: httpMethod.POST,
    data: domain,
  });
};

/**
 * @Param domain - domain to be edited
 * @param {number} domain.id - id of domain
 * @Param {string} domain.name - name of domain
 * @param {string} domain.description - description of domain
 * */
const editDomain = (domain) => {
  return sendRequest({
    fullUrl: domainEndpoints.EDIT_DOMAIN,
    method: httpMethod.PUT,
    data: domain,
  });
};

/**
 * @param domainId - id of domain to be deleted
 * */
const deleteDomain = (domainId) => {
  return sendRequest({
    fullUrl: domainEndpoints.DELETE_DOMAIN,
    method: httpMethod.DELETE,
    params: { id: domainId },
  });
};

/**
 * @param {number} domainId - id of domain in which subcategory will be added
 * @param {Array<number>} subCategoryIds - list of skills with their respective weights
 * */
const addSubcategoryToDomain = (domainId, subCategoryIds) => {
  return sendRequest({
    fullUrl: domainEndpoints.ADD_SUB_CATEGORY_TO_DOMAIN,
    method: httpMethod.POST,
    data: { domainId, subCategoryIds },
  });
};

/**
 * @param domainId - id of domain from which skill will be deleted
 * @param subCategoryId - id of subcategory to be deleted from domains
 * */
const unLinkSubcategoryFromDomain = (domainId, subCategoryId) => {
  return sendRequest({
    fullUrl: domainEndpoints.DELETE_SUB_CATEGORY_FROM_DOMAIN,
    method: httpMethod.DELETE,
    params: { domainId, subCategoryId },
  });
};

/**
 * Gets the list all of domains to populate domain dropdown
 * */
const getDomainOptionsList = () => {
  return sendRequest({
    fullUrl: domainEndpoints.GET_DOMAIN_OPTIONS_LIST,
    method: httpMethod.GET,
  });
};

export default {
  addDomain,
  editDomain,
  deleteDomain,
  getDomainById,
  getDomainsList,
  getDomainOptionsList,
  addSubcategoryToDomain,
  unLinkSubcategoryFromDomain,
};
