import React from 'react';
import { Row } from 'reactstrap';
import Panel from '@/shared/components/Panel';
import JobsTrend from './JobsTrend';
import JobsFollowersFunction from './JobsFollowersFunction';
import JobFollowersIndustry from './JobFollowersIndustry';

const JobsSection = () => {
  return (
    <Row className="mt-3">
      <Panel lg={12} xl={12} title={'Jobs'}>
        <JobsTrend />
      </Panel>
      <Panel lg={6} xl={6} title={''}>
        <JobFollowersIndustry />
      </Panel>
      <Panel lg={6} xl={6} title={''}>
        <JobsFollowersFunction />
      </Panel>
    </Row>
  );
};

export default JobsSection;
