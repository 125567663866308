import React, { createContext, useContext } from 'react';

const NotificationUIContext = createContext();

export function useNotificationUIContext() {
  return useContext(NotificationUIContext);
}

export function NotificationUIProvider({ notificationUIEvents, children }) {
  const value = {
    openNotifications: notificationUIEvents.openNotifications,
    openViewNotificationDialog: notificationUIEvents.openViewNotificationDialog,
    openNewNotificationDialog: notificationUIEvents.openNewNotificationDialog,
    openEditNotificationDialog: notificationUIEvents.openEditNotificationDialog,
    openDeactivateNotificationDialog: notificationUIEvents.openDeactivateNotificationDialog,
  };

  return <NotificationUIContext.Provider value={value}>{children}</NotificationUIContext.Provider>;
}
