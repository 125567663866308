import { handleActions } from 'redux-actions';
import { fetchSunrise25UserList, setSunrise25UserList, setSunrise25UserError } from './action';

const defaultState = {
  loading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: 'name',
    search: '',
    isSortOrderDescending: false,
  },
  totalRecords: 0,
  data: [],
  error: null,
};

export default handleActions(
  {
    [fetchSunrise25UserList](state) {
      return {
        ...state,
        loading: true,
        data: [],
      };
    },
    [setSunrise25UserList](state, { payload }) {
      return {
        ...state,
        loading: false,
        data: payload.data,
        totalRecords: payload.data.length,
        error: null,
      };
    },
    [setSunrise25UserError](state, { payload }) {
      return {
        ...state,
        data: [],
        loading: false,
        totalPages: defaultState.totalPages,
        error: payload?.message,
      };
    },
  },
  defaultState,
);
