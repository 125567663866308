import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loading from '@/shared/components/Loading';
import { paths } from '@/shared/helpers/paths';
import './index.scss';
import AssignPage from './components/AssignPage';

const assignBaseUrl = paths.CADS_AI.LEARNING.ASSIGN;
export default function Assign() {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path={assignBaseUrl} component={AssignPage} />
      </Switch>
    </Suspense>
  );
}
