import React from 'react';
import LogInForm from './LogInForm';
import './login.scss';

const LogIn = () => (
  <div className="account account--not-photo">
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__head">
          <p className="topbar__logo mx-auto" style={{ height: '55px' }} />
        </div>
        <LogInForm form="log_in_form" />
      </div>
    </div>
  </div>
);

export default LogIn;
