import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const fetchAnnouncementList = createAction('CADS_AI_FETCH_ANNOUNCEMENT_LIST');
export const setAnnouncementList = createAction('CADS_AI_SET_ANNOUNCEMENT_LIST');
export const setAnnouncementCount = createAction('CADS_AI_SET_ANNOUNCEMENT_COUNT');
export const setAnnouncementError = createAction('CADS_AI_SET_ANNOUNCEMENT_ERROR');
export const setAnnouncementFilters = createAction('CADS_AI_SET_ANNOUNCEMENT_FILTERS');
export const toggleCheckedAnnouncement = createAction('CADS_AI_TOGGLE_CHECKED_ANNOUNCEMENTS');
export const uncheckAllAnnouncement = createAction('CADS_AI_UNCHECK_ALL_ANNOUNCEMENTS');

export const toggleCheckedItemById = (announcementId) => (dispatch) => {
  dispatch(toggleCheckedAnnouncement(announcementId));
};

export const uncheckAllSelectedAnnouncement = () => (dispatch) => {
  dispatch(uncheckAllAnnouncement());
};

export const getAnnouncementCount = (filter) => async (dispatch) => {};

export const getAnnouncementList = (filter) => async (dispatch) => {
  const defaultError = `Error occurred getting announcement list`;
  dispatch(fetchAnnouncementList());
  try {
    const { data, isSuccess } = await api.cadsAIAnnouncements.getAnnouncementList(filter);
    if (isSuccess) dispatch(setAnnouncementList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setAnnouncementError(error.message));
    console.error(defaultError, error.message);
  }
};
