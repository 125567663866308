import React, { createContext, useContext } from 'react';

const UsersUIContext = createContext();

export function useUsersUIContext() {
  return useContext(UsersUIContext);
}

const UsersUIProvider = ({ usersUIEvents, children }) => {
  const value = {
    openViewUserDialog: usersUIEvents.openViewUserDialog,
  };
  return <UsersUIContext.Provider value={value}>{children}</UsersUIContext.Provider>;
};

export default UsersUIProvider;
