import React from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import UsersTable from './UsersTable';
import api from '@/utils/apiClient';
import { shallowEqual, useSelector } from 'react-redux';

const UsersTableWrapper = () => {
  const cadsAIUserFilters = useSelector((state) => state.cadsAIUserData.filters, shallowEqual);

  const downloadCSV = async () => {
    const usersCSVFilters = { ...cadsAIUserFilters, pageNumber: null, pageSize: null };
    const csvData = await api.cadsAIUsers.getCadsAIUsersListCSV(usersCSVFilters);
    require('js-file-download')(
      csvData,
      `Cads_AI_User_List__${new Date().toLocaleString().replace(/[\/:,\s]/gi, '_')}.csv`,
    );
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="products-list">
          <div className="card__title">
            <h5 className="bold-text">Data Users List</h5>
            <ButtonToolbar className="products-list__btn-toolbar-top">
              <Button color="success" onClick={downloadCSV} outline>
                Export Users
              </Button>
            </ButtonToolbar>
          </div>
          <UsersTable />
        </CardBody>
      </Card>
    </Col>
  );
};

export default UsersTableWrapper;
