import OverviewContainer from './OverviewContainer';
import { Col, Row } from 'reactstrap';
import { Fragment } from 'react';
import { useState } from 'react';
import OverviewDescription from './OverviewDescription';

const Overview = ({ smartSkillData }) => {
  const [selectedCard, setSelectedCard] = useState(1);

  const handleClick = (id) => {
    setSelectedCard(id);
  };

  const getSmartSkillFactor = (id) => {
    return smartSkillData?.find((element) => {
      return element.responseFactorId === id;
    });
  };

  return (
    <Fragment>
      <Row>
        <Col md={12}>
          <div className="row">
            {smartSkillData?.map((smartSkill, index) => {
              return (
                <OverviewContainer
                  color={smartSkill.color}
                  level={smartSkill.scoreTitle}
                  value={smartSkill.score}
                  title={smartSkill.factorName}
                  description={smartSkill.factorAlias}
                  isSelected={selectedCard === smartSkill.responseFactorId}
                  id={smartSkill.factorId}
                  handleClick={() =>handleClick(smartSkill?.responseFactorId)}
                />
              );
            })}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={'12'}>
          <OverviewDescription
            id={selectedCard}
            color={getSmartSkillFactor(selectedCard)?.color}
            title={getSmartSkillFactor(selectedCard)?.factorName}
            description={getSmartSkillFactor(selectedCard)?.factorDescription}
            behavioralAnalysis={getSmartSkillFactor(selectedCard)?.behavioralAnalysis}
            competenciesAnalysis={getSmartSkillFactor(selectedCard)?.competenciesAnalysis}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default Overview;
