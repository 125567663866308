import cpUserReducer from '@/containers/Control-Panel/redux/user/reducer';
import cpCohortReducer from '@/containers/Control-Panel/redux/cohort/reducer';
import cpOrgReducer from '@/containers/Control-Panel/redux/organization/reducer';
import cpCourseReducer from '@/containers/Control-Panel/redux/course/reducer';
import cpModuleReducer from '@/containers/Control-Panel/Module/redux/reducer';
import cpModuleScheduleReducer from '@/containers/Control-Panel/redux/moduleSchedule/reducer';
import cpLearningPathReducer from '@/containers/Control-Panel/LearningPath/redux/reducer';
import cpTrainingProviderReducer from '@/containers/Control-Panel/TrainingProvider/redux/reducer';
import controlPanelReducer from '@/containers/Control-Panel/redux/reducer';
import assignPageReducer from '@/containers/Control-Panel/Assign/redux/reducer';
import assignedPageReducer from '@/containers/Control-Panel/Assigned/redux/reducer';
import baselineThresholdReducer from '@/containers/Control-Panel/BaselineThreshold/redux/reducer';
import cadsAIAssignedPageReducer from '@/containers/Cads-AI/ManageLearning/Assigned/redux/reducer';

export const CPReducers = {
  controlPanel: controlPanelReducer,
  assignPage: assignPageReducer,
  assignedPage: assignedPageReducer,
  cadsAIAssignedPage: cadsAIAssignedPageReducer,
  baselineThreshold: baselineThresholdReducer,
  cpUsers: cpUserReducer,
  cpCohorts: cpCohortReducer,
  cpOrgs: cpOrgReducer,
  cpCourses: cpCourseReducer,
  cpModules: cpModuleReducer,
  cpModuleSchedules: cpModuleScheduleReducer,
  cpLearningPaths: cpLearningPathReducer,
  cpTrainingProviders: cpTrainingProviderReducer,
};
