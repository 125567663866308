import React, { memo } from 'react';
import { Button, Table } from 'reactstrap';
import { CheckBoxField } from '@/shared/components/form/CheckBox';
import { useForm } from 'react-hook-form';
import { emptyStringPattern } from '@/shared/helpers';
import { FormField } from '@/shared/components/form/hook/FormField';
import DeleteIcon from 'mdi-react/DeleteIcon';
import { useDispatch } from 'react-redux';
import { setConfigPageQuestionTypeData } from '../../redux/actions';
import { getDefaultAnswerProps } from '../../redux/util';
import { RadioButtonField } from '@/shared/components/form/RadioButton';
import { LikertViewOptions } from '@/containers/Admin/Assessments/components/assessment-config-card/general-assessment-config/options';

const AnswerTableRow = ({ position, answer, pageId, questionId, mcqMultiple, dispatch }) => {
  const { control, errors, setValue } = useForm();

  const handleChange = (fieldName, fieldValue) => {
    setValue(fieldName, fieldValue);
    dispatch(
      setConfigPageQuestionTypeData({
        pageId,
        questionId,
        typeData: {
          mcqMultiple: {
            ...mcqMultiple,
            answers: mcqMultiple.answers.map((ans) =>
              ans.id !== answer.id ? ans : { ...ans, [fieldName]: fieldValue },
            ),
          },
        },
      }),
    );
  };

  const deleteAnswer = () => {
    dispatch(
      setConfigPageQuestionTypeData({
        pageId,
        questionId,
        typeData: {
          mcqMultiple: {
            ...mcqMultiple,
            answers: mcqMultiple.answers.filter((ans) => ans.id !== answer.id),
          },
        },
      }),
    );
  };

  return (
    <tr key={answer.id}>
      <td>{position}</td>
      <td>
        <FormField
          name="answerText"
          customClassName="form pl-3 pr-3"
          control={control}
          component="input"
          errors={errors}
          onChange={({ target: { value } }) => handleChange('answerText', value)}
          rules={{
            required: 'This is required field',
            pattern: emptyStringPattern,
          }}
          defaultValue={answer.answerText}
          isAboveError={true}
          placeholder="Enter answer"
        />
      </td>
      <td>
        <FormField
          name="points"
          control={control}
          customClassName="form pl-3 pr-3"
          component="input"
          type="number"
          onChange={({ target: { value } }) => handleChange('points', +value)}
          errors={errors}
          rules={{
            required: 'This is required field',
            pattern: emptyStringPattern,
          }}
          defaultValue={answer.points}
          isAboveError={true}
          placeholder="Enter points"
        />
      </td>
      <td>
        <DeleteIcon style={{ color: '#ff4861' }} role="button" onClick={deleteAnswer} />
      </td>
    </tr>
  );
};

const MCQMultiSelect = ({
  pageId,
  questionId,
  typeData,
  showOthersCheckBox = true,
  showViewRadioBtn = false,
}) => {
  const dispatch = useDispatch();
  const { allowOther, answers, view } = typeData?.mcqMultiple ?? { allowOther: false, answers: [] };
  const { HORIZONTAL, VERTICAL } = LikertViewOptions;

  const toggleAllowOther = () => {
    dispatch(
      setConfigPageQuestionTypeData({
        pageId,
        questionId,
        typeData: {
          mcqMultiple: {
            ...typeData.mcqMultiple,
            allowOther: !allowOther,
          },
        },
      }),
    );
  };

  const toggleView = () => {
    dispatch(
      setConfigPageQuestionTypeData({
        pageId,
        questionId,
        typeData: {
          mcqMultiple: {
            ...typeData.mcqMultiple,
            view: view === HORIZONTAL ? VERTICAL : HORIZONTAL,
          },
        },
      }),
    );
  };

  const addNewAnswer = () => {
    dispatch(
      setConfigPageQuestionTypeData({
        pageId,
        questionId,
        typeData: {
          mcqMultiple: {
            ...typeData.mcqMultiple,
            answers: [...answers, getDefaultAnswerProps()],
          },
        },
      }),
    );
  };

  return (
    <div className="question_type_field">
      {showOthersCheckBox && (
        <CheckBoxField
          className="colored-click w-fit-content"
          onChange={toggleAllowOther}
          checked={allowOther}
          name={`otherAllowed-${questionId}`}
          label={'Other Allowed ?'}
        />
      )}
      {showViewRadioBtn && (
        <>
          <span className="form__form-group-label mr-2" style={{ marginTop: '0.5rem' }}>
            View :{' '}
          </span>
          <RadioButtonField
            radioValue={view}
            name={`view-${HORIZONTAL}-${questionId}`}
            onChange={toggleView}
            value={HORIZONTAL}
            className="colored-click mr-2"
            label={HORIZONTAL}
          />
          <RadioButtonField
            radioValue={view}
            name={`view-${VERTICAL}-${questionId}`}
            onChange={toggleView}
            value={VERTICAL}
            className="colored-click"
            label={VERTICAL}
          />
        </>
      )}
      <Table striped responsive className="mb-3">
        <thead>
          <tr>
            <th style={{ width: 50 }}>#</th>
            <th>Answer</th>
            <th style={{ width: 145 }}>Points</th>
            <th style={{ width: 110 }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {answers?.map((answer, idx) => (
            <AnswerTableRow
              position={idx + 1}
              key={answer.id}
              answer={answer}
              pageId={pageId}
              questionId={questionId}
              mcqMultiple={typeData.mcqMultiple}
              dispatch={dispatch}
            />
          ))}
        </tbody>
      </Table>
      <Button style={{ padding: '4px 10px' }} color="primary" onClick={addNewAnswer} outline>
        Add new Answer
      </Button>
    </div>
  );
};

export default memo(MCQMultiSelect);
