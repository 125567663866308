import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { cadsAiSponsorshipBaseUrl } from '../index';
import { SponsorshipUIProvider } from './SponsorshipUIProvider';
import { SponsorshipCard } from './SponsorshipCard';
import SponsorshipEditDialog from './sponsorship-add-edit-dialog/SponsorshipEditDialog';
import SponsorshipViewDialog from './sponsorship-view-dialog/SponsorshipViewDialog';
import { setSponsorshipFilters, uncheckAllSponsorships } from '../redux/actions';
import { useDispatch } from 'react-redux';
import SponsorshipDeleteDialog from './sponsorship-delete-dialog/SponsorshipDeleteDialog';

export function SponsorshipPage({ history, match }) {
  const dispatch = useDispatch();

  //clean up
  React.useEffect(() => () => dispatch(uncheckAllSponsorships()), []);

  const sponsorshipUIEvents = {
    openViewSponsorshipDialog: (id) => {
      history.push(`${cadsAiSponsorshipBaseUrl}/${id}/view`);
    },
    openNewSponsorshipDialog: () => {
      history.push(`${cadsAiSponsorshipBaseUrl}/new`);
    },
    openEditSponsorshipDialog: (id) => {
      history.push(`${cadsAiSponsorshipBaseUrl}/${id}/edit`);
    },
    openDeleteSponsorshipDialog: (id) => {
      history.push(`${cadsAiSponsorshipBaseUrl}/${id}/delete`);
    },
  };

  return (
    <SponsorshipUIProvider sponsorshipUIEvents={sponsorshipUIEvents}>
      <Switch>
        <Route path={`${cadsAiSponsorshipBaseUrl}/new`}>
          {({ history, match }) => (
            <SponsorshipEditDialog
              show={match != null}
              isEdit={false}
              onHide={() => {
                dispatch(setSponsorshipFilters());
                history.push(cadsAiSponsorshipBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${cadsAiSponsorshipBaseUrl}/:id/edit`}>
          {({ history, match }) => (
            <SponsorshipEditDialog
              show={match != null}
              isEdit={true}
              id={match?.params?.id}
              onHide={() => {
                dispatch(setSponsorshipFilters());
                history.push(cadsAiSponsorshipBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${cadsAiSponsorshipBaseUrl}/:id/delete`}>
          {({ history, match }) => (
            <SponsorshipDeleteDialog
              show={match != null}
              isEdit={true}
              id={match?.params?.id}
              onHide={() => {
                dispatch(setSponsorshipFilters());
                history.push(cadsAiSponsorshipBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${cadsAiSponsorshipBaseUrl}/:id/view`}>
          {({ history, match }) => (
            <SponsorshipViewDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                history.push(cadsAiSponsorshipBaseUrl);
              }}
            />
          )}
        </Route>
      </Switch>
      <SponsorshipCard />
    </SponsorshipUIProvider>
  );
}
