import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import api from '@/utils/apiClient';
import ApplicantsTable from './ApplicantsTable';
import { useApplicantsUIContext } from '../ApplicantsUIProvider';

const ApplicantsTableWrapper = () => {
  const applicantsUIContext = useApplicantsUIContext();
  const { filters: applicantFilter, checkedApplicantIds } = useSelector(
    (state) => state.applicants,
    shallowEqual,
  );

  const downloadCSV = async () => {
    const programId = applicantFilter.programId;
    const applicantCsvFilter = {
      programId,
    };
    const csvData = await api.applicants.getApplicantListCSV(applicantCsvFilter);
    require('js-file-download')(
      csvData,
      `Applicant_List__${new Date().toLocaleString().replace(/[\/:,\s]/gi, '_')}.csv`,
    );
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="products-list">
          <div className="card__title">
            <ButtonToolbar className="products-list__btn-toolbar-top">
              <Button
                color="success"
                onClick={applicantsUIContext.openUpdateStatusDialog}
                outline
                disabled={checkedApplicantIds.length === 0}
              >
                Update status
              </Button>
              <Button
                color="success"
                onClick={applicantsUIContext.openUpdateStageDialog}
                outline
                disabled={checkedApplicantIds.length === 0}
              >
                Update stage
              </Button>
              <Button
                color="success"
                onClick={applicantsUIContext.openUpdateCohortDialog}
                outline
                disabled={checkedApplicantIds.length === 0 || !applicantFilter?.programId}
              >
                Update cohort
              </Button>
              <Button color="success" onClick={downloadCSV} outline>
                Export
              </Button>
            </ButtonToolbar>
          </div>
          <br />
          <ApplicantsTable />
        </CardBody>
      </Card>
    </Col>
  );
};

export default ApplicantsTableWrapper;
