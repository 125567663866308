import React, { Fragment, useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import { Button } from 'reactstrap';
import { useSpamRequestsUIContext } from '../SpamRequestsUIProvider';
import SpamRequestsTableColumns, { SpamRequestsColumns } from './SpamRequestsTableColumns';
import { SpamRequestsTableSearchBar } from './SpamRequestsTableSearchBar';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getSpamRequestList, setSpamRequestFilters } from '../../redux/actions';
import EyeIcon from 'mdi-react/EyeIcon';
import HideIcon from 'mdi-react/HideIcon';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { defaultPaginationProps } from '@/shared/helpers';
import moment from 'moment';

const SpamRequestsTable = () => {
  const spamRequestUIContext = useSpamRequestsUIContext();
  const dispatch = useDispatch();
  const {
    data: spamRequests,
    error,
    filters,
    loading,
    totalRecords,
  } = useSelector((state) => state.cadsAISpamRequests, shallowEqual);
  const {
    pageSize: rowsPerPage,
    pageNumber: page,
    sortBy: orderBy,
    isSortOrderDescending,
  } = filters;

  const [columns, setColumns] = useState(0);

  useEffect(() => {
    setColumns(SpamRequestsColumns.length);
  }, [SpamRequestsColumns.length]);

  React.useEffect(() => {
    dispatch(getSpamRequestList(filters));
  }, [filters]);

  const handleRequestSort = (event, property) => {
    dispatch(
      setSpamRequestFilters({
        sortBy: property,
        isSortOrderDescending: !isSortOrderDescending,
      }),
    );
  };

  const renderActionButtons = (id) => (
    <div className="actions">
       <Button
        className="icon mb-0"
        color="primary"
        outline
        onClick={() => spamRequestUIContext.openUpdateSpamDialog(id)}
      >
        <p>
          <HideIcon /> Hide
        </p>
      </Button>
      <Button
        className="icon mb-0"
        color="success"
        outline
        onClick={() => spamRequestUIContext.openViewSpamDialog(id)}
      >
        <p>
          <EyeIcon /> Details
        </p>
      </Button>
    </div>
  );

  const handleChangePage = (event, currentPage) => {
    dispatch(setSpamRequestFilters({ pageSize: rowsPerPage, pageNumber: currentPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setSpamRequestFilters({ pageSize: +event.target.value, pageNumber: 1 }));
  };

  const emptyRows = page === Math.ceil(totalRecords / rowsPerPage) ? rowsPerPage - spamRequests.length : 0;

  return (
    <Fragment>
      <div className="material-table__wrap">
        <SpamRequestsTableSearchBar />
        <Table className="material-table">
          <SpamRequestsTableColumns
            order={isSortOrderDescending ? 'desc' : 'asc'}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rowsPerPage}
          />
          <TableBody>
            {(loading || !!error) && (
              <TableRow>
                <TableCell colSpan={columns}>
                  {loading && (
                    <div className={'text-center overflow-hidden'}>
                      <div className="panel__refresh position-relative" style={{ height: 75 }}>
                        <LoadingIcon />
                      </div>
                    </div>
                  )}
                  <div className={'text-center'}>
                    <p className={!!error ? 'm-5' : ''}>{error || 'Loading Spam Requests...'}</p>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {!!!spamRequests?.length && !loading && !error && (
              <TableRow style={{ height: 120 }}>
                <TableCell colSpan={columns} align="center">
                  {filters.status === null && filters.search === ''
                    ? 'No Spam Requests'
                    : 'No Spam Requests with applied filters'}
                </TableCell>
              </TableRow>
            )}
            {spamRequests?.map((spam) => {
              return (
                <TableRow className="material-table" tabIndex={-1} key={spam?.id}>
                  <TableCell align="center" className="material-table__cell">
                    {spam?.reportedEmail}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                  {spam.reportedDate ? moment(spam.reportedDate).format('DD/MM/yyyy') : '-'}                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {spam.type}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {spam.details}
                  </TableCell>
                  <TableCell
                    align="center"
                    className="material-table__cell"
                    style={{ minWidth: 250 }}
                  >
                    {renderActionButtons(spam?.id)}
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={columns} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        component={defaultPaginationProps.component}
        backIconButtonProps={defaultPaginationProps.backIconButton}
        nextIconButtonProps={defaultPaginationProps.nextIconButton}
        rowsPerPageOptions={defaultPaginationProps.rowsPerPage}
        className={defaultPaginationProps.className}
        dir={defaultPaginationProps.dir}
      />
    </Fragment>
  );
};

export default SpamRequestsTable;
