import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import OrderHistoryTable from './OrderHistoryTable';

const OrderHistoryTableWrapper = () => {
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="products-list">
          <div className="card__title">
            <h5 className="bold-text">Order History List</h5>
          </div>
          <OrderHistoryTable />
        </CardBody>
      </Card>
    </Col>
  );
};

export default OrderHistoryTableWrapper;
