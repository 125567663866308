import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const fetchDomainList = createAction('FETCH_DOMAIN_LIST');
export const setDomainList = createAction('SET_DOMAIN_LIST');
export const setDomainOptionsList = createAction('SET_DOMAIN_OPTIONS_LIST');
export const updateInDomainList = createAction('UPDATE_DOMAIN_IN_LIST');
export const setDomainError = createAction('SET_DOMAIN_ERROR');
export const setDomainFilters = createAction('SET_DOMAIN_FILTERS');
export const deleteDomainFromList = createAction('DELETE_DOMAIN_FROM_LIST');

export const getDomainsList = (filter) => async (dispatch) => {
  const defaultError = 'Error occurred getting domains list';
  try {
    dispatch(fetchDomainList());
    const { data, isSuccess } = await api.domain.getDomainsList(filter);
    if (isSuccess) dispatch(setDomainList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setDomainError(error));
    console.error(defaultError, error.message);
  }
};

export const getDomainOptionsList = () => async (dispatch) => {
  const defaultError = 'Error occurred getting domain options list';
  try {
    const { data, isSuccess } = await api.domain.getDomainOptionsList();
    if (isSuccess) dispatch(setDomainOptionsList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    console.error(defaultError, error.message);
  }
};

export const updateDomainInList = (domain) => (dispatch) => {
  try {
    dispatch(updateInDomainList(domain.data));
  } catch (error) {
    console.error('Unable to update the domain in domain list');
  }
};

export const deleteDomain = (domainId) => (dispatch) => {
  try {
    dispatch(deleteDomainFromList(domainId));
  } catch (error) {
    console.error('Unable to remove the domain from domain list');
  }
};
