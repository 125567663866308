import React from 'react';
import { Col, Row } from 'reactstrap';
import GroupSkillComponent from './GroupSkillComponent';

export const HardSkills = ({ userDetails }) => {
  const skillList = [
    {
      category: 'Advanced Data Skills',
      skills: [
        { level: -1, skillId: 2, skillName: 'Python Programming' },
        { level: -1, skillId: 5, skillName: 'Excel' },
        { level: -1, skillId: 8, skillName: 'Statistics' },
        { level: -1, skillId: 11, skillName: 'SQL' },
        { level: -1, skillId: 15, skillName: 'Data Storytelling & Presentation Skill' },
        { level: -1, skillId: 18, skillName: 'Dashboards' },
        { level: -1, skillId: 21, skillName: 'Visualizations' },
        { level: -1, skillId: 30, skillName: 'Data Cleansing/Preparation' },
        { level: -1, skillId: 30, skillName: 'Data Cleansing/Preparation' },
        { level: -1, skillId: 30, skillName: 'Data Cleansing/Preparation' },
        { level: -1, skillId: 30, skillName: 'Data Cleansing/Preparation' },
        { level: -1, skillId: 30, skillName: 'Data Cleansing/Preparation' },
      ],
    },
    {
      category: 'Advanced Data Skills',
      skills: [
        { level: -1, skillId: 2, skillName: 'Python Programming' },
        { level: -1, skillId: 5, skillName: 'Excel' },
        { level: -1, skillId: 8, skillName: 'Statistics' },
        { level: -1, skillId: 11, skillName: 'SQL' },
        { level: -1, skillId: 15, skillName: 'Data Storytelling & Presentation Skill' },
        { level: -1, skillId: 18, skillName: 'Dashboards' },
        { level: -1, skillId: 21, skillName: 'Visualizations' },
        { level: -1, skillId: 30, skillName: 'Data Cleansing/Preparation' },
        { level: -1, skillId: 30, skillName: 'Data Cleansing/Preparation' },
        { level: -1, skillId: 30, skillName: 'Data Cleansing/Preparation' },
        { level: -1, skillId: 30, skillName: 'Data Cleansing/Preparation' },
        { level: -1, skillId: 30, skillName: 'Data Cleansing/Preparation' },
      ],
    },
  ];

  return (
    <div className="skills-wrapper">
      <Row>
        <Col lg={6}>
          <div className="skills-card">
            <div className={'card-body'}>
              <b className={'mt-4 font-size-14 font-color-for-common'}>
                Select Data literacy skills
              </b>
              {skillList.map((skill, index) => {
                return (
                  <div style={{ display: 'flex', flexWrap: 'wrap' }} key={index}>
                    <b className={'w-100 ml-1 mt-4 font-size-14 font-color-for-common'}>
                      {skill.category}
                    </b>
                    {skill.skills.map((skillName, skillIndex) => {
                      return <GroupSkillComponent index={skillIndex} skill={skillName} />;
                    })}
                    <br />
                  </div>
                );
              })}
            </div>
          </div>
        </Col>
        <Col lg={6}>
          <div className="skills-card">
            <div className={'card-body'}>
              <b className={'mt-4 font-size-14 font-color-for-common'}>Other skills</b>
              <div>
                {userDetails?.otherSkills?.map((skill, index) => {
                  return (
                    <div style={{ display: 'inline-flex', flexWrap: 'wrap' }} key={index}>
                      <div key={index} className="skill-level-component">
                        <span className={'skill-component__skill-name'}>{skill}</span>
                        <br />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
