import React, { useState } from 'react';
import { Modal } from 'reactstrap';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';

const images = [
  { id: 1, photo: 'https://cdn.pixabay.com/photo/2017/01/08/13/58/cube-1963036__340.jpg' },
  {
    id: 2,
    photo:
      'https://www.thedesignwork.com/wp-content/uploads/2011/10/Random-Pictures-of-Conceptual-and-Creative-Ideas-02.jpg',
  },
  { id: 3, photo: 'https://hatrabbits.com/wp-content/uploads/2017/01/random.jpg' },
  { id: 4, photo: 'https://cdn.pixabay.com/photo/2017/01/08/13/58/cube-1963036__340.jpg' },
  {
    id: 5,
    photo:
      'https://www.thedesignwork.com/wp-content/uploads/2011/10/Random-Pictures-of-Conceptual-and-Creative-Ideas-02.jpg',
  },
  { id: 6, photo: 'https://hatrabbits.com/wp-content/uploads/2017/01/random.jpg' },
  { id: 1, photo: 'https://cdn.pixabay.com/photo/2017/01/08/13/58/cube-1963036__340.jpg' },
  {
    id: 7,
    photo:
      'https://www.thedesignwork.com/wp-content/uploads/2011/10/Random-Pictures-of-Conceptual-and-Creative-Ideas-02.jpg',
  },
  { id: 8, photo: 'https://hatrabbits.com/wp-content/uploads/2017/01/random.jpg' },
];

const CompanyPhotos = () => {
  const [isOpenLightbox, setIsOpenLightbox] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [currentImagePreview, setCurrentImagePreview] = useState(0);
  const hideModal = images?.length < currentImage + 1;

  const changeImg = (item) => {
    setCurrentImagePreview(item);
    setCurrentImage(item);
  };

  const openLightbox = (index) => {
    setCurrentImage(index);
    setIsOpenLightbox(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setIsOpenLightbox(false);
  };

  return (
    <div className="gallery">
      {images.map((item, index) => (
        <div
          type="button"
          key={`index_${item?.photo}`}
          onClick={() => changeImg(index)}
          className="gallery__img"
        >
          <img
            className="gallery__img"
            src={item?.photo}
            onClick={() => openLightbox(currentImage)}
            alt="product-img"
          />
        </div>
      ))}
      {hideModal || (
        <Modal
          isOpen={isOpenLightbox}
          toggle={closeLightbox}
          className="modal-dialog--primary modal-dialog--carousel"
        >
          <div className="modal__body">
            <div className="modal__header">
              <button
                className="lnr lnr-cross modal__close-btn"
                type="button"
                aria-label="close lightbox button"
                onClick={closeLightbox}
              />
            </div>
            <div className="position-relative">
              <img src={images[currentImagePreview]?.photo} alt="product-img" />
              <div
                className="d-flex w-100 position-absolute"
                style={{ justifyContent: 'space-between', top: '50%' }}
              >
                <div className="modal__btn">
                  <ChevronLeftIcon
                    onClick={() => changeImg(currentImage - 1)}
                    className="modal__btn_left position-relative"
                  />
                </div>
                <div className="modal__btn">
                  <ChevronRightIcon
                    onClick={() => changeImg(currentImage + 1)}
                    className="modal__btn_left position-relative"
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default CompanyPhotos;
