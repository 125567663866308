import { handleActions } from 'redux-actions';
import { loginUserInitiate, loginUserSuccess, loginUserFailure, logoutUser } from './actions';
import { USER } from '@/shared/helpers/auth.enum';
import { getAppItem, extractUserDataFromToken } from '@/shared/helpers';

const defaultState = {
  loginResponse: null,
  isLoginSuccess: false,
  isLoginFailed: false,
  isLoggingIn: false,
  error: null,
  data: JSON.parse(getAppItem(USER)),
};

export default handleActions(
  {
    [loginUserInitiate](state) {
      return {
        ...state,
        isLoggingIn: true,
      };
    },
    [loginUserSuccess](state, { payload }) {
      return {
        ...state,
        isLoggingIn: false,
        loginResponse: payload,
        data: extractUserDataFromToken(payload.data?.token),
        isLoginSuccess: true,
        isLoginFailed: false,
        error: null,
      };
    },
    [loginUserFailure](state, { payload }) {
      return {
        ...state,
        isLoggingIn: false,
        loginResponse: null,
        isLoginFailed: true,
        isLoginSuccess: false,
        error: payload?.messages?.[0] ?? payload?.message,
        data: null,
      };
    },
    [logoutUser](state) {
      return {
        ...state,
        loginResponse: null,
        data: null,
      };
    },
  },
  defaultState,
);
