import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loading from '@/shared/components/Loading';
import { paths } from '@/shared/helpers/paths';
import { DSCohortPage } from './components/DSCohortPage';

export const cadsAiDSCohortBaseUrl = paths.CADS_AI.DATA_STAR.DS_COHORT;

const DSCohort = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path={cadsAiDSCohortBaseUrl} component={DSCohortPage} />
      </Switch>
    </Suspense>
  );
};

export default DSCohort;
