import { useEffect } from 'react';
import Highcharts from 'highcharts';
import Tree from 'highcharts/modules/treemap';
import exporting from 'highcharts/modules/exporting';
import data from 'highcharts/modules/data';
import Heatmap from 'highcharts/modules/heatmap';

Tree(Highcharts);
data(Highcharts);
Heatmap(Highcharts);
exporting(Highcharts);

const UserOnboardCard = () => {
  const initialChart = () => {
    Highcharts.chart('UserOnboardCard', {
      xAxis: {
        visible: false,
      },

      yAxis: {
        title: {
          text: null,
        },
      },
      title: {
        text: '',
      },

      credits: {
        enabled: false,
      },

      colors: ['#e74f68'],

      legend: {
        align: 'center',
        verticalAlign: 'bottom',
        x: 0,
        y: 0,
      },

      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
          pointStart: 2010,
        },
      },

      series: [
        {
          name: 'Users',
          data: [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175],
        },
      ],

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
              },
            },
          },
        ],
      },
    });
  };

  useEffect(() => {
    initialChart();
  }, []);

  return <div id="UserOnboardCard" className="SkillsTrend" />;
};

export default UserOnboardCard;
