import React, { useEffect, useState } from 'react';
import LoadingIcon from 'mdi-react/LoadingIcon';
import api from '@/utils/apiClient';
import TextEditor from '@/shared/components/text-editor/TextEditor';
import { Button, ButtonToolbar } from 'reactstrap';
import { getSanitizeHTML } from '@/shared/components/SanitizeHTML';
import { convertImageFileToBase64, emptyStringPattern, YesNoOptions } from '@/shared/helpers';
import FormField from '@/shared/components/form/hook/FormField';
import { useForm } from 'react-hook-form';
import { RadioButtonField } from '@/shared/components/form/RadioButton';
import renderDropZoneField from '@/shared/components/form/DropZone';

const TrainingProviderEditDialogBody = ({ modal, isEdit, id }) => {
  const initialValues = {
    description: '',
    isGeneral: 'true',
    hasSso: 'true',
  };

  const [data, setData] = useState(initialValues);

  const [state, setState] = useState({
    loading: false,
    fetchingTrainingProvider: false,
    newTrainingProviderAdded: false,
    errorMessage: '',
  });

  useEffect(() => {
    if (isEdit && id) {
      setDefaultData(+id);
    }
  }, [isEdit, id]);

  const setDefaultData = async (id) => {
    try {
      const { data: res, isSuccess } = await api.cadsAITrainingProvider.getTrainingProviderById(id);
      const trainingProviderData = res.data;
      if (id) {
        setData({
          ...trainingProviderData,
        });
        ['id', 'title', 'websiteAddress', 'description'].forEach((field) =>
          setValue(field, trainingProviderData?.[field]),
        );
        setValue('isGeneral', trainingProviderData?.isGeneral ? 'true' : 'false');
        setValue('hasSso', trainingProviderData?.hasSso ? 'true' : 'false');
        setValue(
          'ssoSecrets',
          JSON.parse(trainingProviderData?.ssoSecrets ?? [])?.[0]?.SsoSecrets ?? '',
        ); //TODO: change once api returns ssoSecret as string
      }
    } catch ({ message }) {
      console.error('Training Provider edit error : ', message);
      setState({
        ...state,
        errorMessage: 'Error while getting existing training provider details',
      });
    }
  };

  const constructApiRequestData = async ({
    description,
    title,
    imageUrl,
    websiteAddress,
    ssoSecrets,
    bgUrl,
  }) => {
    const base64Img = await convertImageFileToBase64(imageUrl?.[0]);
    const base64BgUrl = await convertImageFileToBase64(bgUrl?.[0]);
    return {
      id,
      title,
      description,
      logoUrl: base64Img?.split(',')?.[1],
      bgImageUrl: base64BgUrl?.split(',')?.[1],
      websiteAddress,
      isGeneral: isGeneral === 'true',
      hasSso: hasSso === 'true',
      ssoSecrets: [{ ssoSecrets }], //TODO: update API to have it as a string
    };
  };
  const handleAddEditTP = async (trainingProviderData) => {
    setState({ ...state, loading: true });
    const newTrainingProvider = await constructApiRequestData(trainingProviderData);
    const defaultError = `Error occurred while ${
      isEdit ? 'Updating' : 'Adding new'
    } training provider`;
    try {
      const { data: res, isSuccess } = isEdit
        ? await api.cadsAITrainingProvider.updateTrainingProvider(newTrainingProvider)
        : await api.cadsAITrainingProvider.addTrainingProvider(newTrainingProvider);
      if (isSuccess) {
        setState({ ...state, newTrainingProviderAdded: true, loading: false });
        if (!isEdit) setData(initialValues);
      } else {
        throw new Error(data?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({
        ...state,
        loading: false,
        newTrainingProviderAdded: false,
        errorMessage: error.message,
      });
    }
  };

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ defaultValues: initialValues });
  const isGeneral = watch('isGeneral');
  const setIsGeneral = (value) => setValue('isGeneral', value);

  const hasSso = watch('hasSso');
  const setHasSso = (value) => setValue('hasSso', value);

  return (
    <div>
      {/*Add/Edit Training Form Status*/}
      <div className="ml-4">
        {state.loading && (
          <div>
            <div className="panel__refresh position-relative" style={{ height: 75, width: 20 }}>
              <LoadingIcon />
              <div style={{ width: 200, padding: '23px 30px' }}>
                {isEdit ? 'Updating' : 'Adding new'} training provider...
              </div>
            </div>
          </div>
        )}
        {(state.newTrainingProviderAdded || !!state.errorMessage) && (
          <>
            <table className="mt-3">
              <tr>
                <td>
                  {state.newTrainingProviderAdded ? (
                    <span className="lnr lnr-thumbs-up modal__title-icon success" />
                  ) : (
                    <span className="lnr lnr-cross-circle modal__title-icon error" />
                  )}
                </td>
                <td className="pl-2">
                  {state.newTrainingProviderAdded
                    ? `${
                        isEdit ? 'Training Provider Updated' : 'Training Provider Added'
                      } Successfully`
                    : state.errorMessage}
                </td>
              </tr>
            </table>
          </>
        )}
      </div>
      <div className="modal__body add-edit-learning-path dropzone-border">
        <form className={`form form--horizontal`} onSubmit={handleSubmit(handleAddEditTP)}>
          {!state.newTrainingProviderAdded ? (
            <div className={'w-100 p-3'}>
              <div className="form__form-group">
                <span className="form__form-group-label">Title</span>
                <div className="form__form-group-field">
                  <FormField
                    name="title"
                    control={control}
                    component="input"
                    errors={errors}
                    rules={{
                      required: 'This is required field',
                      pattern: emptyStringPattern,
                    }}
                    defaultValue=""
                    isAboveError={true}
                    placeholder="Enter Title"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Description</span>
                <div className="form__form-group-field">
                  {/*TODO: set html after sanitizing for all text editor*/}
                  <FormField
                    control={control}
                    name="description"
                    component={() => (
                      <TextEditor
                        defaultContent={getSanitizeHTML(data?.description)}
                        onChange={(value) => setValue('description', value)}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="form__form-group pt-2">
                <span className="form__form-group-label">Logo</span>
                <div className="form__form-group-field">
                  <FormField name="imageUrl" control={control} component={renderDropZoneField} />
                </div>
              </div>
              <div className="form__form-group pt-2">
                <span className="form__form-group-label">Background Image</span>
                <div className="form__form-group-field">
                  <FormField name="bgUrl" control={control} component={renderDropZoneField} />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Website Address</span>
                <div className="form__form-group-field">
                  <FormField
                    name="websiteAddress"
                    control={control}
                    component="input"
                    errors={errors}
                    defaultValue=""
                    isAboveError={true}
                    placeholder="Enter Website Address"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Sso Secrets</span>
                <div className="form__form-group-field">
                  <FormField
                    name="ssoSecrets"
                    control={control}
                    component="input"
                    errors={errors}
                    defaultValue=""
                    isAboveError={true}
                    placeholder="Enter SSO Secrets"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Is General</span>
                <div className="form__form-group-field mt-2">
                  <RadioButtonField
                    radioValue={`${YesNoOptions[0].value}`}
                    name={'IsGeneral'}
                    onChange={setIsGeneral}
                    value={isGeneral}
                    className="colored-click mr-5"
                    label={YesNoOptions[0].label}
                  />
                  <RadioButtonField
                    radioValue={`${YesNoOptions[1].value}`}
                    name={'IsGeneral'}
                    onChange={setIsGeneral}
                    value={isGeneral}
                    className="colored-click mr-5"
                    label={YesNoOptions[1].label}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Has Sso</span>
                <div className="form__form-group-field mt-2">
                  <RadioButtonField
                    radioValue={`${YesNoOptions[0].value}`}
                    name={YesNoOptions[0].label}
                    onChange={setHasSso}
                    value={hasSso}
                    className="colored-click mr-5"
                    label={YesNoOptions[0].label}
                  />
                  <RadioButtonField
                    radioValue={`${YesNoOptions[1].value}`}
                    name={YesNoOptions[1].label}
                    onChange={setHasSso}
                    value={hasSso}
                    className="colored-click mr-5"
                    label={YesNoOptions[1].label}
                  />
                </div>
              </div>
              <ButtonToolbar className="modal__footer btn-toolbar">
                <>
                  <Button color="primary" type="submit">
                    Submit
                  </Button>
                  <Button
                    color="secondary"
                    type="button"
                    className="modal_cancel btn btn-secondary"
                    onClick={() => modal.hide()}
                  >
                    Close
                  </Button>
                </>
              </ButtonToolbar>
            </div>
          ) : (
            <div className={'p-3'}>
              <ButtonToolbar className="modal__footer btn-toolbar">
                <Button color="primary" type="button" onClick={() => modal.hide()}>
                  Okay
                </Button>
              </ButtonToolbar>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default TrainingProviderEditDialogBody;
