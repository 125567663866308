import React, { Fragment, useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import { Badge, Button } from 'reactstrap';
import { useProgramsUIContext } from '../ProgramsUIProvider';
import ProgramsTableColumns, { ProgramsColumns } from './ProgramsTableColumns';
import { ProgramsTableSearchBar } from './ProgramsTableSearchBar';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getProgramsList, setProgramFilters, toggleCheckedItemById } from '../../redux/actions';
import EyeIcon from 'mdi-react/EyeIcon';
import PencilIcon from 'mdi-react/PencilIcon';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { defaultPaginationProps } from '@/shared/helpers';
import { FormGroup, Input } from 'reactstrap';
import moment from 'moment';

const ProgramsTable = () => {
  const programsUIContext = useProgramsUIContext();
  const dispatch = useDispatch();
  const {
    data: programs,
    error,
    filters,
    loading,
    totalRecords,
    checkedProgramIds,
  } = useSelector((state) => state.cadsAIPrograms, shallowEqual);
  const {
    pageSize: rowsPerPage,
    pageNumber: page,
    sortBy: orderBy,
    isSortOrderDescending,
  } = filters;

  const [columns, setColumns] = useState(0);

  useEffect(() => {
    setColumns(ProgramsColumns.length);
  }, [ProgramsColumns.length]);

  React.useEffect(() => {
    dispatch(getProgramsList(filters));
  }, [filters]);

  const handleRequestSort = (event, property) => {
    dispatch(
      setProgramFilters({
        sortBy: property,
        isSortOrderDescending: !isSortOrderDescending,
      }),
    );
  };

  const renderActionButtons = (programId) => (
    <div className="actions">
      <Button
        className="icon mb-0"
        color="success"
        outline
        onClick={() => programsUIContext.openViewProgramsDialog(programId)}
      >
        <p>
          <EyeIcon /> View
        </p>
      </Button>
      <Button
        className="icon mb-0"
        color="primary"
        outline
        onClick={() => programsUIContext.openEditProgramsDialog(programId)}
      >
        <p>
          <PencilIcon /> Edit
        </p>
      </Button>
    </div>
  );

  const handleChangePage = (event, currentPage) => {
    dispatch(setProgramFilters({ pageSize: rowsPerPage, pageNumber: currentPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setProgramFilters({ pageSize: +event.target.value, pageNumber: 1 }));
  };

  const emptyRows =
    page === Math.ceil(totalRecords / rowsPerPage) ? rowsPerPage - programs.length : 0;

  return (
    <Fragment>
      <div className="material-table__wrap">
        <ProgramsTableSearchBar />
        <Table className="material-table">
          <ProgramsTableColumns
            order={isSortOrderDescending ? 'desc' : 'asc'}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rowsPerPage}
          />
          <TableBody>
            {(loading || !!error) && (
              <TableRow>
                <TableCell colSpan={columns}>
                  {loading && (
                    <div className={'text-center overflow-hidden'}>
                      <div className="panel__refresh position-relative" style={{ height: 75 }}>
                        <LoadingIcon />
                      </div>
                    </div>
                  )}
                  <div className={'text-center'}>
                    <p className={!!error ? 'm-5' : ''}>{error || 'Loading programs...'}</p>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {!programs?.length && !loading && !error && (
              <TableRow style={{ height: 120 }}>
                <TableCell colSpan={columns} align="center">
                  {filters.status === null && filters.search === ''
                    ? 'No Programs'
                    : 'No Programs with applied filters'}
                </TableCell>
              </TableRow>
            )}
            {programs?.map((program) => {
              return (
                <TableRow className="material-table" tabIndex={-1} key={program?.id}>
                  <TableCell align="center" className="material-table__cell">
                    <FormGroup>
                      <Input
                        type="checkbox"
                        value={program?.id}
                        defaultChecked={checkedProgramIds.includes(program?.id)}
                        onChange={(e) => dispatch(toggleCheckedItemById(+e.target.value))}
                      />
                    </FormGroup>
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    <img
                      className="program_logo_img"
                      src={
                        program?.logoImgUrl?.startsWith('http')
                          ? program?.logoImgUrl
                          : 'https://general-assets.sgp1.digitaloceanspaces.com/datastar-general/datastar-logo-image.png'
                      }
                      alt="program logo"
                    />
                  </TableCell>
                  <TableCell className="material-table__cell" align="center">
                    {program?.title}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {program?.startDate ? moment(program?.startDate).format('DD/MM/yyyy') : '-'}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {program?.endDate ? moment(program?.endDate).format('DD/MM/yyyy') : '-'}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {program?.numberOfActiveApplicants}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    <Badge
                      color={`${
                        program?.programStatus?.title === 'Publish' ? 'primary' : 'secondary'
                      }`}
                      style={{ marginRight: 24 }}
                    >
                      {program?.programStatus?.title ?? '-'}
                    </Badge>
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    <Badge
                      color={`${program?.status ? 'success' : 'danger'}`}
                      style={{ marginRight: 24 }}
                    >
                      {program?.status ? 'Active' : 'Deactivated'}
                    </Badge>
                  </TableCell>
                  <TableCell
                    align="center"
                    className="material-table__cell"
                    style={{ minWidth: 250 }}
                  >
                    {renderActionButtons(program?.id)}
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={columns} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        component={defaultPaginationProps.component}
        backIconButtonProps={defaultPaginationProps.backIconButton}
        nextIconButtonProps={defaultPaginationProps.nextIconButton}
        rowsPerPageOptions={defaultPaginationProps.rowsPerPage}
        className={defaultPaginationProps.className}
        dir={defaultPaginationProps.dir}
      />
    </Fragment>
  );
};

export default ProgramsTable;
