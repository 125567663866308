import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const fetchSponsorList = createAction('CADS_AI_FETCH_SPONSORS_LIST');
export const setSponsorList = createAction('CADS_AI_SET_SPONSORS_LIST');
export const setSponsorOptions = createAction('CADS_AI_SET_SPONSORS_OPTIONS');
export const setSponsorCount = createAction('CADS_AI_SET_SPONSORS_COUNT');
export const setSponsorError = createAction('CADS_AI_SET_SPONSORS_ERROR');
export const setSponsorFilters = createAction('CADS_AI_SET_SPONSORS_FILTERS');

export const getSponsorsList = (filter) => async (dispatch) => {
  const defaultError = `Error occurred getting sponsors list`;
  dispatch(fetchSponsorList());
  try {
    const { data, isSuccess } = await api.cadsAISponsors.getSponsorsList(filter);
    if (isSuccess) dispatch(setSponsorList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setSponsorError(error.message));
    console.error(defaultError, error.message);
  }
};

export const getAllSponsorOptions = () => async (dispatch) => {
  const defaultError = `Error occurred getting sponosor options list`;
  try {
    const { data, isSuccess } = await api.cadsAIPrograms.getSponsorOptions();
    if (isSuccess) dispatch(setSponsorOptions(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    console.error(defaultError, error.message);
  }
};
