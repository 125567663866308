import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loading from '@/shared/components/Loading';
import { paths } from '@/shared/helpers/paths';
import { SponsorsPage } from './components/SponsorsPage';
import './index.scss';

export const cadsAiSponsorsBaseUrl = paths.CADS_AI.DATA_STAR.SPONSORS;

const Sponsors = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path={cadsAiSponsorsBaseUrl} component={SponsorsPage} />
      </Switch>
    </Suspense>
  );
};

export default Sponsors;
