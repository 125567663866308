import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { StepDefaultProps, StepPropTypes } from './Props';
import FormField from '@/shared/components/form/hook/FormField';
import { SelectField } from '@/shared/components/form/Select';
import { emailPattern } from '@/shared/helpers';
import api from '@/utils/apiClient';
import { extractFieldData, getStepFields } from '../index';

const StepOne = ({ onSubmit, page, data }) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm(extractFieldData(page, data));

  React.useEffect(() => {
    getStepFields(page).forEach((key) => setValue(key, data[key]));
  }, [data]);

  React.useEffect(() => {
    getCountryList();
  }, []);

  const [countries, setCountries] = React.useState([]);
  const getCountryList = async () => {
    const { data: countries, isSuccess } = await api.country.getCountryList();
    if (isSuccess) {
      setCountries(countries.data.map((country) => ({ value: country.id, label: country.name })));
    }
  };

  return (
    <form className="form wizard__form step-one" onSubmit={handleSubmit(onSubmit)}>
      <div className="form__form-group">
        <span className="form__form-group-label">Name</span>
        <div className="form__form-group-field">
          <FormField
            name="companyName"
            as="input"
            placeholder="Enter name"
            control={control}
            defaultValue=""
            errors={errors}
            rules={{ required: 'This is required field' }}
            isAboveError
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Registration Number</span>
        <div className="form__form-group-field">
          <FormField
            name="regNo"
            as="input"
            placeholder="Enter registration number"
            control={control}
            defaultValue=""
            errors={errors}
            rules={{ required: 'This is required field' }}
            isAboveError
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Address</span>
        <div className="form__form-group-field">
          <FormField
            name="address"
            component="textarea"
            placeholder="Enter Address"
            control={control}
            defaultValue=""
            errors={errors}
            rules={{ required: 'This is required field' }}
            isAboveError
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Country</span>
        <div className="form__form-group-field">
          <FormField
            control={control}
            name="country"
            component={SelectField}
            options={countries}
            placeholder="Select country"
            rules={{ required: 'This is required field' }}
            errors={errors}
            isAboveError
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Number of Employees</span>
        <div className="form__form-group-field">
          <FormField
            name="noOfEmp"
            as="input"
            type="number"
            placeholder="Enter number of employees"
            control={control}
            defaultValue=""
            errors={errors}
            rules={{
              required: 'This is required field',
              min: { value: 0, message: 'Can not have negative value' },
            }}
            isAboveError
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">E-mail</span>
        <div className="form__form-group-field">
          <FormField
            name="email"
            as="input"
            type="email"
            placeholder="Enter email"
            control={control}
            errors={errors}
            isAboveError
            defaultValue=""
            rules={{
              required: 'This is required field',
              pattern: {
                value: emailPattern,
                message: 'Entered value does not match email format',
              },
            }}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label d-block">HostName</span>
        <div className="form__form-group-field d-inline-block w-50">
          <FormField
            name="subdomain"
            as="input"
            type="text"
            placeholder="Subdomain"
            control={control}
            defaultValue=""
            errors={errors}
            rules={{
              required: 'This is required field',
              pattern: {
                value: /^[a-zA-Z0-9][a-zA-Z0-9.-]+[a-zA-Z0-9]$/,
                message: 'Please enter valid subdomain',
              },
            }}
            isAboveError
          />
        </div>
        <p className="d-inline-block">.talentspy.staging.cads.ai</p>
      </div>
      <ButtonToolbar className="form__button-toolbar wizard__toolbar">
        <Button color="primary" type="button" disabled className="previous">
          Back
        </Button>
        <Button color="primary" type="submit" className="next">
          Next
        </Button>
      </ButtonToolbar>
    </form>
  );
};

StepOne.propTypes = StepPropTypes;
StepOne.defaultProps = StepDefaultProps;

export default StepOne;
