import React, { Suspense, useEffect, useState, useRef } from 'react';
import { Container, Col, Row } from 'reactstrap';
import Loading from '@/shared/components/Loading';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import api from '@/utils/apiClient';
import Panel from '@/shared/components/Panel';
import PanelCard from './PanelCard';
import DataStarChart from './DataStarChart';
import './index.scss';
import { useReducer } from 'react';

const Dashboard = () => {
  const userSVG = (
    <svg height="80" width="80" class="mdi-icon " fill="currentColor" viewBox="0 0 24 24">
      <path d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z"></path>
    </svg>
  );

  const sendSVG = (
    <svg class="mdi-icon " width="80" height="80" fill="currentColor" viewBox="0 0 24 24">
      <path d="M2,21L23,12L2,3V10L17,12L2,14V21Z"></path>
    </svg>
  );

  const searchedProgramPlaceholder = { value: null, label: 'Select Program' };
  const searchedEvaluationPlaceholder = { value: null, label: 'All' };
  const searchedAssessmentPlaceholder = { value: null, label: 'All' };
  const searchedStatusPlaceholder = { value: true, label: 'Active' };
  const [applicantCount, setApplicantCount] = useState(0);
  const [dataStarAnalytics, setDataStarAnalytics] = useState(null);
  const [programCount, setProgramCount] = useState(0);
  const [completedSurveyCount, setCompletedSurveyCount] = useState({
    total: 0,
    preAssessment: 0,
    smartSkill: 0,
  });
  const [evaluationCount, setEvaluationCount] = useState(0);
  const [programs, setPrograms] = useState(searchedProgramPlaceholder);
  const [evaluationOpts, setEvaluationOpts] = useState([]);
  const [searchProgram, setSearchProgram] = useState(searchedProgramPlaceholder);
  const [searchAssessment, setSearchAssessment] = useState(searchedAssessmentPlaceholder);
  const [searchEvaluation, setSearchEvaluation] = useState(searchedEvaluationPlaceholder);
  const [status, setStatus] = useState(searchedStatusPlaceholder);

  const initialValues = {
    checked: false,
    title: 'NO. OF APPLICANTS',
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'PROGRAMS':
        return {
          ...state,
          title: 'Programs',
          checked: true,
          evaluationDropdownVisible: false,
          assessmentDropdownVisible: false,
          selectedProgram: true,
          selectedTalent: false,
          selectedAssessment: false,
          selectedEvaluation: false,
        };
      case 'TALENT_REGISTERED':
        return {
          ...state,
          title: 'Talent Registered',
          checked: true,
          evaluationDropdownVisible: false,
          assessmentDropdownVisible: false,
          selectedProgram: false,
          selectedTalent: true,
          selectedAssessment: false,
          selectedEvaluation: false,
        };
      case 'ASSESSMENTS':
        return {
          ...state,
          title: 'Assessments',
          checked: true,
          evaluationDropdownVisible: false,
          assessmentDropdownVisible: true,
          selectedProgram: false,
          selectedTalent: false,
          selectedAssessment: true,
          selectedEvaluation: false,
        };
      case 'EVALUATION':
        return {
          ...state,
          title: 'Evaluation',
          checked: true,
          evaluationDropdownVisible: true,
          assessmentDropdownVisible: false,
          selectedProgram: false,
          selectedTalent: false,
          selectedAssessment: false,
          selectedEvaluation: true,
        };
      default:
        return state;
    }
  };

  const [values, dispatch] = useReducer(reducer, initialValues);

  useEffect(() => {
    getAllPrograms();
    getAllEvaluationOptions();
  }, []);

  const handleProgram = async () => {
    try {
      const { data: res, isSuccess } = await api.cadsAIDSDashboard.getAllProgramDailyCountGraph();
      if (isSuccess) {
        res.data.forEach(function (item) {
          item.date = item.date.slice(0, 10);
        });
        setDataStarAnalytics(res?.data);
        dispatch({ type: 'PROGRAMS' });
      } else {
        throw new Error('error while fetching datastar graph data');
      }
    } catch (error) {
      console.error('Error inside dashboard datastar graph', error.message);
    }
  };
  const handleTalentRegistered = async () => {
    try {
      const { data: res, isSuccess } = await api.cadsAIDSDashboard.getAllTalentDailyCountGraph();
      if (isSuccess) {
        res.data.forEach(function (item) {
          item.date = item.date.slice(0, 10);
        });
        setDataStarAnalytics(res?.data);
        dispatch({ type: 'TALENT_REGISTERED' });
      } else {
        throw new Error('error while fetching datastar graph data');
      }
    } catch (error) {
      console.error('Error inside dashboard datastar graph', error.message);
    }
  };

  const handleAssessments = async () => {
    try {
      const { data: res, isSuccess } =
        await api.cadsAIDSDashboard.getAllAssessmentDailyCountGraph();
      if (isSuccess) {
        res.data.forEach(function (item) {
          item.date = item.date.slice(0, 10);
        });
        setDataStarAnalytics(res?.data);
        dispatch({ type: 'ASSESSMENTS' });
      } else {
        throw new Error('error while fetching datastar graph data');
      }
    } catch (error) {
      console.error('Error inside dashboard datastar graph', error.message);
    }
  };

  const handleEvaluation = async () => {
    try {
      const { data: res, isSuccess } =
        await api.cadsAIDSDashboard.getAllEvaluationDailyCountGraph();
      if (isSuccess) {
        res.data.forEach(function (item) {
          item.date = item.date.slice(0, 10);
        });
        setDataStarAnalytics(res?.data);
        dispatch({ type: 'EVALUATION' });
      } else {
        throw new Error('error while fetching datastar graph data');
      }
    } catch (error) {
      console.error('Error inside dashboard datastar graph', error.message);
    }
  };

  const getAllPrograms = async () => {
    try {
      const { data: res, isSuccess } = await api.cadsAIPrograms.getProgramOptionsList();
      if (isSuccess) {
        setPrograms([{ value: null, label: 'Select Program' }]);
        res.data?.map((item) => {
          setPrograms((programs) => [...programs, { value: item.id, label: item.title }]);
        });
      } else {
        throw new Error('error while fetching all programs');
      }
    } catch (error) {
      console.error('Error inside dashboard all programs', error.message);
    }
  };

  const getAllEvaluationOptions = async () => {
    try {
      const { data: res, isSuccess } = await api.cadsAIDSDashboard.getEvaluationOptions();
      if (isSuccess) {
        setEvaluationOpts([{ value: null, label: 'Select Evaluation' }]);
        res.data?.map((item) => {
          setEvaluationOpts((evaluation) => [...evaluation, { value: item.id, label: item.title }]);
        });
      } else {
        throw new Error('error while fetching all evaluation');
      }
    } catch (error) {
      console.error('Error inside dashboard all evaluation', error.message);
    }
  };

  const getRegisterTalents = async (filters) => {
    try {
      const { data: res, isSuccess } = await api.cadsAIDSDashboard.getApplicantCount(filters);
      if (isSuccess) {
        setApplicantCount(res?.data?.count);
      } else {
        throw new Error('error while fetching registered talent');
      }
    } catch (error) {
      console.error('Error inside dashboard registered talent', error.message);
    }
  };

  const getProgramCount = async (filters) => {
    try {
      const { data: res, isSuccess } = await api.cadsAIDSDashboard.getProgramCount(filters);
      if (isSuccess) {
        setProgramCount(res?.data?.count);
      } else {
        throw new Error('error while fetching program count');
      }
    } catch (error) {
      console.error('Error inside dashboard program count', error.message);
    }
  };

  const getEvaluationCount = async (filters) => {
    try {
      const { data: res, isSuccess } = await api.cadsAIDSDashboard.getProgramApplicantStageCount(
        filters,
        3,
      );
      if (isSuccess) {
        setEvaluationCount(res?.data ?? res?.data?.count ?? 0);
      } else {
        throw new Error('error while fetching evaluation count');
      }
    } catch (error) {
      console.error('Error inside dashboard evaluation count', error.message);
    }
  };

  const getCompletedSurvey = async (filters) => {
    try {
      const { data: res, isSuccess } = await api.cadsAIDSDashboard.getTotalAssessmentCount(filters);
      if (isSuccess) {
        setCompletedSurveyCount({
          total: res?.data?.totalCount,
          preAssessment: res?.data?.datastarPreAssessmentCount,
          smartSkill: res?.data?.smartSkillCount,
        });
      } else {
        throw new Error('error while fetching complete survey');
      }
    } catch (error) {
      console.error('Error inside dashboard complete survey', error.message);
    }
  };

  useEffect(() => {
    getRegisterTalents({ status: status.value });
    getProgramCount({ status: status.value });
    getEvaluationCount({ status: status.value });
    getCompletedSurvey({});
  }, []);

  const handleChange = (e, type) => {
    if (type == 'program_type') {
      setSearchProgramType(e);
      getRegisterTalents({
        programTypeId: e?.value,
        programId: searchProgram?.value,
        status: status?.value,
      });
      getProgramCount({ programTypeId: e?.value, status: status?.value });
    }
    if (type == 'status') {
      setStatus(e);
      getRegisterTalents({ programTypeId: 1, programId: searchProgram?.value, status: e?.value });
      getProgramCount({ programTypeId: 1, status: e?.value });
      getEvaluationCount({ programTypeId: 1, programId: searchProgram?.value, status: e?.value });
    }

    if (type == 'program') {
      setSearchProgram(e);
      getRegisterTalents({ programTypeId: 1, programId: e?.value, status: status?.value });
      getEvaluationCount({ programTypeId: 1, programId: e?.value, status: status?.value });
      getCompletedSurvey({ programTypeId: 1, programId: e?.value });
    }
    if (type == 'evaluation') {
      setSearchEvaluation(e);
    }
    if (type == 'assessment') {
      setSearchAssessment(e);
    }
  };

  return (
    <Container className="dashboard">
      <Row>
        <Col md={6}>
          <h3 className="page-title">Data Star Dashboard</h3>
        </Col>
      </Row>

      <Suspense fallback={<Loading />}>
        <Row>
          <Panel xl={4} lg={4} md={4} title="Metrics">
            <PanelCard
              selectedPanel={values?.selectedProgram}
              count={programCount}
              svg={sendSVG}
              text="Programs"
              colorClass="card-green"
              onClick={handleProgram}
            />
            <PanelCard
              selectedPanel={values?.selectedTalent}
              count={applicantCount}
              svg={userSVG}
              text="Talents Registered"
              colorClass="card-orange"
              onClick={handleTalentRegistered}
            />
            <PanelCard
              count={completedSurveyCount.total ?? 0}
              isCompletedSurveyCount={true}
              selectedPanel={values?.selectedAssessment}
              extras={[
                completedSurveyCount.smartSkill ?? 0,
                completedSurveyCount.preAssessment ?? 0,
              ]}
              svg={sendSVG}
              text="Assessments Completed"
              colorClass="card-green"
              onClick={handleAssessments}
            />
            <PanelCard
              selectedPanel={values?.selectedEvaluation}
              count={evaluationCount}
              svg={userSVG}
              text="Evaluation"
              colorClass="card-orange"
              onClick={handleEvaluation}
            />
          </Panel>

          <Panel
            xl={8}
            lg={8}
            md={8}
            title={`${values.checked ? values.title : 'No. of applicants'}`}
          >
            <DataStarChart
              programTypeId={1}
              searchEvaluation={searchEvaluation}
              searchProgram={searchProgram}
              status={status}
              programs={programs}
              evaluationOpts={evaluationOpts}
              handleChange={handleChange}
              setSearchEvaluation={setSearchEvaluation}
              setSearchProgram={setSearchProgram}
              setStatus={setStatus}
              setDataStarAnalytics={setDataStarAnalytics}
              dataStarAnalytics={dataStarAnalytics}
              values={values}
              searchAssessment={searchAssessment}
            />
          </Panel>
        </Row>
      </Suspense>
    </Container>
  );
};

Dashboard.propTypes = {
  dir: PropTypes.string.isRequired,
  themeName: PropTypes.string.isRequired,
};

export default connect((state) => ({ themeName: state.theme.className }))(Dashboard);
