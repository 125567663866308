import React from 'react';
import { Button, ButtonToolbar, CardBody, FormGroup, Input, Modal } from 'reactstrap';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import LoadingIcon from 'mdi-react/LoadingIcon';
import TablePagination from '@material-ui/core/TablePagination';
import { defaultPaginationProps } from '@/shared/helpers';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import api from '@/utils/apiClient';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getCPOrganizationsList,
  setOrganizationOptionsList,
} from '@/containers/Control-Panel/redux/organization/actions';
import * as Constants from '@/shared/helpers/constants';
import { getCPUsersList } from '@/containers/Control-Panel/redux/user/actions';
import { uncheckAllItem } from '@/containers/Control-Panel/Assign/redux/actions';
import { ToggleButtonField } from '@/shared/components/form/ToggleButton';

const EmployeeColumns = [
  {
    id: 'checkbox',
    disablePadding: true,
    label: '',
  },
  {
    id: 'name',
    disablePadding: true,
    label: 'Employee Name',
  },
  {
    id: 'email',
    disablePadding: true,
    label: 'Employee Email',
  },
];

const AssignDialog = ({ show, onHide }) => {
  const { rtl } = useSelector((state) => state, shallowEqual);

  const initialState = {
    isOpen: show,
    title: 'Assignment Confirmation',
    message: 'Are you sure want to proceed forward with assignment?',
    hide: onHide,
    loading: false,
    assignmentSuccess: false,
    assignmentError: false,
  };
  const [modal, setModal] = React.useState({ ...initialState });

  const handleOnClose = (clearAll = false) => {
    const filter = { assigned: false };
    if (clearAll) {
      //TODO: uncheck all
      setModal({ ...initialState });
    }
    onHide();
  };

  const performAssignment = async () => {
    setModal({
      ...modal,
      loading: true,
      title: 'Performing Assignment',
      message: 'Assignment in progress...',
    });
    /*Make api call for performing assignment*/
    /*let updatedModal;
    try {
      const response = await api.assigment.setAssignmentEmployees();
      if(response.isSuccess) {
        updatedModal = { loading: false, assignmentSuccess: true, title: 'Done', message: 'Assignment completed successfully!'};
      } else {
        throw new Error("Error while performing assignment");
      }
    } catch (e) {
      updatedModal = { loading: false, assignmentError: true, title: 'Opps', message: 'Error performing assignments!'};
    } finally {
      setModal({...modal, ...updatedModal});
    }*/
    const updatedModal = {
      loading: false,
      assignmentSuccess: true,
      title: 'Done',
      message: 'Assignment completed successfully!',
    };
    setModal({ ...modal, ...updatedModal });
  };

  return (
    <Modal
      isOpen={show}
      toggle={modal.hide}
      keyboard={false}
      backdrop={'static'}
      modalClassName={`${rtl.direction}-support`}
      onExit={() => handleOnClose(true)}
      className="modal-dialog--primary assignment-modal"
    >
      <div className="modal__header">
        <button
          className="lnr lnr-cross modal__close-btn"
          aria-label="modal__close-btn"
          type="button"
          onClick={() => handleOnClose(true)}
        />
        {!(modal.loading || modal.assignmentSuccess || modal.assignmentError) && (
          <span className="lnr lnr-pushpin modal__title-icon" />
        )}
        {modal.assignmentSuccess && (
          <span className="lnr lnr-thumbs-up modal__title-icon success" />
        )}
        {modal.assignmentError && <span className="lnr lnr-cross-circle modal__title-icon error" />}
        <h4 className="text-modal  modal__title">{modal.title}</h4>
        <div className="modal__body">
          {modal.loading && (
            <div className="panel__refresh position-relative" style={{ height: 75 }}>
              <LoadingIcon />
            </div>
          )}
          <p className="confirmation-text">{modal.message}</p>
        </div>
        <ButtonToolbar className="modal__footer">
          {modal.assignmentError && (
            <Button className="modal_cancel" onClick={handleOnClose} outline>
              Close
            </Button>
          )}
          {modal.assignmentSuccess && (
            <>
              <Button className="modal_cancel" onClick={() => handleOnClose(true)} outline>
                Okay
              </Button>
            </>
          )}
          {!(modal.assignmentSuccess || modal.assignmentError || modal.loading) && (
            <>
              <Button className="modal_cancel" onClick={handleOnClose}>
                Cancel
              </Button>
              <Button className="modal_ok" outline color="success" onClick={performAssignment}>
                Yes, Assign
              </Button>
            </>
          )}
        </ButtonToolbar>
      </div>
    </Modal>
  );
};

const EmployeeTableSearchBar = React.memo(({ totalEmployee, filters, setFilters }) => {
  const [isSearched, setIsSearched] = React.useState(false);
  const [search, setSearch] = React.useState('');

  const handleSearch = () => {
    setIsSearched(true);
    setFilters({ ...filters, search, pageNumber: 1 });
  };

  const handleClear = () => {
    setSearch('');
    setIsSearched(false);
    setFilters({ ...filters, search: '' });
  };

  return (
    <TableHead>
      <div className="table__search">
        <div className="table__search_item">
          <b>Employee Name</b>
          <div className="form">
            <div className="form__form-group">
              <div className="form__form-group-field">
                <div className="form__form-group-icon" style={{ height: 37 }}>
                  <MagnifyIcon />
                </div>
                <input
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="search-bar-search-text"
                  name="searchText"
                  type="text"
                  placeholder="Search by Employee Name"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table__search_item mt-3">
          <Button color="primary" type="button" onClick={handleSearch} outline>
            {' '}
            Search{' '}
          </Button>
        </div>
        <div className="table__search_item mt-3">
          <Button color="secondary" onClick={handleClear} outline>
            Clear
          </Button>
        </div>
      </div>

      {isSearched && (
        <div className="ml-1">
          <span>Found {totalEmployee} matches</span>
        </div>
      )}
    </TableHead>
  );
});

const EmployeeTableColumns = React.memo(({ order, orderBy, onRequestSort }) => {
  return (
    <TableRow>
      {EmployeeColumns.map((row) => (
        <TableCell
          className="material-table__cell material-table__cell--sort material-table__cell-center"
          key={row.id}
          align={'center'}
          padding={row.disablePadding ? 'none' : 'default'}
          sortDirection={orderBy === row.id ? order : false}
        >
          <TableSortLabel
            active={orderBy === row.id}
            direction={order}
            onClick={(event) => onRequestSort(event, row.id)}
            className="material-table__sort-label"
            dir="ltr"
          >
            <b>{row.label}</b>
          </TableSortLabel>
        </TableCell>
      ))}
    </TableRow>
  );
});

const EmployeeTableRows = React.memo(({ employees, checkedEmployee, setCheckedEmployee }) => {
  let checkedEmpIds = checkedEmployee.map((emp) => emp.id);
  const toggleSelectedEmployee = (employee) => {
    setCheckedEmployee(
      checkedEmpIds.includes(employee.id)
        ? checkedEmployee.filter((e) => e.id !== employee.id)
        : [...checkedEmployee, employee],
    );
  };

  return employees?.map((employee) => (
    <TableRow className="material-table" tabIndex={-1} key={employee.id}>
      <TableCell align="center" className="material-table__cell">
        <FormGroup>
          <Input
            className="table-checkbox"
            type="checkbox"
            value={employee.id}
            checked={checkedEmpIds.includes(employee.id)}
            onChange={() => toggleSelectedEmployee(employee)}
          />
        </FormGroup>
      </TableCell>
      <TableCell align="center" className="material-table__cell">
        {employee?.name}
      </TableCell>
      <TableCell align="center" className="material-table__cell">
        {employee?.email}
      </TableCell>
    </TableRow>
  ));
});

const StepThree = ({
  nextPage,
  previousPage,
  checkedCustomer,
  checkedAssessment,
  checkedEmployee,
  setCheckedEmployee,
}) => {
  const [state, setState] = React.useState({
    loading: false,
    message: '',
    isError: false,
    isSuccess: false,
    employees: [],
    totalRecords: 0,
  });
  const [filters, setFilters] = React.useState({
    pageSize: 10,
    pageNumber: 1,
    sortBy: '',
    isSortOrderDescending: false,
    search: '',
  });
  const [showModal, setShowModal] = React.useState(false);
  const {
    pageSize: rowsPerPage,
    pageNumber: page,
    sortBy: orderBy,
    isSortOrderDescending,
  } = filters;
  const { employees, totalRecords, loading, isSuccess, isError, message } = state;

  const getEmployeeListForAssignment = async (filters) => {
    const defaultError = 'Error occurred getting employees list';
    let updatedState = {};
    try {
      setState({ ...state, loading: true });
      const { data: res, isSuccess } = await api.assessments.getEmployeeList({
        ...filters,
        organizationId: checkedCustomer.organizationId,
        assessmentId: checkedAssessment.id,
      });
      if (isSuccess)
        updatedState = {
          ...state,
          isSuccess: true,
          isError: false,
          employees: res.data.records,
          totalRecords: res.data.totalRecords,
        };
      else throw new Error(res?.data?.messages?.[0] ?? defaultError);
    } catch (error) {
      updatedState = { ...state, isSuccess: false, isError: true, employees: [], totalRecords: 0 };
      console.error(defaultError, error.message);
    } finally {
      setState({ ...updatedState, loading: false });
    }
  };

  React.useEffect(() => {
    getEmployeeListForAssignment(filters);
  }, [filters]);

  const handleRequestSort = (event, property) => {
    setFilters({ ...filters, sortBy: property, isSortOrderDescending: !isSortOrderDescending });
  };

  const handleChangePage = (event, currentPage) => {
    setFilters({ ...filters, pageSize: rowsPerPage, pageNumber: currentPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilters({ ...filters, pageSize: +event.target.value, pageNumber: 1 });
  };

  const emptyRows =
    page === Math.ceil(totalRecords / rowsPerPage) ? rowsPerPage - employees.length : 0;

  return (
    <form className="form step-one" onSubmit={nextPage}>
      {/*Employee Table*/}
      <CardBody className="col mt-4">
        <div className="material-table__wrap">
          <EmployeeTableSearchBar
            totalEmployee={totalRecords}
            setFilters={setFilters}
            filters={filters}
          />
          <Table className="material-table">
            <TableBody>
              <EmployeeTableColumns
                order={isSortOrderDescending ? 'desc' : 'asc'}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              {(loading || isError) && (
                <TableRow>
                  <TableCell colSpan={EmployeeColumns.length}>
                    {loading && (
                      <div className={'text-center overflow-hidden'}>
                        <div className="panel__refresh position-relative" style={{ height: 75 }}>
                          <LoadingIcon />
                        </div>
                      </div>
                    )}
                    <div className={'text-center'}>
                      <p className={isError ? 'm-5' : ''}>
                        {isError ? message : 'Loading Employee...'}
                      </p>
                    </div>
                  </TableCell>
                </TableRow>
              )}
              <EmployeeTableRows
                employees={employees}
                checkedEmployee={checkedEmployee}
                setCheckedEmployee={setCheckedEmployee}
              />
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={EmployeeColumns.length} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          count={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          component={defaultPaginationProps.component}
          backIconButtonProps={defaultPaginationProps.backIconButton}
          nextIconButtonProps={defaultPaginationProps.nextIconButton}
          rowsPerPageOptions={defaultPaginationProps.rowsPerPage}
          className={defaultPaginationProps.className}
          dir={defaultPaginationProps.dir}
        />
      </CardBody>

      <br />

      <ButtonToolbar className="form__button-toolbar wizard__toolbar">
        <Button color="primary" type="button" onClick={previousPage} className="previous">
          Back
        </Button>
        <Button
          color="success"
          type="button"
          className="next"
          outline
          onClick={() => setShowModal(true)}
          disabled={checkedEmployee.length === 0}
        >
          Assign Selected
        </Button>
      </ButtonToolbar>
      <AssignDialog show={showModal} onHide={() => setShowModal(false)} />
    </form>
  );
};

export default StepThree;
