import React from 'react';
import { useDispatch } from 'react-redux';
import { paths } from '@/shared/helpers/paths';
import { Route, Switch, useHistory } from 'react-router-dom';
import { JobCard } from './JobCard';
import { setJobFilters } from '../redux/actions';
import { JobUIProvider } from './JobUIProvider';
import JobViewDialog from './job-view-dialog/JobViewDialog';
import JobEditDialog from './job-add-edit-dialog/JobEditDialog';
import JobDeleteDialog from './job-delete-dialog/JobDeleteDialog';

const jobBaseUrl = paths.CADS_AI.OPPORTUNITIES.JOBS;

export function JobPage() {
  const history = useHistory();

  const jobUIEvents = {
    openViewJobDialog: (id) => {
      history.push(`${jobBaseUrl}/${id}/view`);
    },
    openNewJobDialog: () => {
      history.push(`${jobBaseUrl}/new`);
    },
    openViewAllJobs: () => {
      history.push(`${jobBaseUrl}`);
    },
    openEditJobDialog: (id) => {
      history.push(`${jobBaseUrl}/${id}/edit`);
    },
    openDeleteJobDialog: (id) => {
      history.push(`${jobBaseUrl}/${id}/delete`);
    },
  };

  const dispatch = useDispatch();

  return (
    <JobUIProvider jobUIEvents={jobUIEvents}>
      <Switch>
        <Route path={`${jobBaseUrl}/new`}>
          {({ history, match }) => (
            <JobEditDialog
              show={match != null}
              isEdit={false}
              onHide={() => {
                dispatch(setJobFilters());
                history.push(jobBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${jobBaseUrl}/:id/edit`}>
          {({ history, match }) => (
            <JobEditDialog
              show={match != null}
              isEdit={true}
              id={match?.params?.id}
              onHide={() => {
                dispatch(setJobFilters());
                history.push(jobBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${jobBaseUrl}/:id/delete`}>
          {({ history, match }) => (
            <JobDeleteDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                history.push(jobBaseUrl);
              }}
            />
          )}
        </Route>
        {/* <Route path={`${jobBaseUrl}/:id/view`}>
                    {({history, match}) => (
                        <JobViewDialog
                            show={match != null}
                            id={match?.params?.id}
                            onHide={() => {
                                history.push(jobBaseUrl);
                            }}
                        />
                    )}
                </Route> */}
      </Switch>
      <JobCard />
    </JobUIProvider>
  );
}
