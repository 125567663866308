import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const fetchDSCohortList = createAction('CADS_AI_FETCH_DS_COHORT_LIST');
export const setDSCohortList = createAction('CADS_AI_SET_DS_COHORT_LIST');
export const setDSCohortError = createAction('CADS_AI_SET_DS_COHORT_ERROR');
export const setDSCohortFilters = createAction('CADS_AI_SET_DS_COHORT_FILTERS');
export const toggleCheckedDSCohorts = createAction('CADS_AI_TOGGLE_CHECKED_DS_COHORTS');
export const uncheckAllDSCohorts = createAction('CADS_AI_UNCHECK_ALL_DS_COHORTS');

export const toggleCheckedItemById = (cohortId) => (dispatch) => {
  dispatch(toggleCheckedDSCohorts(cohortId));
};

export const getDSCohortsList = (filter) => async (dispatch) => {
  const defaultError = `Error occurred getting ds cohort list`;
  dispatch(fetchDSCohortList());
  try {
    const { data, isSuccess } = await api.cadsAIDSCohort.getDSCohortList(filter);
    if (isSuccess) dispatch(setDSCohortList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setDSCohortError(error.message));
    console.error(defaultError, error.message);
  }
};
