import PropTypes from 'prop-types';
import React from 'react';

export const Field = ({ IconComponent, title, fieldValue, iconDisable }) => {
  return (
    <>
      <div className="orgprofile__basic-information">
        <div className="orgprofile__basic-header">
          {iconDisable || <button className="orgprofile__basic-icon">{IconComponent}</button>}
          <div>
            <div className="orgprofile__basic-header-title">{title}</div>
            <div className="orgprofile__basic-body">{fieldValue}</div>
          </div>
        </div>
      </div>
    </>
  );
};

Field.propTypes = {
  IconComponent: PropTypes.any,
  title: PropTypes.string.isRequired,
  fieldValue: PropTypes.string,
  iconDisable: PropTypes.bool,
};
