import React, { Suspense, useEffect, useState, useRef } from 'react';
import { Container, Col, Row } from 'reactstrap';
import Loading from '@/shared/components/Loading';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import api from '@/utils/apiClient';
import Panel from '@/shared/components/Panel';
import Select from 'react-select';
import PanelCard from './PanelCard';
import DataStarChart from './DataStarChart';

const Dashboard = () => {
  const userSVG = (
    <svg height="80" width="80" class="mdi-icon " fill="currentColor" viewBox="0 0 24 24">
      <path d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z"></path>
    </svg>
  );

  const sendSVG = (
    <svg class="mdi-icon " width="80" height="80" fill="currentColor" viewBox="0 0 24 24">
      <path d="M2,21L23,12L2,3V10L17,12L2,14V21Z"></path>
    </svg>
  );

  const { t } = useTranslation('common');
  // const searchedProgramTypePlaceholder = { value: null, label: "Select Program Type" }
  const searchedProgramPlaceholder = { value: null, label: 'Select Program' };
  const searchedStatusPlaceholder = { value: true, label: 'Active' };
  const [applicantCount, setApplicantCount] = useState(0);
  const [programCount, setProgramCount] = useState(0);
  const [acceptedApplicantCount, setAcceptedApplicantCount] = useState(0);
  const [completedSurveyCount, setCompletedSurveyCount] = useState({
    total: 0,
    preAssessment: 0,
    smartSkill: 0,
  });
  // const [programTypeOptions, setProgramTypeOptions] = useState(searchedProgramTypePlaceholder);
  const [evaluationCount, setEvaluationCount] = useState(0);
  const [programs, setPrograms] = useState(searchedProgramPlaceholder);
  const [searchProgram, setSearchProgram] = useState(searchedProgramPlaceholder);
  // const [searchProgramType, setSearchProgramType] = useState(searchedProgramTypePlaceholder);
  const [status, setStatus] = useState(searchedStatusPlaceholder);
  const [preOnboardingCount, setPreOnboardingCount] = useState(0);
  const [onboardingCount, setOnboardingCount] = useState(0);

  useEffect(() => {
    // getProgramTypeOptions();
    getRegisterTalents({ status: status.value });
    getProgramCount({ status: status.value });
    getAcceptedApplicant({ status: status.value });
    getEvaluationCount({ status: status.value });
    getPreOnboardingCount({ status: status.value });
    getOnboardingCount({ status: status.value });
    getCompletedSurvey({});
  }, []);

  useEffect(() => {
    getAllPrograms();
  }, []);

  // const getProgramTypeOptions = async () => {
  //   try {
  //     const { data: data, isSuccess } = await api.programs.getProgramTypeOptions();
  //     if (isSuccess) {
  //       setProgramTypeOptions([{ value: null, label: "Select Program Type" }])
  //       data.data.map(item => {
  //         setProgramTypeOptions(programTypeOptions => [...programTypeOptions, { value: item.id, label: item.title }])
  //       })
  //     } else {
  //       throw new Error('error while fetching program type');
  //     }
  //   } catch (error) {
  //     console.error('Error inside dashboard program type', error.message);
  //   }
  // }

  const getAllPrograms = async () => {
    try {
      const { data: res, isSuccess } = await api.programs.getAllProgramOptions();
      if (isSuccess) {
        setPrograms([{ value: null, label: 'Select Program' }]);
        res.data.map((item) => {
          setPrograms((programs) => [...programs, { value: item.id, label: item.title }]);
        });
      } else {
        throw new Error('error while fetching all programs');
      }
    } catch (error) {
      console.error('Error inside dashboard all programs', error.message);
    }
  };

  const getRegisterTalents = async (filters) => {
    try {
      const { data: res, isSuccess } = await api.dashboard.getApplicantCount(filters);
      if (isSuccess) {
        setApplicantCount(res?.data?.count);
      } else {
        throw new Error('error while fetching registered talent');
      }
    } catch (error) {
      console.error('Error inside dashboard registered talent', error.message);
    }
  };

  const getProgramCount = async (filters) => {
    try {
      const { data: res, isSuccess } = await api.programs.getProgramCount(filters);
      if (isSuccess) {
        setProgramCount(res?.data?.count);
      } else {
        throw new Error('error while fetching program count');
      }
    } catch (error) {
      console.error('Error inside dashboard program count', error.message);
    }
  };

  const getEvaluationCount = async (filters) => {
    try {
      const { data: res, isSuccess } = await api.dashboard.getProgramApplicantStageCount(
        filters,
        3,
      );
      if (isSuccess) {
        setEvaluationCount(res?.data ?? res?.data?.count ?? 0);
      } else {
        throw new Error('error while fetching evaluation count');
      }
    } catch (error) {
      console.error('Error inside dashboard evaluation count', error.message);
    }
  };

  const getAcceptedApplicant = async (filters) => {
    try {
      const { data: res, isSuccess } = await api.dashboard.getAcceptedApplicantCount(filters);
      if (isSuccess) {
        setAcceptedApplicantCount(res?.data?.count);
      } else {
        throw new Error('error while fetching accepted applicant count');
      }
    } catch (error) {
      console.error('Error inside dashboard accepted applicant count', error.message);
    }
  };

  const getCompletedSurvey = async (filters) => {
    try {
      const { data: res, isSuccess } = await api.dashboard.getTotalAssessmentCount(filters);
      if (isSuccess) {
        setCompletedSurveyCount({
          total: res?.data?.totalCount,
          preAssessment: res?.data?.datastarPreAssessmentCount,
          smartSkill: res?.data?.smartSkillCount,
        });
      } else {
        throw new Error('error while fetching complete survey');
      }
    } catch (error) {
      console.error('Error inside dashboard complete survey', error.message);
    }
  };

  const getPreOnboardingCount = async (filters) => {
    try {
      const { data: res, isSuccess } = await api.dashboard.getProgramApplicantStageCount(
        filters,
        4,
      );
      if (isSuccess) {
        setPreOnboardingCount(res?.data ?? res?.data?.count ?? 0);
      } else {
        throw new Error('error while fetching preOnboarding count');
      }
    } catch (error) {
      console.error('Error inside dashboard preOnboarding count', error.message);
    }
  };

  const getOnboardingCount = async (filters) => {
    try {
      const { data: res, isSuccess } = await api.dashboard.getProgramApplicantStageCount(
        filters,
        5,
      );
      if (isSuccess) {
        setOnboardingCount(res?.data ?? res?.data?.count ?? 0);
      } else {
        throw new Error('error while fetching onboarding count');
      }
    } catch (error) {
      console.error('Error inside dashboard onboarding count', error.message);
    }
  };

  const handleChange = (e, type) => {
    // if (type == 'program_type') {
    //   setSearchProgramType(e)
    //   getRegisterTalents({ programTypeId: e?.value, programId: searchProgram?.value, status: status?.value});
    //   getProgramCount({ programTypeId: e?.value, status: status?.value });
    //   getAcceptedApplicant({ programTypeId: e?.value, programId: searchProgram?.value, status: status?.value})
    // }
    if (type == 'status') {
      setStatus(e);
      getRegisterTalents({ programTypeId: 1, programId: searchProgram?.value, status: e?.value });
      getProgramCount({ programTypeId: 1, status: e?.value });
      getEvaluationCount({ programTypeId: 1, programId: searchProgram?.value, status: e?.value });
      getAcceptedApplicant({ programTypeId: 1, programId: searchProgram?.value, status: e?.value });
      getPreOnboardingCount({
        programTypeId: 1,
        programId: searchProgram?.value,
        status: e?.value,
      });
      getOnboardingCount({ programTypeId: 1, programId: searchProgram?.value, status: e?.value });
    }

    if (type == 'program') {
      setSearchProgram(e);
      getRegisterTalents({ programTypeId: 1, programId: e?.value, status: status?.value });
      getAcceptedApplicant({ programTypeId: 1, programId: e?.value, status: status?.value });
      getEvaluationCount({ programTypeId: 1, programId: e?.value, status: status?.value });
      getPreOnboardingCount({ programTypeId: 1, programId: e?.value, status: status?.value });
      getOnboardingCount({ programTypeId: 1, programId: e?.value, status: status?.value });
      getCompletedSurvey({ programTypeId: 1, programId: e?.value });
    }
  };

  return (
    <Container className="dashboard">
      <Row>
        <Col md={6}>
          <h3 className="page-title">Data Star Dashboard</h3>
        </Col>
        <Col md={6} className="mb-4">
          <div className="d-flex flex-wrap float-right">
            {/* <div className="table__search_item">
              Program Type
              <div style={{ minWidth: 200 }}>
                <Select
                  name="program_type"
                  value={searchProgramType}
                  onChange={(e) => { handleChange(e, 'program_type') }}
                  autosize={true}
                  options={programTypeOptions}
                  clearable={false}
                  className="react-select"
                  placeholder={"Select Program Type"}
                  classNamePrefix="react-select"
                />
              </div>
            </div> */}
            <div className="table__search_item">
              Program
              <div style={{ minWidth: 200 }}>
                <Select
                  name="program"
                  value={searchProgram}
                  onChange={(e) => {
                    handleChange(e, 'program');
                  }}
                  autosize={true}
                  options={programs}
                  clearable={false}
                  className="react-select"
                  placeholder={'Select Program'}
                  classNamePrefix="react-select"
                />
              </div>
            </div>
            <div className="table__search_item">
              Status
              <div style={{ minWidth: 200 }}>
                <Select
                  name="status"
                  value={status}
                  onChange={(e) => handleChange(e, 'status')}
                  autosize={true}
                  options={[
                    { value: true, label: 'Active' },
                    { value: false, label: 'Deactive' },
                  ]}
                  clearable={false}
                  className="react-select"
                  placeholder={'Select Status'}
                  classNamePrefix="react-select"
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Suspense fallback={<Loading />}>
        <Row>
          <Panel xl={4} lg={4} md={4} title="Metrics">
            <PanelCard count={programCount} svg={sendSVG} text="Programs" colorClass="card-green" />
            <PanelCard
              count={applicantCount}
              svg={userSVG}
              text="Talents Registered"
              colorClass="card-orange"
            />
            <PanelCard
              count={completedSurveyCount.total ?? 0}
              isCompletedSurveyCount={true}
              extras={[
                completedSurveyCount.smartSkill ?? 0,
                completedSurveyCount.preAssessment ?? 0,
              ]}
              svg={sendSVG}
              text="Assessments Completed"
              colorClass="card-green"
            />
            <PanelCard
              count={evaluationCount}
              svg={userSVG}
              text="Evaluation"
              colorClass="card-orange"
            />
            <PanelCard
              count={acceptedApplicantCount}
              isAcceptedApplicantCount={true}
              extras={[preOnboardingCount, onboardingCount]}
              svg={userSVG}
              text="Accepted"
              colorClass="card-green"
            />
          </Panel>

          <Panel xl={8} lg={8} md={8} title="No. of applicants">
            <DataStarChart
              programTypeId={1}
              programId={searchProgram?.value}
              status={status?.value}
            />
          </Panel>
        </Row>
      </Suspense>
    </Container>
  );
};

Dashboard.propTypes = {
  dir: PropTypes.string.isRequired,
  themeName: PropTypes.string.isRequired,
};

export default connect((state) => ({ themeName: state.theme.className }))(Dashboard);
