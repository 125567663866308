import { useState, useEffect } from "react";
import api from '@/utils/apiClient';
import OrderDetailHeader from "./OrderHistoryHeader";
import OrderHistoryTable from "./OrderHistoryTable";
import { ButtonToolbar } from 'reactstrap';
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon';
import { useOrderHistoryUIContext } from "../OrderHistoryUIProvider";

const OrderHistoryView = ({ id }) => {
    const orderHistoryUIContext = useOrderHistoryUIContext();
    const [orderHistory, setOrderHistory] = useState()
    const getOrderHistoryDetail = async (orderId) => {
        try {
            const { data: res, isSuccess } = await api.cadsAIOrderHistory.getOrderHistoryById(orderId);
            if (isSuccess) {
                setOrderHistory(res?.data);
            } else {
                throw new Error('Error while getting order details');
            }
        } catch (e) {
            console.error(e.message ?? 'Error while getting order details');
        }
    };

    const handleBackClick = () => {
        orderHistoryUIContext.openOrderHistoryPage()
    }

    useEffect(() => {
        getOrderHistoryDetail(id)
    }, [id])

    return (
        <div>
        <ButtonToolbar>
        <ArrowLeftIcon color="primary" className="preview-back-icon" onClick={handleBackClick} />
        <h2 className="page-title mb-2">{orderHistory?.orderId}</h2>
        </ButtonToolbar>
            <OrderDetailHeader orderHistory={orderHistory} />
            <OrderHistoryTable orders={orderHistory?.orders} />
        </div>
    )
}

export default OrderHistoryView