import React, { createContext, useContext } from 'react';

const SubCategoryUIContext = createContext();

export function useSubcategoryUIContext() {
  return useContext(SubCategoryUIContext);
}

export function SubcategoryUIProvider({ subCategoryUIEvents, children }) {
  const value = {
    openViewSubCategoryDialog: subCategoryUIEvents.openViewSubCategoryDialog,
    openNewSubCategoryDialog: subCategoryUIEvents.openNewSubCategoryDialog,
    openEditSubCategoryDialog: subCategoryUIEvents.openEditSubCategoryDialog,
    openDeleteSubCategoryDialog: subCategoryUIEvents.openDeleteSubCategoryDialog,
    openManageSkillsDialog: subCategoryUIEvents.openManageSkillsDialog,
  };

  return <SubCategoryUIContext.Provider value={value}>{children}</SubCategoryUIContext.Provider>;
}
