import React, { createContext, useContext } from 'react';

const DdoUIContext = createContext();

export function useDdoUIContext() {
  return useContext(DdoUIContext);
}

export function DdoUIProvider({ ddoUIEvents, children }) {
  const value = {
    openViewDdoDialog: ddoUIEvents.openViewDdoDialog,
    openNewDdoDialog: ddoUIEvents.openNewDdoDialog,
    openEditDdoDialog: ddoUIEvents.openEditDdoDialog,
    openDeleteDdoDialog: ddoUIEvents.openDeleteDdoDialog,
  };

  return <DdoUIContext.Provider value={value}>{children}</DdoUIContext.Provider>;
}
