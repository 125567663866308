import React, { createContext, useContext } from 'react';

const QAContext = createContext();

export function useQAUIContext() {
  return useContext(QAContext);
}

export function QAUIProvider({ qaUIEvents, children }) {
  const value = {
    openViewQADialog: qaUIEvents.openViewQADialog,
    openDeleteQADialog: qaUIEvents.openDeleteQADialog,
    openDeactivateReplyDialog: qaUIEvents.openDeactivateReplyDialog,
    openQA: qaUIEvents.openQA,
    openViewPinnedQuestions: qaUIEvents.openViewPinnedQuestions,
    openNewPinnedQuestions: qaUIEvents.openNewPinnedQuestions,
    openUpdatePinnedQuestions: qaUIEvents.openUpdatePinnedQuestions,
    openUnpinQuestion: qaUIEvents.openUnpinQuestion,
  };

  return <QAContext.Provider value={value}>{children}</QAContext.Provider>;
}
