import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { cadsAiDdoBaseUrl } from '../index';
import { DdoUIProvider } from './DdoUIProvider';
import { DdoCard } from './DdoCard';
import DdoEditDialog from './ddo-add-edit-dialog/DdoEditDialog';
import DdoViewDialog from './ddo-view-dialog/DdoViewDialog';
import { setDdoFilters } from '../redux/actions';
import { useDispatch } from 'react-redux';
import DdoDeleteDialog from './ddo-delete-dialog/DdoDeleteDialog';

export function DdoPage({ history }) {
  const dispatch = useDispatch();

  const ddoUIEvents = {
    openViewDdoDialog: (id) => {
      history.push(`${cadsAiDdoBaseUrl}/${id}/view`);
    },
    openNewDdoDialog: () => {
      history.push(`${cadsAiDdoBaseUrl}/new`);
    },
    openEditDdoDialog: (id) => {
      history.push(`${cadsAiDdoBaseUrl}/${id}/edit`);
    },
    openDeleteDdoDialog: (id) => {
      history.push(`${cadsAiDdoBaseUrl}/${id}/delete`);
    },
  };

  return (
    <DdoUIProvider ddoUIEvents={ddoUIEvents}>
      <Switch>
        <Route path={`${cadsAiDdoBaseUrl}/new`}>
          {({ history, match }) => (
            <DdoEditDialog
              show={match != null}
              isEdit={false}
              onHide={() => {
                dispatch(setDdoFilters());
                history.push(cadsAiDdoBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${cadsAiDdoBaseUrl}/:id/edit`}>
          {({ history, match }) => (
            <DdoEditDialog
              show={match != null}
              isEdit={true}
              id={match?.params?.id}
              onHide={() => {
                dispatch(setDdoFilters());
                history.push(cadsAiDdoBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${cadsAiDdoBaseUrl}/:id/delete`}>
          {({ history, match }) => (
            <DdoDeleteDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                dispatch(setDdoFilters());
                history.push(cadsAiDdoBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${cadsAiDdoBaseUrl}/:id/view`}>
          {({history, match}) => (
              <DdoViewDialog
                  show={match != null}
                  id={match?.params?.id}
                  onHide={() => {
                    history.push(cadsAiDdoBaseUrl);
                  }}
              />
          )}
        </Route>
      </Switch>
      <DdoCard/>
    </DdoUIProvider>
  );
}
