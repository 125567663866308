import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loading from '@/shared/components/Loading';
import { paths } from '@/shared/helpers/paths';
import { OrderHistoryPage } from './components/OrderHistoryPage';
import './index.scss';

export const cadsAiOrderHistoryBaseUrl = paths.CADS_AI.ORDER_HISTORY;

const OrderHistory = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path={cadsAiOrderHistoryBaseUrl} component={OrderHistoryPage} />
      </Switch>
    </Suspense>
  );
};

export default OrderHistory;
