import DOMPurify from 'dompurify';
import { isEmpty } from 'lodash';

/*TODO: think about more restriction and enforce only required ones as allowed tags*/
const defaultOptions = {
  // ALLOWED_TAGS: [ 'b', 'i', 'em', 'strong', 'a' ],
  ALLOWED_ATTR: ['style', 'href'],
};

export const getSanitizeHTML = (dirtyHTML, options = {}) =>
  DOMPurify.sanitize(dirtyHTML, { ...defaultOptions, ...options });

const sanitize = (dirty, options = {}) => {
  if (isEmpty(dirty)) return dirty;
  return {
    __html: DOMPurify.sanitize(dirty, { ...defaultOptions, ...options }),
  };
};

const SanitizeHTML = ({html, options, className}) => {
  let dirtyHTML = html ?? '</P>';
  if (dirtyHTML.length === 0) {
    dirtyHTML = '</P>';
  } else if (!dirtyHTML.startsWith('<p>')) {
    dirtyHTML = `<p>${dirtyHTML}</p>`;
  }
  return (
      <div className={className} dangerouslySetInnerHTML={sanitize(dirtyHTML, options)}/>
  );
};

export default SanitizeHTML;
