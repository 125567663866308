import React from 'react';
import { ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon';
import { useApplicantUIContext } from '../ApplicantsUIProvider';
import api from '@/utils/apiClient';
import LoadingIcon from 'mdi-react/LoadingIcon';
import UserTabs from './user-tabs';

const ApplicantDetailsPage = ({ id }) => {
  const applicantEvent = useApplicantUIContext();

  const [applicantStage, setApplicantStage] = React.useState([]);
  const [smartSkillsData, setSmartSkillsData] = React.useState({});
  const [state, setState] = React.useState({ loading: false, applicant: {}, error: '' });

  const { userDetails } = state?.applicant;

  React.useEffect(() => {
    if (id) {
      getCadsAiApplicantById(+id);
      getStageOptions();
    }
  }, [id]);

  const getStageOptions = async () => {
    const { data: res, isSuccess } = await api.cadsAIApplicant.getApplicantStageOptions();
    if (isSuccess) {
      setApplicantStage(res?.data?.map((stage) => ({ value: stage.id, label: stage.title })));
    }
  };

  const getCadsAiApplicantById = async (id) => {
    try {
      setState({ ...state, loading: true });
      const { data: res, isSuccess } = await api.cadsAIApplicant.getApplicantById(id);
      if (isSuccess) {
        setState({ ...state, loading: false, applicant: res?.data, error: '' });
      } else {
        throw new Error(res?.messages?.[0] ?? 'Error while getting applicant details');
      }
    } catch ({ message }) {
      setState({ ...state, loading: false, applicant: {}, error: message });
      console.error(message);
    }
  };

  const getUserSmartSkillsResult = async (userId) => {
    try {
      const { data: res, isSuccess } = await api.cadsAIUsers.getUserSmartSkillsResult(userId);
      if (isSuccess) {
        setSmartSkillsData(res?.data?.factors);
      } else {
        throw new Error('Error while getting user details');
      }
    } catch (e) {
      console.error(e.message ?? 'Error while getting user details');
    }
  };

  React.useMemo(() => {
    if (userDetails?.id) {
      getUserSmartSkillsResult(userDetails?.id);
    }
  }, [userDetails?.id]);

  return (
    <Col md={12} lg={12} className="applicant-details-page">
      {state?.loading && (
        <div className={'text-center overflow-hidden'}>
          <div className="panel__refresh position-relative" style={{ height: 75 }}>
            <LoadingIcon />
          </div>
        </div>
      )}
      <Card>
        <CardBody className="products-list">
          <div>
            <div>
              <ButtonToolbar className="button-placement">
                <ArrowLeftIcon
                  color="primary"
                  outline
                  onClick={() => applicantEvent.openViewApplicants()}
                >
                  Back To Applicant List
                </ArrowLeftIcon>
              </ButtonToolbar>
              <div className="dashboard__info-title">
                <h2 className="page-title mb-2">
                  {state?.applicant?.userDetails?.userFName}{' '}
                  {state?.applicant?.userDetails?.userLName}
                </h2>
                <span className="bread-crumb-title">{`Applicant / ${state?.applicant?.userDetails?.userFName}`}</span>
              </div>
            </div>
            <div className="mb-2">
              <div className="card__supporting-text" style={{ overflowX: 'auto' }}>
                <div className="stepper-horizontal-alternative">
                  {applicantStage?.map((stage, idx) => (
                    <div
                      className={`stepper-step ${
                        idx <=
                        applicantStage.findIndex(
                          (stage) => stage.value === state?.applicant?.applicantFinalStage?.id,
                        )
                          ? 'active-step'
                          : ''
                      }`}
                    >
                      <div className="stepper-title">{stage?.label}</div>
                      <div className="stepper-circle">
                        <span>{idx + 1}</span>
                      </div>
                      <div className="stepper-bar-left" />
                      <div className="stepper-bar-right" />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <UserTabs
            firstStage={applicantStage?.[0]}
            enrollmentId={id ? +id : 0}
            state={state}
            smartSkillsData={smartSkillsData}
          />
        </CardBody>
      </Card>
    </Col>
  );
};

export default ApplicantDetailsPage;
