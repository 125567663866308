import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { cadsAiNotificationBaseUrl } from '../index';
import { NotificationUIProvider } from './NotificationUIProvider';
import { NotificationCard } from './NotificationCard';
import NotificationEditDialog from './notification-add-edit-dialog/NotificationEditDialog';
import { setNotificationFilters, uncheckAllNotification } from '../redux/actions';
import { useDispatch } from 'react-redux';
import NotificationDeactivateDialog from './notification-deactivate-dialog/NotificationDeactivateDialog';

export function NotificationPage({ history }) {
  const dispatch = useDispatch();

  //clean up
  React.useEffect(() => () => dispatch(uncheckAllNotification()), []);

  const notificationUIEvents = {
    openNotifications: () => {
      history.push(`${cadsAiNotificationBaseUrl}`);
    },
    openViewNotificationDialog: (id) => {
      history.push(`${cadsAiNotificationBaseUrl}/${id}/view`);
    },
    openNewNotificationDialog: () => {
      history.push(`${cadsAiNotificationBaseUrl}/new`);
    },
    openEditNotificationDialog: (id) => {
      history.push(`${cadsAiNotificationBaseUrl}/${id}/edit`);
    },
    openDeactivateNotificationDialog: () => {
      history.push(`${cadsAiNotificationBaseUrl}/deactivate`);
    },
  };

  return (
    <NotificationUIProvider notificationUIEvents={notificationUIEvents}>
      <Switch>
        <Route path={`${cadsAiNotificationBaseUrl}/new`}>
          {({ history, match }) => (
            <NotificationEditDialog
              show={match != null}
              isEdit={false}
              onHide={() => {
                dispatch(setNotificationFilters());
                history.push(cadsAiNotificationBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${cadsAiNotificationBaseUrl}/:id/edit`}>
          {({ history, match }) => (
            <NotificationEditDialog
              show={match != null}
              isEdit={true}
              id={match?.params?.id}
              onHide={() => {
                dispatch(setNotificationFilters());
                history.push(cadsAiNotificationBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${cadsAiNotificationBaseUrl}/deactivate`}>
          {({ history, match }) => (
            <NotificationDeactivateDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                dispatch(setNotificationFilters());
                history.push(cadsAiNotificationBaseUrl);
              }}
            />
          )}
        </Route>
      </Switch>
      <NotificationCard />
    </NotificationUIProvider>
  );
}
