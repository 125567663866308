import React, { memo } from 'react';
import { questionTypes } from '../../options';
import MCQMultiSelect from './MCQMultiSelect';
import FileUploadDropzone from './FileUploadDropzone';
import ContactInformation from './ContactInformation';
import ScaleRatingQuestion from './ScaleRatingQuestion';
import MatrixRatingQuestion from './MatrixRatingQuestion';

const QuestionTypes = ({ pageId, questionId, questionType, ...extras }) => {
  const props = {
    pageId,
    questionId,
    typeData: extras.typeData,
  };
  return (
    <>
      {{
        [questionTypes.MCQ_MULTI_SELECT.value]: <MCQMultiSelect {...props} />,
        [questionTypes.MCQ_SINGLE_SELECT.value]: (
          <MCQMultiSelect {...props} />
        ) /*Reusing MCQMultiSelect for MCQ_SINGLE_SELECT as they are identical*/,
        [questionTypes.DROPDOWN.value]: (
          <MCQMultiSelect {...props} />
        ) /*Reusing MCQMultiSelect for DROPDOWN as they are identical*/,
        [questionTypes.LIKERT.value]: (
          <MCQMultiSelect {...props} showViewRadioBtn showOthersCheckBox={false} />
        ) /*Reusing MCQMultiSelect for LIKERT as they are identical*/,
        [questionTypes.FILE_UPLOAD.value]: <FileUploadDropzone {...props} />,
        [questionTypes.CONTACT_INFO.value]: <ContactInformation {...props} />,
        [questionTypes.SCALE_RATING_QUESTION.value]: <ScaleRatingQuestion {...props} />,
        [questionTypes.MATRIX_RATING_QUESTION.value]: <MatrixRatingQuestion {...props} />,
      }?.[questionType?.value] ?? <></>}
    </>
  );
};

export default memo(QuestionTypes);
