import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, ButtonToolbar, Col, Row } from 'reactstrap';
import { YesNoOptions } from '@/shared/helpers';
import { FormField } from '@/shared/components/form/hook/FormField';
import { SelectField } from '@/shared/components/form/Select';
import { ErrorMessage } from '@hookform/error-message';
import { isEmpty } from 'lodash';
import { RadioButtonField } from '@/shared/components/form/RadioButton';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableCell } from '@material-ui/core';
import DeleteIcon from 'mdi-react/DeleteIcon';
import Table from '@material-ui/core/Table';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getAllLearningPathOptions } from '@/containers/Cads-AI/ManageLearning/LearningPath/redux/actions';
import { getAllCourseOptions } from '@/containers/Cads-AI/ManageLearning/Course/redux/actions';
import { getAllModuleOptions } from '@/containers/Cads-AI/ManageLearning/Module/redux/actions';

const LearningPathForm = ({
  learningList,
  setLearningList,
  setCourseOpts,
  setModuleOpts,
  setLearningPathOpts,
  learningPathOpts,
  courseOpts,
  moduleOpts,
}) => {
  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
    watch,
    setError,
    clearErrors,
    setValue,
  } = useForm({ reValidateMode: 'onChange' });

  const [learningPath, course, module] = watch(['learningPath', 'course', 'module']);

  const [isMandatory, setIsMandatory] = React.useState(`${YesNoOptions[1].value}`);

  /*Use Effects*/
  React.useEffect(() => {
    dispatch(getAllLearningPathOptions());
    dispatch(getAllCourseOptions());
    dispatch(getAllModuleOptions());
  }, []);
  React.useEffect(() => {
    if (learningPath && !isEmpty(learningPath)) {
      setValue('course', null);
      setValue('module', null);
    }
  }, [learningPath]);
  React.useEffect(() => {
    if (course && !isEmpty(course)) {
      setValue('learningPath', null);
      setValue('module', null);
    }
  }, [course]);
  React.useEffect(() => {
    if (module && !isEmpty(module)) {
      setValue('course', null);
      setValue('learningPath', null);
    }
  }, [module]);

  const onSubmit = (data) => {
    //adding new learning to list
    const { learningPath, course, module } = getValues();
    setLearningList([
      ...learningList,
      {
        ...data,
        id: new Date().getTime(),
        learningPath: isEmpty(data.learningPath)
          ? null
          : { id: data.learningPath.value, title: data.learningPath.label },
        course: isEmpty(data.course) ? null : { id: data.course.value, title: data.course.label },
        module: isEmpty(data.module) ? null : { id: data.module.value, title: data.module.label },
        isMandatory: isMandatory === 'true',
        sortNumber: isEmpty(data.sortNumber) ? 0 : data.sortNumber,
        action: 'added',
      },
    ]);
    if (learningPath) {
      setLearningPathOpts(learningPathOpts.filter((item) => item?.value !== learningPath?.value));
      setValue('learningPath', '');
    } else if (course) {
      setCourseOpts(courseOpts.filter((item) => item?.value !== course?.value));
      setValue('course', '');
    } else if (module) {
      setModuleOpts(moduleOpts.filter((item) => item?.value !== module?.value));
      setValue('module', '');
    }
  };

  return (
    <form className={`form`} onSubmit={handleSubmit(onSubmit)}>
      <ErrorMessage
        errors={errors}
        name="learningPath"
        render={({ message }) => <span className="form__form-group-error lp-error">{message}</span>}
      />
      <Row>
        <Col>
          <div className="form__form-group">
            <span className="form__form-group-label">Learning Path</span>
            <div className="form__form-group-field">
              <FormField
                control={control}
                name="learningPath"
                component={SelectField}
                options={learningPathOpts}
                placeholder="Select Learning Path"
                rules={{
                  validate: () => {
                    if (!(learningPath || module || course))
                      return 'Select one of the following : learning path or module or course';
                    return true;
                  },
                }}
              />
            </div>
          </div>
        </Col>
        <Col>
          <div className="form__form-group">
            <span className="form__form-group-label">Course</span>
            <div className="form__form-group-field">
              <FormField
                control={control}
                name="course"
                options={courseOpts}
                component={SelectField}
                placeholder="Select Course"
                rules={{
                  validate: () => {
                    clearErrors('learningPath');
                    if (!(learningPath || module || course))
                      setError('learningPath', {
                        message: 'Select one of the following : learning path or module or course',
                        type: 'custom',
                      });
                    return true;
                  },
                }}
              />
            </div>
          </div>
        </Col>
        <Col>
          <div className="form__form-group">
            <span className="form__form-group-label">Module</span>
            <div className="form__form-group-field">
              <FormField
                control={control}
                name="module"
                component={SelectField}
                options={moduleOpts}
                placeholder="Select Module"
                rules={{
                  validate: () => {
                    clearErrors('learningPath');
                    if (!(learningPath || module || course))
                      setError('learningPath', {
                        message: 'Select one of the following : learning path or module or course',
                        type: 'custom',
                      });
                    return true;
                  },
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="form__form-group">
            <span className="form__form-group-label">Sort Order</span>
            <div className="form__form-group-field">
              <FormField
                name="sortNumber"
                as="input"
                type="number"
                min={0}
                placeholder="Enter Sort Order"
                control={control}
                defaultValue="0"
              />
            </div>
          </div>
        </Col>
        <Col>
          <div className="form__form-group">
            <span className="form__form-group-label">Is Mandatory</span>
            <div className="form__form-group-field mt-2">
              <RadioButtonField
                radioValue={`${YesNoOptions[0].value}`}
                name={YesNoOptions[0].label}
                onChange={setIsMandatory}
                value={isMandatory}
                className="colored-click mr-5"
                label={YesNoOptions[0].label}
              />
              <RadioButtonField
                radioValue={`${YesNoOptions[1].value}`}
                name={YesNoOptions[1].label}
                onChange={setIsMandatory}
                value={isMandatory}
                className="colored-click mr-5"
                label={YesNoOptions[1].label}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="form__form-group">
            <span className="form__form-group-label">Description</span>
            <div className="form__form-group-field">
              <FormField
                name="description"
                control={control}
                component="textarea"
                defaultValue=""
                placeholder="Enter Description"
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <ButtonToolbar className="form__button-toolbar modal__footer w-100">
            <Button disabled={false} color="success" outline type="submit">
              Add Learning
            </Button>
          </ButtonToolbar>
        </Col>
      </Row>
    </form>
  );
};

const LearningPathTable = ({
  learningList,
  setLearningList,
  setCourseOpts,
  setModuleOpts,
  setLearningPathOpts,
  learningPathOpts,
  courseOpts,
  moduleOpts,
}) => {
  const removeLearning = (learnings) => {
    if (learnings?.course) {
      setCourseOpts([
        { value: learnings?.course?.value, label: learnings?.course?.title },
        ...courseOpts,
      ]);
      setLearningList(
        learningList.map((learning) =>
          learning.id !== learnings?.id ? learning : { ...learning, action: 'deleted' },
        ),
      );
    } else if (learnings?.module) {
      setModuleOpts([
        { value: learnings?.module?.value, label: learnings?.module?.title },
        ...moduleOpts,
      ]);
      setLearningList(
        learningList.map((learning) =>
          learning.id !== learnings?.id ? learning : { ...learning, action: 'deleted' },
        ),
      );
    } else if (learnings?.learningPath) {
      setLearningPathOpts([
        { value: learnings?.learningPath?.value, label: learnings?.learningPath?.title },
        ...learningPathOpts,
      ]);
      setLearningList(
        learningList.map((learning) =>
          learning.id !== learnings?.id ? learning : { ...learning, action: 'deleted' },
        ),
      );
    }
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{ minWidth: 100 }}>Learning Path</TableCell>
          <TableCell style={{ minWidth: 100 }}>Course</TableCell>
          <TableCell style={{ minWidth: 100 }}>Module</TableCell>
          <TableCell style={{ minWidth: 70 }}>Is Mandatory</TableCell>
          <TableCell style={{ minWidth: 100 }}>Sort Order</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
      {!learningList.length && (
        <TableRow>
          <TableCell colSpan={7} align="center">
            <p className="mt-3 mb-3">
              There are no learnings added, fill out the about form and click on Add Learning button
              to add new learning path.
            </p>
          </TableCell>
        </TableRow>
      )}
      {learningList
        ?.filter((ll) => ll.action !== 'deleted')
        ?.sort((l1, l2) => +(l1?.sortOrder ?? 0) - (l2?.sortOrder ?? 0))
        .map((ll) => (
          <TableRow>
            <TableCell>{ll.learningPath?.title ?? '-'}</TableCell>
            <TableCell>{ll.course?.title ?? '-'}</TableCell>
            <TableCell>{ll?.module?.title ?? '-'}</TableCell>
            <TableCell>{ll?.isMandatory ? 'Yes' : 'No'}</TableCell>
            <TableCell>{ll?.sortNumber ?? '-'}</TableCell>
            <TableCell>{ll?.description?.trim() === '' ? '-' : ll?.description?.trim()}</TableCell>
            <TableCell>
              <DeleteIcon
                style={{ color: '#ff4861' }}
                role="button"
                onClick={() => removeLearning(ll)}
              />
            </TableCell>
          </TableRow>
        ))}
    </Table>
  );
};

const StepThree = ({ onSubmit, page, data, previousPage, isEdit }) => {
  const [learningList, setLearningList] = React.useState([]);
  const [learningPathOpts, setLearningPathOpts] = React.useState([]);
  let learningPath = [];
  let course = [];
  let module = [];
  const [courseOpts, setCourseOpts] = React.useState([]);
  const [moduleOpts, setModuleOpts] = React.useState([]);
  const { learningPathOptions } = useSelector((state) => state.cadsAILearningPaths, shallowEqual);
  const { courseOptions } = useSelector((state) => state.cadsAICourse, shallowEqual);
  const { moduleOptions } = useSelector((state) => state.cadsAIModule, shallowEqual);

  learningList.forEach((item) => {
    if (item.learningPath) {
      learningPath.push(item);
    } else if (item.course) {
      course.push(item);
    } else if (item.module) {
      module.push(item);
    }
  });

  React.useEffect(() => {
    setLearningList(data?.learningList ?? []);
  }, [data]);

  React.useEffect(() => {
    if (learningPathOptions.length > 0 || learningPath.length > 0) {
      const selectedLPIds = learningPath.map((item) => item?.learningPath?.id) ?? [];
      setLearningPathOpts(
        learningPathOptions
          .filter((s) => !selectedLPIds.includes(s?.id))
          .map((item) => ({ value: item?.id, label: item?.title })),
      );
    }
  }, [learningPathOptions]);

  React.useEffect(() => {
    if (courseOptions.length > 0 || course.length > 0) {
      const selectedCourseIds = course.map((item) => item?.course?.id) ?? [];
      setCourseOpts(
        courseOptions
          .filter((s) => !selectedCourseIds.includes(s?.id))
          .map((item) => ({ value: item?.id, label: item?.title })),
      );
    }
  }, [courseOptions]);

  React.useEffect(() => {
    if (moduleOptions.length > 0 || module.length > 0) {
      const selectedModuleIds = module.map((item) => item?.module?.id) ?? [];
      setModuleOpts(
        moduleOptions
          .filter((s) => !selectedModuleIds.includes(s?.id))
          .map((item) => ({ value: item?.id, label: item?.title })),
      );
    }
  }, [moduleOptions]);

  return (
    <div className="modal__body add-edit-program-modal">
      <LearningPathForm
        learningList={learningList}
        setLearningList={setLearningList}
        setCourseOpts={setCourseOpts}
        courseOpts={courseOpts}
        setModuleOpts={setModuleOpts}
        moduleOpts={moduleOpts}
        setLearningPathOpts={setLearningPathOpts}
        learningPathOpts={learningPathOpts}
      />
      <LearningPathTable
        learningList={learningList}
        setLearningList={setLearningList}
        setCourseOpts={setCourseOpts}
        courseOpts={courseOpts}
        setModuleOpts={setModuleOpts}
        moduleOpts={moduleOpts}
        setLearningPathOpts={setLearningPathOpts}
        learningPathOpts={learningPathOpts}
      />
      <ButtonToolbar className="form__button-toolbar modal__footer">
        <Button type="button" className="previous" onClick={() => previousPage({ learningList })}>
          Back
        </Button>
        <Button
          disabled={false}
          color="primary"
          type="button"
          onClick={() => onSubmit({ learningList })}
        >
          Next
        </Button>
      </ButtonToolbar>
    </div>
  );
};

export default StepThree;
