import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, ButtonToolbar } from 'reactstrap';
import { emptyStringPattern } from '@/shared/helpers';
import { convertUrlToFile } from '@/shared/helpers';
import { FormField } from '@/shared/components/form/hook/FormField';
import { extractFieldData, getStepFields } from '../index';
import renderDropZoneField from '@/shared/components/form/DropZone';

const StepOne = ({ onSubmit, page, data }) => {
  // learningPath form related functions
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm(extractFieldData(page, data));

  const setImage = async () => {
    if (data?.imageUrl) {
      const imgFile = await convertUrlToFile(data?.imageUrl);
      setValue('imageUrl', imgFile ? [imgFile] : '');
    }
  };

  React.useEffect(() => {
    getStepFields(page).forEach((key) => {
      setValue(key, data[key]);
    });
    setImage();
  }, [data]);

  return (
    <div className="modal__body add-edit-learning-path">
      <form className={`form form--horizontal`} onSubmit={handleSubmit(onSubmit)}>
        <div className="form__form-group">
          <span className="form__form-group-label">Full Title</span>
          <div className="form__form-group-field">
            <FormField
              name="fullTitle"
              control={control}
              component="input"
              errors={errors}
              rules={{
                required: 'This is required field',
                pattern: emptyStringPattern,
              }}
              defaultValue=""
              isAboveError={true}
              placeholder="Full Name of Learning Path"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Short title</span>
          <div className="form__form-group-field">
            <FormField
              name="shortTitle"
              control={control}
              component="input"
              errors={errors}
              rules={{
                required: 'This is required field',
                pattern: emptyStringPattern,
              }}
              defaultValue=""
              isAboveError={true}
              placeholder="Short Name of Learning Path"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Description</span>
          <div className="form__form-group-field">
            <FormField
              name="description"
              control={control}
              component="textarea"
              errors={errors}
              defaultValue=""
              isAboveError={true}
              placeholder="Write description about learning path"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Tagline</span>
          <div className="form__form-group-field">
            <FormField
              name="tagJson"
              control={control}
              component="input"
              errors={errors}
              defaultValue=""
              isAboveError={true}
              placeholder="Tag Line for Learning Path"
            />
          </div>
        </div>
        <div className="form__form-group mt-1">
          <span className="form__form-group-label">Image</span>
          <div className="form__form-group-field">
            <FormField
              name="imageUrl"
              as="input"
              placeholder="Enter Image Url"
              control={control}
              component={renderDropZoneField}
              defaultValue=""
            />
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar modal__footer">
          <Button color="primary" type="submit">
            Next
          </Button>
        </ButtonToolbar>
      </form>
    </div>
  );
};

export default StepOne;
