import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import AssessmentDuplicateDialogHeader from './AssessmentDuplicateDialogHeader';
import AssessmentDuplicateForm from './AssessmentDuplicateForm';

const AssessmentDuplicateDialog = ({ show, onHide, id, isEdit, rtl }) => {
  const [modal, setModal] = useState({
    isOpen: show,
    title: 'Duplicate Assessment',
    hide: onHide,
  });

  return (
    <Modal
      isOpen={show}
      toggle={modal.hide}
      keyboard={false}
      backdrop={'static'}
      modalClassName={`${rtl.direction}-support`}
      className="modal-dialog--primary modal-dialog--header"
    >
      <AssessmentDuplicateDialogHeader modal={modal} />
      <AssessmentDuplicateForm modal={modal} isEdit={isEdit} id={id} />
    </Modal>
  );
};

export default connect((state) => ({
  rtl: state.rtl,
}))(AssessmentDuplicateDialog);
