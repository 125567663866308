import React from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import EmailTable from './EmailTable';
import { useEmailUIContext } from '../EmailUIProvider';

const EmailsTableWrapper = () => {
  const emailsUIContext = useEmailUIContext();

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="products-list">
          <div className="card__title">
            <h5 className="bold-text">Data Emails List</h5>
            <ButtonToolbar className="products-list__btn-toolbar-top">
              <Button color="primary" onClick={emailsUIContext.openNewEmailDialog}>
                New Email
              </Button>
              <Button color="success" outline onClick={() => null}>
                Export Emails
              </Button>
            </ButtonToolbar>
          </div>
          <EmailTable />
        </CardBody>
      </Card>
    </Col>
  );
};

export default EmailsTableWrapper;
