import { handleActions } from 'redux-actions';
import {
  fetchCadsAITrainingList,
  setCadsAITrainingList,
  setCadsAITrainingError,
  setCadsAITrainingFilters,
  toggleCheckedCadsAITraining,
  uncheckAllCadsAITraining,
} from './actions';

const defaultState = {
  loading: false,
  checkedTrainingProviderIds: [],
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    search: '',
    isSortOrderDescending: false,
    status: null,
  },
  totalRecords: 0,
  data: [],
  options: [],
  error: null,
};

export default handleActions(
  {
    [fetchCadsAITrainingList](state) {
      return {
        ...state,
        loading: true,
        data: [],
      };
    },
    [setCadsAITrainingList](state, { payload }) {
      return {
        ...state,
        loading: false,
        data: payload.data,
        totalRecords: payload.data?.length,
        error: null,
      };
    },
    [setCadsAITrainingError](state, { payload }) {
      return {
        ...state,
        data: [],
        loading: false,
        totalPages: defaultState.totalPages,
        error: payload?.message,
      };
    },
    [setCadsAITrainingFilters](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
        checkedTrainingProviderIds: [],
      };
    },
    [toggleCheckedCadsAITraining](state, { payload: eventId }) {
      const checkedIds = state.checkedTrainingProviderIds;
      return {
        ...state,
        checkedTrainingProviderIds: checkedIds.includes(eventId)
          ? checkedIds.filter((id) => id !== eventId)
          : [...checkedIds, eventId],
      };
    },
    [uncheckAllCadsAITraining](state) {
      return {
        ...state,
        checkedTrainingProviderIds: [],
      };
    },
  },
  defaultState,
);
