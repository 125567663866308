import React from 'react';
import { Col } from 'reactstrap';
import { CP_PRODUCTS } from '@/shared/helpers/constants';
import AssignModuleWizard from './assign-module-wizard';
import AssignLearningPathWizard from '@/containers/Control-Panel/Assign/components/assign-wizard/assign-learning-path-wizard';
import AssignTrainingProviderWizard from '@/containers/Control-Panel/Assign/components/assign-wizard/assign-training-provider-wizard';

const AssignWizardPage = ({ selectedProduct }) => {
  return (
    <Col className="card-body">
      {selectedProduct === CP_PRODUCTS.MODULE && <AssignModuleWizard />}
      {selectedProduct === CP_PRODUCTS.LEARNING_PATH && <AssignLearningPathWizard />}
      {selectedProduct === CP_PRODUCTS.TRAINING_PROVIDER && <AssignTrainingProviderWizard />}
      {/*<AssignWizardEditForm /> TODO: remove this*/}
    </Col>
  );
};

export default AssignWizardPage;
