import * as Constants from '@/shared/helpers/constants';
import { handleActions } from 'redux-actions';
import {
  setAssignActiveTab,
  toggleCheckedItem,
  uncheckAllItem,
  setDataFromModule,
  setDataFromLearningPath,
  setDataFromTrainingProvider,
} from './actions';

const initialState = {
  checkedItemIds: [],
  activeTab: Constants.USERS,
  isFromModule: false,
  isFromLearningPath: false,
  isFromTrainingProvider: false,
};

export default handleActions(
  {
    [toggleCheckedItem](state, { payload: itemId }) {
      const checkedIds = state.checkedItemIds;
      return {
        ...state,
        checkedItemIds: checkedIds.includes(itemId)
          ? checkedIds.filter((id) => id !== itemId)
          : [...checkedIds, itemId],
      };
    },
    [uncheckAllItem](state) {
      return {
        ...state,
        checkedItemIds: [],
      };
    },
    [setAssignActiveTab](state, { payload: activeTab }) {
      return {
        ...state,
        activeTab,
      };
    },
    [setDataFromModule](state, { payload }) {
      return {
        ...state,
        isFromModule: payload,
        isFromLearningPath: false,
      };
    },
    [setDataFromLearningPath](state, { payload }) {
      return {
        ...state,
        isFromLearningPath: payload,
        isFromModule: false,
      };
    },
    [setDataFromTrainingProvider](state, { payload }) {
      return {
        ...state,
        isFromTrainingProvider: payload,
        isFromModule: false,
        isFromLearningPath: false,
      };
    },
  },
  initialState,
);
