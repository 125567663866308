import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loading from '@/shared/components/Loading';
import { paths } from '@/shared/helpers/paths';
import { JobPage } from './components/JobPage';
import './index.scss';

export const jobsBaseUrl = paths.CADS_AI.OPPORTUNITIES.JOBS;
export default function Jobs() {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path={jobsBaseUrl} component={JobPage} />
      </Switch>
    </Suspense>
  );
}
