import React, { useMemo } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { useQuestionUIContext } from './QuestionUIProvider';
import QuestionsTableWrapper from './table/QuestionTableWrapper';

export function QuestionCard() {
  const questionsUIContext = useQuestionUIContext();
  const questionsUIProps = useMemo(() => {
    return {
      openNewQuestionsDialog: questionsUIContext.openNewQuestionsDialog,
    };
  }, [questionsUIContext]);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Questions List</h3>
          <h3 className="page-subhead subhead">From here you can manage all the questions.</h3>
        </Col>
      </Row>
      <Row>
        <QuestionsTableWrapper />
      </Row>
    </Container>
  );
}
