import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const fetchProgramList = createAction('FETCH_PROGRAM_LIST');
export const setProgramList = createAction('SET_PROGRAM_LIST');
export const setProgramCount = createAction('SET_PROGRAM_COUNT');
export const setProgramError = createAction('SET_PROGRAM_ERROR');
export const setProgramFilters = createAction('SET_PROGRAM_FILTERS');
export const toggleCheckedPrograms = createAction('TOGGLE_CHECKED_PROGRAMS');
export const uncheckAllPrograms = createAction('UNCHECK_ALL_PROGRAMS');

export const setProgramOptionsList = createAction('SET_PROGRAM_OPTIONS_LIST');

export const toggleCheckedItemById = (programId) => (dispatch) => {
  dispatch(toggleCheckedPrograms(programId));
};

export const getProgramCount = (filter) => async (dispatch) => {
  try {
    const { data: res, isSuccess } = await api.programs.getProgramCount(filter);
    if (isSuccess) {
      dispatch(setProgramCount(res?.data?.count));
    } else {
      throw new Error('error while fetching program count');
    }
  } catch (error) {
    console.error('Error inside dashboard program count', error.message);
  }
};

export const uncheckAllSelectedPrograms = () => (dispatch) => {
  dispatch(uncheckAllPrograms());
};

export const getProgramsList = (filter) => async (dispatch) => {
  const defaultError = 'Error occurred getting programs list';
  try {
    dispatch(fetchProgramList());
    const { data, isSuccess } = await api.programs.getProgramList(filter);
    if (isSuccess) dispatch(setProgramList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setProgramError(error));
    console.error(defaultError, error.message);
  }
};

export const getProgramsListOptions = () => async (dispatch) => {
  const defaultError = 'Error occurred getting program options list';
  try {
    const { data, isSuccess } = await api.programs.getProgramOptionsList();
    if (isSuccess) dispatch(setProgramOptionsList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    console.error(defaultError, error.message);
  }
};
