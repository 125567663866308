import React, { Fragment } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import { Button } from 'reactstrap';
import EyeIcon from 'mdi-react/EyeIcon';
import PencilIcon from 'mdi-react/PencilIcon';
import BinIcon from 'mdi-react/BinIcon';
import { useJobUIContext } from '../JobUIProvider';
import JobTableColumns, { JobColumns } from './JobTableColumns';
import { JobTableSearchBar } from './JobTableSearchBar';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getJobsList, setJobFilters } from '../../redux/actions';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { defaultPaginationProps } from '@/shared/helpers';
import moment from 'moment';

const JobTable = () => {
  const jobUIContext = useJobUIContext();
  const dispatch = useDispatch();
  const {
    data: jobs,
    error,
    filters,
    loading,
    totalRecords,
  } = useSelector((state) => state.cadsAIJobs, shallowEqual);
  const {
    pageSize: rowsPerPage,
    pageNumber: page,
    sortBy: orderBy,
    isSortOrderDescending,
  } = filters;

  React.useEffect(() => {
    dispatch(getJobsList(filters));
  }, [filters]);

  const handleRequestSort = (event, property) => {
    dispatch(setJobFilters({ sortBy: property, isSortOrderDescending: !isSortOrderDescending }));
  };

  const handleChangePage = (event, currentPage) => {
    dispatch(setJobFilters({ pageSize: rowsPerPage, pageNumber: currentPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setJobFilters({ pageSize: +event.target.value, pageNumber: 1 }));
  };

  const emptyRows = page === Math.ceil(totalRecords / rowsPerPage) ? rowsPerPage - jobs.length : 0;

  const renderActionButtons = ({ id: jobId }) => (
    <div className="actions">
      <Button
        className="icon mb-2"
        color="success"
        outline
        onClick={() => jobUIContext.openViewJobDialog(jobId)}
      >
        <p>
          <EyeIcon /> View
        </p>
      </Button>
      <Button
        className="icon mb-2"
        color="primary"
        outline
        onClick={() => jobUIContext.openEditJobDialog(jobId)}
      >
        <p>
          <PencilIcon /> Edit
        </p>
      </Button>
      <Button
        className="icon mb-2"
        color="danger"
        outline
        onClick={() => jobUIContext.openDeleteJobDialog(jobId)}
      >
        <p>
          <BinIcon /> Delete
        </p>
      </Button>
    </div>
  );

  return (
    <Fragment>
      <div className="material-table__wrap">
        <JobTableSearchBar />
        <Table className="material-table">
          <JobTableColumns
            order={isSortOrderDescending ? 'desc' : 'asc'}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rowsPerPage}
          />
          <TableBody>
            {(loading || !!error) && (
              <TableRow>
                <TableCell colSpan={JobColumns.length}>
                  {loading && (
                    <div className={'text-center overflow-hidden'}>
                      <div className="panel__refresh position-relative" style={{ height: 75 }}>
                        <LoadingIcon />
                      </div>
                    </div>
                  )}
                  <div className={'text-center'}>
                    <p className={!!error ? 'm-5' : ''}>{error || 'Loading jobs...'}</p>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {!!!jobs.length && !loading && !error && (
              <TableRow style={{ height: 120 }}>
                <TableCell colSpan={JobColumns.length} align="center">
                  {filters.status === null && filters.search === ''
                    ? 'No jobs'
                    : 'No jobs with applied filters'}
                </TableCell>
              </TableRow>
            )}
            {jobs?.map((job) => {
              return (
                <TableRow className="material-table" tabIndex={-1} key={job.id}>
                  <TableCell
                    align="center"
                    className="material-table__cell"
                    role="button"
                    onClick={() => jobUIContext.openViewJobDialog(job?.id)}
                  >
                    {job?.title ?? '-'}
                  </TableCell>
                  <TableCell
                    align="center"
                    className="material-table__cell"
                    role="button"
                    onClick={() => jobUIContext.openViewJobDialog(job?.id)}
                  >
                    {`${job?.description.slice(0, 100)}...` ?? '-'}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell pr-5">
                    {job?.organizationName ?? '-'}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {job?.countryName}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {job?.jobTypeName}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {job?.userJobAppliedDate
                      ? moment(job?.userJobAppliedDate).format('DD/MM/yyyy')
                      : '-'}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {renderActionButtons(job)}
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={JobColumns.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        component={defaultPaginationProps.component}
        backIconButtonProps={defaultPaginationProps.backIconButton}
        nextIconButtonProps={defaultPaginationProps.nextIconButton}
        rowsPerPageOptions={defaultPaginationProps.rowsPerPage}
        className={defaultPaginationProps.className}
        dir={defaultPaginationProps.dir}
      />
    </Fragment>
  );
};

export default JobTable;
