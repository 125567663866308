import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Table } from 'reactstrap';
import { FormField } from '@/shared/components/form/hook/FormField';
import { emptyStringPattern } from '@/shared/helpers';
import DeleteIcon from 'mdi-react/DeleteIcon';
import { useForm } from 'react-hook-form';
import { SelectField } from '@/shared/components/form/Select';
import { contactFieldTypeOptions } from '../../options';
import { setConfigPageQuestionTypeData } from '../../redux/actions';
import { getDefaultContactFieldProps } from '../../redux/util';

const ContactFieldRow = ({ position, contact, pageId, questionId, contactInfo, dispatch }) => {
  const { control, errors, setValue } = useForm();

  const handleChange = (fieldName, fieldValue) => {
    setValue(fieldName, fieldValue);
    dispatch(
      setConfigPageQuestionTypeData({
        pageId,
        questionId,
        typeData: {
          contactInfo: {
            contactFields: contactInfo.contactFields.map((con) =>
              con.id !== contact.id ? con : { ...con, [fieldName]: fieldValue },
            ),
          },
        },
      }),
    );
  };

  const deleteContactField = () => {
    dispatch(
      setConfigPageQuestionTypeData({
        pageId,
        questionId,
        typeData: {
          contactInfo: {
            ...contactInfo,
            contactFields: contactInfo.contactFields.filter((con) => con.id !== contact.id),
          },
        },
      }),
    );
  };

  return (
    <tr key={contact.id}>
      <td>{position}</td>
      <td>
        <FormField
          name="fieldName"
          customClassName="form pl-3 pr-3"
          control={control}
          component="input"
          errors={errors}
          onChange={({ target: { value } }) => handleChange('fieldName', value)}
          rules={{
            required: 'This is required field',
            pattern: emptyStringPattern,
          }}
          defaultValue={contact.fieldName}
          isAboveError={true}
          placeholder="Enter contact field name"
        />
      </td>
      <td>
        <FormField
          name="fieldType"
          customClassName="form pl-3 pr-3"
          control={control}
          component={SelectField}
          errors={errors}
          defaultValue={contact.fieldType}
          onChange={(fieldType) => handleChange('fieldType', fieldType)}
          rules={{ required: 'This is required field' }}
          options={contactFieldTypeOptions}
          placeholder="Select contact field type"
          isAboveError
        />
      </td>
      <td>
        <DeleteIcon style={{ color: '#ff4861' }} role="button" onClick={deleteContactField} />
      </td>
    </tr>
  );
};

const ContactInformation = ({ pageId, questionId, typeData }) => {
  const dispatch = useDispatch();
  const { contactFields } = typeData.contactInfo ?? { contactFields: [] };

  const addNewContactField = () => {
    dispatch(
      setConfigPageQuestionTypeData({
        pageId,
        questionId,
        typeData: {
          contactInfo: {
            ...typeData.contactInfo,
            contactFields: [...contactFields, getDefaultContactFieldProps()],
          },
        },
      }),
    );
  };

  return (
    <div className="question_type_field">
      <Table striped className="mb-3">
        <thead>
          <tr>
            <th style={{ width: 50 }}>#</th>
            <th>Contact Field</th>
            <th>Field Type</th>
            <th style={{ width: 110 }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {contactFields.map((contact, idx) => (
            <ContactFieldRow
              position={idx + 1}
              key={contact.id}
              contact={contact}
              pageId={pageId}
              questionId={questionId}
              contactInfo={typeData.contactInfo}
              dispatch={dispatch}
            />
          ))}
        </tbody>
      </Table>
      <Button style={{ padding: '4px 10px' }} color="primary" onClick={addNewContactField} outline>
        Add contact Field
      </Button>
    </div>
  );
};

export default memo(ContactInformation);
