import React, { Fragment, useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import { Badge, Button } from 'reactstrap';
import { useOrderHistoryUIContext } from '../OrderHistoryUIProvider';
import OrderHistoryTableColumns, { OrderHistoryColumns } from './OrderHistoryTableColumns';
import { OrderHistoryTableSearchBar } from './OrderHistoryTableSearchBar';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getOrderHistoryList, setOrderHistoryFilters } from '../../redux/actions';
import EyeIcon from 'mdi-react/EyeIcon';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { defaultPaginationProps } from '@/shared/helpers';
import moment from 'moment';

const HelpTable = () => {
  const orderHistoryUIContext = useOrderHistoryUIContext();
  const dispatch = useDispatch();
  const {
    data: orderHistory,
    error,
    filters,
    loading,
    totalRecords,
  } = useSelector((state) => state.cadsAIOrdersHistory, shallowEqual);
  const {
    pageSize: rowsPerPage,
    pageNumber: page,
    sortBy: orderBy,
    isSortOrderDescending,
  } = filters;

  const [columns, setColumns] = useState(0);

  useEffect(() => {
    setColumns(OrderHistoryColumns.length);
  }, [OrderHistoryColumns.length]);

  React.useEffect(() => {
    dispatch(getOrderHistoryList(filters));
  }, [filters]);

  const handleRequestSort = (event, property) => {
    dispatch(
      setOrderHistoryFilters({
        sortBy: property,
        isSortOrderDescending: !isSortOrderDescending,
      }),
    );
  };

  const renderActionButtons = (orderId) => (
    <div className="actions">
      <Button
        className="icon mb-0"
        color="success"
        outline
        onClick={() => orderHistoryUIContext.openViewOrderHistoryDialog(orderId)}
      >
        <p>
          <EyeIcon /> View Invoice
        </p>
      </Button>
    </div>
  );

  const handleChangePage = (event, currentPage) => {
    dispatch(setOrderHistoryFilters({ pageSize: rowsPerPage, pageNumber: currentPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setOrderHistoryFilters({ pageSize: +event.target.value, pageNumber: 1 }));
  };

  const emptyRows = page === Math.ceil(totalRecords / rowsPerPage) ? rowsPerPage - orderHistory.length : 0;

  return (
    <Fragment>
      <div className="material-table__wrap">
        <OrderHistoryTableSearchBar />
        <Table className="material-table">
          <OrderHistoryTableColumns
            order={isSortOrderDescending ? 'desc' : 'asc'}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rowsPerPage}
          />
          <TableBody>
            {(loading || !!error) && (
              <TableRow>
                <TableCell colSpan={columns}>
                  {loading && (
                    <div className={'text-center overflow-hidden'}>
                      <div className="panel__refresh position-relative" style={{ height: 75 }}>
                        <LoadingIcon />
                      </div>
                    </div>
                  )}
                  <div className={'text-center'}>
                    <p className={!!error ? 'm-5' : ''}>{error || 'Loading order history...'}</p>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {!!!orderHistory?.length && !loading && !error && (
              <TableRow style={{ height: 120 }}>
                <TableCell colSpan={columns} align="center">
                  {filters.status === null && filters.search === ''
                    ? 'No order history'
                    : 'No order history with applied filters'}
                </TableCell>
              </TableRow>
            )}
            {orderHistory?.map((order) => {
              return (
                <TableRow className="material-table" tabIndex={-1} key={order?.id}>
                  <TableCell align="center" className="material-table__cell">
                    {order?.paymentId}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {order.orderId}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {order.amount}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {order.purchaseDate ? moment(order.purchaseDate).format('DD/MM/yyyy') : '-'}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    <Badge
                      color={`${order?.status === 'paid' ? 'primary' : 'secondary'}`}
                      style={{ marginRight: 24 }}
                    >
                      {order?.status}
                    </Badge>
                  </TableCell>
                  <TableCell
                    align="center"
                    className="material-table__cell"
                    style={{ minWidth: 250 }}
                  >
                    {renderActionButtons(order?.paymentId)}
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={columns} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        component={defaultPaginationProps.component}
        backIconButtonProps={defaultPaginationProps.backIconButton}
        nextIconButtonProps={defaultPaginationProps.nextIconButton}
        rowsPerPageOptions={defaultPaginationProps.rowsPerPage}
        className={defaultPaginationProps.className}
        dir={defaultPaginationProps.dir}
      />
    </Fragment>
  );
};

export default HelpTable;
