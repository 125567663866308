import React from 'react';
import { Row, Col } from 'reactstrap';
import Panel from '@/shared/components/Panel';
import LearningStacked from './LearningStacked';
import AspirationParticipation from './AspirationParticipation';
import LearningDetails from './LearningDetails';

const LearningSection = () => {
  return (
    <Row className="mt-3">
      <Panel lg={12} xl={12} title={'Learning'}>
        <AspirationParticipation />
      </Panel>
      <LearningDetails />
      <Panel lg={12} xl={12} title={'Learning'}>
        <LearningStacked />
      </Panel>
    </Row>
  );
};

export default LearningSection;
