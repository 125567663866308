import { handleActions } from 'redux-actions';
import {
  fetchCadsAIUsersList,
  setCadsAIUsersError,
  setCadsAIUsersList,
  setCohortId,
  setOrganizationId,
  setUsersFilters,
  toggleCheckedUsers,
  uncheckAllUsers,
} from './action';

const defaultState = {
  loading: false,
  checkedUsersIds: [],
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    name: '',
    email: '',
    isSortOrderDescending: false,
    status: null,
    cohortId: null,
    organizationId: null,
  },
  totalRecords: 0,
  data: [],
  options: [],
  error: null,
};

export default handleActions(
  {
    [fetchCadsAIUsersList](state) {
      return {
        ...state,
        loading: true,
        data: [],
      };
    },
    [setCadsAIUsersList](state, { payload }) {
      return {
        ...state,
        loading: false,
        data: payload?.data?.records,
        totalRecords: payload?.data?.totalRecords,
        error: null,
      };
    },
    [setCadsAIUsersError](state, { payload }) {
      return {
        ...state,
        data: [],
        loading: false,
        totalPages: defaultState.totalPages,
        error: payload?.message,
      };
    },
    [setUsersFilters](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
        checkedUsersIds: [],
      };
    },
    [setCohortId](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          cohortId: payload,
        },
      };
    },
    [setOrganizationId](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          organizationId: payload,
        },
      };
    },
    [toggleCheckedUsers](state, { payload: userId }) {
      const checkedIds = state.checkedUsersIds;
      return {
        ...state,
        checkedUsersIds: checkedIds.includes(userId)
          ? checkedIds.filter((id) => id !== userId)
          : [...checkedIds, userId],
      };
    },
    [uncheckAllUsers](state) {
      return {
        ...state,
        checkedUsersIds: [],
      };
    },
  },
  defaultState,
);
