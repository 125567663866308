import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loading from '@/shared/components/Loading';
import { paths } from '@/shared/helpers/paths';
import './index.scss';
import AssignedPage from './components/AssignedPage';

const assignedBaseUrl = paths.CADS_AI.LEARNING.ASSIGNED;
export default function Assigned() {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path={assignedBaseUrl} component={AssignedPage} />
      </Switch>
    </Suspense>
  );
}
