import React, { useState, useEffect } from 'react';
import LoadingIcon from 'mdi-react/LoadingIcon';
import api from '@/utils/apiClient';
import TextEditor from '@/shared/components/text-editor/TextEditor';
import { Button, ButtonToolbar } from 'reactstrap';
import { getSanitizeHTML } from '@/shared/components/SanitizeHTML';
import { emptyStringPattern, YesNoOptions } from '@/shared/helpers';
import FormField from '@/shared/components/form/hook/FormField';
import { useForm } from 'react-hook-form';
import { convertImageFileToBase64, convertUrlToFile } from '@/shared/helpers';
import renderDropZoneField from '@/shared/components/form/DropZone';
import { RadioButtonField } from '@/shared/components/form/RadioButton';

const SponsorshipEditDialogBody = ({ modal, isEdit, id }) => {
  const initialValues = {
    title: '',
    slug: '',
    publishDate: '',
    link: '',
    isPublished: 'false',
    status: 'active',
    boostScore: '',
    description: '',
  };

  const [data, setData] = useState(initialValues);

  const [state, setState] = useState({
    loading: false,
    fetchingSponosrship: false,
    newSponsorshipAdded: false,
    errorMessage: '',
  });

  useEffect(() => {
    if (isEdit && id) {
      setDefaultData(+id);
    }
  }, [isEdit, id]);

  const setDefaultData = async (sponsorshipId) => {
    try {
      const { data: res, isSuccess } = await api.cadsAISponsorship.getSponsorshipById(
        sponsorshipId,
      );
      const sponsorshipData = res.data;
      if (id) {
        setData({
          ...sponsorshipData,
        });
        ['title', 'slug', 'link', 'boostScore', 'description', 'tags'].forEach((field) =>
          setValue(field, sponsorshipData?.[field]),
        );
        setValue('publishDate', sponsorshipData?.publishDate?.substr(0, 10));
        setValue('isPublished', sponsorshipData?.isPublished ? 'true' : 'false');
        setValue('status', sponsorshipData?.status ? 'active' : 'inactive');
      }
    } catch ({ message }) {
      console.error('Sponsorship edit error : ', message);
      setState({
        ...state,
        errorMessage: 'Error while getting existing sponsorship details',
      });
    }
  };

  const constructApiRequestData = async ({
    description,
    title,
    slug,
    tags,
    boostScore,
    link,
    image,
    imageThumbnailUrl,
    duration,
    publishDate,
  }) => {
    const base64Img = await convertImageFileToBase64(image?.[0]);
    const base64ImgThumb = await convertImageFileToBase64(imageThumbnailUrl?.[0]);
    return {
      description,
      title,
      slug,
      tags,
      boostScore,
      link,
      publishDate,
      isPublished: isPublished,
      status: status === 'active' ? true : false,
      postType: 'sponsorships',
      image: base64Img?.split(',')?.[1],
      imageThumbnail: base64ImgThumb?.split(',')?.[1],
    };
  };
  const handleAddEditSponsorship = async (sponsorshipData) => {
    setState({ ...state, loading: true });
    const newsponsorshipData = await constructApiRequestData(sponsorshipData);
    const defaultError = `Error occurred while ${isEdit ? 'Updating' : 'Adding new'} sponsorship`;
    try {
      const { data: res, isSuccess } = isEdit
        ? await api.cadsAISponsorship.updateSponsorship(newsponsorshipData)
        : await api.cadsAISponsorship.addSponsorship(newsponsorshipData);
      if (isSuccess) {
        setState({ ...state, newSponsorshipAdded: true, loading: false });
        if (!isEdit) setData(initialValues);
      } else {
        throw new Error(data?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({
        ...state,
        loading: false,
        newSponsorshipAdded: false,
        errorMessage: error.message,
      });
    }
  };

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ defaultValues: initialValues });
  const isPublished = watch('isPublished');
  const setIsPublished = (value) => setValue('isPublished', value);

  const status = watch('status');
  const setStatus = (value) => setValue('status', value);

  return (
    <div>
      {/*Add/Edit Sponsorship Form Status*/}
      <div className="ml-4">
        {state.loading && (
          <div>
            <div className="panel__refresh position-relative" style={{ height: 75, width: 20 }}>
              <LoadingIcon />
              <div style={{ width: 200, padding: '23px 30px' }}>
                {isEdit ? 'Updating' : 'Adding new'} sponsorship...
              </div>
            </div>
          </div>
        )}
        {(state.newSponsorshipAdded || !!state.errorMessage) && (
          <>
            <table className="mt-3">
              <tr>
                <td>
                  {state.newSponsorshipAdded ? (
                    <span className="lnr lnr-thumbs-up modal__title-icon success" />
                  ) : (
                    <span className="lnr lnr-cross-circle modal__title-icon error" />
                  )}
                </td>
                <td className="pl-2">
                  {state.newSponsorshipAdded
                    ? `${isEdit ? 'Sponsorship Updated' : 'New Sponsorship Added'} Successfully`
                    : state.errorMessage}
                </td>
              </tr>
            </table>
          </>
        )}
      </div>
      <div className="modal__body add-edit-learning-path dropzone-border">
        <form className={`form form--horizontal`} onSubmit={handleSubmit(handleAddEditSponsorship)}>
          {!state.newSponsorshipAdded ? (
            <div className={'w-100 p-3'}>
              <div className="form__form-group">
                <span className="form__form-group-label">Title</span>
                <div className="form__form-group-field">
                  <FormField
                    name="title"
                    control={control}
                    component="input"
                    errors={errors}
                    rules={{
                      required: 'This is required field',
                      pattern: emptyStringPattern,
                    }}
                    defaultValue=""
                    isAboveError={true}
                    placeholder="Enter Title"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Slug</span>
                <div className="form__form-group-field">
                  <FormField
                    name="slug"
                    control={control}
                    component="input"
                    defaultValue=""
                    placeholder="Enter Slug"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Description</span>
                <div className="form__form-group-field">
                  {/*TODO: set html after sanitizing for all text editor*/}
                  {(!isEdit || data.description) && (
                    <FormField
                      control={control}
                      name="description"
                      component={() => (
                        <TextEditor
                          defaultContent={getSanitizeHTML(data.description)}
                          onChange={(value) => setValue('description', value)}
                        />
                      )}
                    />
                  )}
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Image</span>
                <div className="form__form-group-field">
                  <FormField name="image" control={control} component={renderDropZoneField} />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Image Thumbnail</span>
                <div className="form__form-group-field">
                  <FormField
                    name="imageThumbnailUrl"
                    control={control}
                    component={renderDropZoneField}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Tags</span>
                <div className="form__form-group-field">
                  <FormField
                    name="tags"
                    control={control}
                    component="input"
                    defaultValue=""
                    placeholder="Enter tags"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Boost Score</span>
                <div className="form__form-group-field">
                  <FormField
                    name="boostScore"
                    control={control}
                    component="input"
                    min={0}
                    type="number"
                    defaultValue=""
                    placeholder="Enter Boost Score"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Link</span>
                <div className="form__form-group-field">
                  <FormField
                    name="link"
                    control={control}
                    component="input"
                    defaultValue=""
                    placeholder="Enter Link"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Is Published</span>
                <div className="form__form-group-field mt-2">
                  <RadioButtonField
                    radioValue={`${YesNoOptions[0].value}`}
                    name={YesNoOptions[0].label}
                    onChange={setIsPublished}
                    value={isPublished}
                    className="colored-click mr-5"
                    label={YesNoOptions[0].label}
                  />
                  <RadioButtonField
                    radioValue={`${YesNoOptions[1].value}`}
                    name={YesNoOptions[1].label}
                    onChange={setIsPublished}
                    value={isPublished}
                    className="colored-click mr-5"
                    label={YesNoOptions[1].label}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Status</span>
                <div className="form__form-group-field mt-2">
                  <RadioButtonField
                    radioValue={'active'}
                    name={status}
                    onChange={setStatus}
                    value={status}
                    className="colored-click mr-5"
                    label={'Active'}
                  />
                  <RadioButtonField
                    radioValue={`inactive`}
                    name={status}
                    onChange={setStatus}
                    value={status}
                    className="colored-click mr-5"
                    label={'Inactive'}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Publish Date</span>
                <div className="form__form-group-field">
                  <FormField
                    name="publishDate"
                    type="date"
                    control={control}
                    errors={errors}
                    defaultValue=""
                    isAboveError={true}
                    placeholder="Enter the publish date"
                  />
                </div>
              </div>
              <ButtonToolbar className="modal__footer btn-toolbar">
                <>
                  <Button
                    color="primary"
                    type="submit"
                    onClick={() => handleAddEditSponsorship(data)}
                  >
                    Submit
                  </Button>
                  <Button
                    color="secondary"
                    type="button"
                    className="modal_cancel btn btn-secondary"
                    onClick={() => modal.hide()}
                  >
                    Close
                  </Button>
                </>
              </ButtonToolbar>
            </div>
          ) : (
            <div className={'p-3'}>
              <ButtonToolbar className="modal__footer btn-toolbar">
                <Button color="primary" type="button" onClick={() => modal.hide()}>
                  Okay
                </Button>
              </ButtonToolbar>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default SponsorshipEditDialogBody;
