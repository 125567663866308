import { sendRequest } from '../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const unifiedAPIServiceBase =
  process.env.REACT_APP_CADS_AI_API + process.env.REACT_APP_ADMIN_ENDPOINT;

const questionsEndpoints = {
  ADD_QUESTION: `${unifiedAPIServiceBase}/insertQuestion`,
  EDIT_QUESTION: `${unifiedAPIServiceBase}/editQuestions`,
  DELETE_QUESTION: `${unifiedAPIServiceBase}/deleteQuestion`,
  GET_QUESTION: `${unifiedAPIServiceBase}/getQuestion`,
  GET_QUESTION_LIST: `${unifiedAPIServiceBase}/getQuestionsList`,
  GET_QUESTION_LIST_CSV: `${unifiedAPIServiceBase}/getQuestionListCsv`,

  GET_QUESTION_LEVEL_LIST: `${unifiedAPIServiceBase}/getQuestionLevelList`,
  GET_QUESTION_TYPE_LIST: `${unifiedAPIServiceBase}/getQuestionTypeList`,
  GET_QUESTION_TAG_LIST: `${unifiedAPIServiceBase}/getQuestionTagList`,

  DELETE_QUESTION_OPTION: `${unifiedAPIServiceBase}/deleteQuestionOption`,
  EDIT_QUESTION_OPTIONS: `${unifiedAPIServiceBase}/editQuestionOptions`,
  ADD_QUESTION_OPTIONS: `${unifiedAPIServiceBase}/insertQuestionOption`,
};

/**
 * @param filters to filter data based on some parameters
 **/
const getQuestionList = (filters) => {
  // return getMockQuestionsList(); // TODO: remove this when the actual api is ready
  return sendRequest({
    fullUrl: questionsEndpoints.GET_QUESTION_LIST,
    method: httpMethod.GET,
    params: filters,
  });
};

/**
 * @param {number} questionId id of question to fetch more details about the question
 */
const getQuestion = (questionId) => {
  // return getMOckQuestionById(questionId);
  return sendRequest({
    fullUrl: questionsEndpoints.GET_QUESTION,
    method: httpMethod.GET,
    params: { id: questionId },
  });
};

/**
 * @param questionData Information required for adding questions.
 * @param {number} questionData.questionsTypeId
 * @param {number} questionData.skillsId
 * @param {number} questionData.questionLevelId
 * @param {string} questionData.questionMain
 * @param {string} questionData.questionExtra
 * @param {string} questionData.image
 * @param {boolean} questionData.allowSkip
 * @param {boolean} questionData.allowOther
 */
const addQuestion = (questionData) => {
  return sendRequest({
    fullUrl: questionsEndpoints.ADD_QUESTION,
    method: httpMethod.POST,
    data: questionData,
  });
};

/**
 * @param questionData updated questions data.
 * @param {number} questionData.id existing question id
 * @param {string} questionData.name
 * @param {string} questionData.description
 * @param {string} questionData.competencies
 * @param {number} questionData.organizationId
 */
const updateQuestion = (questionData) => {
  return sendRequest({
    fullUrl: questionsEndpoints.EDIT_QUESTION,
    method: httpMethod.PUT,
    data: questionData,
  });
};

/**
 * @param {number} questionId id of question to delete the question
 */
const deleteQuestion = (questionId) => {
  return sendRequest({
    fullUrl: questionsEndpoints.DELETE_QUESTION,
    method: httpMethod.DELETE,
    params: { id: questionId },
  });
};

/**
 * @param filter to filter data based on some parameters
 * @param {number} filter.profile profile id to filter
 * @param {Array<number>} filter.domains array of domain id to filter
 * @param {string} filter.search to filter questions by search text
 * @param {string} filter.sortBy to sort based on any field
 * @param {boolean} filter.isSortOrderDescending true if search direction is descending
 **/
const getQuestionListCSV = (filter) => {
  return sendRequest({
    fullUrl: questionsEndpoints.GET_QUESTION_LIST_CSV,
    method: httpMethod.GET,
    params: filter,
  });
};

/**
 * Gets list of question levels
 * */
const getQuestionLevelList = () => {
  // return mockQuestionLevelList();
  return sendRequest({
    fullUrl: questionsEndpoints.GET_QUESTION_LEVEL_LIST,
    method: httpMethod.GET,
  });
};

/**
 * Gets list of question types
 * */
const getQuestionTypeList = () => {
  // return mockQuestionTypeList();
  return sendRequest({
    fullUrl: questionsEndpoints.GET_QUESTION_TYPE_LIST,
    method: httpMethod.GET,
  });
};

/**
 * Gets list of question tags
 * */
const getQuestionTagList = () => {
  return sendRequest({
    fullUrl: questionsEndpoints.GET_QUESTION_TAG_LIST,
    method: httpMethod.GET,
  });
};

/**
 * @param editedOptions edited options/answers data
 * @param {number} editedOptions.questionId question id for which options to be updated
 * @param {Array<{id: number, text:string, isCorrect: boolean}>} editedOptions.answers updated options
 */
const editQuestionOptions = (editedOptions) => {
  // return {isSuccess: true};
  return sendRequest({
    fullUrl: questionsEndpoints.EDIT_QUESTION_OPTIONS,
    method: httpMethod.PUT,
    data: editedOptions,
  });
};

/**
 * @param addOptions new options to add to the question
 * @param {number} addOptions.questionId question id for which options to be added
 * @param {Array<{text:string, isCorrect: boolean}>} addOptions.answers options to add
 * */
const addQuestionOptions = (addOptions) => {
  // return {isSuccess: true};
  return sendRequest({
    fullUrl: questionsEndpoints.ADD_QUESTION_OPTIONS,
    method: httpMethod.POST,
    data: addOptions,
  });
};

/**
 * @param {number} questionId id of question to delete question option
 * @param {number} optionId denotes option id
 */
const deleteQuestionOption = (questionId, optionId) => {
  return sendRequest({
    fullUrl: questionsEndpoints.DELETE_QUESTION_OPTION,
    method: httpMethod.DELETE,
    params: {questionId, answerId: optionId},
  });
};

export default {
  addQuestion,
  updateQuestion,
  getQuestion,
  getQuestionList,
  getQuestionListCSV,
  deleteQuestion,

  getQuestionLevelList,
  getQuestionTypeList,
  getQuestionTagList,

  deleteQuestionOption,
  addQuestionOptions,
  editQuestionOptions,
};

const getMockQuestionsList = () =>
  new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [''],
            data: {
              totalRecords: 1,
              currentPage: 1,
              pageSize: 10,
              records: [
                {
                  id: 1,
                  skill: {
                    id: 1,
                    name: 'Machine Learning for skill verification',
                  },
                  questionLevel: {
                    id: 1,
                    name: 'Level 1',
                  },
                  questionMain:
                    'Which of the following statements explains the cohesion as a parameter used to calculate Silhouette Coefficient? Select all that apply',
                  questionExtra:
                    'I.\tTreemap\nII.\tStacked Bar Chart\nIII.\tBox Plot\nIV.\tHistogram',
                  imageUrl:
                    'https://admin.talentspy.staging.cads.ai/survey-assets/a5fea339-0e0b-4499-b19e-b1af6e2913fa.png',
                  allowSkip: true,
                  allowOther: true,
                  correctAnswers: [
                    'Cohesion measures how similar an object is to its own cluster.',
                    'Cohesion is the mean distance between a sample and all other points in its cluster All the choices',
                  ],
                  allowMultipleAnswer: true,
                  submittedAnswers: 10,
                  correctAnswerRate: 50,
                  status: true,
                },
                {
                  id: 2,
                  skill: {
                    id: 2,
                    name: 'NoSQL Module G1 ',
                  },
                  questionLevel: {
                    id: 1,
                    name: 'Level 2',
                  },
                  questionMain: 'Which scenario is best suited for NoSQL?',
                  questionExtra: '',
                  imageUrl: '',
                  allowSkip: true,
                  allowOther: true,
                  correctAnswers: ['When dealing with large data sets.'],
                  allowMultipleAnswer: true,
                  submittedAnswers: 10,
                  correctAnswerRate: 50,
                  status: true,
                },
              ],
            },
          },
        }),
      500,
    );
  });

const mockQuestionTypeList = () =>
  new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [''],
            data: [
              { id: 1, name: 'Single Answer' },
              { id: 2, name: 'Multiple Answer' },
            ],
          },
        }),
      100,
    );
  });

const mockQuestionLevelList = () =>
  new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [''],
            data: [
              { id: 1, name: 'Level 1' },
              { id: 2, name: 'Level 2' },
            ],
          },
        }),
      100,
    );
  });

const getMOckQuestionById = () =>
  new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [''],
            data: {
              id: 1,
              questionType: { id: 2, name: 'Multiple Answer' }, //{id: 1, name: 'Single Answer'},
              skill: { id: 8, name: 'Redux' },
              questionLevel: { id: 1, name: 'Level 1' },
              questionMain:
                'Which of the following statements explains the cohesion as a parameter used to calculate Silhouette Coefficient? Select all that apply',
              questionExtra: 'I.\tTreemap\nII.\tStacked Bar Chart\nIII.\tBox Plot\nIV.\tHistogram',
              imageUrl:
                'https://leverageedu.com/blog/wp-content/uploads/2020/05/Scope-of-Public-Administration-800x500.png',
              allowSkip: false,
              allowOther: true,
              correctAnswerRate: 25,
              totalAnswers: 4,
              correctAnswers: 1,
              incorrectAnswer: 3,
              skipped: 1,
              answers: [
                { id: 1, text: 'This is answer one', isCorrect: false },
                { id: 2, text: 'This is answer two', isCorrect: true },
                {id: 3, text: 'This is answer three', isCorrect: false},
              ],
              status: true,
            },
          },
        }),
        100,
    );
  });
