import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableCell } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import moment from 'moment';

const FollowedUsers = ({ followedUsers }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{ minWidth: 70 }}>Order</TableCell>
          <TableCell style={{ minWidth: 70 }}>Created Date</TableCell>
        </TableRow>
      </TableHead>
      {!followedUsers?.length && (
        <TableRow>
          <TableCell colSpan={6} align="center">
            <p className="mt-3 mb-3">There are no followed users.</p>
          </TableCell>
        </TableRow>
      )}
      {followedUsers?.map((a) => (
        <TableRow>
          {/* <TableCell>
            <img className="job-profile-image" alt="Job Image" src={a?.imageUrl} />
          </TableCell> */}
          <TableCell>{a?.id ?? '-'}</TableCell>
          <TableCell>
            {a?.createdDate ? moment(a?.createdDate).format('DD/MM/yyyy') : '-'}
          </TableCell>
        </TableRow>
      ))}
    </Table>
  );
};

export default FollowedUsers;
