import React from 'react';

const PanelCard = ({
  count,
  isCompletedSurveyCount = false,
  onClick,
  extras = [],
  svg,
  text,
  colorClass,
  selectedPanel,
}) => {
  return (
    <div
      className={selectedPanel ? `selected-panel ${colorClass}` : `datastar-card ${colorClass}`}
      onClick={onClick}
    >
      <div className="ds-card-icon">{svg}</div>
      <div className="card-txt">
        <p>{text}</p>
        <strong>{count}</strong>
        {isCompletedSurveyCount && (
          <div className="extra-counts">
            {extras[0]} Smart Skills & {extras[1]} Pre-Assessment
          </div>
        )}
      </div>
    </div>
  );
};

export default PanelCard;
