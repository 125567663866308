import React, { useMemo } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { useBaselineThresholdUIContext } from './BaselineThresholdUIProvider';
import BaselineThresholdTableWrapper from './table/BaselineThresholdTableWrapper';

export function BaselineThresholdCard() {
  const baselineThresholdsUIContext = useBaselineThresholdUIContext();
  const baselineThresholdsUIProps = useMemo(() => {
    return {
      openBaselineThresholdConfigDialog:
        baselineThresholdsUIContext.openBaselineThresholdConfigDialog,
    };
  }, [baselineThresholdsUIContext]);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Baseline Threshold Settings</h3>
          <h3 className="page-subhead subhead">
            From here you can manage all the baseline thresholds settings for organizations.
          </h3>
        </Col>
      </Row>
      <Row>
        <BaselineThresholdTableWrapper />
      </Row>
    </Container>
  );
}
