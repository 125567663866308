import { sendRequest } from '../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const trainingProviderEndpoints = {
  GET_ASSIGNED_ORGS_TP: `/getAllAssignedOrgsTrainingProviderDtls`,
  UNASSIGN_TP_FROM_ORG: `/unassignTrainingProvidersFromOrgs`,
};

const getAssignedUserTP = (filter) => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          status: 200,
          messages: [],
          isSuccess: true,
          data: {
            totalRecords: 5,
            data: [
              {
                email: 'mock1@gmail.com',
                jobseekerId: 390,
                trainingProviderList: [
                  {
                    allowRecommendation: true,
                    fullName: 'Mock 1',
                    group: { id: 2, name: 'EDA' },
                    imageUrl:
                      'https://general-assets.sgp1.digitaloceanspaces.com/training-icons/eda_210616.png',
                    trainingProviderRecomId: 13,
                    tpId: 3,
                    main: true,
                    shortName: 'EDA',
                  },
                ],
                userFullName: 'Mock user 1',
              },
              {
                email: 'mock2@gmail.com',
                jobseekerId: 420,
                trainingProviderList: [
                  {
                    allowRecommendation: true,
                    fullName: 'Mock 2',
                    group: { id: 2, name: 'EDA' },
                    imageUrl:
                      'https://general-assets.sgp1.digitaloceanspaces.com/training-icons/eda_210616.png',
                    trainingProviderRecomId: 12,
                    tpId: 3,
                    main: true,
                    shortName: 'EDA',
                  },
                ],
                userFullName: 'Mock user 2',
              },
              {
                email: 'mock3@gmail.com',
                jobseekerId: 175,
                trainingProviderList: [
                  {
                    allowRecommendation: true,
                    fullName: 'Mock 3',
                    group: { id: 2, name: 'EDA' },
                    imageUrl:
                      'https://general-assets.sgp1.digitaloceanspaces.com/training-icons/eda_210616.png',
                    trainingProviderRecomId: 30,
                    tpId: 3,
                    main: true,
                    shortName: 'EDA',
                  },
                ],
                userFullName: 'Mock user 3',
              },
            ],
          },
        }),
      500,
    );
  });
};

const getAssignedOrganizationTP = (filter) => {
  return sendRequest(
    {
      url: trainingProviderEndpoints.GET_ASSIGNED_ORGS_TP,
      method: httpMethod.GET,
      params: { ...filter },
    },
    process.env.REACT_APP_CONTROL_PANEL_ENDPOINT,
  );
};

const unAssignTPToOrganization = (reqData) => {
  return sendRequest(
    {
      url: trainingProviderEndpoints.UNASSIGN_TP_FROM_ORG,
      method: httpMethod.DELETE,
      data: reqData,
    },
    process.env.REACT_APP_CONTROL_PANEL_ENDPOINT,
  );
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          status: 200,
          messages: [],
          isSuccess: true,
        }),
      500,
    );
  });
};

const unAssignTPToUser = (reqData) => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          status: 200,
          messages: [],
          isSuccess: true,
        }),
      500,
    );
  });
};

export default {
  getAssignedUserTP,
  getAssignedOrganizationTP,
  unAssignTPToOrganization,
  unAssignTPToUser,
};
