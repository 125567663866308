import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const setCadsAiLearningPathFetching = createAction('SET_CADS_AI_LEARNING_PATH_FETCHING');
export const setCadsAiLearningPathError = createAction('SET_CADS_AI_LEARNING_PATH_ERROR');
export const setCadsAiAssignLearningPathList = createAction(
  'SET_CADS_AI_ASSIGN_LEARNING_PATH_LIST',
);
export const setCadsAiLearningPathFilters = createAction('SET_CADS_AI_LEARNING_PATH_LIST_FILTER');
export const toggleCadsAiCheckedLearningPath = createAction('TOGGLE_CADS_AI_CHECKED_LEARNING_PATH');
export const clearCadsAiCheckedLearningPath = createAction('CLEAR_CADS_AI_SELECTED_LEARNING_PATH');
export const setCadsAiLearningPathGroupOptions = createAction(
  'SET_CADS_AI_LEARNING_PATH_GROUP_OPTIONS',
);
export const setCadsAiLearningPathSkills = createAction('SET_CADS_AI_LEARNING_PATH_GROUP_SKILLS');
export const setCadsAiLearningPathOtherSkills = createAction(
  'SET_CADS_AI_LEARNING_PATH_OTHER_SKILLS',
);
export const setCadsAiLearningPathTypeOptions = createAction(
  'SET_CADS_AI_LEARNING_PATH_TYPE_OPTIONS',
);
export const clearCadsAiLearningPathFilters = createAction('CLEAR_CADS_AI_LEARNING_PATH_FILTERS');
export const updateCadsAiLearningPathInList = createAction('UPDATE_CADS_AI_LEARNING_PATH_IN_LIST');
export const toggleCheckedCadsAiLP = createAction('CADS_AI_MANAGE_TOGGLE_CHECKED_LEARNING_PATH');
export const uncheckAllLearningPath = createAction('CADS_AI_MANAGE_UNCHECK_ALL_LEARNING_PATH');
export const toggleCheckedLearningPath = createAction(
  'CADS_AI_MANAGE_TOGGLE_CHECKED_ASSIGN_LEARNING_PATH',
);
export const clearCheckedLearningPath = createAction('CADS_AI_MANAGE_CLEAR_SELECTED_LEARNING_PATH');
export const setModuleOptions = createAction('CADS_AI_MANAGE_SET_MODULE_OPTIONS');
export const setLearningPathOptions = createAction('CADS_AI_SET_LEARNING_PATH_OPTIONS');

export const toggleCheckedItemById = (itemId) => (dispatch) => {
  dispatch(toggleCheckedCadsAiLP(itemId));
};

export const uncheckAllSelectedLearningPath = () => (dispatch) => {
  dispatch(uncheckAllLearningPath());
};

export const getCadsAILearningPathsList = (filter) => async (dispatch) => {
  const defaultError = `Error occurred getting learning path list`;
  dispatch(setCadsAiLearningPathFetching());
  try {
    const { data, isSuccess } = await api.cadsAILearningPathApi.getCadsAiLearningPathList(filter);
    if (isSuccess) dispatch(setCadsAiAssignLearningPathList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setCadsAiLearningPathError(error.message));
    console.error(defaultError, error.message);
  }
};

export const getCadsAiLearningPathGroupOptionList = () => async (dispatch) => {
  const defaultError = 'Error occurred getting learning path group option list';
  try {
    const { data, isSuccess } = await api.cadsAILearningPathApi.getCadsAiLearningPathOption();
    if (isSuccess) dispatch(setCadsAiLearningPathGroupOptions(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    console.error(defaultError, error.message);
  }
};

export const getCadsAiLearningPathSkills = () => async (dispatch) => {
  const defaultError = 'Error occurred getting learning path group option list';
  try {
    const { data, isSuccess } = await api.cadsAILearningPathApi.getCadsAiLearningPathSkills();
    if (isSuccess) dispatch(setCadsAiLearningPathSkills(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    console.error(defaultError, error.message);
  }
};

export const getLearningPathTypeOptions = () => async (dispatch) => {
  const defaultError = 'Error occurred getting learning path group option list';
  try {
    const { data, isSuccess } = await api.cadsAILearningPathApi.getLearningPathTypeOptions();
    if (isSuccess) dispatch(setCadsAiLearningPathTypeOptions(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    console.error(defaultError, error.message);
  }
};

export const getLearningPathOtherSkills = () => async (dispatch) => {
  const defaultError = 'Error occurred getting learning path group option list';
  try {
    const { data, isSuccess } = await api.cadsAILearningPathApi.getLearningPathOtherSkills();
    if (isSuccess) dispatch(setCadsAiLearningPathOtherSkills(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    console.error(defaultError, error.message);
  }
};

export const getLearningPathModuleOptions = () => async (dispatch) => {
  const defaultError = 'Error occurred getting learning path group option list';
  try {
    const { data, isSuccess } = await api.cadsAILearningPathApi.getLearningPathModuleOptions();
    if (isSuccess) dispatch(setModuleOptions(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    console.error(defaultError, error.message);
  }
};

export const getAllLearningPathOptions = () => async (dispatch) => {
  const defaultError = `Error occurred getting learningPath options list`;
  try {
    const { data, isSuccess } = await api.cadsAIPrograms.getLearningPathOptions();
    if (isSuccess) dispatch(setLearningPathOptions(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    console.error(defaultError, error.message);
  }
};
