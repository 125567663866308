import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Card, Row, CardBody } from 'reactstrap';
import StepOne from './steps/StepOne';
import StepTwo from './steps/StepTwo';
import StepThree from './steps/StepThree';

export function getStepFields(page) {
  switch (page) {
    case 1:
      return ['companyName', 'regNo', 'address', 'country', 'noOfEmp', 'email', 'subdomain'];
    case 2:
      return ['firstName', 'lastName', 'role', 'companyOfficePhoneNo', 'companyOfficeMobileNo'];
    case 3:
      return ['notes'];
    default:
      return [];
  }
}

export function extractFieldData(page, data) {
  const obj = {};
  getStepFields(page).forEach((key) => (obj[key] = data[key]));
  return obj;
}

const WizardForm = ({ onSubmit, data, setData, initialValues }) => {
  const [page, setPage] = useState(1);

  const nextPage = (newData) => {
    setData({ ...data, ...newData });
    setPage(page + 1);
  };

  const previousPage = (newData) => {
    setData({ ...data, ...newData });
    setPage(page - 1);
  };

  const clearForm = () => {
    setData(initialValues);
    setPage(1);
  };

  const submitHandler = (newData) => {
    const dataToSubmit = { ...data, ...newData };
    setData(dataToSubmit);
    onSubmit(dataToSubmit, clearForm);
  };

  return (
    <Row>
      <Col md={12} lg={12}>
        <Card className="pb-0">
          <CardBody className="wizard">
            <div className="wizard__steps">
              <div className={`wizard__step${page === 1 ? ' wizard__step--active' : ''}`}>
                <p>Company Profile</p>
              </div>
              <div className={`wizard__step${page === 2 ? ' wizard__step--active' : ''}`}>
                <p>Contact Person</p>
              </div>
              <div className={`wizard__step${page === 3 ? ' wizard__step--active' : ''}`}>
                <p>Notes</p>
              </div>
            </div>
            <div className="wizard__form-wrapper">
              {page === 1 && <StepOne page={page} onSubmit={nextPage} data={data} />}
              {page === 2 && (
                <StepTwo page={page} previousPage={previousPage} onSubmit={nextPage} data={data} />
              )}
              {page === 3 && (
                <StepThree
                  page={page}
                  previousPage={previousPage}
                  onSubmit={submitHandler}
                  onClear={clearForm}
                  data={data}
                />
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

WizardForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default WizardForm;
