import React, { Fragment } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import { Badge, Button, FormGroup, Input } from 'reactstrap';
import { useApplicantsUIContext } from '../ApplicantsUIProvider';
import ApplicantsTableColumns, { applicantsColumns } from './ApplicantsTableColumns';
import { ApplicantsTableSearchBar } from './ApplicantsTableSearchBar';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getApplicantsList, setApplicantFilters, toggleCheckedItemById } from '../../redux/actions';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { defaultPaginationProps } from '@/shared/helpers';
import PencilIcon from 'mdi-react/PencilIcon';
import moment from 'moment';

const ApplicantsTable = () => {
  const applicantsUIContext = useApplicantsUIContext();
  const dispatch = useDispatch();
  const {
    data: applicants,
    checkedApplicantIds,
    error,
    filters,
    loading,
    totalRecords,
  } = useSelector((state) => state.applicants, shallowEqual);
  const {
    pageSize: rowsPerPage,
    pageNumber: page,
    sortBy: orderBy,
    isSortOrderDescending,
  } = filters;

  const [popOver, setPopover] = React.useState({});

  React.useEffect(() => {
    dispatch(getApplicantsList(filters));
  }, [filters]);

  const handleRequestSort = (event, property) => {
    dispatch(
      setApplicantFilters({
        sortBy: property,
        isSortOrderDescending: !isSortOrderDescending,
      }),
    );
  };

  const handleChangePage = (event, currentPage) => {
    dispatch(
      setApplicantFilters({
        pageSize: rowsPerPage,
        pageNumber: currentPage + 1,
      }),
    );
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setApplicantFilters({ pageSize: +event.target.value, pageNumber: 1 }));
  };

  const emptyRows =
    page === Math.ceil(totalRecords / rowsPerPage) ? rowsPerPage - applicants.length : 0;

  const renderActionButtons = ({ id: applicantId }) => (
    <div className="actions">
      <Button
        className="icon mb-0"
        color="primary"
        outline
        onClick={() => applicantsUIContext.openEditApplicantsDialog(applicantId)}
      >
        <p>
          <PencilIcon /> Edit
        </p>
      </Button>
    </div>
  );

  const getColorClassForApplicationStatus = (status) => {
    switch (status) {
      //TODO: import form global const
      case 'Review':
        return 'primary';
      case 'Reject':
        return 'danger';
      case 'Approve':
        return 'success';
      case 'Ineligible':
        return 'warning';
      default:
        return 'secondary';
    }
  };

  return (
    <Fragment>
      <div className="material-table__wrap">
        <ApplicantsTableSearchBar />
        <p className="mb-3">
          Tip: To update cohort status, first select the program and click on search
        </p>
        <Table className="material-table">
          <ApplicantsTableColumns
            order={isSortOrderDescending ? 'desc' : 'asc'}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rowsPerPage}
          />
          <TableBody>
            {(loading || !!error) && (
              <TableRow>
                <TableCell colSpan={applicantsColumns.length}>
                  {loading && (
                    <div className={'text-center overflow-hidden'}>
                      <div className="panel__refresh position-relative" style={{ height: 75 }}>
                        <LoadingIcon />
                      </div>
                    </div>
                  )}
                  <div className={'text-center'}>
                    <p className={!!error ? 'm-5' : ''}>{error || 'Loading Applicants...'}</p>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {!!!applicants.length && !loading && !error && (
              <TableRow style={{ height: 120 }}>
                <TableCell colSpan={applicantsColumns.length} align="center">
                  {filters.status === null && filters.search === ''
                    ? 'No Applicants'
                    : 'No Applicants with applied filters'}
                </TableCell>
              </TableRow>
            )}
            {applicants.map((applicant) => {
              return (
                <TableRow className="material-table" tabIndex={-1} key={applicant?.id}>
                  <TableCell align="center" className="material-table__cell">
                    <FormGroup>
                      <Input
                        type="checkbox"
                        value={applicant?.id}
                        defaultChecked={checkedApplicantIds.includes(applicant?.id)}
                        onChange={(e) => dispatch(toggleCheckedItemById(+e.target.value))}
                      />
                    </FormGroup>
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    <img
                      className="applicant_logo_img"
                      src={
                        applicant?.logoImgUrl ||
                        'https://general-assets.sgp1.digitaloceanspaces.com/assets/dummy_profile.png'
                      }
                      alt="applicant logo"
                    />
                  </TableCell>
                  <TableCell
                    className="material-table__cell"
                    component="th"
                    align="left"
                    scope="row"
                    role="button"
                    onClick={() => applicantsUIContext.openViewApplicantsDialog(applicant?.id)}
                  >
                    {applicant?.userName}
                  </TableCell>
                  {/*<TableCell align="center" className="material-table__cell">
                    {applicant?.userEmail}
                  </TableCell>*/}
                  <TableCell align="center" className="material-table__cell">
                    {applicant?.program?.programName}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {moment(applicant?.program?.startDate).format('DD/MM/yyyy')}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {moment(applicant?.program?.endDate).format('DD/MM/yyyy')}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {applicant?.applicantCohort?.name}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {applicant?.isBhumiputra}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {moment(applicant?.program?.appliedDate).format('DD/MM/yyyy')}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {applicant?.applicantStage?.name}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    <Badge
                      color={getColorClassForApplicationStatus(applicant?.applicantStatus?.name)}
                      style={{ marginRight: 24 }}
                    >
                      {applicant?.applicantStatus?.name}
                    </Badge>
                  </TableCell>
                  {/* <TableCell
                    align="center"
                    className="material-table__cell"
                    style={{ minWidth: "295px" }}
                  >
                    {renderActionButtons(applicant)}
                  </TableCell> */}
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={applicantsColumns.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        component={defaultPaginationProps.component}
        backIconButtonProps={defaultPaginationProps.backIconButton}
        nextIconButtonProps={defaultPaginationProps.nextIconButton}
        rowsPerPageOptions={defaultPaginationProps.rowsPerPage}
        className={defaultPaginationProps.className}
        dir={defaultPaginationProps.dir}
      />
    </Fragment>
  );
};

export default ApplicantsTable;
