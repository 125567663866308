import { sendRequest } from '../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const sponsorshipBaseUrl = process.env.REACT_APP_CADS_AI_API + '/post';

export const sponsorshipEndpoints = {
  GET_ALL_SPONSORSHIPS: '/getAllPosts',
  GET_SPONSORSHIP_BY_ID: '/getPost',
  ADD_SPONSORSHIP: '/addPost',
  EDIT_SPONSORSHIP: '/editPost',
  DEACTIVATE_SPONSORSHIP: '/deactivatePost',
};

const getSponsorshipList = (filter) => {
  // return getMockSponsorshipList();
  return sendRequest({
    fullUrl: sponsorshipBaseUrl + sponsorshipEndpoints.GET_ALL_SPONSORSHIPS,
    method: httpMethod.GET,
    params: filter,
  });
};

const getSponsorshipById = (id) => {
  // return getMockSponsorshipById();
  return sendRequest({
    fullUrl: sponsorshipBaseUrl + sponsorshipEndpoints.GET_SPONSORSHIP_BY_ID,
    method: httpMethod.GET,
    params: { id: id },
  });
};

const addSponsorship = (data) => {
  // return addMockSponsorship();
  return sendRequest({
    fullUrl: sponsorshipBaseUrl + sponsorshipEndpoints.ADD_SPONSORSHIP,
    method: httpMethod.POST,
    data: data,
  });
};

const updateSponsorship = (data) => {
  // return updateMockSponsorship();
  return sendRequest({
    fullUrl: sponsorshipBaseUrl + sponsorshipEndpoints.EDIT_SPONSORSHIP,
    method: httpMethod.POST,
    data: data,
  });
};

const deleteSponsorship = (id) => {
  // return deleteMockSponsorship();
  return sendRequest({
    fullUrl: sponsorshipBaseUrl + sponsorshipEndpoints.DEACTIVATE_SPONSORSHIP,
    method: httpMethod.DELETE,
    params: { id: id },
  });
};

const getMockSponsorshipList = () => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [],
            data: {
              totalRecords: 3,
              records: [
                {
                  id: 1,
                  title: 'Title',
                  slug: 'MockSlug',
                  description: '<p>Mock Description of the <b>Sponsorship</b>.</p>',
                  image: 'https://unified.dev.cads.ai/image/sample/rectangle-34.png',
                  imageThumbnail: 'https://unified.dev.cads.ai/image/sample/rectangle-34.png',
                  tags: 'tags',
                  boostScore: 5,
                  link: 'links',
                  readCount: 50,
                  isPublished: true,
                  publishDate: '2023-06-27T00:00:00',
                  status: false,
                  followerCount: 12,
                },
                {
                  id: 2,
                  title: 'Title',
                  slug: 'MockSlug',
                  description: '<p>Mock Description of the <b>Sponsorship</b>.</p>',
                  image: 'https://unified.dev.cads.ai/image/sample/rectangle-34.png',
                  imageThumbnail: 'https://unified.dev.cads.ai/image/sample/rectangle-34.png',
                  tags: 'tags',
                  boostScore: 5,
                  link: 'links',
                  readCount: 50,
                  isPublished: true,
                  publishDate: '2023-06-27T00:00:00',
                  status: false,
                  followerCount: 12,
                },
                {
                  id: 3,
                  slug: 'MockSlug',
                  description: '<p>Mock Description of the <b>Sponsorship</b>.</p>',
                  image: 'https://unified.dev.cads.ai/image/sample/rectangle-34.png',
                  imageThumbnail: 'https://unified.dev.cads.ai/image/sample/rectangle-34.png',
                  tags: 'tags',
                  boostScore: 5,
                  link: 'links',
                  readCount: 50,
                  isPublished: true,
                  publishDate: '2023-06-27T00:00:00',
                  status: false,
                  followerCount: 12,
                },
              ],
            },
          },
        }),
      500,
    );
  });
};
const addMockSponsorship = (data) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          status: 200,
          messages: ['Sponsorship added successfully'],
        },
      });
    }, 500);
  });
};

const updateMockSponsorship = (data) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          status: 200,
          messages: ['Sponsorship added successfully'],
        },
      });
    }, 500);
  });
};

const deleteMockSponsorship = (data) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          status: 200,
          messages: ['Sponosrship deleted successfully'],
        },
      });
    }, 500);
  });
};

const getMockSponsorshipById = (data) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          status: 200,
          messages: ['Sponsorship added successfully'],
          data: {
            id: 1,
            title: 'Mock Sponsorship 1',
            slug: 'mock-Sponsorship-1',
            description: '<p>Mock Description of the <b>SponsorShip</b>.</p>',
            imageUrl: 'https://unified.dev.cads.ai/image/sample/rectangle-34.png',
            imageThumbnail: 'https://unified.dev.cads.ai/image/sample/rectangle-34.png',
            tags: 'mock-tag',
            boostScore: 10,
            readCount: 50,
            followedCount: 12,
            link: 'https://unified.dev.cads.ai/image/sample/rectangle-34.png',
            isPublished: true,
            publishDate: '2023-06-27T00:00:00',
            status: false, // [active, inactive],
          },
        },
      });
    }, 500);
  });
};

export default {
  getSponsorshipList,
  addSponsorship,
  updateSponsorship,
  getSponsorshipById,
  deleteSponsorship,
};
