import React, { Fragment, useRef, useState } from 'react';
import { getOrganizationOptionsList } from '@/containers/Cads-AI/redux/organization/actions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import { defaultPaginationProps } from '@/shared/helpers';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import LoadingIcon from 'mdi-react/LoadingIcon';
import api from '@/utils/apiClient';
import PencilIcon from 'mdi-react/PencilIcon';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import MagnifyIcon from 'mdi-react/MagnifyIcon';

const columns = [
  {
    id: 'picUrl',
    disablePadding: true,
    label: 'Picture',
  },
  {
    id: 'userFullName',
    disablePadding: true,
    label: 'Full name',
  },
  {
    id: 'email',
    disablePadding: true,
    label: 'Email',
  },
  {
    id: 'courses',
    disablePadding: true,
    label: 'Courses',
  },
  {
    id: 'action',
    disablePadding: true,
    label: 'Actions',
  },
];

const AssignedModuleTableColumns = ({ order, orderBy, onRequestSort }) => {
  const createSortHandler = (property, onRequestSort) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <Fragment>
      <TableRow>
        {columns.map((row, idx) => (
          <TableCell
            className="material-table__cell material-table__cell--sort material-table__cell-center"
            key={row.id}
            align={'center'}
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
          >
            {idx === 0 ? (
              <b>{row.label}</b>
            ) : (
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id, onRequestSort)}
                className="material-table__sort-label"
                dir="ltr"
              >
                <b>{row.label}</b>
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </Fragment>
  );
};

const UnassignDialog = ({ show, onHide, modules, jobseekerId, tenantId }) => {
  const { rtl } = useSelector((state) => state, shallowEqual);

  const initialValues = {
    isOpen: show,
    title: 'Unassignment Confirmation',
    message: 'Select course to unassign',
    hide: onHide,
    loading: false,
    unassignmentSuccess: false,
    unassignmentError: false,
  };
  const [modal, setModal] = React.useState(initialValues);
  const [selectedCourse, setSelectedCourse] = React.useState({
    value: -1,
    label: 'Select Course',
  });

  React.useEffect(() => {
    setModal({ ...modal, isOpen: show, hide: onHide });
  }, [show, onHide]);

  const performUnassignment = async () => {
    setModal({
      ...modal,
      loading: true,
      title: 'Performing Unassignment',
      message: 'Unassignment in progress...',
    });

    /*Make api call for performing assignment*/
    let response = await api.cadsAICourse.unAssignCourseToUsers({
      moduleId: selectedCourse.value,
      tenantId,
      jobSeekerIds: [jobseekerId],
    });

    /*update modal properties based on response*/
    let updatedModal = {};
    if (response !== null) {
      if (response.isSuccess) {
        updatedModal = {
          loading: false,
          unassignmentSuccess: true,
          title: 'Done',
          message: 'Unassignment completed successfully!',
        };
      } else {
        updatedModal = {
          loading: false,
          unassignmentError: true,
          title: 'Opps',
          message: 'Error performing unassignment!',
        };
      }
    }
    setModal({ ...modal, ...updatedModal });
  };

  const resetModal = () => {
    setModal(initialValues);
    setSelectedCourse({ value: -1, label: 'Select Module' });
  };

  return (
    <Modal
      isOpen={modal.isOpen}
      toggle={modal.hide}
      keyboard={false}
      backdrop={'static'}
      onClosed={resetModal}
      modalClassName={`${rtl.direction}-support`}
      className="modal-dialog--primary modal-dialog"
    >
      <div className="modal__header">
        <button
          className="lnr lnr-cross modal__close-btn"
          aria-label="modal__close-btn"
          type="button"
          onClick={modal.hide}
        />
        {!(modal.loading || modal.unassignmentSuccess || modal.unassignmentError) && (
          <span className="lnr lnr-unlink modal__title-icon" />
        )}
        {modal.unassignmentSuccess && (
          <span className="lnr lnr-thumbs-up modal__title-icon success" />
        )}
        {modal.unassignmentError && (
          <span className="lnr lnr-cross-circle modal__title-icon error" />
        )}
        <h4 className="text-modal  modal__title">{modal.title}</h4>
        <div className="modal__body">
          {modal.loading && (
            <div className="panel__refresh position-relative" style={{ height: 75 }}>
              <LoadingIcon />
            </div>
          )}
          {modal.message}
          {!(modal.unassignmentSuccess || modal.unassignmentError || modal.loading) && (
            <Select
              name="selectCourse"
              value={selectedCourse}
              onChange={setSelectedCourse}
              autosize={true}
              options={modules.map((m) => ({ value: m.id, label: m.name }))}
              clearable={false}
              className="react-select mt-3 mb-3"
              placeholder={'Select Module'}
              classNamePrefix="react-select"
            />
          )}
        </div>
        <ButtonToolbar className="modal__footer">
          {modal.unassignmentError && (
            <Button className="modal_cancel" onClick={onHide} outline>
              Close
            </Button>
          )}
          {modal.unassignmentSuccess && (
            <>
              <Button className="modal_cancel" onClick={onHide} outline>
                Okay
              </Button>
            </>
          )}
          {!(modal.unassignmentSuccess || modal.unassignmentError || modal.loading) && (
            <>
              <Button className="modal_cancel" onClick={onHide}>
                Cancel
              </Button>
              <Button
                className="modal_ok"
                outline
                color="danger"
                disabled={selectedCourse.value === -1}
                onClick={performUnassignment}
              >
                Yes, Unassign
              </Button>
            </>
          )}
        </ButtonToolbar>
      </div>
    </Modal>
  );
};

const UserAssignedCourseTable = () => {
  const dispatch = useDispatch();
  const { options: organizationOpts } = useSelector((state) => state.cadsAIOrgs, shallowEqual);
  const [selectedOrgOpt, setSelectedOrgOpt] = React.useState({});
  const [organizationOptions, setOrganizationOptions] = React.useState([]);
  const [filters, setFilters] = React.useState({
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    searchText: '',
    isSortOrderDescending: false,
  });
  const { pageSize: rowsPerPage, pageNumber: page, isSortOrderDescending, sortBy } = filters;
  const [courseUserData, setCourseUserData] = React.useState({
    totalRecords: 0,
    loading: false,
    error: '',
    records: [],
  });
  const { totalRecords, loading, error, records } = courseUserData;
  const colLength = columns.length;
  const emptyRows =
    page === Math.ceil(totalRecords / rowsPerPage) ? rowsPerPage - records.length : 0;
  const [unAssignDialog, setUnAssignDialog] = React.useState({
    isOpen: false,
    jobseekerId: null,
    modules: [],
  });

  const [isSearched, setIsSearched] = useState(false);
  const searchRef = useRef(null);

  const handleSearch = () => {
    setIsSearched(true);
    setFilters({ ...filters, searchText: searchRef?.current?.value });
  };

  const handleClear = () => {
    if (searchRef?.current?.value) searchRef.current.value = '';
    setIsSearched(false);
    setFilters({ ...filters, searchText: '' });
  };

  React.useEffect(() => {
    dispatch(getOrganizationOptionsList());
  }, []);

  React.useEffect(() => {
    const firstOrg = organizationOpts?.[0] ?? null;
    if (firstOrg) {
      setSelectedOrgOpt({ value: firstOrg?.id, label: firstOrg?.title });
      setOrganizationOptions(
        organizationOpts?.map((org) => ({ value: org?.id, label: org?.title })) ?? [],
      );
    }
  }, [organizationOpts]);

  React.useEffect(() => {
    if (selectedOrgOpt.value && !unAssignDialog.isOpen) getCourseData(selectedOrgOpt.value);
  }, [selectedOrgOpt, filters, unAssignDialog.isOpen]);

  const getCourseData = async (tenantId) => {
    const defaultError = 'Error occurred getting user assigned course list';
    try {
      setCourseUserData({ ...courseUserData, loading: true });
      const { data: res, isSuccess } = await api.cadsAICourse.getAssignedCourseUsers({
        ...filters,
        tenantId,
      });
      if (isSuccess) {
        setCourseUserData({
          ...courseUserData,
          loading: false,
          error: '',
          records: res.data.records,
          totalRecords: res.data.totalRecords,
        });
      } else throw new Error(res?.messages?.[0] ?? defaultError);
    } catch (error) {
      setCourseUserData({
        ...courseUserData,
        loading: false,
        error: error.msg,
        totalRecords: 0,
        records: [],
      });
      console.error(defaultError, error.message);
    }
  };

  const handleRequestSort = (e, sortBy) => {
    setFilters({
      ...filters,
      sortBy,
      isSortOrderDescending: !filters.isSortOrderDescending,
    });
  };

  const handleChangePage = (event, currentPage) => {
    setFilters({ ...filters, pageNumber: currentPage + 1 });
  };

  const handleChangeRowsPerPage = (e) => {
    setFilters({ ...filters, pageNumber: 1, pageSize: e.target.value });
  };

  const renderTableStatus = () =>
    (loading || !!error || totalRecords === 0) && (
      <TableRow>
        <TableCell colSpan={colLength}>
          {loading && (
            <div className={'text-center overflow-hidden'}>
              <div className="panel__refresh position-relative" style={{ height: 75 }}>
                <LoadingIcon />
              </div>
            </div>
          )}
          {totalRecords === 0 && !error && !loading && (
            <div className={'text-center'}>
              <p className={'m-5'}>No records found with applied filter.</p>
            </div>
          )}
          {(!!error || loading) && (
            <div className={'text-center'}>
              <p className={!!error ? 'm-5 error' : ''}>{error || 'Loading...'}</p>
            </div>
          )}
        </TableCell>
      </TableRow>
    );

  return (
    <>
      <div className="table__search">
        <div className="table__search_item">
          <b>User Name</b>
          <div className="form">
            <div className="form__form-group">
              <div className="form__form-group-field">
                <div className="form__form-group-icon" style={{ height: 37 }}>
                  <MagnifyIcon />
                </div>
                <input
                  ref={searchRef}
                  className="search-bar-search-text"
                  name="searchText"
                  type="text"
                  placeholder="Search By User Name"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table__search_item">
          <b>Organization</b>
          <div style={{ minWidth: 200 }}>
            <Select
              name="organization"
              options={organizationOptions}
              value={selectedOrgOpt}
              onChange={setSelectedOrgOpt}
              autosize={true}
              clearable={false}
              className="react-select"
              placeholder={'Select organization'}
              classNamePrefix="react-select"
            />
          </div>
        </div>
        <div className="table__search_item mt-3">
          <Button color="primary" onClick={handleSearch}>
            {' '}
            Search{' '}
          </Button>
        </div>
        <div className="table__search_item mt-3">
          <Button color="secondary" onClick={handleClear} outline>
            Clear
          </Button>
        </div>
      </div>
      <div className="material-table__wrap">
        <Table className="material-table">
          <TableBody>
            <AssignedModuleTableColumns
              order={isSortOrderDescending ? 'desc' : 'asc'}
              orderBy={sortBy}
              onRequestSort={handleRequestSort}
              rowCount={rowsPerPage}
            />

            {renderTableStatus()}
            {records.map((data) => (
              <TableRow key={data.jobseekerId}>
                <TableCell align="center" className="material-table__cell">
                  <img
                    className="program_logo_img"
                    src={
                      data?.picureUrl ??
                      'https://general-assets.sgp1.digitaloceanspaces.com/assets/dummy_profile.png'
                    }
                    alt="Picture"
                  />
                </TableCell>
                <TableCell align="center" className="material-table__cell">
                  {data?.userFullName}{' '}
                </TableCell>
                <TableCell align="center" className="material-table__cell">
                  {data?.email}{' '}
                </TableCell>
                <TableCell align="center" className="material-table__cell">
                  {data?.moduleRecoms?.map((m) => m.module.name).join(', ')}
                </TableCell>
                <TableCell align="center" className="material-table__cell">
                  <Button
                    className="icon mb-0"
                    color="primary"
                    outline
                    onClick={() =>
                      setUnAssignDialog({
                        isOpen: true,
                        modules: data?.moduleRecoms?.map((m) => m?.module) ?? [],
                        jobseekerId: data.jobseekerId,
                      })
                    }
                  >
                    <p>
                      <PencilIcon /> UnAssign
                    </p>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            <UnassignDialog
              onHide={() => {
                setUnAssignDialog({ ...unAssignDialog, isOpen: false });
                if (selectedOrgOpt.value) {
                  getCourseData(selectedOrgOpt.value);
                }
              }}
              show={unAssignDialog.isOpen}
              tenantId={selectedOrgOpt.value}
              modules={unAssignDialog.modules}
              jobseekerId={unAssignDialog.jobseekerId}
            />
            {/*Rendering empty rows for better UI*/}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={colLength} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        component={defaultPaginationProps.component}
        backIconButtonProps={defaultPaginationProps.backIconButton}
        nextIconButtonProps={defaultPaginationProps.nextIconButton}
        rowsPerPageOptions={defaultPaginationProps.rowsPerPage}
        className={defaultPaginationProps.className}
        dir={defaultPaginationProps.dir}
      />
    </>
  );
};

export default UserAssignedCourseTable;
