import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { useModuleUIContext } from './ModuleUIProvider';
import ModuleTableWrapper from './table/ModuleTableWrapper';

export function ModuleCard() {
  const moduleUIContext = useModuleUIContext();

  return moduleUIContext.isForAssignment ? (
    <ModuleTableWrapper />
  ) : (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Module List</h3>
          <h3 className="page-subhead subhead">
            From here you can manage all the modules and their schedule related content under
            control panel.
          </h3>
        </Col>
      </Row>
      <Row>
        <ModuleTableWrapper />
      </Row>
    </Container>
  );
}
