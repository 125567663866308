import React from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import SubcategoryTable from './SubcategoryTable';
import { useSubcategoryUIContext } from '../SubcategoryUIProvider';

const SubcategoryTableWrapper = () => {
  const subCategoryUIContext = useSubcategoryUIContext();

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="products-list">
          <div className="card__title">
            <h5 className="bold-text">subcategories List</h5>
            <ButtonToolbar className="products-list__btn-toolbar-top">
              <Button color="primary" onClick={subCategoryUIContext.openNewSubCategoryDialog}>
                Add new SubCategory
              </Button>
            </ButtonToolbar>
          </div>
          <SubcategoryTable />
        </CardBody>
      </Card>
    </Col>
  );
};

export default SubcategoryTableWrapper;
