import { sendRequest } from '../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const moduleEndpoints = {
  GET_MODULE_LIST: `/getModuleList`,
  GET_TRAINING_SKILL_OPTIONS_LIST: `/getTrainingSkills`,
  ADD_NEW_MODULE: `/addModuleDetails`,
  EDIT_MODULE: `/UpdateModuleDetails`,
  GET_ASSIGNED_USERS_MODULE: `/getAllAssignedUsersModuleDetails`,
  GET_ASSIGNED_ORGS_MODULE: `/getAllAssignedOrgsModuleDetails`,
  UN_ASSIGNED_USERS_MODULE: `/unassignModuleToUsers`,
  UN_ASSIGNED_ORGS_MODULE: `/unassignModuleToOrgs`,
  GET_MODULE_DTLS_BY_LP_ID: '/getModuleDtlsByLearningPathId',
  GET_ASSESSMENT_OPTIONS:
    'https://jbalb.talentspy.staging.cads.ai/api/Webservice/getCHROAssessmentList.json',
};

const getModuleList = (filter) => {
  // return getMockModuleList();
  return sendRequest(
    {
      url: moduleEndpoints.GET_MODULE_LIST,
      method: httpMethod.GET,
      params: { ...filter, searchText: filter.search },
    },
    process.env.REACT_APP_CONTROL_PANEL_ENDPOINT,
  );
};
const getTrainingSkillOptions = () => {
  // return getMockTrainingSkillOptions();
  return sendRequest(
    {
      url: moduleEndpoints.GET_TRAINING_SKILL_OPTIONS_LIST,
      method: httpMethod.GET,
    },
    process.env.REACT_APP_CONTROL_PANEL_ENDPOINT,
  );
};

const addModule = (module) => {
  // return new Promise(resolve => setTimeout(() => resolve({isSuccess: true, data: true}), 500));
  return sendRequest(
    {
      url: moduleEndpoints.ADD_NEW_MODULE,
      method: httpMethod.POST,
      data: module,
    },
    process.env.REACT_APP_CONTROL_PANEL_SS_ENDPOINT,
  );
};

const unAssignModuleToUser = (reqData) => {
  // return new Promise(resolve => setTimeout(() => resolve({isSuccess: true, data: true}), 500));
  return sendRequest(
    {
      url: moduleEndpoints.UN_ASSIGNED_USERS_MODULE,
      method: httpMethod.DELETE,
      data: reqData,
    },
    process.env.REACT_APP_CONTROL_PANEL_ENDPOINT,
  );
};

const unAssignModuleToOrganization = (reqData) => {
  // return new Promise(resolve => setTimeout(() => resolve({isSuccess: true, data: true}), 500));
  return sendRequest(
    {
      url: moduleEndpoints.UN_ASSIGNED_ORGS_MODULE,
      method: httpMethod.DELETE,
      data: reqData,
    },
    process.env.REACT_APP_CONTROL_PANEL_ENDPOINT,
  );
};
const editModule = ({ id, ...module }) => {
  // return new Promise(resolve => setTimeout(() => resolve({isSuccess: true, data: true}), 500));
  return sendRequest(
    {
      url: moduleEndpoints.EDIT_MODULE,
      method: httpMethod.PUT,
      data: module,
      params: { id },
    },
    process.env.REACT_APP_CONTROL_PANEL_SS_ENDPOINT,
  );
};

const getAssignedUserModule = (filters) => {
  // return getMockAssignedUserModule();
  return sendRequest(
    {
      url: moduleEndpoints.GET_ASSIGNED_USERS_MODULE,
      method: httpMethod.GET,
      params: filters,
    },
    process.env.REACT_APP_CONTROL_PANEL_ENDPOINT,
  );
};

const getAssignedOrganizationModule = (filters) => {
  // return getMockAssignedOrganizationModule();
  return sendRequest(
    {
      url: moduleEndpoints.GET_ASSIGNED_ORGS_MODULE,
      method: httpMethod.GET,
      params: filters,
    },
    process.env.REACT_APP_CONTROL_PANEL_ENDPOINT,
  );
};

const getAssessmentOptions = () => {
  // return getMockTrainingSkillOptions();
  return sendRequest({
    fullUrl: moduleEndpoints.GET_ASSESSMENT_OPTIONS,
    method: httpMethod.POST,
    data: { type: 'assessments' },
  });
};

const getModuleDtlsByLearningPathId = (learningPathId) => {
  return sendRequest(
    {
      url: moduleEndpoints.GET_MODULE_DTLS_BY_LP_ID,
      method: httpMethod.GET,
      params: { id: learningPathId },
    },
    process.env.REACT_APP_CONTROL_PANEL_SS_ENDPOINT,
  );
};

export default {
  getModuleList,
  getTrainingSkillOptions,
  addModule,
  editModule,
  getAssignedUserModule,
  getAssignedOrganizationModule,
  unAssignModuleToUser,
  unAssignModuleToOrganization,
  getAssessmentOptions,
  getModuleDtlsByLearningPathId,
};

const getMockModuleList = () =>
  new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [''],
            data: {
              totalRecords: 2,
              currentPage: 1,
              pageSize: 10,
              records: [
                {
                  id: 1,
                  name: 'Mock Module 1',
                  description: 'MOck Desc',
                  summary: 'sum',
                  tagline: 'tag',
                  imageUrl:
                    'https://images.squarespace-cdn.com/content/v1/60b6fd136dae8570d704e0d7/1625024934442-M9UBJJVLY4D4PA0HQGYF/CADS_Learning+Path+Banner_EDS.png',
                  preAssessmentId: 2,
                  postAssessmnetId: 3,
                  materialsUrl:
                    'https://images.squarespace-cdn.com/content/v1/60b6fd136dae8570d704e0d7/1625024934442-M9UBJJVLY4D4PA0HQGYF/CADS_Learning+Path+Banner_EDS.png',
                  moduleSkills: [
                    { id: 1, name: 'module skill 1' },
                    { id: 2, name: 'module skill 2' },
                  ],
                },
                {
                  id: 2,
                  name: 'Mock Module 2',
                  description: 'MOck Desc',
                  summary: 'sum',
                  tagline: 'tag',
                  imageUrl:
                    'https://images.squarespace-cdn.com/content/v1/60b6fd136dae8570d704e0d7/1625024934442-M9UBJJVLY4D4PA0HQGYF/CADS_Learning+Path+Banner_EDS.png',
                  preAssessmentId: 2,
                  postAssessmnetId: 3,
                  materialsUrl:
                    'https://images.squarespace-cdn.com/content/v1/60b6fd136dae8570d704e0d7/1625024934442-M9UBJJVLY4D4PA0HQGYF/CADS_Learning+Path+Banner_EDS.png',
                  moduleSkills: [
                    { id: 3, name: 'module skill 3' },
                    { id: 4, name: 'module skill 4' },
                  ],
                },
              ],
            },
          },
        }),
      500,
    );
  });

const getMockAssignedUserModule = () =>
  new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [''],
            data: {
              totalRecords: 2,
              currentPage: 1,
              pageSize: 10,
              records: [
                {
                  moduleRecomId: 1,
                  picUrl:
                    'https://general-assets.sgp1.digitaloceanspaces.com/assets/dummy_profile.png',
                  userFullName: 'User 1',
                  email: 'user@email.com',
                  jobseekerId: 100,
                  moduleList: [
                    { id: 1, name: 'Mock Module 1', imageUrl: '', tagline: '' },
                    { id: 2, name: 'Mock Module 2', imageUrl: '', tagline: '' },
                  ],
                },
                {
                  moduleRecomId: 2,
                  picUrl:
                    'https://general-assets.sgp1.digitaloceanspaces.com/assets/dummy_profile.png',
                  userFullName: 'User 2',
                  jobseekerId: 200,
                  email: 'user@email.com',
                  moduleList: [
                    { id: 1, name: 'Mock Module 1', imageUrl: '', tagline: '' },
                    { id: 2, name: 'Mock Module 2', imageUrl: '', tagline: '' },
                  ],
                },
              ],
            },
          },
        }),
      500,
    );
  });

const getMockAssignedOrganizationModule = () =>
  new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [''],
            data: {
              totalRecords: 2,
              currentPage: 1,
              pageSize: 10,
              records: [
                {
                  tenantId: 1,
                  moduleRecomId: 10,
                  picUrl:
                    'https://general-assets.sgp1.digitaloceanspaces.com/assets/dummy_profile.png',
                  orgName: 'Mock Module Org 1',
                  orgEmail: 'org@gmail.com',
                  moduleList: [
                    { id: 1, name: 'Mock Module 1', imageUrl: '', tagline: '' },
                    { id: 2, name: 'Mock Module 2', imageUrl: '', tagline: '' },
                  ],
                },
                {
                  tenantId: 2,
                  moduleRecomId: 20,
                  picUrl:
                    'https://general-assets.sgp1.digitaloceanspaces.com/assets/dummy_profile.png',
                  orgName: 'Mock Module Org 2',
                  orgEmail: 'org@gmail.com',
                  moduleList: [
                    { id: 1, name: 'Mock Module 1', imageUrl: '', tagline: '' },
                    { id: 2, name: 'Mock Module 2', imageUrl: '', tagline: '' },
                  ],
                },
              ],
            },
          },
        }),
      500,
    );
  });

const getMockTrainingSkillOptions = () =>
  new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [''],
            data: [
              { id: 1, name: 'Module Skill 1' },
              { id: 2, name: 'Module Skill 2' },
              { id: 3, name: 'Module Skill 3' },
              { id: 4, name: 'Module Skill 4' },
            ],
          },
        }),
      500,
    );
  });
