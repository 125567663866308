import React from 'react';
import { useDispatch } from 'react-redux';
import api from '@/utils/apiClient';
import { convertImageFileToBase64, YesNoOptions } from '@/shared/helpers';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { updateCadsAiModuleInList } from '../../redux/actions';
import ModuleWizardForm from './module-wizard-form';

const ModuleEditForm = ({ isEdit, id }) => {
  const dispatch = useDispatch();
  const initialValues = {
    title: '',
    code: '',
    description: '',
    summary: '',
    moduleType: '',
    imageUrl: '',
    courses: '',
    otherDataSkills: '',
    dataSkills: '',
    isPlatformWide: {
      value: true,
      label: 'Yes',
    },
  };

  //states
  const [data, setData] = React.useState(initialValues);
  const [state, setState] = React.useState({
    loading: false,
    fetchingModule: false,
    newModuleAdded: false,
    errorMessage: '',
  });
  const [selectedModule, setSelectedModule] = React.useState({});
  const [prerequisiteModulesList, setPrerequisiteModulesList] = React.useState([]); // selected pre-req modules
  const [courseList, setCourseList] = React.useState([]); // selected pre-req modules

  // prefill the form in case of edit module
  React.useEffect(() => {
    if (isEdit && id) {
      setDefaultData(+id);
    }
  }, [isEdit, id]);

  const setDefaultData = async (moduleId) => {
    let selectedModule = {};
    try {
      const { data: res, isSuccess } = await api.cadsAIModule.getModuleById(moduleId);
      if (isSuccess) selectedModule = res.data;
      else throw new Error('Error getting module details');
    } catch (e) {
      console.error(e.message);
    }
    setData({
      ...selectedModule,
      dataOtherSkills: selectedModule?.dataOtherSkills?.map((m) => ({
        value: m.id,
        label: m.title,
      })),
      isPlatformWide: selectedModule?.isPlatformWide ? YesNoOptions[0] : YesNoOptions[1],
      moduleType: {
        value: selectedModule?.moduleType?.id,
        label: selectedModule?.moduleType?.title,
      },
      dataSkills: selectedModule?.dataSkills?.map((m) => ({ value: m.id, label: m.title })),
    });
  };

  const constructRequest = async ({
    title,
    description,
    summary,
    tagline,
    imageUrl,
    materialsUrl,
    isPlatformWide,
    code,
    dataSkills,
    dataOtherSkills,
    moduleType,
  }) => {
    const base64Img = await convertImageFileToBase64(imageUrl?.[0]);
    return {
      title,
      description,
      summary,
      tagline,
      materialsUrl,
      code,
      isPlatformWide: isPlatformWide?.value,
      imageUrl: base64Img?.split(',')?.[1],
      dataSkillId: dataSkills?.map((skill) => skill.value),
      moduleTypeId: moduleType?.value,
      dataOtherSkillId: dataOtherSkills?.map((skill) => skill.value),
      /*      modulePrerequisites: prerequisiteModulesList?.map((module) => ({
        prerequisiteModuleId: module.value,
        isMandatory: module.isMandatory,
      })),*/
      /*courses: courseList?.map((course) => ({
        courseId: course.value,
        isMandatory: course.isMandatory,
      })),*/
      courseId: courseList?.map((course) => course.value),
    };
  };

  const onSubmit = async (moduleData, resetForm) => {
    setState({ ...state, loading: true });
    const reqData = await constructRequest(moduleData);
    const defaultError = `Error occurred while ${isEdit ? 'Updating' : 'Adding new'} module`;
    try {
      const { data, isSuccess } = isEdit
        ? await api.cadsAIModule.cadsAiEditModule({ id, ...reqData })
        : await api.cadsAIModule.cadsAiAddModule(reqData);
      if (isSuccess) {
        setState({ ...state, newModuleAdded: true, loading: false });

        if (isEdit)
          dispatch(
            updateCadsAiModuleInList({
              id,
              ...reqData,
              moduleSkills: (moduleData?.moduleSkills ?? []).map((m) => ({
                id: m.value,
                name: m.label,
              })),
            }),
          );
        else resetForm();
      } else {
        throw new Error(data?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({ ...state, loading: false, newModuleAdded: false, errorMessage: error.message });
    }
  };

  return (
    <div className="cads-ai-add-edit">
      {/*Add/Edit Module Form Status*/}
      <div className="ml-4">
        {state.loading && (
          <div>
            <div className="panel__refresh position-relative" style={{ height: 75, width: 20 }}>
              <LoadingIcon />
              <div style={{ width: 200, padding: '23px 40px' }}>
                {isEdit ? 'Updating' : 'Adding new'} module...
              </div>
            </div>
          </div>
        )}
        {(state.newModuleAdded || !!state.errorMessage) && (
          <table className="mt-3">
            <tr>
              <td>
                {state.newModuleAdded ? (
                  <span className="lnr lnr-thumbs-up modal__title-icon success" />
                ) : (
                  <span className="lnr lnr-cross-circle modal__title-icon error" />
                )}
              </td>
              <td className="pl-2">
                {state.newModuleAdded
                  ? `${isEdit ? 'Module Updated' : 'New Module Added'} Successfully`
                  : state.errorMessage}
              </td>
            </tr>
          </table>
        )}
      </div>

      {/*Add/Edit Module Form*/}
      <ModuleWizardForm
        data={data}
        setData={setData}
        initialValues={initialValues}
        id={id}
        prerequisiteModulesList={prerequisiteModulesList}
        setPrerequisiteModulesList={setPrerequisiteModulesList}
        onSubmit={onSubmit}
        selectedModule={selectedModule}
        setCourseList={setCourseList}
        courseList={courseList}
        state={state}
      />
    </div>
  );
};

export default ModuleEditForm;
