import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, ButtonToolbar } from 'reactstrap';
import { FormField } from '@/shared/components/form/hook/FormField';
import { extractFieldData } from '../index';
import { SelectField } from '@/shared/components/form/Select';
import { isEmpty } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getAllCourseOptions } from '../../../../../Course/redux/actions';

const StepThree = ({
  onSubmit,
  page,
  data,
  setCourseList,
  courseList,
  previousPage,
  state,
  id,
}) => {
  //states
  const dispatch = useDispatch();
  const { courseOptions: courseOpts } = useSelector((state) => state.cadsAICourse, shallowEqual);
  const [courseOptions, setCourseOptions] = React.useState([]); // pre-req modules available to select

  // learningPath form related functions
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    getValues,
  } = useForm(extractFieldData(page, data));

  React.useEffect(() => {
    dispatch(getAllCourseOptions());
  }, []);

  React.useEffect(() => {
    if (id) {
      setCourseList(
        data?.courses?.map((course) => ({
          value: course?.id,
          label: course?.title,
          ...course,
        })) ?? [],
      );
    }
  }, [id]);

  React.useEffect(() => {
    const selectedCourseIds = courseList?.map((item) => item?.value) ?? [];
    setCourseOptions(
      courseOpts
        .filter((s) => !selectedCourseIds.includes(s?.id))
        .map((item) => ({ value: item?.id, label: item?.title })),
    );
  }, [courseOpts]);

  const addToCourseList = async () => {
    const { courses, isMandatory, sortOrder } = getValues();
    /*if isEmpty(isMandatory) || */
    if (isEmpty(courses)) return;
    setCourseList([...courseList, courses]);
    /*if (courseList.length > 0) {
      const sortedCourse = courseList.slice(0, sortOrder - 1);
      const sortedAnotherCourses = courseList.slice(sortOrder - 1);
      let sortedAllCourses = sortedCourse;
      sortedAllCourses.push({ ...courses, isMandatory: isMandatory?.value, sortOrder: sortOrder });
      courseList = [...sortedAllCourses, ...sortedAnotherCourses];
      courseList.forEach((item, index) => {
        item.sortOrder = (index + 1).toString();
      });
      setCourseList(courseList);
    } else {
      setCourseList([
        ...courseList,
        { ...courses, isMandatory: isMandatory?.value, sortOrder: sortOrder },
      ]);
    }*/
    setValue('courses', '');
    setCourseOptions(courseOptions?.filter((item) => item?.value !== courses?.value));
    // setValue('sortOrder', 0);
    // setValue('isMandatory', { value: false, label: 'No' });
  };

  const removeCourseFromList = (course) => {
    setCourseOptions([...courseOptions, course]);
    setCourseList([...courseList.filter(({ value }) => value !== course.value)]);
  };

  return (
    <div className="modal__body add-edit-learning-path">
      <form className={`form form--horizontal`} onSubmit={handleSubmit(onSubmit)}>
        <div className="form__form-group lp_edit_module_error" style={{ width: '80%' }}>
          <span className="form__form-group-label" style={{ minWidth: 'fit-content' }}>
            Courses
          </span>
          <FormField
            style={{ height: 40 }}
            name="courses"
            control={control}
            component={SelectField}
            rules={{
              validate: () => (courseList?.length === 0 ? 'Please add at least one course' : true),
            }}
            errors={courseList?.length === 0 && errors}
            isAboveError
            options={courseOptions}
            placeholder="Select Courses"
          />
        </div>
        {/*<div className="form__form-group ml-2" style={{ width: '24%' }}>
          <span className="form__form-group-label">Is Mandatory?</span>
          <FormField
            name="isMandatory"
            style={{ height: 40 }}
            control={control}
            component={SelectField}
            errors={errors}
            options={[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' },
            ]}
            defaultValue={{ value: false, label: 'No' }}
            isAboveError
          />
        </div>
        <div className="form__form-group ml-2" style={{ width: '25%' }}>
          <span className="form__form-group-label">Sort Order</span>
          <FormField
            name="sortOrder"
            as="input"
            style={{ height: 39 }}
            type="number"
            min={1}
            placeholder="Enter Sort Order"
            control={control}
            defaultValue=""
          />
        </div>*/}
        <div className="form__form-group" style={{ width: '0%' }}>
          <div className="competency_add_button">
            <Button
              color="success"
              type="button"
              outline
              style={{ marginLeft: 20, height: 38, marginTop: 8 }}
              onClick={addToCourseList}
            >
              Add
            </Button>
          </div>
        </div>

        {courseList.length > 0 && (
          <table className="competency_add_section table" style={{ width: '100%' }}>
            <tr>
              {/*<th>Order</th>*/}
              <th className="pl-4">Course</th>
              {/*<th className="pl-4">Is Mandatory</th>*/}
              <th className="pl-4">Remove Module</th>
            </tr>
            {courseList
              .sort((a, b) => a.sortOrder - b.sortOrder)
              .map((course) => (
                <tr>
                  {/*<td className="pl-4">{course.sortOrder}</td>*/}
                  <td className="pl-4">{course.label} </td>
                  {/*<td className="pl-5">{course?.isOptional ? 'Yes' : 'No'}</td>*/}
                  <td className="pl-5">
                    <span
                      onClick={() => removeCourseFromList(course)}
                      onMouseEnter={(e) => (e.target.className = 'lnr lnr-trash')}
                      style={{
                        color: 'red',
                        cursor: 'pointer',
                      }}
                      className="lnr lnr-trash"
                    />{' '}
                  </td>
                </tr>
              ))}
          </table>
        )}
        <ButtonToolbar className="form__button-toolbar modal__footer">
          <Button onClick={previousPage} type="button" className="previous">
            Back
          </Button>
          <Button disabled={state?.loading} color="primary" type="submit">
            Submit
          </Button>
        </ButtonToolbar>
      </form>
    </div>
  );
};

export default StepThree;
