import React, { createContext, useContext } from 'react';

const ProgramsUIContext = createContext();

export function useProgramsUIContext() {
  return useContext(ProgramsUIContext);
}

export function ProgramsUIProvider({ programsUIEvents, children }) {
  const value = {
    openViewProgramsDialog: programsUIEvents.openViewProgramsDialog,
    openNewProgramsDialog: programsUIEvents.openNewProgramsDialog,
    openEditProgramsDialog: programsUIEvents.openEditProgramsDialog,
    openDeleteProgramsDialog: programsUIEvents.openDeleteProgramsDialog,
    openUpdateStatusDialog: programsUIEvents.openUpdateStatusDialog,
  };

  return <ProgramsUIContext.Provider value={value}>{children}</ProgramsUIContext.Provider>;
}
