import React from 'react';
import { Col, Nav, NavItem, NavLink, TabContent, TabPane, Button, ButtonToolbar } from 'reactstrap';
import classnames from 'classnames';
import AssignedCard from './AssignedCard';
import * as Constants from '@/shared/helpers/constants';
import { setActiveTab, uncheckAllItem } from '../redux/actions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useAssignedUIContext } from './ControlPanelAssignedUIProvider';

const ControlPanelAssignTabs = () => {
  const assignedUIContext = useAssignedUIContext();
  const { activeTab, checkedItemIds } = useSelector((state) => state.assignedPage, shallowEqual);
  const [unassignBtnDisabled, setUnassignBtnDisabled] = React.useState(true);
  const dispatch = useDispatch();
  const toggle = (tab) => {
    dispatch(setActiveTab(tab));
    dispatch(uncheckAllItem());
  };

  React.useEffect(() => {
    if (checkedItemIds.length === 0) {
      setUnassignBtnDisabled(true);
    } else {
      setUnassignBtnDisabled(false);
    }
  }, [checkedItemIds]);

  return (
    <Col className="card-body">
      <div className="tabs tabs--bordered-bottom">
        <div className="tabs__wrap">
          <Nav tabs>
            {Object.keys(Constants.controlPanelTabs).map((key) => (
              <NavItem key={Constants.controlPanelTabs[key].id}>
                <NavLink
                  className={classnames({
                    active: activeTab === Constants.controlPanelTabs[key].id,
                  })}
                  onClick={() => toggle(Constants.controlPanelTabs[key].id)}
                >
                  {Constants.controlPanelTabs[key].label}
                </NavLink>
              </NavItem>
            ))}
            <ButtonToolbar className="products-list__btn-toolbar-top">
              <Button
                disabled={unassignBtnDisabled}
                color="danger"
                outline
                className="mb-0 mr-3 mt-2"
                onClick={() => assignedUIContext.openUnassignDialog()}
              >
                Unassign
              </Button>
            </ButtonToolbar>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={activeTab}>
              <AssignedCard />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </Col>
  );
};

export default ControlPanelAssignTabs;
