import { handleActions } from 'redux-actions';
import {
  fetchNotificationList,
  setNotificationError,
  setNotificationFilters,
  setNotificationList,
  toggleCheckedNotification,
  uncheckAllNotification,
} from './actions';

const defaultState = {
  loading: false,
  checkedNotificationIds: [],
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    search: '',
    isSortOrderDescending: false,
    status: null,
  },
  totalRecords: 0,
  data: [],
  options: [],
  error: null,
};

export default handleActions(
  {
    [fetchNotificationList](state) {
      return {
        ...state,
        loading: true,
        data: [],
      };
    },
    [setNotificationList](state, { payload }) {
      return {
        ...state,
        loading: false,
        data: payload.data.records,
        totalRecords: payload.data.totalRecords,
        error: null,
      };
    },
    [setNotificationError](state, { payload }) {
      return {
        ...state,
        data: [],
        loading: false,
        totalPages: defaultState.totalPages,
        error: payload?.message,
      };
    },
    [setNotificationFilters](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
        checkedNotificationIds: [],
      };
    },
    [toggleCheckedNotification](state, { payload: eventId }) {
      const checkedIds = state.checkedNotificationIds;
      return {
        ...state,
        checkedNotificationIds: checkedIds.includes(eventId)
          ? checkedIds.filter((id) => id !== eventId)
          : [...checkedIds, eventId],
      };
    },
    [uncheckAllNotification](state) {
      return {
        ...state,
        checkedNotificationIds: [],
      };
    },
  },
  defaultState,
);
