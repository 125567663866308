import React from 'react';
import { useDispatch } from 'react-redux';
import { paths } from '@/shared/helpers/paths';
import { Route, Switch, useHistory } from 'react-router-dom';
import { LearningPathCard } from './LearningPathCard';
import { clearLearningPathFilters, setLearningPathFilters } from '../redux/actions';
import { LearningPathUIProvider } from './LearningPathUIProvider';
import LearningPathEditDialog from './learning-path-add-edit-dialog/LearningPathEditDialog';

const learningPathBaseUrl = paths.CONTROL_PANEL.LEARNING_PATH;

export function LearningPathPage({ isForAssignment = false }) {
  const history = useHistory();

  //clean up
  React.useEffect(() => dispatch(clearLearningPathFilters()), []);

  const learningPathUIEvents = {
    openViewLearningPathDialog: (id) => {
      history.push(`${learningPathBaseUrl}/${id}/view`);
    },
    openNewLearningPathDialog: () => {
      history.push(`${learningPathBaseUrl}/new`);
    },
    openEditLearningPathDialog: (id) => {
      history.push(`${learningPathBaseUrl}/${id}/edit`);
    },
    openDeleteLearningPathDialog: (id) => {
      history.push(`${learningPathBaseUrl}/${id}/delete`);
    },
  };

  const dispatch = useDispatch();

  return (
    <LearningPathUIProvider
      isForAssignment={isForAssignment}
      learningPathUIEvents={learningPathUIEvents}
    >
      <Switch>
        <Route path={`${learningPathBaseUrl}/new`}>
          {({ history, match }) => (
            <LearningPathEditDialog
              show={match != null}
              isEdit={false}
              onHide={() => {
                dispatch(setLearningPathFilters());
                history.push(learningPathBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${learningPathBaseUrl}/:id/edit`}>
          {({ history, match }) => (
            <LearningPathEditDialog
              show={match != null}
              isEdit={true}
              id={match?.params?.id}
              onHide={() => {
                history.push(learningPathBaseUrl);
              }}
            />
          )}
        </Route>
      </Switch>
      <LearningPathCard />
    </LearningPathUIProvider>
  );
}
