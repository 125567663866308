import React, { useState } from 'react';
import { Col, Card, Row, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import ProgramDetailsTab from './ProgramDetailsTab';
import { useForm } from 'react-hook-form';
import ProgramAssociatedCohortTab from '@/containers/Data-Star/Programs/components/program-add-edit-dialog/ProgramAssociatedCohortTab';

export function getStepFields() {
  return [
    'id',
    'title',
    'headline',
    'description',
    'start_date',
    'end_date',
    'tags',
    'location',
    'country_name',
    'bg_img_url',
    'logo_img_url',
    'main_img_url',
    'cohortList',
  ];
}

export function extractFieldData(data) {
  const obj = {};
  getStepFields().forEach((key) => (obj[key] = data[key]));
  return obj;
}

const ProgramEditForm = (programEditProps) => {
  /*TABS*/
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    reset,
    getValues,
  } = useForm(extractFieldData(programEditProps?.data));

  const formProps = {
    handleSubmit,
    control,
    setValue,
    errors,
    reset,
    getValues,
  };

  return (
    <Row>
      <Col md={12} lg={12}>
        <Card className="pb-0">
          <CardBody>
            <div className="tabs tabs--justify tabs--bordered-bottom overflow-hidden">
              <div className="tabs__wrap">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => toggle('1')}
                    >
                      Program Details
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => toggle('2')}
                    >
                      Associated Cohorts
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <ProgramDetailsTab {...programEditProps} formProps={formProps} />
                  </TabPane>
                  <TabPane tabId="2">
                    <ProgramAssociatedCohortTab {...programEditProps} formProps={formProps} />
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default ProgramEditForm;
