import React, { Fragment, useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import { Badge, Button } from 'reactstrap';
import { useDdoUIContext } from '../DdoUIProvider';
import DdoTableColumns, { DdoColumns } from './DdoTableColumns';
import { DdoTableSearchBar } from './DdoTableSearchBar';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getDdoList, setDdoFilters } from '../../redux/actions';
import EyeIcon from 'mdi-react/EyeIcon';
import PencilIcon from 'mdi-react/PencilIcon';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { defaultPaginationProps } from '@/shared/helpers';
import DeleteIcon from 'mdi-react/DeleteIcon';
import moment from 'moment';

const DdoTable = () => {
  const ddoUIContext = useDdoUIContext();
  const dispatch = useDispatch();
  const {
    data: ddoList,
    error,
    filters,
    loading,
    totalRecords,
  } = useSelector((state) => state.cadsAIDdo, shallowEqual);
  const {
    pageSize: rowsPerPage,
    pageNumber: page,
    sortBy: orderBy,
    isSortOrderDescending,
  } = filters;

  const [columns, setColumns] = useState(0);

  useEffect(() => {
    setColumns(DdoColumns.length);
  }, [DdoColumns.length]);

  React.useEffect(() => {
    dispatch(getDdoList(filters));
  }, [filters]);

  const handleRequestSort = (event, property) => {
    dispatch(
      setDdoFilters({
        sortBy: property,
        isSortOrderDescending: !isSortOrderDescending,
      }),
    );
  };

  const renderActionButtons = (id) => (
      <div className="actions">
        <Button
            className="icon mb-0"
            color="success"
            outline
            onClick={() => ddoUIContext.openViewDdoDialog(id)}
        >
          <p>
            <EyeIcon/> View
          </p>
        </Button>
      <Button
        className="icon mb-0"
        color="primary"
        outline
        onClick={() => ddoUIContext.openEditDdoDialog(id)}
      >
        <p>
          <PencilIcon /> Edit
        </p>
      </Button>
      <Button
        className="icon mb-0"
        color="danger"
        outline
        onClick={() => ddoUIContext.openDeleteDdoDialog(id)}
      >
        <p>
          <DeleteIcon /> Remove
        </p>
      </Button>
    </div>
  );

  const handleChangePage = (event, currentPage) => {
    dispatch(setDdoFilters({ pageSize: rowsPerPage, pageNumber: currentPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setDdoFilters({pageSize: +event.target.value, pageNumber: 1}));
  };

  const emptyRows =
    page === Math.ceil(totalRecords / rowsPerPage) ? rowsPerPage - ddoList.length : 0;

  return (
    <Fragment>
      <div className="material-table__wrap">
        <DdoTableSearchBar />
        <Table className="material-table">
          <DdoTableColumns
            order={isSortOrderDescending ? 'desc' : 'asc'}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rowsPerPage}
          />
          <TableBody>
            {(loading || !!error) && (
              <TableRow>
                <TableCell colSpan={columns}>
                  {loading && (
                    <div className={'text-center overflow-hidden'}>
                      <div className="panel__refresh position-relative" style={{ height: 75 }}>
                        <LoadingIcon/>
                      </div>
                    </div>
                  )}
                  <div className={'text-center'}>
                    <p className={!!error ? 'm-5' : ''}>{error || 'Loading ddo...'}</p>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {!!!ddoList?.length && !loading && !error && (
                <TableRow style={{height: 120}}>
                  <TableCell colSpan={columns} align="center">
                    {filters.status === null && filters.search === ''
                        ? 'No DDO'
                        : 'No DDO with applied filters'}
                  </TableCell>
                </TableRow>
            )}
            {ddoList?.map((item) => {
              return (
                  <TableRow className="material-table" tabIndex={-1} key={item?.id}>
                    <TableCell className="material-table__cell" align="center">
                    {item?.id}
                    </TableCell>
                    <TableCell align="center" className="material-table__cell">
                      {item?.ddoProgramTitle}
                    </TableCell>
                    <TableCell align="center" className="material-table__cell">
                      {item?.organization?.title ?? "-"}
                    </TableCell>
                    <TableCell align="center" className="material-table__cell">
                      {item.ddoStartdate ? moment(item?.ddoStartdate).format("DD/MM/yyyy") : ''}
                    </TableCell>
                    <TableCell align="center" className="material-table__cell">
                      {item.ddoEnddate ? moment(item?.ddoEnddate).format("DD/MM/yyyy") : ''}
                    </TableCell>
                    <TableCell align="center" className="material-table__cell">
                      {(item?.status === true || item?.status === false) ?
                          <Badge
                              color={`${item?.status ? 'success' : 'danger'}`}
                              style={{marginRight: 24}}
                          >
                            {item.status ? 'Active' : 'Deactivated'}
                          </Badge>
                          :
                          "-"
                      }
                    </TableCell>
                    <TableCell
                        align="center"
                        className="material-table__cell"
                        style={{minWidth: 250}}
                    >
                      {renderActionButtons(item?.id)}
                    </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={columns} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        component={defaultPaginationProps.component}
        backIconButtonProps={defaultPaginationProps.backIconButton}
        nextIconButtonProps={defaultPaginationProps.nextIconButton}
        rowsPerPageOptions={defaultPaginationProps.rowsPerPage}
        className={defaultPaginationProps.className}
        dir={defaultPaginationProps.dir}
      />
    </Fragment>
  );
};

export default DdoTable;
