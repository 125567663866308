import React, { useState, useEffect } from 'react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
} from 'recharts';
import { Button } from 'reactstrap';
import api from '@/utils/apiClient';

const DataStarChart = ({ programTypeId, programId, status }) => {
  const [isSearched, setIsSearched] = useState(false);
  const today = new Date();
  const month = today.getMonth() > 9 ? today.getMonth() + 1 : '0' + (today.getMonth() + 1);
  const year = today.getFullYear();
  const totalDaysInMonth = new Date(year, month, 0).getDate();
  const [fromDate, setFromDate] = useState(`${year}-${month}-01`);
  const [toDate, setToDate] = useState(`${year}-${month}-${totalDaysInMonth}`);
  const [dataStarAnalytics, setDataStarAnalytics] = useState(null);

  const sortedDataStarAnalytics = dataStarAnalytics?.sort((a, b) => {
    return new Date(a.date) - new Date(b.date);
  });

  useEffect(() => {
    getDataStarAnalytics({
      programTypeId: programTypeId,
      programId: programId,
      startDate: fromDate,
      endDate: toDate,
      status: status,
    });
  }, [programTypeId, programId, status]);

  const getDataStarAnalytics = async (filters) => {
    try {
      const { data: res, isSuccess } = await api.dashboard.getProgramDailyCountGraph(filters);
      if (isSuccess) {
        res.data.forEach(function (item) {
          item.date = item.date.slice(0, 10);
        });
        setDataStarAnalytics(res?.data);
      } else {
        throw new Error('error while fetching datastar graph data');
      }
    } catch (error) {
      console.error('Error inside dashboard datastar graph', error.message);
    }
  };

  const handleSearch = () => {
    setIsSearched(true);
    getDataStarAnalytics({
      programTypeId: programTypeId,
      programId: programId,
      startDate: fromDate,
      endDate: toDate,
      status: status,
    });
  };

  const handleClear = () => {
    setIsSearched(false);
    setFromDate('');
    setToDate('');
    getDataStarAnalytics({ programTypeId: programTypeId, programId: programId, status: status });
  };

  return (
    <>
      <div className="d-flex flex-wrap" style={{ margin: '90px 0' }}>
        <div className="table__search_item" style={{ width: 200 }}>
          <b>From Date</b>
          <div className="form">
            <div className="form__form-group">
              <div className="form__form-group-field">
                <input
                  type="date"
                  className="search-bar-search-text"
                  name="from_date"
                  placeholder="From date"
                  onChange={(e) => setFromDate(e.target.value)}
                  value={fromDate}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table__search_item" style={{ width: 200 }}>
          <b>To Date</b>
          <div className="form">
            <div className="form__form-group">
              <div className="form__form-group-field">
                <input
                  type="date"
                  className="search-bar-search-text"
                  name="to_date"
                  placeholder="To date"
                  min={fromDate}
                  onChange={(e) => setToDate(e.target.value)}
                  value={toDate}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table__search_item mt-3">
          <Button color="primary" onClick={handleSearch}>
            {' '}
            Search{' '}
          </Button>
        </div>
        <div className="table__search_item mt-3">
          <Button color="secondary" onClick={handleClear} outline>
            Clear
          </Button>
        </div>
      </div>

      <div dir="ltr">
        <ResponsiveContainer height={220} className="dashboard__area">
          <AreaChart
            data={sortedDataStarAnalytics}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Area
              name="No of applicant"
              dataKey="noOfApplicants"
              fill="#4ce1b6"
              stroke="#4ce1b6"
              fillOpacity={0.2}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default DataStarChart;
