import { Row, Container } from 'reactstrap';
import AssignTableWrapper from './table/AssignTableWrapper';

const AssignCard = () => {
  return (
    <Container>
      <Row>
        <AssignTableWrapper />
      </Row>
    </Container>
  );
};

export default AssignCard;
