import React, { useRef } from 'react';
import { Button } from 'reactstrap';
import Select from 'react-select';
import TableHead from '@material-ui/core/TableHead';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setBaselineThresholdFilters } from '../../redux/actions';

export function BaselineThresholdTableSearchBar() {
  const dispatch = useDispatch();
  const totalBaselineThresholdCount = useSelector(
    (state) => state.baselineThreshold?.data?.length,
    shallowEqual,
  );
  const [isSearched, setIsSearched] = React.useState(false);
  const searchNameRef = useRef(null);
  const handleSearch = () => {
    dispatch(setBaselineThresholdFilters({ search: searchNameRef?.current?.value, pageNumber: 1 }));
  };

  const handleClear = () => {
    if (searchNameRef?.current?.value) searchNameRef.current.value = '';
    setIsSearched(false);
    dispatch(setBaselineThresholdFilters({ search: '', status: null }));
  };

  return (
    <TableHead>
      <div className="table__search">
        <div className="table__search_item">
          <b>Organization Name</b>
          <div className="form">
            <div className="form__form-group">
              <div className="form__form-group-field">
                <div className="form__form-group-icon" style={{ height: 37 }}>
                  <MagnifyIcon />
                </div>
                <input
                  ref={searchNameRef}
                  className="search-bar-search-text"
                  name="searchText"
                  type="text"
                  placeholder="Search by Organization Name"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table__search_item mt-3">
          <Button color="primary" onClick={handleSearch}>
            {' '}
            Search{' '}
          </Button>
        </div>
        <div className="table__search_item mt-3">
          <Button color="secondary" onClick={handleClear} outline>
            Clear
          </Button>
        </div>
      </div>

      {isSearched && (
        <div className="ml-1">
          <span>Found {totalBaselineThresholdCount} matches</span>
        </div>
      )}
    </TableHead>
  );
}
