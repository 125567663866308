import React, { useMemo } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { useSkillUIContext } from './SkillUIProvider';
import SkillTableWrapper from './table/SkillTableWrapper';

export function SkillCard() {
  const skillsUIContext = useSkillUIContext();
  const skillsUIProps = useMemo(() => {
    return {
      openNewSkillDialog: skillsUIContext.openNewSkillDialog,
    };
  }, [skillsUIContext]);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Skill List</h3>
          <h3 className="page-subhead subhead">
            Here you can manage all the skills under base skills.
          </h3>
        </Col>
      </Row>
      <Row>
        <SkillTableWrapper />
      </Row>
    </Container>
  );
}
