import React, { useMemo } from 'react';
import { Route, Switch } from 'react-router';
import { Row, Col, Container } from 'reactstrap';
import { organizationsBaseUrl } from '..';
import PlatformDetails from './organization-view-dialog/benifits-and-photos';
import { useOrganizationUIContext } from './OrganizationUIProvider';
import OrganizationTableWrapper from './table/OrganizationTableWrapper';

export function OrganizationCard() {
  const organizationsUIContext = useOrganizationUIContext();
  const organizationsUIProps = useMemo(() => {
    return {
      openNewOrganizationDialog: organizationsUIContext.openNewOrganizationDialog,
    };
  }, [organizationsUIContext]);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Organization List</h3>
          <h3 className="page-subhead subhead">From here you can manage all the organizations.</h3>
        </Col>
      </Row>
      <Row>
        <Switch>
          <Route path={`${organizationsBaseUrl}/:id/view`}>
            {({ history, match }) => (
              <div className="w-100 organization-main-page">
                <PlatformDetails
                  show={match != null}
                  id={match?.params?.id ? +match?.params?.id : ''}
                  onHide={() => {
                    history.push(organizationsBaseUrl);
                  }}
                />
              </div>
            )}
          </Route>
          <Route path="">
            <OrganizationTableWrapper />
          </Route>
        </Switch>
      </Row>
    </Container>
  );
}
