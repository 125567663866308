import React from 'react';
import { Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import AssignCard from './AssignCard';
import * as Constants from '@/shared/helpers/constants';
import { setActiveTab, uncheckAllItem } from '../redux/actions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useAssignUIContext } from './CadsAIAssignUIProvider';

const ControlPanelAssignTabs = () => {
  const dispatch = useDispatch();
  const assignUIContext = useAssignUIContext();
  const { activeTab, checkedItemIds, isFromCourse, isFromLearningPath, isFromTrainingProvider } =
    useSelector((state) => state.cadsAIAssignPage, shallowEqual);
  const [assignBtnDisabled, setAssignBtnDisabled] = React.useState(true);
  const toggle = (tab) => {
    dispatch(setActiveTab(tab));
    dispatch(uncheckAllItem());
  };

  React.useEffect(() => {
    return () => {
      dispatch(setActiveTab(Constants.USERS));
    };
  }, []);

  React.useEffect(() => {
    if (checkedItemIds.length === 0) {
      setAssignBtnDisabled(true);
    } else {
      setAssignBtnDisabled(false);
    }
  }, [checkedItemIds.length]);

  return (
    <Col className="card-body">
      <div className="tabs tabs--bordered-bottom">
        <div className="tabs__wrap">
          <Nav tabs>
            {Object.keys(Constants.cadsAITabs).map((key) => (
              <NavItem key={Constants.cadsAITabs[key].id}>
                <NavLink
                  className={classnames({
                    active: activeTab === Constants.cadsAITabs[key].id,
                  })}
                  onClick={() => toggle(Constants.cadsAITabs[key].id)}
                >
                  {Constants.cadsAITabs[key].label}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={activeTab}>
              <AssignCard />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </Col>
  );
};

export default ControlPanelAssignTabs;
