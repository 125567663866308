import { handleActions } from 'redux-actions';
import {
  setCadsAiLearningPathFetching,
  setCadsAiAssignLearningPathList,
  setCadsAiLearningPathError,
  setCadsAiLearningPathFilters,
  toggleCadsAiCheckedLearningPath,
  clearCadsAiCheckedLearningPath,
  setCadsAiLearningPathGroupOptions,
  setCadsAiLearningPathSkills,
  clearCadsAiLearningPathFilters,
  updateCadsAiLearningPathInList,
  toggleCheckedCadsAiLP,
  uncheckAllLearningPath,
  setCadsAiLearningPathTypeOptions,
  setCadsAiLearningPathOtherSkills,
  toggleCheckedLearningPath,
  clearCheckedLearningPath,
  setModuleOptions,
  setLearningPathOptions,
} from './actions';

const defaultState = {
  loading: false,
  checkedLPIds: [],
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    search: '',
    isSortOrderDescending: false,
  },
  totalRecords: 0,
  assignLearningPaths: [],
  learningPathOptions: [],
  learningPathGroupOptions: [],
  learningPathSkills: [],
  learningPathTypeOptions: [],
  learningPathOtherSkills: [],
  moduleList: [],
  error: null,
  checkedLearningPath: { id: -1, name: 'Select Learning Path' }, //setting to -1 if no learningPath is selected
};

export default handleActions(
  {
    [setCadsAiLearningPathFetching](state) {
      return {
        ...state,
        assignLearningPaths: [],
        totalRecords: 0,
        loading: true,
        error: null,
      };
    },
    [setCadsAiLearningPathError](state, { payload }) {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    },
    [setCadsAiAssignLearningPathList](state, { payload }) {
      const assignLearningPathList = payload.data;
      return {
        ...state,
        assignLearningPaths: assignLearningPathList?.records,
        totalRecords: assignLearningPathList?.totalRecords,
        loading: false,
        error: null,
      };
    },
    [toggleCheckedLearningPath](state, { payload }) {
      return {
        ...state,
        checkedLearningPath: payload ?? defaultState.checkedLearningPath,
      };
    },
    [clearCheckedLearningPath](state) {
      return {
        ...state,
        checkedLearningPath: defaultState.checkedLearningPath,
      };
    },
    [toggleCheckedCadsAiLP](state, { payload: itemId }) {
      const checkedIds = state.checkedLPIds;
      return {
        ...state,
        checkedLPIds: checkedIds.includes(itemId)
          ? checkedIds.filter((id) => id !== itemId)
          : [...checkedIds, itemId],
      };
    },
    [uncheckAllLearningPath](state) {
      return {
        ...state,
        checkedLPIds: [],
      };
    },
    [setCadsAiLearningPathFilters](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
        checkedLPIds: [],
      };
    },
    [toggleCadsAiCheckedLearningPath](state, { payload }) {
      return {
        ...state,
        checkedLearningPath: payload ?? defaultState.checkedLearningPath,
      };
    },
    [clearCadsAiCheckedLearningPath](state) {
      return {
        ...state,
        checkedLearningPath: defaultState.checkedLearningPath,
      };
    },
    [setCadsAiLearningPathGroupOptions](state, { payload }) {
      return {
        ...state,
        learningPathGroupOptions: payload.data?.map((grp) => ({
          value: grp.id,
          label: grp.title,
          code: grp.code,
        })),
      };
    },
    [setCadsAiLearningPathSkills](state, { payload }) {
      return {
        ...state,
        learningPathSkills: payload.data?.map((skill) => ({ id: skill.id, name: skill.title })),
      };
    },
    [setCadsAiLearningPathTypeOptions](state, { payload }) {
      return {
        ...state,
        learningPathTypeOptions: payload.data?.map((skill) => ({
          id: skill.id,
          name: skill.title,
        })),
      };
    },
    [setModuleOptions](state, { payload }) {
      return {
        ...state,
        moduleList: payload.data?.map((module) => ({ id: module.id, name: module.title })),
      };
    },
    [setLearningPathOptions](state, { payload }) {
      return {
        ...state,
        learningPathOptions: payload.data,
      };
    },
    [setCadsAiLearningPathOtherSkills](state, { payload }) {
      return {
        ...state,
        learningPathOtherSkills: payload.data?.map((skill) => ({ id: skill.id, name: skill.name })),
      };
    },
    [clearCadsAiLearningPathFilters](state) {
      return {
        ...state,
        filters: { ...defaultState.filters },
      };
    },
    [updateCadsAiLearningPathInList](state, { payload }) {
      return {
        ...state,
        assignLearningPaths: state.assignLearningPaths?.map((lp) =>
          lp.id === payload.id ? { ...lp, ...payload } : lp,
        ),
      };
    },
  },
  defaultState,
);
