import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loading from '@/shared/components/Loading';
import { paths } from '@/shared/helpers/paths';
import { OrganizationsPage } from './components/OrganizationPage';
import './index.scss';

export const organizationsBaseUrl = paths.CADS_AI.ORGANIZATION;

export default function Organizations() {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path={organizationsBaseUrl} component={OrganizationsPage} />
      </Switch>
    </Suspense>
  );
}
