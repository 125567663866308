import React from 'react';
import { Field, Form } from 'react-final-form';
import { Alert, Button } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import AlternateEmailIcon from 'mdi-react/AlternateEmailIcon';
import { paths } from '@/shared/helpers/paths';
import api from '@/utils/apiClient';

const ForgotPasswordForm = ({}) => {
  const [forgotPassData, setForgotPassData] = React.useState({ inProgress: false });

  const onSubmit = async (forgotPasswordData) => {
    setForgotPassData({ ...forgotPasswordData, inProgress: true, error: '' });
    try {
      const { email } = forgotPasswordData;
      const { data, isSuccess } = await api.auth.forgotPassword(email);
      setForgotPassData({
        isSuccess: isSuccess && data?.data,
        error: data?.messages?.[0],
        email,
        inProgress: false,
      });
    } catch (error) {
      console.error('Error occurred while submitting email needed for forgot password : ', error);
      setForgotPassData({
        isSuccess: false,
        email: forgotPasswordData.email,
        inProgress: false,
        error: error.message,
      });
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <form className="form reset-password-form" onSubmit={handleSubmit}>
          <div className="form login-form w-100">
            <Alert color="danger" isOpen={forgotPassData.isSuccess === false}>
              {forgotPassData?.error || 'Error in forgot password'}
            </Alert>
            <Alert color="primary" isOpen={forgotPassData.isSuccess === true}>
              Password reset link has been sent to you email : {forgotPassData.email}
            </Alert>
          </div>
          {!!forgotPassData?.isSuccess === false && (
            <>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <AlternateEmailIcon />
                  </div>
                  <Field
                    name="email"
                    id="email"
                    component="input"
                    type="email"
                    placeholder="Your email"
                    className="input-without-border-radius"
                  />
                </div>
              </div>
              <Button
                className="account__btn"
                type="submit"
                color="primary"
                disabled={!!forgotPassData?.inProgress}
              >
                Submit
              </Button>
            </>
          )}
          <div className="account__have-account">
            <p>
              {forgotPassData?.isSuccess === true
                ? 'Back to login page ?'
                : 'Remember your password?'}{' '}
              <NavLink to={paths.LOGIN}>Login</NavLink>
            </p>
          </div>
        </form>
      )}
    </Form>
  );
};

export default ForgotPasswordForm;
