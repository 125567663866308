import { handleActions } from 'redux-actions';
import {
  setQuestionList,
  fetchQuestionList,
  setQuestionError,
  deleteQuestionFromList,
  setQuestionFilters,
  setQuestionLevelList,
  setQuestionTypeList,
  setQuestionTagList,
} from './actions';

const defaultState = {
  loading: false,
  filters: {
    pageNumber: 1,
    pageSize: 5,
    questionText: '',
    skillId: null,
    questionLevelId: null,
    sortBy: '',
    isSortOrderDescending: false,
    status: true,
  },
  totalRecords: 0,
  data: [],

  questionLevels: [],
  questionTypes: [],
  questionTags: [],
  error: null,
};

export default handleActions(
  {
    [fetchQuestionList](state) {
      return {
        ...state,
        loading: true,
        data: [],
      };
    },
    [setQuestionList](state, { payload }) {
      return {
        ...state,
        loading: false,
        data: payload.data.records,
        totalRecords: payload.data.totalRecords,
        error: null,
      };
    },
    [setQuestionError](state, { payload }) {
      return {
        ...state,
        data: [],
        loading: false,
        totalPages: defaultState.totalPages,
        error: payload?.message,
      };
    },
    [setQuestionFilters](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
      };
    },
    [deleteQuestionFromList](state, { payload }) {
      return {
        ...state,
        data: state.data.filter((question) => question.id !== payload),
        totalRecords: state.totalRecords - 1,
      };
    },

    [setQuestionLevelList](state, { payload }) {
      return {
        ...state,
        questionLevels: payload.data,
      };
    },
    [setQuestionTypeList](state, { payload }) {
      return {
        ...state,
        questionTypes: payload.data,
      };
    },
    [setQuestionTagList](state, { payload }) {
      return {
        ...state,
        questionTags: payload.data,
      };
    },
  },
  defaultState,
);
