import { sendRequest } from '../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const sunrise25UsersEndpoints = {
  GET_SUNRISE_25_USERS: '/getSunrise25CampaignReport',
};

const getSunrise25CampaignReport = (filter) => {
  return sendRequest(
    {
      url: sunrise25UsersEndpoints.GET_SUNRISE_25_USERS,
      method: httpMethod.GET,
      // params: filter,
    },
    process.env.REACT_APP_CAMPAIGN_ADMIN_ENDPOINT,
  );
};

export default {
  getSunrise25CampaignReport,
};
