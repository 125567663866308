import { handleActions } from 'redux-actions';
import {
  fetchSponsorshipList,
  setSponsorshipList,
  setSponsorshipError,
  setSponsorshipFilters,
  toggleCheckedSponsorship,
  uncheckAllSponsorships,
} from './actions';

const defaultState = {
  loading: false,
  checkedSponsorshipIds: [],
  filters: {
    pageNumber: 1,
    pageSize: 10,
    postType: 'sponsorships',
    sortBy: '',
    search: '',
    isSortOrderDescending: false,
    status: null,
  },
  totalRecords: 0,
  data: [],
  options: [],
  error: null,
};

export default handleActions(
  {
    [fetchSponsorshipList](state) {
      return {
        ...state,
        loading: true,
        data: [],
      };
    },
    [setSponsorshipList](state, { payload }) {
      return {
        ...state,
        loading: false,
        data: payload.data.records,
        totalRecords: payload.data.totalRecords,
        error: null,
      };
    },
    [setSponsorshipError](state, { payload }) {
      return {
        ...state,
        data: [],
        loading: false,
        totalPages: defaultState.totalPages,
        error: payload?.message,
      };
    },
    [setSponsorshipFilters](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
        checkedSponsorshipIds: [],
      };
    },
    [toggleCheckedSponsorship](state, { payload: sponsorshipId }) {
      const checkedIds = state.checkedSponsorshipIds;
      return {
        ...state,
        checkedSponsorshipIds: checkedIds.includes(sponsorshipId)
          ? checkedIds.filter((id) => id !== sponsorshipId)
          : [...checkedIds, sponsorshipId],
      };
    },
    [uncheckAllSponsorships](state) {
      return {
        ...state,
        checkedSponsorshipIds: [],
      };
    },
  },
  defaultState,
);
