import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const setCourseFetching = createAction('CADS_AI_SET_COURSE_FETCHING');
export const setCourseError = createAction('CADS_AI_SET_COURSE_ERROR');
export const setCourseAssignList = createAction('CADS_AI_SET_ASSIGN_COURSE_LIST');
export const setCadsAiCourseFilters = createAction('CADS_AI_SET_COURSE_LIST');
export const toggleCheckedCourse = createAction('CADS_AI_TOGGLE_CHECKED_COURSE');
export const clearCheckedCourse = createAction('CADS_AI_CLEAR_SELECTED_COURSE');
export const clearCourseFilters = createAction('CADS_AI_CLEAR_COURSE_FILTERS');
export const updateCourseInList = createAction('CADS_AI_UPDATE_COURSE_IN_LIST');
export const toggleCheckedCadsAiCourse = createAction('CADS_AI_TOGGLE_CHECKED_COURSES');
export const uncheckAllCourses = createAction('CADS_AI_UNCHECK_ALL_COURSE');
export const setAssignModuleId = createAction('CADS_AI_SET_ASSIGN_MODULE_ID');
export const setCourseOptions = createAction('CADS_AI_SET_COURSE_OPTIONS');

export const toggleCheckedItemById = (id) => (dispatch) => {
  dispatch(toggleCheckedCadsAiCourse(id));
};

export const uncheckAllSelectedCourses = () => (dispatch) => {
  dispatch(uncheckAllCourses());
};

export const getCadsAiCoursesList = (filter) => async (dispatch) => {
  const defaultError = `Error occurred getting module list`;
  dispatch(setCourseFetching());
  try {
    const { data, isSuccess } = await api.cadsAICourse.getCadsAiCourses(filter);
    if (isSuccess) dispatch(setCourseAssignList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setCourseError(error.message));
    console.error(defaultError, error.message);
  }
};

export const getAllCourseOptions = () => async (dispatch) => {
  const defaultError = `Error occurred getting course options list`;
  try {
    const { data, isSuccess } = await api.cadsAIPrograms.getCourseOptions();
    if (isSuccess) dispatch(setCourseOptions(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    console.error(defaultError, error.message);
  }
};

export const setSelectedModuleId = (orgId) => (dispatch) => {
  dispatch(setAssignModuleId(orgId));
};
