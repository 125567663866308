import React from 'react';
import { Col, Nav, NavItem, NavLink, TabContent, TabPane, Button, ButtonToolbar } from 'reactstrap';
import classnames from 'classnames';
import AssignCard from './AssignCard';
import * as Constants from '@/shared/helpers/constants';
import { setActiveTab, uncheckAllItem } from '../redux/actions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useAssignUIContext } from './ControlPanelAssignUIProvider';
import { COHORT, ORGANIZATION } from '@/shared/helpers/constants';

const ControlPanelAssignTabs = () => {
  const dispatch = useDispatch();
  const assignUIContext = useAssignUIContext();
  const { activeTab, checkedItemIds, isFromModule, isFromLearningPath, isFromTrainingProvider } =
    useSelector((state) => state.assignPage, shallowEqual);
  const [assignBtnDisabled, setAssignBtnDisabled] = React.useState(true);
  const toggle = (tab) => {
    dispatch(setActiveTab(tab));
    dispatch(uncheckAllItem());
  };

  React.useEffect(() => {
    return () => {
      dispatch(setActiveTab(Constants.USERS));
    };
  }, []);

  React.useEffect(() => {
    if (checkedItemIds.length === 0) {
      setAssignBtnDisabled(true);
    } else {
      setAssignBtnDisabled(false);
    }
  }, [checkedItemIds.length]);

  return (
    <Col className="card-body">
      <div className="tabs tabs--bordered-bottom">
        <div className="tabs__wrap">
          <Nav tabs>
            {Object.keys(Constants.controlPanelTabs)
              .filter((key) =>
                isFromTrainingProvider
                  ? key === ORGANIZATION
                  : isFromModule || isFromLearningPath
                  ? key !== COHORT
                  : key,
              )
              .map((key) => (
                <NavItem key={Constants.controlPanelTabs[key].id}>
                  <NavLink
                    className={classnames({
                      active: activeTab === Constants.controlPanelTabs[key].id,
                    })}
                    onClick={() => toggle(Constants.controlPanelTabs[key].id)}
                  >
                    {Constants.controlPanelTabs[key].label}
                  </NavLink>
                </NavItem>
              ))}
            {!isFromModule && !isFromLearningPath && !isFromTrainingProvider && (
              <ButtonToolbar className="products-list__btn-toolbar-top">
                <Button
                  disabled={assignBtnDisabled}
                  color="success"
                  outline
                  className="mb-0 mr-3 mt-2"
                  onClick={() => assignUIContext.openAssignDialog()}
                >
                  Assign
                </Button>
              </ButtonToolbar>
            )}
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={activeTab}>
              <AssignCard />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </Col>
  );
};

export default ControlPanelAssignTabs;
