import React, { useMemo } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { useCustomerUIContext } from './CustomerUIProvider';
import CustomerTableWrapper from './table/CustomerTableWrapper';

export function CustomerCard() {
  const customersUIContext = useCustomerUIContext();
  const customersUIProps = useMemo(() => {
    return {
      openNewCustomerDialog: customersUIContext.openNewCustomerDialog,
    };
  }, [customersUIContext]);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Customer List</h3>
          <h3 className="page-subhead subhead">From here you can manage all the customers.</h3>
        </Col>
      </Row>
      <Row>
        <CustomerTableWrapper />
      </Row>
    </Container>
  );
}
