import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const fetchEmailList = createAction('CADS_AI_FETCH_EMAIL_LIST');
export const setEmailList = createAction('CADS_AI_SET_EMAIL_LIST');
export const setEmailError = createAction('CADS_AI_SET_EMAIL_ERROR');
export const setEmailFilters = createAction('CADS_AI_SET_EMAIL_FILTERS');
export const uncheckAllEmail = createAction('CADS_AI_UNCHECK_ALL_EMAIL');

export const getEmailList = (filter) => async (dispatch) => {
  const defaultError = `Error occurred getting email list`;
  dispatch(fetchEmailList());
  try {
    const { data, isSuccess } = await api.cadsAIEmail.getEmailList(filter);
    if (isSuccess) dispatch(setEmailList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setEmailError(error.message));
    console.error(defaultError, error.message);
  }
};
