import { sendRequest } from '../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

//TODO: user proper endpoint for announcement
const announcementBaseUrl = process.env.REACT_APP_CADS_AI_API + '/notification'; // it is weired, but we are using notification endpoint to get the announcement which is essentially subset of notification

export const announcementEndpoints = {
  GET_ALL_ANNOUNCEMENTS: `${announcementBaseUrl}/getAllNotifications`,
  GET_ANNOUNCEMENT_BY_ID: `${announcementBaseUrl}/getNotification`,
  ADD_ANNOUNCEMENT: `${announcementBaseUrl}/addNotification`,
  EDIT_ANNOUNCEMENT: `${announcementBaseUrl}/editNotification`,
  DEACTIVATE_ANNOUNCEMENT: `${announcementBaseUrl}/deactivateNotifications`,
};

const getAnnouncementList = (filter) => {
  return sendRequest({
    fullUrl: announcementEndpoints.GET_ALL_ANNOUNCEMENTS,
    method: httpMethod.GET,
    params: filter,
  });
};

const addAnnouncement = (announcement) => {
  return sendRequest({
    fullUrl: announcementEndpoints.ADD_ANNOUNCEMENT,
    method: httpMethod.POST,
    data: announcement,
  });
};

const updateAnnouncement = (announcement) => {
  return sendRequest({
    fullUrl: announcementEndpoints.EDIT_ANNOUNCEMENT,
    method: httpMethod.PUT,
    data: announcement,
  });
};

const getAnnouncementById = (announcementId) => {
  return sendRequest({
    fullUrl: announcementEndpoints.GET_ANNOUNCEMENT_BY_ID,
    method: httpMethod.GET,
    params: { id: announcementId },
  });
};

const deactivateAnnouncement = (announcementIds) => {
  return sendRequest({
    fullUrl: announcementEndpoints.DEACTIVATE_ANNOUNCEMENT,
    method: httpMethod.DELETE,
    data: { announcementIds },
  });
};

export default {
  getAnnouncementList,
  addAnnouncement,
  updateAnnouncement,
  getAnnouncementById,
  deactivateAnnouncement,
};
