import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';

const StatsCard = ({ title, stats, Icon, colorFill }) => {
  return (
    <Col md={12} xl={2} lg={6} xs={12}>
      <Card>
        <CardBody className="dashboard__booking-card">
          <div className="dashboard__booking-total-container">
            <h5
              className={`dashboard__booking-total-title dashboard__booking-total-title`}
              style={{ color: colorFill }}
            >
              {stats}
            </h5>
            <Icon className="datastar-stats-icon mt-auto" />
          </div>
          <h5 className="dashboard__booking-total-description">{title}</h5>
        </CardBody>
      </Card>
    </Col>
  );
};

export default StatsCard;
