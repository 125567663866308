import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'reactstrap';
import LoadingIcon from 'mdi-react/LoadingIcon';
import UserViewDialogHeader from './UserViewDialogHeader';
import UserViewTabs from './UserViewTabs';

const UserViewDialog = ({ show, onHide, id }) => {
  const { rtl, campaignUser, loading, error } = useSelector((state) => state);
  const [modal, setModal] = useState({
    isOpen: show,
    title: 'View User',
    hide: onHide,
  });

  const [userData, setUserData] = useState({});
  useEffect(() => {
    const user = campaignUser.data.find((item) => item.id == id);
    setUserData(user);
  }, [id]);

  return (
    <Modal
      isOpen={show}
      toggle={modal.hide}
      modalClassName={`${rtl.direction}-support`}
      className="modal-dialog--primary modal-dialog--header modal-view-dialog"
    >
      <UserViewDialogHeader modal={modal} user={userData} />
      {/*User Modal Body*/}
      <div style={{ padding: 15 }}>
        {loading || !!error ? (
          <>
            {loading && (
              <div className="panel__refresh position-relative" style={{ height: 75 }}>
                <LoadingIcon />
              </div>
            )}
            <div className="text-center">
              {error && (
                <div>
                  <span className="lnr lnr-cross-circle modal__title-icon error" />
                </div>
              )}
              {loading ? 'Getting user details...' : error}
            </div>
          </>
        ) : (
          <>
            <UserViewTabs user={userData} />
          </>
        )}
      </div>
    </Modal>
  );
};

export default UserViewDialog;
