import { shallowEqual, useSelector } from 'react-redux';
import React from 'react';
import api from '@/utils/apiClient';
import { Modal } from 'reactstrap';
import Select from 'react-select';
import LoadingIcon from 'mdi-react/LoadingIcon';

const ViewMoreDialog = ({ show, onHide, learningPaths }) => {
  const { rtl } = useSelector((state) => state, shallowEqual);

  const initialValues = {
    isOpen: show,
    message: '',
    hide: onHide,
    loading: false,
    viewSuccess: false,
    viewError: false,
  };
  const [modal, setModal] = React.useState(initialValues);
  const [scheduleList, setScheduleList] = React.useState([]);
  const [moduleList, setModuleList] = React.useState([]);
  const [selectedLP, setSelectedLP] = React.useState({ value: -1, label: 'Select Learning Path' });
  const [selectedModule, setSelectedModule] = React.useState({ value: -1, label: 'Select Module' });

  React.useEffect(() => {
    if (!show) {
      setSelectedLP({ value: -1, label: 'Select Learning Path' });
      setSelectedModule({ value: -1, label: 'Select Module' });
      setModuleList([]);
      setScheduleList([]);
    }
  }, [show]);

  const getMoreDetailsOfLearningPath = async (lpId) => {
    setModal({ ...modal, loading: true });
    const { data: res, isSuccess } = await api.modules.getModuleDtlsByLearningPathId(lpId);
    if (isSuccess) {
      setModuleList(
        res?.data?.map((module) => ({ value: module?.id, label: module?.name, ...module })),
      );
    } else {
      setModuleList([]);
    }
    setModal({ ...modal, loading: false });
  };

  const getMoreDetailsOfModule = async (moduleId) => {
    setModal({ ...modal, loading: true });
    const { data: res, isSuccess } = await api.moduleSchedules.getModuleScheduleListSS(moduleId);
    if (isSuccess) {
      setScheduleList(res?.data ?? []);
    } else {
      setScheduleList([]);
    }
    setModal({ ...modal, loading: false });
  };

  React.useEffect(() => {
    if (selectedLP.value !== -1) {
      setSelectedModule({ value: -1, label: 'Select Module' });
      getMoreDetailsOfLearningPath(selectedLP.value);
    }
  }, [selectedLP]);

  React.useEffect(() => {
    if (selectedModule.value !== -1) {
      getMoreDetailsOfModule(selectedModule.value);
    }
  }, [selectedModule]);

  const { loading, error } = modal;

  return (
    <Modal
      isOpen={show}
      toggle={onHide}
      modalClassName={`${rtl.direction}-support`}
      className="modal-dialog--primary modal-dialog--header modal-view-dialog"
    >
      <div className="modal__header">
        <button
          className="lnr lnr-cross modal__close-btn"
          aria-label="modal__close-btn"
          type="button"
          onClick={onHide}
        />
        <h4 className="text-modal  modal__title">View Learning Path Details</h4>
      </div>
      <div className="modal__body">
        <p style={{ fontWeight: 'bold', color: 'gray' }}>Select Learning Path</p>
        <Select
          name="selectLP"
          value={selectedLP}
          onChange={setSelectedLP}
          autosize={true}
          options={learningPaths?.map((l) => ({ value: l?.lpId, label: l?.fullName })) ?? []}
          clearable={false}
          className="react-select mt-1 mb-3"
          placeholder={'Select Learning Path'}
          classNamePrefix="react-select"
        />
        {selectedLP && selectedLP.value !== -1 && (
          <>
            <p style={{ fontWeight: 'bold', color: 'gray' }}>Select Module</p>
            <Select
              name="selectModule"
              value={selectedModule}
              onChange={setSelectedModule}
              autosize={true}
              options={moduleList?.map((m) => ({ value: m?.id, label: m?.name, ...m })) ?? []}
              clearable={false}
              className="react-select mt-1 mb-3"
              placeholder={'Select Module'}
              classNamePrefix="react-select"
            />
          </>
        )}
      </div>

      <div>
        {loading || !!error ? (
          <>
            {loading && (
              <div className="panel__refresh position-relative" style={{ height: 75 }}>
                <LoadingIcon />
              </div>
            )}
            <div className="text-center">
              {error && (
                <div>
                  <span className="lnr lnr-cross-circle modal__title-icon error" />
                </div>
              )}
              {loading ? 'Getting Learning Path details ...' : error}
            </div>
          </>
        ) : (
          selectedModule &&
          selectedModule.value !== -1 && (
            <ul style={{ marginLeft: 26, marginBottom: 20 }}>
              {scheduleList?.map((schedule) => (
                <li>{schedule?.name}</li>
              ))}
            </ul>
          )
        )}
      </div>
    </Modal>
  );
};

export default ViewMoreDialog;
