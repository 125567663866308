import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Loading from '@/shared/components/Loading';
import { paths } from '@/shared/helpers/paths';
import Sunrise25UsersPage from './components/Sunrise25UsersPage';

export const campaignSunrise25UsersBaseUrl = paths.CAMPAIGN.SUNRISE25USERS;

const Sunrise25Users = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path={campaignSunrise25UsersBaseUrl} component={Sunrise25UsersPage} />
      </Switch>
    </Suspense>
  );
};

export default Sunrise25Users;
