import React from 'react';
import { Button, ButtonToolbar, CardBody, FormGroup, Input } from 'reactstrap';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import LoadingIcon from 'mdi-react/LoadingIcon';
import TablePagination from '@material-ui/core/TablePagination';
import { defaultPaginationProps } from '@/shared/helpers';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import api from '@/utils/apiClient';
import { isEmpty } from 'lodash/lang';

const CustomerColumns = [
  {
    id: 'checkbox',
    disablePadding: true,
    label: '',
  },
  {
    id: 'organizationName',
    disablePadding: true,
    label: 'Customer Name',
  },
  {
    id: 'email',
    disablePadding: true,
    label: 'Email',
  },
];

const CustomerTableSearchBar = React.memo(({ totalCustomer, filters, setFilters }) => {
  const [isSearched, setIsSearched] = React.useState(false);
  const [search, setSearch] = React.useState('');

  const handleSearch = () => {
    setIsSearched(true);
    setFilters({ ...filters, search, pageNumber: 1 });
  };

  const handleClear = () => {
    setSearch('');
    setIsSearched(false);
    setFilters({ ...filters, search: '' });
  };

  return (
    <TableHead>
      <div className="table__search">
        <div className="table__search_item">
          <b>Customer Name</b>
          <div className="form">
            <div className="form__form-group">
              <div className="form__form-group-field">
                <div className="form__form-group-icon" style={{ height: 37 }}>
                  <MagnifyIcon />
                </div>
                <input
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="search-bar-search-text"
                  name="searchText"
                  type="text"
                  placeholder="Search by Customer Name"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table__search_item mt-3">
          <Button color="primary" type="button" onClick={handleSearch} outline>
            {' '}
            Search{' '}
          </Button>
        </div>
        <div className="table__search_item mt-3">
          <Button color="secondary" onClick={handleClear} outline>
            Clear
          </Button>
        </div>
      </div>

      {isSearched && (
        <div className="ml-1">
          <span>Found {totalCustomer} matches</span>
        </div>
      )}
    </TableHead>
  );
});

const CustomerTableColumns = React.memo(({ order, orderBy, onRequestSort }) => {
  return (
    <TableRow>
      {CustomerColumns.map((row) => (
        <TableCell
          className="material-table__cell material-table__cell--sort material-table__cell-center"
          key={row.id}
          align={'center'}
          padding={row.disablePadding ? 'none' : 'default'}
          sortDirection={orderBy === row.id ? order : false}
        >
          <TableSortLabel
            active={orderBy === row.id}
            direction={order}
            onClick={(event) => onRequestSort(event, row.id)}
            className="material-table__sort-label"
            dir="ltr"
          >
            <b>{row.label}</b>
          </TableSortLabel>
        </TableCell>
      ))}
    </TableRow>
  );
});

const CustomerTableRows = React.memo(({ customers, checkedCustomer, setCheckedCustomer }) => {
  return customers?.map((customer) => (
    <TableRow className="material-table" tabIndex={-1} key={customer.organizationId}>
      <TableCell align="center" className="material-table__cell">
        <FormGroup>
          <Input
            className="table-checkbox"
            type="checkbox"
            value={customer.organizationId}
            checked={checkedCustomer?.organizationId === customer.organizationId}
            onChange={() => setCheckedCustomer(customer)}
          />
        </FormGroup>
      </TableCell>
      <TableCell align="center" className="material-table__cell">
        {customer?.organizationName}
      </TableCell>
      <TableCell align="center" className="material-table__cell">
        {customer?.email}
      </TableCell>
    </TableRow>
  ));
});

const StepOne = ({ nextPage, checkedCustomer, setCheckedCustomer }) => {
  const [state, setState] = React.useState({
    loading: false,
    message: '',
    isError: false,
    isSuccess: false,
    customers: [],
    totalRecords: 0,
  });
  const [filters, setFilters] = React.useState({
    pageSize: 10,
    pageNumber: 1,
    sortBy: '',
    isSortOrderDescending: false,
    search: '',
  });
  const {
    pageSize: rowsPerPage,
    pageNumber: page,
    sortBy: orderBy,
    isSortOrderDescending,
  } = filters;
  const { customers, totalRecords, loading, isSuccess, isError, message } = state;

  const getCustomerListForAssignment = async (filters) => {
    const defaultError = 'Error occurred getting customers list';
    let updatedState = {};
    try {
      setState({ ...state, loading: true });
      const { data: res, isSuccess } = await api.customers.getCustomerList(filters);
      if (isSuccess)
        updatedState = {
          ...state,
          isSuccess: true,
          isError: false,
          customers: res.data.records,
          totalRecords: res.data.totalRecords,
        };
      else throw new Error(res?.data?.messages?.[0] ?? defaultError);
    } catch (error) {
      updatedState = { ...state, isSuccess: false, isError: true, customers: [], totalRecords: 0 };
      console.error(defaultError, error.message);
    } finally {
      setState({ ...updatedState, loading: false });
    }
  };

  React.useEffect(() => {
    getCustomerListForAssignment(filters);
  }, [filters]);

  const handleRequestSort = (event, property) => {
    setFilters({ ...filters, sortBy: property, isSortOrderDescending: !isSortOrderDescending });
  };

  const handleChangePage = (event, currentPage) => {
    setFilters({ ...filters, pageSize: rowsPerPage, pageNumber: currentPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilters({ ...filters, pageSize: +event.target.value, pageNumber: 1 });
  };

  const emptyRows =
    page === Math.ceil(totalRecords / rowsPerPage) ? rowsPerPage - customers.length : 0;

  return (
    <form className="form step-one">
      {/*Customer Table*/}
      <CardBody className="col mt-4">
        <div className="material-table__wrap">
          <CustomerTableSearchBar
            totalCustomer={totalRecords}
            setFilters={setFilters}
            filters={filters}
          />
          <Table className="material-table">
            <TableBody>
              <CustomerTableColumns
                order={isSortOrderDescending ? 'desc' : 'asc'}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              {(loading || isError) && (
                <TableRow>
                  <TableCell colSpan={CustomerColumns.length}>
                    {loading && (
                      <div className={'text-center overflow-hidden'}>
                        <div className="panel__refresh position-relative" style={{ height: 75 }}>
                          <LoadingIcon />
                        </div>
                      </div>
                    )}
                    <div className={'text-center'}>
                      <p className={isError ? 'm-5' : ''}>
                        {isError ? message : 'Loading Customers...'}
                      </p>
                    </div>
                  </TableCell>
                </TableRow>
              )}
              <CustomerTableRows
                customers={customers}
                checkedCustomer={checkedCustomer}
                setCheckedCustomer={setCheckedCustomer}
              />
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={CustomerColumns.length} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          count={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          component={defaultPaginationProps.component}
          backIconButtonProps={defaultPaginationProps.backIconButton}
          nextIconButtonProps={defaultPaginationProps.nextIconButton}
          rowsPerPageOptions={defaultPaginationProps.rowsPerPage}
          className={defaultPaginationProps.className}
          dir={defaultPaginationProps.dir}
        />
      </CardBody>

      <br />

      <ButtonToolbar className="form__button-toolbar wizard__toolbar">
        <Button
          color="primary"
          type="button"
          className="next"
          onClick={nextPage}
          disabled={isEmpty(checkedCustomer)}
        >
          Next
        </Button>
      </ButtonToolbar>
    </form>
  );
};

export default StepOne;
