import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loading from '@/shared/components/Loading';
import { paths } from '@/shared/helpers/paths';
import { ModulePage } from './components/ModulePage';
import './index.scss';

const moduleBaseUrl = paths.CADS_AI.LEARNING.MODULE;
export default function Modules() {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path={moduleBaseUrl} component={ModulePage} />
      </Switch>
    </Suspense>
  );
}
