import { sendRequest } from '../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const baseUrl = process.env.REACT_APP_CADS_AI_API + process.env.REACT_APP_ADMIN_ENDPOINT;

export const authEndpoints = {
  LOGIN: `${baseUrl}/login`,
  REFRESH_TOKEN: '/refreshToken',
  CHANGE_PASSWORD: '/changePassword',
  FORGOT_PASSWORD: '/forgetPassword',
  RESET_PASSWORD: '/resetPassword',
};

/**
 * @param loginData Information required for login.
 * @param {string} loginData.userName user's username.
 * @param {string} loginData.password user's password.
 */
const login = (loginData) => {
  return sendRequest({
    fullUrl: authEndpoints.LOGIN,
    method: httpMethod.POST,
    data: loginData,
  });
};

/**
 * @param {*&{token: string}} passwordData Information required for password reset.
 * @param {string} passwordData.oldPassword user's old password.
 * @param {string} passwordData.newPassword user's new password.
 * @param {string} passwordData.renewPassword user's reentered password.
 */
const changePassword = (passwordData) => {
  return sendRequest({
    url: authEndpoints.CHANGE_PASSWORD,
    method: httpMethod.POST,
    data: passwordData,
  });
};

/**
 * @param {string} email user's email on which password reset link will be sent.
 */
const forgotPassword = (email) => {
  return sendRequest({
    url: authEndpoints.FORGOT_PASSWORD,
    method: httpMethod.POST,
    data: { email },
  });
};

/**
 * @param {*&{resetKey: string}} resetPasswordData data required to reset password
 * @param {string} resetPasswordData.resetKey password reset key
 * @param {string} resetPasswordData.newPassword new password
 * @param {string} resetPasswordData.renewPassword retyped new password
 */
const resetPassword = (resetPasswordData) => {
  return sendRequest({
    url: authEndpoints.RESET_PASSWORD,
    method: httpMethod.POST,
    data: resetPasswordData,
  });
};

export default {
  login,
  changePassword,
  forgotPassword,
  resetPassword,
};
