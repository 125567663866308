import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import DSCohortViewDialogHeader from './DSCohortViewDialogHeader';
import api from '@/utils/apiClient';
import LoadingIcon from 'mdi-react/LoadingIcon';
import SanitizeHTML from '@/shared/components/SanitizeHTML';

const DSCohortsViewDialog = ({ show, onHide, id, rtl }) => {
  const [modal] = useState({
    isOpen: show,
    title: 'View DS Cohort',
    hide: onHide,
  });

  const [state, setState] = useState({
    loading: true,
    dsCohort: {},
    error: '',
  });

  useEffect(() => {
    if (id) getDSCohortById(+id);
  }, [id]);

  const getDSCohortById = async (id) => {
    try {
      const { data: res, isSuccess } = await api.cadsAIDSCohort.getDSCohortById(id);
      if (id) {
        setState({ ...state, loading: false, dsCohort: res.data, error: '' });
      } else {
        throw new Error(res?.messages?.[0] ?? 'Error while getting ds cohort details');
      }
    } catch ({ message }) {
      setState({ ...state, loading: false, dsCohort: {}, error: message });
      console.error(message);
    }
  };

  const { error, loading, dsCohort } = state;

  return (
    <Modal
      isOpen={show}
      toggle={modal.hide}
      modalClassName={`${rtl.direction}-support`}
      className="modal-dialog--primary modal-dialog--header modal-view-dialog"
    >
      <DSCohortViewDialogHeader modal={modal} dsCohort={dsCohort} />
      {/* Sponosrship Modal Body */}
      <div style={{ padding: 15 }}>
        {loading || !!error ? (
          <>
            {loading && (
              <div className="panel__refresh position-relative" style={{ height: 75 }}>
                <LoadingIcon />
              </div>
            )}
            <div className="text-center">
              {error && (
                <div>
                  <span className="lnr lnr-cross-circle modal__title-icon error" />
                </div>
              )}
              {loading ? 'Getting ds cohort details...' : error}
            </div>
          </>
        ) : (
          <table className="project-summary__info">
            <tr>
              <th>Title</th>
              <td>{dsCohort?.title}</td>
            </tr>
            <tr>
              <th>Description</th>
              <td>
                <div className="question-extra-html">
                  <SanitizeHTML html={dsCohort.description ?? '<p/>'} />
                </div>
              </td>
            </tr>
            <tr>
              <th>Program</th>
              <td>{dsCohort?.program?.title}</td>
            </tr>
          </table>
        )}
      </div>
    </Modal>
  );
};

export default connect((state) => ({
  rtl: state.rtl,
}))(DSCohortsViewDialog);
