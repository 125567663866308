import React, { createContext, useContext } from 'react';

const EmailUIContext = createContext();

export function useEmailUIContext() {
  return useContext(EmailUIContext);
}

export function EmailUIProvider({ emailsUIEvents, children }) {
  const value = {
    openViewEmailDialog: emailsUIEvents.openViewEmailDialog,
    openNewEmailDialog: emailsUIEvents.openNewEmailDialog,
  };

  return <EmailUIContext.Provider value={value}>{children}</EmailUIContext.Provider>;
}
