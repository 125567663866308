import React, { useState } from 'react';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';

const renderAccountView = (customer) => (
  <table className="project-summary__info">
    <tr>
      <th>Name</th>
      <td>{customer?.companyName}</td>
    </tr>
    <tr>
      <th>Email</th>
      <td>{customer?.email}</td>
    </tr>
    <tr>
      <th>Hostname</th>
      <td>
        {customer?.subdomain}.talentspy.staging.cads.ai {' | '}
        <a href={`https://${customer?.subdomain}.talentspy.staging.cads.ai/`} target="_blank">
          Talent
        </a>{' '}
        {' | '}
        <a href={`https://${customer?.subdomain}.talentspy.staging.cads.ai/`} target="_blank">
          Admin
        </a>
      </td>
    </tr>
  </table>
);

const renderCompanyProfileView = (customer) => (
  <table className="project-summary__info">
    <tr>
      <th>Email</th>
      <td>
        <a href={`mailto:${customer?.email}`}>{customer?.email}</a>
      </td>
    </tr>
    <tr>
      <th style={{ width: 132 }}>Registration Number</th>
      <td>{customer?.registrationNumber}</td>
    </tr>
    <tr>
      <th>No. of Employees</th>
      <td>{customer?.noOfEmp}</td>
    </tr>
    <tr>
      <th>Country</th>
      <td>{customer?.country?.name}</td>
    </tr>
    <tr>
      <th>Address</th>
      <td>{customer?.address}</td>
    </tr>
  </table>
);

const renderContactPersonView = (customer) => (
  <table className="project-summary__info">
    <tr>
      <th style={{ width: 132 }}>First Name</th>
      <td>{customer?.firstName}</td>
    </tr>
    <tr>
      <th>Last Name</th>
      <td>{customer?.lastName}</td>
    </tr>
    <tr>
      <th>Role</th>
      <td>Administrator</td>
      {/*TODO: change this when we have actual roles*/}
    </tr>
    <tr>
      <th>Phone (Office)</th>
      <td>{customer?.phoneNumberOffice}</td>
    </tr>
    <tr>
      <th>Phone (Mobile)</th>
      <td>{customer?.phoneNumberMobile}</td>
    </tr>
  </table>
);

const renderNotesView = (customer) => <p>{customer?.notes}</p>;

export default function ({ customer }) {
  const tabs = React.useMemo(
    () => ({
      ACCOUNT: { id: 'ACCOUNT', label: 'Account', renderTab: () => renderAccountView(customer) },
      COMPANY_PROFILE: {
        id: 'COMPANY_PROFILE',
        label: 'Company Profile',
        renderTab: () => renderCompanyProfileView(customer),
      },
      CONTACT_PERSON: {
        id: 'CONTACT_PERSON',
        label: 'Contact Person',
        renderTab: () => renderContactPersonView(customer),
      },
      NOTES: { id: 'NOTES', label: 'Notes', renderTab: () => renderNotesView(customer) },
    }),
    [customer],
  );

  const [activeTab, setActiveTab] = useState(tabs.ACCOUNT.id);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Row className="p-1">
      <Col>
        <div className="tabs tabs--bordered-bottom">
          <div className="tabs__wrap">
            <Nav tabs>
              {Object.keys(tabs).map((key) => (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === tabs[key].id })}
                    onClick={() => toggle(tabs[key].id)}
                  >
                    {tabs[key].label}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={activeTab}>{tabs[activeTab].renderTab()}</TabPane>
            </TabContent>
          </div>
        </div>
      </Col>
    </Row>
  );
}
