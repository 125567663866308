import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const fetchSubCategoryList = createAction('FETCH_SUB_CATEGORY_LIST');
export const setSubCategoryList = createAction('SET_SUB_CATEGORY_LIST');
export const setSubCategoryOptionList = createAction('SET_SUB_CATEGORY_OPTION_LIST');
export const updateInSubCategoryList = createAction('UPDATE_SUB_CATEGORY_IN_LIST');
export const setSubCategoryError = createAction('SET_SUB_CATEGORY_ERROR');
export const setSubCategoryFilters = createAction('SET_SUB_CATEGORY_FILTERS');
export const deleteSubCategoryFromList = createAction('DELETE_SUB_CATEGORY_FROM_LIST');

export const getSubCategoriesList = (filter) => async (dispatch) => {
  const defaultError = 'Error occurred getting subcategories list';
  try {
    dispatch(fetchSubCategoryList());
    const { data, isSuccess } = await api.subCategory.getSubCategoriesList(filter);
    if (isSuccess) dispatch(setSubCategoryList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setSubCategoryError(error));
    console.error(defaultError, error.message);
  }
};

export const getSubCategoriesOptionsList = () => async (dispatch) => {
  const defaultError = 'Error occurred getting subcategories options list';
  try {
    const { data, isSuccess } = await api.subCategory.getSubcategoriesOptionsList();
    if (isSuccess) dispatch(setSubCategoryOptionList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    console.error(defaultError, error.message);
  }
};

export const updateSubCategoryInList = (subcategory) => (dispatch) => {
  try {
    dispatch(updateInSubCategoryList(subcategory.data));
  } catch (error) {
    console.error('Unable to update the subcategory in subcategory list');
  }
};

export const deleteSubCategory = (subcategoryId) => (dispatch) => {
  try {
    dispatch(deleteSubCategoryFromList(subcategoryId));
  } catch (error) {
    console.error('Unable to update the subcategory in subcategory list');
  }
};
