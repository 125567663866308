import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, ButtonToolbar, Col, Row } from 'reactstrap';
import { emptyStringPattern } from '@/shared/helpers';
import { FormField } from '@/shared/components/form/hook/FormField';
import { ErrorMessage } from '@hookform/error-message';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableCell } from '@material-ui/core';
import DeleteIcon from 'mdi-react/DeleteIcon';
import Table from '@material-ui/core/Table';
import { shallowEqual, useSelector } from "react-redux";

const renderCohorts = (cohorts, removeCohort) => {
  return (
      <Table className="program-from-cohort-options">
        <TableHead>
          <TableRow>
            <TableCell style={{minWidth: 100}}>Cohort Name</TableCell>
            <TableCell style={{minWidth: 100}}>Description</TableCell>
            <TableCell style={{minWidth: 100}}>Code</TableCell>
            <TableCell style={{minWidth: 70}}>Start Date</TableCell>
            <TableCell style={{minWidth: 70}}>End Date</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        {cohorts.map(cohort => (
            <TableRow>
              <TableCell>{cohort.name}</TableCell>
              <TableCell>{cohort.description}</TableCell>
              <TableCell>{cohort.code}</TableCell>
              <TableCell>{cohort.startDate}</TableCell>
              <TableCell>{cohort.endDate}</TableCell>
              <TableCell>
                <DeleteIcon
                    style={{color: '#ff4861'}}
                    role="button"
                    onClick={() => removeCohort(cohort.id)}
                />
              </TableCell>
            </TableRow>
        ))}
      </Table>
  );
}

const ProgramCohortForm = ({cohorts, setCohorts}) => {
  const formProps = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
      description: '',
      code: '',
      startDate: '',
      endDate: ''
    }
  });
  const {
    handleSubmit,
    control,
    getValues,
    formState: {errors},
    trigger,
    reset
  } = formProps;

  const addCohort = async () => {
    const isValid = await trigger();
    if (!isValid) {
      return;
    }
    const data = getValues();
    let cohort = {
      id: Date.now(),
      name: data.name,
      description: data.description,
      code: data.code,
      startDate: data.startDate,
      endDate: data.endDate
    }
    reset();
    setCohorts([...cohorts, cohort]);
  }

  const removeCohort = id => {
    setCohorts(cohorts.filter(cohort => cohort.id !== id));
  }


  return (
      <form className={`form`} onSubmit={handleSubmit(data => {
      })}>
        <ErrorMessage
            errors={errors}
            name="formFields"
            render={({message}) => <span className="form__form-group-error lp-error">{message}</span>}
        />
        <Row>
          <Col>
            <div className="form__form-group">
              <span className="form__form-group-label">Cohort Name</span>
              <div className="form__form-group-cohort">
                <FormField
                    name="name"
                    control={control}
                    component="input"
                    rules={{
                      required: 'Cohort name is required',
                      pattern: emptyStringPattern,
                    }}
                    errors={errors}
                    isAboveError
                    defaultValue=""
                    placeholder="Enter Cohort Name"
                />
              </div>
            </div>
          </Col>
          <Col>
            <div className="form__form-group">
              <span className="form__form-group-label">Code</span>
              <div className="form__form-group-cohort">
                <FormField
                    name="code"
                    control={control}
                    component="input"
                    rules={{
                      required: 'Cohort code is required',
                      pattern: emptyStringPattern,
                    }}
                    errors={errors}
                    isAboveError
                    defaultValue=""
                    placeholder="Enter Code"
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="form__form-group">
              <span className="form__form-group-label">Cohort Description</span>
              <div className="form__form-group-cohort">
                <FormField
                    name="description"
                    control={control}
                    component="textarea"
                    rules={{
                      required: 'Cohort description is required',
                      pattern: emptyStringPattern,
                    }}
                    errors={errors}
                    isAboveError
                    defaultValue=""
                    placeholder="Enter Cohort Description"
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="form__form-group">
              <span className="form__form-group-label">Start Date</span>
              <div className="form__form-group-cohort">
                <FormField
                    name="startDate"
                    type="date"
                    placeholder="Enter Start Date"
                    control={control}
                    rules={{
                      required: 'Start date is required',
                      pattern: emptyStringPattern,
                    }}
                    errors={errors}
                    defaultValue=""
                    isAboveError
                />
              </div>
            </div>
          </Col>
          <Col>
            <div className="form__form-group">
              <span className="form__form-group-label">End Date</span>
              <div className="form__form-group-cohort">
                <FormField
                    name="endDate"
                    type="date"
                    placeholder="Enter End Date"
                    control={control}
                    rules={{
                      required: 'End date is required',
                      pattern: emptyStringPattern,
                    }}
                    errors={errors}
                    defaultValue=""
                    isAboveError
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <ButtonToolbar className="form__button-toolbar modal__footer w-100">
              <Button disabled={false} onClick={addCohort} color="success" outline type="button">
                Add Cohort
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>

        {renderCohorts(cohorts, removeCohort)}
      </form>
  );
};


const StepSix = ({onSubmit, page, data, previousPage, isEdit, confirmationModal, setConfirmationModal, setTooglePreviewPage}) => {

  const program = useSelector((state) => state.cadsAIPrograms, shallowEqual);
  const [cohortList, setCohortList] = React.useState([]);

  React.useEffect(() => {
    if (data?.cohortList) {
      setCohortList(data?.cohortList ?? []);
    }
  }, [data?.cohortList]);

  return (
      <div className="modal__body add-edit-program-modal">
        <ProgramCohortForm cohorts={cohortList} setCohorts={setCohortList}/>
        <ButtonToolbar className="form__button-toolbar modal__footer">
          <Button type="button" className="previous" onClick={() => previousPage()}>
            Back
          </Button>
          <Button type="button" color="outline-primary" onClick={() => setTooglePreviewPage(true)}>
            {' '}
            Preview{' '}
          </Button>
          {/*TODO: show confirmation modal on clicking of save as draft or publish button*/}
          {/*TODO: only show following "Save as Draft" button if program is not published before*/}
          <Button
              type="button"
              color="success"
              onClick={() => {
                setConfirmationModal({
                  ...confirmationModal,
                  isOpen: true,
                  message: 'Clicking on yes will save the program data as draft',
                  confirmBtnText: 'Yes, Save as Draft',
                  onConfirm: () => {
                    onSubmit({
                      cohortList,
                      programStatus: program?.statusOptions?.find(
                          (p) => p.title.toLowerCase() === 'draft',
                      ),
                    });
                    setConfirmationModal({...confirmationModal, isOpen: false});
                  },
                });
              }}
          >
            {' '}
            Save As Draft{' '}
          </Button>
          <Button
              disabled={false}
              color="primary"
              type="button"
              onClick={() => {
                setConfirmationModal({
                  ...confirmationModal,
                  isOpen: true,
                  message: 'Clicking on yes will publish the program',
                  confirmBtnText: 'Yes, Publish',
                  onConfirm: () => {
                    onSubmit({
                      cohortList,
                      programStatus: program?.statusOptions?.find(
                          (p) => p.title.toLowerCase() === 'publish',
                      )
                    });
                    setConfirmationModal({...confirmationModal, isOpen: false});
                  },
                });
              }}
          >
            {' '}
            Publish{' '}
          </Button>
        </ButtonToolbar>
      </div>
  );
};

export default StepSix;
