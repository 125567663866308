import React from 'react';

const PanelCard = ({
  count,
  isCompletedSurveyCount = false,
  isAcceptedApplicantCount = false,
  extras = [],
  svg,
  text,
  colorClass,
}) => {
  return (
    <div className={`datastar-card ${colorClass}`}>
      <div className="ds-card-icon">{svg}</div>
      <div className="card-txt">
        <p>{text}</p>
        <strong>{count}</strong>
        {isCompletedSurveyCount && (
          <div className="extra-counts">
            {extras[0]} Smart Skills & {extras[1]} Pre-Assessment
          </div>
        )}
        {isAcceptedApplicantCount && (
          <div className="extra-counts">
            {extras[0]} Pre-Onboarding & {extras[1]} Onboarding
          </div>
        )}
      </div>
    </div>
  );
};

export default PanelCard;
