const Progress = () => {
  return (
    <div className="progress-circle">
      <div className="progress-title">My Courses</div>
      <div>
        <div className="circle-wrap">
          <div className="donut-chart-block block">
            <div className="donut-chart">
              <div className="part1 portion-block">
                <div className="circle"></div>
              </div>
              <div className="part2 portion-block">
                <div className="circle"></div>
              </div>
              <div className="part3 portion-block">
                <div className="circle"></div>
              </div>
              <p className="center">
                <span>0</span>
                <span className="sub">Courses</span>
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="progress-subtitle">
            <span className="bullet started"></span> 0 Started
          </div>
          <div className="progress-subtitle">
            <span className="bullet completed"></span> 0 Completed
          </div>
          <div className="progress-subtitle">
            <span className="bullet"></span> 0 Not Started
          </div>
        </div>
      </div>
    </div>
  );
};

export default Progress;
