import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { cadsAiAnnouncementsBaseUrl } from '../index';
import { AnnouncementsUIProvider } from './AnnouncementsUIProvider';
import { AnnouncementsCard } from './AnnouncementsCard';
import AnnouncementEditDialog from './announcement-add-edit-dialog/AnnouncementEditDialog';
import { setAnnouncementFilters, uncheckAllAnnouncement } from '../redux/actions';
import { useDispatch } from 'react-redux';
import AnnouncementViewDialog from './announcement-view-dialog/AnnouncementViewDialog';
import { AnnouncementDeactivateDialog } from '@/containers/Cads-AI/ManageHome/Announcements/components/announcement-deactivate-dialog/AnnouncementDeactivateDialog';

export function AnnouncementsPage({ history, match }) {
  const dispatch = useDispatch();

  //clean up
  React.useEffect(() => () => dispatch(uncheckAllAnnouncement()), []);

  const announcementsUIEvents = {
    openViewAnnouncementsDialog: (id) => {
      history.push(`${cadsAiAnnouncementsBaseUrl}/${id}/view`);
    },
    openNewAnnouncementsDialog: () => {
      history.push(`${cadsAiAnnouncementsBaseUrl}/new`);
    },
    openEditAnnouncementsDialog: (id) => {
      history.push(`${cadsAiAnnouncementsBaseUrl}/${id}/edit`);
    },
    openAnnouncementDeactivationDialog: () => {
      history.push(`${cadsAiAnnouncementsBaseUrl}/deactivate`);
    },
  };

  return (
    <AnnouncementsUIProvider announcementsUIEvents={announcementsUIEvents}>
      <Switch>
        <Route path={`${cadsAiAnnouncementsBaseUrl}/new`}>
          {({ history, match }) => (
            <AnnouncementEditDialog
              show={match != null}
              isEdit={false}
              onHide={() => {
                dispatch(setAnnouncementFilters());
                history.push(cadsAiAnnouncementsBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${cadsAiAnnouncementsBaseUrl}/:id/edit`}>
          {({ history, match }) => (
            <AnnouncementEditDialog
              show={match != null}
              isEdit={true}
              id={match?.params?.id}
              onHide={() => {
                dispatch(setAnnouncementFilters());
                history.push(cadsAiAnnouncementsBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${cadsAiAnnouncementsBaseUrl}/:id/view`}>
          {({ history, match }) => (
            <AnnouncementViewDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                dispatch(setAnnouncementFilters());
                history.push(cadsAiAnnouncementsBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${cadsAiAnnouncementsBaseUrl}/deactivate`}>
          {({ history, match }) => (
            <AnnouncementDeactivateDialog
              show={match != null}
              onHide={() => {
                dispatch(setAnnouncementFilters());
                history.push(cadsAiAnnouncementsBaseUrl);
              }}
            />
          )}
        </Route>
      </Switch>
      <AnnouncementsCard />
    </AnnouncementsUIProvider>
  );
}
