const initialState = {
  loading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    search: '',
    isSortOrderDescending: false,
  },
  totalRecords: 0,
  data: [],
  error: null,
};

const campaignUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_USERS_LIST':
      return { ...state, data: action.payload.data, loading: action.payload.loading };
    default:
      return state;
  }
};

export default campaignUserReducer;
