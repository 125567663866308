import React, { memo } from 'react';
import FormField from '@/shared/components/form/hook/FormField';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { setAssessmentConfig } from '../redux/actions';
import { emptyStringPattern } from '@/shared/helpers';
import LogoDetailsCollapse from './LogoDetailsCollapse';

const GeneralAssessmentConfigTopbar = ({ timeLimit, logo }) => {
  const dispatch = useDispatch();
  const { control, errors, setValue } = useForm();

  React.useEffect(() => {
    setValue('timeLimit', timeLimit);
  }, [timeLimit]);

  return (
    <div>
      <div className="form">
        <div className="form__form-group w-25">
          <span className="form__form-group-label">Time Limit (In minutes)</span>
          <div className="form__form-group-field">
            <FormField
              name="timeLimit"
              control={control}
              component="input"
              type="number"
              min={1}
              errors={errors}
              onChange={({ target: { value } }) => {
                setValue('timeLimit', value);
                dispatch(setAssessmentConfig({ timeLimit: value }));
              }}
              rules={{
                required: 'This is required field',
                pattern: emptyStringPattern,
              }}
              defaultValue={timeLimit}
              isAboveError={true}
              placeholder="Enter time limit (In minutes)"
            />
          </div>
        </div>
      </div>
      <LogoDetailsCollapse logo={logo} />
    </div>
  );
};

export default memo(GeneralAssessmentConfigTopbar);
