import React from 'react';
import { useSelector } from 'react-redux';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import api from '@/utils/apiClient';
import QuestionTable from './QuestionTable';
import { useQuestionUIContext } from '../QuestionUIProvider';

const QuestionTableWrapper = () => {
  const questionUIContext = useQuestionUIContext();

  const questionFilter = useSelector((state) => state.questions.filters);

  const downloadCSV = async () => {
    const questionCSVFilters = { ...questionFilter, pageNumber: null, pageSize: null };
    const csvData = await api.questions.getQuestionListCSV(questionCSVFilters);
    require('js-file-download')(
      csvData,
      `Question_List__${new Date().toLocaleString().replace(/[\/:,\s]/gi, '_')}.csv`,
    );
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="products-list">
          <div className="card__title">
            <h5 className="bold-text">Questions List</h5>
            <ButtonToolbar className="products-list__btn-toolbar-top">
              <Button color="success" onClick={downloadCSV} outline>
                Export Questions
              </Button>
              <Button color="primary" onClick={questionUIContext.openNewQuestionDialog}>
                Add new Questions
              </Button>
            </ButtonToolbar>
          </div>
          <QuestionTable />
        </CardBody>
      </Card>
    </Col>
  );
};

export default QuestionTableWrapper;
