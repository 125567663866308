import React from 'react';
import { useDispatch } from 'react-redux';
import { paths } from '@/shared/helpers/paths';
import { Route, Switch, useHistory } from 'react-router-dom';
import { ModuleCard } from './ModuleCard';
import { clearModuleFilters, setModuleFilters } from '../redux/actions';
import { ModuleUIProvider } from './ModuleUIProvider';
import ModuleEditDialog from './module-add-edit-dialog/ModuleEditDialog';
import ModuleScheduleEditDialog from './module-schedule-add-edit-dialog/ModuleScheduleEditDialog';

const moduleBaseUrl = paths.CONTROL_PANEL.MODULE;

export function ModulePage({ isForAssignment = false }) {
  const history = useHistory();

  //clean up
  React.useEffect(() => dispatch(clearModuleFilters()), []);

  const moduleUIEvents = {
    openManageSchedulesDialog: (id) => {
      history.push(`${moduleBaseUrl}/${id}/manage-schedules`);
    },
    openViewModuleDialog: (id) => {
      history.push(`${moduleBaseUrl}/${id}/view`);
    },
    openNewModuleDialog: () => {
      history.push(`${moduleBaseUrl}/new`);
    },
    openEditModuleDialog: (id) => {
      history.push(`${moduleBaseUrl}/${id}/edit`);
    },
    openDeleteModuleDialog: (id) => {
      history.push(`${moduleBaseUrl}/${id}/delete`);
    },
  };

  const dispatch = useDispatch();

  return (
    <ModuleUIProvider isForAssignment={isForAssignment} moduleUIEvents={moduleUIEvents}>
      <Switch>
        <Route path={`${moduleBaseUrl}/new`}>
          {({ history, match }) => (
            <ModuleEditDialog
              show={match != null}
              isEdit={false}
              onHide={() => {
                dispatch(setModuleFilters());
                history.push(moduleBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${moduleBaseUrl}/:id/manage-schedules`}>
          {({ history, match }) => (
            <ModuleScheduleEditDialog
              show={match != null}
              isEdit={true}
              id={match?.params?.id}
              onHide={() => {
                history.push(moduleBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${moduleBaseUrl}/:id/edit`}>
          {({ history, match }) => (
            <ModuleEditDialog
              show={match != null}
              isEdit={true}
              id={match?.params?.id}
              onHide={() => {
                history.push(moduleBaseUrl);
              }}
            />
          )}
        </Route>
      </Switch>
      <ModuleCard />
    </ModuleUIProvider>
  );
}
