import React, { createContext, useContext } from 'react';

const SponsorsUIContext = createContext();

export function useSponsorUIContext() {
  return useContext(SponsorsUIContext);
}

export function SponsorUIProvider({ sponsorUIEvents, children }) {
  const value = {
    openNewSponsorDialog: sponsorUIEvents.openNewSponsorDialog,
    openEditSponsorDialog: sponsorUIEvents.openEditSponsorDialog,
  };

  return <SponsorsUIContext.Provider value={value}>{children}</SponsorsUIContext.Provider>;
}
