import React, { useState } from 'react';
import LoadingIcon from 'mdi-react/LoadingIcon';
import api from '@/utils/apiClient';
import QuestionWizardForm from './QuestionWizardForm';
import { convertImageFileToBase64, convertUrlToFile } from '@/shared/helpers';
import moment from "moment";

const QuestionEditForm = ({modal, isEdit, id}) => {
  const initialValues = {
    //step 1
    skill: null,
    questionLevel: null,
    questionType: null,
    tags: null,

    //step2
    question: '',
    additionalText: '',
    image: null,

    //step3
    allowSkip: { value: true, label: 'Yes' },
    allowOther: { value: true, label: 'No' },
    answers: [],
  };

  const [data, setData] = useState(initialValues);

  const [state, setState] = React.useState({
    loading: false,
    fetchingQuestion: false,
    newQuestionAdded: false,
    errorMessage: '',
  });

  React.useEffect(() => {
    if (isEdit && id) {
      setDefaultData(+id);
    }
  }, [isEdit, id]);

  const setDefaultData = async (questionId) => {
    try {
      const { data: res, isSuccess } = await api.questions.getQuestion(questionId);
      const question = res.data;
      if (isSuccess) {
        setData({
          //step 1
          skill: { value: question?.skill?.id, label: question?.skill?.name },
          questionLevel: {
            value: question?.questionLevel?.id,
            label: question?.questionLevel?.name,
          },
          questionType: {value: question?.questionType?.id, label: question?.questionType?.name},
          tags: question?.questionTags?.map((tag) => ({value: tag.id, label: tag.name})),

          //step2
          question: question?.questionMain,
          additionalText: question?.questionExtra,
          image: question?.imageUrl ? [await convertUrlToFile(question?.imageUrl)] : [],
          imageUrl: question?.imageUrl,

          //step3
          allowSkip: {value: question?.allowSkip, label: question?.allowSkip ? 'Yes' : 'No'},
          allowOther: {value: question?.allowOther, label: question?.allowOther ? 'Yes' : 'No'},
          answers: question?.answers ?? [],
        });
      } else {
        throw new Error(res?.messages?.[0]);
      }
    } catch ({ message }) {
      console.error('Question edit error : ', message);
      setState({ ...state, errorMessage: 'Error while getting existing question details' });
    }
  };

  const constructApiRequestData = async (questionData) => {
    const base64Img = await convertImageFileToBase64(questionData.image?.[0]);
    return {
      questionsTypeId: questionData?.questionType?.value,
      skillsId: questionData?.skill?.value,
      questionLevelId: questionData?.questionLevel?.value,
      questionMain: questionData?.question,
      questionExtra: questionData?.additionalText,
      image: base64Img?.split(',')?.[1],
      imageFileName: 'image_' + moment(new Date()).format('DD-MM-yyyy') + '-' + new Date().getTime().toString(),
      tags: questionData?.tags?.map((tag) => ({
        id: tag.__isNew__ ? null : tag?.value ?? "empty",
        name: tag?.label ?? "Empty Tag",
      })),
      allowSkip: questionData?.allowSkip?.value,
      allowOther: questionData?.allowOther?.value,
      answers: questionData?.answers,
    };
  };

  const handleEdit = async (newQuestionData) => {
    // remove answer
    const answers = [...newQuestionData.answers];
    delete newQuestionData.answers;

    //separate answer to add
    const answersToAdd = [];
    const answersToEdit = [];
    answers.forEach((ans) => {
      if (ans.id === null) {
        delete ans.id;
        answersToAdd.push(ans);
      } else answersToEdit.push(ans);
    });
    //TODO: improve ui experience and reduce api calls here
    const { isSuccess: updateSuccess } = await api.questions.updateQuestion(newQuestionData);
    if (!updateSuccess) throw new Error('Error while updating question');
    const { isSuccess: updateAnsSuccess } = await api.questions.editQuestionOptions({
      questionId: id,
      answers: answersToEdit,
    });
    if (!updateAnsSuccess) throw new Error('Error while updating answers');
    const { isSuccess: addAnsSuccess } = await api.questions.addQuestionOptions({
      questionId: id,
      answers: answersToAdd,
    });
    if (!addAnsSuccess) throw new Error('Error while adding new answers');

    return { isSuccess: true };
  };

  const handleAddEditQuestion = async (questionData, clearForm) => {
    setState({ ...state, loading: true });
    const newQuestionData = await constructApiRequestData(questionData);
    const defaultError = `Error occurred while ${isEdit ? 'Updating' : 'Adding new'} question`;
    try {
      const { data, isSuccess } = isEdit
        ? await handleEdit({ id, ...newQuestionData })
        : await api.questions.addQuestion(newQuestionData);
      if (isSuccess) {
        setState({ ...state, newQuestionAdded: true, loading: false });
        if (!isEdit) clearForm();
      } else {
        throw new Error(data?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({ ...state, loading: false, newQuestionAdded: false, errorMessage: error.message });
    }
  };

  return (
    <div>
      {/*Add/Edit Question Form Status*/}
      <div className="ml-4">
        {state.loading && (
          <div>
            <div className="panel__refresh position-relative" style={{ height: 75, width: 20 }}>
              <LoadingIcon />
              <div style={{ width: 200, padding: '23px 40px' }}>
                {isEdit ? 'Updating' : 'Adding new'} question...
              </div>
            </div>
          </div>
        )}
        {(state.newQuestionAdded || !!state.errorMessage) && (
          <table className="mt-3">
            <tr>
              <td>
                {state.newQuestionAdded ? (
                  <span className="lnr lnr-thumbs-up modal__title-icon success" />
                ) : (
                  <span className="lnr lnr-cross-circle modal__title-icon error" />
                )}
              </td>
              <td className="pl-2">
                {state.newQuestionAdded
                  ? `${isEdit ? 'Question Updated' : 'New Question Added'} successfully`
                  : state.errorMessage}
              </td>
            </tr>
          </table>
        )}
      </div>

      {/*Add/Edit Question Form*/}
      <QuestionWizardForm
        onSubmit={handleAddEditQuestion}
        initialValues={initialValues}
        data={data}
        setData={setData}
        isEdit={isEdit}
        questionId={id}
      />
    </div>
  );
};

export default QuestionEditForm;
