import React, { createContext, useContext } from 'react';

const OrganizationUIContext = createContext();

export function useOrganizationUIContext() {
  return useContext(OrganizationUIContext);
}

export function OrganizationUIProvider({ organizationUIEvents, children }) {
  const value = {
    openViewOrganizationDialog: organizationUIEvents.openViewOrganizationDialog,
    openNewOrganizationDialog: organizationUIEvents.openNewOrganizationDialog,
    openViewAllOrganizationList: organizationUIEvents.openViewAllOrganizationList,
    openEditOrganizationDialog: organizationUIEvents.openEditOrganizationDialog,
    openDeleteOrganizationDialog: organizationUIEvents.openDeleteOrganizationDialog,
    openOrganizationTypeDialog: organizationUIEvents.openOrganizationTypeDialog,
    openDeactivateOrganizationDialog: organizationUIEvents.openDeactivateOrganizationDialog,
  };

  return <OrganizationUIContext.Provider value={value}>{children}</OrganizationUIContext.Provider>;
}
