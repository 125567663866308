import React from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import TrainingProvidersTable from './TrainingProvidersTable';
import { shallowEqual, useSelector } from 'react-redux';
import { useTrainingProviderUIContext } from '../TrainingProviderUIProvider';

const TrainingProvidersTableWrapper = () => {
  const trainingProviderUIContext = useTrainingProviderUIContext();
  const { checkedTrainingProviderIds } = useSelector(
    (state) => state.cadsAITrainingProviders,
    shallowEqual,
  );

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="products-list">
          <div className="card__title">
            <h5 className="bold-text">Data Training Provider List</h5>
            <ButtonToolbar className="products-list__btn-toolbar-top">
              <Button
                color="primary"
                onClick={trainingProviderUIContext.openNewTrainingProviderDialog}
              >
                Add new
              </Button>
              <Button
                color="danger"
                outline
                disabled={checkedTrainingProviderIds.length === 0}
                onClick={trainingProviderUIContext.openDeactivateTrainingProviderDialog}
              >
                Deactivate
              </Button>
            </ButtonToolbar>
          </div>
          <TrainingProvidersTable />
        </CardBody>
      </Card>
    </Col>
  );
};

export default TrainingProvidersTableWrapper;
