import {
  blocksShadowsReducer,
  customizerReducer,
  roundBordersReducer,
  rtlReducer,
  sidebarReducer,
  themeReducer,
} from '@/redux/reducers/index';
import appConfigReducer from '@/redux/reducers/appConfigReducer';

export const AppReducers = {
  theme: themeReducer,
  rtl: rtlReducer,
  roundBorders: roundBordersReducer,
  blocksShadows: blocksShadowsReducer,
  appConfig: appConfigReducer,
  customizer: customizerReducer,
  sidebar: sidebarReducer,
};
