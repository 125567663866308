import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonToolbar } from 'reactstrap';
import { getColorForWeight } from '@/shared/helpers';
import { FormField } from '@/shared/components/form/hook/FormField';
import { SelectField } from '@/shared/components/form/Select';
import { CreatableSelectField } from '@/shared/components/form/CreatableSelectField';
import { isEmpty } from 'lodash/lang';
import { shallowEqual } from 'recompose';
import { extractFieldData, getStepFields } from '../index';
import {
  getCadsAiLearningPathGroupOptionList,
  getLearningPathModuleOptions,
  getLearningPathOtherSkills,
  getLearningPathTypeOptions,
} from '../../../../redux/actions';

const StepTwo = ({
  onSubmit,
  page,
  onClear,
  previousPage,
  data,
  id,
  state,
  setSelectedModuleLists,
  selectedModuleLists,
  selectedLP,
}) => {
  const [groupOptions, setGroupOptions] = React.useState([]);
  const [moduleOptions, setModuleOptions] = React.useState([]);
  const [skillsOptions, setSkillsOptions] = React.useState([]);
  const [otherSkillsOptions, setOtherSkillsOptions] = React.useState([]);
  const [learningPathOptions, setLearningPathTypeOptions] = React.useState([]);
  const { learningPathGroupOptions: groups } = useSelector(
    (state) => state.cadsAILearningPaths,
    shallowEqual,
  );
  const { learningPathTypeOptions, learningPathOtherSkills, learningPathSkills, moduleList } =
    useSelector((state) => state.cadsAILearningPaths, shallowEqual);
  const dispatch = useDispatch();

  // learningPath form related functions
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm(extractFieldData(page, data));

  React.useEffect(() => {
    getStepFields(page).forEach((key) => setValue(key, data[key]));
  }, [data]);

  React.useEffect(() => {
    dispatch(getCadsAiLearningPathGroupOptionList());
    dispatch(getLearningPathModuleOptions({ pageSize: 1000 }));
    dispatch(getLearningPathTypeOptions());
    dispatch(getLearningPathOtherSkills());
  }, []);

  React.useEffect(() => {
    setGroupOptions(groups);
  }, [groups]);

  React.useEffect(() => {
    setOtherSkillsOptions(
      learningPathOtherSkills?.map((skill) => ({ value: skill.id, label: skill.name })) ?? [],
    );
  }, [learningPathOtherSkills]);

  React.useEffect(() => {
    setLearningPathTypeOptions(
      learningPathTypeOptions?.map((skill) => ({ value: skill.id, label: skill.name })) ?? [],
    );
  }, [learningPathTypeOptions]);

  React.useEffect(() => {
    setSkillsOptions(
      learningPathSkills?.map((skill) => ({ value: skill?.id, label: skill?.name })) ?? [],
    );
  }, [learningPathSkills]);

  React.useEffect(() => {
    if (id) {
      setValue('group', {
        value: data?.learningPathGroup?.id,
        label: data?.learningPathGroup?.title,
      });
      setValue('learningPathType', {
        value: data?.learningPathType?.id,
        label: data?.learningPathType?.title,
      });
      setSelectedModuleLists(
        data?.modules?.map((module) => ({
          value: module.id,
          label: module.title,
          ...module,
        })) ?? [],
      );
      setValue(
        'dataSkills',
        data?.learningPathSkills?.map((m) => ({ value: m.id, label: m.title })),
      );
      setValue(
        'otherDataSkills',
        data?.learningPathOtherSkills?.map((m) => ({ value: m.id, label: m.title })),
      );
      const selectedModuleIds = data?.modules?.map((module) => module.id) ?? [];
      setModuleOptions(
        moduleList
          ?.filter((m) => !selectedModuleIds.includes(m.id))
          .map((module) => ({
            label: module.name,
            value: module.id,
            ...module,
          })),
      );
      const selectedSkillsIds = data?.dataSkills?.map((skill) => skill.id) ?? [];
      setSkillsOptions(
        learningPathSkills
          ?.filter((s) => !selectedSkillsIds.includes(s.id))
          .map((skill) => ({ label: skill.name, value: skill.id, ...skill })),
      );
      const selectedOtherSkillsIds = data?.otherDataSkills?.map((skill) => skill.id) ?? [];
      setOtherSkillsOptions(
        learningPathOtherSkills
          ?.filter((s) => !selectedOtherSkillsIds.includes(s.id))
          .map((skill) => ({ label: skill.name, value: skill.id, ...skill })),
      );
    } else {
      setModuleOptions(
        moduleList?.map((module) => ({
          label: module?.name,
          value: module.id,
          ...module,
        })),
      );
    }
  }, [moduleList, selectedLP]);

  React.useEffect(() => {
    trigger('module');
  }, [selectedModuleLists]);

  const addToModuleList = async () => {
    const { module, isOptional, sortOrder } = getValues();
    if (isEmpty(isOptional) || isEmpty(module) || isEmpty(sortOrder)) return;
    if (selectedModuleLists.length > 0) {
      const sortedModules = selectedModuleLists.slice(0, sortOrder - 1);
      const sortedAnotherModules = selectedModuleLists.slice(sortOrder - 1);
      let sortedAllModules = sortedModules;
      sortedAllModules.push({ ...module, isOptional: isOptional?.value, sortOrder: sortOrder });
      selectedModuleLists = [...sortedAllModules, ...sortedAnotherModules];
      selectedModuleLists.forEach((item, index) => {
        item.sortOrder = (index + 1).toString();
      });
      setSelectedModuleLists(selectedModuleLists);
    } else {
      setSelectedModuleLists([
        ...selectedModuleLists,
        { ...module, isOptional: isOptional?.value, sortOrder: sortOrder },
      ]);
    }
    setValue('module', '');
    setValue('isOptional', { value: false, label: 'No' });
    setValue('sortOrder', '');
    setModuleOptions(moduleOptions.filter((opt) => opt.value !== module?.value));
  };

  const removeModuleFromList = (module) => {
    setModuleOptions([...moduleOptions, module]);
    setSelectedModuleLists([...selectedModuleLists.filter(({ value }) => value !== module?.value)]);
  };
  return (
    <div className="modal__body add-edit-learning-path">
      <form className={`form form--horizontal`} onSubmit={handleSubmit(onSubmit)}>
        <div className="form__form-group">
          <span className="form__form-group-label">Create/Select Group</span>
          <div className="form__form-group-field">
            <FormField
              control={control}
              name="group"
              component={CreatableSelectField}
              isMulti={false}
              options={groupOptions}
              placeholder="Create Or Select Group"
              rules={{ required: 'This is required field' }}
              errors={errors}
              isAboveError
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Learning path Type</span>
          <div className="form__form-group-field">
            <FormField
              control={control}
              name="learningPathType"
              component={CreatableSelectField}
              isMulti={false}
              options={learningPathOptions}
              placeholder="Select Type"
              // rules={{required: 'This is required field'}}
              errors={errors}
              isAboveError
            />
          </div>
        </div>
        <div className="form__form-group" style={{ width: '100%' }}>
          <span className="form__form-group-label">Other Skills</span>
          <div className="form__form-group-field">
            <FormField
              control={control}
              name="otherDataSkills"
              component={CreatableSelectField}
              isMulti
              options={otherSkillsOptions}
              placeholder="Select Other Skills"
              // rules={{required: 'This is required field'}}
              errors={errors}
              isAboveError
            />
          </div>
        </div>
        <div className="form__form-group" style={{ width: '100%' }}>
          <span className="form__form-group-label">Skill</span>
          <div className="form__form-group-field">
            <FormField
              control={control}
              name="dataSkills"
              isMulti
              component={SelectField}
              options={skillsOptions}
              // rules={{validate: () => selectedSkills?.length === 0 ? 'Please add at least one skill' : true}}
              placeholder="Select Skill"
              errors={errors}
              isAboveError
            />
          </div>
        </div>
        <div className="form__form-group lp_edit_module_error" style={{ width: '40%' }}>
          <span className="form__form-group-label">Module</span>
          <FormField
            style={{ height: 40 }}
            name="module"
            control={control}
            placeholder="Select Module"
            component={SelectField}
            errors={errors}
            // rules={{validate: () => selectedModuleList?.length === 0 ? 'Please add at least one module' : true}}
            options={moduleOptions}
            isAboveError
          />
        </div>
        <div className="form__form-group ml-2" style={{ width: '20%' }}>
          <span className="form__form-group-label">Is Optional?</span>
          <FormField
            name="isOptional"
            style={{ height: 40 }}
            control={control}
            component={SelectField}
            errors={errors}
            options={[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' },
            ]}
            defaultValue={{ value: false, label: 'No' }}
            isAboveError
          />
        </div>
        <div className="form__form-group ml-2" style={{ width: '25%' }}>
          <span className="form__form-group-label">Sort Order</span>
          <FormField
            name="sortOrder"
            as="input"
            style={{ height: 39 }}
            type="number"
            min={1}
            placeholder="Enter Sort Order"
            control={control}
            defaultValue=""
          />
        </div>
        <div className="form__form-group mt-2" style={{ width: '10%' }}>
          <div className="competency_add_button">
            <Button
              color="success"
              type="button"
              outline
              style={{ marginLeft: 20, height: 38 }}
              onClick={addToModuleList}
            >
              Add
            </Button>
          </div>
        </div>
        {selectedModuleLists.length > 0 && (
          <table className="competency_add_section table" style={{ width: '100%' }}>
            <tr>
              <th>Order</th>
              <th className="pl-4">Module</th>
              <th className="pl-4">Is Optional</th>
              <th className="pl-4">Remove Module</th>
            </tr>
            {selectedModuleLists
              .sort((a, b) => a.sortOrder - b.sortOrder)
              .map((module) => (
                <tr>
                  <td className="pl-4">{module.sortOrder}</td>
                  <td className="pl-4">{module.label} </td>
                  <td className="pl-5">{module?.isOptional ? 'Yes' : 'No'}</td>
                  <td className="pl-5">
                    <span
                      onClick={() => removeModuleFromList(module)}
                      onMouseEnter={(e) => (e.target.className = 'lnr lnr-trash')}
                      style={{
                        color: getColorForWeight({ random: true }),
                        cursor: 'pointer',
                      }}
                      className="lnr lnr-trash"
                    />{' '}
                  </td>
                </tr>
              ))}
          </table>
        )}

        <ButtonToolbar className="form__button-toolbar modal__footer">
          <Button
            color="primary"
            type="button"
            className="previous"
            onClick={() => previousPage(getValues())}
          >
            Back
          </Button>
          <Button color="secondary" type="button" onClick={onClear} outline>
            Clear
          </Button>
          <Button disabled={state?.loading} color="primary" type="submit">
            Submit
          </Button>
        </ButtonToolbar>
      </form>
    </div>
  );
};

export default StepTwo;
