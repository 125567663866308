import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, ButtonToolbar } from 'reactstrap';
import { FormField } from '@/shared/components/form/hook/FormField';
import { extractFieldData, getStepFields } from '../index';

const StepThree = ({ onSubmit, page, data, previousPage, state }) => {
  const { handleSubmit, control, setValue } = useForm(extractFieldData(page, data));

  React.useEffect(() => {
    getStepFields(page).forEach((key) => {
      setValue(key, data[key]);
    });
  }, [data]);

  return (
    <div className="modal__body add-edit-learning-path">
      <form className={`form form--horizontal`} onSubmit={handleSubmit(onSubmit)}>
        <div className="form__form-group">
          <span className="form__form-group-label">Materials URL</span>
          <div className="form__form-group-field">
            <FormField
              name="masterMaterialsLink"
              as="input"
              placeholder="Enter Material Url"
              control={control}
              defaultValue=""
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Participants Url</span>
          <div className="form__form-group-field">
            <FormField
              name="participantsMaterialLink"
              as="input"
              placeholder="Enter Participants Url"
              control={control}
              defaultValue=""
            />
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar modal__footer">
          <Button onClick={previousPage} type="button" className="previous">
            Back
          </Button>
          <Button disabled={state?.loading} color="primary" type="submit">
            Submit
          </Button>
        </ButtonToolbar>
      </form>
    </div>
  );
};

export default StepThree;
