import { handleActions } from 'redux-actions';
import {
  setOrganizationList,
  setOrganizationOptionsList,
  setOrganizationFetching,
  setOrganizationError,
  setAssignOrganizationList,
  setOrganizationFilters,
  setOrgState,
  setOrganizationTypeList,
  toggleCheckedOrganization,
  unCheckAllOrganizations,
  fetchOrganizationList,
} from './actions';
import { CP_PRODUCTS } from '@/shared/helpers/constants';

export const defaultOrgState = {
  loading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    isSortOrderDescending: false,
  },
  searchAssign: {
    searchText: '',
  },
  searchAssigned: {
    searchText: '',
  },
  totalRecords: 0,
  data: [],
  assignOrgs: [],
  assigned: false,
  feature: CP_PRODUCTS.ACCELTIC.id, //TODO: define separate constant for cadsAI
  options: [], // list of active organizations with minimum details (Array<{id: number, title: string}>)
  types: [],
  error: null,
  checkedOrganizationIds: [],
};

export default handleActions(
  {
    [setOrganizationFetching](state) {
      return {
        ...state,
        assignOrgs: [],
        loading: true,
        error: null,
      };
    },
    [setOrganizationError](state, { payload }) {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    },
    [setOrganizationOptionsList](state, { payload }) {
      return {
        ...state,
        options: payload.data,
      };
    },
    [setOrganizationTypeList](state, { payload }) {
      return {
        ...state,
        types: payload.data,
      };
    },
    [setAssignOrganizationList](state, { payload }) {
      let assignOrganizationsList;
      assignOrganizationsList = payload.data;
      return {
        ...state,
        assignOrgs: assignOrganizationsList?.records,
        totalRecords: assignOrganizationsList?.totalRecords,
        loading: false,
        error: null,
      };
    },
    [setOrganizationList](state, { payload }) {
      return {
        ...state,
        data: payload?.data?.records,
        totalRecords: payload?.data?.totalRecords,
        loading: false,
        error: null,
      };
    },
    [setOrganizationFilters](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
        checkedOrganizationIds: [],
      };
    },
    [setOrgState](state, { payload }) {
      return payload;
    },
    [toggleCheckedOrganization](state, { payload: organizationId }) {
      const checkedIds = state.checkedOrganizationIds;
      return {
        ...state,
        checkedOrganizationIds: checkedIds.includes(organizationId)
          ? checkedIds.filter((id) => id !== organizationId)
          : [...checkedIds, organizationId],
      };
    },
    [unCheckAllOrganizations](state) {
      return {
        ...state,
        checkedOrganizationIds: [],
      };
    },
  },
  defaultOrgState,
);
