import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import api from '@/utils/apiClient';
import ProgramsTable from './ProgramsTable';
import { useProgramsUIContext } from '../ProgramsUIProvider';

const ProgramsTableWrapper = () => {
  const programsUIContext = useProgramsUIContext();

  const programFilter = useSelector((state) => {
    state.programs.filters;
  });

  const checkedProgramIds = useSelector((state) => state.programs.checkedProgramIds, shallowEqual);

  const downloadCSV = async () => {
    const programCsvFilter = {
      ...programFilter,
      pageSize: null,
      pageNumber: null,
    };
    const csvData = await api.programs.getProgramListCSV(programCsvFilter);
    require('js-file-download')(
      csvData,
      `Program_List__${new Date().toLocaleString().replace(/[\/:,\s]/gi, '_')}.csv`,
    );
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="products-list">
          <div className="card__title">
            <h5 className="bold-text">Data Program List</h5>
            <ButtonToolbar className="products-list__btn-toolbar-top">
              <Button color="primary" onClick={programsUIContext.openNewProgramsDialog}>
                Add new
              </Button>
              <Button
                color="success"
                onClick={programsUIContext.openUpdateStatusDialog}
                outline
                disabled={checkedProgramIds.length === 0}
              >
                Update Status
              </Button>
              <Button color="success" outline disabled>
                Clone
              </Button>
              <Button color="success" onClick={downloadCSV} outline>
                Export
              </Button>
            </ButtonToolbar>
          </div>
          <ProgramsTable />
        </CardBody>
      </Card>
    </Col>
  );
};

export default ProgramsTableWrapper;
