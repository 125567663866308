import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { campaignUsersBaseUrl } from '../index';
import UsersUIProvider from './UsersUIProvider';
import UsersCard from './UsersCard';
import UserViewDialog from './user-view-dialog/UserViewDialog';

const UsersPage = ({ history, match }) => {
  const usersUIEvents = {
    openViewUserDialog: (id) => {
      history.push(`${campaignUsersBaseUrl}/${id}/view`);
    },
  };

  return (
    <UsersUIProvider usersUIEvents={usersUIEvents}>
      <Switch>
        <Route path={`${campaignUsersBaseUrl}/:id/view`}>
          {({ history, match }) => (
            <UserViewDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                history.push(campaignUsersBaseUrl);
              }}
            />
          )}
        </Route>
      </Switch>
      <UsersCard />
    </UsersUIProvider>
  );
};

export default UsersPage;
