import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { RTLProps } from '@/shared/prop-types/ReducerProps';
import ABTestingAnalytics from './components/ABTestingAnalytics';
import BounceRateArea from './components/BounceRateArea';
import VisitorsSessions from './components/VisitorsSessions';
import SalesStatistic from './components/SalesStatistic';
import BudgetStatistic from './components/BudgetStatistic';
import AudienceByCountry from './components/AudienceByCountry';
import StatsSection from './components/stats';
// import DataStarAnalytics from './components/DataStarAnalytics';
import api from '@/utils/apiClient';
import './index.scss';

const Dashboard = ({ rtl }) => {
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">TalentSpy Admin Dashboard</h3>
        </Col>
      </Row>
      <StatsSection />
      <Row>
        {/* <DataStarAnalytics dir={rtl.direction} /> */}
        <ABTestingAnalytics dir={rtl.direction} />
        <BounceRateArea dir={rtl.direction} />
        <VisitorsSessions dir={rtl.direction} />
        <SalesStatistic />
        <BudgetStatistic />
        <AudienceByCountry />
      </Row>
    </Container>
  );
};

Dashboard.propTypes = {
  rtl: RTLProps.isRequired,
};

export default compose(
  connect((state) => ({
    rtl: state.rtl,
  })),
)(Dashboard);
