import React from 'react';
import { useDispatch } from 'react-redux';
import { paths } from '@/shared/helpers/paths';
import { Route, Switch, useHistory } from 'react-router-dom';
import { SubcategoryCard } from './SubcategoryCard';
import { setSubCategoryFilters } from '../redux/actions';
import { SubcategoryUIProvider } from './SubcategoryUIProvider';
import SubcategoryEditDialog from './subcategory-add-edit-dialog/SubcategoryEditDialog';
import SubcategoryDeleteDialog from './subcategory-delete-dialog/SubcategoryDeleteDialog';
import SubcategoryViewDialog from './subcategory-view-dialog/SubcategoryViewDialog';
import SubcategoryManageSkillDialog from './subcategory-manageSkill-dialog/SubcategoryManageSkillDialog';

const subCategoryBaseUrl = paths.SKILL_BANK.SUB_CATEGORIES;
export const getSubcategoryViewUrl = (id) => `${subCategoryBaseUrl}/${id}/view`;

export function SubcategoryPage() {
  const history = useHistory();

  const subCategoryUIEvents = {
    openManageSkillsDialog: (id) => {
      history.push(`${subCategoryBaseUrl}/${id}/manageSkills`);
    },
    openViewSubCategoryDialog: (id) => {
      history.push(getSubcategoryViewUrl(id));
    },
    openNewSubCategoryDialog: () => {
      history.push(`${subCategoryBaseUrl}/new`);
    },
    openEditSubCategoryDialog: (id) => {
      history.push(`${subCategoryBaseUrl}/${id}/edit`);
    },
    openDeleteSubCategoryDialog: (id) => {
      history.push(`${subCategoryBaseUrl}/${id}/delete`);
    },
  };

  const dispatch = useDispatch();

  return (
    <SubcategoryUIProvider subCategoryUIEvents={subCategoryUIEvents}>
      <Switch>
        <Route path={`${subCategoryBaseUrl}/new`}>
          {({ history, match }) => (
            <SubcategoryEditDialog
              show={match != null}
              isEdit={false}
              onHide={() => {
                dispatch(setSubCategoryFilters());
                history.push(subCategoryBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${subCategoryBaseUrl}/:id/edit`}>
          {({ history, match }) => (
            <SubcategoryEditDialog
              show={match != null}
              isEdit={true}
              id={match?.params?.id}
              onHide={() => {
                history.push(subCategoryBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${subCategoryBaseUrl}/:id/delete`}>
          {({ history, match }) => (
            <SubcategoryDeleteDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                history.push(subCategoryBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${subCategoryBaseUrl}/:id/view`}>
          {({ history, match }) => (
            <SubcategoryViewDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                history.push(subCategoryBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${subCategoryBaseUrl}/:id/manageSkills`}>
          {({ history, match }) => (
            <SubcategoryManageSkillDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                history.push(subCategoryBaseUrl);
              }}
            />
          )}
        </Route>
      </Switch>
      <SubcategoryCard />
    </SubcategoryUIProvider>
  );
}
