import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import DdoTableWrapper from './table/DdoTableWrapper';

export function DdoCard() {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">DDO</h3>
        </Col>
      </Row>
      <Row>
        <DdoTableWrapper />
      </Row>
    </Container>
  );
}
