import { sendRequest } from '../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const courseEndpoints = {
  GET_COURSE_LIST: '/getCourseList',
};

const getCourseList = (filter) => {
  return getMockCourseList();
  return sendRequest({
    url: courseEndpoints.GET_COURSE_LIST,
    method: httpMethod.GET,
    params: filter,
  });
};

export default {
  getCourseList,
};

const getMockCourseList = () =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: [
          { key: 'Test Course 1', value: 1 },
          { key: 'Test Course 2', value: 2 },
          { key: 'Test Course 3', value: 3 },
        ],
      });
    }, 500);
  });
