import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { SelectField } from '@/shared/components/form/Select';
import FormField from '@/shared/components/form/hook/FormField';
import { StepPropTypes, StepDefaultProps } from './Props';
import OfficeBuildingLocationIcon from 'mdi-react/OfficeBuildingLocationIcon';
import MobilePhoneBasicIcon from 'mdi-react/MobilePhoneBasicIcon';
import { extractFieldData, getStepFields } from '../index';

const StepTwo = ({ onSubmit, page, previousPage, data }) => {
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm(extractFieldData(page, data));

  React.useEffect(() => {
    getStepFields(page).forEach((key) => setValue(key, data[key]));
  }, [data]);

  return (
    <form className="form wizard__form" onSubmit={handleSubmit(onSubmit)}>
      <div className="form__form-group">
        <span className="form__form-group-label">First Name</span>
        <div className="form__form-group-field">
          <FormField
            name="firstName"
            as="input"
            placeholder="Enter first name"
            control={control}
            defaultValue=""
            errors={errors}
            rules={{ required: 'This is required field' }}
            isAboveError
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Last Name</span>
        <div className="form__form-group-field">
          <FormField
            name="lastName"
            as="input"
            placeholder="Enter last name"
            control={control}
            defaultValue=""
            errors={errors}
            rules={{ required: 'This is required field' }}
            isAboveError
          />
        </div>
      </div>
      {/* <div className="form__form-group">
        <span className="form__form-group-label">Role</span>
        <div className="form__form-group-field">
          <FormField
              control={control}
              name="role"
              component={SelectField}
              options={[
                { value: 'admin', label: 'Admin' },
              ]}
              placeholder="Select role"
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Phone Number(Office)</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <OfficeBuildingLocationIcon/>
          </div>
          <FormField
              name="companyOfficePhoneNo"
              as="input"
              type="number"
              placeholder="Enter phone number"
              control={control}
              defaultValue=""
              errors={errors}
              rules={{
                required: 'This is required field',
                minLength: {value: 10, message: 'Please enter valid phone number'},
                maxLength: {value: 13, message: 'Please enter valid phone number'}
              }}
              isAboveError
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Phone Number(Mobile)</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <MobilePhoneBasicIcon/>
          </div>
          <FormField
              name="companyOfficeMobileNo"
              as="input"
              type="number"
              placeholder="Enter mobile number"
              control={control}
              defaultValue=""
              errors={errors}
              rules={{
                required: 'This is required field',
                minLength: {value: 10, message: 'Please enter valid phone number'},
                maxLength: {value: 13, message: 'Please enter valid phone number'}
              }}
              isAboveError
          />
        </div>
      </div> */}

      <ButtonToolbar className="form__button-toolbar wizard__toolbar">
        <Button
          color="primary"
          type="button"
          className="previous"
          onClick={() => previousPage(getValues())}
        >
          Back
        </Button>
        <Button color="primary" type="submit" className="next">
          Next
        </Button>
      </ButtonToolbar>
    </form>
  );
};

StepTwo.propTypes = StepPropTypes;
StepTwo.defaultProps = StepDefaultProps;

export default StepTwo;
