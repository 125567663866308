import { handleActions } from 'redux-actions';
import { fetchEmailList, setEmailList, setEmailError, uncheckAllEmail } from './actions';

const defaultState = {
  loading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    search: '',
    isSortOrderDescending: false,
    status: null,
  },
  totalRecords: 0,
  data: [],
  options: [],
  error: null,
};

export default handleActions(
  {
    [fetchEmailList](state) {
      return {
        ...state,
        loading: true,
        data: [],
      };
    },
    [setEmailList](state, { payload }) {
      return {
        ...state,
        loading: false,
        data: payload.data.records,
        totalRecords: payload.data.totalRecords,
        error: null,
      };
    },
    [setEmailError](state, { payload }) {
      return {
        ...state,
        data: [],
        loading: false,
        totalPages: defaultState.totalPages,
        error: payload?.message,
      };
    },
    [setEmailError](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
      };
    },
    [uncheckAllEmail](state) {
      return {
        ...state,
      };
    },
  },
  defaultState,
);
