import Collapse from '@/shared/components/Collapse';
import React, { memo } from 'react';
import QuestionCollapse from './QuestionCollapse';
import { Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { addNewAssessmentConfigPageQuestion } from '../redux/actions';

const PageQuestionsCollapse = ({ id: pageId, questions }) => {
  const dispatch = useDispatch();

  const addQuestionToPage = () => {
    dispatch(addNewAssessmentConfigPageQuestion({ pageId }));
  };

  return (
    <Collapse title="Questions">
      {questions?.map((question, idx) => (
        <QuestionCollapse
          pageId={pageId}
          key={question.id}
          position={idx + 1}
          {...question}
          collapse={idx + 1 === questions.length}
        />
      ))}
      <div className="text-center">
        <Button color="success" className="m-0" onClick={addQuestionToPage} outline>
          Add new question
        </Button>
      </div>
    </Collapse>
  );
};

export default memo(PageQuestionsCollapse);
