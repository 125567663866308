import { useEffect } from 'react';
import Highcharts from 'highcharts';
import Tree from 'highcharts/modules/treemap';
import exporting from 'highcharts/modules/exporting';
import data from 'highcharts/modules/data';
import Heatmap from 'highcharts/modules/heatmap';

Tree(Highcharts);
data(Highcharts);
Heatmap(Highcharts);
exporting(Highcharts);

const LearningStacked = () => {
  const initialChart = () => {
    Highcharts.chart('LearningStacked', {
      chart: {
        type: 'column',
      },

      title: {
        text: '',
      },

      xAxis: {
        categories: ['Year 2', 'Year 3', 'Year 4', 'Year 5', 'Year 6'],
      },

      yAxis: {
        allowDecimals: true,
        min: 0,
        labels: {
          formatter: function () {
            return this.value + '%';
          },
        },
        title: {
          text: null,
        },
      },

      credits: {
        enabled: false,
      },

      tooltip: { enabled: false },

      colors: ['#66c3a5', '#ed8b5f', '#8d9fcb'],

      plotOptions: {
        column: {
          stacking: 'normal',
        },
        series: {
          enableMouseTracking: false,
        },
      },

      series: [
        {
          name: 'Participants',
          data: [7.4, 7.4, 7.8, 7.1, 8],
          stack: 'participants',
        },
        {
          name: 'Intention to complete',
          data: [8.2, 7.2, 8.4, 6.9, 7.3],
          stack: 'intentionToComplete',
        },
        {
          name: 'Verified',
          data: [17.7, 13.1, 15.2, 14.9, 19],
          stack: 'verified',
        },
      ],
    });
  };

  useEffect(() => {
    initialChart();
  }, []);

  return <div id="LearningStacked" />;
};

export default LearningStacked;
