import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { shallowEqual, useSelector } from 'react-redux';
import { ModulePage } from '@/containers/Control-Panel/Module/components/ModulePage';

const StepOne = ({ onSubmit }) => {
  const { checkedModule } = useSelector((state) => state.cpModules, shallowEqual);

  return (
    <div className="step-one">
      <br />

      <ModulePage isForAssignment={true} />

      <br />

      <ButtonToolbar className="form__button-toolbar wizard__toolbar">
        <Button color="primary" disabled className="previous">
          Back
        </Button>
        <Button
          color="primary"
          className="next"
          disabled={checkedModule.id === -1}
          onClick={onSubmit}
        >
          Next
        </Button>
      </ButtonToolbar>
    </div>
  );
};

export default StepOne;
