import React from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import JobTable from './JobTable';
import { useJobUIContext } from '../JobUIProvider';

const JobTableWrapper = () => {
  const jobUIContext = useJobUIContext();

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="products-list">
          <div className="card__title">
            <h5 className="bold-text">Job List</h5>
            <ButtonToolbar className="products-list__btn-toolbar-top">
              <Button color="primary" onClick={jobUIContext.openNewJobDialog}>
                Add new Job
              </Button>
            </ButtonToolbar>
          </div>
          <JobTable />
        </CardBody>
      </Card>
    </Col>
  );
};

export default JobTableWrapper;
