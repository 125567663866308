import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import TrainingProviderViewDialogHeader from './TrainingProviderViewDialogHeader';
import api from '@/utils/apiClient';
import LoadingIcon from 'mdi-react/LoadingIcon';
import SanitizeHTML from '@/shared/components/SanitizeHTML';

const TrainingProviderViewDialog = ({ show, onHide, id, rtl }) => {
  const [modal] = useState({
    isOpen: show,
    title: 'View Training Provider',
    hide: onHide,
  });

  const [state, setState] = useState({
    loading: true,
    trainingProvider: {},
    error: '',
  });

  useEffect(() => {
    if (id) getTrainingProviderById(+id);
  }, [id]);

  const getTrainingProviderById = async (id) => {
    try {
      const { data: res, isSuccess } = await api.cadsAITrainingProvider.getTrainingProviderById(id);
      if (id) {
        setState({ ...state, loading: false, trainingProvider: res.data, error: '' });
      } else {
        throw new Error(res?.messages?.[0] ?? 'Error while getting training provider details');
      }
    } catch ({ message }) {
      setState({ ...state, loading: false, trainingProvider: {}, error: message });
      console.error(message);
    }
  };

  const { error, loading, trainingProvider } = state;

  return (
    <Modal
      isOpen={show}
      toggle={modal.hide}
      modalClassName={`${rtl.direction}-support`}
      className="modal-dialog--primary modal-dialog--header modal-view-dialog"
    >
      <TrainingProviderViewDialogHeader modal={modal} trainingProvider={trainingProvider} />
      {/* trainingProvider Modal Body */}
      <div style={{ padding: 15 }}>
        {loading || !!error ? (
          <>
            {loading && (
              <div className="panel__refresh position-relative" style={{ height: 75 }}>
                <LoadingIcon />
              </div>
            )}
            <div className="text-center">
              {error && (
                <div>
                  <span className="lnr lnr-cross-circle modal__title-icon error" />
                </div>
              )}
              {loading ? 'Getting training provider details...' : error}
            </div>
          </>
        ) : (
          <table className="project-summary__info">
            <tr>
              <th>Image</th>
              <td>
                <img
                  style={{ height: 100, width: 100 }}
                  alt={'Training Provider Picture'}
                  src={
                    trainingProvider.logoUrl ??
                    'https://unified.dev.cads.ai/image/sample/rectangle-34.png'
                  }
                />
              </td>
            </tr>
            <tr>
              <th>Title</th>
              <td>{trainingProvider?.title ?? '-'}</td>
            </tr>
            <tr>
              <th>Description</th>
              <td>
                {trainingProvider.description ? (
                  <div className="question-extra-html">
                    <SanitizeHTML html={trainingProvider.description} />
                  </div>
                ) : (
                  '-'
                )}
              </td>
            </tr>
            <tr>
              <th>Has SSO ?</th>
              <td>{trainingProvider?.hasSso ? 'Yes' : 'No'}</td>
            </tr>
            <tr>
              <th>Is General ?</th>
              <td>{trainingProvider?.isGeneral ? 'Yes' : 'No'}</td>
            </tr>
            <tr>
              <th>SSO Secrets</th>
              <td>
                {/*TODO: change following once response has key ssoSecret as string*/}
                {JSON.parse(trainingProvider?.ssoSecrets ?? '')?.[0]?.SsoSecrets ? (
                  <div className="question-extra-html">
                    {JSON.parse(trainingProvider?.ssoSecrets ?? '')?.[0]?.SsoSecrets}
                  </div>
                ) : (
                  '-'
                )}
              </td>
            </tr>
            <tr>
              <th>Website Address</th>
              <td>
                <td>
                  <a
                    href={`${
                      trainingProvider?.websiteAddress?.startsWith('http') ? '' : 'https://'
                    }${trainingProvider?.websiteAddress}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {trainingProvider?.websiteAddress}
                  </a>
                </td>
              </td>
            </tr>
          </table>
        )}
      </div>
    </Modal>
  );
};

export default connect((state) => ({
  rtl: state.rtl,
}))(TrainingProviderViewDialog);
