import React from 'react';
import { useForm } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, ButtonToolbar } from 'reactstrap';
import api from '@/utils/apiClient';
import { emptyStringPattern } from '@/shared/helpers';
import { SelectField } from '@/shared/components/form/Select';
import { FormField } from '@/shared/components/form/hook/FormField';
import { getAssessmentCategoryOptionsList } from '../../redux/actions';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { getCustomersListOptions } from '@/containers/Admin/Customers/redux/actions';
import SettingsIcon from 'mdi-react/SettingsIcon';
import { useAssessmentUIContext } from '@/containers/Admin/Assessments/components/AssessmentUIProvider';

const AssessmentDuplicateForm = ({ modal, id }) => {
  const dispatch = useDispatch();
  const assessmentUIContext = useAssessmentUIContext();
  const [duplicatedAssessmentId, setDuplicatedAssessmentId] = React.useState(null);
  const [state, setState] = React.useState({
    loading: false,
    fetchingAssessmentDuplicate: false,
    assessmentDuplicated: false,
    errorMessage: '',
  });

  React.useEffect(() => {
    dispatch(getAssessmentCategoryOptionsList());
    dispatch(getCustomersListOptions());
  }, []);

  // categories options
  const categoryOptions = useSelector((state) => state.assessments.categoryOptions, shallowEqual);
  const [categoryOpts, setCategoryOpts] = React.useState([]);
  React.useEffect(() => {
    setCategoryOpts(categoryOptions?.map(({ id, title }) => ({ value: id, label: title })) ?? []);
  }, [categoryOptions]);

  // customer options
  const customerOptions = useSelector((state) => state.customers.options, shallowEqual);
  const [customerOpts, setCustomerOpts] = React.useState([]);
  React.useEffect(() => {
    setCustomerOpts(customerOptions?.map(({ id, title }) => ({ value: id, label: title })) ?? []);
  }, [customerOptions]);

  // AssessmentDuplicate form related functions
  const {
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();

  const resetForm = () => {
    reset({ title: '', assessmentCategory: null, customer: null });
  };

  const onSubmit = async ({ title, assessmentCategory, customer }) => {
    const duplicationData = {
      title,
      assessmentId: +id,
      organizationId: customer.value,
      subcategoryId: assessmentCategory.value,
    };
    setState({ ...state, loading: true, errorMessage: '' });
    const defaultError = 'Error occurred while duplicating assessment';
    try {
      const { data: res, isSuccess } = await api.assessments.duplicateAssessment(duplicationData);
      if (isSuccess) {
        setDuplicatedAssessmentId(res.data.id);
        setState({ ...state, assessmentDuplicated: true, loading: false });
        resetForm();
      } else {
        throw new Error(res?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({
        ...state,
        loading: false,
        assessmentDuplicated: false,
        errorMessage: error.message,
      });
    }
  };

  const goToConfigPage = () => {
    if (duplicatedAssessmentId)
      assessmentUIContext.openAssessmentConfigureDialog(duplicatedAssessmentId);
    else
      setState({
        ...state,
        assessmentDuplicated: false,
        errorMessage: 'Error while redirecting to configure duplicated assessment',
      });
  };

  const onCancel = () => {
    resetForm();
    modal.hide();
  };

  return (
    <div>
      {/*Add/Edit AssessmentDuplicate Form Status*/}
      <div className="ml-4">
        {state.loading && (
          <div>
            <div className="panel__refresh position-relative" style={{ height: 75, width: 20 }}>
              <LoadingIcon />
              <div style={{ width: 300, padding: '23px 40px' }}>Duplicating Assessment...</div>
            </div>
          </div>
        )}
        {(state.assessmentDuplicated || !!state.errorMessage) && (
          <table className="mt-3">
            <tr>
              <td>
                {state.assessmentDuplicated ? (
                  <span className="lnr lnr-thumbs-up modal__title-icon success" />
                ) : (
                  <span className="lnr lnr-cross-circle modal__title-icon error" />
                )}
              </td>
              <td className="pl-2">
                {state.assessmentDuplicated
                  ? 'New Assessment Duplicated Successfully'
                  : state.errorMessage}
              </td>
            </tr>
          </table>
        )}
      </div>

      {/*Add/Edit AssessmentDuplicate Form*/}
      <div className="modal__body">
        {(!state.assessmentDuplicated || !!state.errorMessage) && (
          /*Render form if assessment is not already duplicated */
          <form className={`form`} onSubmit={handleSubmit(onSubmit)}>
            <div className="form__form-group">
              <span className="form__form-group-label">Assessment Name</span>
              <div className="form__form-group-field">
                <FormField
                  name="title"
                  control={control}
                  component="input"
                  errors={errors}
                  rules={{
                    required: 'This is required field',
                    pattern: emptyStringPattern,
                  }}
                  defaultValue=""
                  isAboveError={true}
                  placeholder="A title for assessment"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Assessment Category</span>
              <div className="form__form-group-field">
                <FormField
                  control={control}
                  name="assessmentCategory"
                  component={SelectField}
                  options={categoryOpts}
                  placeholder="Select Assessment Category"
                  rules={{ required: 'This is required field' }}
                  errors={errors}
                  isAboveError
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Customer</span>
              <div className="form__form-group-field">
                <FormField
                  control={control}
                  name="customer"
                  component={SelectField}
                  options={customerOpts}
                  placeholder="Select Customer To Assign"
                  rules={{ required: 'This is required field' }}
                  errors={errors}
                  isAboveError
                />
              </div>
            </div>
            <ButtonToolbar className="form__button-toolbar modal__footer">
              <Button disabled={state.loading} color="primary" type="submit">
                Submit
              </Button>
              <Button type="button" className="modal_cancel" onClick={onCancel}>
                Close
              </Button>
            </ButtonToolbar>
          </form>
        )}
        {state.assessmentDuplicated && !state.errorMessage && (
          <div>
            <Button onClick={goToConfigPage} color="primary" type="button" outline>
              {/*TODO: redirect to configure path*/}
              <p>
                <SettingsIcon />
                Configure Assessment
              </p>
            </Button>
            <Button onClick={onCancel} color="secondary" type="button" outline>
              I Will Do It Later
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AssessmentDuplicateForm;
