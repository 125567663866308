import { handleActions } from 'redux-actions';
import { fetchEmployeeList, setEmployeeError, setEmployeeFilters, setEmployeeList, toggleCheckedEmployee, uncheckAllEmployee, } from './actions';

const defaultState = {
  loading: false,
  checkedEmployeeIds: [],
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    searchText: '',
    isSortOrderDescending: false,
    status: null,
  },
  totalRecords: 0,
  data: [],
  options: [],
  error: null,
};

export default handleActions(
  {
    [fetchEmployeeList](state) {
      return {
        ...state,
        loading: true,
        data: [],
      };
    },
    [setEmployeeList](state, { payload }) {
      return {
        ...state,
        loading: false,
        data: payload.data?.records,
        totalRecords: payload.data?.totalRecords,
        error: null,
      };
    },
    [setEmployeeError](state, { payload }) {
      return {
        ...state,
        data: [],
        loading: false,
        totalPages: defaultState.totalPages,
        error: payload?.message,
      };
    },
    [setEmployeeFilters](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
        checkedEmployeeIds: [],
      };
    },
    [toggleCheckedEmployee](state, { payload: employeeId }) {
      const checkedIds = state.checkedEmployeeIds;
      return {
        ...state,
        checkedEmployeeIds: checkedIds.includes(employeeId)
          ? checkedIds.filter((id) => id !== employeeId)
          : [...checkedIds, employeeId],
      };
    },
    [uncheckAllEmployee](state) {
      return {
        ...state,
        checkedEmployeeIds: [],
      };
    },
  },
  defaultState,
);
