import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { renderComponentField } from '@/shared/components/form/FormField';

export const SelectField = React.forwardRef(
  ({ onChange, value, name, placeholder, options, disabled, clearable = false, ...rest }, ref) => {
    const handleChange = (selectedOption) => {
      onChange(selectedOption);
    };

    return (
      <Select
        name={name}
        value={value}
        onChange={handleChange}
        options={options}
        isClearable={clearable}
        isDisabled={disabled}
        className="react-select"
        placeholder={placeholder}
        classNamePrefix="react-select"
        ref={ref}
        {...rest}
      />
    );
  },
);

SelectField.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  clearable: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ]).isRequired,
};

SelectField.defaultProps = {
  placeholder: '',
  options: [],
};

export default renderComponentField(SelectField);
