import React from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import BaselineThresholdTable from './BaselineThresholdTable';
import { useBaselineThresholdUIContext } from '../BaselineThresholdUIProvider';
import { shallowEqual, useSelector } from 'react-redux';

const BaselineThresholdTableWrapper = () => {
  const baselineThresholdUIContext = useBaselineThresholdUIContext();
  const checkedOrgIds = useSelector((state) => state.baselineThreshold.checkedOrgIds, shallowEqual);

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="products-list">
          <div className="card__title">
            <h5 className="bold-text">Organization List</h5>
            <ButtonToolbar className="products-list__btn-toolbar-top">
              <Button
                color="success"
                onClick={baselineThresholdUIContext.openBaselineThresholdConfigDialog}
                outline
                disabled={checkedOrgIds.length === 0}
              >
                Configure Baseline Threshold
              </Button>
            </ButtonToolbar>
          </div>
          <BaselineThresholdTable />
        </CardBody>
      </Card>
    </Col>
  );
};

export default BaselineThresholdTableWrapper;
