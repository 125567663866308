import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loading from '@/shared/components/Loading';
import { paths } from '@/shared/helpers/paths';
import { QAPage } from './components/QAPage';
import './index.scss';

export const cadsAiQABaseUrl = paths.CADS_AI.HOME.QA;

const QA = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path={cadsAiQABaseUrl} component={QAPage} />
      </Switch>
    </Suspense>
  );
};

export default QA;
