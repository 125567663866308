import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import CourseDialogHeader from './CourseDialogHeader';
import api from '@/utils/apiClient';
import CourseViewTabs from './CourseViewTabs';
import LoadingIcon from 'mdi-react/LoadingIcon';

const CoursesViewDialog = ({ show, onHide, id, rtl }) => {
  const [modal, setModal] = useState({
    isOpen: show,
    title: 'View Course',
    hide: onHide,
  });

  const [state, setState] = React.useState({
    loading: false,
    courses: {
      name: 'Test Courses 1',
      description: 'any',
    },
    error: '',
  });

  React.useEffect(() => {
    if (id) getCadsAiCoursesById(+id);
  }, [id]);

  const getCadsAiCoursesById = async (id) => {
    try {
      setState({ ...state, loading: true });
      const { data: res, isSuccess } = await api.cadsAICourse.getCourseById(id);
      if (isSuccess) {
        setState({ ...state, loading: false, courses: res?.data, error: '' });
      } else {
        throw new Error(res?.messages?.[0] ?? 'Error while getting course details');
      }
    } catch ({ message }) {
      setState({ ...state, loading: false, courses: {}, error: message });
      console.error(message);
    }
  };

  const { error, loading, courses } = state;

  return (
    <Modal
      isOpen={show}
      toggle={modal.hide}
      modalClassName={`${rtl.direction}-support`}
      className="modal-dialog--primary modal-dialog--header modal-view-dialog"
    >
      <CourseDialogHeader modal={modal} courses={courses} />
      <div style={{ padding: 15 }}>
        {loading || !!error ? (
          <>
            {loading && (
              <div className="panel__refresh position-relative" style={{ height: 75 }}>
                <LoadingIcon />
              </div>
            )}
            <div className="text-center">
              {error && (
                <div>
                  <span className="lnr lnr-cross-circle modal__title-icon error" />
                </div>
              )}
              {loading ? 'Getting learning path details...' : error}
            </div>
          </>
        ) : (
          <CourseViewTabs courses={courses} />
        )}
      </div>
    </Modal>
  );
};

export default connect((state) => ({
  rtl: state.rtl,
}))(CoursesViewDialog);
