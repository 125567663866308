import { handleActions } from 'redux-actions';
import {
  setLearningPathFetching,
  setAssignLearningPathList,
  setLearningPathError,
  setLearningPathFilters,
  toggleCheckedLearningPath,
  clearCheckedLearningPath,
  setLearningPathGroupOptions,
  clearLearningPathFilters,
  updateLearningPathInList,
} from './actions';

const defaultState = {
  loading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    search: '',
    isSortOrderDescending: false,
  },
  totalRecords: 0,
  assignLearningPaths: [],
  learningPathGroupOptions: [],
  error: null,
  checkedLearningPath: { id: -1, name: 'Select Learning Path' }, //setting to -1 if no learningPath is selected
};

export default handleActions(
  {
    [setLearningPathFetching](state) {
      return {
        ...state,
        assignLearningPaths: [],
        totalRecords: 0,
        loading: true,
        error: null,
      };
    },
    [setLearningPathError](state, { payload }) {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    },
    [setAssignLearningPathList](state, { payload }) {
      const assignLearningPathList = payload.data;
      return {
        ...state,
        assignLearningPaths: assignLearningPathList?.records,
        totalRecords: assignLearningPathList?.totalRecords,
        loading: false,
        error: null,
      };
    },
    [setLearningPathFilters](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
      };
    },
    [toggleCheckedLearningPath](state, { payload }) {
      return {
        ...state,
        checkedLearningPath: payload ?? defaultState.checkedLearningPath,
      };
    },
    [clearCheckedLearningPath](state) {
      return {
        ...state,
        checkedLearningPath: defaultState.checkedLearningPath,
      };
    },
    [setLearningPathGroupOptions](state, { payload }) {
      return {
        ...state,
        learningPathGroupOptions: payload.data?.map((grp) => ({ value: grp.id, label: grp.name })),
      };
    },
    [clearLearningPathFilters](state) {
      return {
        ...state,
        filters: { ...defaultState.filters },
      };
    },
    [updateLearningPathInList](state, { payload }) {
      return {
        ...state,
        assignLearningPaths: state.assignLearningPaths?.map((lp) =>
          lp.id === payload.id ? { ...lp, ...payload } : lp,
        ),
      };
    },
  },
  defaultState,
);
