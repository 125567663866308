import { httpMethod } from '@/shared/helpers/httpMethod.enum';
import { sendRequest } from '../base/ApiService';

const CADS_AI_CP_API =
  process.env.REACT_APP_CADS_AI_API + process.env.REACT_APP_CADS_AI_CONTROL_PANEL_ENDPOINT;

const sponsorsEndpoints = {
  GET_ALL_SPONSORS: `${CADS_AI_CP_API}/getAllSponsors`,
  ADD_SPONSORS: `${CADS_AI_CP_API}/addSponsor`,
  EDIT_SPONSORS: `${CADS_AI_CP_API}/editSponsor`,
  EXPORT_ALL_SPONSORS: `${CADS_AI_CP_API}/exportAllSponsors`,
};

const getSponsorsList = (filter) => {
  return sendRequest({
    fullUrl: sponsorsEndpoints.GET_ALL_SPONSORS,
    method: httpMethod.GET,
    params: filter,
  });
};

const addSponsors = (data) => {
  return sendRequest({
    fullUrl: sponsorsEndpoints.ADD_SPONSORS,
    method: httpMethod.POST,
    data: data,
  });
};

const editSponsors = (data) => {
  return sendRequest({
    fullUrl: sponsorsEndpoints.EDIT_SPONSORS,
    method: httpMethod.PUT,
    data: data,
  });
};

const getSponsorListCSV = (filter) => {
  return sendRequest({
    fullUrl: sponsorsEndpoints.EXPORT_ALL_SPONSORS,
    method: httpMethod.GET,
    params: filter,
  });
};

export default {
  getSponsorsList,
  addSponsors,
  editSponsors,
  getSponsorListCSV,
};
