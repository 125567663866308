import React, { Fragment, useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import { Button } from 'reactstrap';
import { useSponsorshipUIContext } from '../SponsorshipUIProvider';
import SponsorshipTableColumns, { SponsorshipColumns } from './SponsorshipTableColumns';
import { SponsorshipTableSearchBar } from './SponsorshipTableSearchBar';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getSponsorshipList,
  setSponsorshipFilters,
  toggleCheckedItemById,
} from '../../redux/actions';
import SanitizeHTML from '@/shared/components/SanitizeHTML';
import EyeIcon from 'mdi-react/EyeIcon';
import PencilIcon from 'mdi-react/PencilIcon';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { defaultPaginationProps } from '@/shared/helpers';
import { FormGroup, Input } from 'reactstrap';
import DeleteIcon from 'mdi-react/DeleteIcon';

const SponsorshipTable = () => {
  const sponsorshipUIContext = useSponsorshipUIContext();
  const dispatch = useDispatch();
  const {
    data: sponsorships,
    error,
    filters,
    loading,
    totalRecords,
    checkedSponsorshipIds,
  } = useSelector((state) => state.cadsAISponsorships, shallowEqual);
  const {
    pageSize: rowsPerPage,
    pageNumber: page,
    sortBy: orderBy,
    isSortOrderDescending,
  } = filters;

  const [columns, setColumns] = useState(0);

  useEffect(() => {
    setColumns(SponsorshipColumns.length);
  }, [SponsorshipColumns.length]);

  React.useEffect(() => {
    dispatch(getSponsorshipList(filters));
  }, [filters]);

  const handleRequestSort = (event, property) => {
    dispatch(
      setSponsorshipFilters({
        sortBy: property,
        isSortOrderDescending: !isSortOrderDescending,
      }),
    );
  };

  const renderActionButtons = (sponsorshipId) => (
    <div className="actions">
      <Button
        className="icon mb-0"
        color="success"
        outline
        onClick={() => sponsorshipUIContext.openViewSponsorshipDialog(sponsorshipId)}
      >
        <p>
          <EyeIcon /> View
        </p>
      </Button>
      <Button
        className="icon mb-0"
        color="primary"
        outline
        onClick={() => sponsorshipUIContext.openEditSponsorshipDialog(sponsorshipId)}
      >
        <p>
          <PencilIcon /> Edit
        </p>
      </Button>
      <Button
        className="icon mb-0"
        color="danger"
        outline
        onClick={() => sponsorshipUIContext.openDeleteSponsorshipDialog(sponsorshipId)}
      >
        <p>
          <DeleteIcon /> Remove
        </p>
      </Button>
    </div>
  );

  const handleChangePage = (event, currentPage) => {
    dispatch(setSponsorshipFilters({ pageSize: rowsPerPage, pageNumber: currentPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setSponsorshipFilters({ pageSize: +event.target.value, pageNumber: 1 }));
  };

  const emptyRows =
    page === Math.ceil(totalRecords / rowsPerPage) ? rowsPerPage - sponsorships.length : 0;

  return (
    <Fragment>
      <div className="material-table__wrap">
        <SponsorshipTableSearchBar />
        <Table className="material-table">
          <SponsorshipTableColumns
            order={isSortOrderDescending ? 'desc' : 'asc'}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rowsPerPage}
          />
          <TableBody>
            {(loading || !!error) && (
              <TableRow>
                <TableCell colSpan={columns}>
                  {loading && (
                    <div className={'text-center overflow-hidden'}>
                      <div className="panel__refresh position-relative" style={{ height: 75 }}>
                        <LoadingIcon />
                      </div>
                    </div>
                  )}
                  <div className={'text-center'}>
                    <p className={!!error ? 'm-5' : ''}>{error || 'Loading sponsorships...'}</p>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {!!!sponsorships.length && !loading && !error && (
              <TableRow style={{ height: 120 }}>
                <TableCell colSpan={columns} align="center">
                  {filters.status === null && filters.search === ''
                    ? 'No Sponsorships'
                    : 'No Sponsorships with applied filters'}
                </TableCell>
              </TableRow>
            )}
            {sponsorships.map((sponsorship) => {
              return (
                <TableRow className="material-table" tabIndex={-1} key={sponsorship?.id}>
                  <TableCell align="center" className="material-table__cell">
                    <FormGroup>
                      <Input
                        type="checkbox"
                        value={sponsorship?.id}
                        defaultChecked={checkedSponsorshipIds.includes(sponsorship?.id)}
                        onChange={(e) => dispatch(toggleCheckedItemById(+e.target.value))}
                      />
                    </FormGroup>
                  </TableCell>
                  <TableCell className="material-table__cell" align="center">
                    <img
                      className={'module_logo_image'}
                      src={sponsorship.image}
                      alt={'Sponsorship Image'}
                    />
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {sponsorship.title}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    <SanitizeHTML html={sponsorship?.description} />
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {sponsorship.readCount}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {sponsorship.followerCount}
                  </TableCell>
                  <TableCell
                    align="center"
                    className="material-table__cell"
                    style={{ minWidth: 250 }}
                  >
                    {renderActionButtons(sponsorship?.id)}
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={columns} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        component={defaultPaginationProps.component}
        backIconButtonProps={defaultPaginationProps.backIconButton}
        nextIconButtonProps={defaultPaginationProps.nextIconButton}
        rowsPerPageOptions={defaultPaginationProps.rowsPerPage}
        className={defaultPaginationProps.className}
        dir={defaultPaginationProps.dir}
      />
    </Fragment>
  );
};

export default SponsorshipTable;
