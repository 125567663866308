import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, ButtonToolbar } from 'reactstrap';
import { RadioButtonField } from '@/shared/components/form/RadioButton';
import api from '@/utils/apiClient';
import { emptyStringPattern, YesNoOptions } from '@/shared/helpers';
import { FormField } from '@/shared/components/form/hook/FormField';
import { extractFieldData, getStepFields } from '../index';
import { SelectField } from '@/shared/components/form/Select';

const StepTwo = ({ onSubmit, page, data, previousPage }) => {
  //states
  const [jobCurrencyOptions, setJobCurrencyOptions] = React.useState([]);
  const [salaryNegotiable, setSalaryNegotiable] = React.useState('true');

  const getJobCurrencyOptions = async () => {
    const { data: res, isSuccess } = await api.cadsAIJob.getJobCurrencyOptions();
    if (isSuccess) {
      setJobCurrencyOptions(res.data.map((skill) => ({ value: skill.id, label: skill.title })));
    }
  };

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm(extractFieldData(page, data));

  React.useEffect(() => {
    getJobCurrencyOptions();
  }, []);

  React.useEffect(() => {
    getStepFields(page).forEach((key) => {
      setValue(key, data[key]);
    });
    setSalaryNegotiable(data?.salaryNegotiable);
  }, [data]);

  return (
    <div className="modal__body add-edit-learning-path">
      <form
        className={`form form--horizontal`}
        onSubmit={handleSubmit((data) => onSubmit({ ...data, salaryNegotiable: salaryNegotiable }))}
      >
        <div className="form__form-group">
          <span className="form__form-group-label">Is Salary Negotiable</span>
          <div className="form__form-group-field mt-2">
            <RadioButtonField
              radioValue={`${YesNoOptions[0].value}`}
              name={'isSalaryNegotiable'}
              onChange={setSalaryNegotiable}
              value={salaryNegotiable}
              className="colored-click mr-5"
              label={YesNoOptions[0].label}
            />
            <RadioButtonField
              radioValue={`${YesNoOptions[1].value}`}
              name={'isSalaryNegotiable'}
              onChange={setSalaryNegotiable}
              value={salaryNegotiable}
              className="colored-click mr-5"
              label={YesNoOptions[1].label}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Minimum</span>
          <div className="form__form-group-field">
            <FormField
              name="salaryBegin"
              control={control}
              errors={errors}
              type="number"
              min={0}
              rules={{
                required: 'This is required field',
                pattern: emptyStringPattern,
              }}
              defaultValue=""
              isAboveError={true}
              placeholder="Minimum"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Maximum</span>
          <div className="form__form-group-field">
            <FormField
              name="salaryEnd"
              control={control}
              component="input"
              type="number"
              min={0}
              errors={errors}
              rules={{
                required: 'This is required field',
                pattern: emptyStringPattern,
              }}
              defaultValue=""
              isAboveError={true}
              placeholder="Maximum"
            />
          </div>
        </div>
        <div className="form__form-group mt-3">
          <span className="form__form-group-label">Select Currency</span>
          <div className="form__form-group-field">
            <FormField
              control={control}
              name="currency"
              component={SelectField}
              options={jobCurrencyOptions}
              placeholder="Select Currency"
              rules={{ required: 'This is required field' }}
              errors={errors}
              isAboveError
            />
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar modal__footer">
          <Button onClick={previousPage} type="button" className="previous">
            Back
          </Button>
          <Button color="primary" type="submit">
            Next
          </Button>
        </ButtonToolbar>
      </form>
    </div>
  );
};

export default StepTwo;
