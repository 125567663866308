import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Button, ButtonToolbar } from 'reactstrap';
import api from '@/utils/apiClient';
import { emptyStringPattern } from '@/shared/helpers';
import { FormField } from '@/shared/components/form/hook/FormField';
import { updateDomainInList } from '../../redux/actions';
import LoadingIcon from 'mdi-react/LoadingIcon';

const DomainEditForm = ({ modal, isEdit, id }) => {
  const dispatch = useDispatch();

  //states
  const [state, setState] = React.useState({
    loading: false,
    fetchingDomain: false,
    newDomainAdded: false,
    errorMessage: '',
  });

  // prefill the form in case of edit domain
  React.useEffect(() => {
    if (isEdit && id) {
      setDefaultData(+id);
    }
  }, [isEdit, id]);

  const setDefaultData = async (domainId) => {
    try {
      const { data: res, isSuccess } = await api.domain.getDomainById(domainId);
      if (isSuccess) {
        ['id', 'name', 'description'].forEach((field) => setValue(field, res?.data?.[field]));
      } else {
        throw new Error(res?.messages?.[0]);
      }
    } catch ({ message }) {
      console.error('Domain edit error : ', message);
      setState({ ...state, errorMessage: 'Error while getting existing domain details' });
    }
  };

  // domain form related functions
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const resetForm = () => {
    reset({ id: 0, name: '', description: '' });
  };

  const onSubmit = async ({ name, description }) => {
    setState({ ...state, loading: true });
    const defaultError = `Error occurred while ${isEdit ? 'Updating' : 'Adding new'} domain`;
    try {
      const { data, isSuccess } = isEdit
        ? await api.domain.editDomain({ id, name, description })
        : await api.domain.addDomain({ name, description });
      if (isSuccess) {
        setState({ ...state, newDomainAdded: true, loading: false });

        if (isEdit) dispatch(updateDomainInList(data));
        else resetForm();
      } else {
        throw new Error(data?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({ ...state, loading: false, newDomainAdded: false, errorMessage: error.message });
    }
  };

  const onCancel = () => {
    resetForm();
    modal.hide();
  };

  return (
    <div>
      {/*Add/Edit Domain Form Status*/}
      <div className="ml-4">
        {state.loading && (
          <div>
            <div className="panel__refresh position-relative" style={{ height: 75, width: 20 }}>
              <LoadingIcon />
              <div style={{ width: 200, padding: '23px 40px' }}>
                {isEdit ? 'Updating' : 'Adding new'} domain...
              </div>
            </div>
          </div>
        )}
        {(state.newDomainAdded || !!state.errorMessage) && (
          <table className="mt-3">
            <tr>
              <td>
                {state.newDomainAdded ? (
                  <span className="lnr lnr-thumbs-up modal__title-icon success" />
                ) : (
                  <span className="lnr lnr-cross-circle modal__title-icon error" />
                )}
              </td>
              <td className="pl-2">
                {state.newDomainAdded
                  ? `${isEdit ? 'Domain Updated' : 'New Domain Added'} successfully`
                  : state.errorMessage}
              </td>
            </tr>
          </table>
        )}
      </div>

      {/*Add/Edit Domain Form*/}
      <div className="modal__body">
        <form className={`form`} onSubmit={handleSubmit(onSubmit)}>
          <div className="form__form-group">
            <span className="form__form-group-label">Name</span>
            <div className="form__form-group-field">
              <FormField
                name="name"
                control={control}
                component="input"
                errors={errors}
                rules={{
                  required: 'This is required field',
                  pattern: emptyStringPattern,
                }}
                defaultValue=""
                isAboveError={true}
                placeholder="Name of the domain"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Description</span>
            <div className="form__form-group-field">
              <FormField
                name="description"
                control={control}
                component="textarea"
                errors={errors}
                rules={{
                  required: 'This is required field',
                  pattern: emptyStringPattern,
                }}
                defaultValue=""
                isAboveError={true}
                placeholder="Write description about domain"
              />
            </div>
          </div>
          <ButtonToolbar className="form__button-toolbar modal__footer">
            <Button disabled={state.loading} color="primary" type="submit">
              Submit
            </Button>
            <Button type="button" className="modal_cancel" onClick={onCancel}>
              Close
            </Button>
          </ButtonToolbar>
        </form>
      </div>
    </div>
  );
};

export default DomainEditForm;
