import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import FormField from '@/shared/components/form/hook/FormField';
import TextEditor from '@/shared/components/text-editor/TextEditor';
import { useProgramsUIContext } from '@/containers/Data-Star/Programs/components/ProgramsUIProvider';
import { getStepFields } from '@/containers/Data-Star/Programs/components/program-add-edit-dialog/ProgramAddEditForm';
import { urlPattern } from '@/shared/helpers';

const ProgramDetailsTab = ({ onSubmit, data, modal, isEdit, formProps }) => {
  const { handleSubmit, control, setValue, errors } = formProps;
  const programsUIContext = useProgramsUIContext();

  React.useEffect(() => {
    getStepFields().forEach((key) => setValue(key, data[key]));
  }, [data]);

  return (
    <div className="form-wrapper">
      <form className="form form--horizontal" onSubmit={handleSubmit(onSubmit)}>
        <div className="form__form-group">
          <span className="form__form-group-label">Title</span>
          <div className="form__form-group-field">
            <FormField
              name="title"
              as="input"
              placeholder="Enter title"
              control={control}
              defaultValue=""
              errors={errors}
              rules={{ required: 'This is required field' }}
              isAboveError
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Headline</span>
          <div className="form__form-group-field">
            <FormField
              name="headline"
              as="input"
              placeholder="Enter headline"
              control={control}
              defaultValue=""
              errors={errors}
              rules={{ required: 'This is required field' }}
              isAboveError
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Description</span>
          <div className="form__form-group-field">
            <FormField
              control={control}
              name="description"
              component={() => (
                <TextEditor
                  defaultContent={data?.description}
                  onChange={(value) => setValue('description', value)}
                />
              )}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Start Date</span>
          <div className="form__form-group-field">
            <FormField
              name="start_date"
              type="date"
              placeholder="Enter start Date"
              control={control}
              defaultValue=""
              errors={errors}
              rules={{
                required: 'This is required field',
              }}
              isAboveError
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">End Date</span>
          <div className="form__form-group-field">
            <FormField
              name="end_date"
              type="date"
              placeholder="Enter end Date"
              control={control}
              defaultValue=""
              errors={errors}
              rules={{
                required: 'This is required field',
              }}
              isAboveError
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Tags</span>
          <div className="form__form-group-field">
            <FormField
              name="tags"
              as="input"
              placeholder="Enter Tags"
              control={control}
              defaultValue=""
              errors={errors}
              isAboveError
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Location</span>
          <div className="form__form-group-field">
            <FormField
              name="location"
              as="input"
              placeholder="Enter Location"
              control={control}
              defaultValue=""
              errors={errors}
              rules={{ required: 'This is required field' }}
              isAboveError
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Country Name</span>
          <div className="form__form-group-field">
            <FormField
              name="country_name"
              as="input"
              placeholder="Enter Country Name"
              control={control}
              defaultValue=""
              errors={errors}
              rules={{ required: 'This is required field' }}
              isAboveError
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Bg img url</span>
          <div className="form__form-group-field">
            <FormField
              name="bg_img_url"
              as="input"
              placeholder="Enter Bg img url"
              control={control}
              defaultValue=""
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Logo img url</span>
          <div className="form__form-group-field">
            <FormField
              name="logo_img_url"
              as="input"
              placeholder="Enter Logo img url"
              control={control}
              defaultValue=""
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Main img url</span>
          <div className="form__form-group-field">
            <FormField
              name="main_img_url"
              as="input"
              placeholder="Enter Main img url"
              control={control}
              defaultValue=""
            />
          </div>
        </div>

        <ButtonToolbar className="modal__footer btn-toolbar">
          <Button color="primary" className="btn btn-primary" type="submit">
            Submit
          </Button>
          <Button
            color="secondary"
            type="button"
            className="modal_cancel btn btn-secondary"
            onClick={() =>
              isEdit ? programsUIContext.openViewProgramsDialog(data?.['id']) : modal.hide()
            }
          >
            Close
          </Button>
        </ButtonToolbar>
      </form>
    </div>
  );
};

export default ProgramDetailsTab;
