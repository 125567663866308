import React from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import NotificationTable from './NotificationTable';
import { useNotificationUIContext } from '../NotificationUIProvider';
import { shallowEqual, useSelector } from 'react-redux';

const NotificationTableWrapper = () => {
  const notificationUIContext = useNotificationUIContext();
  const { checkedNotificationIds } = useSelector(
    (state) => state.cadsAINotifications,
    shallowEqual,
  );

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="products-list">
          <div className="card__title">
            <h5 className="bold-text">Data Notification List</h5>
            <ButtonToolbar className="products-list__btn-toolbar-top">
              <Button color="primary" onClick={notificationUIContext.openNewNotificationDialog}>
                Add new
              </Button>
              <Button
                outline
                color="danger"
                disabled={checkedNotificationIds.length === 0}
                onClick={notificationUIContext.openDeactivateNotificationDialog}
              >
                Deactivate
              </Button>
            </ButtonToolbar>
          </div>
          <NotificationTable />
        </CardBody>
      </Card>
    </Col>
  );
};

export default NotificationTableWrapper;
