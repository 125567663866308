import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loading from '@/shared/components/Loading';
import { paths } from '@/shared/helpers/paths';
import './index.scss';
import { CoursePage } from './components/CoursePage';

const courseBaseUrl = paths.CADS_AI.LEARNING.COURSE;
export default function Courses() {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path={courseBaseUrl} component={CoursePage} />
      </Switch>
    </Suspense>
  );
}
