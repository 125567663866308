import React, { Fragment, useRef, useState } from 'react';
import { getOrganizationOptionsList } from '@/containers/Control-Panel/redux/organization/actions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import { defaultPaginationProps } from '@/shared/helpers';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import LoadingIcon from 'mdi-react/LoadingIcon';
import api from '@/utils/apiClient';
import PencilIcon from 'mdi-react/PencilIcon';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import Select from 'react-select';
import MagnifyIcon from 'mdi-react/MagnifyIcon';

const columns = [
  {
    id: 'picUrl',
    disablePadding: true,
    label: 'Picture',
  },
  {
    id: 'orgName',
    disablePadding: true,
    label: 'Organization Name',
  },
  {
    id: 'orgEmail',
    disablePadding: true,
    label: 'Email',
  },
  {
    id: 'trainingProvidersList',
    disablePadding: true,
    label: 'Training Providers',
  },
  {
    id: 'action',
    disablePadding: true,
    label: 'Actions',
  },
];

const AssignedTPTableColumns = ({ order, orderBy, onRequestSort }) => {
  const createSortHandler = (property, onRequestSort) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <Fragment>
      <TableRow>
        {columns.map((row, idx) => (
          <TableCell
            className="material-table__cell material-table__cell--sort material-table__cell-center"
            key={row.id}
            align={'center'}
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
          >
            {idx === 0 ? (
              <b>{row.label}</b>
            ) : (
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id, onRequestSort)}
                className="material-table__sort-label"
                dir="ltr"
              >
                <b>{row.label}</b>
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </Fragment>
  );
};

const UnassignDialog = ({ show, onHide, tenantId, trainingProviders }) => {
  const { rtl } = useSelector((state) => state, shallowEqual);

  const initialValues = {
    isOpen: show,
    title: 'Unassignment Confirmation',
    message: 'Select training provider to unassign',
    hide: onHide,
    loading: false,
    unassignmentSuccess: false,
    unassignmentError: false,
  };
  const [modal, setModal] = React.useState(initialValues);
  const [selectedTP, setSelectedTP] = React.useState({
    value: -1,
    label: 'Select Training Provider',
  });

  React.useEffect(() => {
    setModal({ ...modal, isOpen: show, hide: onHide });
  }, [show, onHide]);

  const performUnassignment = async () => {
    setModal({
      ...modal,
      loading: true,
      title: 'Performing Unassignment',
      message: 'Unassignment in progress...',
    });

    /*Make api call for performing assignment*/
    let response = await api.trainingProviders.unAssignTPToOrganization({
      trainingProviderId: selectedTP.value,
      tenantIds: [tenantId],
    });

    /*update modal properties based on response*/
    let updatedModal = {};
    if (response !== null) {
      if (response.isSuccess) {
        updatedModal = {
          loading: false,
          unassignmentSuccess: true,
          title: 'Done',
          message: 'Unassignment completed successfully!',
        };
      } else {
        updatedModal = {
          loading: false,
          unassignmentError: true,
          title: 'Opps',
          message: 'Error performing unassignment!',
        };
      }
    }
    setModal({ ...modal, ...updatedModal });
  };

  const resetModal = () => {
    setModal(initialValues);
    setSelectedTP({ value: -1, label: 'Select Training Provider' });
  };

  return (
    <Modal
      isOpen={show}
      toggle={modal.hide}
      keyboard={false}
      onClosed={resetModal}
      backdrop={'static'}
      modalClassName={`${rtl.direction}-support`}
      className="modal-dialog--primary modal-dialog"
    >
      <div className="modal__header">
        <button
          className="lnr lnr-cross modal__close-btn"
          aria-label="modal__close-btn"
          type="button"
          onClick={modal.hide}
        />
        {!(modal.loading || modal.unassignmentSuccess || modal.unassignmentError) && (
          <span className="lnr lnr-unlink modal__title-icon" />
        )}
        {modal.unassignmentSuccess && (
          <span className="lnr lnr-thumbs-up modal__title-icon success" />
        )}
        {modal.unassignmentError && (
          <span className="lnr lnr-cross-circle modal__title-icon error" />
        )}
        <h4 className="text-modal  modal__title">{modal.title}</h4>
        <div className="modal__body">
          {modal.loading && (
            <div className="panel__refresh position-relative" style={{ height: 75 }}>
              <LoadingIcon />
            </div>
          )}
          {modal.message}
          {!(modal.unassignmentSuccess || modal.unassignmentError || modal.loading) && (
            <Select
              name="selectTrainingProvider"
              value={selectedTP}
              onChange={setSelectedTP}
              autosize={true}
              options={trainingProviders.map((tp) => ({
                value: tp.id,
                label: tp.name,
              }))}
              clearable={false}
              className="react-select mt-3 mb-3"
              placeholder={'Select Training Provider'}
              classNamePrefix="react-select"
            />
          )}
        </div>
        <ButtonToolbar className="modal__footer">
          {modal.unassignmentError && (
            <Button className="modal_cancel" onClick={onHide} outline>
              Close
            </Button>
          )}
          {modal.unassignmentSuccess && (
            <>
              <Button className="modal_cancel" onClick={onHide} outline>
                Okay
              </Button>
            </>
          )}
          {!(modal.unassignmentSuccess || modal.unassignmentError || modal.loading) && (
            <>
              <Button className="modal_cancel" onClick={onHide}>
                Cancel
              </Button>
              <Button className="modal_ok" outline color="danger" onClick={performUnassignment}>
                Yes, Unassign
              </Button>
            </>
          )}
        </ButtonToolbar>
      </div>
    </Modal>
  );
};

const OrganizationAssignedTPTable = () => {
  const [filters, setFilters] = React.useState({
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    searchText: '',
    isSortOrderDescending: false,
  });
  const { pageSize: rowsPerPage, pageNumber: page, isSortOrderDescending, sortBy } = filters;
  const [tpOrganizationData, setTPOrganizationData] = React.useState({
    totalRecords: 0,
    loading: false,
    error: '',
    records: [],
  });
  const { totalRecords, loading, error, records } = tpOrganizationData;
  const colLength = columns.length;
  const emptyRows =
    page === Math.ceil(totalRecords / rowsPerPage) ? rowsPerPage - records.length : 0;
  const [unAssignDialog, setUnAssignDialog] = React.useState({
    isOpen: false,
    tenantId: null,
    trainingProviders: [],
  });

  const [isSearched, setIsSearched] = useState(false);
  const searchRef = useRef(null);

  const handleSearch = () => {
    setIsSearched(true);
    setFilters({ ...filters, searchText: searchRef.current.value });
  };

  const handleClear = () => {
    if (searchRef?.current?.value) searchRef.current.value = '';
    setIsSearched(false);
    setFilters({ ...filters, searchText: '' });
  };

  React.useEffect(() => {
    if (unAssignDialog.isOpen === false) getTPData();
  }, [unAssignDialog.isOpen, filters]);

  const getTPData = async () => {
    const defaultError = 'Error occurred getting organization assigned training provider list';
    try {
      setTPOrganizationData({ ...tpOrganizationData, loading: true });
      const { data: res, isSuccess } = await api.trainingProviders.getAssignedOrganizationTP({
        ...filters,
      });
      if (isSuccess) {
        setTPOrganizationData({
          ...tpOrganizationData,
          loading: false,
          error: '',
          records: res?.data.records,
          totalRecords: res?.data?.totalRecords,
        });
      } else throw new Error(res?.messages?.[0] ?? defaultError);
    } catch (error) {
      setTPOrganizationData({
        ...tpOrganizationData,
        loading: false,
        error: error.msg,
        totalRecords: 0,
        records: [],
      });
      console.error(defaultError, error.message);
    }
  };

  const handleRequestSort = (e, sortBy) => {
    setFilters({
      ...filters,
      sortBy,
      isSortOrderDescending: !filters.isSortOrderDescending,
    });
  };

  const handleChangePage = (event, currentPage) => {
    setFilters({ ...filters, pageNumber: currentPage });
  };

  const handleChangeRowsPerPage = (e) => {
    setFilters({ ...filters, pageNumber: 1, pageSize: e.target.value });
  };

  const renderTableStatus = () =>
    (loading || !!error || totalRecords === 0) && (
      <TableRow>
        <TableCell colSpan={colLength}>
          {loading && (
            <div className={'text-center overflow-hidden'}>
              <div className="panel__refresh position-relative" style={{ height: 75 }}>
                <LoadingIcon />
              </div>
            </div>
          )}
          {totalRecords === 0 && !error && !loading && (
            <div className={'text-center'}>
              <p className={'m-5'}>No records found with applied filter.</p>
            </div>
          )}
          {(!!error || loading) && (
            <div className={'text-center'}>
              <p className={!!error ? 'm-5 error' : ''}>{error || 'Loading...'}</p>
            </div>
          )}
        </TableCell>
      </TableRow>
    );

  return (
    <>
      <div className="table__search">
        <div className="table__search_item">
          <b>Organization Name</b>
          <div className="form">
            <div className="form__form-group">
              <div className="form__form-group-field">
                <div className="form__form-group-icon" style={{ height: 37 }}>
                  <MagnifyIcon />
                </div>
                <input
                  ref={searchRef}
                  className="search-bar-search-text"
                  name="searchText"
                  type="text"
                  placeholder="Search by Organization Name"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table__search_item mt-3">
          <Button color="primary" onClick={handleSearch}>
            {' '}
            Search{' '}
          </Button>
        </div>
        <div className="table__search_item mt-3">
          <Button color="secondary" onClick={handleClear} outline>
            Clear
          </Button>
        </div>
      </div>
      <div className="material-table__wrap">
        <Table className="material-table">
          <TableBody>
            <AssignedTPTableColumns
              order={isSortOrderDescending ? 'desc' : 'asc'}
              orderBy={sortBy}
              onRequestSort={handleRequestSort}
              rowCount={rowsPerPage}
            />

            {renderTableStatus()}
            {records.map((data) => (
              <TableRow key={data.tenantId}>
                <TableCell align="center" className="material-table__cell">
                  <img className="program_logo_img" src={data?.organizationPicUrl} alt="Picture" />
                </TableCell>
                <TableCell align="center" className="material-table__cell">
                  {data?.organizationName}{' '}
                </TableCell>
                <TableCell align="center" className="material-table__cell">
                  {data?.organizationEmail}{' '}
                </TableCell>
                <TableCell align="center" className="material-table__cell">
                  {data?.trainingProviderList?.map((m) => m.name).join(', ')}
                </TableCell>
                <TableCell align="center" className="material-table__cell">
                  <Button
                    className="icon mb-0"
                    color="primary"
                    outline
                    onClick={() =>
                      setUnAssignDialog({
                        isOpen: true,
                        tenantId: data.tenantId,
                        trainingProviders: data?.trainingProviderList,
                      })
                    }
                  >
                    <p>
                      <PencilIcon /> UnAssign
                    </p>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            <UnassignDialog
              onHide={() => setUnAssignDialog({ ...unAssignDialog, isOpen: false })}
              show={unAssignDialog.isOpen}
              tenantId={unAssignDialog.tenantId}
              trainingProviders={unAssignDialog.trainingProviders}
            />
            {/*Rendering empty rows for better UI*/}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={colLength} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        count={totalRecords}
        page={page - 1}
        rowsPerPage={rowsPerPage}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        component={defaultPaginationProps.component}
        backIconButtonProps={defaultPaginationProps.backIconButton}
        nextIconButtonProps={defaultPaginationProps.nextIconButton}
        rowsPerPageOptions={defaultPaginationProps.rowsPerPage}
        className={defaultPaginationProps.className}
        dir={defaultPaginationProps.dir}
      />
    </>
  );
};

export default OrganizationAssignedTPTable;
