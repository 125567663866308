import { Col, Row } from 'reactstrap';
import Select from 'react-select';
import React from 'react';
import moment from 'moment';

const allMonths = moment.months().map((item, index) => ({ value: index + 1, label: item }));
const currentYear = new Date().getFullYear();
// last 3 years
const weeks = [
  {
    value: '',
    label: 'All Weeks',
  },
  {
    value: 1,
    label: 'Week 1',
  },
  {
    value: 2,
    label: 'Week 2',
  },
  {
    value: 3,
    label: 'Week 3',
  },
  {
    value: 4,
    label: 'Week 4',
  },
];
const years = [currentYear, currentYear - 1, currentYear - 2];

const QAFilters = ({ filter, setFilter, isDatesHidden, isOtherFiltersHidden }) => {
  return (
    <Row>
      <Col md={12}>
        {isOtherFiltersHidden || (
          <>
            <div className="table__search_item float-right pb-4">
              <span>Year</span>
              <div style={{ width: '200px' }}>
                <Select
                  name="date"
                  value={filter.selectedYear}
                  onChange={(selectedYear) => setFilter({ ...filter, selectedYear })}
                  options={years.map((year) => ({ value: year, label: year }))}
                  className="react-select"
                  placeholder={'Select Year'}
                  classNamePrefix="react-select"
                />
              </div>
            </div>
            <div className="table__search_item float-right pb-4">
              <span>Month</span>
              <div style={{ width: '200px' }}>
                <Select
                  name="date"
                  value={filter.selectedMonth}
                  onChange={(selectedMonth) => setFilter({ ...filter, selectedMonth })}
                  options={allMonths}
                  className="react-select"
                  placeholder={'Select Month'}
                  classNamePrefix="react-select"
                />
              </div>
            </div>
            <div className="table__search_item float-right pb-4">
              <span>Week</span>
              <div style={{ width: '200px' }}>
                <Select
                  name="weeks"
                  value={filter.selectedWeek}
                  onChange={(selectedWeek) => setFilter({ ...filter, selectedWeek })}
                  options={weeks}
                  className="react-select"
                  placeholder={'Select Weeks'}
                  classNamePrefix="react-select"
                />
              </div>
            </div>
          </>
        )}
        {isDatesHidden || (
          <>
            <div className="table__search_item float-right pb-4" style={{ width: 200 }}>
              <b>End Date</b>
              <div className="form">
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <input
                      type="date"
                      className="search-bar-search-text"
                      name="to_date"
                      placeholder="To date"
                      min={filter.selectedStartDate}
                      onChange={(e) => setFilter(e.target.value)}
                      value={filter.selectedEndDate}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="table__search_item float-right pb-4" style={{ width: 200 }}>
              <b>Start Date</b>
              <div className="form">
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <input
                      type="date"
                      className="search-bar-search-text"
                      name="from_date"
                      placeholder="From date"
                      onChange={(e) => setFilter(e.target.value)}
                      value={filter.selectedStartDate}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Col>
    </Row>
  );
};

export default QAFilters;
