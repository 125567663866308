import { paths } from '@/shared/helpers/paths';
import React, { Suspense } from 'react';
import Loading from '@/shared/components/Loading';
import { Redirect, Route, Switch } from 'react-router-dom';
import Jobs from './Job';

export default function ManageOpportunities() {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        {
          <Redirect
            exact
            from={paths.CADS_AI.OPPORTUNITIES.BASE}
            to={paths.CADS_AI.OPPORTUNITIES.JOBS}
          />
        }
        <Route path={paths.CADS_AI.OPPORTUNITIES.JOBS} component={Jobs} />
      </Switch>
    </Suspense>
  );
}
