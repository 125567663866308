import React, { createContext, useContext } from 'react';

const SkillUIContext = createContext();

export function useSkillUIContext() {
  return useContext(SkillUIContext);
}

export function SkillUIProvider({ skillUIEvents, children }) {
  const value = {
    openViewSkillDialog: skillUIEvents.openViewSkillDialog,
    openNewSkillDialog: skillUIEvents.openNewSkillDialog,
    openEditSkillDialog: skillUIEvents.openEditSkillDialog,
    openDeleteSkillDialog: skillUIEvents.openDeleteSkillDialog,
  };

  return <SkillUIContext.Provider value={value}>{children}</SkillUIContext.Provider>;
}
