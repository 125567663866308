import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loading from '@/shared/components/Loading';
import { paths } from '@/shared/helpers/paths';
import { HelpPage } from './components/HelpPage';

export const cadsAiHelpBaseUrl = paths.CADS_AI.HELP;

const Helps = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path={cadsAiHelpBaseUrl} component={HelpPage} />
      </Switch>
    </Suspense>
  );
};

export default Helps;
