import React from 'react';
import CreatableSelect from 'react-select/creatable';
import PropTypes from 'prop-types';
import { renderComponentField } from '@/shared/components/form/FormField';

export const CreatableSelectField = React.forwardRef(
  ({ onChange, value, name, placeholder, options, isMulti }, ref) => {
    const handleChange = (selectedOption) => {
      onChange(selectedOption);
    };

    return (
      <CreatableSelect
        isMulti={isMulti}
        name={name}
        value={value}
        onChange={handleChange}
        options={options}
        clearable={false}
        className="react-select"
        placeholder={placeholder}
        classNamePrefix="react-select"
        ref={ref}
      />
    );
  },
);

CreatableSelectField.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ]).isRequired,
};

CreatableSelectField.defaultProps = {
  placeholder: '',
  options: [],
  isMulti: false,
};

export default renderComponentField(CreatableSelectField);
