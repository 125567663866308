import React, { Fragment, useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import { Badge, FormGroup, Input } from 'reactstrap';
import { useApplicantUIContext } from '../ApplicantsUIProvider';
import ApplicantTableColumns, { ApplicantColumns } from './ApplicantTableColumns';
import { ApplicantsTableSearchBar } from './ApplicantsTableSearchBar';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getApplicantList, setApplicantFilters, toggleCheckedItemById } from '../../redux/actions';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { defaultPaginationProps } from '@/shared/helpers';
import moment from 'moment';

const ApplicantTable = () => {
  const applicantUIContext = useApplicantUIContext();
  const dispatch = useDispatch();
  const {
    data: applicants,
    error,
    filters,
    loading,
    totalRecords,
    checkedApplicantIds,
  } = useSelector((state) => state.cadsAIApplicants, shallowEqual);
  const {
    pageSize: rowsPerPage,
    pageNumber: page,
    sortBy: orderBy,
    isSortOrderDescending,
  } = filters;

  const [columns, setColumns] = useState(0);

  useEffect(() => {
    setColumns(ApplicantColumns.length);
  }, [ApplicantColumns.length]);

  React.useEffect(() => {
    dispatch(getApplicantList(filters));
  }, [filters]);

  const handleRequestSort = (event, property) => {
    dispatch(
      setApplicantFilters({
        sortBy: property,
        isSortOrderDescending: !isSortOrderDescending,
      }),
    );
  };

  const handleChangePage = (event, currentPage) => {
    dispatch(setApplicantFilters({ pageSize: rowsPerPage, pageNumber: currentPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setApplicantFilters({ pageSize: +event.target.value, pageNumber: 1 }));
  };

  const emptyRows =
    page === Math.ceil(totalRecords / rowsPerPage) ? rowsPerPage - applicants.length : 0;

  return (
    <Fragment>
      <div className="material-table__wrap">
        <ApplicantsTableSearchBar />
        <Table className="material-table">
          <ApplicantTableColumns
            order={isSortOrderDescending ? 'desc' : 'asc'}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rowsPerPage}
          />
          <TableBody>
            {(loading || !!error) && (
              <TableRow>
                <TableCell colSpan={columns}>
                  {loading && (
                    <div className={'text-center overflow-hidden'}>
                      <div className="panel__refresh position-relative" style={{ height: 75 }}>
                        <LoadingIcon />
                      </div>
                    </div>
                  )}
                  <div className={'text-center'}>
                    <p className={!!error ? 'm-5' : ''}>{error || 'Loading applicants...'}</p>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {!!!applicants.length && !loading && !error && (
              <TableRow style={{ height: 120 }}>
                <TableCell colSpan={columns} align="center">
                  {filters.status === null && filters.search === ''
                    ? 'No Applicants'
                    : 'No Applicants with applied filters'}
                </TableCell>
              </TableRow>
            )}
            {applicants.map((applicant) => {
              return (
                <TableRow className="material-table" tabIndex={-1} key={applicant?.id}>
                  <TableCell align="center" className="material-table__cell">
                    <FormGroup>
                      <Input
                        type="checkbox"
                        value={applicant?.id}
                        defaultChecked={checkedApplicantIds.includes(applicant?.id)}
                        onChange={(e) => dispatch(toggleCheckedItemById(+e.target.value))}
                      />
                    </FormGroup>
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    <img
                      className="applicant_logo_img"
                      src={
                        applicant?.userLogoUrl?.startsWith('https')
                          ? applicant?.userLogoUrl
                          : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__480.png'
                      }
                      alt="applicant logo"
                    />
                  </TableCell>
                  <TableCell
                    className="material-table__cell"
                    component="th"
                    role="button"
                    align="left"
                    scope="row"
                    onClick={() => applicantUIContext.openViewApplicantDialog(applicant?.id)}
                  >
                    {applicant?.userName ?? '-'}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {applicant?.userEmail ?? '-'}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {applicant?.isBhumiputra ? 'Yes' : 'No'}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {applicant?.university ?? '-'}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {applicant?.enrollmentDate
                      ? moment(applicant?.enrollmentDate).format('DD/MM/yyyy')
                      : '-'}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {applicant?.program ?? '-'}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    <Badge color={'warning'} style={{ marginRight: 24 }}>
                      {applicant?.applicantFinalStage?.title ?? 'NA'}
                    </Badge>
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    <Badge
                      color={`${applicant?.status ? 'primary' : 'secondary'}`}
                      style={{ marginRight: 24 }}
                    >
                      {applicant?.applicantFinalStatus?.title ?? 'NA'}
                    </Badge>
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={columns} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        component={defaultPaginationProps.component}
        backIconButtonProps={defaultPaginationProps.backIconButton}
        nextIconButtonProps={defaultPaginationProps.nextIconButton}
        rowsPerPageOptions={defaultPaginationProps.rowsPerPage}
        className={defaultPaginationProps.className}
        dir={defaultPaginationProps.dir}
      />
    </Fragment>
  );
};

export default ApplicantTable;
