import React from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import UsersTable from './UsersTable';

const UsersTableWrapper = () => {
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="products-list">
          <div className="card__title">
            <h5 className="bold-text">Users List</h5>
            <ButtonToolbar className="products-list__btn-toolbar-top">
              <Button color="success" outline>
                Export as CSV
              </Button>
            </ButtonToolbar>
          </div>
          <UsersTable />
        </CardBody>
      </Card>
    </Col>
  );
};

export default UsersTableWrapper;
