import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const fetchQuestionList = createAction('FETCH_QUESTION_LIST');
export const setQuestionList = createAction('SET_QUESTION_LIST');
export const setQuestionError = createAction('SET_QUESTION_ERROR');
export const setQuestionFilters = createAction('SET_QUESTION_FILTERS');
export const deleteQuestionFromList = createAction('DELETE_QUESTION_FROM_LIST');

export const setQuestionLevelList = createAction('SET_QUESTION_LEVEL_LIST');
export const setQuestionTypeList = createAction('SET_QUESTION_TYPE_LIST');
export const setQuestionTagList = createAction('SET_QUESTION_TAG_LIST');

export const getQuestionsList = (filter) => async (dispatch) => {
  const defaultError = 'Error occurred getting questions list';
  try {
    dispatch(fetchQuestionList());
    const { data, isSuccess } = await api.questions.getQuestionList(filter);
    if (isSuccess) dispatch(setQuestionList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setQuestionError(error));
    console.error(defaultError, error.message);
  }
};

export const getQuestionLevelsList = () => async (dispatch) => {
  const defaultError = 'Error occurred getting question levels list';
  try {
    const { data, isSuccess } = await api.questions.getQuestionLevelList();
    if (isSuccess) dispatch(setQuestionLevelList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    console.error(defaultError, error.message);
  }
};
export const getQuestionTypesList = () => async (dispatch) => {
  const defaultError = 'Error occurred getting question types list';
  try {
    const { data, isSuccess } = await api.questions.getQuestionTypeList();
    if (isSuccess) dispatch(setQuestionTypeList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    console.error(defaultError, error.message);
  }
};

export const getQuestionTagsList = () => async (dispatch) => {
  const defaultError = 'Error occurred getting question tags list';
  try {
    const { data, isSuccess } = await api.questions.getQuestionTagList();
    if (isSuccess) dispatch(setQuestionTagList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    console.error(defaultError, error.message);
  }
};

export const deleteQuestion = (questionId) => (dispatch) => {
  try {
    dispatch(deleteQuestionFromList(questionId));
  } catch (error) {
    console.error('Unable to delete customer from customer list');
  }
};
