import React from 'react';
import { Col } from 'reactstrap';
import { Field } from '@/shared/components/Field';
import CompanyIcon from 'mdi-react/CompanyIcon';
import AccountCircleIcon from 'mdi-react/AccountCircleIcon';
import SpiritLevelIcon from 'mdi-react/SpiritLevelIcon';
import SortDescendingIcon from 'mdi-react/SortDescendingIcon';
import IndustrialIcon from 'mdi-react/IndustrialIcon';
import GraphIcon from 'mdi-react/GraphIcon';
import MapMarkerCheckIcon from 'mdi-react/MapMarkerCheckIcon';
import DateRangeIcon from 'mdi-react/DateRangeIcon';
import moment from 'moment';

const JobDetails = ({ jobDetails }) => {
  return (
    <>
      <Col md={4} xl={4} lg={4} className="orgprofile__avatar-no">
        <div className="orgprofile__avatar">
          <img
            style={{ width: '60%' }}
            src={
              jobDetails?.organizationLogo ??
              'https://images.squarespace-cdn.com/content/v1/60b6fd136dae8570d704e0d7/1622722725302-ORNFVRE6HCBLC0KRNL1D/CADS-logo-color-slogan.png?format=1500w'
            }
            alt="avatar"
          />
        </div>
      </Col>
      <Col md={4} xl={4} lg={4}>
        <Field
          IconComponent={<CompanyIcon size={'20'} />}
          title={'Organization Name'}
          fieldValue={jobDetails?.organizationName ?? '-'}
        />
        <Field
          IconComponent={<AccountCircleIcon size={'20'} />}
          title={'Title'}
          fieldValue={jobDetails?.title ?? '-'}
        />
        <Field
          IconComponent={<SpiritLevelIcon size={'20'} />}
          title={'Level'}
          fieldValue={jobDetails?.jobLevelName ?? '-'}
        />
        <Field
          IconComponent={<SortDescendingIcon size={'20'} />}
          title={'Description'}
          fieldValue={jobDetails?.description ?? '-'}
        />
      </Col>
      <Col md={4} xl={4} lg={4}>
        <Field
          IconComponent={<IndustrialIcon size={'20'} />}
          title={'Industry'}
          fieldValue={jobDetails?.industry ?? '-'}
        />
        <Field
          IconComponent={<GraphIcon size={'20'} />}
          title={'Salary Range'}
          fieldValue={`${jobDetails?.salaryBegin} - ${jobDetails?.salaryEnd}` ?? '-'}
        />
        {/*{RoleOptions.length > 0 && (*/}
        <Field
          IconComponent={<MapMarkerCheckIcon size={'20'} />}
          title={'Country'}
          fieldValue={jobDetails?.country ?? '-'}
        />
        {/*)}*/}
        <Field
          IconComponent={<DateRangeIcon size={'20'} />}
          title={'Applied Date'}
          fieldValue={
            jobDetails?.userJobAppliedDate
              ? moment(jobDetails?.userJobAppliedDate).format('DD/MM/yyyy')
              : '-'
          }
        />
      </Col>
    </>
  );
};

export default JobDetails;
