import React from 'react';
import Panel from '@/shared/components/Panel';
import AssessmentStats from './AssessmentStats';
import AssessmentStatsFilter from './AssessmentStatsFilter';
import { Row } from 'reactstrap';
import AssessmentUserStats from './AssessmentUserStats';

const AssessmentSection = () => {
  return (
    <Row className="mt-3">
      <Panel title={'Assessment'}>
        <Row className="flex-row-reverse">
          <AssessmentStatsFilter />
        </Row>
        <Row>
          <AssessmentStats />
          <AssessmentUserStats />
        </Row>
      </Panel>
    </Row>
  );
};

export default AssessmentSection;
