import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loading from '@/shared/components/Loading';
import { paths } from '@/shared/helpers/paths';
import { TrainingProvidersPage } from './components/TrainingProvidersPage';

export const cadsAiTPBaseUrl = paths.CADS_AI.LEARNING.TRAINING_PROVIDERS;

const TrainingProviders = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path={cadsAiTPBaseUrl} component={TrainingProvidersPage} />
      </Switch>
    </Suspense>
  );
};

export default TrainingProviders;
