import React from 'react';
import { Row } from 'reactstrap';
import StatsCard from './StatsCard';
import api from '@/utils/apiClient';
import UserIcon from 'mdi-react/UserIcon';
import DomainIcon from 'mdi-react/DomainIcon';
import ProgressWrenchIcon from 'mdi-react/ProgressWrenchIcon';
import AssessmentIcon from 'mdi-react/AssessmentIcon';
import BriefcaseIcon from 'mdi-react/BriefcaseIcon';
import StarsIcon from 'mdi-react/StarsIcon';
import StatsFilter from './StatsFilter';
import './index.scss';

const StatsSection = () => {
  const [filter, setFilter] = React.useState({
    selectedMonth: null,
    selectedYear: null,
  });

  const [userStats, setUsersStats] = React.useState({
    title: 'Users',
    stats: '-',
    Icon: UserIcon,
    colorFill: '#ff4861',
  });
  const [orgStats, setOrgStats] = React.useState({
    title: 'Organizations',
    stats: '-',
    Icon: DomainIcon,
    colorFill: '#FFA500',
  });
  const [skillStats, setSkillStats] = React.useState({
    title: 'Skills',
    stats: '-',
    Icon: ProgressWrenchIcon,
    colorFill: '#4ce1b6',
  });
  const [assessmentStats, setAssessmentStats] = React.useState({
    title: 'Assessment',
    stats: '-',
    Icon: AssessmentIcon,
    colorFill: '#70bbfd',
  });
  const [jobStats, setJobStats] = React.useState({
    title: 'Jobs',
    stats: '-',
    Icon: BriefcaseIcon,
    colorFill: '#FFA500',
  });
  const [programStats, setProgramStats] = React.useState({
    title: 'Programs',
    stats: '-',
    Icon: StarsIcon,
    colorFill: '#ff4861',
  });

  React.useEffect(() => {
    getUserStats();
    getOrgStats();
    getSkillStats();
    getAssessmentStats();
    getJobStats();
    getProgramStats();
  }, [filter]);

  const getUserStats = async () => {
    try {
      const { data: res, isSuccess } = await api.cadsAIDashobard.getUserStats(
        filter.selectedYear?.value,
        filter.selectedMonth?.value,
      );
      if (isSuccess) {
        const registeredUsers = res?.data?.registered ?? 0;
        setUsersStats({ ...userStats, stats: registeredUsers });
      } else {
        throw new Error('error while fetching user dashboard status');
      }
    } catch (error) {
      console.error('Error inside dashboard user stats', error.message);
    }
  };

  const getOrgStats = async () => {
    try {
      const { data: res, isSuccess } = await api.cadsAIDashobard.getOrgStats(
        filter.selectedYear?.value,
        filter.selectedMonth?.value,
      );
      if (isSuccess) {
        setOrgStats({ ...orgStats, stats: res?.data?.organizations ?? 0 });
      } else {
        throw new Error('Error while fetching organization dashboard stats');
      }
    } catch (error) {
      console.error('Error inside dashboard organization stats', error.message);
    }
  };

  const getSkillStats = async () => {
    try {
      const { data: res, isSuccess } = await api.cadsAIDashobard.getSkillStats(
        filter.selectedYear?.value,
        filter.selectedMonth?.value,
      );
      if (isSuccess) {
        setSkillStats({ ...skillStats, stats: res?.data?.skills ?? 0 });
      } else {
        throw new Error('Error while fetching organization dashboard skills count');
      }
    } catch (error) {
      console.error('Error inside dashboard skills stats', error.message);
    }
  };

  const getJobStats = async () => {
    try {
      const { data: res, isSuccess } = await api.cadsAIDashobard.getJobStats(
        filter.selectedYear?.value,
        filter.selectedMonth?.value,
      );
      if (isSuccess) {
        const posted = res?.data?.posted ?? 0;
        setJobStats({ ...jobStats, stats: posted });
      } else {
        throw new Error('error while fetching job dashboard status');
      }
    } catch (error) {
      console.error('Error inside dashboard job stats', error.message);
    }
  };

  const getProgramStats = async () => {
    try {
      const { data: res, isSuccess } = await api.cadsAIDashobard.getProgramStats(
        filter.selectedYear?.value,
        filter.selectedMonth?.value,
      );
      if (isSuccess) {
        const posted = res?.data?.posted ?? 0;
        setProgramStats({ ...programStats, stats: posted });
      } else {
        throw new Error('error while fetching program dashboard status');
      }
    } catch (error) {
      console.error('Error inside dashboard program stats', error.message);
    }
  };

  const getAssessmentStats = async () => {
    try {
      const { data: res, isSuccess } = await api.cadsAIDashobard.getAssessmentStats(
        filter.selectedYear?.value,
        filter.selectedMonth?.value,
      );
      if (isSuccess) {
        const accomplished = res?.data?.accomplished ?? 0;
        setAssessmentStats({ ...assessmentStats, stats: accomplished });
      } else {
        throw new Error('error while fetching assessment dashboard status');
      }
    } catch (error) {
      console.error('Error inside dashboard assessment stats', error.message);
    }
  };

  return (
    <>
      <StatsFilter filter={filter} setFilter={setFilter} />
      <Row>
        <StatsCard {...userStats} />
        <StatsCard {...orgStats} />
        <StatsCard {...skillStats} />
        <StatsCard {...assessmentStats} />
        <StatsCard {...jobStats} />
        <StatsCard {...programStats} />
      </Row>
    </>
  );
};

export default StatsSection;
