import React, { useState } from 'react';
import LoadingIcon from 'mdi-react/LoadingIcon';
import api from '@/utils/apiClient';
import TextEditor from '@/shared/components/text-editor/TextEditor';
import { Button, ButtonToolbar } from 'reactstrap';
import { getSanitizeHTML } from '@/shared/components/SanitizeHTML';
import { emptyStringPattern } from '@/shared/helpers';
import FormField from '@/shared/components/form/hook/FormField';
import { CreatableSelectField } from '@/shared/components/form/CreatableSelectField';
import { useForm } from 'react-hook-form';

const EmailEditDialogBody = ({ modal, isEdit, id }) => {
  const initialValues = {
    body: '',
  };

  const [data, setData] = useState(initialValues);

  const [state, setState] = useState({
    loading: false,
    fetchingEmail: false,
    newEmailAdded: false,
    errorMessage: '',
  });

  const constructApiRequestData = async ({ body, to, subject }) => {
    return { body, to, subject };
  };
  const handleAddEmail = async (emailData) => {
    setState({ ...state, loading: true });
    const newEmailData = await constructApiRequestData(emailData);
    const defaultError = `Error occurred while ${isEdit ? 'Updating' : 'Adding new'} email`;
    try {
      const { data: res, isSuccess } = await api.cadsAIEmail.addEmail(newEmailData);
      if (isSuccess) {
        setState({ ...state, newEmailAdded: true, loading: false });
        if (!isEdit) setData(initialValues);
      } else {
        throw new Error(data?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({
        ...state,
        loading: false,
        newEmailAdded: false,
        errorMessage: error.message,
      });
    }
  };

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  return (
    <div>
      {/*Add/Edit Email Form Status*/}
      <div className="ml-4">
        {state.loading && (
          <div>
            <div className="panel__refresh position-relative" style={{ height: 75, width: 20 }}>
              <LoadingIcon />
              <div style={{ width: 200, padding: '23px 30px' }}>
                {isEdit ? 'Updating' : 'Adding new'} email...
              </div>
            </div>
          </div>
        )}
        {(state.newEmailAdded || !!state.errorMessage) && (
          <>
            <table className="mt-3">
              <tr>
                <td>
                  {state.newEmailAdded ? (
                    <span className="lnr lnr-thumbs-up modal__title-icon success" />
                  ) : (
                    <span className="lnr lnr-cross-circle modal__title-icon error" />
                  )}
                </td>
                <td className="pl-2">
                  {state.newEmailAdded
                    ? `${isEdit ? 'Email Updated' : 'New Email Added'} Successfully`
                    : state.errorMessage}
                </td>
              </tr>
            </table>
          </>
        )}
      </div>
      <div className="modal__body add-edit-learning-path dropzone-border">
        <form className={`form form--horizontal`} onSubmit={handleSubmit(handleAddEmail)}>
          {!state.newEmailAdded ? (
            <div className={'w-100 p-3'}>
              <div className="form__form-group">
                <span className="form__form-group-label">To</span>
                <div className="form__form-group-field">
                  <FormField
                    name="to"
                    control={control}
                    isMulti
                    component={CreatableSelectField}
                    errors={errors}
                    rules={{
                      required: 'This is required field',
                      pattern: emptyStringPattern,
                    }}
                    defaultValue=""
                    isAboveError={true}
                    placeholder="Enter Title"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Subject</span>
                <div className="form__form-group-field">
                  <FormField
                    name="subject"
                    control={control}
                    component="input"
                    errors={errors}
                    rules={{
                      required: 'This is required field',
                      pattern: emptyStringPattern,
                    }}
                    defaultValue=""
                    isAboveError={true}
                    placeholder="Enter Subject"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Body</span>
                <div className="form__form-group-field">
                  {/*TODO: set html after sanitizing for all text editor*/}
                  {(!isEdit || data.body) && (
                    <FormField
                      control={control}
                      name="body"
                      component={() => (
                        <TextEditor
                          defaultContent={getSanitizeHTML(data.body)}
                          onChange={(value) => setValue('body', value)}
                        />
                      )}
                    />
                  )}
                </div>
              </div>
              <ButtonToolbar className="modal__footer btn-toolbar pr-3">
                <>
                  <Button color="success" type="submit" onClick={() => handleAddEmail(data)}>
                    Save as Draft
                  </Button>
                  <Button color="primary" type="submit" onClick={() => handleAddEmail(data)}>
                    Sent
                  </Button>
                  <Button
                    color="secondary"
                    type="button"
                    className="modal_cancel btn btn-secondary"
                    onClick={() => modal.hide()}
                  >
                    Close
                  </Button>
                </>
              </ButtonToolbar>
            </div>
          ) : (
            <div className={'p-3'}>
              <ButtonToolbar className="modal__footer btn-toolbar">
                <Button color="primary" type="button" onClick={() => modal.hide()}>
                  Okay
                </Button>
              </ButtonToolbar>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default EmailEditDialogBody;
