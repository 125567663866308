import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Loading from '@/shared/components/Loading';
import { paths } from '@/shared/helpers/paths';
import UsersPage from './components/UsersPage';

export const campaignUsersBaseUrl = paths.CAMPAIGN.USERS + '/users-page';

const Users = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        {<Redirect exact from={paths.CAMPAIGN.USERS} to={campaignUsersBaseUrl} />}
        <Route path={campaignUsersBaseUrl} component={UsersPage} />
      </Switch>
    </Suspense>
  );
};

export default Users;
