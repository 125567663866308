import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loading from '@/shared/components/Loading';
import { paths } from '@/shared/helpers/paths';
import { EventsPage } from './components/EventsPage';
import './index.scss';

export const cadsAiEventsBaseUrl = paths.CADS_AI.HOME.EVENTS;

const Events = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path={cadsAiEventsBaseUrl} component={EventsPage} />
      </Switch>
    </Suspense>
  );
};

export default Events;
