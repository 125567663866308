import { useEffect } from 'react';
import Highcharts from 'highcharts';
import Tree from 'highcharts/modules/treemap';
import exporting from 'highcharts/modules/exporting';
import data from 'highcharts/modules/data';
import Heatmap from 'highcharts/modules/heatmap';

Tree(Highcharts);
data(Highcharts);
Heatmap(Highcharts);
exporting(Highcharts);

const QAAgeGroup = () => {
  const initialChart = () => {
    Highcharts.chart('QAAgeGroup', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
      },

      title: {
        text: 'QA users by age group',
      },

      tooltip: {
        pointFormat: '<b>{point.percentage:.1f}%</b>',
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      legend: {
        enabled: true,
      },
      credits: {
        enabled: false,
      },
      colors: ['#5e1a12', '#8d363f', '#b75a7d'],
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false,
            distance: -80,
          },
          startAngle: 180,
          endAngle: 90,
          center: ['50%', '60%'],
          size: '60%',
        },
      },
      series: [
        {
          type: 'pie',
          name: 'Age group',
          innerSize: '50%',
          data: [
            ['18-24', 45],
            {
              name: '25-34',
              y: 32,
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: 'Other',
              y: 23,
              dataLabels: {
                enabled: false,
              },
            },
          ],
        },
      ],
    });
  };

  useEffect(() => {
    initialChart();
  }, []);

  return <div id="QAAgeGroup" className="col-lg-5" />;
};

export default QAAgeGroup;
