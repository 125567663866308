import { useEffect } from 'react';
import Highcharts from 'highcharts';
import Tree from 'highcharts/modules/treemap';
import exporting from 'highcharts/modules/exporting';
import data from 'highcharts/modules/data';
import Heatmap from 'highcharts/modules/heatmap';

Tree(Highcharts);
data(Highcharts);
Heatmap(Highcharts);
exporting(Highcharts);

const QuestionPosted = () => {
  const initialChart = () => {
    Highcharts.chart('QuestionPosted', {
      xAxis: {
        title: {
          text: 'Days',
        },
      },

      yAxis: {
        title: {
          text: 'Number of questions posted',
        },
      },
      title: {
        text: '',
      },

      credits: {
        enabled: false,
      },

      colors: ['#030303'],

      legend: {
        align: 'center',
        verticalAlign: 'bottom',
        x: 0,
        y: 0,
      },

      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
          pointStart: 1,
        },
      },

      series: [
        {
          name: 'Questions',
          data: [43, 52, 77, 69, 97, 60, 137, 154],
        },
      ],

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
              },
            },
          },
        ],
      },
    });
  };

  useEffect(() => {
    initialChart();
  }, []);

  return <div id="QuestionPosted" />;
};

export default QuestionPosted;
