import React from 'react';
import { cadsAiOrderHistoryBaseUrl } from '../index';
import { OrderHistoryUIProvider } from './OrderHistoryUIProvider';
import { OrderHistoryCard } from './OrderHistoryCard';

export function OrderHistoryPage({ history }) {

  const orderHistoryUIEvents = {
    openViewOrderHistoryDialog: (id) => {
      history.push(`${cadsAiOrderHistoryBaseUrl}/${id}/view`);
    },
    openOrderHistoryPage: () => {
      history.push(`${cadsAiOrderHistoryBaseUrl}`);
    },
  };

  return (
    <OrderHistoryUIProvider orderHistoryUIEvents={orderHistoryUIEvents}>
      <OrderHistoryCard />
    </OrderHistoryUIProvider>
  );
}
