import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { Field } from '@/shared/components/Field';
import Panel from '@/shared/components/Panel';
import moment from 'moment';

const AssessmentDetails = ({ assessmentDetail, toggle }) => {
  const assessmentTypes = {
    PRE: { id: 1, title: 'Pre Assessment', value: 'pre', list: [] },
    POST: { id: 2, title: 'Post Assessment', value: 'post', list: [] },
    SMART_SKILLS: { id: 3, title: 'Smart Skills', value: 'smartskills', list: [] },
  };

  const { PRE, POST, SMART_SKILLS } = assessmentTypes;

  const [assessments, setAssessments] = React.useState({ PRE, POST, SMART_SKILLS });

  const handleClick = () => {
    toggle('3');
    window.scrollTo(0, 0);
  };

  React.useEffect(() => {
    const assessments = { PRE, POST, SMART_SKILLS };
    assessmentDetail?.forEach((assessment) => {
      if (assessment.prePostStatus === PRE.value) {
        assessments['PRE'].list.push(assessment);
      }
      if (assessment.prePostStatus === POST.value) {
        assessments['POST'].list.push(assessment);
      }
      if (assessment.prePostStatus === SMART_SKILLS.value) {
        assessments['SMART_SKILLS'].list.push(assessment);
      }
    });
    setAssessments({ ...assessments });
  }, [assessmentDetail]);

  return (
    <div className="pt-5 applicant_panel_card">
      <h4 className="pl-2 mb-4">Assessment Details</h4>
      {Object.keys(assessments)?.map((key) =>
        assessments[key].list?.map((assessment) => (
          <Panel
            title={assessment.assessment.title}
            key={assessment.assessment.id}
            subhead={assessments[key]?.title}
          >
            <Row>
              <Col md={3} xl={3} lg={3}>
                <Field
                  title={'Assessment Name'}
                  iconDisable={true}
                  fieldValue={assessment?.assessment?.title ?? '-'}
                />
              </Col>
              <Col md={3} xl={3} lg={3}>
                <Field
                  title={'Assessment Status'}
                  iconDisable={true}
                  fieldValue={assessment?.assessmentStatus ? assessment?.assessmentStatus : '-'}
                />
              </Col>
              <Col md={3} xl={3} lg={3}>
                <Field
                  title={'Sort Order'}
                  iconDisable={true}
                  fieldValue={assessment?.sortOrder ? assessment?.sortOrder : '-'}
                />
              </Col>
              <Col md={3} xl={3} lg={3}>
                <Field
                  title={'Assessment sent date'}
                  iconDisable={true}
                  fieldValue={
                    assessment?.assessmentSentDate
                      ? moment(assessment?.assessmentSentDate).format('DD/MM/yyyy')
                      : '-'
                  }
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={3} xl={3} lg={3}>
                <Field
                  title={'Code'}
                  iconDisable={true}
                  fieldValue={assessment?.code ? assessment?.code : '-'}
                />
              </Col>
              <Col md={3} xl={3} lg={3}>
                <Field
                  iconDisable={true}
                  title={'Extra Description'}
                  fieldValue={assessment?.extraDescription ? assessment?.extraDescription : '-'}
                />
              </Col>
              <Col md={3} xl={3} lg={3}>
                <Field
                  title={'Is Assessment Sent'}
                  iconDisable={true}
                  fieldValue={assessment?.assessmentIsSent ? 'Yes' : 'No'}
                />
              </Col>
              <Col md={3} xl={3} lg={3}>
               {assessment.prePostStatus === 'smartskills' || <Field
                  title={'Score'}
                  iconDisable={true}
                  fieldValue={
                    assessment?.assessmentStatus === 'Completed'
                      ? `${Math.round(assessment?.score ?? 0)}%` ?? '0%'
                      : '-'
                  }
                />}
                {assessment.prePostStatus === 'smartskills' && (
                  <Button disabled={assessment?.assessmentStatus !== 'Completed'} color='primary' onClick={handleClick} className="upload-resume curve-gray-button mt-3">
                    <span>View Smart Skills</span>
                  </Button>
                )}
              </Col>
            </Row>
          </Panel>
        )),
      )}
    </div>
  );
};
export default AssessmentDetails;
