import { sendRequest } from '../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const CADS_API_ENDPOINT = process.env.REACT_APP_CADS_AI_API + process.env.REACT_APP_CADS_AI_CONTROL_PANEL_ENDPOINT
const customersEndpoints = {
  ADD_CUSTOMER: '/addCustomer',
  EDIT_CUSTOMER: '/editCustomer',
  GET_CUSTOMER: '/getCustomer',
  GET_CUSTOMER_LIST: '/getCustomerList',
  GET_CUSTOMER_LIST_CSV: '/getCustomerListCsv',

  GET_ORGANIZATION_ID_NAME: `${CADS_API_ENDPOINT}/GetOrganizationsIdName`,
};

/**
 * @param customerData Information required for adding customers.
 * @param {string} customerData.companyName
 * @param {string} customerData.registrationNumber
 * @param {string} customerData.address
 * @param {number} customerData.countryId
 * @param {number} customerData.noOfEmp
 * @param {string} customerData.email
 * @param {string} customerData.subdomain
 * @param {string} customerData.firstName
 * @param {string} customerData.lastName
 * @param {string} customerData.phoneNumberOffice
 * @param {string} customerData.phoneNumberMobile
 * @param {string} customerData.notes
 */
const addCustomer = (customerData) => {
  return sendRequest({
    url: customersEndpoints.ADD_CUSTOMER,
    method: httpMethod.POST,
    data: customerData,
  });
};

/**
 * @param customerData updated customers data.
 * @param {number} customerData.id existing customer id
 * @param {string} customerData.name
 * @param {string} customerData.description
 * @param {string} customerData.competencies
 * @param {number} customerData.organizationId
 */
const updateCustomer = (customerData) => {
  return sendRequest({
    url: customersEndpoints.EDIT_CUSTOMER,
    method: httpMethod.PUT,
    data: customerData,
  });
};

/**
 * @param {number} customerId id of customer to fetch more details about the customer
 */
const getCustomer = (customerId) => {
  return sendRequest({
    url: customersEndpoints.GET_CUSTOMER,
    method: httpMethod.GET,
    params: { request: customerId },
  });
};
/**
 * Fetched option list of customers containing id and title to display in the dropdowns
 */
const getCustomerOptionsList = () => {
  return sendRequest({
    fullUrl: customersEndpoints.GET_ORGANIZATION_ID_NAME,
    method: httpMethod.GET,
  });
};

/**
 * @param filter to filter data based on some parameters
 * @param {number} filter.profile profile id to filter
 * @param {Array<number>} filter.domains array of domain id to filter
 * @param {string} filter.search to filter customers by search text
 * @param {string} filter.sortBy to sort based on any field
 * @param {boolean} filter.isSortOrderDescending true if search direction is descending
 **/
const getCustomerList = (filter) => {
  return sendRequest({
    url: customersEndpoints.GET_CUSTOMER_LIST,
    method: httpMethod.GET,
    params: { ...filter, searchText: filter?.search },
  });
};

/**
 * @param filter to filter data based on some parameters
 * @param {string} filter.search to filter customers by search text
 * @param {string} filter.sortBy to sort based on any field
 * @param {boolean} filter.isSortOrderDescending true if search direction is descending
 **/
const getCustomerListCSV = (filter) => {
  return sendRequest({
    url: customersEndpoints.GET_CUSTOMER_LIST_CSV,
    method: httpMethod.GET,
    params: filter,
  });
};

export default {
  addCustomer,
  updateCustomer,
  getCustomer,
  getCustomerList,
  getCustomerListCSV,

  getCustomerOptionsList,
};
