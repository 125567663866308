import { Fragment } from 'react';

const CompetencyTab = ({ competenciesAnalysis }) => {
  return (
    <Fragment>
      <div className="competency-tab">{competenciesAnalysis}</div>
      <br />
    </Fragment>
  );
};

export default CompetencyTab;
