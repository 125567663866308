import React from 'react';
import { cadsAiUserBaseUrl } from '../index';
import { UsersUIProvider } from './UsersUIProvider';
import { UsersCard } from './UsersCard';
import { uncheckAllUsers } from '../redux/action';
import { useDispatch } from 'react-redux';

export function UsersPage({ history, match }) {
  const dispatch = useDispatch();

  //clean up
  React.useEffect(() => () => dispatch(uncheckAllUsers()), []);

  const usersUIEvents = {
    openViewUsersDialog: (id) => {
      history.push(`${cadsAiUserBaseUrl}/${id}/view`);
    },
    openList: () => {
      history.push(`${cadsAiUserBaseUrl}`);
    },
  };

  return (
    <UsersUIProvider usersUIEvents={usersUIEvents}>
      <UsersCard />
    </UsersUIProvider>
  );
}
