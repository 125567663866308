import { Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import DetailedViewTab from './DetailViewTabs';

const DetailedViewDescription = ({ currentDimension, color }) => {
  return (
    <Fragment>
      <Row>
        <Col md={'12'}>
          <div style={{ color: color }}>
            <b>{currentDimension.dimensionName}</b>
            <b
              style={{
                backgroundColor: '#D5D5D5',
                marginLeft: '20px',
                borderRadius: '10px',
              }}
            >
              &nbsp; You are &nbsp;{currentDimension.scoreTitle} on &nbsp;{' '}
              {currentDimension.dimensionName} &nbsp;
            </b>
          </div>
          <div style={{ color: color }}>{currentDimension.dimensionDescription}</div>
          <DetailedViewTab currentDimension={currentDimension} />
        </Col>
      </Row>
    </Fragment>
  );
};

export default DetailedViewDescription;
