import React, { useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useApplicantsUIContext } from './ApplicantsUIProvider';
import ApplicantsTableWrapper from './table/ApplicantsTableWrapper';
import api from '@/utils/apiClient';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getApplicantCount } from '../redux/actions';

export function ApplicantsCard() {
  const applicantsUIContext = useApplicantsUIContext();
  const applicantsUIProps = useMemo(() => {
    return {
      openNewApplicantsDialog: applicantsUIContext.openNewApplicantsDialog,
    };
  }, [applicantsUIContext]);

  const dispatch = useDispatch();
  const { applicantCount } = useSelector((state) => state.applicants, shallowEqual);

  const [programCount, setProgramCount] = useState();

  const getProgramCount = async (filters) => {
    try {
      const { data: res, isSuccess } = await api.programs.getProgramCount(filters);
      if (isSuccess) {
        setProgramCount(res?.data?.count);
      } else {
        throw new Error('error while fetching program count');
      }
    } catch (error) {
      console.error('Error inside dashboard program count', error.message);
    }
  };

  useEffect(() => {
    getProgramCount({ status: true });
    dispatch(getApplicantCount({ status: true }));
  }, []);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">
            Applicants - {applicantCount} Active / {programCount} Programs
          </h3>
        </Col>
      </Row>
      <Row>
        <ApplicantsTableWrapper />
      </Row>
    </Container>
  );
}
