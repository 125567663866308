import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import moment from 'moment';
import PencilIcon from 'mdi-react/PencilIcon';
import { useProgramsUIContext } from '@/containers/Data-Star/Programs/components/ProgramsUIProvider';
import SanitizeHTML from '@/shared/components/SanitizeHTML';
import { getColorForWeight } from '@/shared/helpers';

export default function ({ program }) {
  const programsUIContext = useProgramsUIContext();

  return (
    <Row className="p-1">
      <Col>
        <Button
          className="icon mb-0 float-right"
          color="primary"
          outline
          onClick={() => programsUIContext.openEditProgramsDialog(program?.id)}
        >
          <p>
            <PencilIcon /> Edit
          </p>
        </Button>
        <table className="project-summary__info">
          {/* <tr>
            <th>Id</th>
            <td>{program?.id}</td>
          </tr>
          <tr>
            <th>Program type id</th>
            <td>{program?.program_type_id}</td>
          </tr> */}
          <tr>
            <th>Title</th>
            <td>{program?.title}</td>
          </tr>
          <tr>
            <th>Headline</th>
            <td>{program?.headline}</td>
          </tr>
          <tr>
            <th>Description</th>
            <td>
              <div className="html_description">
                <SanitizeHTML html={program?.description ?? '<p/>'} />
              </div>
            </td>
          </tr>
          <tr>
            <th>Start Date</th>
            <td>{moment(program?.startDate).format('DD/MM/YY')}</td>
          </tr>
          <tr>
            <th>End Date</th>
            <td>{moment(program?.endDate).format('DD/MM/YY')}</td>
          </tr>
          <tr>
            <th>Tags</th>
            <td>{program?.tags}</td>
          </tr>
          <tr>
            <th>Location</th>
            <td>{program?.location}</td>
          </tr>
          <tr>
            <th>Country Name</th>
            <td>{program?.countryName}</td>
          </tr>
          <tr>
            <th>Bg img url</th>
            <td>{program?.bgImgUrl}</td>
          </tr>
          <tr>
            <th>Logo img url</th>
            <td>{program?.logoImgUrl}</td>
          </tr>
          <tr>
            <th>Main img url</th>
            <td>{program?.mainImgUrl}</td>
          </tr>
          {/* <tr>
            <th>Organization id</th>
            <td>{program?.organization_id}</td>
          </tr> */}
          <tr>
            <th>Sponsor name(s)</th>
            <td>{program?.sponsorList?.map((sponsor) => sponsor.title)?.join(', ')}</td>
          </tr>
          <tr>
            <th style={{ width: '6rem' }}>Number of Applicants</th>
            <td>{program?.numberOfApplicants}</td>
          </tr>
          <tr>
            <th>Status</th>
            <td>{program?.status ? 'Active' : 'Deactivated'}</td>
          </tr>
          <tr>
            <th>Cohorts</th>
            <td>
              {program?.cohortList?.map((cohort) => (
                <button
                  className={`btn btn-${getColorForWeight({
                    random: true,
                    cssClass: true,
                  })} btn-sm`}
                >
                  {cohort?.title}
                </button>
              ))}
            </td>
          </tr>
        </table>
      </Col>
    </Row>
  );
}
