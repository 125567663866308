import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import EmailViewDialogHeader from './EmailViewDialogHeader';
import api from '@/utils/apiClient';
import LoadingIcon from 'mdi-react/LoadingIcon';
import SanitizeHTML from '@/shared/components/SanitizeHTML';
import moment from 'moment';

const EmailViewDialog = ({ show, onHide, id, rtl }) => {
  const [modal] = useState({
    isOpen: show,
    title: 'View Email',
    hide: onHide,
  });

  const [state, setState] = useState({
    loading: true,
    email: {
      emailHtml: '',
    },
    error: '',
  });

  useEffect(() => {
    if (id) getEmailById(+id);
  }, [id]);

  const getEmailById = async (emailId) => {
    try {
      const { data: res, isSuccess } = await api.cadsAIEmail.getEmailById(emailId);
      if (id) {
        setState({ ...state, loading: false, email: res.data, error: '' });
      } else {
        throw new Error(res?.messages?.[0] ?? 'Error while getting email details');
      }
    } catch ({ message }) {
      setState({ ...state, loading: false, email: {}, error: message });
      console.error(message);
    }
  };

  const { error, loading, email } = state;

  return (
    <Modal
      isOpen={show}
      toggle={modal.hide}
      modalClassName={`${rtl.direction}-support`}
      className="modal-dialog--primary modal-dialog--header modal-view-dialog"
    >
      <EmailViewDialogHeader modal={modal} email={email} />
      {/* Email Modal Body */}
      <div style={{ padding: 15 }}>
        {loading || !!error ? (
          <>
            {loading && (
              <div className="panel__refresh position-relative" style={{ height: 75 }}>
                <LoadingIcon />
              </div>
            )}
            <div className="text-center">
              {error && (
                <div>
                  <span className="lnr lnr-cross-circle modal__title-icon error" />
                </div>
              )}
              {loading ? 'Getting email details...' : error}
            </div>
          </>
        ) : (
          <table className="project-summary__info">
            <tr>
              <th>Subject</th>
              <td>{email?.subject}</td>
            </tr>
            <tr>
              <th>Body</th>
              <td>
                <div className="question-extra-html">
                  <SanitizeHTML html={email?.body ?? '<p/>'} />
                </div>
              </td>
            </tr>
            <tr>
              <th>From</th>
              <td>{email?.from}</td>
            </tr>
            <tr>
              <th>To</th>
              <td>{email?.to}</td>
            </tr>
            <tr>
              <th>Date</th>
              <td>{moment(email?.date).format('DD/MM/yyyy')}</td>
            </tr>
          </table>
        )}
      </div>
    </Modal>
  );
};

export default connect((state) => ({
  rtl: state.rtl,
}))(EmailViewDialog);
