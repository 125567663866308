import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, ButtonToolbar, Col, Row } from 'reactstrap';
import { YesNoOptions } from '@/shared/helpers';
import { FormField } from '@/shared/components/form/hook/FormField';
import { SelectField } from '@/shared/components/form/Select';
import { isEmpty } from 'lodash';
import { RadioButtonField } from '@/shared/components/form/RadioButton';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableCell } from '@material-ui/core';
import DeleteIcon from 'mdi-react/DeleteIcon';
import Table from '@material-ui/core/Table';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getAllSponsorOptions } from '@/containers/Cads-AI/ManageDataStar/Sponsors/redux/actions';

const SponsorForm = ({
  sponsorList,
  setSponsorList,
  setErrorMessage,
  isMainSponsor,
  setSponsorsOpts,
  sponsorsOpts,
  isEdit,
}) => {
  const dispatch = useDispatch();
  const { sponsorOptions } = useSelector((state) => state.cadsAISponsor, shallowEqual);
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({ reValidateMode: 'onChange' });
  const [isMain, setIsMain] = React.useState(`${YesNoOptions[1].value}`);

  React.useEffect(() => {
    dispatch(getAllSponsorOptions());
  }, []);

  React.useEffect(() => {
    if (isMainSponsor.length === 0) {
      setErrorMessage('');
    } else if (isMainSponsor.length >= 1 && isMain === 'true') {
      setErrorMessage('There can be only one main sponsor');
    } else {
      setErrorMessage('');
    }
  }, [isMain]);

  React.useEffect(() => {
    const selectedSponsorIds = sponsorList.map((item) => item?.id) ?? [];
    setSponsorsOpts(
      sponsorOptions
        .filter((s) => !selectedSponsorIds.includes(s?.id))
        .map((item) => ({ value: item?.id, label: item?.title, logoUrl: item?.logoUrl })),
    );
  }, [sponsorOptions]);

  const onSubmit = (data) => {
    //adding new sponsor to list
    const { sponsor } = getValues();
    if (isMainSponsor.length < 1 || isMain === 'false') {
      setSponsorList([
        ...sponsorList,
        {
          ...data,
          id: data.sponsor.value,
          name: data.sponsor.label,
          isMain: isMain === 'true',
          sortOrder: isEmpty(data.sortOrder) ? 0 : data.sortOrder,
          action: 'added',
        },
      ]);
      setSponsorsOpts(sponsorsOpts.filter((item) => item?.value !== sponsor?.value));
      setValue('sponsor', '');
    }
    if (isMainSponsor.length >= 1 && isMain === 'true') {
      setErrorMessage('There can be only one main sponsor');
    }
  };

  return (
    <form className={`form`} onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col>
          <div className="form__form-group">
            <span className="form__form-group-label">Sponsors</span>
            <div className="form__form-group-field">
              <FormField
                control={control}
                name="sponsor"
                isMulti={false}
                component={SelectField}
                options={sponsorsOpts}
                placeholder="Select Sponsors"
                rules={{ required: 'This is required field' }}
                errors={errors}
                isAboveError
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="form__form-group">
            <span className="form__form-group-label">Sort Order</span>
            <div className="form__form-group-field">
              <FormField
                name="sortOrder"
                as="input"
                type="number"
                min={0}
                placeholder="Enter Sort Order"
                control={control}
                defaultValue="0"
              />
            </div>
          </div>
        </Col>
        <Col>
          <Col>
            <div className="form__form-group">
              <span className="form__form-group-label">Is Main</span>
              <div className="form__form-group-field mt-2">
                <RadioButtonField
                  radioValue={`${YesNoOptions[0].value}`}
                  name={YesNoOptions[0].label}
                  onChange={setIsMain}
                  value={isMain}
                  className="colored-click mr-5"
                  label={YesNoOptions[0].label}
                />
                <RadioButtonField
                  radioValue={`${YesNoOptions[1].value}`}
                  name={YesNoOptions[1].label}
                  onChange={setIsMain}
                  value={isMain}
                  className="colored-click mr-5"
                  label={YesNoOptions[1].label}
                />
              </div>
            </div>
          </Col>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="form__form-group">
            <span className="form__form-group-label">Description</span>
            <div className="form__form-group-field">
              <FormField
                name="description"
                control={control}
                component="textarea"
                defaultValue=""
                placeholder="Enter Description"
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <ButtonToolbar className="form__button-toolbar modal__footer w-100">
            <Button disabled={false} color="success" outline type="submit">
              Add Sponsor
            </Button>
          </ButtonToolbar>
        </Col>
      </Row>
    </form>
  );
};

const SponsorTable = ({ sponsorList, setSponsorList, setSponsorsOpts, sponsorsOpts, isEdit }) => {
  const removeSponsor = (sponsors) => {
    setSponsorsOpts([
      { value: sponsors?.id, label: sponsors?.sponsor?.label ?? sponsors?.title },
      ...sponsorsOpts,
    ]);
    setSponsorList(
      sponsorList.map((sponsor) =>
        sponsor.id !== sponsors?.id ? sponsor : { ...sponsor, action: 'deleted' },
      ),
    );
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{ minWidth: 100 }}>Sponsor Name</TableCell>
          <TableCell style={{ minWidth: 100 }}>Sort Order</TableCell>
          <TableCell style={{ minWidth: 70 }}>Is Main</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
      {!sponsorList.length && (
        <TableRow>
          <TableCell colSpan={7} align="center">
            <p className="mt-3 mb-3">
              There are no sponsors added, fill out the about form and click on Add Sponsor button
              to add new Sponsor.
            </p>
          </TableCell>
        </TableRow>
      )}
      {sponsorList
        ?.filter((s) => s.action !== 'deleted')
        ?.sort((s1, s2) => +(s1?.sortOrder ?? 0) - (s2?.sortOrder ?? 0))
        .map((sponsor) => (
          <TableRow>
            <TableCell>{sponsor?.name ?? sponsor?.title ?? '-'}</TableCell>
            <TableCell>{sponsor?.sortOrder ?? '-'}</TableCell>
            <TableCell>{sponsor?.isMain ? 'Yes' : 'No'}</TableCell>
            <TableCell>
              {sponsor?.description?.trim() === '' ? '-' : sponsor?.description?.trim() ?? '-'}
            </TableCell>
            <TableCell>
              <DeleteIcon
                style={{ color: '#ff4861' }}
                role="button"
                onClick={() => removeSponsor(sponsor)}
              />
            </TableCell>
          </TableRow>
        ))}
    </Table>
  );
};

const StepFour = ({
  onSubmit,
  data,
  previousPage,
  isEdit,
}) => {
  const [sponsorList, setSponsorList] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [sponsorsOpts, setSponsorsOpts] = React.useState([]);
  const isMainSponsor = sponsorList.filter(
    (item) => item.isMain === true && item.action !== 'deleted',
  );

  React.useEffect(() => {
    setSponsorList(data?.sponsorList ?? []);
  }, [data]);

  return (
    <div className="modal__body add-edit-program-modal">
      <div className="error-message">{errorMessage}</div>
      <SponsorForm
        sponsorList={sponsorList}
        setSponsorList={setSponsorList}
        setErrorMessage={setErrorMessage}
        isMainSponsor={isMainSponsor}
        setSponsorsOpts={setSponsorsOpts}
        sponsorsOpts={sponsorsOpts}
        isEdit={isEdit}
      />
      <SponsorTable
        sponsorList={sponsorList}
        setSponsorList={setSponsorList}
        setSponsorsOpts={setSponsorsOpts}
        sponsorsOpts={sponsorsOpts}
      />

      <ButtonToolbar className="form__button-toolbar modal__footer">
        <Button
            type="button"
            className="previous"
            onClick={() => previousPage({sponsorList})}
        >
          {' '}
          Back{' '}
        </Button>
        <Button
            disabled={false}
            color="primary"
            type="button"
            onClick={() => {
              if (isMainSponsor.length === 1) {
                onSubmit({sponsorList})
              } else {
                setErrorMessage('There should be one main sponsor');
              }
            }}
        >
          {' '}
          Next
        </Button>
      </ButtonToolbar>
    </div>
  );
};

export default StepFour;
