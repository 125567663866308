import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const fetchDdoList = createAction('CADS_AI_FETCH_DDO_LIST');
export const setDdoList = createAction('CADS_AI_SET_DDO_LIST');
export const setDdoError = createAction('CADS_AI_SET_DDO_ERROR');
export const setDdoFilters = createAction('CADS_AI_SET_DDO_FILTERS');

export const getDdoList = (filter) => async (dispatch) => {
  const defaultError = `Error occurred getting ddo list`;
  dispatch(fetchDdoList());
  try {
    const { data, isSuccess } = await api.cadsAIDdo.getDdoList(filter);
    if (isSuccess) dispatch(setDdoList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setDdoError(error.message));
    console.error(defaultError, error.message);
  }
};
