import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import HelpTableWrapper from './table/HelpTableWrapper';

export function HelpCard() {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Helps</h3>
        </Col>
      </Row>
      <Row>
        <HelpTableWrapper />
      </Row>
    </Container>
  );
}
