import { sendRequest } from '@/utils/api/base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';
import * as Constants from '@/shared/helpers/constants';

const CP_ENDPOINT = process.env.REACT_APP_ROOT_URL + process.env.REACT_APP_CONTROL_PANEL_ENDPOINT;

const cpAssignmentEndpoints = {
  ASSIGN_FOR_DDO: `${CP_ENDPOINT}/setControlForDDO`,
  ASSIGN_FOR_CHATBOT: `${CP_ENDPOINT}/setControlForChatbot`,
  ASSIGN_FOR_ACCELTIC: `${CP_ENDPOINT}/setControlForAcceltic`,
  ASSIGN_MODULE_TO_USERS: `${CP_ENDPOINT}/assignModuleToUsers`,
  ASSIGN_MODULE_TO_ORGS: `${CP_ENDPOINT}/assignModuleToOrgs`,
  ASSIGN_LEARNING_PATH_TO_USERS: `/assignLearningPathToUsers`,
  ASSIGN_LEARNING_PATH_TO_ORGS: `/assignLearningPathToOrgs`,
  ASSIGN_TRAINING_TO_ORGS: `/assignTrainingToOrgs`,
};

const setAssignmentForDDO = (orgIds, cohortIds, userIds, forAssignment) => {
  const reqData = {
    orgIds,
    cohortIds,
    userIds,
    assignUnAssign: forAssignment ? 0 : 1, // Assignment=0/UnAssignment=1
  };
  // return mockAssignmentResponse();
  return sendRequest({
    fullUrl: cpAssignmentEndpoints.ASSIGN_FOR_DDO,
    method: httpMethod.POST,
    data: reqData,
  });
};

const setAssignmentForChatbot = (orgIds, cohortIds, userIds, forAssignment) => {
  const reqData = {
    orgIds,
    cohortIds,
    userIds,
    assignUnAssign: forAssignment ? 0 : 1, // Assignment=0/UnAssignment=1
  };
  // return mockAssignmentResponse();
  return sendRequest({
    fullUrl: cpAssignmentEndpoints.ASSIGN_FOR_CHATBOT,
    method: httpMethod.POST,
    data: reqData,
  });
};

const setAssignmentAcceltic = (orgIds, cohortIds, userIds, forAssignment) => {
  const reqData = {
    orgIds,
    cohortIds,
    userIds,
    assignUnAssign: forAssignment ? 0 : 1, // Assignment=0/UnAssignment=1
  };
  // return mockAssignmentResponse();
  return sendRequest({
    fullUrl: cpAssignmentEndpoints.ASSIGN_FOR_ACCELTIC,
    method: httpMethod.POST,
    data: reqData,
  });
};

const setAssignmentModule = (
  moduleId,
  moduleVariantIds,
  selectedIds,
  activeTab,
  allowRecommendation = false,
  tenantId = null,
) => {
  const reqData = { moduleId, moduleVariantIds, allowRecommendation };
  let apiEndpoint;
  if (activeTab === Constants.USERS) {
    apiEndpoint = cpAssignmentEndpoints.ASSIGN_MODULE_TO_USERS;
    reqData.jobseekerIds = selectedIds;
    reqData.tenantId = tenantId;
  } else {
    apiEndpoint = cpAssignmentEndpoints.ASSIGN_MODULE_TO_ORGS;
    reqData.tenantIds = selectedIds;
  }
  // return mockAssignmentResponse();
  return sendRequest({
    fullUrl: apiEndpoint,
    method: httpMethod.POST,
    data: reqData,
  });
};

const setAssignmentLearningPath = (
  learningPathId,
  selectedIds,
  activeTab,
  tenantId,
  allowRecommendation = false,
) => {
  const reqData = { learningPathId, allowRecommendation };
  let apiEndpoint;
  if (activeTab === Constants.USERS) {
    apiEndpoint = cpAssignmentEndpoints.ASSIGN_LEARNING_PATH_TO_USERS;
    reqData.jobSeekerIds = selectedIds;
    reqData.tenantId = tenantId;
    reqData.sortOrder = 1;
    reqData.stateId = 1;
    reqData.sourceId = 1;
  } else {
    apiEndpoint = cpAssignmentEndpoints.ASSIGN_LEARNING_PATH_TO_ORGS;
    reqData.tenantIds = selectedIds;
  }
  // return mockAssignmentResponse();
  return sendRequest(
    {
      url: apiEndpoint,
      method: httpMethod.POST,
      data: reqData,
    },
    process.env.REACT_APP_CONTROL_PANEL_SS_ENDPOINT,
  );
};

const setAssignmentTrainingProvider = (trainingProviderId, checkedItemIds) => {
  return sendRequest(
    {
      url: cpAssignmentEndpoints.ASSIGN_TRAINING_TO_ORGS,
      method: httpMethod.POST,
      data: {
        tenantIds: checkedItemIds,
        trainingProviderId: trainingProviderId,
      },
    },
    process.env.REACT_APP_CONTROL_PANEL_ENDPOINT,
  );
};

export default {
  setAssignmentForDDO,
  setAssignmentForChatbot,
  setAssignmentAcceltic,
  setAssignmentModule,
  setAssignmentLearningPath,
  setAssignmentTrainingProvider,
};

const mockAssignmentResponse = () =>
  new Promise((resolve, reject) => {
    setTimeout(() => resolve({ isSuccess: true }), 1000);
  });
