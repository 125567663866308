import React from 'react';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import api from '@/utils/apiClient';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { unCheckAllOrganizations } from '@/containers/Cads-AI/redux/organization/actions';

const OrganizationDeactivateDialog = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state, shallowEqual);
  const [modal, setModal] = React.useState({
    isOpen: show,
    title: '',
    message: 'Are you sure you want to deactivate selected organizations?',
    hide: onHide,
    loading: false,
    deactivateSuccess: false,
    deactivateError: false,
    isError: false,
  });
  const { checkedOrganizationIds } = useSelector((state) => state.cadsAIOrgs, shallowEqual);

  const handleOrganizationDeactivate = async () => {
    setModal({ ...modal, loading: true });
    const { isSuccess } = await api.cadsAIOrganization.deactivateOrganizations(
      checkedOrganizationIds,
    );
    if (isSuccess) {
      dispatch(unCheckAllOrganizations());
      setModal({
        ...modal,
        loading: false,
        deactivateSuccess: true,
        message: 'Organizations deactivated successfully.',
      });
    } else {
      setModal({
        ...modal,
        loading: false,
        message: 'Failed to deactivate organizations',
        isError: true,
        deactivateError: true,
      });
    }
  };

  return (
    <Modal
      isOpen={show}
      toggle={modal.hide}
      keyboard={false}
      backdrop={'static'}
      modalClassName={`${rtl.direction}-support`}
      className="modal-dialog--primary modal-dialog"
    >
      <div className="modal__header">
        <button
          className="lnr lnr-cross modal__close-btn"
          aria-label="modal__close-btn"
          type="button"
          onClick={modal.hide}
        />
        {!(modal.loading || modal.deactivateSuccess || modal.deactivateError) && (
          <span className="lnr lnr-unlink modal__title-icon" />
        )}
        {modal.deactivateSuccess && (
          <span className="lnr lnr-thumbs-up modal__title-icon success" />
        )}
        {modal.deactivateError && <span className="lnr lnr-cross-circle modal__title-icon error" />}
        <h4 className="text-modal  modal__title">{modal.title}</h4>
        <div className="modal__body">
          {modal.loading && (
            <div className="panel__refresh position-relative" style={{ height: 75 }}>
              <LoadingIcon />
            </div>
          )}
          {modal.message}
        </div>
        <ButtonToolbar className="modal__footer">
          {modal.deactivateError && (
            <Button className="modal_cancel" onClick={onHide} outline>
              Close
            </Button>
          )}
          {modal.deactivateSuccess && (
            <>
              <Button className="modal_cancel" onClick={onHide} outline>
                Okay
              </Button>
            </>
          )}
          {!(modal.deactivateSuccess || modal.deactivateError || modal.loading) && (
            <>
              <Button className="modal_cancel" onClick={onHide}>
                Cancel
              </Button>
              <Button
                className="modal_ok"
                outline
                color="danger"
                onClick={handleOrganizationDeactivate}
              >
                Yes, Deactivate
              </Button>
            </>
          )}
        </ButtonToolbar>
      </div>
    </Modal>
  );
};

export default OrganizationDeactivateDialog;
