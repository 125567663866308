import { useEffect } from 'react';
import Highcharts from 'highcharts';
import Tree from 'highcharts/modules/treemap';
import exporting from 'highcharts/modules/exporting';
import data from 'highcharts/modules/data';
import Heatmap from 'highcharts/modules/heatmap';

Tree(Highcharts);
data(Highcharts);
Heatmap(Highcharts);
exporting(Highcharts);

const AssessmentStats = () => {
  const initialChart = () => {
    Highcharts.chart('AssessmentStats', {
      chart: {
        type: 'pie',
      },
      title: {
        text: '',
      },
      subtitle: {
        text: 'For all users (regardless of selected assessment)',
      },

      accessibility: {
        announceNewData: {
          enabled: true,
        },
        point: {
          valueSuffix: '%',
        },
      },

      credits: {
        enabled: false,
      },

      colors: ['#e74c5c', '#70bbfb', '#77e2b7'],

      plotOptions: {
        pie: {
          showInLegend: true,
        },
        series: {
          enableMouseTracking: false,
          dataLabels: {
            enabled: true,
            format: '{point.y:.1f}%',
          },
        },
      },

      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>',
      },

      series: [
        {
          name: '',
          colorByPoint: true,
          data: [
            {
              name: 'Unopened',
              y: 40,
            },
            {
              name: 'Opened',
              y: 30,
            },
            {
              name: 'Completed',
              y: 30,
            },
          ],
        },
      ],
    });
  };

  useEffect(() => {
    initialChart();
  }, []);

  return <div id="AssessmentStats" className="col-lg-7" />;
};

export default AssessmentStats;
