import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, ButtonToolbar } from 'reactstrap';
import { emptyStringPattern, YesNoOptions } from '@/shared/helpers';
import api from '@/utils/apiClient';
import { SelectField } from '@/shared/components/form/Select';
import { FormField } from '@/shared/components/form/hook/FormField';
import { extractFieldData, getStepFields } from '../index';
import renderDropZoneField from '@/shared/components/form/DropZone';
import { RadioButtonField } from '@/shared/components/form/RadioButton';
import TextEditor from '@/shared/components/text-editor/TextEditor';

const StepOne = ({ onSubmit, page, data, isEdit }) => {
  // program form related functions
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm(extractFieldData(page, data));
  const [activate, setActivate] = React.useState(
    (data.activationStatus ?? 'activate').toLowerCase() === 'active' ? 'Activate' : 'Deactivate',
  );

  React.useEffect(() => {
    setValue('activationStatus', activate === 'activate' ? 'Active' : 'Deactivated');
  }, [activate]);

  React.useEffect(() => {
    if (['Active', 'Deactivated'].includes(data.activationStatus)) {
      setActivate(data.activationStatus === 'Active' ? 'activate' : 'deactivate');
    } else {
      setActivate(data.activationStatus);
    }
  }, [data]);

  React.useEffect(() => {
    getStepFields(page).forEach((key) => {
      setValue(key, data[key]);
    });
  }, [data]);

  return (
    <div className="modal__body add-edit-learning-path">
      <form id="programForm" className={`form form--horizontal`} onSubmit={handleSubmit(onSubmit)}>
        <div className="form__form-group">
          <span className="form__form-group-label">Title</span>
          <div className="form__form-group-field">
            <FormField
              name="title"
              control={control}
              component="input"
              errors={errors}
              rules={{
                required: 'Title is required field',
                pattern: emptyStringPattern,
              }}
              defaultValue=""
              isAboveError={true}
              placeholder="Enter Title"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Code</span>
          <div className="form__form-group-field">
            <FormField
              name="code"
              control={control}
              component="input"
              defaultValue=""
              placeholder="Enter Code"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Description</span>
          <div className="form__form-group-field">
            <FormField
              control={control}
              name="description"
              component={() => (
                <TextEditor
                  defaultContent={data?.description}
                  onChange={(value) => setValue('description', value)}
                />
              )}
              isAboveError={true}
              errors={errors}
              rules={{
                required: 'Description is required field',
                pattern: emptyStringPattern,
              }}
            />
          </div>
        </div>
        {/* {isEdit &&
            <div className="form__form-group">
              <span className="form__form-group-label">Activation Status</span>
              <div className="form__form-group-field mt-2">
                <RadioButtonField radioValue={`activate`} name={'Activate'} onChange={setActivate} value={activate} className="colored-click mr-5" label={'Activate'}/>
                <RadioButtonField radioValue={`deactivate`} name={'Deactivate'} onChange={setActivate} value={activate} className="colored-click mr-5" label={'De-Activate'}/>
              </div>
            </div>
        } */}
        <div className="form__form-group">
          <span className="form__form-group-label">Start Date</span>
          <div className="form__form-group-field">
            <FormField
              name="startDate"
              type="date"
              control={control}
              errors={errors}
              defaultValue=""
              isAboveError={true}
              placeholder="Enter the Start Date"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">End Date</span>
          <div className="form__form-group-field">
            <FormField
              name="endDate"
              type="date"
              control={control}
              errors={errors}
              defaultValue=""
              isAboveError={true}
              placeholder="Enter the End Date"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Tag</span>
          <div className="form__form-group-field">
            <FormField
              name="tags"
              control={control}
              component="input"
              errors={errors}
              defaultValue=""
              isAboveError={true}
              placeholder="Tag for program"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Limitations</span>
          <div className="form__form-group-field">
            <FormField
              name="limitations"
              control={control}
              component="input"
              errors={errors}
              defaultValue=""
              isAboveError={true}
              placeholder="Limitations for Program"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Logo</span>
          <div className="form__form-group-field">
            <FormField
              name="logoUrl"
              as="input"
              placeholder="Enter Logo Url"
              control={control}
              component={renderDropZoneField}
              defaultValue=""
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Background</span>
          <div className="form__form-group-field">
            <FormField
              name="backgroundUrl"
              as="input"
              placeholder="Enter Background Url"
              control={control}
              component={renderDropZoneField}
              defaultValue=""
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Poster</span>
          <div className="form__form-group-field">
            <FormField
              name="posterUrl"
              as="input"
              placeholder="Enter Poster Url"
              control={control}
              component={renderDropZoneField}
              defaultValue=""
            />
          </div>
        </div>
      </form>
      <ButtonToolbar className="form__button-toolbar modal__footer">
        <Button disabled={false} form="programForm" color="primary" type="submit">
          Next
        </Button>
      </ButtonToolbar>
    </div>
  );
};

export default StepOne;
