import React from 'react';
import { CardBody } from 'reactstrap';
import AssignedTable from './AssignedTable';
import AssignedTableFilter from './AssignedTableFilter';

const AssignedTableWrapper = () => {
  return (
    <CardBody>
      <AssignedTableFilter />
      <AssignedTable />
    </CardBody>
  );
};

export default AssignedTableWrapper;
