import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const fetchOrderHistoryList = createAction('CADS_AI_FETCH_ORDER_HISTORY_LIST');
export const setOrderHistoryList = createAction('CADS_AI_SET_ORDER_HISTORY_LIST');
export const setOrderHistoryError = createAction('CADS_AI_SET_ORDER_HISTORY_ERROR');
export const setOrderHistoryFilters = createAction('CADS_AI_SET_ORDER_HISTORY_FILTERS');

export const getOrderHistoryList = (filter) => async (dispatch) => {
  const defaultError = `Error occurred getting order history list`;
  dispatch(fetchOrderHistoryList());
  try {
    const { data, isSuccess } = await api.cadsAIOrderHistory.getOrderHistoryList(filter);
    if (isSuccess) dispatch(setOrderHistoryList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setOrderHistoryError(error.message));
    console.error(defaultError, error.message);
  }
};
