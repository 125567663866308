import React, { memo } from 'react';
import FormField from '@/shared/components/form/hook/FormField';
import renderDropZoneField from '@/shared/components/form/DropZone';
import { useForm } from 'react-hook-form';
import { setConfigPageQuestionTypeData } from '../../redux/actions';
import { useDispatch } from 'react-redux';
import { convertImageFileToBase64 } from '@/shared/helpers';

const FileUploadDropzone = ({ pageId, questionId, typeData }) => {
  const dispatch = useDispatch();
  const { control, setValue } = useForm();
  const { image } = typeData.fileUpload ?? { image: [] };

  const handleFileChange = async (file) => {
    setValue('image', file);
    dispatch(
      setConfigPageQuestionTypeData({
        pageId,
        questionId,
        typeData: {
          fileUpload: {
            image: file,
            imageBase64: file?.[0] ? await convertImageFileToBase64(file[0]) : '',
          },
        },
      }),
    );
  };

  return (
    <div className="form">
      <div className="form__form-group">
        <span className="form__form-group-label w-25">Image File</span>
        <span className="form__form-group-label text-right w-75">
          only JPG, JPEG, GIF or PNG accepted, max size: 2MB
        </span>
        <div className="form__form-group-field">
          <FormField
            name="image"
            control={control}
            defaultValue={image}
            onChange={handleFileChange}
            component={renderDropZoneField}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(FileUploadDropzone);
