export const getUsersList = (filters) => (dispatch) => {
  dispatch({
    type: 'FETCH_USERS_LIST',
    payload: {
      data: [
        {
          id: 1,
          name: 'Test',
          email: 'test@test.com',
          contact: 123456789789,
          literacy_skills: ['Dashboard', 'Excel', 'Java', 'Power'],
          other_skills: ['Visualisation', 'Excel'],
          experience_link: 'http://localhost:3001/campaign/users/users-page',
          blog_link: 'http://localhost:3001/campaign/users/users-page',
          portfolio_link: 'http://localhost:3001/campaign/users/users-page',
        },
        {
          id: 2,
          name: 'Test2',
          email: 'test@test2.com',
          contact: 123456789789,
          literacy_skills: ['Dashboard', 'Excel', 'Java', 'Power'],
          other_skills: ['Visualisation', 'Excel'],
          experience_link: 'http://localhost:3001/campaign/users/users-page',
          blog_link: 'http://localhost:3001/campaign/users/users-page',
          portfolio_link: 'http://localhost:3001/campaign/users/users-page',
        },
      ],
      loading: false,
      filters: filters,
    },
  });
};
