import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const setCohortFetching = createAction('CADS_AI_SET_COHORT_FETCHING');
export const setCohortError = createAction('CADS_AI_SET_COHORT_ERROR');
export const setAssignCohortList = createAction('CADS_AI_SET_ASSIGN_COHORT_LIST');
export const setCohortFilters = createAction('CADS_AI_SET_COHORT_LIST_FILTER');
export const setCohortState = createAction('CADS_AI_SET_COHORT_STATE');
export const setCohortOptionsList = createAction('CADS_AI_SET_COHORT_OPTIONS');

export const getCadsAICohortsList = (filter) => async (dispatch) => {
  const defaultError = `Error occurred getting ${
    filter.assigned ? 'Assigned' : 'Assign'
  } cohort list`;
  dispatch(setCohortFetching());
  try {
    const { data, isSuccess } = await api.cadsAICohort.getCadsAICohortList(filter);
    if (isSuccess) dispatch(setAssignCohortList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setCohortError(error.message));
    console.error(defaultError, error.message);
  }
};

export const getCohortOptionsList = (filter) => async (dispatch) => {
  const defaultError = 'Error occurred getting organization options list';
  try {
    const { data, isSuccess } = await api.cadsAICohort.getCohortOptions(filter);
    if (isSuccess) dispatch(setCohortOptionsList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    console.error(defaultError, error.message);
  }
};
