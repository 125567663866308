import React, { Fragment } from 'react';

const GroupSkillComponent = ({ index, skill }) => {
  return (
    <Fragment>
      <div key={index} className="skill-level-component">
        <span className={'skill-component__skill-name'}>{skill.skillName}</span>
        {skill.level >= 0 && (
          <span
            className={
              skill.level === 1 || skill.level === 0
                ? `level1`
                : skill.level === 2
                ? `level2`
                : skill.level === 3
                ? `level3`
                : skill.level === 4
                ? `level4`
                : skill.level === 5
                ? `level5`
                : ``
            }
          >
            LVL {skill.level}
          </span>
        )}
      </div>
    </Fragment>
  );
};

export default GroupSkillComponent;
