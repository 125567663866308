import { handleActions } from 'redux-actions';
import { fetchDdoList, setDdoError, setDdoFilters, setDdoList } from './actions';

const defaultState = {
  loading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    searchText: '',
    isSortOrderDescending: false,
  },
  totalRecords: 0,
  data: [],
  options: [],
  error: null,
};

export default handleActions(
  {
    [fetchDdoList](state) {
      return {
        ...state,
        loading: true,
        data: [],
      };
    },
    [setDdoList](state, { payload }) {
      return {
        ...state,
        loading: false,
        data: payload.data?.records,
        totalRecords: payload.data?.totalRecords,
        error: null,
      };
    },
    [setDdoError](state, { payload }) {
      return {
        ...state,
        data: [],
        loading: false,
        totalPages: defaultState.totalPages,
        error: payload?.message,
      };
    },
    [setDdoFilters](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
      };
    },
  },
  defaultState,
);
