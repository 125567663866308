import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

export const getModuleColumns = (isForAssignment) => {
  const cols = [
    {
      id: 'imageUrl',
      disablePadding: true,
      label: 'Image',
    },
    {
      id: 'name',
      disablePadding: true,
      label: 'Module Name',
    },
    {
      id: 'summary',
      disablePadding: true,
      label: 'Summary',
    },
    {
      id: 'moduleSkills',
      disablePadding: true,
      label: 'Skills',
    },
  ];
  return isForAssignment
    ? [{ id: 'checkbox', disablePadding: true, label: '' }, ...cols]
    : [...cols, { id: 'Action', disablePadding: true, label: 'Action' }];
};

const createSortHandler = (property, onRequestSort) => (event) => {
  onRequestSort(event, property);
};

const ModuleTableColumns = ({ order, orderBy, onRequestSort, isForAssignment }) => (
  <Fragment>
    <TableRow>
      {getModuleColumns(isForAssignment).map((row) => (
        <TableCell
          className="material-table__cell material-table__cell--sort material-table__cell-center"
          key={row.id}
          align={'center'}
          padding={row.disablePadding ? 'none' : 'default'}
          sortDirection={orderBy === row.id ? order : false}
        >
          <TableSortLabel
            active={orderBy === row.id}
            direction={order}
            onClick={createSortHandler(row.id, onRequestSort)}
            className="material-table__sort-label"
            dir="ltr"
          >
            <b>{row.label}</b>
          </TableSortLabel>
        </TableCell>
      ))}
    </TableRow>
  </Fragment>
);

ModuleTableColumns.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default connect((state) => ({
  rtl: state.rtl,
}))(ModuleTableColumns);
