import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Card, Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import SkillTab from './SkillTab';
import RecommendationTab from './RecommendationTab';

const DetailedViewTab = ({ currentDimension }) => {
  const [activeTab, setActiveTab] = useState('skill');

  useEffect(() => {
    setActiveTab('skill');
  }, []);

  const detailedTabs = [
    {
      name: 'Skills Analysis',
      value: 'skill',
    },
    {
      name: 'Recommendations for Development',
      value: 'recommendation',
    },
  ];

  const toggle = (tab) => {
    if (activeTab !== tab) {
      let update = { activeTab: tab };
      if (tab === 'skill') {
        update.isProfileRefresh = true;
      } else {
        update.isSkillsRefresh = true;
      }
      setActiveTab(update.activeTab);
    }
  };

  return (
    <Row className={'mt-3'}>
      <Col md={12} lg={12} xl={12}>
        <Card>
          <div className="profile__card tabs tabs--bordered-bottom">
            <div className="tabs__wrap">
              <Nav tabs className={'p-0'} style={{ border: '0px' }}>
                {detailedTabs.map((profileTab, index) => {
                  return (
                    <NavItem key={index}>
                      <NavLink
                        className={classnames({
                          active: activeTab === profileTab.value,
                        })}
                        onClick={() => toggle(profileTab.value)}
                      >
                        {profileTab.name}
                      </NavLink>
                    </NavItem>
                  );
                })}
              </Nav>
              {activeTab === 'skill' ? (
                <SkillTab skills={currentDimension.skillAnalysis} />
              ) : activeTab === 'recommendation' ? (
                <RecommendationTab recommendation={currentDimension.recommendations} />
              ) : null}
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default DetailedViewTab;
