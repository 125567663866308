import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import SponsorEditForm from './SponsorsAddEditForm';
import SponsorEditDialogHeader from './SponsorsEditDialogHeader';

const SponsersEditDialog = ({ show, onHide, id, isEdit, rtl }) => {
  const [modal, setModal] = useState({
    isOpen: show,
    title: `${isEdit ? 'Edit' : 'Add'}  Sponsors`,
    hide: onHide,
  });

  return (
    <Modal
      isOpen={show}
      toggle={modal.hide}
      modalClassName={`${rtl.direction}-support`}
      className="modal-dialog--primary modal-dialog--header modal-edit-dialog"
    >
      <SponsorEditDialogHeader modal={modal} />
      <SponsorEditForm modal={modal} isEdit={isEdit} id={+id} />
    </Modal>
  );
};

export default connect((state) => ({
  rtl: state.rtl,
}))(SponsersEditDialog);
