import React, { useEffect, useState } from 'react';
import LoadingIcon from 'mdi-react/LoadingIcon';
import api from '@/utils/apiClient';
import ApplicantAddEditForm from './ApplicantAddEditForm';
import { shallowEqual, useSelector } from 'react-redux';

const ApplicantEditDialogBody = ({ modal, isEdit, id }) => {
  const initialValues = {
    status: '',
  };

  const { data: applicants } = useSelector((state) => state.applicants, shallowEqual);

  const [data, setData] = useState(initialValues);

  const [state, setState] = useState({
    loading: false,
    fetchingApplicant: false,
    newApplicantAdded: false,
    errorMessage: '',
  });

  useEffect(() => {
    if (isEdit && id) {
      setDefaultData(+id);
    }
  }, [isEdit, id]);

  useEffect(() => {
    if (state.newApplicantAdded) {
      modal.hide();
    }
  }, [state?.newApplicantAdded]);

  const getApplicant = async (applicantId) => {
    return applicants.filter((applicant) => applicant.id == applicantId)[0];
  };

  const setDefaultData = async (applicantId) => {
    try {
      const data = await getApplicant(applicantId);
      setData({
        id,
        program_application_status: {
          value: data?.program_application_status.id,
          label: data?.program_application_status.name,
        },
      });
    } catch ({ message }) {
      console.error('Applicant edit error : ', message);
      setState({
        ...state,
        errorMessage: 'Error while getting existing applicant details',
      });
    }
  };

  const constructApiRequestData = ({ id, program_application_status }) => ({
    user_program_application_dtls_id: id,
    program_application_status_id: program_application_status.value,
  });

  const handleAddEditApplicant = async (applicantData) => {
    setState({ ...state, loading: true });
    const newApplicantData = constructApiRequestData(applicantData);
    const defaultError = `Error occurred while ${isEdit ? 'Updating' : 'Adding new'} applicant`;
    try {
      const { data, isSuccess } = isEdit
        ? await api.applicants.updateStatusForProgramApplicant(newApplicantData)
        : await api.applicants.addApplicant(newApplicantData);
      if (isSuccess) {
        setState({ ...state, newApplicantAdded: true, loading: false });
        setData(initialValues);
        if (!isEdit) setData(initialValues);
      } else {
        throw new Error(data?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({
        ...state,
        loading: false,
        newApplicantAdded: false,
        errorMessage: error.message,
      });
    }
  };

  return (
    <div>
      {/*Add/Edit Applicant Form Status*/}
      <div className="ml-4">
        {state.loading && (
          <div>
            <div className="panel__refresh position-relative" style={{ height: 75, width: 20 }}>
              <LoadingIcon />
              <div style={{ width: 200, padding: '23px 30px' }}>
                {isEdit ? 'Updating' : 'Adding new'} applicant...
              </div>
            </div>
          </div>
        )}
        {(state.newApplicantAdded || !!state.errorMessage) && (
          <>
            <table className="mt-3">
              <tr>
                <td>
                  {state.newApplicantAdded ? (
                    <span className="lnr lnr-thumbs-up modal__title-icon success" />
                  ) : (
                    <span className="lnr lnr-cross-circle modal__title-icon error" />
                  )}
                </td>
                <td className="pl-2">
                  {state.newApplicantAdded
                    ? `${isEdit ? 'Applicant Updated' : 'New Applicant Added'} successfully`
                    : state.errorMessage}
                </td>
              </tr>
            </table>
          </>
        )}
      </div>

      {/*Add/Edit Applicant Form*/}
      <ApplicantAddEditForm onSubmit={handleAddEditApplicant} data={data} modal={modal} />
    </div>
  );
};

export default ApplicantEditDialogBody;
