import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const fetchProgramList = createAction('CADS_AI_FETCH_PROGRAM_LIST');
export const setProgramList = createAction('CADS_AI_SET_PROGRAM_LIST');
export const setProgramCount = createAction('CADS_AI_SET_PROGRAM_COUNT');
export const setProgramError = createAction('CADS_AI_SET_PROGRAM_ERROR');
export const setAssessmentOptions = createAction('CADS_AI_SET_ASSESSMENT_LIST');
export const setProgramFilters = createAction('CADS_AI_SET_PROGRAM_FILTERS');
export const toggleCheckedPrograms = createAction('CADS_AI_TOGGLE_CHECKED_PROGRAMS');
export const uncheckAllPrograms = createAction('CADS_AI_UNCHECK_ALL_PROGRAMS');
export const setProgramOptionsList = createAction('CADS_AI_SET_PROGRAM_OPTIONS_LIST');
export const setProgramStatusOptionsList = createAction('CADS_AI_SET_PROGRAM_STATUS_OPTIONS_LIST');

export const toggleCheckedItemById = (programId) => (dispatch) => {
  dispatch(toggleCheckedPrograms(programId));
};

export const getProgramCount = (filter) => async (dispatch) => {};

export const uncheckAllSelectedPrograms = () => (dispatch) => {};

export const getProgramsList = (filter) => async (dispatch) => {
  const defaultError = `Error occurred getting program list`;
  dispatch(fetchProgramList());
  try {
    const { data, isSuccess } = await api.cadsAIPrograms.getProgramList(filter);
    if (isSuccess) dispatch(setProgramList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setProgramError(error.message));
    console.error(defaultError, error.message);
  }
};

export const getAllAssessmentOptions = () => async (dispatch) => {
  const defaultError = `Error occurred getting assessment options list`;
  try {
    const { data, isSuccess } = await api.cadsAIPrograms.getAssessmentOptions();
    if (isSuccess) dispatch(setAssessmentOptions(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    console.error(defaultError, error.message);
  }
};

export const getProgramsListOptions = () => async (dispatch) => {};
