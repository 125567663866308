import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { RTLProps } from '@/shared/prop-types/ReducerProps';

export const QuestionColumns = [
  {
    id: 'qaSelect',
    label: 'Select',
    disablePadding: true,
  },
  {
    id: 'questionTitle',
    label: 'Question',
    disablePadding: true,
  },
  {
    id: 'postedBy',
    label: 'Posted By',
    disablePadding: true,
  }
];

const createSortHandler = (property, onRequestSort) => (event) => {
  onRequestSort(event, property);
};

const QaTableColumns = ({order, orderBy, rtl, onRequestSort}) => (
    <Fragment>
      <TableRow>
        {QuestionColumns.map((row) => (
            <TableCell
                className="material-table__cell material-table__cell--sort material-table__cell-center"
                key={row.id}
                align={row.id === 'questionTitle' ? 'left' : 'center'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
            >
              <TableSortLabel
                  active={orderBy === row.id}
                  direction={order}
                  onClick={createSortHandler(row.id, onRequestSort)}
                  className="material-table__sort-label"
                  dir="ltr"
              >
                <b>{row.label}</b>
              </TableSortLabel>
            </TableCell>
        ))}
      </TableRow>
    </Fragment>
);

QaTableColumns.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rtl: RTLProps.isRequired,
};

export default connect((state) => ({
  rtl: state.rtl,
}))(QaTableColumns);
