import React, { createContext, useContext } from 'react';

const ArticlesUIContext = createContext();

export function useArticlesUIContext() {
  return useContext(ArticlesUIContext);
}

export function ArticlesUIProvider({ articlesUIEvents, children }) {
  const value = {
    openViewArticlesDialog: articlesUIEvents.openViewArticlesDialog,
    openNewArticlesDialog: articlesUIEvents.openNewArticlesDialog,
    openEditArticlesDialog: articlesUIEvents.openEditArticlesDialog,
    openDeactivateArticlesDialog: articlesUIEvents.openDeactivateArticlesDialog,
  };

  return <ArticlesUIContext.Provider value={value}>{children}</ArticlesUIContext.Provider>;
}
