import React from 'react';
import { Col } from 'reactstrap';
import { Field } from '@/shared/components/Field';
import AccountCircleOutlineIcon from 'mdi-react/AccountCircleOutlineIcon';
import DollarIcon from 'mdi-react/DollarIcon';
import BuildingIcon from 'mdi-react/BuildingIcon';
import MapMarkerOutlineIcon from 'mdi-react/MapMarkerOutlineIcon';
import IndustrialIcon from 'mdi-react/IndustrialIcon';
import VerifiedIcon from 'mdi-react/VerifiedIcon';
import ListStatusIcon from 'mdi-react/ListStatusIcon';
import OtherDetails from './OtherDetails';
import moment from 'moment';
import ContactDetails from './ContactDetails';

export const OrganizationDetails = ({ organization }) => {
  return (
    <>
      <div className="row border-bottom pb-4">
        <Col xl={2} lg={4} md={4} className="orgprofile__avatar-no pl-4">
          <div className="orgprofile__avatar">
            <img
              className="orgprofile__avatar-img"
              src={`${organization?.logo ?? 'https://i.stack.imgur.com/l60Hf.png'}`}
              alt="avatar"
            />
          </div>
        </Col>
        <Col md={4} xl={5} lg={6}>
          <Field
            IconComponent={<AccountCircleOutlineIcon size={'20'} />}
            title={'Name'}
            fieldValue={organization.title ?? '-'}
          />
          <Field
            IconComponent={<IndustrialIcon size={'20'} />}
            title={'Industry'}
            fieldValue={organization?.industry ?? '-'}
          />
          <Field
            IconComponent={<MapMarkerOutlineIcon size={'20'} />}
            title={'City'}
            fieldValue={organization?.mainOfficeCity ?? '-'}
          />
          <Field
            IconComponent={<MapMarkerOutlineIcon size={'20'} />}
            title={'Country'}
            fieldValue={organization?.country ?? '-'}
          />
        </Col>
        <Col md={4} xl={5} lg={4}>
          <Field
            IconComponent={<BuildingIcon size={'20'} />}
            title={'Organization Type'}
            fieldValue={organization?.organizationType?.title ?? '-'}
          />
          <Field
            IconComponent={<DollarIcon size={'20'} />}
            title={'Revenue Size'}
            fieldValue={organization.revenueSize ?? '-'}
          />
          <Field
            IconComponent={<VerifiedIcon size={'20'} />}
            title={'Verification'}
            fieldValue={
              organization.isVerified
                ? organization?.verifyDate
                  ? moment(organization?.verifyDate).format('DD/MM/yyyy')
                  : '-'
                : '-'
            }
          />
          <Field
            IconComponent={<ListStatusIcon size={'20'} />}
            title={'Status'}
            fieldValue={organization.status ? 'Active' : 'Inactive'}
          />
        </Col>
      </div>
      <ContactDetails organization={organization} />
      <OtherDetails organization={organization} />
    </>
  );
};
