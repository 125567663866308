import { Button, ButtonToolbar, Modal } from 'reactstrap';
import React from 'react';
import CadsAIAssignTabs from '@/containers/Cads-AI/ManageLearning/Assign/components/CadsAIAssignTabs';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as Constants from '@/shared/helpers/constants';
import { getCadsAIUsersList } from '@/containers/Cads-AI/redux/user/actions';
import { getOrganizationsList, setOrganizationOptionsList, } from '@/containers/Cads-AI/redux/organization/actions';
import api from '@/utils/apiClient';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { uncheckAllItem } from '@/containers/Control-Panel/Assign/redux/actions';
import { getCadsAICohortsList } from '@/containers/Cads-AI/redux/cohort/actions';
import { paths } from "@/shared/helpers/paths";
import { useHistory } from "react-router-dom";

const cadsAIAssignedBaseUrl = paths.CADS_AI.LEARNING.ASSIGNED;

const AssignDialog = ({show, onHide}) => {
  const dispatch = useDispatch();
  const history = useHistory();


  const {rtl} = useSelector((state) => state, shallowEqual);
  const {checkedCourse} = useSelector((state) => state.cadsAICourse, shallowEqual);
  const {assignedProduct, assignedOrgId, tenantId} = useSelector(
      (state) => state.cadsAI,
      shallowEqual,
  );
  const {activeTab, checkedItemIds} = useSelector(
      (state) => state.cadsAIAssignPage,
      shallowEqual,
  );
  const {filters: cohortFilter} = useSelector(
      (state) => state.cadsAIAssignmentCohort,
      shallowEqual,
  );
  const {filters: userFilters} = useSelector((state) => state.cadsAIAssignmentUser, shallowEqual);
  const {filters: orgFilters} = useSelector((state) => state.cadsAIAssignmentOrgs, shallowEqual);

  const initialState = {
    isOpen: show,
    title: 'Assignment Confirmation',
    message: 'Are you sure want to proceed forward with assignment?',
    hide: onHide,
    loading: false,
    assignmentSuccess: false,
    assignmentError: false,
  };
  const [modal, setModal] = React.useState({ ...initialState });
  const [isRecommendationAllowed, setIsRecommendationAllowed] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      const { data: res } = await api.cadsAIOrganization.getOrganizationOptionsList();
      dispatch(setOrganizationOptionsList(res));
    })();
  }, []);

  const handleOnClose = (clearAll = false) => {
    const filter = {
      assigned: false,
      feature: assignedProduct?.id,
      orgId: assignedOrgId,
      courseId: checkedCourse?.id,
    };
    if (activeTab === Constants.USERS) {
      dispatch(getCadsAIUsersList({ ...filter, ...userFilters, tenantId }));
    } else if (activeTab === Constants.ORGANIZATION) {
      dispatch(getOrganizationsList({ ...filter, ...orgFilters }));
    } else if (activeTab === Constants.COHORT) {
      dispatch(getCadsAICohortsList({ ...filter, ...cohortFilter }));
    }
    setIsRecommendationAllowed(false);
    if (clearAll) {
      dispatch(uncheckAllItem());
      setModal({ ...initialState });
    }
    onHide();
  };

  const performAssignment = async () => {
    setModal({
      ...modal,
      loading: true,
      title: 'Performing Assignment',
      message: 'Assignment in progress...',
    });
    /*Make api call for performing assignment*/
    let updatedModal;
    try {
      const response = await api.cadsAIAssignment.assignmentCourseState(
          checkedCourse?.id,
          checkedItemIds,
          activeTab,
      );
      if (response.isSuccess) {
        updatedModal = {
          loading: false,
          assignmentSuccess: true,
          title: 'Done',
          message: 'Assignment completed successfully!',
        };
      } else {
        throw new Error('Error while performing assignment');
      }
    } catch (e) {
      updatedModal = {
        loading: false,
        assignmentError: true,
        title: 'Opps',
        message: 'Error performing assignments!',
      };
    } finally {
      setModal({ ...modal, ...updatedModal });
    }
  };

  const goToAssigned = () => {
    history.push(`${cadsAIAssignedBaseUrl}?activeTab=${activeTab}&assignmentFor=${assignedProduct.id}`);
  };

  return (
    <Modal
      isOpen={show}
      toggle={modal.hide}
      keyboard={false}
      backdrop={'static'}
      modalClassName={`${rtl.direction}-support`}
      onExit={() => handleOnClose(true)}
      className="modal-dialog--primary assignment-modal"
    >
      <div className="modal__header">
        <button
          className="lnr lnr-cross modal__close-btn"
          aria-label="modal__close-btn"
          type="button"
          onClick={() => handleOnClose(true)}
        />
        {!(modal.loading || modal.assignmentSuccess || modal.assignmentError) && (
          <span className="lnr lnr-pushpin modal__title-icon" />
        )}
        {modal.assignmentSuccess && (
          <span className="lnr lnr-thumbs-up modal__title-icon success" />
        )}
        {modal.assignmentError && <span className="lnr lnr-cross-circle modal__title-icon error" />}
        <h4 className="text-modal  modal__title">{modal.title}</h4>
        <div className="modal__body">
          {modal.loading && (
            <div className="panel__refresh position-relative" style={{ height: 75 }}>
              <LoadingIcon />
            </div>
          )}
          <p className="confirmation-text">{modal.message}</p>
          {/*{!modal.assignmentSuccess && !modal.loading && (
            <div className="form form--horizontal form__form-group">
              <span className="form__form-group-label">Allow Platform Recommendation ?</span>
              <div className="form__form-group-field">
                <ToggleButtonField
                  onChange={() => setIsRecommendationAllowed(!isRecommendationAllowed)}
                  name={'isRecommendationAllowed'}
                  value={isRecommendationAllowed}
                />
              </div>
              <p className="wizard__description">
                This will allow platform to recommend learning path based on their skills
              </p>
            </div>
          )}*/}
        </div>
        <ButtonToolbar className="modal__footer">
          {modal.assignmentError && (
            <Button className="modal_cancel" onClick={handleOnClose} outline>
              Close
            </Button>
          )}
          {modal.assignmentSuccess && (
            <>
              <Button className="modal_cancel" onClick={() => handleOnClose(true)} outline>
                Okay
              </Button>
              <Button className="modal_ok" outline color="primary" onClick={goToAssigned}>
                Go to Assigned
              </Button>
            </>
          )}
          {!(modal.assignmentSuccess || modal.assignmentError || modal.loading) && (
            <>
              <Button className="modal_cancel" onClick={handleOnClose}>
                Cancel
              </Button>
              <Button className="modal_ok" outline color="success" onClick={performAssignment}>
                Yes, Assign
              </Button>
            </>
          )}
        </ButtonToolbar>
      </div>
    </Modal>
  );
};

const StepTwo = ({ previousPage }) => {
  const { checkedItemIds } = useSelector((state) => state.cadsAIAssignPage, shallowEqual);
  const [showModal, setShowModal] = React.useState(false);

  return (
    <form className="mt-3">
      <CadsAIAssignTabs />
      <ButtonToolbar className="form__button-toolbar wizard__toolbar">
        <Button color="primary" type="button" className="previous" onClick={() => previousPage()}>
          Back
        </Button>
        <Button
          color="success"
          type="button"
          className="next"
          outline
          onClick={() => setShowModal(true)}
          disabled={checkedItemIds.length === 0}
        >
          Assign Selected
        </Button>
      </ButtonToolbar>
      <AssignDialog show={showModal} onHide={() => setShowModal(false)} />
    </form>
  );
};

export default StepTwo;
