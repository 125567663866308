import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import LoadingIcon from 'mdi-react/LoadingIcon';
import Select from 'react-select';
import { uncheckAllSelectedOrgsForBT } from '@/containers/Control-Panel/BaselineThreshold/redux/actions';
import api from '@/utils/apiClient';

export const BaselineThresholdConfigDialog = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state, shallowEqual);
  const checkedOrgIds = useSelector((state) => state.baselineThreshold.checkedOrgIds, shallowEqual);

  const [baselineThreshold, setBaselineThreshold] = React.useState({ value: 1, label: '1' });
  const [modal, setModal] = React.useState({
    isOpen: show,
    title: 'Baseline Threshold Assignment',
    message: 'Select baseline threshold value',
    hide: onHide,
    loading: false,
    assignmentSuccess: false,
    assignmentError: false,
  });

  const handleBTAssignment = async () => {
    setModal({
      ...modal,
      loading: true,
      title: 'Performing Assignment',
      message: 'Baseline Threshold Assignment In Progress...',
    });
    const response = await api.baselineThreshold.setOrganizationsBaselineThreshold(
      checkedOrgIds,
      baselineThreshold.value,
    );
    let updatedModal = {};
    if (response.isSuccess) {
      updatedModal = {
        loading: false,
        assignmentSuccess: true,
        title: 'Done',
        message: 'Baseline Threshold Assignment Completed Successfully!',
      };
      dispatch(uncheckAllSelectedOrgsForBT());
    } else {
      updatedModal = {
        loading: false,
        assignmentError: true,
        title: 'Opps',
        message: 'Error Performing Baseline Threshold Assignments!',
      };
    }
    setModal({ ...modal, ...updatedModal });
  };

  return (
    <Modal
      isOpen={show}
      toggle={modal.hide}
      keyboard={false}
      backdrop={'static'}
      modalClassName={`${rtl.direction}-support`}
      className="modal-dialog--primary modal-dialog"
    >
      <div className="modal__header">
        <button
          className="lnr lnr-cross modal__close-btn"
          aria-label="modal__close-btn"
          type="button"
          onClick={modal.hide}
        />
        {!(modal.loading || modal.assignmentSuccess || modal.assignmentError) && (
          <span className="lnr lnr-pushpin modal__title-icon" />
        )}
        {modal.assignmentSuccess && (
          <span className="lnr lnr-thumbs-up modal__title-icon success" />
        )}
        {modal.assignmentError && <span className="lnr lnr-cross-circle modal__title-icon error" />}
        <h4 className="text-modal  modal__title">{modal.title}</h4>
        <div className="modal__body bt-config">
          {modal.loading && (
            <div className="panel__refresh position-relative" style={{ height: 75 }}>
              <LoadingIcon />
            </div>
          )}
          {modal.message}
          {!(modal.assignmentSuccess || modal.assignmentError || modal.loading) && (
            <Select
              name="baselineThreshold"
              value={baselineThreshold}
              onChange={setBaselineThreshold}
              autosize={true}
              options={(() => {
                const opts = [];
                for (let i = 1; i <= 100; i++) opts.push({ value: i, label: i });
                return opts;
              })()}
              clearable={false}
              className="react-select mt-3 mb-3"
              placeholder={'Select Status'}
              classNamePrefix="react-select"
            />
          )}
        </div>
        <ButtonToolbar className="modal__footer">
          {modal.assignmentError && (
            <Button className="modal_cancel" onClick={modal.hide} outline>
              Close
            </Button>
          )}
          {modal.assignmentSuccess && (
            <>
              <Button className="modal_cancel" onClick={modal.hide} outline>
                Okay
              </Button>
            </>
          )}
          {!(modal.assignmentSuccess || modal.assignmentError || modal.loading) && (
            <>
              <Button className="modal_cancel" onClick={modal.hide}>
                Cancel
              </Button>
              <Button className="modal_ok" outline color="success" onClick={handleBTAssignment}>
                Assign
              </Button>
            </>
          )}
        </ButtonToolbar>
      </div>
    </Modal>
  );
};
