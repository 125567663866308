import React, { Fragment } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import { Button, FormGroup, Input } from 'reactstrap';
import ViewModuleIcon from 'mdi-react/ViewModuleIcon';

import SettingsIcon from 'mdi-react/SettingsIcon';
import PencilIcon from 'mdi-react/PencilIcon';
import { useModuleUIContext } from '../ModuleUIProvider';
import ModuleTableColumns, { getModuleColumns } from './ModuleTableColumns';
import { ModuleTableSearchBar } from './ModuleTableSearchBar';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getCPModulesList, setModuleFilters, toggleCheckedModule } from '../../redux/actions';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { defaultPaginationProps } from '@/shared/helpers';

const ModuleTable = () => {
  const moduleUIContext = useModuleUIContext();
  const dispatch = useDispatch();
  const {
    loading,
    filters,
    totalRecords,
    assignModules: modules,
    checkedModule,
    error,
  } = useSelector((state) => state.cpModules, shallowEqual);
  const {
    pageSize: rowsPerPage,
    pageNumber: page,
    sortBy: orderBy,
    isSortOrderDescending,
  } = filters;

  React.useEffect(() => {
    dispatch(getCPModulesList(filters));
  }, [filters]);

  const handleRequestSort = (event, property) => {
    dispatch(setModuleFilters({ sortBy: property, isSortOrderDescending: !isSortOrderDescending }));
  };

  const handleChangePage = (event, currentPage) => {
    dispatch(setModuleFilters({ pageSize: rowsPerPage, pageNumber: currentPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setModuleFilters({ pageSize: +event.target.value, pageNumber: 1 }));
  };

  const emptyRows =
    page === Math.ceil(totalRecords / rowsPerPage) ? rowsPerPage - modules.length : 0;

  const ModuleColumns = getModuleColumns(moduleUIContext.isForAssignment);

  const renderActionButtons = ({ id: moduleId }) => (
    <div className="actions">
      <Button
        className="icon mb-0"
        color="secondary"
        outline
        onClick={() => moduleUIContext.openManageSchedulesDialog(moduleId)}
      >
        <p>
          <SettingsIcon />
          Manage Schedules
        </p>
      </Button>
      <Button
        className="icon mb-0"
        color="primary"
        outline
        onClick={() => moduleUIContext.openEditModuleDialog(moduleId)}
      >
        <p>
          <PencilIcon /> Edit
        </p>
      </Button>
    </div>
  );

  return (
    <Fragment>
      <div className="material-table__wrap">
        <ModuleTableSearchBar />
        <Table className="material-table">
          <ModuleTableColumns
            isForAssignment={moduleUIContext.isForAssignment}
            order={isSortOrderDescending ? 'desc' : 'asc'}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rowsPerPage}
          />
          <TableBody>
            {(loading || !!error) && (
              <TableRow>
                <TableCell colSpan={ModuleColumns.length}>
                  {loading && (
                    <div className={'text-center overflow-hidden'}>
                      <div className="panel__refresh position-relative" style={{ height: 75 }}>
                        <LoadingIcon />
                      </div>
                    </div>
                  )}
                  <div className={'text-center'}>
                    <p className={!!error ? 'm-5' : ''}>{error || 'Loading modules...'}</p>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {!!!modules.length && !loading && !error && (
              <TableRow style={{ height: 120 }}>
                <TableCell colSpan={ModuleColumns.length} align="center">
                  {filters.status === null && filters.search === ''
                    ? 'No modules'
                    : 'No modules with applied filters'}
                </TableCell>
              </TableRow>
            )}
            {modules?.map((module) => {
              return (
                <TableRow className="material-table" tabIndex={-1} key={module.id}>
                  {moduleUIContext.isForAssignment && (
                    <TableCell align="center" className="material-table__cell">
                      <FormGroup>
                        <Input
                          className="table-checkbox"
                          type="checkbox"
                          value={module.id}
                          checked={checkedModule.id === module.id}
                          onChange={() => dispatch(toggleCheckedModule(module))}
                        />
                      </FormGroup>
                    </TableCell>
                  )}
                  <TableCell align="center" className="material-table__cell">
                    {module?.imageUrl ? (
                      <img
                        className="module_logo_image"
                        src={module?.imageUrl}
                        alt="module image"
                      />
                    ) : (
                      <span className="module_default_logo">
                        <ViewModuleIcon />
                      </span>
                    )}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {module?.name}
                  </TableCell>
                  <TableCell
                    align="center"
                    className="material-table__cell"
                    style={{ maxWidth: 600 }}
                  >
                    {module?.summary}
                  </TableCell>
                  <TableCell
                    align="center"
                    className="material-table__cell"
                    style={{ minWidth: 300 }}
                  >
                    {module?.moduleSkills.map((skill) => skill?.name).join(', ') || '-'}
                  </TableCell>
                  {!moduleUIContext.isForAssignment && (
                    <TableCell
                      align="center"
                      className="material-table__cell"
                      style={{ minWidth: 256 }}
                    >
                      {renderActionButtons(module)}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={ModuleColumns.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        component={defaultPaginationProps.component}
        backIconButtonProps={defaultPaginationProps.backIconButton}
        nextIconButtonProps={defaultPaginationProps.nextIconButton}
        rowsPerPageOptions={defaultPaginationProps.rowsPerPage}
        className={defaultPaginationProps.className}
        dir={defaultPaginationProps.dir}
      />
    </Fragment>
  );
};

export default ModuleTable;
