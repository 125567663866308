import React from 'react';
import api from '@/utils/apiClient';
import LoadingIcon from 'mdi-react/LoadingIcon';
import renderDropZoneField from '@/shared/components/form/DropZone';
import { convertImageFileToBase64, convertUrlToFile } from '@/shared/helpers';
import { SelectField } from '@/shared/components/form/Select';
import { useForm } from 'react-hook-form';
import { FormField } from '@/shared/components/form/hook/FormField';
import { Button, ButtonToolbar } from 'reactstrap';
import { emptyStringPattern } from '@/shared/helpers';
import { useSelector, shallowEqual } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getOrganizationOptionsList } from '../../../../redux/organization/actions';
import { isEmpty } from 'lodash';

const SponsorEditForm = ({ isEdit, modal, id }) => {
  const { data: sponsors } = useSelector((state) => state.cadsAISponsor, shallowEqual);
  const { options: organizationOpts } = useSelector((state) => state.cadsAIOrgs, shallowEqual);
  const dispatch = useDispatch();
  //states
  const [state, setState] = React.useState({
    loading: false,
    fetchingSponsors: false,
    newSponsorsAdded: false,
    errorMessage: '',
  });
  const [selectedSponsors, setSelectedSponsor] = useState({});
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (id) setSelectedSponsor(sponsors?.find((item) => item?.id === id));
  }, [sponsors, id]);

  useEffect(() => {
    dispatch(getOrganizationOptionsList());
  }, []);

  const onCancel = () => {
    modal.hide();
  };

  useEffect(() => {
    if (isEdit && id && !isEmpty(selectedSponsors)) {
      setDefaultData(+id);
    }
  }, [isEdit, id, selectedSponsors]);

  useEffect(() => {
    setOrganizationOptions(
      organizationOpts?.map((org) => ({ value: org.id, label: org.title })) ?? [],
    );
  }, []);

  const setDefaultData = async () => {
    ['title', 'description'].forEach((field) => setValue(field, selectedSponsors?.[field]));
    setValue(
      'organization',
      !selectedSponsors?.organization
        ? null
        : {
            value: selectedSponsors?.organization?.id,
            label: selectedSponsors?.organization?.title,
          },
    );
    const imgFile = await convertUrlToFile(selectedSponsors?.logoUrl);
    setValue('imageUrl', imgFile ? [imgFile] : '');
  };

  const onSubmit = async ({ title, description, imageUrl, organization }, e) => {
    setState({ ...state, loading: true });
    const base64Img = await convertImageFileToBase64(imageUrl?.[0]);
    const reqData = {
      title,
      description,
      logoUrl: base64Img?.split(',')?.[1],
      organizationId: organization?.value,
    };
    const defaultError = `Error occurred while ${isEdit ? 'Updating' : 'Adding new'} sponsors`;
    try {
      const { data, isSuccess } = isEdit
        ? await api.cadsAISponsors.editSponsors({ ...reqData, id })
        : await api.cadsAISponsors.addSponsors(reqData);
      if (isSuccess) {
        setState({ ...state, newSponsorsAdded: true, loading: false });
      } else {
        throw new Error(data?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({ ...state, loading: false, newSponsorsAdded: false, errorMessage: error.message });
    }
  };

  return (
    <div className="pl-4 pr-4">
      {/*Add/Edit Sponsors Form Status*/}
      <div className="mb-4">
        {state.loading && (
          <div>
            <div className="panel__refresh position-relative" style={{ height: 75, width: 20 }}>
              <LoadingIcon />
              <div style={{ width: 200, padding: '23px 40px' }}>
                {isEdit ? 'Updating' : 'Adding new'} Sponsors...
              </div>
            </div>
          </div>
        )}
        {(state.newSponsorsAdded || !!state.errorMessage) && (
          <table className="mt-3">
            <tr>
              <td>
                {state.newSponsorsAdded ? (
                  <span className="lnr lnr-thumbs-up modal__title-icon success" />
                ) : (
                  <span className="lnr lnr-cross-circle modal__title-icon error" />
                )}
              </td>
              <td className="pl-2">
                {state.newSponsorsAdded
                  ? `${isEdit ? 'Sponsor updated' : 'New sponsor added'} successfully`
                  : state.errorMessage}
              </td>
            </tr>
          </table>
        )}
      </div>
      <form className={`form form--horizontal sponsor-add-edit`} onSubmit={handleSubmit(onSubmit)}>
        <div className="form__form-group">
          <span className="form__form-group-label">Title</span>
          <div className="form__form-group-field">
            <FormField
              name="title"
              control={control}
              component="input"
              errors={errors}
              rules={{
                required: 'This is required field',
                pattern: emptyStringPattern,
              }}
              defaultValue=""
              isAboveError={true}
              placeholder="Name of Sponsor"
            />
          </div>
        </div>
        <div className="form__form-group mt-3">
          <span className="form__form-group-label">Select Organization</span>
          <div className="form__form-group-field">
            <FormField
              control={control}
              name="organization"
              component={SelectField}
              options={organizationOptions}
              placeholder="Select Organization"
              errors={errors}
              clearable={true}
              isAboveError
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Description</span>
          <div className="form__form-group-field">
            <FormField
              name="description"
              control={control}
              component="textarea"
              defaultValue=""
              placeholder="Write description about Sponsor"
            />
          </div>
        </div>
        <div className="form__form-group mt-1">
          <span className="form__form-group-label">Image URL</span>
          <div className="form__form-group-field border w-75">
            <FormField
              name="imageUrl"
              as="input"
              placeholder="Enter Image Url"
              control={control}
              errors={errors}
              rules={{ required: 'This is required field' }}
              component={renderDropZoneField}
              defaultValue=""
              isAboveError
            />
          </div>
        </div>

        <ButtonToolbar className="form__button-toolbar modal__footer">
          <Button disabled={state.loading} color="primary" type="submit">
            Submit
          </Button>
          <Button type="button" className="modal_cancel" onClick={onCancel}>
            Close
          </Button>
        </ButtonToolbar>
      </form>
    </div>
  );
};

export default SponsorEditForm;
