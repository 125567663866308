import React from 'react';
import { Col } from 'reactstrap';
import { Field } from '@/shared/components/Field';
import AccountCircleOutlineIcon from 'mdi-react/AccountCircleOutlineIcon';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import BriefcaseOutlineIcon from 'mdi-react/BriefcaseOutlineIcon';
import PhoneOutlineIcon from 'mdi-react/PhoneOutlineIcon';
import MapMarkerOutlineIcon from 'mdi-react/MapMarkerOutlineIcon';
import TrophyVariantOutlineIcon from 'mdi-react/TrophyVariantOutlineIcon';
import { WorkExperience } from './WorkExperience';
import { EducationDetails } from './EducationDetails';

export const Profile = ({ userDetails }) => {
  return (
    <>
      <div className="row border-bottom pb-4">
        <Col xl={2} lg={4} md={4} className="orgprofile__avatar-no pl-4">
          <div className="orgprofile__avatar">
            <img
              className="orgprofile__avatar-img"
              src={`${userDetails?.profilePic ?? 'https://i.stack.imgur.com/l60Hf.png'}`}
              alt="avatar"
            />
          </div>
        </Col>
        <Col md={4} xl={5} lg={4}>
          <Field
            IconComponent={<AccountCircleOutlineIcon size={'20'} />}
            title={'First Name'}
            fieldValue={userDetails.name ?? '-'}
          />
          <Field
            IconComponent={<EmailOutlineIcon size={'20'} />}
            title={'Email'}
            fieldValue={userDetails.email ?? '-'}
          />
          <Field
            IconComponent={<PhoneOutlineIcon size={'20'} />}
            title={'Mobile Phone'}
            fieldValue={userDetails?.mobile ?? '-'}
          />
        </Col>
        <Col md={4} xl={5} lg={4}>
          <Field
            IconComponent={<MapMarkerOutlineIcon size={'20'} />}
            title={'Country'}
            fieldValue={userDetails?.country ?? '-'}
          />
          <Field
            IconComponent={<BriefcaseOutlineIcon size={'20'} />}
            title={'Bio'}
            fieldValue={userDetails?.bio ?? '-'}
          />
          <Field
            IconComponent={<MapMarkerOutlineIcon size={'20'} />}
            title={'City'}
            fieldValue={userDetails?.city ?? '-'}
          />
        </Col>
        <Col className="mt-5 pl-5">
          <Col md={'2'} />
          <Col md={'10'}>
            <b className={'font-color-for-common'}>Verified Skills</b>
          </Col>
          <Col md={3} className="orgprofile__basic-detail">
            <button className="empprofile__trophy-verified-skills">
              <TrophyVariantOutlineIcon size={'20'} />
            </button>
            <div className="orgprofile__basic-information">
              <div className="orgprofile__basic-header">
                <div className="orgprofile__basic-header-title">Da & AI Skills</div>
              </div>
              <div className="orgprofile__basic-body">5 Skills Verified</div>
              <div className={'mt-1'}>
                <span
                  // href={"javascript:void(0);"}
                  style={{ color: '#21CFBA' }}
                >
                  View Details
                </span>
              </div>
            </div>
          </Col>
          <Col md={3} className="orgprofile__basic-detail">
            <button className="empprofile__trophy-other-skills">
              <TrophyVariantOutlineIcon size={'20'} />
            </button>
            <div className="orgprofile__basic-information">
              <div className="orgprofile__basic-header">
                <div className="orgprofile__basic-header-title">Other Skills</div>
              </div>
              <div className="orgprofile__basic-body">7 Skills Verified</div>
              <div className={'mt-1'}>
                <span
                  // href={"javascript:void(0);"}
                  style={{ color: '#21CFBA' }}
                >
                  View Details
                </span>
              </div>
            </div>
          </Col>
        </Col>
      </div>
      <WorkExperience />
      <EducationDetails />
    </>
  );
};
