import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonToolbar } from 'reactstrap';
import api from '@/utils/apiClient';
import { emptyStringPattern } from '@/shared/helpers';
import { FormField } from '@/shared/components/form/hook/FormField';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { SelectField } from '@/shared/components/form/Select';
import { updateModuleInList } from '../../redux/actions';

const ModuleEditForm = ({ modal, isEdit, id }) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();

  //states
  const [state, setState] = React.useState({
    loading: false,
    fetchingModule: false,
    newModuleAdded: false,
    errorMessage: '',
  });
  const [skillOptions, setSkillOptions] = React.useState([]);
  const { assignModules } = useSelector((state) => state.cpModules);
  const selectedModule = id ? assignModules.find((module) => module.id === id) : {};

  React.useEffect(() => {
    getSkillOptions();
  }, []);

  const getSkillOptions = async () => {
    const { data: res, isSuccess } = await api.modules.getTrainingSkillOptions();
    if (isSuccess) {
      setSkillOptions(res.data.map((skill) => ({ value: skill.id, label: skill.name })));
    }
  };

  // prefill the form in case of edit module
  React.useEffect(() => {
    if (isEdit && id) {
      setDefaultData(+id);
    }
  }, [isEdit, id]);

  const setDefaultData = (moduleId) => {
    if (selectedModule?.preAssessmentId) {
      selectedModule['preAssessmentName'] = selectedModule?.preAssessmentId;
    }
    if (selectedModule?.postAssessmentId) {
      selectedModule['postAssessmnetName'] = selectedModule?.postAssessmentId;
    }
    [
      'id',
      'name',
      'description',
      'summary',
      'tagline',
      'imageUrl',
      'materialsUrl',
      'preAssessmentName',
      'postAssessmnetName',
    ].forEach((field) => setValue(field, selectedModule?.[field]));
    setValue(
      'moduleSkills',
      selectedModule?.moduleSkills?.map((m) => ({ value: m.id, label: m.name })),
    );
  };

  const resetForm = () => {
    reset({
      id: 0,
      name: '',
      description: '',
      summary: '',
      tagline: '',
      imageUrl: '',
      materialsUrl: '',
      moduleSkills: [],
      preAssessment: '',
      postAssessment: '',
    });
  };

  const constructRequest = ({
    name,
    description,
    summary,
    tagline,
    imageUrl,
    materialsUrl,
    moduleSkills,
    preAssessmentName,
    postAssessmnetName,
  }) => ({
    name,
    description,
    summary,
    tagline,
    imageUrl,
    materialsUrl,
    preAssessmentId: preAssessmentName ?? null,
    postAssessmentId: postAssessmnetName ?? null,
    moduleSkillIds: moduleSkills.map((skill) => skill.value),
  });

  const onSubmit = async (moduleData, e) => {
    setState({ ...state, loading: true });
    const reqData = constructRequest(moduleData);
    const defaultError = `Error occurred while ${isEdit ? 'Updating' : 'Adding new'} module`;
    try {
      const { data, isSuccess } = isEdit
        ? await api.modules.editModule({ id, ...reqData })
        : await api.modules.addModule(reqData);
      if (isSuccess) {
        setState({ ...state, newModuleAdded: true, loading: false });

        if (isEdit)
          dispatch(
            updateModuleInList({
              id,
              ...reqData,
              moduleSkills: (moduleData?.moduleSkills ?? []).map((m) => ({
                id: m.value,
                name: m.label,
              })),
            }),
          );
        else resetForm();
      } else {
        throw new Error(data?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({ ...state, loading: false, newModuleAdded: false, errorMessage: error.message });
    }
  };

  const onCancel = () => {
    resetForm();
    modal.hide();
  };

  return (
    <div>
      {/*Add/Edit Module Form Status*/}
      <div className="ml-4">
        {state.loading && (
          <div>
            <div className="panel__refresh position-relative" style={{ height: 75, width: 20 }}>
              <LoadingIcon />
              <div style={{ width: 200, padding: '23px 40px' }}>
                {isEdit ? 'Updating' : 'Adding new'} module...
              </div>
            </div>
          </div>
        )}
        {(state.newModuleAdded || !!state.errorMessage) && (
          <table className="mt-3">
            <tr>
              <td>
                {state.newModuleAdded ? (
                  <span className="lnr lnr-thumbs-up modal__title-icon success" />
                ) : (
                  <span className="lnr lnr-cross-circle modal__title-icon error" />
                )}
              </td>
              <td className="pl-2">
                {state.newModuleAdded
                  ? `${isEdit ? 'Module Updated' : 'New Module Added'} successfully`
                  : state.errorMessage}
              </td>
            </tr>
          </table>
        )}
      </div>

      {/*Add/Edit Module Form*/}
      <div className="modal__body add-edit-learning-path">
        <form className={`form form--horizontal`} onSubmit={handleSubmit(onSubmit)}>
          <div className="form__form-group">
            <span className="form__form-group-label">Name</span>
            <div className="form__form-group-field">
              <FormField
                name="name"
                control={control}
                component="input"
                errors={errors}
                rules={{
                  required: 'This is required field',
                  pattern: emptyStringPattern,
                }}
                defaultValue=""
                isAboveError={true}
                placeholder="Name of module"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Description</span>
            <div className="form__form-group-field">
              <FormField
                name="description"
                control={control}
                component="textarea"
                errors={errors}
                rules={{
                  required: 'This is required field',
                  pattern: emptyStringPattern,
                }}
                defaultValue=""
                isAboveError={true}
                placeholder="Write description about module"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Summary</span>
            <div className="form__form-group-field">
              <FormField
                name="summary"
                control={control}
                component="textarea"
                /*errors={errors}
                                rules={{
                                    required: 'This is required field',
                                    pattern: emptyStringPattern
                                }}
                                defaultValue=""
                                isAboveError={true}*/
                placeholder="Write summary about module"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Tagline</span>
            <div className="form__form-group-field">
              <FormField
                name="tagline"
                control={control}
                component="input"
                defaultValue=""
                placeholder="Tag line for module"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Image URL</span>
            <div className="form__form-group-field">
              <FormField
                name="imageUrl"
                as="input"
                placeholder="Enter image url"
                control={control}
                defaultValue=""
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Materials URL</span>
            <div className="form__form-group-field">
              <FormField
                name="materialsUrl"
                as="input"
                placeholder="Enter material url"
                control={control}
                defaultValue=""
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Pre-Assessment</span>
            <div className="form__form-group-field">
              <FormField
                control={control}
                name="preAssessmentName"
                as="input"
                clearable
                placeholder="Enter pre assessment"
              />
            </div>
          </div>
          <div className="form__form-group mt-3">
            <span className="form__form-group-label">Post-Assessment</span>
            <div className="form__form-group-field">
              <FormField
                control={control}
                name="postAssessmnetName"
                clearable
                as="input"
                placeholder="Enter post assessment"
              />
            </div>
          </div>
          <div className="form__form-group mt-3">
            <span className="form__form-group-label">Module Skill</span>
            <div className="form__form-group-field">
              <FormField
                control={control}
                name="moduleSkills"
                component={SelectField}
                isMulti
                options={skillOptions}
                placeholder="Select module skill"
                rules={{ required: 'This is required field' }}
                errors={errors}
                isAboveError
              />
            </div>
          </div>

          <ButtonToolbar className="form__button-toolbar modal__footer">
            <Button disabled={state.loading} color="primary" type="submit">
              Submit
            </Button>
            <Button type="button" className="modal_cancel" onClick={onCancel}>
              Close
            </Button>
          </ButtonToolbar>
        </form>
      </div>
    </div>
  );
};

export default ModuleEditForm;
