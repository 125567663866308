import React, { createContext, useContext } from 'react';

const Sunrise25UsersUIContext = createContext();

export function useSunrise25UsersUIContext() {
  return useContext(Sunrise25UsersUIContext);
}

const Sunrise25UsersUIProvider = ({ sunrise25UsersUIEvents, children }) => {
  const value = {
    openViewSunrise25UserDialog: sunrise25UsersUIEvents.openViewSunrise25UserDialog,
  };
  return (
    <Sunrise25UsersUIContext.Provider value={value}>{children}</Sunrise25UsersUIContext.Provider>
  );
};

export default Sunrise25UsersUIProvider;
