import React from 'react';

export function SubcategoryViewBasicDetails({ subcategory }) {
  return (
    <table className="project-summary__info">
      <tbody>
        {/*<tr>
                <th>Name</th>
                <td>{subcategory?.name}</td>
            </tr>*/}
        <tr>
          <th>Description</th>
          <td>{subcategory?.description}</td>
        </tr>
      </tbody>
    </table>
  );
}
