import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { Paper, TableContainer } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import { getColorForWeight } from '@/shared/helpers';

export function SkillViewTabs({ competencies }) {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const columns = [
    { id: 'name', label: 'Name', minWidth: 50, align: 'center' },
    { id: 'description', label: 'Description', minWidth: 100, align: 'center' },
    {
      id: 'associatedDomains',
      label: 'Associated Domains',
      minWidth: 170,
      align: 'center',
      format: (value) => (
        <div className="cell-tags">
          <Button color="primary" size="sm">
            AB Testing
          </Button>
          <Button color="primary" size="sm">
            AB Testing
          </Button>
          <Button color="primary" size="sm">
            AB Testing
          </Button>
          <Button color="success" size="sm" outline>
            <p>
              <EyeIcon style={{ marginTop: 4 }} /> view all{' '}
            </p>
          </Button>
        </div>
      ),
    },
  ];

  const [pageDetails, setPageDetails] = React.useState({
    page: 0,
    isLastPage: false,
    totalPages: 1,
    rowsPerPage: 10,
  });

  const { page, rowsPerPage, totalPages } = pageDetails;

  return (
    <div className="tabs tabs--justify tabs--bordered-bottom overflow-hidden">
      <div className="tabs__wrap">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => toggle('1')}
            >
              Competencies
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => toggle('2')}
            >
              Associated Domains
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '3' })}
              onClick={() => toggle('3')}
            >
              Associated Profiles
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            {competencies?.map((competency) => (
              <Button
                color={getColorForWeight({ weight: +(competency?.weight ?? 0), cssClass: true })}
                size="sm"
              >
                {competency?.name}
              </Button>
            ))}
          </TabPane>
          <TabPane tabId="2">
            <Button color="primary" size="sm">
              AB Testing
            </Button>
            <Button color="warning" size="sm">
              Development
            </Button>
            <Button color="danger" size="sm">
              Devops
            </Button>
            <Button color="primary" size="sm">
              AB Testing
            </Button>
            <Button color="success" size="sm">
              Pen Testing
            </Button>
            <Button color="warning" size="sm">
              Development
            </Button>
            <Button color="danger" size="sm">
              Devops
            </Button>
          </TabPane>
          <TabPane tabId="3">
            <form className="form">
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <MagnifyIcon />
                  </div>
                  <input
                    name="search"
                    type="text"
                    placeholder="Type here to start searching by profile names"
                  />
                </div>
              </div>
            </form>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          <b>{column.label}</b>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {[1, 2, 3, 4, 5].map((i) => (
                      <TableRow hover role="checkbox" tabIndex={-1}>
                        {/*key={row.code}*/}
                        {columns.map((column) => {
                          // const value = row[column.id];
                          const value = 'someValue';
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format ? column.format(value) : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={rowsPerPage * totalPages}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10]}
                page={page}
                onPageChange={null}
                onRowsPerPageChange={null}
              />
            </Paper>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
}
