import React from 'react';
import SkillsTrend from './SkillsTrend';
import { Row } from 'reactstrap';
import Panel from '@/shared/components/Panel';
import ViewGridOutlineIcon from 'mdi-react/ViewGridOutlineIcon';
import ViewListOutlineIcon from 'mdi-react/ViewListOutlineIcon';
import SkillsTrendFilter from './SkillsTrendFilter';
import SkillListGraph from './SkillListGraph';
import './index.scss';

const data = [
  {
    DataLiteracySkills: [
      { name: 'Excel', color: '#42bd99', value: 43 },
      { name: 'Visualizations', color: '#42bd99', value: 30 },
      { name: 'Dashboards', color: '#42bd99', value: 29 },
      { name: 'R Programming', color: '#42bd99', value: 29 },
      { name: 'Java', color: '#42bd99', value: 27 },
      { name: 'Python Programming', color: '#42bd99', value: 27 },
      { name: 'AB Testing', color: '#42bd99', value: 24 },
      { name: 'Data Storytelling & Presentation Skill', color: '#42bd99', value: 22 },
      { name: 'SQL', color: '#42bd99', value: 21 },
      { name: 'Statistics', color: '#42bd99', value: 18 },
      { name: 'Tableau', color: '#589bd6', value: 16 },
      { name: 'Spark', color: '#589bd6', value: 15 },
      { name: 'JavaScript', color: '#589bd6', value: 14 },
      { name: 'NLP', color: '#589bd6', value: 13 },
      { name: 'Unsupervised Machine Learning', color: '#589bd6', value: 13 },
      { name: 'Cloud Technologies', color: '#589bd6', value: 12 },
    ],
  },
];

const skillsTrendData = [
  {
    skillName: 'Excel',
    skillCount: 43,
    skillType: 'Industry',
    employeeVMs: [
      {
        date: null,
        job_title: null,
        name: 'Ipman121',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 0',
      },
      {
        date: null,
        job_title: null,
        name: 'Julian Goh',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 3',
      },
      {
        date: null,
        job_title: null,
        name: 'Barney Rubble',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 1',
      },
      {
        date: null,
        job_title: null,
        name: 'Demodfds User',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 2',
      },
      {
        date: null,
        job_title: null,
        name: 'Mark',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 5',
      },
      {
        date: null,
        job_title: null,
        name: 'Paul',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 4',
      },
      {
        date: null,
        job_title: null,
        name: 'Axiata User2',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 0',
      },
      {
        date: null,
        job_title: null,
        name: 'Emp Loyee',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 1',
      },
    ],
  },
  {
    skillName: 'Visualizations',
    skillCount: 30,
    skillType: 'Industry',
    employeeVMs: [
      {
        date: null,
        job_title: null,
        name: 'Ipman121',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 0',
      },
      {
        date: null,
        job_title: null,
        name: 'Julian Goh',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 3',
      },
      {
        date: null,
        job_title: null,
        name: 'Barney Rubble',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 1',
      },
      {
        date: null,
        job_title: null,
        name: 'Demodfds User',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 2',
      },
      {
        date: null,
        job_title: null,
        name: 'Mark',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 5',
      },
      {
        date: null,
        job_title: null,
        name: 'Paul',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 4',
      },
      {
        date: null,
        job_title: null,
        name: 'Axiata User2',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 0',
      },
      {
        date: null,
        job_title: null,
        name: 'Emp Loyee',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 1',
      },
    ],
  },
  {
    skillName: 'Dashboards',
    skillCount: 29,
    skillType: 'Industry',
    employeeVMs: [
      {
        date: null,
        job_title: null,
        name: 'Ipman121',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 0',
      },
      {
        date: null,
        job_title: null,
        name: 'Julian Goh',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 3',
      },
      {
        date: null,
        job_title: null,
        name: 'Barney Rubble',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 1',
      },
      {
        date: null,
        job_title: null,
        name: 'Demodfds User',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 2',
      },
      {
        date: null,
        job_title: null,
        name: 'Mark',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 5',
      },
      {
        date: null,
        job_title: null,
        name: 'Paul',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 4',
      },
      {
        date: null,
        job_title: null,
        name: 'Axiata User2',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 0',
      },
      {
        date: null,
        job_title: null,
        name: 'Emp Loyee',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 1',
      },
    ],
  },
  {
    skillName: 'R Programming',
    skillCount: 29,
    skillType: 'Industry',
    employeeVMs: [
      {
        date: null,
        job_title: null,
        name: 'Ipman121',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 0',
      },
      {
        date: null,
        job_title: null,
        name: 'Julian Goh',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 3',
      },
      {
        date: null,
        job_title: null,
        name: 'Barney Rubble',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 1',
      },
      {
        date: null,
        job_title: null,
        name: 'Demodfds User',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 2',
      },
      {
        date: null,
        job_title: null,
        name: 'Mark',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 5',
      },
      {
        date: null,
        job_title: null,
        name: 'Paul',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 4',
      },
      {
        date: null,
        job_title: null,
        name: 'Axiata User2',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 0',
      },
      {
        date: null,
        job_title: null,
        name: 'Emp Loyee',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 1',
      },
    ],
  },
  {
    skillName: 'Java',
    skillCount: 27,
    skillType: 'Industry',
    employeeVMs: [
      {
        date: null,
        job_title: null,
        name: 'Ipman121',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 0',
      },
      {
        date: null,
        job_title: null,
        name: 'Julian Goh',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 3',
      },
      {
        date: null,
        job_title: null,
        name: 'Barney Rubble',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 1',
      },
      {
        date: null,
        job_title: null,
        name: 'Demodfds User',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 2',
      },
      {
        date: null,
        job_title: null,
        name: 'Mark',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 5',
      },
      {
        date: null,
        job_title: null,
        name: 'Paul',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 4',
      },
      {
        date: null,
        job_title: null,
        name: 'Axiata User2',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 0',
      },
      {
        date: null,
        job_title: null,
        name: 'Emp Loyee',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 1',
      },
    ],
  },
  {
    skillName: 'Python Programming',
    skillCount: 27,
    skillType: 'Industry',
    employeeVMs: [
      {
        date: null,
        job_title: null,
        name: 'Ipman121',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 0',
      },
      {
        date: null,
        job_title: null,
        name: 'Julian Goh',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 3',
      },
      {
        date: null,
        job_title: null,
        name: 'Barney Rubble',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 1',
      },
      {
        date: null,
        job_title: null,
        name: 'Demodfds User',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 2',
      },
      {
        date: null,
        job_title: null,
        name: 'Mark',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 5',
      },
      {
        date: null,
        job_title: null,
        name: 'Paul',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 4',
      },
      {
        date: null,
        job_title: null,
        name: 'Axiata User2',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 0',
      },
      {
        date: null,
        job_title: null,
        name: 'Emp Loyee',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 1',
      },
    ],
  },
  {
    skillName: 'Data Storytelling & Presentation Skill',
    skillCount: 22,
    skillType: 'Industry',
    employeeVMs: [
      {
        date: null,
        job_title: null,
        name: 'Ipman121',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 0',
      },
      {
        date: null,
        job_title: null,
        name: 'Julian Goh',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 3',
      },
      {
        date: null,
        job_title: null,
        name: 'Barney Rubble',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 1',
      },
      {
        date: null,
        job_title: null,
        name: 'Demodfds User',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 2',
      },
      {
        date: null,
        job_title: null,
        name: 'Mark',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 5',
      },
      {
        date: null,
        job_title: null,
        name: 'Paul',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 4',
      },
      {
        date: null,
        job_title: null,
        name: 'Axiata User2',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 0',
      },
      {
        date: null,
        job_title: null,
        name: 'Emp Loyee',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 1',
      },
    ],
  },
  {
    skillName: 'SQL',
    skillCount: 21,
    skillType: 'Industry',
    employeeVMs: [
      {
        date: null,
        job_title: null,
        name: 'Ipman121',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 0',
      },
      {
        date: null,
        job_title: null,
        name: 'Julian Goh',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 3',
      },
      {
        date: null,
        job_title: null,
        name: 'Barney Rubble',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 1',
      },
      {
        date: null,
        job_title: null,
        name: 'Demodfds User',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 2',
      },
      {
        date: null,
        job_title: null,
        name: 'Mark',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 5',
      },
      {
        date: null,
        job_title: null,
        name: 'Paul',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 4',
      },
      {
        date: null,
        job_title: null,
        name: 'Axiata User2',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 0',
      },
      {
        date: null,
        job_title: null,
        name: 'Emp Loyee',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 1',
      },
    ],
  },
  {
    skillName: 'Python Programming',
    skillCount: 27,
    skillType: 'Industry',
    employeeVMs: [
      {
        date: null,
        job_title: null,
        name: 'Ipman121',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 0',
      },
      {
        date: null,
        job_title: null,
        name: 'Julian Goh',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 3',
      },
      {
        date: null,
        job_title: null,
        name: 'Barney Rubble',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 1',
      },
      {
        date: null,
        job_title: null,
        name: 'Demodfds User',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 2',
      },
      {
        date: null,
        job_title: null,
        name: 'Mark',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 5',
      },
      {
        date: null,
        job_title: null,
        name: 'Paul',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 4',
      },
      {
        date: null,
        job_title: null,
        name: 'Axiata User2',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 0',
      },
      {
        date: null,
        job_title: null,
        name: 'Emp Loyee',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 1',
      },
    ],
  },
  {
    skillName: 'Python Programming',
    skillCount: 27,
    skillType: 'Industry',
    employeeVMs: [
      {
        date: null,
        job_title: null,
        name: 'Ipman121',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 0',
      },
      {
        date: null,
        job_title: null,
        name: 'Julian Goh',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 3',
      },
      {
        date: null,
        job_title: null,
        name: 'Barney Rubble',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 1',
      },
      {
        date: null,
        job_title: null,
        name: 'Demodfds User',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 2',
      },
      {
        date: null,
        job_title: null,
        name: 'Mark',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 5',
      },
      {
        date: null,
        job_title: null,
        name: 'Paul',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 4',
      },
      {
        date: null,
        job_title: null,
        name: 'Axiata User2',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 0',
      },
      {
        date: null,
        job_title: null,
        name: 'Emp Loyee',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 1',
      },
    ],
  },
  {
    skillName: 'Python Programming',
    skillCount: 27,
    skillType: 'Industry',
    employeeVMs: [
      {
        date: null,
        job_title: null,
        name: 'Ipman121',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 0',
      },
      {
        date: null,
        job_title: null,
        name: 'Julian Goh',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 3',
      },
      {
        date: null,
        job_title: null,
        name: 'Barney Rubble',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 1',
      },
      {
        date: null,
        job_title: null,
        name: 'Demodfds User',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 2',
      },
      {
        date: null,
        job_title: null,
        name: 'Mark',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 5',
      },
      {
        date: null,
        job_title: null,
        name: 'Paul',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 4',
      },
      {
        date: null,
        job_title: null,
        name: 'Axiata User2',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 0',
      },
      {
        date: null,
        job_title: null,
        name: 'Emp Loyee',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 1',
      },
    ],
  },
  {
    skillName: 'Python Programming',
    skillCount: 27,
    skillType: 'Industry',
    employeeVMs: [
      {
        date: null,
        job_title: null,
        name: 'Ipman121',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 0',
      },
      {
        date: null,
        job_title: null,
        name: 'Julian Goh',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 3',
      },
      {
        date: null,
        job_title: null,
        name: 'Barney Rubble',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 1',
      },
      {
        date: null,
        job_title: null,
        name: 'Demodfds User',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 2',
      },
      {
        date: null,
        job_title: null,
        name: 'Mark',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 5',
      },
      {
        date: null,
        job_title: null,
        name: 'Paul',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 4',
      },
      {
        date: null,
        job_title: null,
        name: 'Axiata User2',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 0',
      },
      {
        date: null,
        job_title: null,
        name: 'Emp Loyee',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 1',
      },
    ],
  },
  {
    skillName: 'Python Programming',
    skillCount: 27,
    skillType: 'Industry',
    employeeVMs: [
      {
        date: null,
        job_title: null,
        name: 'Ipman121',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 0',
      },
      {
        date: null,
        job_title: null,
        name: 'Julian Goh',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 3',
      },
      {
        date: null,
        job_title: null,
        name: 'Barney Rubble',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 1',
      },
      {
        date: null,
        job_title: null,
        name: 'Demodfds User',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 2',
      },
      {
        date: null,
        job_title: null,
        name: 'Mark',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 5',
      },
      {
        date: null,
        job_title: null,
        name: 'Paul',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 4',
      },
      {
        date: null,
        job_title: null,
        name: 'Axiata User2',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 0',
      },
      {
        date: null,
        job_title: null,
        name: 'Emp Loyee',
        score: null,
        survey_taken_name: null,
        talent_id: 311,
        level: 'Level 1',
      },
    ],
  },
];

const SkillsSection = () => {
  const [filter, setFilter] = React.useState({
    selectedMonth: null,
    selectedYear: null,
    selectedLevel: null,
  });
  const [currentGraph, setCurrentGraph] = React.useState('Grid');
  const dataSkills = [];
  const otherSkills = [];

  const toggleActiveGraph = (value) => {
    setCurrentGraph(value);
  };
  React.useMemo(() => {
    data[0].DataLiteracySkills?.forEach((item) => {
      item.color === '#42bd99'
        ? dataSkills.push({
            name: item?.name,
            y: item.value,
            color: item.color,
          })
        : otherSkills.push({
            name: item?.name,
            y: item.value,
            color: item.color,
          });
    });
  }, [dataSkills]);
  return (
    <Row className="mt-3">
      <Panel lg={12} xl={12} title={'Skill Trend'}>
        <div className="d-flex justify-content-end">
          <div className="chart__option_select">
            <div
              className={`option_change_chart mr-1 ${currentGraph === 'Grid' ? `activeTab` : ``}`}
              onClick={() => toggleActiveGraph('Grid')}
            >
              <ViewGridOutlineIcon color="#2E485C" />
            </div>
            <div
              className={`option_change_chart mr-1 ${currentGraph === 'List' ? `activeTab` : ``}`}
              onClick={() => toggleActiveGraph('List')}
            >
              <ViewListOutlineIcon color="#2E485C" />
            </div>
          </div>
          <SkillsTrendFilter setFilter={setFilter} filter={filter} />
        </div>
        {currentGraph === 'List' ? (
          <SkillListGraph otherSkills={otherSkills} dataSkills={dataSkills} />
        ) : (
          <SkillsTrend chartData={data[0].DataLiteracySkills} skillsTrendData={skillsTrendData} />
        )}
      </Panel>
    </Row>
  );
};

export default SkillsSection;
