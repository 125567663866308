import { sendRequest } from '../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const CP_ENDPOINT = process.env.REACT_APP_ROOT_URL + process.env.REACT_APP_CONTROL_PANEL_ENDPOINT;

const cpOrganizationEndpoints = {
  GET_ORGANIZATION_OPTIONS_LIST: `${CP_ENDPOINT}/getAllOrgnizations`,
  GET_ORGANIZATION_LIST: `${CP_ENDPOINT}/getAllControlledOrgs`,
  GET_ORGANIZATION_LIST_BY_MODULE_ID: `/getOrganizationsByModule`,
  GET_ORGANIZATION_LIST_BY_LP_ID: `/getOrgsByLearningPath`,
  GET_ORGANIZATION_BY_TRAINING_PROVIDER: '/getAllOrgsByTrainingProvider',
};
/**
 * Fetches list of all organization to show in dropdown
 * */
const getOrganizationOptionsList = (filters) => {
  return sendRequest({
    fullUrl: cpOrganizationEndpoints.GET_ORGANIZATION_OPTIONS_LIST,
    method: httpMethod.GET,
    params: filters,
  });
};

const getOrganizationList = ({
  assigned,
  feature,
  moduleId,
  learningPathId,
  trainingProviderId,
  isSortOrderDescending,
  pageNumber,
  pageSize,
  searchText,
  sortBy,
  ...otherFilter
}) => {
  const commonFilters = {
    isSortOrderDescending,
    pageNumber,
    pageSize,
    searchText,
    sortBy,
  };
  if (
    (moduleId || learningPathId || trainingProviderId) &&
    moduleId !== -1 &&
    learningPathId !== -1 &&
    trainingProviderId !== -1
  ) {
    // return getMockOrganizationList();
    if (moduleId && moduleId !== -1)
      return getOrganizationListByModuleId({
        assigned: false,
        moduleId,
        ...commonFilters,
      });
    if (learningPathId && learningPathId !== -1)
      return getOrganizationListByLearningPathId({
        lpId: learningPathId,
        assigned: false,
        ...commonFilters,
        pageNo: pageNumber,
      });
    if (trainingProviderId && trainingProviderId !== -1)
      return getOrganizationListByTrainingProviderId({
        ...commonFilters,
        trainingProviderId: trainingProviderId,
      });
  }
  // Assignment : Assigned=0/Unassigned=1
  // Feature : Chatbot=0/DDO=1/Acceltic=2
  return getDefaultOrganizationList({
    accessStatus: assigned ? 0 : 1,
    feature,
    ...commonFilters,
    ...otherFilter,
  });
};

export default {
  getOrganizationOptionsList,
  getOrganizationList,
};

/************* PRIVATE *****************/
const getDefaultOrganizationList = (filters) =>
  sendRequest({
    fullUrl: cpOrganizationEndpoints.GET_ORGANIZATION_LIST,
    method: httpMethod.GET,
    params: filters,
  });

const getOrganizationListByModuleId = (filters) =>
  sendRequest(
    {
      url: cpOrganizationEndpoints.GET_ORGANIZATION_LIST_BY_MODULE_ID,
      method: httpMethod.GET,
      params: filters,
    },
    process.env.REACT_APP_CONTROL_PANEL_ENDPOINT,
  );

const getOrganizationListByLearningPathId = (filters) =>
  sendRequest(
    {
      url: cpOrganizationEndpoints.GET_ORGANIZATION_LIST_BY_LP_ID,
      method: httpMethod.GET,
      params: {
        ...filters,
        pageNo: filters.pageNumber - 1,
        search: filters.searchText,
      },
    },
    process.env.REACT_APP_CONTROL_PANEL_SS_ENDPOINT,
  );

const getOrganizationListByTrainingProviderId = (filters) =>
  sendRequest(
    {
      url: cpOrganizationEndpoints.GET_ORGANIZATION_BY_TRAINING_PROVIDER,
      method: httpMethod.GET,
      params: {
        ...filters,
      },
    },
    process.env.REACT_APP_CONTROL_PANEL_ENDPOINT,
  );
