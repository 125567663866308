import { sendRequest } from '../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const articleBaseUrl = process.env.REACT_APP_CADS_AI_API + '/post';

export const articleEndpoints = {
  GET_ALL_ARTICLES: '/getAllPosts',
  GET_ARTICLE_BY_ID: '/getPost',
  ADD_ARTICLE: '/addPost',
  EDIT_ARTICLE: '/editPost',
  DEACTIVATE_ARTICLE: '/deactivatePosts',
};

const getArticleList = (filter) => {
  return sendRequest({
    fullUrl: articleBaseUrl + articleEndpoints.GET_ALL_ARTICLES,
    method: httpMethod.GET,
    params: filter,
  });
};

const getArticleById = (articleId) => {
  return sendRequest({
    fullUrl: articleBaseUrl + articleEndpoints.GET_ARTICLE_BY_ID,
    method: httpMethod.GET,
    params: { id: articleId },
  });
};

const addArticle = (article) => {
  return sendRequest({
    fullUrl: articleBaseUrl + articleEndpoints.ADD_ARTICLE,
    method: httpMethod.POST,
    data: article,
  });
};

const updateArticle = (article) => {
  return sendRequest({
    fullUrl: articleBaseUrl + articleEndpoints.EDIT_ARTICLE,
    method: httpMethod.PUT,
    data: article,
  });
};

const deactivateArticle = (id) => {
  return sendRequest({
    fullUrl: articleBaseUrl + articleEndpoints.DEACTIVATE_ARTICLE,
    method: httpMethod.DELETE,
    params: { id: id },
  });
};

export default {
  getArticleList,
  getArticleById,
  addArticle,
  updateArticle,
  deactivateArticle,
};
