import React from 'react';
import { Alert, Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';

import { useForm, Controller } from 'react-hook-form';
import PasswordField from '@/shared/components/form/Password';
import api from '@/utils/apiClient';

const ChangePasswordForm = ({ isAboveError = true }) => {
  const { handleSubmit, reset, control } = useForm();

  const [state, setState] = React.useState({
    changing: true,
  });

  const onSubmit = async (changePasswordRequest) => {
    setState({ changing: true, error: '' });
    try {
      const { data, isSuccess } = await api.auth.changePassword(changePasswordRequest);
      setState({ isSuccess: isSuccess && data?.data, error: data?.messages?.[0], changing: false });
      resetForm();
    } catch (error) {
      console.error('Error occurred while changing password : ', error);
      setState({ isSuccess: false, changing: false, error: error.message });
    }
  };

  const resetForm = () => {
    reset({
      oldPassword: '',
      newPassword: '',
      renewPassword: '',
    });
  };

  return (
    <Col md={12} lg={12} xl={6}>
      <Card>
        <CardBody>
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form login-form w-100">
              <Alert color="danger" isOpen={state.isSuccess === false}>
                {state?.error ?? 'Error occurred while changing passwords'}
              </Alert>
              <Alert color="primary" isOpen={state.isSuccess === true}>
                Password changed successfully !
              </Alert>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Current Password</span>
              <div className="form__form-group-field">
                <Controller
                  name="oldPassword"
                  control={control}
                  render={({ field, fieldState }) => (
                    <PasswordField
                      input={field}
                      meta={{ touched: !!fieldState.error, error: fieldState.error?.message }}
                      placeholder="Current password"
                      isAboveError={isAboveError}
                    />
                  )}
                  rules={{ required: 'This is required field' }}
                  defaultValue=""
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">New Password</span>
              <div className="form__form-group-field">
                <Controller
                  name="newPassword"
                  control={control}
                  render={({ field, fieldState }) => (
                    <PasswordField
                      input={field}
                      meta={{ touched: !!fieldState.error, error: fieldState.error?.message }}
                      placeholder="New password"
                      isAboveError={isAboveError}
                    />
                  )}
                  rules={{ required: 'This is required field' }}
                  defaultValue=""
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Confirm New Password</span>
              <div className="form__form-group-field">
                <Controller
                  name="renewPassword"
                  control={control}
                  render={({ field, fieldState }) => (
                    <PasswordField
                      input={field}
                      meta={{ touched: !!fieldState.error, error: fieldState.error?.message }}
                      placeholder="Re-enter new password"
                      isAboveError={isAboveError}
                    />
                  )}
                  rules={{ required: 'This is required field' }}
                  defaultValue=""
                />
              </div>
            </div>
            <div className="w-100">
              <ButtonToolbar className="form__button-toolbar float-right">
                <Button color="primary" type="submit">
                  Submit
                </Button>
                <Button type="button" onClick={resetForm}>
                  Clear
                </Button>
              </ButtonToolbar>
            </div>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ChangePasswordForm;
