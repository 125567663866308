import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'reactstrap';
import Select from 'react-select';
import TableHead from '@material-ui/core/TableHead';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { clearApplicantFilters, setApplicantFilters } from '../../redux/actions';
import api from '@/utils/apiClient';

export function ApplicantsTableSearchBar() {
  const dispatch = useDispatch();
  const totalApplicantCount = useSelector((state) => state.applicants?.data?.length, shallowEqual);
  const [isSearched, setIsSearched] = useState(false);
  // const [programTypeOptions, setProgramTypeOptions] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [searchProgram, setSearchProgram] = useState([]);
  // const [searchProgramType, setSearchProgramType] = useState([]);

  const searchNameRef = useRef(null);
  const handleSearch = () => {
    dispatch(
      setApplicantFilters({
        searchByName: searchNameRef?.current?.value,
        programId: searchProgram?.value,
      }),
    );
  };

  const handleClear = () => {
    if (searchNameRef?.current?.value) searchNameRef.current.value = '';
    setIsSearched(false);
    // setSearchProgramType([]);
    setSearchProgram([]);
    dispatch(clearApplicantFilters());
  };

  // const getProgramTypeOptions = async () => {
  //   try {
  //     const { data: data, isSuccess } =
  //       await api.programs.getProgramTypeOptions();
  //     if (isSuccess) {
  //       setProgramTypeOptions([]);
  //       data.data.map((item) => {
  //         setProgramTypeOptions((programTypeOptions) => [
  //           ...programTypeOptions,
  //           { value: item.id, label: item.title },
  //         ]);
  //       });
  //     } else {
  //       throw new Error("error while fetching program type");
  //     }
  //   } catch (error) {
  //     console.error("Error inside dashboard program type", error.message);
  //   }
  // };

  const getAllPrograms = async () => {
    try {
      const { data: res, isSuccess } = await api.programs.getAllProgramOptions();
      if (isSuccess) {
        setPrograms([]);
        res.data.map((item) => {
          setPrograms((programs) => [...programs, { value: item.id, label: item.title }]);
        });
      } else {
        throw new Error('error while fetching all programs');
      }
    } catch (error) {
      console.error('Error inside dashboard all programs', error.message);
    }
  };

  // useEffect(() => {
  //   getProgramTypeOptions();
  // }, []);

  useEffect(() => {
    getAllPrograms();
  }, []);

  return (
    <TableHead>
      <div className="table__search">
        <div className="table__search_item">
          <b>Applicant Name</b>
          <div className="form">
            <div className="form__form-group">
              <div className="form__form-group-field">
                <div className="form__form-group-icon" style={{ height: 37 }}>
                  <MagnifyIcon />
                </div>
                <input
                  ref={searchNameRef}
                  className="search-bar-search-text"
                  name="searchText"
                  type="text"
                  placeholder="Search by Applicant Name"
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="table__search_item">
          <b>Program Type</b>
          <div style={{ minWidth: 200 }}>
            <Select
              name="program_type"
              value={searchProgramType}
              onChange={(e) => {
                setSearchProgramType(e);
              }}
              autosize={true}
              options={programTypeOptions}
              clearable={false}
              className="react-select"
              placeholder={"Select Program Type"}
              classNamePrefix="react-select"
            />
          </div>
        </div> */}
        <div className="table__search_item">
          <b>Program</b>
          <div style={{ minWidth: 200 }}>
            <Select
              name="program"
              value={searchProgram}
              onChange={(e) => setSearchProgram(e)}
              autosize={true}
              options={programs}
              clearable={false}
              className="react-select"
              placeholder={'Select Program'}
              classNamePrefix="react-select"
            />
          </div>
        </div>
        <div className="table__search_item mt-3">
          <Button color="primary" onClick={handleSearch}>
            {' '}
            Search{' '}
          </Button>
        </div>
        <div className="table__search_item mt-3">
          <Button color="secondary" onClick={handleClear} outline>
            Clear
          </Button>
        </div>
      </div>

      {isSearched && (
        <div className="ml-1">
          <span>Found {totalApplicantCount} matches</span>
        </div>
      )}
    </TableHead>
  );
}
