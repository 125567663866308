import { useEffect } from 'react';
import Highcharts from 'highcharts';
import Tree from 'highcharts/modules/treemap';
import exporting from 'highcharts/modules/exporting';
import data from 'highcharts/modules/data';
import Heatmap from 'highcharts/modules/heatmap';

Tree(Highcharts);
data(Highcharts);
Heatmap(Highcharts);
exporting(Highcharts);

const ActiveUserCard = () => {
  const initialChart = () => {
    Highcharts.chart('ActiveUserCard', {
      chart: {
        type: 'bar',
      },
      title: {
        text: '',
      },
      xAxis: {
        type: 'category',
      },
      yAxis: {
        gridLineColor: 'transparent',
        min: 0,
        title: {
          align: 'high',
        },
        title: {
          text: null,
        },
        labels: {
          overflow: 'justify',
        },
      },
      tooltip: {
        valueSuffix: ' millions',
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
          },
        },
      },
      colors: ['#7edafb'],
      legend: {
        align: 'center',
        verticalAlign: 'bottom',
        x: 0,
        y: 0,
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: 'Users',
          data: [
            ['India', 1020],
            ['Malaysia', 204],
            ['USA', 725],
            ['Iran', 1234],
            ['Canada', 550],
          ],
        },
      ],
    });
  };

  useEffect(() => {
    initialChart();
  }, []);

  return <div id="ActiveUserCard" className="SkillsTrend" />;
};

export default ActiveUserCard;
