import { paths } from '@/shared/helpers/paths';
import React, { Suspense } from 'react';
import Loading from '@/shared/components/Loading';
import { Route, Switch } from 'react-router-dom';
import Organizations from './Organizations';
import Users from './Users';
import Emails from './ManageEmail';
import CadsAIDashboard from './Dashboard';
import ManageDataStar from './ManageDataStar';
import ManageLearning from './ManageLearning';
import ManageHome from './ManageHome';
import ManageOpportunities from './ManageOpportunities';
import Helps from './Help';
import Employees from './Employees';
import OrderHistory from './OrderHistory';
import Ddo from './DDO';

export default function CadsAI() {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        {/*BASE*/}
        <Route path={paths.CADS_AI.DASHBOARD} component={CadsAIDashboard} />
        <Route path={paths.CADS_AI.USERS} component={Users} />
        <Route path={paths.CADS_AI.ORGANIZATION} component={Organizations} />
        <Route path={paths.CADS_AI.EMPLOYEES} component={Employees} />
        <Route path={paths.CADS_AI.ORDER_HISTORY} component={OrderHistory} />
        <Route path={paths.CADS_AI.DDO} component={Ddo} />
        <Route path={paths.CADS_AI.HELP} component={Helps} />
        <Route path={paths.CADS_AI.EMAIL} component={Emails} />

        {/*Manage Home*/}
        <Route path={paths.CADS_AI.HOME.BASE} component={ManageHome} />

        {/*Manage Learning*/}
        <Route path={paths.CADS_AI.LEARNING.BASE} component={ManageLearning} />

        {/*Manage Data Star*/}
        <Route path={paths.CADS_AI.DATA_STAR.BASE} component={ManageDataStar} />

        {/*Manage Opportunities*/}
        <Route path={paths.CADS_AI.OPPORTUNITIES.BASE} component={ManageOpportunities} />
      </Switch>
    </Suspense>
  );
}
