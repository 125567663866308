export const USERS = 'USERS';
export const COHORT = 'COHORT';
export const ORGANIZATION = 'ORGANIZATION';
export const controlPanelTabs = {
  USERS: { id: USERS, label: 'Users' },
  //COHORT: { id: COHORT, label: 'Cohort' },
  ORGANIZATION: { id: ORGANIZATION, label: 'Organization' },
};

export const cadsAITabs = {
  USERS: { id: USERS, label: 'Users' },
  COHORT: { id: COHORT, label: 'Cohort' },
  ORGANIZATION: { id: ORGANIZATION, label: 'Organization' },
};

export const assignedModuleORLPTabs = {
  USERS: { id: USERS, label: 'Users' },
  ORGANIZATION: { id: ORGANIZATION, label: 'Organization' },
};
export const assignedModuleORTPTabs = {
  ORGANIZATION: { id: ORGANIZATION, label: 'Organization' },
};

export const assignedCourseORLPTabs = {
  USERS: { id: USERS, label: 'Users' },
  COHORT: { id: COHORT, label: 'Cohort' },
  ORGANIZATION: { id: ORGANIZATION, label: 'Organization' },
};

export const CP_PRODUCTS = {
  ACCELTIC: { id: 2, name: 'Acceltic' },
  DDO: { id: 1, name: 'DDO' },
  CHATBOT: { id: 0, name: 'Chatbot' },
  MODULE: { id: 4, name: 'Module' },
  LEARNING_PATH: { id: 5, name: 'Learning Path' },
  TRAINING_PROVIDER: { id: 6, name: 'Training Provider' },
};

export const CADS_AI_PRODUCTS = {
  LEARNING_PATH: { id: 1, name: 'Learning Path' },
  COURSE: { id: 2, name: 'Course' },
};

export const ASSESSMENT_TYPES = {
  GENERAL_ASSESSMENT: 'General Assessment',
  DDO: 'DDO',
  SMART_SKILLS: 'Smart Skills',
  KNOWLEDGE_BASE_ASSESSMENT: 'Knowledge Based',
  DATA_LITERACY_ASSESSMENT: 'Data Literacy Assessment'
};

export const toolbarOptions = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block', 'image'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }],
    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
  ],
};
