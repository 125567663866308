import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loading from '@/shared/components/Loading';
import { paths } from '@/shared/helpers/paths';
import { LearningPathPage } from './components/LearningPathPage';

import './index.scss';
export const learningPathBaseUrl = paths.CADS_AI.LEARNING.LEARNING_PATH;

export default function LearningPaths() {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path={learningPathBaseUrl} component={LearningPathPage} />
      </Switch>
    </Suspense>
  );
}
