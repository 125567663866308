import cadsAIProgramsReducer from '@/containers/Cads-AI/ManageDataStar/Programs/redux/reducer';
import cadsAIUsersReducer from '@/containers/Cads-AI/Users/redux/reducer';
import cadsAIAnnouncementsReducer from '@/containers/Cads-AI/ManageHome/Announcements/redux/reducer';
import cadsAINotificationReducer from '@/containers/Cads-AI/ManageHome/Notifications/redux/reducer';
import cadsAIArticlesReducer from '@/containers/Cads-AI/ManageHome/Articles/redux/reducer';
import cadsAIEventsReducer from '@/containers/Cads-AI/ManageHome/Events/redux/reducer';
import cadsAISponsorshipReducer from '@/containers/Cads-AI/ManageHome/Sponsorship/redux/reducer';
import cadsAIQAReducer from '@/containers/Cads-AI/ManageHome/Q&A/redux/reducer';
import cadsAIEmailReducer from '@/containers/Cads-AI/ManageEmail/redux/reducer';
import cadsAIApplicantsReducer from '@/containers/Cads-AI/ManageDataStar/Applicants/redux/reducer';
import cadsAISponsorsReducer from '@/containers/Cads-AI/ManageDataStar/Sponsors/redux/reducer';
import cadsAIDSCohortReducer from '@/containers/Cads-AI/ManageDataStar/DSCohort/redux/reducer';
import cadsAIJobReducer from '@/containers/Cads-AI/ManageOpportunities/Job/redux/reducer';
import cadsAIAssignPageReducer from '@/containers/Cads-AI/ManageLearning/Assign/redux/reducer';
import cadsAIReducer from '@/containers/Cads-AI/redux/reducer';
import cadsAILearningPathReducer from '@/containers/Cads-AI/ManageLearning/LearningPath/redux/reducer';
import cadsAITrainingProvidersReducer from '@/containers/Cads-AI/ManageLearning/TrainingProviders/redux/reducer';
import cadsAIModuleReducer from '@/containers/Cads-AI/ManageLearning/Module/redux/reducer';
import cadsAICourseReducer from '@/containers/Cads-AI/ManageLearning/Course/redux/reducer';
import cadsAIAssignmentUserReducer from '@/containers/Cads-AI/redux/user/reducer';
import cadsAIOrgsReducer from '@/containers/Cads-AI/Organizations/redux/reducer';
import cadsAIAssignmentOrgsReducer from '@/containers/Cads-AI/redux/organization/reducer';
import cadsAIAssignmentCohortReducer from '@/containers/Cads-AI/redux/cohort/reducer';
import cadsAIHelpReducer from '@/containers/Cads-AI/Help/redux/reducer';
import cadsAISpamRequestsReducer from '@/containers/Cads-AI/ManageHome/SpamRequests/redux/reducer';
import cadsAIEmployeesReducer from '@/containers/Cads-AI/Employees/redux/reducer';
import cadsAIOrdersHistoryReducer from '@/containers/Cads-AI/OrderHistory/redux/reducer';
import cadsAIDdoReducer from '@/containers/Cads-AI/DDO/redux/reducer';

export const CadsAIReducers = {
  cadsAI: cadsAIReducer,
  cadsAIOrgs: cadsAIOrgsReducer,
  cadsAIAssignmentCohort: cadsAIAssignmentCohortReducer,
  cadsAIAssignmentOrgs: cadsAIAssignmentOrgsReducer,
  cadsAIModule: cadsAIModuleReducer,
  cadsAICourse: cadsAICourseReducer,
  cadsAIAssignPage: cadsAIAssignPageReducer,
  cadsAILearningPaths: cadsAILearningPathReducer,
  cadsAIPrograms: cadsAIProgramsReducer,
  cadsAIAssignmentUser: cadsAIAssignmentUserReducer, //TODO: fix ambiguous namings
  cadsAIUserData: cadsAIUsersReducer,
  cadsAIAnnouncements: cadsAIAnnouncementsReducer,
  cadsAINotifications: cadsAINotificationReducer,
  cadsAIArticles: cadsAIArticlesReducer,
  cadsAIEvents: cadsAIEventsReducer,
  cadsAISponsorships: cadsAISponsorshipReducer,
  cadsAIQAData: cadsAIQAReducer,
  cadsAIEmails: cadsAIEmailReducer,
  cadsAIDSCohorts: cadsAIDSCohortReducer,
  cadsAIApplicants: cadsAIApplicantsReducer,
  cadsAISponsor: cadsAISponsorsReducer,
  cadsAIJobs: cadsAIJobReducer,
  cadsAITrainingProviders: cadsAITrainingProvidersReducer,
  cadsAIHelps: cadsAIHelpReducer,
  cadsAISpamRequests: cadsAISpamRequestsReducer,
  cadsAIEmployees: cadsAIEmployeesReducer,
  cadsAIOrdersHistory: cadsAIOrdersHistoryReducer,
  cadsAIDdo: cadsAIDdoReducer
};
