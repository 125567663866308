import { handleActions } from 'redux-actions';
import {
  fetchSpamRequestList,
  setSpamRequestError,
  setSpamRequestFilters,
  setSpamRequestList,
} from './actions';

const defaultState = {
  loading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    search: '',
    isSortOrderDescending: false,
    status: null,
  },
  totalRecords: 0,
  data: [],
  options: [],
  error: null,
};

export default handleActions(
  {
    [fetchSpamRequestList](state) {
      return {
        ...state,
        loading: true,
        data: [],
      };
    },
    [setSpamRequestList](state, { payload }) {
      return {
        ...state,
        loading: false,
        data: payload?.data?.records,
        totalRecords: payload?.data?.totalRecords,
        error: null,
      };
    },
    [setSpamRequestError](state, { payload }) {
      return {
        ...state,
        data: [],
        loading: false,
        totalPages: defaultState.totalPages,
        error: payload?.message,
      };
    },
    [setSpamRequestFilters](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
        checkedHelpIds: [],
      };
    },
  },
  defaultState,
);
