import React from 'react';
import { Col, Row } from 'reactstrap';
import { Field } from '@/shared/components/Field';
import moment from 'moment';
import Panel from '@/shared/components/Panel';

const CompletedAssessments = ({ userDetails }) => {
  return (
    <div className="applicant_panel_card">
      <h4 className="pl-4 mb-4 mt-2">Completed Assessment Details</h4>
      {userDetails?.assessments?.map?.((assessment, idx) => (
        <Panel title={assessment.completedAssessmentName} key={idx}>
          <Row className="pt-2">
            <Col md={4} xl={4} lg={4}>
              <Field
                title={'Assessment Name'}
                iconDisable={true}
                fieldValue={assessment?.completedAssessmentName ?? '-'}
              />
            </Col>
            <Col md={4} xl={4} lg={4}>
              <Field
                title={'Score'}
                iconDisable={true}
                fieldValue={`${Math.round(assessment?.score)}%`}
              />
            </Col>
            <Col md={4} xl={4} lg={4}>
              <Field
                title={'Completion Date'}
                iconDisable={true}
                fieldValue={
                  assessment?.completionDate
                    ? moment(assessment?.completionDate).format('DD/MM/yyyy')
                    : '-'
                }
              />
            </Col>
          </Row>
        </Panel>
      ))}
    </div>
  );
};

export default CompletedAssessments;
