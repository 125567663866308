import { sendRequest } from '../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const qaBaseUrl = process.env.REACT_APP_CADS_AI_API + process.env.REACT_APP_CADS_AI_QA_ENDPOINT;

export const qaEndpoints = {
  GET_ALL_QA: '/getAllQuestions',
  GET_QA_BY_ID: '/getQA',
  DEACTIVATE_QA: '/deactivateQuestions',
  DELETE_REPLY: '/deactivateReply',
  GET_QUESTIONS_BY_FILTER: '/getQuestionsByFilter',
  PIN_NEW_QUESTIONS: '/addQuestionOrderMapping',
  UNPIN_QUESTION: '/removeQuestionOrderMapping',
  GET_POSTED_BY_USER_LIST: '/getQuestionAuthorsList',
  UPDATE_PINNED_QUESTION: '/updateQuestionOrderMapping'
};

const getQAList = (filter) => {
  return sendRequest({
    fullUrl: qaBaseUrl + qaEndpoints.GET_ALL_QA,
    method: httpMethod.GET,
    params: filter,
  });
};

const getQAById = (id) => {
  return sendRequest({
    fullUrl: qaBaseUrl + qaEndpoints.GET_QA_BY_ID,
    method: httpMethod.GET,
    params: { id: id },
  });
};

const deleteQA = (id) => {
  return sendRequest({
    fullUrl: qaBaseUrl + qaEndpoints.DEACTIVATE_QA,
    method: httpMethod.DELETE,
    params: { id: id },
  });
};

const deleteReply = (id) => {
  return sendRequest({
    fullUrl: qaBaseUrl + qaEndpoints.DELETE_REPLY,
    method: httpMethod.DELETE,
    params: {id: id},
  });
};

const getPinnedQuestions = () => {
  // return getMockPinnedQuestions();
  return sendRequest({
    fullUrl: qaBaseUrl + qaEndpoints.GET_QUESTIONS_BY_FILTER,
    method: httpMethod.GET,
    params: {
      isPinByAdmin: true,
      pageNumber: 1,
      pageSize: 1000, //TODO: update this api to have all question without pagination
    }
  });
};

const pinNewQuestions = (questionIds) => {
  return sendRequest({
    fullUrl: qaBaseUrl + qaEndpoints.PIN_NEW_QUESTIONS,
    method: httpMethod.POST,
    data: {questionId: questionIds}
  })
}

const updatePinnedQuestion = (oldQueId, newQueId, order) => {
  return sendRequest({
    fullUrl: qaBaseUrl + qaEndpoints.UPDATE_PINNED_QUESTION,
    method: httpMethod.PUT,
    params: {
      oldQuestionId: oldQueId,
      newQuestionId: newQueId,
      sequence: order
    }
  });
}

const unpinQuestion = (questionId) => {
  return sendRequest({
    fullUrl: qaBaseUrl + qaEndpoints.UNPIN_QUESTION,
    method: httpMethod.DELETE,
    params: {questionIds: questionId}
  })
}

const getPostedByUserList = () => {
  return sendRequest({
    fullUrl: qaBaseUrl + qaEndpoints.GET_POSTED_BY_USER_LIST,
    method: httpMethod.GET
  })
}

export default {
  getQAList,
  deleteQA,
  getQAById,
  deleteReply,
  getPinnedQuestions,
  pinNewQuestions,
  unpinQuestion,
  getPostedByUserList,
  updatePinnedQuestion,
};


const getMockPinnedQuestions = () =>
    new Promise((resolve, reject) => {
      setTimeout(
          () =>
              resolve({
                isSuccess: true,
                data: {
                  status: 200,
                  messages: [''],
                  data: [
                    {questionId: 1, questionText: "Pinned Mock Question 1", order: 1, postedBy: "Terry Shaw"},
                    {questionId: 3, questionText: "Pinned Mock Question 3", order: 3, postedBy: "Tony Cads"},
                    {questionId: 2, questionText: "Pinned Mock Question 2", order: 2, postedBy: "Kacey Turner"},
                  ],
                },
              }),
          500,
      );
    });
