import React, { memo } from 'react';
import FormField from '@/shared/components/form/hook/FormField';
import renderDropZoneField from '@/shared/components/form/DropZone';
import { SelectField } from '@/shared/components/form/Select';
import Collapse from '@/shared/components/Collapse';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { setAssessmentConfig } from '../redux/actions';
import { logoSizeOptions, logoPositions } from '../options';
import { convertImageFileToBase64 } from '@/shared/helpers';

const LogoDetailsCollapse = ({ logo: logoDetails }) => {
  const dispatch = useDispatch();
  const { control, errors, setValue } = useForm();

  React.useEffect(() => {
    if (logoDetails) {
      setValue('image', logoDetails.image);
      setValue('size', logoDetails.size);
      setValue('position', logoDetails.position);
    }
  }, [logoDetails]);

  const handleLogoPropsChange = async (fieldName, fieldValue) => {
    setValue(fieldName, fieldValue);
    dispatch(
      setAssessmentConfig({
        logo: {
          ...logoDetails,
          [fieldName]: fieldValue,
          imageBase64:
            fieldName === 'image'
              ? await convertImageFileToBase64(fieldValue?.[0])
              : logoDetails.imageBase64,
        },
      }),
    );
  };

  return (
    <Collapse title="Logo">
      <div className="form">
        <div className="form__form-group w-50">
          <span className="form__form-group-label w-25">Logo Image (optional)</span>
          <span className="form__form-group-label text-right w-75">
            only JPG, JPEG, GIF or PNG accepted, max size: 2MB
          </span>
          <div className="form__form-group-field">
            <FormField
              name="image"
              control={control}
              onChange={(file) => handleLogoPropsChange('image', file)}
              component={renderDropZoneField}
              defaultValue={logoDetails.image}
            />
          </div>
        </div>
        <div style={{ marginLeft: 30 }}>
          <div className="form__form-group" style={{ minWidth: 200 }}>
            <span className="form__form-group-label">Logo Size</span>
            <div className="form__form-group-field">
              <FormField
                control={control}
                name="size"
                component={SelectField}
                options={logoSizeOptions}
                onChange={(logoSize) => handleLogoPropsChange('size', logoSize)}
                placeholder="Select logo size"
                defaultValue={logoDetails.size}
                rules={{ required: 'This is required field' }}
                errors={errors}
                isAboveError
              />
            </div>
          </div>
          <div className="form__form-group" style={{ minWidth: 200 }}>
            <span className="form__form-group-label">Logo Position</span>
            <div className="form__form-group-field">
              <FormField
                control={control}
                name="position"
                component={SelectField}
                options={logoPositions}
                onChange={(logoPosition) => handleLogoPropsChange('position', logoPosition)}
                placeholder="Select logo position"
                defaultValue={logoDetails.position}
                rules={{ required: 'This is required field' }}
                errors={errors}
                isAboveError
              />
            </div>
          </div>
        </div>
      </div>
    </Collapse>
  );
};

export default memo(LogoDetailsCollapse);
