import React, { createContext, useContext } from 'react';

const EmployeesUIContext = createContext();

export function useEmployeesUIContext() {
  return useContext(EmployeesUIContext);
}

export function EmployeesUIProvider({ employeeUIEvents, children }) {
  const value = {
    openViewEmployeesDialog: employeeUIEvents.openViewEmployeesDialog,
    openNewEmployeesDialog: employeeUIEvents.openNewEmployeesDialog,
    openEditEmployeesDialog: employeeUIEvents.openEditEmployeesDialog,
    openDeleteEmployeesDialog: employeeUIEvents.openDeleteEmployeesDialog,
    openEmployeeQRDialog: employeeUIEvents.openEmployeeQRDialog,
  };

  return <EmployeesUIContext.Provider value={value}>{children}</EmployeesUIContext.Provider>;
}
