import React, { Fragment, useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import { Button } from 'reactstrap';
import { useEventUIContext } from '../EventsUIProvider';
import EventsTableColumns, { EventsColumns } from './EventsTableColumns';
import { EventsTableSearchBar } from './EventsTableSearchBar';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getEventList, setEventFilters, toggleCheckedItemById } from '../../redux/actions';
import SanitizeHTML from '@/shared/components/SanitizeHTML';
import EyeIcon from 'mdi-react/EyeIcon';
import PencilIcon from 'mdi-react/PencilIcon';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { defaultPaginationProps } from '@/shared/helpers';
import { FormGroup, Input } from 'reactstrap';
import DeleteIcon from 'mdi-react/DeleteIcon';

const EventsTable = () => {
  const eventsUIContext = useEventUIContext();
  const dispatch = useDispatch();
  const {
    data: events,
    error,
    filters,
    loading,
    totalRecords,
    checkedEventsIds,
  } = useSelector((state) => state.cadsAIEvents, shallowEqual);
  const {
    pageSize: rowsPerPage,
    pageNumber: page,
    sortBy: orderBy,
    isSortOrderDescending,
  } = filters;

  const [columns, setColumns] = useState(0);

  useEffect(() => {
    setColumns(EventsColumns.length);
  }, [EventsColumns.length]);

  React.useEffect(() => {
    dispatch(getEventList(filters));
  }, [filters]);

  const handleRequestSort = (event, property) => {
    dispatch(
      setEventFilters({
        sortBy: property,
        isSortOrderDescending: !isSortOrderDescending,
      }),
    );
  };

  const renderActionButtons = (eventId) => (
    <div className="actions">
      <Button
        className="icon mb-0"
        color="success"
        outline
        onClick={() => eventsUIContext.openViewEventDialog(eventId)}
      >
        <p>
          <EyeIcon /> View
        </p>
      </Button>
      <Button
        className="icon mb-0"
        color="primary"
        outline
        onClick={() => eventsUIContext.openEditEventDialog(eventId)}
      >
        <p>
          <PencilIcon /> Edit
        </p>
      </Button>
      <Button
        className="icon mb-0"
        color="danger"
        outline
        onClick={() => eventsUIContext.openDeleteEventDialog(eventId)}
      >
        <p>
          <DeleteIcon /> Remove
        </p>
      </Button>
    </div>
  );

  const handleChangePage = (event, currentPage) => {
    dispatch(setEventFilters({ pageSize: rowsPerPage, pageNumber: currentPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setEventFilters({ pageSize: +event.target.value, pageNumber: 1 }));
  };

  const emptyRows =
    page === Math.ceil(totalRecords / rowsPerPage) ? rowsPerPage - events.length : 0;

  return (
    <Fragment>
      <div className="material-table__wrap">
        <EventsTableSearchBar />
        <Table className="material-table">
          <EventsTableColumns
            order={isSortOrderDescending ? 'desc' : 'asc'}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rowsPerPage}
          />
          <TableBody>
            {(loading || !!error) && (
              <TableRow>
                <TableCell colSpan={columns}>
                  {loading && (
                    <div className={'text-center overflow-hidden'}>
                      <div className="panel__refresh position-relative" style={{ height: 75 }}>
                        <LoadingIcon />
                      </div>
                    </div>
                  )}
                  <div className={'text-center'}>
                    <p className={!!error ? 'm-5' : ''}>{error || 'Loading events...'}</p>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {!!!events.length && !loading && !error && (
              <TableRow style={{ height: 120 }}>
                <TableCell colSpan={columns} align="center">
                  {filters.status === null && filters.search === ''
                    ? 'No Events'
                    : 'No Events with applied filters'}
                </TableCell>
              </TableRow>
            )}
            {events.map((event) => {
              return (
                <TableRow className="material-table" tabIndex={-1} key={event?.id}>
                  <TableCell align="center" className="material-table__cell">
                    <FormGroup>
                      <Input
                        type="checkbox"
                        value={event?.id}
                        defaultChecked={checkedEventsIds.includes(event?.id)}
                        onChange={(e) => dispatch(toggleCheckedItemById(+e.target.value))}
                      />
                    </FormGroup>
                  </TableCell>
                  <TableCell className="material-table__cell" align="center">
                    <img className={'module_logo_image'} src={event.imageUrl} alt={'Event Image'} />
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {event.title}
                  </TableCell>
                  <TableCell
                    align="center"
                    className="material-table__cell"
                    style={{ minWidth: 250 }}
                  >
                    {renderActionButtons(event?.id)}
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={columns} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        component={defaultPaginationProps.component}
        backIconButtonProps={defaultPaginationProps.backIconButton}
        nextIconButtonProps={defaultPaginationProps.nextIconButton}
        rowsPerPageOptions={defaultPaginationProps.rowsPerPage}
        className={defaultPaginationProps.className}
        dir={defaultPaginationProps.dir}
      />
    </Fragment>
  );
};

export default EventsTable;
