import React, { useEffect, useState } from 'react';
import LoadingIcon from 'mdi-react/LoadingIcon';
import api from '@/utils/apiClient';
import { useProgramsUIContext } from '../ProgramsUIProvider';
import ProgramWizardForm from './Program-Wizard-Form';
import { convertImageFileToBase64, convertUrlToFile } from '@/shared/helpers';
import moment from 'moment';
import { isEmpty } from 'lodash';

const ProgramEditDialogBody = ({ modal, isEdit, id }) => {
  const programsUIContext = useProgramsUIContext();

  const initialValues = {
    /*Step 1*/
    title: '',
    code: '',
    description: '',
    activationStatus: '',
    startDate: null,
    endDate: null,
    tags: '',
    limitations: '',
    logoUrl: '',
    backgroundUrl: '',
    posterUrl: '',
    /*Step 2*/
    assessmentList: [],
    /*Step 3*/
    learningList: [],
    /*Step 4*/
    sponsorList: [],
  };

  const [data, setData] = useState(initialValues);

  const [state, setState] = useState({
    loading: false,
    fetchingProgram: false,
    newProgramAdded: false,
    errorMessage: '',
  });

  useEffect(() => {
    if (isEdit && id) {
      setDefaultData(+id);
    }
  }, [isEdit, id]);

  const setDefaultData = async (programId) => {
    try {
      const { data: res, isSuccess } = await api.cadsAIPrograms.getProgramById(programId);
      if (id && isSuccess) {
        const program = res.data;
        setData({
          title: program.title ?? '',
          code: program?.code ?? '',
          description: program.description ?? '',
          activationStatus: program?.status ? 'activate' : 'deactivate',
          startDate: program?.startDate ? moment(program?.startDate)?.format('yyyy-MM-DD') : null,
          endDate: program?.endDate ? moment(program?.endDate)?.format('yyyy-MM-DD') : null,
          tags: program?.tags ?? '',
          limitations: program?.limitations ?? '',
          //TODO: remove System.Bytes condition once backend is fixed
          logoUrl:
            !isEmpty(program?.logoImgUrl) && program?.logoImgUrl !== 'System.Byte[]'
              ? [await convertUrlToFile(program?.logoImgUrl)]
              : '',
          backgroundUrl:
              !isEmpty(program?.bgImgUrl) && program?.bgImgUrl !== 'System.Byte[]'
                  ? [await convertUrlToFile(program?.bgImgUrl)]
                  : '',
          posterUrl:
              !isEmpty(program?.mainImgUrl) && program?.mainImgUrl !== 'System.Byte[]'
                  ? [await convertUrlToFile(program?.mainImgUrl)]
                  : '',
          assessmentList: program?.assessmentList ?? [],
          learningList: program?.learningList ?? [],
          sponsorList: program?.sponsorList ?? [],
          programFields: JSON.parse(program?.programFields ?? JSON.stringify({fields: [], defaultFields: []})),
          cohortList: JSON.parse(program?.cohortList ?? JSON.stringify([]))
        });
      }
    } catch ({ message }) {
      console.error('Program edit error : ', message);
      setState({
        ...state,
        errorMessage: 'Error while getting existing program details',
      });
    }
  };

  const constructApiRequestData = async (program) => {
    let base64Logo = (await convertImageFileToBase64(program?.logoUrl?.[0])) ?? null;
    let base64BackgroundImg = (await convertImageFileToBase64(program?.backgroundUrl?.[0])) ?? null;
    let base64PosterImg = (await convertImageFileToBase64(program?.posterUrl?.[0])) ?? null;

    base64Logo = base64Logo ? base64Logo?.split(',')?.[1] : null;
    base64BackgroundImg = base64BackgroundImg ? base64BackgroundImg?.split(',')?.[1] : null;
    base64PosterImg = base64PosterImg ? base64PosterImg?.split(',')?.[1] : null;

    return {
      id: id ?? null,
      title: isEmpty(program?.title) ? null : program?.title,
      code: isEmpty(program?.code) ? null : program?.code,
      description: isEmpty(program?.description) ? null : program?.description,
      logoUrl: base64Logo,
      backgroundUrl: base64BackgroundImg,
      posterUrl: base64PosterImg,
      startDate: isEmpty(program?.startDate) ? null : program?.startDate,
      endDate: isEmpty(program?.endDate) ? null : program?.endDate,
      tags: [{
        tags: isEmpty(program?.tags) ? null : program.tags,
      }],
      limitations: [{
        limitations: isEmpty(program?.limitations) ? null : program?.limitations,
      }],
      programStatusId: program?.programStatus?.id ?? null,
      sponsorDetails:
          program?.sponsorList?.map((s) => ({
            ...s,
            id: s.action === 'added' ? null : s.id,
            sponsorId: s.id ?? null,
            sortOrder: s.sortOrder ?? 0,
          })) ?? [],
      learningDetails:
          program?.learningList?.map((ll) => ({
          id: ll.action === 'added' ? null : ll.id,
          learningPathId: ll?.learningPath?.id ?? null,
          moduleId: ll.module?.id ?? null,
          courseId: ll.course?.id ?? null,
          description: ll.description,
          isMandatory: ll.isMandatory,
            sortNumber: ll.sortNumber ?? 0,
            action: ll.action,
          })) ?? [],
      assessmentDetails:
          program?.assessmentList?.map((a) => ({
            ...a,
            id: a.action === 'added' ? null : a.id,
            assessmentId: a.id ?? null,
            sortOrder: a.sortOrder ?? 0,
          })) ?? [],
      programFields: JSON.stringify(program?.programFields ?? {fields: [], defaultFields: []}),
      cohortList: JSON.stringify(program?.cohortList ?? []),
    };
  };
  const handleAddEditProgram = async (programData) => {
    setState({ ...state, loading: true });
    const newProgramData = await constructApiRequestData(programData);
    const defaultError = `Error occurred while ${isEdit ? 'Updating' : 'Adding new'} program`;
    try {
      const { data: res, isSuccess } = isEdit
        ? await api.cadsAIPrograms.updateProgram(newProgramData)
        : await api.cadsAIPrograms.addProgram(newProgramData);
      if (isSuccess) {
        setState({ ...state, newProgramAdded: true, loading: false });
        if (!isEdit) setData(initialValues);
        programsUIContext.openViewProgramsDialog(res?.data?.id);
      } else {
        throw new Error(data?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({
        ...state,
        loading: false,
        newProgramAdded: false,
        errorMessage: error.message,
      });
    }
  };

  return (
    <div>
      {/*Add/Edit Program Form Status*/}
      <div className="ml-4">
        {state.loading && (
          <div>
            <div className="panel__refresh position-relative" style={{ height: 75, width: 20 }}>
              <LoadingIcon />
              <div style={{ width: 200, padding: '23px 30px' }}>
                {isEdit ? 'Updating' : 'Adding new'} program...
              </div>
            </div>
          </div>
        )}
        {(state.newProgramAdded || !!state.errorMessage) && (
          <>
            <table className="mt-3">
              <tr>
                <td>
                  {state.newProgramAdded ? (
                    <span className="lnr lnr-thumbs-up modal__title-icon success" />
                  ) : (
                    <span className="lnr lnr-cross-circle modal__title-icon error" />
                  )}
                </td>
                <td className="pl-2">
                  {state.newProgramAdded
                    ? `${isEdit ? 'Program Updated' : 'New Program Added'} successfully`
                    : state.errorMessage}
                </td>
              </tr>
            </table>
          </>
        )}
      </div>

      {/*Add/Edit Program Form*/}
      <ProgramWizardForm
        onSubmit={handleAddEditProgram}
        data={data}
        setData={setData}
        modal={modal}
        isEdit={isEdit}
        programId={id ? +id : null}
        state={state}
        setState={setState}
      />
    </div>
  );
};

export default ProgramEditDialogBody;
