import React from 'react';
import { Redirect } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { paths } from '@/shared/helpers/paths';
import MainWrapper from '../MainWrapper';
import LogIn from '@/containers/App/Account/Auth/Login/index';
import ChangePassword from '@/containers/App/Account/Auth/ChangePassword';
import ForgotPassword from '@/containers/App/Account/Auth/ForgotPassword';
import ResetPassword from '@/containers/App/Account/Auth/ResetPassword';
import WrappedRoutes from './WrappedRoutes.jsx';

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/" render={() => <Redirect to={paths.DASHBOARD} />} />
        <Route exact path={paths.LOGIN} component={LogIn} />
        <Route exact path={paths.FORGOT_PASSWORD} component={ForgotPassword} />
        <Route exact path={paths.RESET_PASSWORD} component={ResetPassword} />
        <Route exact path={paths.CHANGE_PASSWORD} component={ChangePassword} />
        <Route path="/" component={WrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
