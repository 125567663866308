import React, { useState } from 'react';
import { Col, Card, Row, CardBody } from 'reactstrap';
import StepOne from './steps/StepOne';
import StepTwo from './steps/StepTwo';
import { useDispatch } from 'react-redux';
import { clearCheckedTrainingProvider } from '@/containers/Control-Panel/TrainingProvider/redux/actions';
import {
  setIsFromTrainingProvider,
  uncheckAllItem,
} from '@/containers/Control-Panel/Assign/redux/actions';
import './index.scss';

const AssignTrainingProviderWizard = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    // initialization
    dispatch(setIsFromTrainingProvider(true));
    // destruction
    return () => {
      dispatch(setIsFromTrainingProvider(false));
      dispatch(clearCheckedTrainingProvider());
      dispatch(uncheckAllItem());
    };
  }, []);

  const [page, setPage] = useState(1);

  const nextPage = () => {
    setPage(page + 1);
  };

  const previousPage = () => {
    setPage(page - 1);
  };

  return (
    <Row>
      <Col md={12} lg={12}>
        <Card className="pb-0">
          <CardBody className="wizard">
            <div className="wizard__steps">
              <div className={`wizard__step${page === 1 ? ' wizard__step--active' : ''}`}>
                <p>Traning Providers</p>
              </div>
              <div className={`wizard__step${page === 2 ? ' wizard__step--active' : ''}`}>
                <p>Assign Organizations</p>
              </div>
            </div>
            <div className="">
              {page === 1 && <StepOne onSubmit={nextPage} />}
              {page === 2 && <StepTwo previousPage={previousPage} />}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default AssignTrainingProviderWizard;
