import React from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import ModuleTable from './ModuleTable';
import { useModuleUIContext } from '../ModuleUIProvider';
import { shallowEqual, useSelector } from 'react-redux';

const ModuleTableWrapper = () => {
  const moduleUIContext = useModuleUIContext();
  const checkedModuleIds = useSelector(
    (state) => state.cadsAIModule.checkedModuleIds,
    shallowEqual,
  );

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="products-list">
          <div className="card__title">
            <h5 className="bold-text">Module List</h5>
            <ButtonToolbar className="products-list__btn-toolbar-top">
              <Button color="primary" onClick={moduleUIContext.openNewModuleDialog}>
                Add new Module
              </Button>
              <Button
                color="danger"
                outline
                onClick={moduleUIContext.openUpdateStatusDialog}
                disabled={checkedModuleIds.length === 0}
              >
                Deactivate
              </Button>
            </ButtonToolbar>
          </div>
          <ModuleTable />
        </CardBody>
      </Card>
    </Col>
  );
};

export default ModuleTableWrapper;
