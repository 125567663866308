import { createAction } from 'redux-actions';

export const toggleCheckedItem = createAction('TOGGLE_CHECKED_ITEM');
export const uncheckAllItem = createAction('UNCHECK_ALL_ITEM');
export const setAssignActiveTab = createAction('SET_ASSIGN_ACTIVE_TAB');
export const setDataFromModule = createAction('SET_DATA_FROM_MODULE');
export const setDataFromLearningPath = createAction('SET_DATA_FROM_LEARNING_PATH');
export const setDataFromTrainingProvider = createAction('SET_DATA_FROM_TRAINING_PROVIDER');

export const toggleCheckedItemById = (itemId) => (dispatch) => {
  dispatch(toggleCheckedItem(itemId));
};

export const uncheckAllItems = () => (dispatch) => {
  dispatch(uncheckAllItem());
};

export const setActiveTab = (tab) => (dispatch) => {
  dispatch(setAssignActiveTab(tab));
  dispatch(uncheckAllItem());
};

export const setIsFromModule = (isFromModule) => (dispatch) => {
  dispatch(setDataFromModule(isFromModule));
};
export const setIsFromLearningPath = (isFromLearningPath) => (dispatch) => {
  dispatch(setDataFromLearningPath(isFromLearningPath));
};
export const setIsFromTrainingProvider = (isFromTrainingProvider) => (dispatch) => {
  dispatch(setDataFromTrainingProvider(isFromTrainingProvider));
};
