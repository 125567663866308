import { createAction } from 'redux-actions';

export const toggleCheckedItem = createAction('CADS_AI_TOGGLE_CHECKED_ITEM');
export const uncheckAllItem = createAction('CADS_AI_UNCHECK_ALL_ITEM');
export const setAssignActiveTab = createAction('CADS_AI_SET_ASSIGN_ACTIVE_TAB');
export const setDataFromModule = createAction('CADS_AI_SET_DATA_FROM_MODULE');
export const setDataFromLearningPath = createAction('CADS_AI_SET_DATA_FORM_LEARNING_PATH');

export const toggleCheckedItemById = (itemId) => (dispatch) => {
  dispatch(toggleCheckedItem(itemId));
};

export const uncheckAllItems = () => (dispatch) => {
  dispatch(uncheckAllItem());
};

export const setActiveTab = (tab) => (dispatch) => {
  dispatch(setAssignActiveTab(tab));
  dispatch(uncheckAllItem());
};

export const setisFromCourse = (isFromCourse) => (dispatch) => {
  dispatch(setDataFromModule(isFromCourse));
};
export const setIsFromLearningPath = (isFromLearningPath) => (dispatch) => {
  dispatch(setDataFromLearningPath(isFromLearningPath));
};
