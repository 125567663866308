import { handleActions } from 'redux-actions';
import {
  setModuleScheduleFetching,
  setAssignModuleScheduleList,
  setModuleScheduleError,
  setModuleScheduleFilters,
  toggleCheckedModuleSchedule,
  clearCheckedModuleSchedule,
} from './actions';

const defaultState = {
  loading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    search: '',
    isSortOrderDescending: false,
  },
  totalRecords: 0,
  assignModuleSchedules: [],
  error: null,
  checkedModuleSchedule: [],
};

export default handleActions(
  {
    [setModuleScheduleFetching](state) {
      return {
        ...state,
        assignModuleSchedules: [],
        totalRecords: 0,
        loading: true,
        error: null,
      };
    },
    [setModuleScheduleError](state, { payload }) {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    },
    [setAssignModuleScheduleList](state, { payload }) {
      const assignModuleScheduleList = payload.data;
      return {
        ...state,
        assignModuleSchedules: assignModuleScheduleList,
        totalRecords: assignModuleScheduleList?.length,
        loading: false,
        error: null,
      };
    },
    [setModuleScheduleFilters](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
      };
    },
    [toggleCheckedModuleSchedule](state, { payload: itemId }) {
      const checkedIds = state.checkedModuleSchedule;
      return {
        ...state,
        checkedModuleSchedule: checkedIds.includes(itemId)
          ? checkedIds.filter((id) => id !== itemId)
          : [...checkedIds, itemId],
      };
    },
    [clearCheckedModuleSchedule](state) {
      return {
        ...state,
        checkedModuleSchedule: defaultState.checkedModuleSchedule,
      };
    },
  },
  defaultState,
);
