import React, { useRef, useState, useEffect } from 'react';
import { Button } from 'reactstrap';
// import Select from "react-select";
import TableHead from '@material-ui/core/TableHead';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setProgramFilters } from '../../redux/actions';
//import api from "@/utils/apiClient";

export function ProgramsTableSearchBar({ history }) {
  const dispatch = useDispatch();
  const totalProgramCount = useSelector((state) => state.programs?.data?.length, shallowEqual);

  const [isSearched, setIsSearched] = useState(false);
  // const [searchProgramType, setSearchProgramType] = useState([]);
  // const [programTypeOptions, setProgramTypeOptions] = useState([]);

  const searchNameRef = useRef(null);
  const handleSearch = () => {
    dispatch(
      setProgramFilters({
        searchByName: searchNameRef?.current?.value,
        programTypeId: 1,
      }),
    );
  };

  // const getProgramTypeOptions = async () => {
  //   try {
  //     const { data: data, isSuccess } =
  //       await api.programs.getProgramTypeOptions();
  //     if (isSuccess) {
  //       setProgramTypeOptions([]);
  //       data.data.map((item) => {
  //         setProgramTypeOptions((programTypeOptions) => [
  //           ...programTypeOptions,
  //           { value: item.id, label: item.title },
  //         ]);
  //       });
  //     } else {
  //       throw new Error("error while fetching program type options");
  //     }
  //   } catch (error) {
  //     console.error("Error inside program type options", error.message);
  //   }
  // };

  // useEffect(() => {
  //   getProgramTypeOptions();
  // }, []);

  const handleClear = () => {
    if (searchNameRef?.current?.value) searchNameRef.current.value = '';
    setIsSearched(false);
    // setSearchProgramType([]);
    dispatch(setProgramFilters({ searchByName: '', programTypeId: 1 }));
  };

  return (
    <TableHead>
      <div className="table__search">
        <div className="table__search_item">
          <b>Program Name</b>
          <div className="form">
            <div className="form__form-group">
              <div className="form__form-group-field">
                <div className="form__form-group-icon" style={{ height: 37 }}>
                  <MagnifyIcon />
                </div>
                <input
                  ref={searchNameRef}
                  className="search-bar-search-text"
                  name="searchText"
                  type="text"
                  placeholder="Search by Program Name"
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="table__search_item">
          <b>Program Type</b>
          <div style={{ minWidth: 200 }}>
            <Select
              name="program_type"
              value={searchProgramType}
              onChange={(e) => setSearchProgramType(e)}
              autosize={true}
              options={programTypeOptions}
              clearable={false}
              className="react-select"
              placeholder={"Select Program Type"}
              classNamePrefix="react-select"
            />
          </div>
        </div> */}
        <div className="table__search_item mt-3">
          <Button color="primary" onClick={handleSearch}>
            {' '}
            Search{' '}
          </Button>
        </div>
        <div className="table__search_item mt-3">
          <Button color="secondary" onClick={handleClear} outline>
            Clear
          </Button>
        </div>
      </div>

      {isSearched && (
        <div className="ml-1">
          <span>Found {totalProgramCount} matches</span>
        </div>
      )}
    </TableHead>
  );
}
