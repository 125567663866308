import React from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import QATable from './QATable';
import { useQAUIContext } from '../QAUIProvider';
import { shallowEqual, useSelector } from 'react-redux';

const QATableWrapper = () => {
  const qaUIEvents = useQAUIContext();
  const { checkedQAIds } = useSelector((state) => state.cadsAIQAData, shallowEqual);

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="products-list">
          <div className="card__title">
            <h5 className="bold-text">Data Q&A List</h5>
            <ButtonToolbar className="products-list__btn-toolbar-top">
              <Button color="primary" onClick={qaUIEvents.openViewPinnedQuestions} outline>
                Pin Questions
              </Button>
              <Button
                  color="danger"
                  outline
                  onClick={qaUIEvents.openDeleteQADialog}
                  disabled={checkedQAIds.length === 0}
              >
                Deactivate
              </Button>
            </ButtonToolbar>
          </div>
          <QATable />
        </CardBody>
      </Card>
    </Col>
  );
};

export default QATableWrapper;
