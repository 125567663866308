import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import AnnouncementsTable from './AnnouncementsTable';
import { useAnnouncementsUIContext } from '../AnnouncementsUIProvider';

const AnnouncementsTableWrapper = () => {
  const announcementsUIContext = useAnnouncementsUIContext();

  const checkedAnnouncementIds = useSelector(
    (state) => state.cadsAIAnnouncements.checkedAnnouncementIds,
    shallowEqual,
  );

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="products-list">
          <div className="card__title">
            <h5 className="bold-text">Announcement List</h5>
            <ButtonToolbar className="products-list__btn-toolbar-top">
              <Button color="primary" onClick={announcementsUIContext.openNewAnnouncementsDialog}>
                Add new
              </Button>
              <Button
                color="danger"
                outline
                onClick={announcementsUIContext.openAnnouncementDeactivationDialog}
                disabled={checkedAnnouncementIds.length === 0}
              >
                Deactivate
              </Button>
            </ButtonToolbar>
          </div>
          <AnnouncementsTable />
        </CardBody>
      </Card>
    </Col>
  );
};

export default AnnouncementsTableWrapper;
