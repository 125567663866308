import React from 'react';
import { Col } from 'reactstrap';
import { CADS_AI_PRODUCTS } from '@/shared/helpers/constants';
import AssignCourseWizard from './assign-course-wizard';
import AssignLearningPathWizard from '@/containers/Cads-AI/ManageLearning/Assign/components/assign-wizard/assign-learning-path-wizard';

const AssignWizardPage = ({ selectedProduct }) => {
  return (
    <Col className="card-body">
      {selectedProduct === CADS_AI_PRODUCTS.LEARNING_PATH && <AssignLearningPathWizard />}
      {selectedProduct === CADS_AI_PRODUCTS.COURSE && <AssignCourseWizard />}
      {/*<AssignWizardEditForm /> TODO: remove this*/}
    </Col>
  );
};

export default AssignWizardPage;
