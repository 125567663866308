import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { CPReducers } from './Reducers/CPReducers';
import { CampaignReducers } from './Reducers/CampaignReducers';
import { AdminReducers } from './Reducers/AdminReducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import { DataStarReducers } from './Reducers/DataStarReducers';
import { CadsAIReducers } from './Reducers/CadsAIReducers';
import { AppReducers } from './Reducers/AppReducers';

const reducer = combineReducers({
  /*Admin*/
  ...AdminReducers,

  /*Campaign panel related*/
  ...CampaignReducers,

  /*Control panel related*/
  ...CPReducers,

  /*CADS AI*/
  ...CadsAIReducers,

  /*App*/
  ...AppReducers,

  /*Data star*/
  ...DataStarReducers,
});
const store =
  process.env.NODE_ENV === 'production'
    ? createStore(reducer, applyMiddleware(thunk))
    : createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;
