import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const setModuleFetching = createAction('SET_MODULE_FETCHING');
export const setModuleError = createAction('SET_MODULE_ERROR');
export const setAssignModuleList = createAction('SET_ASSIGN_MODULE_LIST');
export const setModuleFilters = createAction('SET_MODULE_LIST_FILTER');
export const toggleCheckedModule = createAction('TOGGLE_CHECKED_MODULE');
export const clearCheckedModule = createAction('CLEAR_SELECTED_MODULE');
export const clearModuleFilters = createAction('CLEAR_MODULE_FILTERS');
export const updateModuleInList = createAction('UPDATE_MODULE_IN_LIST');

export const getCPModulesList = (filter) => async (dispatch) => {
  const defaultError = `Error occurred getting module list`;
  dispatch(setModuleFetching());
  try {
    const { data, isSuccess } = await api.modules.getModuleList(filter);
    if (isSuccess) dispatch(setAssignModuleList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setModuleError(error.message));
    console.error(defaultError, error.message);
  }
};
