import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const fetchCadsAITrainingList = createAction('CADS_AI_FETCH_CADS_AI_TRAINING_LIST');
export const setCadsAITrainingList = createAction('CADS_AI_SET_CADS_AI_TRAINING_LIST');
export const setCadsAITrainingError = createAction('CADS_AI_SET_CADS_AI_TRAINING_ERROR');
export const setCadsAITrainingFilters = createAction('CADS_AI_SET_CADS_AI_TRAINING_FILTERS');
export const toggleCheckedCadsAITraining = createAction('CADS_AI_TOGGLE_CHECKED_CADS_AI_TRAINING');
export const uncheckAllCadsAITraining = createAction('CADS_AI_UNCHECK_ALL_CADS_AI_TRAINING');

export const toggleCheckedItemById = (tpId) => (dispatch) => {
  dispatch(toggleCheckedCadsAITraining(tpId));
};

export const getTrainingProviderList = (filter) => async (dispatch) => {
  const defaultError = `Error occurred getting training provider list`;
  dispatch(fetchCadsAITrainingList());
  try {
    const { data, isSuccess } = await api.cadsAITrainingProvider.getTrainingProviderList(filter);
    if (isSuccess) dispatch(setCadsAITrainingList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setCadsAITrainingError(error.message));
    console.error(defaultError, error.message);
  }
};
