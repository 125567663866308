import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { StepDefaultProps, StepPropTypes } from './Props';
import FormField from '@/shared/components/form/hook/FormField';
import { SelectField } from '@/shared/components/form/Select';
import { getSkillsList } from '@/containers/Admin/SkillBank/Skills/redux/actions';

import { extractFieldData, getStepFields } from '../index';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getQuestionLevelsList,
  getQuestionTagsList,
  getQuestionTypesList,
} from '@/containers/Admin/SkillBank/Questions/redux/actions';
import { CreatableSelectField } from '@/shared/components/form/CreatableSelectField';

const StepOne = ({ onSubmit, page, data }) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm(extractFieldData(page, data));

  /* Get skills options */
  const { data: skills } = useSelector((state) => state.skills, shallowEqual);
  const [skillsOptions, setSkillsOptions] = React.useState([]);
  const getSkillsOptions = React.useCallback(() => {
    // TODO: implement proper solution
    dispatch(getSkillsList({ pageSize: 1000, pageNumber: 1 })); // assuming there won't be more than 100 skills
  }, []);

  /* Get questions level options */
  const {
    questionLevels: levels,
    questionTypes: types,
    questionTags: tags,
  } = useSelector((state) => state.questions, shallowEqual);
  const [levelOptions, setLevelOptions] = React.useState([]);
  const getLevelsOptions = React.useCallback(() => {
    dispatch(getQuestionLevelsList());
  }, []);

  /* Get questions type options */
  const [typeOptions, setTypeOptions] = React.useState([]);
  const getTypeOptions = React.useCallback(() => {
    dispatch(getQuestionTypesList());
  }, []);

  /* Get questions tag options */
  const [tagOptions, setTagOptions] = React.useState([]);
  const getTagOptions = React.useCallback(() => {
    dispatch(getQuestionTagsList());
  }, []);

  /*Initialize options*/
  React.useEffect(() => {
    setSkillsOptions(skills?.map((skill) => ({ value: skill.id, label: skill.name })) ?? []);
  }, [skills]);

  React.useEffect(() => {
    setLevelOptions(levels?.map((level) => ({ value: level.id, label: level.name })) ?? []);
  }, [levels]);

  React.useEffect(() => {
    setTypeOptions(types?.map((type) => ({ value: type.id, label: type.name })) ?? []);
  }, [types]);

  React.useEffect(() => {
    setTagOptions(tags?.map((tag) => ({ value: tag.id, label: tag.name })) ?? []);
  }, [tags]);

  /*Keep step data updated*/
  React.useEffect(() => {
    getStepFields(page).forEach((key) => setValue(key, data[key]));
  }, [data]);

  /*get all options*/
  React.useEffect(() => {
    getSkillsOptions();
    getLevelsOptions();
    getTypeOptions();
    getTagOptions();
  }, []);

  return (
    <form className="form wizard__form step-one" onSubmit={handleSubmit(onSubmit)}>
      <div className="form__form-group">
        <span className="form__form-group-label">Skill</span>
        <div className="form__form-group-field">
          <FormField
            control={control}
            name="skill"
            component={SelectField}
            options={skillsOptions}
            placeholder="Select skill"
            rules={{ required: 'This is required field' }}
            errors={errors}
            isAboveError
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Question Level</span>
        <div className="form__form-group-field">
          <FormField
            control={control}
            name="questionLevel"
            component={SelectField}
            options={levelOptions}
            placeholder="Select question level"
            rules={{ required: 'This is required field' }}
            errors={errors}
            isAboveError
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Question Type</span>
        <div className="form__form-group-field">
          <FormField
            control={control}
            name="questionType"
            component={SelectField}
            options={typeOptions}
            placeholder="Select question type"
            rules={{ required: 'This is required field' }}
            errors={errors}
            isAboveError
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Tags</span>
        <div className="form__form-group-field">
          <FormField
            control={control}
            name="tags"
            component={CreatableSelectField}
            isMulti
            options={tagOptions}
            placeholder="Select tag"
          />
        </div>
      </div>

      <ButtonToolbar className="form__button-toolbar wizard__toolbar">
        <Button color="primary" type="button" disabled className="previous">
          Back
        </Button>
        <Button color="primary" type="submit" className="next">
          Next
        </Button>
      </ButtonToolbar>
    </form>
  );
};

StepOne.propTypes = StepPropTypes;
StepOne.defaultProps = StepDefaultProps;

export default StepOne;
