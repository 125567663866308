import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { cadsAiProgramsBaseUrl } from '../index';
import { ProgramsUIProvider } from './ProgramsUIProvider';
import { ProgramsCard } from './ProgramsCard';
import ProgramEditDialog from './program-add-edit-dialog/ProgramEditDialog';
import ProgramViewDialog from './program-view-dialog/ProgramViewDialog';
import { ProgramUpdateStatusDialog } from './program-update-status-dialog/ProgramUpdateStatusDialog';
import { setProgramFilters, uncheckAllPrograms } from '../redux/actions';
import { useDispatch } from 'react-redux';

export function ProgramsPage({ history, match }) {
  const dispatch = useDispatch();

  //clean up
  React.useEffect(() => () => dispatch(uncheckAllPrograms()), []);

  const programsUIEvents = {
    openViewProgramsDialog: (id) => {
      history.push(`${cadsAiProgramsBaseUrl}/${id}/view`);
    },
    openNewProgramsDialog: () => {
      history.push(`${cadsAiProgramsBaseUrl}/new`);
    },
    openEditProgramsDialog: (id) => {
      history.push(`${cadsAiProgramsBaseUrl}/${id}/edit`);
    },
    openDeleteProgramsDialog: (id) => {
      history.push(`${cadsAiProgramsBaseUrl}/${id}/delete`);
    },
    openUpdateStatusDialog: () => {
      history.push(`${cadsAiProgramsBaseUrl}/update-status`);
    },
  };

  return (
    <ProgramsUIProvider programsUIEvents={programsUIEvents}>
      <Switch>
        <Route path={`${cadsAiProgramsBaseUrl}/new`}>
          {({ history, match }) => (
            <ProgramEditDialog
              show={match != null}
              isEdit={false}
              onHide={() => {
                dispatch(setProgramFilters());
                history.push(cadsAiProgramsBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${cadsAiProgramsBaseUrl}/:id/edit`}>
          {({ history, match }) => (
            <ProgramEditDialog
              show={match != null}
              isEdit={true}
              id={match?.params?.id}
              onHide={() => {
                dispatch(setProgramFilters());
                history.push(cadsAiProgramsBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${cadsAiProgramsBaseUrl}/:id/view`}>
          {({ history, match }) => (
            <ProgramViewDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                dispatch(setProgramFilters());
                history.push(cadsAiProgramsBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${cadsAiProgramsBaseUrl}/update-status`}>
          {({ history, match }) => (
            <ProgramUpdateStatusDialog
              show={match != null}
              onHide={() => {
                dispatch(setProgramFilters());
                history.push(cadsAiProgramsBaseUrl);
              }}
            />
          )}
        </Route>
      </Switch>
      <ProgramsCard />
    </ProgramsUIProvider>
  );
}
