import React from 'react';
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import Collapse from '@/shared/components/Collapse';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { addNewAssessmentConfigPage } from '../redux/actions';
import PageContent from './PageContent';
import { isEmpty } from 'lodash';

const GeneralAssessmentConfigPages = () => {
  const dispatch = useDispatch();
  const pages = useSelector(
      (state) => state.assessmentConfig.pages
  );

  React.useEffect(() => {
    if (isEmpty(pages)) {
      addNewPage();
    }
  }, [pages]);

  const [activeTab, setActiveTab] = React.useState(pages?.[0]);

  React.useEffect(() => {
    setActiveTab(pages.find((p) => p?.id === activeTab?.id) ?? pages?.[0]);
  }, [pages]);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const addNewPage = () => {
    dispatch(addNewAssessmentConfigPage());
  };

  return (
      <Collapse title="pages" defaultCollapse={true}>
        <Button color="success" className="float-right m-0" onClick={addNewPage} outline>
          Add new Page
        </Button>
        {pages.length !== 0 && activeTab?.id &&
            <div className="tabs tabs--vertical tabs--vertical-colored w-100">
              <div className="tabs__wrap">
                <Nav tabs>
                  {pages.map((page, idx) => (
                      <NavItem key={page.id}>
                        <NavLink
                            className={classnames({active: activeTab.id === page.id})}
                            onClick={() => toggle(page)}
                        >
                          Page {idx + 1}
                        </NavLink>
                      </NavItem>
                  ))}
                </Nav>
                <TabContent activeTab={activeTab.id}>
                  <TabPane tabId={activeTab.id}>
                    <PageContent page={activeTab}/>
                  </TabPane>
                </TabContent>
              </div>
            </div>
        }
      </Collapse>
  );
};

export default GeneralAssessmentConfigPages;
