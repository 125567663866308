import { paths } from '@/shared/helpers/paths';
import React, { Suspense } from 'react';
import Loading from '@/shared/components/Loading';
import { Redirect, Route, Switch } from 'react-router-dom';
import Skills from '@/containers/Admin/SkillBank/Skills';
import SubCategories from '@/containers/Admin/SkillBank/SubCategories';
import Domains from '@/containers/Admin/SkillBank/Domains';
import Questions from '@/containers/Admin/SkillBank/Questions';

export default function SkillBank() {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        {<Redirect exact from={paths.SKILL_BANK.BASE} to={paths.SKILL_BANK.SKILLS} />}
        <Route path={paths.SKILL_BANK.SKILLS} component={Skills} />
        <Route path={paths.SKILL_BANK.SUB_CATEGORIES} component={SubCategories} />
        <Route path={paths.SKILL_BANK.DOMAINS} component={Domains} />
        <Route path={paths.SKILL_BANK.QUESTIONS} component={Questions} />
      </Switch>
    </Suspense>
  );
}
