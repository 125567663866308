import React from 'react';
import { Col } from 'reactstrap';
import { Field } from '@/shared/components/Field';

const ContactDetails = ({ organization }) => {
  return (
    <>
      <div className="pt-5">
        <h4 className="pl-2">Contact Details</h4>
        <div className="row pt-2">
          <Col md={6} xl={4} lg={6}>
            <Field
              title={'Phone 1'}
              iconDisable={true}
              fieldValue={organization?.hiringContactPhone1 ?? '-'}
            />
            <Field
              title={'Phone 2'}
              iconDisable={true}
              fieldValue={organization?.hiringContactPhone2 ?? '-'}
            />
          </Col>
          <Col md={6} xl={4} lg={6}>
            <Field
              title={'Email 1'}
              iconDisable={true}
              fieldValue={organization?.hiringContactEmail1 ?? '-'}
            />
            <Field
              title={'Email 2'}
              iconDisable={true}
              fieldValue={organization?.hiringContactEmail2 ?? '-'}
            />
          </Col>
        </div>
      </div>
    </>
  );
};

export default ContactDetails;
