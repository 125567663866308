import React, { createContext, useContext } from 'react';

const ApplicantsUIContext = createContext();

export function useApplicantsUIContext() {
  return useContext(ApplicantsUIContext);
}

export function ApplicantsUIProvider({ applicantsUIEvents, children }) {
  const value = {
    openViewApplicantsDialog: applicantsUIEvents.openViewApplicantsDialog,
    openNewApplicantsDialog: applicantsUIEvents.openNewApplicantsDialog,
    openEditApplicantsDialog: applicantsUIEvents.openEditApplicantsDialog,
    openDeleteApplicantsDialog: applicantsUIEvents.openDeleteApplicantsDialog,
    openUpdateStatusDialog: applicantsUIEvents.openUpdateStatusDialog,
    openUpdateStageDialog: applicantsUIEvents.openUpdateStageDialog,
    openUpdateCohortDialog: applicantsUIEvents.openUpdateCohortDialog,
  };

  return <ApplicantsUIContext.Provider value={value}>{children}</ApplicantsUIContext.Provider>;
}
