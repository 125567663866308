import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const fetchEmployeeList = createAction('CADS_AI_FETCH_EMPLOYEE_LIST');
export const setEmployeeList = createAction('CADS_AI_SET_EMPLOYEE_LIST');
export const setEmployeeError = createAction('CADS_AI_SET_EMPLOYEE_ERROR');
export const setEmployeeFilters = createAction('CADS_AI_SET_EMPLOYEE_FILTERS');
export const toggleCheckedEmployee = createAction('CADS_AI_TOGGLE_CHECKED_EMPLOYEE');
export const uncheckAllEmployee = createAction('CADS_AI_UNCHECK_ALL_EMPLOYEE');

export const toggleCheckedItemById = (employeeId) => (dispatch) => {
  dispatch(toggleCheckedEmployee(employeeId));
};

export const getEmployeeList = (filter) => async (dispatch) => {
  const defaultError = `Error occurred getting employee list`;
  dispatch(fetchEmployeeList());
  try {
    const { data, isSuccess } = await api.cadsAIEmployee.getEmployeeList(filter);
    if (isSuccess) dispatch(setEmployeeList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setEmployeeError(error.message));
    console.error(defaultError, error.message);
  }
};
