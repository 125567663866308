import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { useForm } from 'react-hook-form';
import FormField from '@/shared/components/form/hook/FormField';
import { StepPropTypes, StepDefaultProps } from './Props';
import { extractFieldData, getStepFields } from '../index';
import { SelectField } from '@/shared/components/form/Select';
import renderCheckBoxField from '@/shared/components/form/CheckBox';
import DeleteIcon from 'mdi-react/DeleteIcon';
import api from '@/utils/apiClient';

const StepThree = ({
  onSubmit,
  page,
  onClear,
  previousPage,
  data,
  answerData,
  setAnswerData,
  isEdit,
  questionId,
}) => {
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: extractFieldData(page, data),
    mode: 'onChange',
  });

  React.useEffect(() => {
    getStepFields(page).forEach((key) => setValue(key, data[key]));
  }, [data]);

  const [errorMsg, setErrorMsg] = React.useState('');

  React.useEffect(() => {
    if (isEdit && data.answers) {
      setAnswerData(
        data?.answers?.map((ans) => ({
          id: ans.id,
          name: `answer-${ans.id}`,
          value: ans.text ?? '',
          isCorrect: ans.isCorrect,
          correctName: `correct-${ans.id}`,
        })),
      );
    }
  }, [isEdit]);

  const addNewAnswer = () => {
    const id = 'c-' + new Date().getTime();
    setAnswerData([
      ...answerData,
      {
        id,
        name: `answer-${id}`,
        value: '',
        isCorrect: false,
        correctName: `correct-${id}`,
      },
    ]);
  };

  const toggledChecked = (answerId) => {
    setAnswerData(
      answerData.map((ans) => {
        if (ans.id !== answerId) {
          //TODO: use some strong validation
          if (data.questionType.label.toLowerCase().includes('single')) {
            setValue(ans.correctName, false);
            return { ...ans, isCorrect: false };
          }
          return ans;
        }
        if (ans.id === answerId) {
          setValue(ans.correctName, true);
          return { ...ans, isCorrect: !ans.isCorrect };
        }
      }),
    );
  };

  const deleteAnswer = async (ansId) => {
    setErrorMsg('');
    if (isEdit) {
      try {
        const { isSuccess } = await api.questions.deleteQuestionOption(questionId, ansId);
        if (!isSuccess) {
          console.error('Error while deleting the answer');
          return;
        }
      } catch ({ message }) {
        setErrorMsg('Error in while deleting answer');
        console.error('Error in while deleting answer', message);
        return;
      }
    }
    setAnswerData(answerData.filter((ans) => ans.id !== ansId));
  };

  return (
    <form className="form form--horizontal wizard__form" onSubmit={handleSubmit(onSubmit)}>
      <div className="form__form-group" style={{ width: '48%' }}>
        <span className="form__form-group-label">Allow to skip ?</span>
        <div className="form__form-group-field" style={{ paddingLeft: 10, paddingRight: 50 }}>
          <FormField
            control={control}
            name="allowSkip"
            component={SelectField}
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]}
          />
        </div>
      </div>
      <div className="form__form-group w-50">
        <span className="form__form-group-label">Allow Other ?</span>
        <div className="form__form-group-field" style={{ paddingLeft: 10, paddingRight: 50 }}>
          <FormField
            control={control}
            name="allowOther"
            component={SelectField}
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]}
          />
        </div>
      </div>

      <div className="form__form-group pt-3" style={{ borderTop: '1px solid #eff1f5' }}>
        <h5 className="form__form-group-label bold-text">Answers</h5>
        <div className="d-inline-block text-right w-100">
          <Button color="success" onClick={addNewAnswer} outline>
            {' '}
            Add New Answer{' '}
          </Button>
        </div>
      </div>
      <div className="answer-data">
        {!!errorMsg && <p className="alert-danger p-2">{errorMsg}</p>}
        {!!answerData.length && (
          <p className="ml-5">
            Note: Check the box beside the answer to mark the answer as correct.
          </p>
        )}
        {answerData.map((answer, idx) => {
          return (
            <div className="form__form-group" key={answer.id}>
              <span className="form__form-group-label" style={{ width: 20 }}>
                {idx + 1}.
              </span>
              <div className="form__form-group-field w-75 mr-1 d-inline-block">
                <FormField
                  control={control}
                  customClassName="w-100"
                  name={answer.name}
                  defaultValue={answer.value}
                  component="input"
                  rules={{ required: 'This is required field' }}
                  errors={errors}
                  isAboveError
                />
              </div>
              <div className="d-inline-block">
                <FormField
                  name={answer.correctName}
                  id={answer.correctName}
                  customClassName="ml-2 mt-2"
                  control={control}
                  onClick={() => toggledChecked(answer.id)}
                  checked={answer?.isCorrect}
                  defaultValue={answer?.isCorrect}
                  component={renderCheckBoxField}
                  className="colored-click"
                />
              </div>
              <div className="d-inline-block mt-1">
                <DeleteIcon
                  style={{ color: '#ff4861' }}
                  role="button"
                  onClick={() => deleteAnswer(answer.id)}
                />
              </div>
            </div>
          );
        })}
      </div>

      <ButtonToolbar className="form__button-toolbar wizard__toolbar">
        <Button
          color="primary"
          type="button"
          className="previous"
          onClick={() => previousPage(getValues())}
        >
          Back
        </Button>
        <Button color="secondary" type="button" onClick={onClear} outline>
          Clear
        </Button>
        <Button color="primary" type="submit">
          Submit
        </Button>
      </ButtonToolbar>
    </form>
  );
};

StepThree.propTypes = StepPropTypes;
StepThree.defaultProps = StepDefaultProps;

export default StepThree;
