import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TrainingProviderCard } from './TrainingProviderCard';
import { clearTrainingProviderFilters } from '../redux/actions';
import { TrainingProviderUIProvider } from './TrainingProviderUIProvider';

export function TrainingProviderPage({ isForAssignment = false }) {
  const history = useHistory();

  //clean up
  React.useEffect(() => dispatch(clearTrainingProviderFilters()), []);

  const dispatch = useDispatch();

  return (
    <TrainingProviderUIProvider isForAssignment={isForAssignment}>
      <TrainingProviderCard />
    </TrainingProviderUIProvider>
  );
}
