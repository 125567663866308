import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const fetchNotificationList = createAction('CADS_AI_FETCH_NOTIFICATION_LIST');
export const setNotificationList = createAction('CADS_AI_SET_NOTIFICATION_LIST');
export const setNotificationError = createAction('CADS_AI_SET_NOTIFICATION_ERROR');
export const setNotificationFilters = createAction('CADS_AI_SET_NOTIFICATION_FILTERS');
export const toggleCheckedNotification = createAction('CADS_AI_TOGGLE_CHECKED_NOTIFICATION');
export const uncheckAllNotification = createAction('CADS_AI_UNCHECK_ALL_NOTIFICATION');

export const toggleCheckedItemById = (notificationId) => (dispatch) => {
  dispatch(toggleCheckedNotification(notificationId));
};

export const getNotificationList = (filter) => async (dispatch) => {
  const defaultError = `Error occurred getting notification list`;
  dispatch(fetchNotificationList());
  try {
    const { data, isSuccess } = await api.cadsAINotification.getNotificationList(filter);
    if (isSuccess) dispatch(setNotificationList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setNotificationError(error.message));
    console.error(defaultError, error.message);
  }
};
