import React, { createContext, useContext } from 'react';

const JobUIContext = createContext();

export function useJobUIContext() {
  return useContext(JobUIContext);
}

export function JobUIProvider({ jobUIEvents, children }) {
  const value = {
    openViewJobDialog: jobUIEvents.openViewJobDialog,
    openNewJobDialog: jobUIEvents.openNewJobDialog,
    openEditJobDialog: jobUIEvents.openEditJobDialog,
    openDeleteJobDialog: jobUIEvents.openDeleteJobDialog,
    openViewAllJobs: jobUIEvents.openViewAllJobs,
  };

  return <JobUIContext.Provider value={value}>{children}</JobUIContext.Provider>;
}
