import React from 'react';
import { Row } from 'reactstrap';
import Panel from '@/shared/components/Panel';
import QuestionPosted from './QuestionPosted';
import QAAgeGroup from './QAAgeGroup';
import AudienceGeo from './AudienceGeo';
import QAViews from './QAViews';
import QALikes from './QALikes';
import QAFollows from './QAFollows';
import QAFlag from './QAFlag';
import QAFilters from './QAFilters';

const QASection = () => {
  const [filter, setFilter] = React.useState({
    selectedMonth: null,
    selectedYear: null,
    selectedWeek: null,
    selectedStartDate: null,
    selectedEndDate: null,
  });
  return (
    <Row className="mt-3">
      <Panel lg={12} xl={12} title={'QA'}>
        <QAFilters
          isDatesHidden={false}
          isOtherFiltersHidden={true}
          setFilter={setFilter}
          filter={filter}
        />
        <QuestionPosted />
      </Panel>
      <Panel title={'QA'}>
        <Row>
          <AudienceGeo />
          <QAAgeGroup />
        </Row>
      </Panel>
      <Panel lg={12} xl={12} title={'QA'}>
        <QAFilters
          isDatesHidden={true}
          isOtherFiltersHidden={false}
          setFilter={setFilter}
          filter={filter}
        />
        <Row>
          <QAViews />
          <QALikes />
          <QAFollows />
          <QAFlag />
        </Row>
      </Panel>
    </Row>
  );
};

export default QASection;
