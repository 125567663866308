import React, { useState } from 'react';
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import SanitizeHTML from '@/shared/components/SanitizeHTML';
import { getColorForWeight } from '@/shared/helpers';

export function AssessmentViewTabs({ assessment }) {
  const [activeTab, setActiveTab] = useState(1);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="tabs tabs--justify tabs--bordered-bottom overflow-hidden">
      <div className="tabs__wrap">
        <Nav tabs>
          {[
            { id: 1, title: 'Introduction' },
            { id: 2, title: 'Thank You Page' },
            { id: 3, title: 'Skills' },
            { id: 4, title: 'Domains' },
          ].map((tab) => (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === tab.id })}
                onClick={() => toggle(tab.id)}
              >
                {tab.title}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={1}>
            <SanitizeHTML html={assessment?.introduction ?? '<p/>'} />
          </TabPane>
          <TabPane tabId={2}>
            <SanitizeHTML html={assessment?.thankYouPage ?? '<p/>'} />
          </TabPane>
          <TabPane tabId={3}>
            {assessment?.associatedSkills?.map((skill) => (
              <Button color={getColorForWeight({ cssClass: true, random: true })} size="sm">
                {skill?.title}
              </Button>
            ))}
          </TabPane>
          <TabPane tabId={4}>
            {assessment?.associatedDomains?.map((domain) => (
              <Button color={getColorForWeight({ cssClass: true, random: true })} size="sm">
                {domain?.title}
              </Button>
            ))}
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
}
