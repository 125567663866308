import { sendRequest } from '../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const notificationBaseUrl =
  process.env.REACT_APP_CADS_AI_API + process.env.REACT_APP_BASE_INFO_ENDPOINT;

export const notificationEndpoints = {
  GET_ALL_NOTIFICATION: '/',
  GET_NOTIFICATION_BY_ID: '/',
  ADD_NOTIFICATION: '/',
  EDIT_NOTIFICATION: '/',
  DEACTIVATE_NOTIFICATION: '/',
};

const getNotificationList = (filter) => {
  return getMockNotificationList();
  return sendRequest({
    fullUrl: notificationEndpoints.GET_ALL_NOTIFICATION,
    method: httpMethod.GET,
    params: filter,
  });
};

const getNotificationById = (id) => {
  return getMockNotificationById();
  return sendRequest({
    fullUrl: notificationEndpoints.GET_NOTIFICATION_BY_ID,
    method: httpMethod.GET,
    params: { id: id },
  });
};

const addNotification = (data) => {
  return addMockNotification();
  return sendRequest({
    fullUrl: notificationEndpoints.ADD_NOTIFICATION,
    method: httpMethod.POST,
    data: data,
  });
};

const updateNotification = (data) => {
  return updateMockNotification();
  return sendRequest({
    fullUrl: notificationEndpoints.EDIT_NOTIFICATION,
    method: httpMethod.PUT,
    data: data,
  });
};

const deactivateNotification = (id) => {
  return deactivateMockNotification();
  return sendRequest({
    fullUrl: notificationEndpoints.DEACTIVATE_NOTIFICATION,
    method: httpMethod.DELETE,
    params: { id: id },
  });
};

const getMockNotificationList = () => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [],
            data: {
              totalRecords: 3,
              records: [
                {
                  id: 1,
                  title: 'Title 1',
                  description: 'Mock Description of the Notification 1',
                  actions: 'Mock 1',
                },
                {
                  id: 2,
                  title: 'Title 2',
                  description: 'Mock Description of the Notification 2',
                  actions: 'Mock 1',
                },
                {
                  id: 3,
                  title: 'Title 3',
                  description: 'Mock Description of the Notification 3',
                  actions: 'Mock 1',
                },
              ],
            },
          },
        }),
      500,
    );
  });
};

const updateMockNotification = (data) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          status: 200,
          messages: ['Notification updated successfully'],
        },
      });
    }, 500);
  });
};

const addMockNotification = (data) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          status: 200,
          messages: ['Notification added successfully'],
        },
      });
    }, 500);
  });
};

const getMockNotificationById = () => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [],
            data: {
              id: 1,
              title: 'Title 1',
              description: 'Mock Description of the Notification 1',
              actions: 'Mock',
            },
          },
        }),
      500,
    );
  });
};

const deactivateMockNotification = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          status: 200,
          messages: ['Notification deactivated successfully'],
        },
      });
    }, 500);
  });
};

export default {
  getNotificationList,
  addNotification,
  updateNotification,
  getNotificationById,
  deactivateNotification,
};
