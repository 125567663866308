import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import { CP_PRODUCTS } from '@/shared/helpers/constants';
import api from '@/utils/apiClient';
import * as Constants from '@/shared/helpers/constants';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { getCPUsersList } from '@/containers/Control-Panel/redux/user/actions';
import { getCPCohortsList } from '@/containers/Control-Panel/redux/cohort/actions';
import { getCPOrganizationsList } from '@/containers/Control-Panel/redux/organization/actions';
import { useHistory } from 'react-router-dom';
import { uncheckAllItem } from '@/containers/Control-Panel/Assign/redux/actions';
import { paths } from '@/shared/helpers/paths';

const controlPanelAssignedBaseUrl = paths.CONTROL_PANEL.ASSIGNED;
const AssignDialog = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { rtl } = useSelector((state) => state, shallowEqual);
  const { assignedProduct, assignedOrgId } = useSelector(
    (state) => state.controlPanel,
    shallowEqual,
  );
  const { activeTab, checkedItemIds } = useSelector((state) => state.assignPage, shallowEqual);
  const { filters: userFilters, searchAssign: userSearch } = useSelector(
    (state) => state.cpUsers,
    shallowEqual,
  );
  const { filters: cohortFilters, searchAssign: cohortSearch } = useSelector(
    (state) => state.cpCohorts,
    shallowEqual,
  );
  const { filters: orgFilters, searchAssign: orgSearch } = useSelector(
    (state) => state.cpOrgs,
    shallowEqual,
  );

  const initialState = {
    isOpen: show,
    title: 'Assignment Confirmation',
    message: 'Are you sure want to proceed forward with assignment?',
    hide: onHide,
    loading: false,
    assignmentSuccess: false,
    assignmentError: false,
  };
  const [modal, setModal] = React.useState({ ...initialState });

  const handleOnClose = () => {
    const filter = { assigned: false, feature: assignedProduct?.id, orgId: assignedOrgId };
    if (activeTab === Constants.USERS) {
      dispatch(getCPUsersList({ ...userFilters, ...userSearch, ...filter }));
    } else if (activeTab === Constants.COHORT) {
      dispatch(getCPCohortsList({ ...cohortFilters, ...cohortSearch, ...filter }));
    } else if (activeTab === Constants.ORGANIZATION) {
      dispatch(getCPOrganizationsList({ ...orgFilters, ...orgSearch, ...filter }));
    }
    dispatch(uncheckAllItem());
    setModal({ ...initialState });
    modal.hide();
  };

  const getSelectedIds = () => {
    switch (activeTab) {
      case Constants.USERS:
        return { orgIds: [assignedOrgId], userIds: checkedItemIds };
      case Constants.COHORT:
        return { orgIds: [assignedOrgId], cohortIds: checkedItemIds };
      case Constants.ORGANIZATION:
        return { orgIds: checkedItemIds };
    }
  };

  const performAssignment = async () => {
    setModal({
      ...modal,
      loading: true,
      title: 'Performing Assignment',
      message: 'Assignment in progress...',
    });

    const { DDO, ACCELTIC, CHATBOT } = CP_PRODUCTS;
    const { orgIds = [], userIds = [], cohortIds = [] } = getSelectedIds();

    /*Make api call for performing assignment*/
    let response = null;
    if (assignedProduct === ACCELTIC) {
      response = await api.assigment.setAssignmentAcceltic(orgIds, cohortIds, userIds, true);
    } else if (assignedProduct === DDO) {
      response = await api.assigment.setAssignmentForDDO(orgIds, cohortIds, userIds, true);
    } else if (assignedProduct === CHATBOT) {
      response = await api.assigment.setAssignmentForChatbot(orgIds, cohortIds, userIds, true);
    }

    /*update modal properties based on response*/
    let updatedModal = {};
    if (response !== null) {
      if (response.isSuccess) {
        updatedModal = {
          loading: false,
          assignmentSuccess: true,
          title: 'Done',
          message: 'Assignment completed successfully!',
        };
      } else {
        updatedModal = {
          loading: false,
          assignmentError: true,
          title: 'Opps',
          message: 'Error performing assignments!',
        };
      }
    } else {
      updatedModal = {
        loading: false,
        assignmentError: true,
        title: 'Error',
        message: 'Assignment is only supported for Acceltic, DDO and Chatbot',
      };
    }
    setModal({ ...modal, ...updatedModal });
  };

  const goToAssigned = () => {
    history.push(
      `${controlPanelAssignedBaseUrl}?activeTab=${activeTab}&assignmentFor=${assignedProduct.id}`,
    );
  };

  return (
    <Modal
      isOpen={show}
      toggle={modal.hide}
      keyboard={false}
      backdrop={'static'}
      modalClassName={`${rtl.direction}-support`}
      className="modal-dialog--primary modal-dialog"
    >
      <div className="modal__header">
        <button
          className="lnr lnr-cross modal__close-btn"
          aria-label="modal__close-btn"
          type="button"
          onClick={handleOnClose}
        />
        {!(modal.loading || modal.assignmentSuccess || modal.assignmentError) && (
          <span className="lnr lnr-pushpin modal__title-icon" />
        )}
        {modal.assignmentSuccess && (
          <span className="lnr lnr-thumbs-up modal__title-icon success" />
        )}
        {modal.assignmentError && <span className="lnr lnr-cross-circle modal__title-icon error" />}
        <h4 className="text-modal  modal__title">{modal.title}</h4>
        <div className="modal__body">
          {modal.loading && (
            <div className="panel__refresh position-relative" style={{ height: 75 }}>
              <LoadingIcon />
            </div>
          )}
          {modal.message}
        </div>
        <ButtonToolbar className="modal__footer">
          {modal.assignmentError && (
            <Button className="modal_cancel" onClick={handleOnClose} outline>
              Close
            </Button>
          )}
          {modal.assignmentSuccess && (
            <>
              <Button className="modal_cancel" onClick={handleOnClose} outline>
                Okay
              </Button>
              <Button className="modal_ok" outline color="primary" onClick={goToAssigned}>
                Go to Assigned
              </Button>
            </>
          )}
          {!(modal.assignmentSuccess || modal.assignmentError || modal.loading) && (
            <>
              <Button className="modal_cancel" onClick={handleOnClose}>
                Cancel
              </Button>
              <Button className="modal_ok" outline color="success" onClick={performAssignment}>
                Yes, Assign
              </Button>
            </>
          )}
        </ButtonToolbar>
      </div>
    </Modal>
  );
};

export default AssignDialog;
