import React, { createContext, useContext } from 'react';

const AssignedUIContext = createContext();

export function useAssignedUIContext() {
  return useContext(AssignedUIContext);
}

const CadsAIAssignedUIProvider = ({ assignedUIEvents, children }) => {
  const value = {
    openUnassignDialog: assignedUIEvents.openUnassignDialog,
  };
  return <AssignedUIContext.Provider value={value}>{children}</AssignedUIContext.Provider>;
};

export default CadsAIAssignedUIProvider;
