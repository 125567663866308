import React, { Fragment } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import { Button } from 'reactstrap';
import EyeIcon from 'mdi-react/EyeIcon';
import PencilIcon from 'mdi-react/PencilIcon';
import BinIcon from 'mdi-react/BinIcon';
import { useSkillUIContext } from '../SkillUIProvider';
import SkillTableColumns, { SkillColumns } from './SkillTableColumns';
import { SkillTableSearchBar } from './SkillTableSearchBar';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getSkillsList, setSkillFilters } from '../../redux/actions';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { defaultPaginationProps } from '@/shared/helpers';

const SkillTable = () => {
  const skillUIContext = useSkillUIContext();
  const dispatch = useDispatch();
  const {
    data: skills,
    error,
    filters,
    loading,
    totalRecords,
  } = useSelector((state) => state.skills, shallowEqual);
  const {
    pageSize: rowsPerPage,
    pageNumber: page,
    sortBy: orderBy,
    isSortOrderDescending,
  } = filters;

  React.useEffect(() => {
    dispatch(getSkillsList(filters));
  }, [filters]);

  const handleRequestSort = (event, property) => {
    dispatch(setSkillFilters({ sortBy: property, isSortOrderDescending: !isSortOrderDescending }));
  };

  const handleChangePage = (event, currentPage) => {
    dispatch(setSkillFilters({ pageSize: rowsPerPage, pageNumber: currentPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setSkillFilters({ pageSize: +event.target.value, pageNumber: 1 }));
  };

  const emptyRows =
    page === Math.ceil(totalRecords / rowsPerPage) ? rowsPerPage - skills.length : 0;

  const renderActionButtons = ({ id: skillId }) => (
    <div className="actions">
      <Button
        className="icon mb-0"
        color="success"
        outline
        onClick={() => skillUIContext.openViewSkillDialog(skillId)}
      >
        <p>
          <EyeIcon /> View
        </p>
      </Button>
      <Button
        className="icon mb-0"
        color="primary"
        outline
        onClick={() => skillUIContext.openEditSkillDialog(skillId)}
      >
        <p>
          <PencilIcon /> Edit
        </p>
      </Button>
      <Button
        className="icon mb-0"
        color="danger"
        outline
        onClick={() => skillUIContext.openDeleteSkillDialog(skillId)}
      >
        <p>
          <BinIcon /> Delete
        </p>
      </Button>
    </div>
  );

  return (
    <Fragment>
      <div className="material-table__wrap">
        <SkillTableSearchBar />
        <Table className="material-table">
          <SkillTableColumns
            order={isSortOrderDescending ? 'desc' : 'asc'}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rowsPerPage}
          />
          <TableBody>
            {(loading || !!error) && (
              <TableRow>
                <TableCell colSpan={SkillColumns.length}>
                  {loading && (
                    <div className={'text-center overflow-hidden'}>
                      <div className="panel__refresh position-relative" style={{ height: 75 }}>
                        <LoadingIcon />
                      </div>
                    </div>
                  )}
                  <div className={'text-center'}>
                    <p className={!!error ? 'm-5' : ''}>{error || 'Loading skills...'}</p>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {!!!skills.length && !loading && !error && (
              <TableRow style={{ height: 120 }}>
                <TableCell colSpan={SkillColumns.length} align="center">
                  {filters.status === null && filters.search === ''
                    ? 'No Skills'
                    : 'No skills with applied filters'}
                </TableCell>
              </TableRow>
            )}
            {skills.map((skill) => {
              return (
                <TableRow className="material-table" tabIndex={-1} key={skill.id}>
                  <TableCell
                    className="material-table__cell"
                    component="th"
                    align="center"
                    scope="row"
                  >
                    {skill?.name}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {skill?.description?.length > 65
                      ? skill?.description.substr(0, 65) + '...'
                      : skill?.description}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {skill?.questions}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {skill?.profiles}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {skill?.domains}
                  </TableCell>
                  <TableCell
                    align="center"
                    className="material-table__cell"
                    style={{ minWidth: '295px' }}
                  >
                    {renderActionButtons(skill)}
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={SkillColumns.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        component={defaultPaginationProps.component}
        backIconButtonProps={defaultPaginationProps.backIconButton}
        nextIconButtonProps={defaultPaginationProps.nextIconButton}
        rowsPerPageOptions={defaultPaginationProps.rowsPerPage}
        className={defaultPaginationProps.className}
        dir={defaultPaginationProps.dir}
      />
    </Fragment>
  );
};

export default SkillTable;
