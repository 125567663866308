import React, { createContext, useContext } from 'react';

const LearningPathUIContext = createContext();

export function useLearningPathUIContext() {
  return useContext(LearningPathUIContext);
}

export function LearningPathUIProvider({ learningPathUIEvents, isForAssignment, children }) {
  const value = {
    openViewLearningPathDialog: learningPathUIEvents.openViewLearningPathDialog,
    openNewLearningPathDialog: learningPathUIEvents.openNewLearningPathDialog,
    openEditLearningPathDialog: learningPathUIEvents.openEditLearningPathDialog,
    openDeleteLearningPathDialog: learningPathUIEvents.openDeleteLearningPathDialog,
    isForAssignment,
  };

  return <LearningPathUIContext.Provider value={value}>{children}</LearningPathUIContext.Provider>;
}
