import { paths } from '@/shared/helpers/paths';
import React, { Suspense } from 'react';
import Loading from '@/shared/components/Loading';
import { Redirect, Route, Switch } from 'react-router-dom';
import DataStarDashboard from '@/containers/Data-Star/Dashboard';
import Programs from '@/containers/Data-Star/Programs';
import Applicants from '@/containers/Data-Star/Applicants';

export default function DataStar() {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        {<Redirect exact from={paths.DATA_STAR.BASE} to={paths.DATA_STAR.DASHBOARD} />}
        <Route path={paths.DATA_STAR.DASHBOARD} component={DataStarDashboard} />
        <Route path={paths.DATA_STAR.PROGRAMS} component={Programs} />
        <Route path={paths.DATA_STAR.APPLICANTS} component={Applicants} />
      </Switch>
    </Suspense>
  );
}
