import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const fetchApplicantList = createAction('FETCH_APPLICANT_LIST');
export const setApplicantList = createAction('SET_APPLICANT_LIST');
export const setApplicantError = createAction('SET_APPLICANT_ERROR');
export const setApplicantFilters = createAction('SET_APPLICANT_FILTERS');
export const clearApplicantFilters = createAction('CLEAR_APPLICANT_FILTERS');
export const toggleCheckedApplicants = createAction('TOGGLE_CHECKED_APPLICANTS');
export const uncheckAllApplicants = createAction('UNCHECK_ALL_APPLICANTS');
export const setApplicantCount = createAction('SET_APPLICANT_COUNT');

export const getApplicantCount = (filters) => async (dispatch) => {
  try {
    const { data: res, isSuccess } = await api.dashboard.getApplicantCount(filters);
    if (isSuccess) {
      dispatch(setApplicantCount(res?.data?.count));
    } else {
      throw new Error('error while fetching registered talent');
    }
  } catch (error) {
    console.error('Error inside dashboard registered talent', error.message);
  }
};

export const toggleCheckedItemById = (applicantId) => (dispatch) => {
  dispatch(toggleCheckedApplicants(applicantId));
};

export const uncheckAllSelectedApplicants = () => (dispatch) => {
  dispatch(uncheckAllApplicants());
};

export const setApplicantOptionsList = createAction('SET_APPLICANT_OPTIONS_LIST');

export const getApplicantsList = (filter) => async (dispatch) => {
  const defaultError = 'Error occurred getting Applicants list';
  try {
    dispatch(fetchApplicantList());
    const { data, isSuccess } = await api.applicants.getApplicantsList(filter);
    if (isSuccess) dispatch(setApplicantList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setApplicantError(error));
    console.error(defaultError, error.message);
  }
};

export const getApplicantsListOptions = () => async (dispatch) => {
  const defaultError = 'Error occurred getting Applicant options list';
  try {
    const { data, isSuccess } = await api.applicants.getApplicantOptionsList();
    if (isSuccess) dispatch(setApplicantOptionsList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    console.error(defaultError, error.message);
  }
};
