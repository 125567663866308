import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import EventViewDialogHeader from './EventViewDialogHeader';
import api from '@/utils/apiClient';
import LoadingIcon from 'mdi-react/LoadingIcon';
import SanitizeHTML from '@/shared/components/SanitizeHTML';
import moment from 'moment';

const EventViewDialog = ({ show, onHide, id, rtl }) => {
  const [modal] = useState({
    isOpen: show,
    title: 'View Event',
    hide: onHide,
  });

  const [state, setState] = useState({
    loading: true,
    event: {
      eventHtml: '',
    },
    error: '',
  });

  useEffect(() => {
    if (id) getEventById(+id);
  }, [id]);

  const getEventById = async (eventId) => {
    try {
      const { data: res, isSuccess } = await api.cadsAIEvent.getEventById(eventId);
      if (id) {
        setState({ ...state, loading: false, event: res.data, error: '' });
      } else {
        throw new Error(res?.messages?.[0] ?? 'Error while getting event details');
      }
    } catch ({ message }) {
      setState({ ...state, loading: false, event: {}, error: message });
      console.error(message);
    }
  };

  const { error, loading, event } = state;

  return (
    <Modal
      isOpen={show}
      toggle={modal.hide}
      modalClassName={`${rtl.direction}-support`}
      className="modal-dialog--primary modal-dialog--header modal-view-dialog"
    >
      <EventViewDialogHeader modal={modal} event={event} />
      {/* Event Modal Body */}
      <div style={{ padding: 15 }}>
        {loading || !!error ? (
          <>
            {loading && (
              <div className="panel__refresh position-relative" style={{ height: 75 }}>
                <LoadingIcon />
              </div>
            )}
            <div className="text-center">
              {error && (
                <div>
                  <span className="lnr lnr-cross-circle modal__title-icon error" />
                </div>
              )}
              {loading ? 'Getting event details...' : error}
            </div>
          </>
        ) : (
          <div className="table-wrapper">
            <table className="event-summary__info">
              <tr>
                <th>Image</th>
                <td>
                  <img
                    style={{ height: 100, width: 100 }}
                    alt={'event-img'}
                    src={event.imageUrl ? event.imageUrl : null}
                  />
                </td>
              </tr>
              <tr>
                <th>Title</th>
                <td>{event?.title ?? '-s'}</td>
              </tr>
              <tr>
                <th>Description</th>
                <td>
                  <div className="question-extra-html">
                    <SanitizeHTML html={event.description ?? '<p/>'} />
                  </div>
                </td>
              </tr>
              <tr>
                <th>Slug</th>
                <td>{event?.slug ?? '-'}</td>
              </tr>
              <tr>
                <th>Tags</th>
                <td>{event?.tags ?? '-'}</td>
              </tr>
              <tr>
                <th>Link</th>
                <td>{event?.link ?? '-'}</td>
              </tr>
              <tr>
                <th>From Date</th>
                <td>{event?.fromDate ? moment(event?.fromDate).format('DD/MM/yyyy') : '-'}</td>
              </tr>
              <tr>
                <th>To Date</th>
                <td>{event?.toDate ? moment(event?.toDate).format('DD/MM/yyyy') : '-'}</td>
              </tr>
              <tr>
                <th>Publish Date</th>
                <td>{event.publishDate ? moment(event?.publishDate).format('DD/MM/yyyy') : '-'}</td>
              </tr>
              <tr>
                <th>Is External</th>
                <td>{event?.isExternal ? 'Yes' : 'No'}</td>
              </tr>
            </table>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default connect((state) => ({
  rtl: state.rtl,
}))(EventViewDialog);
