import { sendRequest } from '../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const dsCohortBaseUrl =
  process.env.REACT_APP_CADS_AI_API + process.env.REACT_APP_BASE_INFO_ENDPOINT;

export const dsCohortEndpoints = {
  GET_ALL_DS_COHORT: '/',
  GET_DS_COHORT_BY_ID: '/',
  ADD_DS_COHORT: '/',
  EDIT_DS_COHORT: '/',
  DELETE_DS_COHORT: '/',
};

const getDSCohortList = (filter) => {
  return getMockDSCohortList();
  return sendRequest({
    fullUrl: dsCohortEndpoints.GET_ALL_DS_COHORT,
    method: httpMethod.GET,
    params: filter,
  });
};

const getDSCohortById = (id) => {
  return getMockDSCohortById();
  return sendRequest({
    fullUrl: dsCohortEndpoints.GET_DS_COHORT_BY_ID,
    method: httpMethod.GET,
    params: { id: id },
  });
};

const updateDSCohort = (data) => {
  return updateMockDSCohort();
  return sendRequest({
    fullUrl: dsCohortEndpoints.EDIT_DS_COHORT,
    method: httpMethod.PUT,
    data: data,
  });
};

const addDSCohort = (data) => {
  return addMockDSCohort();
  return sendRequest({
    fullUrl: dsCohortEndpoints.ADD_DS_COHORT,
    method: httpMethod.POST,
    data: data,
  });
};

const deleteDSCohort = (data) => {
  return deleteMockDSCohort();
  return sendRequest({
    fullUrl: dsCohortEndpoints.DELETE_DS_COHORT,
    method: httpMethod.DELETE,
    data: data,
  });
};

const getMockDSCohortList = () => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [],
            data: {
              totalRecords: 3,
              records: [
                {
                  id: 1,
                  title: 'Title 1',
                  description: 'Mock Description of the Cohort 1',
                  program: { id: 1, title: 'Data Star for Yayasan Peneraju' },
                  status: true,
                  noOfApplicants: 5,
                },
                {
                  id: 2,
                  title: 'Title',
                  description: 'Mock Description of the Cohort 2',
                  program: { id: 2, title: 'UPNM CADS' },
                  status: true,
                  noOfApplicants: 15,
                },
                {
                  id: 3,
                  title: 'Title',
                  description: 'Mock Description of the Cohort 3',
                  program: { id: 23, title: 'New Test program' },
                  status: false,
                  noOfApplicants: 8,
                },
              ],
            },
          },
        }),
      500,
    );
  });
};

const updateMockDSCohort = (data) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          status: 200,
          messages: ['DS Cohort updated successfully'],
        },
      });
    }, 500);
  });
};

const addMockDSCohort = (data) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          status: 200,
          messages: ['DS Cohort added successfully'],
        },
      });
    }, 500);
  });
};

const getMockDSCohortById = () => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [],
            data: {
              id: 1,
              title: 'Title 1',
              description: 'Mock Description of the Cohort 1',
              program: { id: 1, title: 'Data Star for Yayasan Peneraju' },
            },
          },
        }),
      500,
    );
  });
};

const deleteMockDSCohort = (data) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          status: 200,
          messages: ['DS Cohort deleted successfully'],
        },
      });
    }, 500);
  });
};

export default {
  getDSCohortList,
  addDSCohort,
  updateDSCohort,
  getDSCohortById,
  deleteDSCohort,
};
