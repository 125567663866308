import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const setOrganizationList = createAction('SET_CADS_AI_ORGANIZATION_LIST');
export const setOrganizationOptionsList = createAction('SET_CADS_AI_ORG_OPTIONS_LIST');
export const setOrganizationTypeList = createAction('SET_CADS_AI_ORG_TYPE_LIST');
export const setOrganizationFetching = createAction('SET_CADS_AI_ORGANIZATION_FETCHING');
export const setOrganizationError = createAction('SET_CADS_AI_ORGANIZATION_ERROR');
export const setAssignOrganizationList = createAction('SET_CADS_AI_ASSIGN_ORGANIZATION_LIST');
export const setOrganizationFilters = createAction('SET_CADS_AI_ORGANIZATION_LIST_FILTER');
export const setOrgState = createAction('SET_CADS_AI_ORGANIZATION_STATE');
export const toggleCheckedOrganization = createAction('TOGGLE_CADS_AI_CHECKED_ORGANIZATION');
export const unCheckAllOrganizations = createAction('UNCHECK_CADS_AI_ALL_ORGANIZATIONS');

export const getOrganizationsList = (filter) => async (dispatch) => {
  const defaultError = 'Error occurred getting organizations list';
  try {
    dispatch(setOrganizationFetching());
    const { data, isSuccess } = await api.cadsAIOrganization.getOrganizationList(filter);
    if (isSuccess) dispatch(setOrganizationList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setOrganizationError(error));
    console.error(defaultError, error.message);
  }
};

export const getOrganizationOptionsList = (filter) => async (dispatch) => {
  const defaultError = 'Error occurred getting organization options list';
  try {
    const { data, isSuccess } = await api.cadsAIOrganization.getOrganizationOptionsList(filter);
    if (isSuccess) dispatch(setOrganizationOptionsList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    console.error(defaultError, error.message);
  }
};

export const getOrganizationTypeList = () => async (dispatch) => {
  const defaultError = 'Error occurred getting organization options list';
  try {
    const { data, isSuccess } = await api.cadsAIOrganization.getOrganizationTypeList();
    if (isSuccess) dispatch(setOrganizationTypeList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    console.error(defaultError, error.message);
  }
};

export const toggleCheckedItemById = (organizationId) => (dispatch) => {
  dispatch(toggleCheckedOrganization(organizationId));
};

export const uncheckAllSelectedOrganizations = () => (dispatch) => {
  dispatch(unCheckAllOrganizations());
};
