import { Fragment } from 'react';

const SkillTab = ({ skills }) => {
  return (
    <Fragment>
      <div style={{ marginTop: '20px', color: '#1D3B55' }}>{skills}</div>
      <br />
      <br />
    </Fragment>
  );
};

export default SkillTab;
