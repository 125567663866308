import { handleActions } from 'redux-actions';
import {
  setSubCategoryList,
  setSubCategoryOptionList,
  fetchSubCategoryList,
  setSubCategoryError,
  setSubCategoryFilters,
  updateInSubCategoryList,
  deleteSubCategoryFromList,
} from './actions';

const defaultState = {
  loading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    searchText: '',
    status: null,
    isSortOrderDescending: false,
  },
  totalRecords: 0,
  data: [],
  options: [], // list of active subcategories with minimum details (Array<{id: number, title: string}>)
  error: null,
};

export default handleActions(
  {
    [fetchSubCategoryList](state) {
      return {
        ...state,
        loading: true,
        data: [],
      };
    },
    [setSubCategoryList](state, { payload }) {
      return {
        ...state,
        loading: false,
        data: payload.data.records,
        totalRecords: payload.data.totalRecords,
        error: null,
      };
    },
    [setSubCategoryOptionList](state, { payload }) {
      return {
        ...state,
        options: payload.data,
      };
    },
    [updateInSubCategoryList](state, { payload }) {
      return {
        ...state,
        data: state.data.map((subCategory) =>
          subCategory.id === payload.id
            ? { ...subCategory, name: payload.name, description: payload.description }
            : subCategory,
        ),
      };
    },
    [deleteSubCategoryFromList](state, { payload }) {
      return {
        ...state,
        data: state.data.filter((subCategory) => subCategory.id !== payload),
        totalRecords: state.totalRecords - 1,
      };
    },
    [setSubCategoryError](state, { payload }) {
      return {
        ...state,
        data: [],
        loading: false,
        error: payload?.message,
      };
    },
    [setSubCategoryFilters](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
      };
    },
  },
  defaultState,
);
