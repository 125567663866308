import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import AssessmentEditForm from './AssessmentEditForm';
import AssessmentEditDialogHeader from './AssessmentEditDialogHeader';

const AssessmentEditDialog = ({ show, onHide, id, isEdit, rtl }) => {
  const [modal, setModal] = useState({
    isOpen: show,
    title: `${isEdit ? 'Edit' : 'Add'} Assessment`,
    hide: onHide,
  });

  return (
    <Modal
      isOpen={show}
      toggle={modal.hide}
      keyboard={false}
      modalClassName={`${rtl.direction}-support`}
      className="modal-dialog--primary modal-dialog--header modal-edit-dialog"
    >
      <AssessmentEditDialogHeader modal={modal} />
      <AssessmentEditForm modal={modal} isEdit={isEdit} id={id} />
    </Modal>
  );
};

export default connect((state) => ({
  rtl: state.rtl,
}))(AssessmentEditDialog);
