import { Col, Row } from 'reactstrap';
import Select from 'react-select';
import React from 'react';
import moment from 'moment';

const allMonths = moment.months().map((item, index) => ({ value: index + 1, label: item }));
const currentYear = new Date().getFullYear();
// last 3 years
const levels = [
  {
    value: '',
    label: 'All',
  },
  {
    value: 1,
    label: 'Level 1',
  },
  {
    value: 2,
    label: 'Level 2',
  },
  {
    value: 3,
    label: 'Level 3',
  },
  {
    value: 4,
    label: 'Level 4',
  },
  {
    value: 5,
    label: 'Level 5',
  },
];
const years = [currentYear, currentYear - 1, currentYear - 2];

const StatsFilter = ({ filter, setFilter, isLevelDropdownVisible }) => {
  return (
    <Row>
      <Col md={12}>
        <div className="table__search_item float-right pb-4">
          <span>Year</span>
          <div style={{ width: '200px' }}>
            <Select
              name="date"
              value={filter.selectedYear}
              onChange={(selectedYear) => setFilter({ ...filter, selectedYear })}
              options={years.map((year) => ({ value: year, label: year }))}
              className="react-select"
              placeholder={'Select Year'}
              classNamePrefix="react-select"
            />
          </div>
        </div>
        <div className="table__search_item float-right pb-4">
          <span>Month</span>
          <div style={{ width: '200px' }}>
            <Select
              name="date"
              value={filter.selectedMonth}
              onChange={(selectedMonth) => setFilter({ ...filter, selectedMonth })}
              options={allMonths}
              className="react-select"
              placeholder={'Select Month'}
              classNamePrefix="react-select"
            />
          </div>
        </div>
        {isLevelDropdownVisible && (
          <div className="table__search_item float-right pb-4">
            <span>Levels</span>
            <div style={{ width: '200px' }}>
              <Select
                name="levels"
                value={filter.selectedLevel}
                onChange={(selectedLevel) => setFilter({ ...filter, selectedLevel })}
                options={levels}
                className="react-select"
                placeholder={'Select Levels'}
                classNamePrefix="react-select"
              />
            </div>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default StatsFilter;
