import { sendRequest } from '../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const dsCohortEndpoints = {
  GET_DS_COHORT_OPTIONS: '/getAllDSCohortsOptions',
  GET_DS_COHORT_LIST_BY_PROGRAM_ID: '/getCohortsByProgram',
};

const getDSCohortListOptions = () => {
  // return mockGetDSCohortListOptions();
  return sendRequest(
    {
      url: dsCohortEndpoints.GET_DS_COHORT_OPTIONS,
      method: httpMethod.GET,
    },
    process.env.REACT_APP_PROGRAM_ADMIN_ENDPOINT,
  );
};

const getCohortsByProgramId = (programId) => {
  return sendRequest(
    {
      url: dsCohortEndpoints.GET_DS_COHORT_LIST_BY_PROGRAM_ID,
      method: httpMethod.GET,
      params: {
        id: programId,
      },
    },
    process.env.REACT_APP_PROGRAM_ADMIN_ENDPOINT,
  );
};

const mockGetDSCohortListOptions = () => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [],
            data: [
              { id: 1, title: 'Cohort 1' },
              { id: 2, title: 'Cohort 2' },
              { id: 3, title: 'Cohort 3' },
              { id: 4, title: 'Cohort 4' },
            ],
          },
        }),
      500,
    );
  });
};

export default {
  getDSCohortListOptions,
  getCohortsByProgramId,
};
