import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loading from '@/shared/components/Loading';
import { paths } from '@/shared/helpers/paths';
import { AssessmentsPage } from './components/AssessmentPage';
import './index.scss';

export const assessmentsBaseUrl = paths.ASSESSMENTS.BASE;

export default function Assessments() {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path={assessmentsBaseUrl} component={AssessmentsPage} />
      </Switch>
    </Suspense>
  );
}
