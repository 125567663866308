import { sendRequest } from '../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const helpBaseUrl = process.env.REACT_APP_CADS_AI_API + process.env.REACT_APP_BASE_INFO_ENDPOINT;

export const helpEndpoints = {
  GET_ALL_HELPS: '/',
  GET_HELP_BY_ID: '/',
  ADD_HELP: '/',
  EDIT_HELP: '/',
  DEACTIVATE_HELP: '/',
  GET_HELP_STATUS_OPTIONS: '/',
};

const getHelpList = (filter) => {
  return getMockHelpList();
  return sendRequest({
    fullUrl: helpEndpoints.GET_ALL_HELPS,
    method: httpMethod.GET,
    params: filter,
  });
};

const getHelpById = (id) => {
  return getMockHelpById();
  return sendRequest({
    fullUrl: helpEndpoints.GET_HELP_BY_ID,
    method: httpMethod.GET,
    params: { id: id },
  });
};

const getHelpStatusOptions = () => {
  return getMockHelpStatusOption();
  return sendRequest({
    fullUrl: helpEndpoints.GET_HELP_STATUS_OPTIONS,
    method: httpMethod.GET,
  });
};

const updateStatusHelp = (id) => {
  return updateMockStatusHelp();
  return sendRequest({
    fullUrl: helpEndpoints.DEACTIVATE_HELP,
    method: httpMethod.DELETE,
    params: { id: id },
  });
};

const getMockHelpList = () => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [],
            data: {
              totalRecords: 3,
              records: [
                {
                  id: 1,
                  name: 'Mock Help 1',
                  subject: 'Mock Subject of the Help 1',
                  email: 'mock1@yopmail.com',
                  inquiredOn: '2023-06-27T00:00:00',
                  status: 'In Review',
                },
                {
                  id: 2,
                  name: 'Mock Help 2',
                  subject: 'Mock Subject of the Help 2',
                  email: 'mock2@yopmail.com',
                  inquiredOn: '2021-01-02T00:00:00',
                  status: 'Opened',
                },
                {
                  id: 3,
                  name: 'Mock Help 3',
                  subject: 'Mock Subject of the Help 3',
                  email: 'mock3@yopmail.com',
                  inquiredOn: '2023-06-07T00:00:00',
                  status: 'Closed',
                },
              ],
            },
          },
        }),
      500,
    );
  });
};

const getMockHelpById = () => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [],
            data: {
              id: 1,
              name: 'Mock Help 1',
              subject: 'Mock Subject of the Help 1',
              email: 'mock1@yopmail.com',
              inquiredOn: '2023-06-27T00:00:00',
              status: 'In Review',
              message:
                'It inserts dummy copy as paragraphs, lists, and regular text. Optionally available are extracts from a speech by Cicero, corporate nonsense, and a randomised',
            },
          },
        }),
      500,
    );
  });
};

const getMockHelpStatusOption = () =>
  new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [''],
            data: [
              {
                id: 1,
                title: 'In Review',
              },
              {
                id: 2,
                title: 'Closed',
              },
              {
                id: 3,
                title: 'Opened',
              },
            ],
          },
        }),
      500,
    );
  });

const updateMockStatusHelp = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          status: 200,
          messages: ['Help status successfully'],
        },
      });
    }, 500);
  });
};

export default {
  getHelpList,
  updateStatusHelp,
  getHelpById,
  getHelpStatusOptions,
};
