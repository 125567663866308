import React from 'react';
import { useForm } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import api from '@/utils/apiClient';
import { emptyStringPattern } from '@/shared/helpers';
import { FormField } from '@/shared/components/form/hook/FormField';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { SelectField } from '@/shared/components/form/Select';
import { getSubCategoriesOptionsList } from '@/containers/Admin/SkillBank/SubCategories/redux/actions';
import { isEmpty } from 'lodash/lang';
import EyeIcon from 'mdi-react/EyeIcon';
import LinkOffIcon from 'mdi-react/LinkOffIcon';
import { Link } from 'react-router-dom';
import { getSubcategoryViewUrl } from '@/containers/Admin/SkillBank/SubCategories/components/SubcategoryPage';

//TODO: put inside common files
const getColorForWeight = (weight) => {
  switch (weight) {
    case 0.1:
    case 0.2:
      return 'secondary';
    case 0.3:
    case 0.4:
      return 'primary';
    case 0.5:
    case 0.6:
      return 'success';
    case 0.7:
    case 0.8:
      return 'warning';
    case 0.9:
    case 1:
      return 'danger';
    default:
      return 'success';
  }
};

const DomainManageSubcategoryEditForm = ({ modal, id }) => {
  const dispatch = useDispatch();
  const { options: subcategoriesOpt } = useSelector((state) => state.subCategories, shallowEqual);

  //states
  const initialState = {
    loading: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    successMessage: '',
  };
  const [state, setState] = React.useState(initialState);
  const [subcategoriesOptions, setSubcategoriesOptions] = React.useState([]);
  const [associatedSubcategories, setAssociatedSubcategories] = React.useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = React.useState({});

  // removing subcategories form the options list, which are already associated
  const updateSubcategoriesOptions = (
    associatedSubcategories,
    subCategoriesOptions = subcategoriesOpt,
  ) => {
    const associatedSubcategoriesId = associatedSubcategories.map((subcategory) => +subcategory.id);
    setSubcategoriesOptions(
      subCategoriesOptions?.filter(
        (subcategory) => !associatedSubcategoriesId.includes(+subcategory.value),
      ),
    );
  };

  //fetch and set subcategories options
  const getSubcategoriesOptions = React.useCallback(() => {
    dispatch(getSubCategoriesOptionsList());
  }, []);
  React.useEffect(() => {
    getSubcategoriesOptions();
  }, []);
  React.useEffect(() => {
    const newOptions =
      subcategoriesOpt?.map((subcategory) => ({
        value: `${subcategory.id}`,
        label: subcategory.title,
      })) ?? [];
    setSubcategoriesOptions(newOptions);
    updateSubcategoriesOptions(associatedSubcategories, newOptions);
  }, [subcategoriesOpt, associatedSubcategories]);

  // fetches domain details by domain id
  const setDefaultData = async (domainId) => {
    try {
      const { data: res, isSuccess } = await api.domain.getDomainById(domainId);
      if (isSuccess) {
        const associatedSubcategories = res?.data?.subCategories ?? [];
        setAssociatedSubcategories(associatedSubcategories);
      } else {
        throw new Error(res?.messages?.[0]);
      }
    } catch ({ message }) {
      console.error('Error getting domain by id  : ', message);
      setState({ ...state, errorMessage: 'Error while getting attached subcategories details' });
    }
  };

  // change edit form default data if domain id changes
  React.useEffect(() => {
    if (id) {
      setDefaultData(+id);
    }
  }, [id]);

  // domain form related functions
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const resetForm = () => {
    reset({ subcategory: [], importance: [] });
    setState(initialState);
  };

  const deleteAssociatedSubcategory = async (subcategory) => {
    setState({ ...initialState, loading: true });
    const defaultError = `Error occurred removing subcategory from domain`;
    try {
      const { data, isSuccess } = await api.domain.unLinkSubcategoryFromDomain(+id, subcategory.id);
      if (isSuccess) {
        setState({
          ...state,
          loading: false,
          isSuccess: true,
          isError: false,
          successMessage: 'Subcategory removed from domain successfully',
        });
        setValue('subcategory', []); // empty selected subcategory
        setAssociatedSubcategories(
          associatedSubcategories.filter((aSubcategory) => aSubcategory.id !== subcategory.id),
        ); // add subcategory to category's associatedSubcategory
        setSubcategoriesOptions([
          ...subcategoriesOptions,
          { value: subcategory.id, label: subcategory.name },
        ]);
        setSelectedSubcategory({});
      } else {
        throw new Error(data?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({
        ...state,
        loading: false,
        isSuccess: false,
        isError: true,
        errorMessage: error.message,
      });
    }
  };

  const onSubmit = async ({ subcategory }) => {
    const subcategoryId = +subcategory.value;
    const subcategoryName = subcategory.label;

    setState({ ...initialState, loading: true });
    const defaultError = `Error occurred while adding new subcategory to domain`;
    try {
      //TODO: modify to add all new subcategories at once instead of adding subcategory one by one to domain
      const { data, isSuccess } = await api.domain.addSubcategoryToDomain(+id, [subcategoryId]);
      if (isSuccess) {
        setState({
          ...state,
          isSuccess: true,
          isError: false,
          successMessage: 'New Subcategory Added Successfully',
          loading: false,
        });
        setValue('subcategory', []); // empty selected subcategory
        const newAssociatedSubcategories = [
          ...associatedSubcategories,
          { id: subcategoryId, name: subcategoryName },
        ];
        setAssociatedSubcategories(newAssociatedSubcategories); // add subcategory to category's associatedSubcategory
        setDefaultData(+id);
      } else {
        throw new Error(data?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({ ...state, loading: false, isSuccess: false, errorMessage: error.message });
    }
  };

  const onCancel = () => {
    resetForm();
    modal.hide();
  };

  const renderLoaderAndMessages = () => (
    <div className="ml-4">
      {state.loading && (
        <div>
          <div className="panel__refresh position-relative" style={{ height: 75, width: 20 }}>
            <LoadingIcon />
            <div style={{ width: 200, padding: '23px 40px' }}>Adding new subcategory</div>
          </div>
        </div>
      )}
      {!state.loading && (state.isError || state.isSuccess) && (
        <table className="mt-3">
          <tr>
            <td>
              {state.isSuccess && <span className="lnr lnr-thumbs-up modal__title-icon success" />}
              {state.isError && <span className="lnr lnr-cross-circle modal__title-icon error" />}
            </td>
            <td className="pl-2">
              {state.isSuccess && state.successMessage}
              {state.isError && state.errorMessage}
            </td>
          </tr>
        </table>
      )}
    </div>
  );

  const renderAssociatedSubcategories = () => (
    <div>
      <span className="form__form-group-label mt-3 mb-1 fw-500">Associated Subcategories</span>
      <div>
        {associatedSubcategories?.map((subCategory) => (
          <Button
            key={subCategory.id}
            onClick={() => setSelectedSubcategory(subCategory)}
            outline={selectedSubcategory?.id !== subCategory.id}
            color="primary"
            size="sm"
          >
            {subCategory?.name}
          </Button>
        ))}
        {isEmpty(selectedSubcategory) && <p>Click on subcategory to view and mange details</p>}
      </div>
    </div>
  );

  const renderSelectedSubcategories = () => (
    <div className="selected_subcategory">
      <span className="form__form-group-label mt-3 mb-1 fw-500">Selected subcategory</span>
      <table className="project-summary__info ml-2">
        <tbody>
          <tr>
            <th>Name</th>
            <td>{selectedSubcategory?.name}</td>
          </tr>
          <tr>
            <th>Description</th>
            <td>{selectedSubcategory?.description}</td>
          </tr>
          <tr style={{ lineHeight: '2rem' }}>
            <th>Actions</th>
            <td>
              <Link to={getSubcategoryViewUrl(selectedSubcategory?.id)} target="_blank">
                <Button className="icon mb-0" color="success" outline size="sm">
                  <p>
                    <EyeIcon /> View
                  </p>
                </Button>
              </Link>
              <Button
                className="icon mb-0 ml-2"
                color="danger"
                outline
                onClick={() => deleteAssociatedSubcategory(selectedSubcategory)}
                size="sm"
              >
                <p>
                  <LinkOffIcon /> Unlink
                </p>
              </Button>
            </td>
          </tr>
          <tr>
            <th>Associated Skills</th>
          </tr>
          <tr>
            <td>
              {selectedSubcategory.skills?.map((skill) => (
                <Button color={getColorForWeight(+Math.random().toFixed(1) + 0.1)} size="sm">
                  {skill?.name}
                </Button>
              ))}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  return (
    <div>
      {/*Add/Edit Domain Form Status*/}
      {renderLoaderAndMessages()}

      {/*Add/Edit Domain Form*/}
      <div className="modal__body">
        <form
          className={`form`}
          onSubmit={handleSubmit(onSubmit)}
          style={{ borderBottom: '1px solid lightgray' }}
        >
          <div className="form__form-group mb-0" style={{ width: '84%' }}>
            <span className="form__form-group-label fw-500">Select Subcategory</span>
            <FormField
              style={{ height: 40 }}
              control={control}
              name="subcategory"
              component={SelectField}
              options={subcategoriesOptions}
              rules={{
                required: 'This is required field',
                pattern: emptyStringPattern,
              }}
              errors={errors}
              defaultValue=""
              isAboveError={true}
              placeholder="Search and select subcategory"
            />
          </div>
          <div className="form__form-group mb-0" style={{ width: '10%' }}>
            <span className="form__form-group-label" />
            <div className="subcategory_add_button">
              <Button color="success" type="submit" outline style={{ marginLeft: 20, height: 38 }}>
                Add
              </Button>
            </div>
          </div>
        </form>

        {renderAssociatedSubcategories()}
        {!isEmpty(selectedSubcategory) && renderSelectedSubcategories()}
      </div>
    </div>
  );
};

export default DomainManageSubcategoryEditForm;
