import React, { createContext, useContext } from 'react';

const LearningPathUIContext = createContext();

export function useLearningPathUIContext() {
  return useContext(LearningPathUIContext);
}

export function LearningPathUIProvider({ cadsAilearningPathUIEvents, isForAssignment, children }) {
  const value = {
    openViewLearningPathDialog: cadsAilearningPathUIEvents.openViewLearningPathDialog,
    openNewLearningPathDialog: cadsAilearningPathUIEvents.openNewLearningPathDialog,
    openEditLearningPathDialog: cadsAilearningPathUIEvents.openEditLearningPathDialog,
    openDeleteLearningPathDialog: cadsAilearningPathUIEvents.openDeleteLearningPathDialog,
    openUpdateStatusDialog: cadsAilearningPathUIEvents.openUpdateStatusDialog,
    isForAssignment,
  };
  return <LearningPathUIContext.Provider value={value}>{children}</LearningPathUIContext.Provider>;
}
