import React, { Fragment } from 'react';
import { FormField } from '@/shared/components/form/hook/FormField';
import { emptyStringPattern } from '@/shared/helpers';

const KnowledgeBaseThresholdFields = ({ control, errors }) => {
  return (
    <Fragment>
      <h6 className="bold-text mb-2 w-100">Thresholds</h6>
      <div className="form__form-group" style={{ width: '47%', marginRight: 15 }}>
        <span className="form__form-group-label">Time Limit (Minutes)</span>
        <div className="form__form-group-field">
          <FormField
            name="timeLimit"
            control={control}
            type="number"
            component="input"
            errors={errors}
            rules={{
              min: {value: 1, message: 'Can not have less than one minute'},
              required: 'This is required field',
              pattern: emptyStringPattern,
            }}
            defaultValue=""
            isAboveError={true}
            placeholder="Enter time limit"
          />
        </div>
      </div>
      <div className="form__form-group" style={{ width: '47%', marginRight: 15 }}>
        <span className="form__form-group-label">Score to Qualify (%)</span>
        <div className="form__form-group-field">
          <FormField
            name="scoreToQualify"
            control={control}
            type="number"
            component="input"
            errors={errors}
            rules={{
              min: {value: 1, message: "Percentage can't be less than 100"},
              max: {value: 100, message: "Percentage can't be greater than 100"},
              required: 'This is required field',
              pattern: emptyStringPattern,
            }}
            defaultValue=""
            isAboveError={true}
            placeholder="Enter required qualifying score"
          />
        </div>
      </div>
    </Fragment>
  );
};

export default KnowledgeBaseThresholdFields;
