import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { cadsAiEmployeesBaseUrl } from '../index';
import { EmployeesUIProvider } from './EmployeesUIProvider';
import { EmployeesCard } from './EmployeesCard';
import EmployeeEditDialog from './employee-add-edit-dialog/EmployeeEditDialog';
import EmployeeViewDialog from './employees-view-dialog/EmployeeViewDialog';
import { setEmployeeFilters, uncheckAllEmployee } from '../redux/actions';
import { useDispatch } from 'react-redux';
import EmployeeDeleteDialog from './employee-delete-dialog/EmployeeDeleteDialog';
import EmployeeQRDialog from "@/containers/Cads-AI/Employees/components/employees-qr-dialog/EmployeeQRDialog";

export function EmployeesPage({ history, match }) {
  const dispatch = useDispatch();

  //clean up
  React.useEffect(() => () => dispatch(uncheckAllEmployee()), []);

  const employeeUIEvents = {
    openViewEmployeesDialog: (slug) => {
      history.push(`${cadsAiEmployeesBaseUrl}/${slug}/view`);
    },
    openNewEmployeesDialog: () => {
      history.push(`${cadsAiEmployeesBaseUrl}/new`);
    },
    openEditEmployeesDialog: (slug, id) => {
      history.push(`${cadsAiEmployeesBaseUrl}/${id}/${slug}/edit`);
    },
    openDeleteEmployeesDialog: (id) => {
      history.push(`${cadsAiEmployeesBaseUrl}/${id}/delete`);
    },
    openEmployeeQRDialog: (slug) => {
      history.push(`${cadsAiEmployeesBaseUrl}/${slug}/qr`);
    }
  };

  return (
    <EmployeesUIProvider employeeUIEvents={employeeUIEvents}>
      <Switch>
        <Route path={`${cadsAiEmployeesBaseUrl}/new`}>
          {({ history, match }) => (
            <EmployeeEditDialog
              show={match != null}
              isEdit={false}
              onHide={() => {
                dispatch(setEmployeeFilters());
                history.push(cadsAiEmployeesBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${cadsAiEmployeesBaseUrl}/:id/:slug/edit`}>
          {({ history, match }) => (
            <EmployeeEditDialog
              show={match != null}
              isEdit={true}
              id={match?.params?.id}
              slug={match?.params?.slug}
              onHide={() => {
                dispatch(setEmployeeFilters());
                history.push(cadsAiEmployeesBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${cadsAiEmployeesBaseUrl}/:id/delete`}>
          {({ history, match }) => (
            <EmployeeDeleteDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                dispatch(setEmployeeFilters());
                history.push(cadsAiEmployeesBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${cadsAiEmployeesBaseUrl}/:slug/view`}>
          {({history, match}) => (
              <EmployeeViewDialog
                  show={match != null}
                  slug={match?.params?.slug}
                  onHide={() => {
                    history.push(cadsAiEmployeesBaseUrl);
                  }}
              />
          )}
        </Route>
        <Route path={`${cadsAiEmployeesBaseUrl}/:slug/qr`}>
          {({history, match}) => (
              <EmployeeQRDialog
                  show={match != null}
                  slug={match?.params?.slug}
                  onHide={() => {
                    history.push(cadsAiEmployeesBaseUrl);
                  }}
              />
          )}
        </Route>
      </Switch>
      <EmployeesCard/>
    </EmployeesUIProvider>
  );
}
