export const logoSizeOptions = [
  { value: 'actual', label: 'Actual' },
  { value: 'small', label: 'Small' },
  { value: 'medium', label: 'Medium' },
  { value: 'large', label: 'Large' },
];

export const logoPositions = [
  { value: 'left', label: 'Left' },
  { value: 'center', label: 'Center' },
  { value: 'right', label: 'Right' },
];

export const contactFieldTypeOptions = [
  { value: 'text', label: 'Text' },
  { value: 'textarea', label: 'Text Area' },
  { value: 'number', label: 'Number' },
  { value: 'date', label: 'Date' },
  { value: 'email', label: 'Email' },
  { value: 'country', label: 'Country' },
  { value: 'state', label: 'State' },
  { value: 'city', label: 'City' },
];

export const LikertViewOptions = {
  HORIZONTAL: 'Horizontal',
  VERTICAL: 'Vertical',
};

export const questionTypes = {
  MCQ_MULTI_SELECT: { value: 1, label: 'Multiple Choice(Multiple Answers)' },
  MCQ_SINGLE_SELECT: { value: 2, label: 'Multiple Choice(Single Answer)' },
  FILE_UPLOAD: { value: 3, label: 'File Upload' },
  CONTACT_INFO: { value: 4, label: 'Contact Information' },
  COMMENT_BOX: { value: 5, label: 'Comment Box' },
  DATE: { value: 6, label: 'Date' },
  LIKERT: { value: 7, label: 'Likert(n points)' },
  DROPDOWN: { value: 8, label: 'Dropdown' },
  MATRIX_RATING_QUESTION: { value: 9, label: 'Matrix Rating Question' },
  SCALE_RATING_QUESTION: { value: 10, label: 'Scale Rating Question' },
  OPEN_TEXT_QUESTION: { value: 11, label: 'Open Text Question' },
};

export const questionTypeOptions = [
  questionTypes.MCQ_MULTI_SELECT,
  questionTypes.MCQ_SINGLE_SELECT,
  questionTypes.FILE_UPLOAD,
  questionTypes.CONTACT_INFO,
  questionTypes.COMMENT_BOX,
  questionTypes.DATE,
  questionTypes.LIKERT,
  questionTypes.DROPDOWN,
  questionTypes.MATRIX_RATING_QUESTION,
  questionTypes.SCALE_RATING_QUESTION,
  questionTypes.OPEN_TEXT_QUESTION,
];
