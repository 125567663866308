import { sendRequest } from '../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const moduleScheduleEndpoints = {
  GET_SCHEDULE_DTL_LIST_BY_MODULE_ID: `/getSchedulesByModule`,
  GET_SCHEDULE_DTL_LIST_BY_MODULE_ID_SS: `/getScheduleDtlsByModuleId`,
  GET_TRAINING_PROVIDER_OPTION_LIST: `/getAllTrainingProviderOptions`,
  GET_CONTENT_PROVIDER_OPTION_LIST: `/getAllContentTypeOptions`,
  GET_MEETING_TYPE_OPTION_LIST: `/getAllMeetingTypeOptions`,
  GET_TRAINING_DURATION_OPTION_LIST: `/getAllTrainingDurationOptions`,
  GET_DELIVERY_LOCATION_TYPE_OPTION_LIST: `/getAllDeliveryLocationTypeOptions`,
  GET_DELIVERY_METHOD_TYPE_OPTION_LIST: `/getAllModuleTypeOptions`,
  ADD_NEW_SCHEDULE: `/addScheduleDetails`,
  EDIT_SCHEDULE: `/updateScheduleDetails`,
  GET_SCHEDULES_BY_MODULE_RECOM_ID: `/getSchedulesByModuleRecomId`,
  GET_SCHEDULES_BY_CLIENT_MODULE_ID: `/getSchedulesByClientModuleId`,
};

const getModuleScheduleList = (filter) => {
  // return getMockModuleScheduleList();
  return sendRequest(
    {
      url: moduleScheduleEndpoints.GET_SCHEDULE_DTL_LIST_BY_MODULE_ID,
      method: httpMethod.GET,
      params: { ...filter, searchText: filter.search },
    },
    process.env.REACT_APP_CONTROL_PANEL_ENDPOINT,
  );
};

const getModuleScheduleListSS = (moduleId) => {
  return sendRequest(
    {
      url: moduleScheduleEndpoints.GET_SCHEDULE_DTL_LIST_BY_MODULE_ID_SS,
      method: httpMethod.GET,
      params: { id: moduleId },
    },
    process.env.REACT_APP_CONTROL_PANEL_SS_ENDPOINT,
  );
};

const getTrainingProviderOptionList = () => {
  // return getMockTrainingProviderOptionList();
  return sendRequest(
    {
      url: moduleScheduleEndpoints.GET_TRAINING_PROVIDER_OPTION_LIST,
      method: httpMethod.GET,
    },
    process.env.REACT_APP_CONTROL_PANEL_SS_ENDPOINT,
  );
};

const getContentProviderOptionList = () => {
  // return getMockContentProviderOptionList();
  return sendRequest(
    {
      url: moduleScheduleEndpoints.GET_CONTENT_PROVIDER_OPTION_LIST,
      method: httpMethod.GET,
    },
    process.env.REACT_APP_CONTROL_PANEL_SS_ENDPOINT,
  );
};

const getMeetingTypeOptionList = () => {
  // return getMockMeetingTypeOptionList();
  return sendRequest(
    {
      url: moduleScheduleEndpoints.GET_MEETING_TYPE_OPTION_LIST,
      method: httpMethod.GET,
    },
    process.env.REACT_APP_CONTROL_PANEL_SS_ENDPOINT,
  );
};

const getTrainingDurationOptionList = () => {
  // return getMockTrainingDurationOptionList();
  return sendRequest(
    {
      url: moduleScheduleEndpoints.GET_TRAINING_DURATION_OPTION_LIST,
      method: httpMethod.GET,
    },
    process.env.REACT_APP_CONTROL_PANEL_SS_ENDPOINT,
  );
};

const getDeliveryLocationTypeOptionList = () => {
  // return getMockDeliveryLocationTypeOptionList();
  return sendRequest(
    {
      url: moduleScheduleEndpoints.GET_DELIVERY_LOCATION_TYPE_OPTION_LIST,
      method: httpMethod.GET,
    },
    process.env.REACT_APP_CONTROL_PANEL_SS_ENDPOINT,
  );
};

const getDeliveryMethodTypeOptionList = () => {
  // return getMockDeliveryMethodTypeOptionList();
  return sendRequest(
    {
      url: moduleScheduleEndpoints.GET_DELIVERY_METHOD_TYPE_OPTION_LIST,
      method: httpMethod.GET,
    },
    process.env.REACT_APP_CONTROL_PANEL_SS_ENDPOINT,
  );
};

const addModuleSchedule = (schedule) => {
  // return getMockDeliveryMethodTypeOptionList();
  return sendRequest(
    {
      url: moduleScheduleEndpoints.ADD_NEW_SCHEDULE,
      method: httpMethod.POST,
      data: schedule,
    },
    process.env.REACT_APP_CONTROL_PANEL_ENDPOINT,
  );
};

const editModuleSchedule = (schedule) => {
  // return getMockDeliveryMethodTypeOptionList();
  return sendRequest(
    {
      url: moduleScheduleEndpoints.EDIT_SCHEDULE,
      method: httpMethod.PUT,
      data: schedule,
    },
    process.env.REACT_APP_CONTROL_PANEL_ENDPOINT,
  );
};

const getSchedulesByModuleRecomId = (moduleRecomId) => {
  return sendRequest(
    {
      url: moduleScheduleEndpoints.GET_SCHEDULES_BY_MODULE_RECOM_ID,
      method: httpMethod.GET,
      params: { id: moduleRecomId },
    },
    process.env.REACT_APP_CONTROL_PANEL_ENDPOINT,
  );
};

const getSchedulesByClientModuleId = (clientModuleId) => {
  return sendRequest(
    {
      url: moduleScheduleEndpoints.GET_SCHEDULES_BY_CLIENT_MODULE_ID,
      method: httpMethod.GET,
      params: { id: clientModuleId },
    },
    process.env.REACT_APP_CONTROL_PANEL_ENDPOINT,
  );
};

export default {
  getModuleScheduleList,
  getModuleScheduleListSS,
  getTrainingProviderOptionList,
  getContentProviderOptionList,
  getMeetingTypeOptionList,
  getTrainingDurationOptionList,
  getDeliveryLocationTypeOptionList,
  getDeliveryMethodTypeOptionList,
  addModuleSchedule,
  editModuleSchedule,
  getSchedulesByModuleRecomId,
  getSchedulesByClientModuleId,
};

const getMockModuleScheduleList = () =>
  new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [''],
            data: [
              {
                id: 1,
                name: 'Mock Module Schedule 1',
                description: 'Description for schedule 1',
                tagline: 'tagline',
                summary: 'sum',
                trainingProvider: { id: 1, name: 'T Provider 1' },
                contentProvider: { id: 1, name: 'C Provider 1' },
                isPlatformWide: true,
                slug: 'myslug',
                ssoUrl: 'sso url',
                instructorEvaluationUrl: 'inst url',
                meetingLinkUrl: 'meet url',
                wooCommerceProductCode: 'product code',
                providerCourseCode: 'course code',
                meetingType: { id: 1, name: 'Meeting Type 1' },
                completionTimeInHours: 3,
                isMainVariant: false,
                duration: { id: 1, name: 'Duration 1', durationInDays: 2 },
                deliveries: [
                  {
                    id: 1, // out deliveriesId : 1,
                    startDate: '2022-04-01T00:00:00', //out
                    endDate: '2022-04-10T00:00:00', //out
                    locationNotes: 'My Notes', //out
                    isPublic: true, //out
                    method: { id: 1, name: 'Method Type 1' }, // out
                    locationType: { id: 1, name: 'Location Type 1' }, //out
                  },
                ],
              },
            ],
          },
        }),
      500,
    );
  });

const getMockTrainingProviderOptionList = () =>
  new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [''],
            data: [
              { id: 1, name: 'T Provider 1' },
              { id: 2, name: 'T Provider 2' },
            ],
          },
        }),
      500,
    );
  });

const getMockContentProviderOptionList = () =>
  new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [''],
            data: [
              { id: 1, name: 'C Provider 1' },
              { id: 2, name: 'C Provider 2' },
            ],
          },
        }),
      500,
    );
  });

const getMockMeetingTypeOptionList = () =>
  new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [''],
            data: [
              { id: 1, name: 'Meeting Type 1' },
              { id: 2, name: 'Meeting Type 2' },
            ],
          },
        }),
      500,
    );
  });

const getMockTrainingDurationOptionList = () =>
  new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [''],
            data: [
              { id: 1, name: 'Duration 1', durationInDays: 10 },
              { id: 2, name: 'Duration 2', durationInDays: 20 },
            ],
          },
        }),
      500,
    );
  });

const getMockDeliveryLocationTypeOptionList = () =>
  new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [''],
            data: [
              { id: 1, name: 'Location Type 1' },
              { id: 2, name: 'Location Type 2' },
            ],
          },
        }),
      500,
    );
  });

const getMockDeliveryMethodTypeOptionList = () =>
  new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [''],
            data: [
              { id: 1, name: 'Method Type 1' },
              { id: 2, name: 'Method Type 2' },
            ],
          },
        }),
      500,
    );
  });
