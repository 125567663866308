import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import api from '@/utils/apiClient';
import OrganizationTable from './OrganizationTable';
import { useOrganizationUIContext } from '../OrganizationUIProvider';

const OrganizationTableWrapper = () => {
  const organizationUIContext = useOrganizationUIContext();
  const { checkedOrganizationIds } = useSelector((state) => state.cadsAIOrgs, shallowEqual);
  const organizationFilter = useSelector((state) => state?.cadsAIOrgs?.filters);

  const downloadCSV = async () => {
    const csvData = await api.cadsAIOrganization.getOrganizationListCSV({
      ...organizationFilter,
      pageSize: 1000, //TODO: remove this hack, change be to give all result when page size not passed
      pageNumber: 1,
    });
    require('js-file-download')(
      csvData,
      `Organization_List__${new Date().toLocaleString().replace(/[\/:,\s]/gi, '_')}.csv`,
    );
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="products-list">
          <div className="card__title">
            <h5 className="bold-text">Organizations List</h5>
            <ButtonToolbar className="products-list__btn-toolbar-top">
              <Button color="success" onClick={downloadCSV} outline>
                Export Organizations
              </Button>
              {/*<Button color="success" onClick={organizationUIContext.openOrganizationTypeDialog} outline disabled={checkedOrganizationIds?.length === 0}>
                Update Type
              </Button>*/}
              <Button color="primary" onClick={organizationUIContext.openNewOrganizationDialog}>
                Add new Organizations
              </Button>
              <Button
                  color="danger"
                  onClick={organizationUIContext.openDeactivateOrganizationDialog}
                  outline
                  disabled={checkedOrganizationIds?.length === 0}
              >
                Deactivate
              </Button>
            </ButtonToolbar>
          </div>
          <OrganizationTable />
        </CardBody>
      </Card>
    </Col>
  );
};

export default OrganizationTableWrapper;
