import Collapse from '@/shared/components/Collapse';
import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { updateConfigPageDetails } from '../redux/actions';
import { toolbarOptions } from '@/shared/helpers/constants';
import ReactQuill from 'react-quill';

const PageDetailsCollapse = ({ id, title, description }) => {
  const dispatch = useDispatch();

  const handleChange = (fieldName, fieldValue) => {
    dispatch(updateConfigPageDetails({ id, [fieldName]: fieldValue }));
  };

  return (
    <Collapse title="Title & Description">
      <div className="form">
        <div className="form__form-group w-100">
          <span className="form__form-group-label">Page Title</span>
          <div className="form__form-group-field">
            <ReactQuill
              key={id}
              value={title}
              modules={toolbarOptions}
              onChange={(title) => handleChange('title', title)}
            />
          </div>
        </div>
        <div className="form__form-group w-100">
          <span className="form__form-group-label">Page Description</span>
          <div className="form__form-group-field">
            <ReactQuill
              key={id}
              value={description}
              modules={toolbarOptions}
              onChange={(description) => handleChange('description', description)}
            />
          </div>
        </div>
      </div>
    </Collapse>
  );
};

export default memo(PageDetailsCollapse);
