import React, { createContext, useContext } from 'react';

const EventsUIContext = createContext();

export function useEventUIContext() {
  return useContext(EventsUIContext);
}

export function EventsUIProvider({ eventsUIEvents, children }) {
  const value = {
    openViewEventDialog: eventsUIEvents.openViewEventDialog,
    openNewEventDialog: eventsUIEvents.openNewEventDialog,
    openEditEventDialog: eventsUIEvents.openEditEventDialog,
    openDeleteEventDialog: eventsUIEvents.openDeleteEventDialog,
  };

  return <EventsUIContext.Provider value={value}>{children}</EventsUIContext.Provider>;
}
