import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loading from '@/shared/components/Loading';
import { paths } from '@/shared/helpers/paths';
import { ProgramsPage } from './components/ProgramsPage';
import './index.scss';

export const cadsAiProgramsBaseUrl = paths.CADS_AI.DATA_STAR.PROGRAMS;

const Programs = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path={cadsAiProgramsBaseUrl} component={ProgramsPage} />
      </Switch>
    </Suspense>
  );
};

export default Programs;
