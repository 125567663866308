import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import PinnedQuestionEditDialogHeader from "./PinnedQuestionEditDialogHeader";
import PinnedQuestionEditForm from "./PinnedQuestionEditForm";

const PinnedQuestionEditDialog = ({id, show, isEdit, onHide, rtl}) => {

  const [modal, setModal] = useState({
    isOpen: show,
    title: isEdit ? 'Update Pinned Question' : 'Pin New Question(s)',
    hide: onHide,
  });

  return (
      <Modal
          isOpen={show}
          toggle={modal.hide}
          backdrop="static"
          keyboard={false}
          modalClassName={`${rtl.direction}-support theme-light`}
          className="modal-dialog--primary modal-dialog--header modal-edit-dialog"
      >
        <PinnedQuestionEditDialogHeader modal={modal}/>
        <PinnedQuestionEditForm modal={modal} isEdit={isEdit} id={id}/>
      </Modal>
  );

}

export default connect((state) => ({
  rtl: state.rtl,
}))(PinnedQuestionEditDialog);
