import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { useProgramsUIContext } from './ProgramsUIProvider';
import ProgramsTableWrapper from './table/ProgramsTableWrapper';
import api from '@/utils/apiClient';
import { getProgramCount } from '../redux/actions';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

export function ProgramsCard() {
  const programsUIContext = useProgramsUIContext();
  const programsUIProps = useMemo(() => {
    return {
      openNewProgramsDialog: programsUIContext.openNewProgramsDialog,
    };
  }, [programsUIContext]);
  const [applicantCount, setApplicantCount] = useState();
  const dispatch = useDispatch();
  const { programCount } = useSelector((state) => state.cadsAIPrograms, shallowEqual);

  const getApplicantCount = async (filters) => {
    // TODO: this API is retuning 401, i have commented it for temporary
    // try {
    //   const { data: res, isSuccess } = await api.dashboard.getApplicantCount(
    //     filters
    //   );
    //   if (isSuccess) {
    //     setApplicantCount(res?.data?.count);
    //   } else {
    //     throw new Error("error while fetching registered talent");
    //   }
    // } catch (error) {
    //   console.error("Error inside dashboard registered talent", error.message);
    // }
  };

  useEffect(() => {
    dispatch(getProgramCount({ status: true }));
    getApplicantCount({ status: true });
  }, []);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Programs</h3>
        </Col>
      </Row>
      <Row>
        <ProgramsTableWrapper />
      </Row>
    </Container>
  );
}
