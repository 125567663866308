import React, { useState } from 'react';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';

const RenderUserDetails = ({ user }) => (
  <table className="project-summary__info">
    <tbody>
      <tr>
        <th>Name</th>
        <td>{user?.name}</td>
      </tr>
      <tr>
        <th>Email</th>
        <td>{user?.email}</td>
      </tr>
      <tr>
        <th>Contact</th>
        <td>{user?.contact}</td>
      </tr>
      <tr>
        <th>Blog Link</th>
        <td>
          <a href={user?.blog_link} target="_blank">
            {user?.blog_link}
          </a>
        </td>
      </tr>
      <tr>
        <th>Experience Link</th>
        <td>
          <a href={user?.experience_link} target="_blank">
            {user?.experience_link}
          </a>
        </td>
      </tr>
      <tr>
        <th>Portfolio Link</th>
        <td>
          <a href={user?.portfolio_link} target="_blank">
            {user?.portfolio_link}
          </a>
        </td>
      </tr>
    </tbody>
  </table>
);

const RenderLiteracySkills = ({ user }) => (
  <table className="project-summary__info">
    <tbody>
      <tr>
        <th>Skill</th>
        <td>{user?.literacy_skills.toString()}</td>
      </tr>
    </tbody>
  </table>
);

const RenderOtherSkills = ({ user }) => (
  <table className="project-summary__info">
    <tbody>
      <tr>
        <th>Skill</th>
        <td>{user?.other_skills.toString()}</td>
      </tr>
    </tbody>
  </table>
);

const UserViewTabs = ({ user }) => {
  const tabs = {
    USER: { id: 'USER', label: 'User Details', render: <RenderUserDetails user={user} /> },
    LITERACY_SKILLS: {
      id: 'LITERACY_SKILLS',
      label: 'Literacy Skills',
      render: <RenderLiteracySkills user={user} />,
    },
    OTHER_SKILLS: {
      id: 'OTHER_SKILLS',
      label: 'Other Skills',
      render: <RenderOtherSkills user={user} />,
    },
  };

  const [activeTab, setActiveTab] = useState(tabs.USER.id);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Row className="p-1">
      <Col>
        <div className="tabs tabs--bordered-bottom">
          <div className="tabs__wrap">
            <Nav tabs>
              {Object.keys(tabs).map((key) => (
                <NavItem key={tabs[key].id}>
                  <NavLink
                    className={classnames({ active: activeTab === tabs[key].id })}
                    onClick={() => toggle(tabs[key].id)}
                  >
                    {tabs[key].label}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={activeTab}>{tabs[activeTab].render}</TabPane>
            </TabContent>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default UserViewTabs;
