import React, { Fragment, useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import { Badge, Button } from 'reactstrap';
import { useEmailUIContext } from '../EmailUIProvider';
import EmailTableColumns, { EmailColumns } from './EmailTableColumns';
import { EmailTableSearchBar } from './EmailTableSearchBar';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getEmailList, setEmailFilters } from '../../redux/actions';
import EyeIcon from 'mdi-react/EyeIcon';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { defaultPaginationProps } from '@/shared/helpers';
import moment from 'moment';

const EmailTable = () => {
  const emailsUIContext = useEmailUIContext();
  const dispatch = useDispatch();
  const {
    data: emails,
    error,
    filters,
    loading,
    totalRecords,
  } = useSelector((state) => state.cadsAIEmails, shallowEqual);
  const {
    pageSize: rowsPerPage,
    pageNumber: page,
    sortBy: orderBy,
    isSortOrderDescending,
  } = filters;

  const [columns, setColumns] = useState(0);

  useEffect(() => {
    setColumns(EmailColumns.length);
  }, [EmailColumns.length]);

  React.useEffect(() => {
    dispatch(getEmailList(filters));
  }, [filters]);

  const handleRequestSort = (property) => {
    dispatch(
      setEmailFilters({
        sortBy: property,
        isSortOrderDescending: !isSortOrderDescending,
      }),
    );
  };

  const renderActionButtons = (emailId) => (
    <div className="actions">
      <Button
        className="icon mb-0"
        color="success"
        outline
        onClick={() => emailsUIContext.openViewEmailDialog(emailId)}
      >
        <p>
          <EyeIcon /> View
        </p>
      </Button>
    </div>
  );

  const handleChangePage = (currentPage) => {
    dispatch(setEmailFilters({ pageSize: rowsPerPage, pageNumber: currentPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setEmailFilters({ pageSize: +event.target.value, pageNumber: 1 }));
  };

  const emptyRows =
    page === Math.ceil(totalRecords / rowsPerPage) ? rowsPerPage - emails.length : 0;

  return (
    <Fragment>
      <div className="material-table__wrap">
        <EmailTableSearchBar />
        <Table className="material-table">
          <EmailTableColumns
            order={isSortOrderDescending ? 'desc' : 'asc'}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rowsPerPage}
          />
          <TableBody>
            {(loading || !!error) && (
              <TableRow>
                <TableCell colSpan={columns}>
                  {loading && (
                    <div className={'text-center overflow-hidden'}>
                      <div className="panel__refresh position-relative" style={{ height: 75 }}>
                        <LoadingIcon />
                      </div>
                    </div>
                  )}
                  <div className={'text-center'}>
                    <p className={!!error ? 'm-5' : ''}>{error || 'Loading emails...'}</p>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {!!!emails.length && !loading && !error && (
              <TableRow style={{ height: 120 }}>
                <TableCell colSpan={columns} align="center">
                  {filters.status === null && filters.search === ''
                    ? 'No Emails'
                    : 'No Emails with applied filters'}
                </TableCell>
              </TableRow>
            )}
            {emails.map((email) => {
              return (
                <TableRow className="material-table" tabIndex={-1} key={email?.id}>
                  <TableCell className="material-table__cell" align="center">
                    {email?.subject}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {moment(email?.date).format('DD/MM/yyyy')}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    <Badge
                      color={`${email?.status === 'draft' ? 'primary' : 'secondary'}`}
                      style={{ marginRight: 24 }}
                    >
                      {email?.status ?? 'NA'}
                    </Badge>
                  </TableCell>
                  <TableCell
                    align="center"
                    className="material-table__cell"
                    style={{ minWidth: 250 }}
                  >
                    {renderActionButtons(email?.id)}
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={columns} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        component={defaultPaginationProps.component}
        backIconButtonProps={defaultPaginationProps.backIconButton}
        nextIconButtonProps={defaultPaginationProps.nextIconButton}
        rowsPerPageOptions={defaultPaginationProps.rowsPerPage}
        className={defaultPaginationProps.className}
        dir={defaultPaginationProps.dir}
      />
    </Fragment>
  );
};

export default EmailTable;
