import { httpMethod } from '@/shared/helpers/httpMethod.enum';
import { sendRequest } from '../base/ApiService';

const CADS_API_ENDPOINT =
  process.env.REACT_APP_CADS_AI_API + process.env.REACT_APP_CADS_AI_LEARNING_PATH_ENDPOINT;

const cadsAIModuleEndpoints = {
  GET_ALL_MODULES: '/getAllModules',
  GET_MODULE_TYPE_OPTIONS: '/getModuleTypeOptions',
  GET_DATA_SKILL_OPTIONS: '/getDataSkillOptions',
  GET_OTHER_DATA_SKILL_OPTIONS: '/getOtherDataSkillOptions',
  GET_PRE_REQ_MODULE_OPTIONS: '/getModuleOptions',
  DELETE_MODULE: '/deleteModulesById',
  ADD_MODULE: '/addModule',
  UPDATE_MODULE: '/updateModuleById',
  GET_MODULE_BY_ID: '/getModuleById',
};

const getCadsAiModules = (filter) => {
  return sendRequest({
    fullUrl: CADS_API_ENDPOINT + cadsAIModuleEndpoints.GET_ALL_MODULES,
    method: httpMethod.GET,
    params: filter,
  });
};

const getPrerequisiteModuleOptions = () => {
  return sendRequest({
    fullUrl: CADS_API_ENDPOINT + cadsAIModuleEndpoints.GET_PRE_REQ_MODULE_OPTIONS,
    method: httpMethod.GET,
  });
};

const deleteModule = (moduleId) => {
  return sendRequest({
    fullUrl: CADS_API_ENDPOINT + cadsAIModuleEndpoints.DELETE_MODULE,
    method: httpMethod.DELETE,
    params: { id: moduleId },
  });
};

const getModuleById = (moduleId) => {
  // return getMockModuleById();
  return sendRequest({
    fullUrl: CADS_API_ENDPOINT + cadsAIModuleEndpoints.GET_MODULE_BY_ID,
    method: httpMethod.GET,
    params: { id: moduleId },
  });
};

/* In Progress APIS */
const cadsAiAddModule = (moduleData) => {
  return sendRequest({
    fullUrl: CADS_API_ENDPOINT + cadsAIModuleEndpoints.ADD_MODULE,
    method: httpMethod.POST,
    data: moduleData,
  });
};

const cadsAiEditModule = (moduleData) => {
  return sendRequest({
    fullUrl: CADS_API_ENDPOINT + cadsAIModuleEndpoints.UPDATE_MODULE,
    method: httpMethod.PUT,
    data: moduleData,
  });
};

export default {
  getCadsAiModules,
  cadsAiAddModule,
  cadsAiEditModule,
  getPrerequisiteModuleOptions,
  deleteModule,
  getModuleById,
};
