import React, { Fragment, useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import SponsorsTableColumns, { SponsorsColumn } from './SponsorsTableColumns';
import { SponsorsTableSearchBar } from './SponsorsTableSearchBar';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { defaultPaginationProps } from '@/shared/helpers';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { getSponsorsList, setSponsorFilters } from '../../redux/actions';
import PencilIcon from 'mdi-react/PencilIcon';
import { Button } from 'reactstrap';
import { useSponsorUIContext } from '../SponsorsUIProvider';

const SponsorsTable = () => {
  const dispatch = useDispatch();
  const sponsorUIEvent = useSponsorUIContext();
  const {
    data: sponsors,
    error,
    filters,
    loading,
    totalRecords,
  } = useSelector((state) => state.cadsAISponsor, shallowEqual);
  const {
    pageSize: rowsPerPage,
    pageNumber: page,
    sortBy: orderBy,
    isSortOrderDescending,
  } = filters;
  const [columns, setColumns] = useState(0);

  useEffect(() => {
    setColumns(SponsorsColumn.length);
  }, [SponsorsColumn.length]);

  useEffect(() => {
    dispatch(getSponsorsList(filters));
  }, [filters]);

  const handleRequestSort = (event, property) => {
    dispatch(
      setSponsorFilters({
        sortBy: property,
        isSortOrderDescending: !isSortOrderDescending,
      }),
    );
  };

  const renderActionButtons = (id) => (
    <div className="actions">
      <Button
        className="icon mb-0"
        color="primary"
        outline
        onClick={() => sponsorUIEvent.openEditSponsorDialog(id)}
      >
        <p>
          <PencilIcon /> Edit
        </p>
      </Button>
    </div>
  );

  const handleChangePage = (event, currentPage) => {
    dispatch(setSponsorFilters({ pageSize: rowsPerPage, pageNumber: currentPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setSponsorFilters({ pageSize: +event.target.value, pageNumber: 1 }));
  };

  const emptyRows = page === Math.ceil(2 / 1) ? rowsPerPage - sponsors.length : 0;

  return (
    <Fragment>
      <div className="material-table__wrap">
        <SponsorsTableSearchBar />
        <Table className="material-table">
          <SponsorsTableColumns
            order={isSortOrderDescending ? 'desc' : 'asc'}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rowsPerPage}
          />
          <TableBody>
            {(loading || !!error) && (
              <TableRow>
                <TableCell colSpan={columns}>
                  {loading && (
                    <div className={'text-center overflow-hidden'}>
                      <div className="panel__refresh position-relative" style={{ height: 75 }}>
                        <LoadingIcon />
                      </div>
                    </div>
                  )}
                  <div className={'text-center'}>
                    <p className={!!error ? 'm-5' : ''}>{error || 'Loading sponsors...'}</p>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {!!!sponsors?.length && !loading && (
              <TableRow style={{ height: 120 }}>
                <TableCell colSpan={columns} align="center">
                  {filters.status === null && filters.search === ''
                    ? 'No Sponsors'
                    : 'No Sponsors with applied filters'}
                </TableCell>
              </TableRow>
            )}
            {sponsors.map((sponsor) => {
              return (
                <TableRow className="material-table" tabIndex={-1} key={sponsor?.id}>
                  <TableCell align="center" className="material-table__cell">
                    <img
                      className="program_logo_img"
                      src={
                        sponsor?.logoUrl?.startsWith('http')
                          ? sponsor?.logoUrl
                          : 'https://general-assets.sgp1.digitaloceanspaces.com/datastar-general/datastar-logo-image.png'
                      }
                      alt="program logo"
                    />
                  </TableCell>
                  <TableCell
                    className="material-table__cell"
                    component="th"
                    role="button"
                    align="left"
                    scope="row"
                  >
                    {sponsor?.title}
                  </TableCell>
                  <TableCell className="material-table__cell" align="center">
                    {sponsor?.description ? sponsor.description : '-'}
                  </TableCell>
                  <TableCell className="material-table__cell" align="center">
                    {!!sponsor?.organization?.title ? sponsor.organization.title : '-'}
                  </TableCell>
                  <TableCell
                    align="center"
                    className="material-table__cell"
                    style={{ minWidth: 265 }}
                  >
                    {renderActionButtons(sponsor?.id)}
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={columns} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        component={defaultPaginationProps.component}
        backIconButtonProps={defaultPaginationProps.backIconButton}
        nextIconButtonProps={defaultPaginationProps.nextIconButton}
        rowsPerPageOptions={defaultPaginationProps.rowsPerPage}
        className={defaultPaginationProps.className}
        dir={defaultPaginationProps.dir}
      />
    </Fragment>
  );
};

export default SponsorsTable;
