import React, { createContext, useContext } from 'react';

const OrderHistoryUIContext = createContext();

export function useOrderHistoryUIContext() {
  return useContext(OrderHistoryUIContext);
}

export function OrderHistoryUIProvider({ orderHistoryUIEvents, children }) {
  const value = {
    openViewOrderHistoryDialog: orderHistoryUIEvents.openViewOrderHistoryDialog,
    openOrderHistoryPage: orderHistoryUIEvents.openOrderHistoryPage,
  };

  return <OrderHistoryUIContext.Provider value={value}>{children}</OrderHistoryUIContext.Provider>;
}
