import React from 'react';
import { withRouter } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ACCESS_TOKEN } from '@/shared/helpers/auth.enum';
import { paths } from '@/shared/helpers/paths';
import { getAppItem, isAllowedToAccess } from '@/shared/helpers';
import { logout } from '@/containers/App/Account/Auth/redux/actions';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const user = useSelector((state) => state.user.data);
  const dispatch = useDispatch();

  if (!isAllowedToAccess(user, rest.path)) {
    dispatch(logout());
  }

  const accessToken = getAppItem(ACCESS_TOKEN);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!!(user && accessToken)) {
          return <Component {...props} />;
        }
        return <Redirect to={paths.LOGIN} />;
      }}
    />
  );
};

export default withRouter(PrivateRoute);
