import { httpMethod } from '@/shared/helpers/httpMethod.enum';
import { sendRequest } from '../base/ApiService';

const controlPanelBaseURL =
  process.env.REACT_APP_CADS_AI_API + process.env.REACT_APP_CADS_AI_CONTROL_PANEL_ENDPOINT;
const learningPathBaseURL =
  process.env.REACT_APP_CADS_AI_API + process.env.REACT_APP_CADS_AI_LEARNING_PATH_ENDPOINT;

const ProgramAPIEndpoints = {
  GET_ALL_PROGRAMS: `${controlPanelBaseURL}/getAllPrograms`,
  GET_ALL_PROGRAM_OPTIONS: `${controlPanelBaseURL}/getAllProgramOptions`,
  ACTIVATE_DEACTIVATE_PROGRAM: `${controlPanelBaseURL}/activateDeactivatePrograms`,
  EXPORT_ALL_PROGRAMS: `${controlPanelBaseURL}/exportAllProgramsToCsv`,
  GET_PROGRAM_BY_ID: `${controlPanelBaseURL}/getProgramById`,
  ADD_PROGRAM: `${controlPanelBaseURL}/addProgram`,
  EDIT_PROGRAM: `${controlPanelBaseURL}/editProgram`,
  GET_ASSESSMENT_OPTIONS: `${controlPanelBaseURL}/getAllAssessmentOptions`,
  GET_LEARNING_PATH_OPTIONS: `${controlPanelBaseURL}/getAllLearningPathOptions`,
  GET_COURSE_OPTIONS: `${controlPanelBaseURL}/GetAllCourseOptions`,
  GET_MODULE_OPTIONS: `${learningPathBaseURL}/getModuleOptions`,
  GET_SPONSOR_OPTIONS: `${controlPanelBaseURL}/getAllSponsors`,
  GET_PROGRAM_STATUS_OPTIONS: `${controlPanelBaseURL}/getProgramStatusOptions`,
};

const getProgramList = (filter) => {
  // return getMockProgramList()
  return sendRequest({
    fullUrl: ProgramAPIEndpoints.GET_ALL_PROGRAMS,
    method: httpMethod.GET,
    params: filter,
  });
};

const getProgramOptionsList = () => {
  return sendRequest({
    fullUrl: ProgramAPIEndpoints.GET_ALL_PROGRAM_OPTIONS,
    method: httpMethod.GET,
  });
};

const getProgramListCSV = (filter) => {
  // return getMockProgramList()
  return sendRequest({
    fullUrl: ProgramAPIEndpoints.EXPORT_ALL_PROGRAMS,
    method: httpMethod.GET,
    params: filter,
  });
};

const getLearningPathOptions = () => {
  // return getMockLearningPath()
  return sendRequest({
    fullUrl: ProgramAPIEndpoints.GET_LEARNING_PATH_OPTIONS,
    method: httpMethod.GET,
  });
};

const addProgram = (data) => {
  // return mockAddProgram(data)
  return sendRequest({
    fullUrl: ProgramAPIEndpoints.ADD_PROGRAM,
    method: httpMethod.POST,
    data,
  });
};

const updateProgram = (data) => {
  // return mockAddProgram(data)
  return sendRequest({
    fullUrl: ProgramAPIEndpoints.EDIT_PROGRAM,
    method: httpMethod.PUT,
    data,
  });
};

const getCourseOptions = () => {
  // return getMockCourse()
  return sendRequest({
    fullUrl: ProgramAPIEndpoints.GET_COURSE_OPTIONS,
    method: httpMethod.GET,
  });
};

const getSponsorOptions = () => {
  // return getMockSponsor()
  return sendRequest({
    fullUrl: ProgramAPIEndpoints.GET_SPONSOR_OPTIONS,
    method: httpMethod.GET,
  });
};

const getAssessmentOptions = () => {
  // return getMockAssessment()
  return sendRequest({
    fullUrl: ProgramAPIEndpoints.GET_ASSESSMENT_OPTIONS,
    method: httpMethod.GET,
  });
};

const getProgramStatusOptions = () => {
  // return getMockProgramStatus()
  return sendRequest({
    fullUrl: ProgramAPIEndpoints.GET_PROGRAM_STATUS_OPTIONS,
    method: httpMethod.GET,
  });
};

const getModuleOptions = () => {
  // return getMockModule()
  return sendRequest({
    fullUrl: ProgramAPIEndpoints.GET_MODULE_OPTIONS,
    method: httpMethod.GET,
  });
};

const getProgramById = (id) => {
  return sendRequest({
    fullUrl: ProgramAPIEndpoints.GET_PROGRAM_BY_ID,
    method: httpMethod.GET,
    params: { id },
  });
};

const changeProgramListStatus = (programIds, status) => {
  return sendRequest({
    fullUrl: ProgramAPIEndpoints.ACTIVATE_DEACTIVATE_PROGRAM,
    method: httpMethod.PUT,
    data: {
      programIds,
      status,
    },
  });
};

export default {
  getProgramById,
  changeProgramListStatus,
  getProgramListCSV,
  getProgramList,
  getProgramOptionsList,
  getLearningPathOptions,
  getCourseOptions,
  getModuleOptions,
  getSponsorOptions,
  getAssessmentOptions,
  getProgramStatusOptions,
  addProgram,
  updateProgram,
};

/*const getMockProgramList = () =>
  new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [""],
            data: {
              totalRecords: 1,
              currentPage: 1,
              pageSize: 0,
              records: [
                {
                  totalActivePrograms: 3,
                  totalActiveApplicants: 5,
                  programList: [
                    {
                      id: 1,
                      title: "program 1",
                      startDate: "2022-04-13T16:14:20.753Z",
                      endDate: "2022-04-13T16:14:20.753Z",
                      logoImgUrl: "",
                      status: true,
                      numberOfActiveApplicants: 5,
                      sponsors: [
                        {
                          id: 1,
                          title: "sponsor"
                        }
                      ],
                      programStatus: {
                        id: 1,
                        title: "programStatus"
                      }
                    }
                  ]
                }
              ]
            }
          },
        }),
      500
    );
  });
*/

const getMockProgramList = () =>
  new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [''],
            data: {
              totalRecords: 2,
              currentPage: 1,
              pageSize: 10,
              records: [
                {
                  endDate: '2023-06-27T00:00:00',
                  id: 1,
                  logoImgUrl:
                    'https://general-assets.sgp1.digitaloceanspaces.com/datastar-general/yayasan-peneraju-logo.png',
                  numberOfApplicants: 15,
                  tags: 'tag 1 ',
                  code: 'code 1',
                  description: 'any',
                  learningPath: { id: 1, title: 'Mock LP 1' },
                  sponsers: { id: 1, title: 'Mock sponsers 1' },
                  assesment: { id: 1, title: 'Mock assesment 1' },
                  module: { id: 1, title: 'Mock module 1' },
                  course: { id: 1, title: 'Mock Course 1' },
                  programData: null,
                  startDate: '2021-01-01T00:00:00',
                  ProgramStatus: true,
                  activationStatus: false,
                  isMain: true,
                  title: 'Data Star Mock 1',
                },
                {
                  endDate: '2023-06-27T00:00:00',
                  id: 2,
                  logoImgUrl:
                    'https://general-assets.sgp1.digitaloceanspaces.com/datastar-general/yayasan-peneraju-logo.png',
                  numberOfApplicants: 25,
                  organizationName: 'B2c Platform',
                  tags: 'tag 2 ',
                  description: 'any 2',
                  code: 'code 2',
                  learningPath: { id: 2, title: 'Mock LP 2' },
                  sponsers: { id: 2, title: 'Mock sponsers 2' },
                  assesment: { id: 2, title: 'Mock assesment 2' },
                  module: { id: 2, title: 'Mock module 2' },
                  course: { id: 2, title: 'Mock Course 2' },
                  programData: null,
                  startDate: '2021-01-01T00:00:00',
                  ProgramStatus: false,
                  activationStatus: true,
                  isMain: false,
                  title: 'Data Star Mock 2',
                },
              ],
            },
          },
        }),
      500,
    );
  });

const getMockLearningPath = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          data: [
            { id: 1, title: 'Mock LP 1', isMandatory: true },
            { id: 2, title: 'Mock LP 2', isMandatory: false },
          ],
        },
      });
    }, 500);
  });
};
const getMockCourse = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          data: [
            { id: 5, title: 'Mock Course 1', isMandatory: true },
            { id: 6, title: 'Mock Course 2', isMandatory: false },
          ],
        },
      });
    }, 500);
  });
};
const getMockModule = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          data: [
            { id: 7, title: 'Mock Module 1', isMandatory: true },
            { id: 8, title: 'Mock Module 2', isMandatory: false },
          ],
        },
      });
    }, 500);
  });
};

const getMockSponsor = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          data: [
            { id: 11, title: 'Mock Sponser 1', isMandatory: true },
            { id: 52, title: 'Mock Sponser 2', isMandatory: false },
          ],
        },
      });
    }, 500);
  });
};

const getMockAssesment = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          data: [
            { id: 17, title: 'Mock Assesment 1', isMandatory: true },
            { id: 32, title: 'Mock Assesment 2', isMandatory: false },
          ],
        },
      });
    }, 500);
  });
};

const getMockProgramStatus = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          data: [
            { id: 21, title: 'Mock Status 1', isMandatory: true },
            { id: 92, title: 'Mock Status 2', isMandatory: false },
          ],
        },
      });
    }, 500);
  });
};

const mockAddProgram = (responseData) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: responseData,
      });
    }, 500);
  });
};
