import React, { useMemo } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { useAssessmentUIContext } from './AssessmentUIProvider';
import AssessmentTableWrapper from './table/AssessmentTableWrapper';
import { Route, Switch } from 'react-router-dom';
import { assessmentsBaseUrl } from '@/containers/Admin/Assessments';
import AssessmentEditDialog from '@/containers/Admin/Assessments/components/assessment-add-edit-dialog/AssessmentEditDialog';
import { setAssessmentFilters } from '@/containers/Admin/Assessments/redux/actions';
import AssessmentViewDialog from '@/containers/Admin/Assessments/components/assessment-view-dialog/AssessmentViewDialog';
import AssessmentConfigCard from '@/containers/Admin/Assessments/components/assessment-config-card';

export function AssessmentCard() {
  const assessmentsUIContext = useAssessmentUIContext();
  const assessmentsUIProps = useMemo(() => {
    return {
      openNewAssessmentDialog: assessmentsUIContext.openNewAssessmentDialog,
    };
  }, [assessmentsUIContext]);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Assessment List</h3>
          <h3 className="page-subhead subhead">From here you can manage all the assessments.</h3>
        </Col>
      </Row>
      <Row>
        <Switch>
          <Route exact path={`${assessmentsBaseUrl}/:id/configure`}>
            {({ history, match }) => (
              <AssessmentConfigCard
                show={match != null}
                id={match?.params?.id}
                onHide={() => {
                  history.push(assessmentsBaseUrl);
                }}
              />
            )}
          </Route>
          <Route path="">
            <AssessmentTableWrapper />
          </Route>
        </Switch>
      </Row>
    </Container>
  );
}
