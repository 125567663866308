import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, ButtonToolbar } from 'reactstrap';
import { emptyStringPattern, YesNoOptions } from '@/shared/helpers';
import { FormField } from '@/shared/components/form/hook/FormField';
import { extractFieldData, getStepFields } from '../index';
import { SelectField } from '@/shared/components/form/Select';

const StepOne = ({ onSubmit, page, data }) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm(extractFieldData(page, data));

  React.useEffect(() => {
    getStepFields(page).forEach((key) => {
      setValue(key, data[key]);
    });
  }, [data]);

  return (
    <div className="modal__body add-edit-learning-path">
      <form className={`form form--horizontal`} onSubmit={handleSubmit(onSubmit)}>
        <div className="form__form-group">
          <span className="form__form-group-label">Title</span>
          <div className="form__form-group-field">
            <FormField
              name="title"
              control={control}
              component="input"
              errors={errors}
              rules={{
                required: 'This is required field',
                pattern: emptyStringPattern,
              }}
              defaultValue=""
              isAboveError={true}
              placeholder="Name of Course"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Description</span>
          <div className="form__form-group-field">
            <FormField
              name="description"
              control={control}
              component="textarea"
              errors={errors}
              rules={{
                required: 'This is required field',
                pattern: emptyStringPattern,
              }}
              defaultValue=""
              isAboveError={true}
              placeholder="Write description about course"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Summary</span>
          <div className="form__form-group-field mb-2">
            <FormField
              name="summary"
              control={control}
              component="textarea"
              placeholder="Write summary about course"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Duration (min)</span>
          <div className="form__form-group-field">
            <FormField
              name="durationInMinutes"
              control={control}
              min={0}
              component="input"
              type="number"
              errors={errors}
              rules={{
                required: 'This is required field',
                pattern: emptyStringPattern,
              }}
              defaultValue=""
              isAboveError={true}
              placeholder="Enter Duration in Minutes"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Is Verified</span>
          <div className="form__form-group-field">
            <FormField
              name="isVerified"
              control={control}
              component={SelectField}
              errors={errors}
              options={YesNoOptions}
              defaultValue={YesNoOptions[0]}
              isAboveError
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Is Provided by Cads</span>
          <div className="form__form-group-field">
            <FormField
              name="isProvidedByCads"
              control={control}
              component={SelectField}
              errors={errors}
              options={YesNoOptions}
              defaultValue={YesNoOptions[0]}
              isAboveError
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Is Confirmed</span>
          <div className="form__form-group-field">
            <FormField
              name="isConfirmed"
              control={control}
              component={SelectField}
              errors={errors}
              options={YesNoOptions}
              defaultValue={YesNoOptions[0]}
              isAboveError
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Is Platform Wide</span>
          <div className="form__form-group-field">
            <FormField
              name="isPlatformWide"
              control={control}
              component={SelectField}
              errors={errors}
              options={YesNoOptions}
              defaultValue={YesNoOptions[0]}
              isAboveError
            />
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar modal__footer">
          <Button color="primary" type="submit">
            Next
          </Button>
        </ButtonToolbar>
      </form>
    </div>
  );
};

export default StepOne;
