import React, { Fragment, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import { Button } from 'reactstrap';
import EyeIcon from 'mdi-react/EyeIcon';
import { useUsersUIContext } from '../UsersUIProvider';
import UsersTableColumns, { UsersColumns } from './UsersTableColumns';
import { useDispatch, useSelector } from 'react-redux';
import { getUsersList } from '../../redux/actions';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { defaultPaginationProps } from '@/shared/helpers';

const UsersTable = () => {
  const usersUIContext = useUsersUIContext();
  const dispatch = useDispatch();
  const {
    data: users,
    error,
    filters,
    loading,
    totalRecords,
  } = useSelector((state) => state.campaignUser);
  const {
    pageSize: rowsPerPage,
    pageNumber: page,
    sortBy: orderBy,
    isSortOrderDescending,
  } = filters;

  useEffect(() => {
    dispatch(getUsersList(filters));
  }, [filters]);

  const handleRequestSort = (event, property) => {};

  const handleChangePage = (event, currentPage) => {};

  const handleChangeRowsPerPage = (event) => {};

  const emptyRows = page === Math.ceil(totalRecords / rowsPerPage) ? rowsPerPage - users.length : 0;

  const renderActionButtons = ({ id: userId }) => (
    <div className="actions">
      <Button
        className="icon mb-0"
        color="success"
        outline
        onClick={() => usersUIContext.openViewUserDialog(userId)}
      >
        <p>
          <EyeIcon /> View
        </p>
      </Button>
    </div>
  );

  return (
    <Fragment>
      <div className="material-table__wrap">
        <Table className="material-table">
          <TableBody>
            <UsersTableColumns
              order={isSortOrderDescending ? 'desc' : 'asc'}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rowsPerPage}
            />
            {(loading || !!error) && (
              <TableRow>
                <TableCell colSpan={UsersColumns.length}>
                  {loading && (
                    <div className={'text-center overflow-hidden'}>
                      <div className="panel__refresh position-relative" style={{ height: 75 }}>
                        <LoadingIcon />
                      </div>
                    </div>
                  )}
                  <div className={'text-center'}>
                    <p className={!!error ? 'm-5' : ''}>{error || 'Loading Users...'}</p>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {users.map((user) => {
              let literacySkill = user?.literacy_skills.toString();
              return (
                <TableRow className="material-table" tabIndex={-1} key={user.id}>
                  <TableCell align="center" className="material-table__cell">
                    {user?.name}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {user?.email}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {user?.contact}
                  </TableCell>
                  <TableCell
                    className="material-table__cell"
                    component="th"
                    align="center"
                    scope="row"
                  >
                    {literacySkill.length > 65
                      ? literacySkill.substr(0, 65) + '...'
                      : literacySkill}
                  </TableCell>

                  <TableCell
                    align="center"
                    className="material-table__cell"
                    style={{ minWidth: '295px' }}
                  >
                    {renderActionButtons(user)}
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={UsersColumns.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        component={defaultPaginationProps.component}
        backIconButtonProps={defaultPaginationProps.backIconButton}
        nextIconButtonProps={defaultPaginationProps.nextIconButton}
        rowsPerPageOptions={defaultPaginationProps.rowsPerPage}
        className={defaultPaginationProps.className}
        dir={defaultPaginationProps.dir}
      />
    </Fragment>
  );
};

export default UsersTable;
