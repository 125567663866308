import { paths } from '@/shared/helpers/paths';
import React, { Suspense } from 'react';
import Loading from '@/shared/components/Loading';
import { Redirect, Route, Switch } from 'react-router-dom';
import LearningPaths from '@/containers/Control-Panel/LearningPath';
import BaselineThreshold from '@/containers/Control-Panel/BaselineThreshold';
import Assign from '@/containers/Control-Panel/Assign';
import Assigned from '@/containers/Control-Panel/Assigned';
import Modules from '@/containers/Control-Panel/Module';

export default function ControlPanel() {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        {<Redirect exact from={paths.CONTROL_PANEL.BASE} to={paths.CONTROL_PANEL.LEARNING_PATH} />}
        <Route path={paths.CONTROL_PANEL.LEARNING_PATH} component={LearningPaths} />
        <Route path={paths.CONTROL_PANEL.MODULE} component={Modules} />
        <Route path={paths.CONTROL_PANEL.BASELINE_THRESHOLD} component={BaselineThreshold} />
        <Route path={paths.CONTROL_PANEL.ASSIGN} component={Assign} />
        <Route path={paths.CONTROL_PANEL.ASSIGNED} component={Assigned} />
      </Switch>
    </Suspense>
  );
}
