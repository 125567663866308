import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const setCohortFetching = createAction('SET_COHORT_FETCHING');
export const setCohortError = createAction('SET_COHORT_ERROR');
export const setAssignCohortList = createAction('SET_ASSIGN_COHORT_LIST');
export const setCohortFilters = createAction('SET_COHORT_LIST_FILTER');
export const setCohortState = createAction('SET_COHORT_STATE');

export const getCPCohortsList = (filter) => async (dispatch) => {
  const defaultError = `Error occurred getting ${
    filter.assigned ? 'Assigned' : 'Assign'
  } cohort list`;
  dispatch(setCohortFetching());
  try {
    const { data, isSuccess } = await api.cohort.getCohortListByOrgId(filter);
    if (isSuccess) dispatch(setAssignCohortList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setCohortError(error.message));
    console.error(defaultError, error.message);
  }
};
