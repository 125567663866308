import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import TableRow from '@material-ui/core/TableRow';
import { toggleCheckedItemById } from '../../redux/actions';
import TableCell from '@material-ui/core/TableCell';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import * as Constants from '@/shared/helpers/constants';

const AssignTableRow = ({ data }) => {
  const dispatch = useDispatch();
  const { activeTab, checkedItemIds, isFromModule, isFromLearningPath, isFromTrainingProvider } =
    useSelector((state) => state.assignPage, shallowEqual);

  const handleOnChange = (itemId) => {
    dispatch(toggleCheckedItemById(itemId));
  };

  const renderUserCells = (user) => {
    const id =
      isFromModule || isFromLearningPath || isFromTrainingProvider
        ? user?.jobseekerId
        : user.userDetailId;
    return (
      <>
        <TableCell align="center" className="material-table__cell">
          <FormGroup>
            <Input
              type="checkbox"
              value={id}
              checked={checkedItemIds.includes(id)}
              onChange={() => handleOnChange(id)}
            />
          </FormGroup>
        </TableCell>
        <TableCell align="center" className="material-table__cell">
          {user?.userFullName}{' '}
        </TableCell>
        <TableCell align="center" className="material-table__cell">
          {user?.email}{' '}
        </TableCell>
      </>
    );
  };

  const renderCohortCells = (cohort) => (
    <>
      <TableCell align="center" className="material-table__cell">
        <FormGroup>
          <Input
            type="checkbox"
            value={cohort?.cohortId}
            checked={checkedItemIds.includes(cohort?.cohortId)}
            onChange={() => handleOnChange(cohort?.cohortId)}
          />
        </FormGroup>
      </TableCell>
      <TableCell align="center" className="material-table__cell">
        {cohort?.cohortName}{' '}
      </TableCell>
    </>
  );

  function renderOrgCells(organization) {
    const id =
      isFromModule || isFromLearningPath || isFromTrainingProvider
        ? organization?.tenantId
        : organization?.organizationId;
    return (
      <>
        <TableCell align="center" className="material-table__cell">
          <FormGroup>
            <Input
              type="checkbox"
              value={id}
              checked={checkedItemIds.includes(id)}
              onChange={() => handleOnChange(id)}
            />
          </FormGroup>
        </TableCell>
        <TableCell align="center" className="material-table__cell">
          {organization?.organizationName ?? organization?.name ?? organization?.orgName}{' '}
        </TableCell>
      </>
    );
  }

  return (
    <>
      {
        {
          [Constants.USERS]: data?.map((d) => (
            <TableRow className="material-table" tabIndex={-1} key={d.userDetailId}>
              {renderUserCells(d)}
            </TableRow>
          )),
          [Constants.COHORT]: data?.map((d) => (
            <TableRow className="material-table" tabIndex={-1} key={d.cohortId}>
              {renderCohortCells(d)}
            </TableRow>
          )),
          [Constants.ORGANIZATION]: data?.map((d) => (
            <TableRow className="material-table" tabIndex={-1} key={d.organizationId}>
              {renderOrgCells(d)}
            </TableRow>
          )),
        }[activeTab]
      }
    </>
  );
};

export default AssignTableRow;
