import React, { useEffect, useMemo, useState } from 'react';
import LoadingIcon from 'mdi-react/LoadingIcon';
import api from '@/utils/apiClient';
import { FormField } from '@/shared/components/form/hook/FormField';
import TextEditor from '@/shared/components/text-editor/TextEditor';
import { Button, ButtonToolbar } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { SelectField } from '@/shared/components/form/Select';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { emptyStringPattern, YesNoOptions } from '@/shared/helpers';
import { getSanitizeHTML } from '@/shared/components/SanitizeHTML';
import { RadioButtonField } from '@/shared/components/form/RadioButton';
import { getOrganizationOptionsList } from '../../../../redux/organization/actions';

const AnnouncementEditDialogBody = ({ modal, isEdit, id }) => {
  const initialValues = {
    title: '',
    description: '',
    isPublic: 'true',
    activeFromDate: '',
    activeToDate: '',
    actionUrl: '',
  };

  const [data, setData] = useState(initialValues);
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({ defaultValues: initialValues });

  const isPublic = watch('isPublic');
  const setIsPublic = (value) => setValue('isPublic', value);
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState({});
  const dispatch = useDispatch();
  const { options: organizationOpts } = useSelector((state) => state.cadsAIOrgs, shallowEqual);

  const [state, setState] = useState({
    loading: false,
    fetchingAnnouncement: false,
    newAnnouncementAdded: false,
    errorMessage: '',
  });

  useEffect(() => {
    dispatch(getOrganizationOptionsList());
  }, []);

  useEffect(() => {
    if (isEdit && id) {
      setDefaultData(+id);
    }
  }, [data?.organizationId, isEdit, id]);

  useEffect(() => {
    setOrganizationOptions(
      organizationOpts?.map((org) => ({ value: org.id, label: org.title })) ?? [],
    );
  }, []);

  useMemo(() => {
    setSelectedOrg(organizationOpts?.find((item) => item?.id === data?.organizationId));
  }, [selectedOrg, data?.organizationId]);

  const setDefaultData = async (announcementId) => {
    try {
      const { data: res, isSuccess } = await api.cadsAIAnnouncements.getAnnouncementById(
        announcementId,
      );
      const announcement = res.data;
      if (id && isSuccess) {
        setData({
          ...announcement,
        });
        setValue('isPublic', announcement?.isPublic ? 'true' : 'false');
        setValue('actionUrl', announcement?.actionUrl);
        setValue(
          'organization',
          !selectedOrg ? null : { value: selectedOrg?.value, label: selectedOrg?.title },
        );
        setValue('activeFromDate', announcement?.activeFromDate?.substring(0, 10));
        setValue('activeToDate', announcement?.activeToDate?.substring(0, 10));
      }
    } catch ({ message }) {
      console.error('Announcement edit error : ', message);
      setState({
        ...state,
        errorMessage: 'Error while getting existing announcement details',
      });
    }
  };

  const constructApiRequestData = async ({
    title,
    description,
    actionUrl,
    activeFromDate,
    activeToDate,
    organization,
  }) => {
    return {
      title,
      description,
      isPublic: isPublic === 'true',
      actionUrl,
      isAnnouncement: true,
      activeFromDate,
      activeToDate,
      organizationId: organization?.value,
    };
  };

  const handleAddEditAnnouncement = async (announcement) => {
    setState({ ...state, loading: true });
    const newAnnouncementData = await constructApiRequestData(announcement);
    const defaultError = `Error occurred while ${isEdit ? 'Updating' : 'Adding new'} announcement`;
    try {
      const { data: res, isSuccess } = isEdit
        ? await api.cadsAIAnnouncements.updateAnnouncement({ id: +id, ...newAnnouncementData })
        : await api.cadsAIAnnouncements.addAnnouncement(newAnnouncementData);
      if (isSuccess) {
        setState({ ...state, newAnnouncementAdded: true, loading: false });
        if (!isEdit) setData(initialValues);
      } else {
        throw new Error(data?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({
        ...state,
        loading: false,
        newAnnouncementAdded: false,
        errorMessage: error.message,
      });
    }
  };

  return (
    <div>
      {/*Add/Edit Announcement Form Status*/}
      <div className="ml-4">
        {state.loading && (
          <div>
            <div className="panel__refresh position-relative" style={{ height: 75, width: 20 }}>
              <LoadingIcon />
              <div style={{ width: 200, padding: '23px 30px' }}>
                {isEdit ? 'Updating' : 'Adding new'} announcement...
              </div>
            </div>
          </div>
        )}
        {(state.newAnnouncementAdded || !!state.errorMessage) && (
          <>
            <table className="mt-3">
              <tr>
                <td>
                  {state.newAnnouncementAdded ? (
                    <span className="lnr lnr-thumbs-up modal__title-icon success" />
                  ) : (
                    <span className="lnr lnr-cross-circle modal__title-icon error" />
                  )}
                </td>
                <td className="pl-2">
                  {state.newAnnouncementAdded
                    ? `${isEdit ? 'Announcement Updated' : 'New Announcement Added'} Successfully`
                    : state.errorMessage}
                </td>
              </tr>
            </table>
          </>
        )}
      </div>
      <div className="modal__body add-edit-announcement">
        <form
          className={`form form--horizontal`}
          onSubmit={handleSubmit(handleAddEditAnnouncement)}
        >
          {!state.newAnnouncementAdded ? (
            <>
              <div className="form__form-group">
                <span className="form__form-group-label">Title</span>
                <div className="form__form-group-field">
                  <FormField
                    control={control}
                    name="description"
                    component={() => (
                      <TextEditor
                        defaultContent={getSanitizeHTML(data.title)}
                        onChange={(value) => setValue('title', value)}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Description</span>
                <div className="form__form-group-field">
                  <FormField
                    control={control}
                    name="description"
                    component={() => (
                      <TextEditor
                        defaultContent={getSanitizeHTML(data.description)}
                        onChange={(value) => setValue('description', value)}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Action Url</span>
                <div className="form__form-group-field">
                  <FormField
                    name="actionUrl"
                    control={control}
                    component="input"
                    errors={errors}
                    rules={{
                      required: 'This is required field',
                      pattern: emptyStringPattern,
                    }}
                    defaultValue=""
                    isAboveError={true}
                    placeholder="Name of Url"
                  />
                </div>
              </div>
              <div className="form__form-group mt-3">
                <span className="form__form-group-label">Select Organization</span>
                <div className="form__form-group-field">
                  <FormField
                    control={control}
                    name="organization"
                    component={SelectField}
                    options={organizationOptions}
                    placeholder="Select Organization"
                    errors={errors}
                    clearable={true}
                    isAboveError
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">From Date</span>
                <div className="form__form-group-field">
                  <FormField
                    name="activeFromDate"
                    type="date"
                    placeholder="Enter Date From When Announcement Should Be Displayed"
                    control={control}
                    defaultValue=""
                    isAboveError
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">To Date</span>
                <div className="form__form-group-field">
                  <FormField
                    name="activeToDate"
                    type="date"
                    placeholder="Enter Date Till When Announcement Should Be Displayed"
                    control={control}
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Is Public</span>
                <div className="form__form-group-field mt-2">
                  <RadioButtonField
                    radioValue={`${YesNoOptions[0].value}`}
                    name={'IsPublic'}
                    onChange={setIsPublic}
                    value={isPublic}
                    className="colored-click mr-5"
                    label={YesNoOptions[0].label}
                  />
                  <RadioButtonField
                    radioValue={`${YesNoOptions[1].value}`}
                    name={'IsPublic'}
                    onChange={setIsPublic}
                    value={isPublic}
                    className="colored-click mr-5"
                    label={YesNoOptions[1].label}
                  />
                </div>
              </div>
              <div>
                <ButtonToolbar className="modal__footer btn-toolbar">
                  <>
                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                    <Button
                      color="secondary"
                      type="button"
                      className="modal_cancel btn btn-secondary"
                      onClick={() => modal.hide()}
                    >
                      Close
                    </Button>
                  </>
                </ButtonToolbar>
              </div>
            </>
          ) : (
            <div className={'p-3'}>
              <ButtonToolbar className="modal__footer btn-toolbar">
                <Button color="primary" type="button" onClick={() => modal.hide()}>
                  Okay
                </Button>
              </ButtonToolbar>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default AnnouncementEditDialogBody;
