import { sendRequest } from '../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const learningPathEndpoints = {
  GET_LEARNING_PATH_LIST: `/getLearningPathList`,
  GET_LEARNING_PATH_GROUP_OPTION_LIST: `/getLearningPathGroupOptions`,
  ADD_LEARNING_PATH: `/addLearningPathDetails`,
  EDIT_LEARNING_PATH: `/updateLearningPathDetails`,
  GET_ASSIGNED_USERS_LP: '/getAllAssignedUsersLearningPathDetails',
  GET_ASSIGNED_ORGS_LP: '/getAllAssignedOrgsLearningPathDetails',
  UN_ASSIGNED_USERS_LP: '/unassignLearningPathToUsers',
  UN_ASSIGNED_ORGS_LP: '/unassignLearningPathsToOrgs',
};

const getLearningPathList = (filter) => {
  // return getMockLearningPathList();
  return sendRequest(
    {
      url: learningPathEndpoints.GET_LEARNING_PATH_LIST,
      method: httpMethod.GET,
      params: { ...filter, searchText: filter.search },
    },
    process.env.REACT_APP_CONTROL_PANEL_ENDPOINT,
  );
};

const getGroupOptionList = () => {
  // return getMockGroupOptionList();
  return sendRequest(
    {
      url: learningPathEndpoints.GET_LEARNING_PATH_GROUP_OPTION_LIST,
      method: httpMethod.GET,
    },
    process.env.REACT_APP_CONTROL_PANEL_SS_ENDPOINT,
  );
};

const addLearningPath = (learningPath) => {
  // return new Promise((resolve, reject) => {setTimeout(()=>resolve({isSuccess: true, data: {data: true}}))});
  return sendRequest(
    {
      url: learningPathEndpoints.ADD_LEARNING_PATH,
      method: httpMethod.POST,
      data: learningPath,
    },
    process.env.REACT_APP_CONTROL_PANEL_SS_ENDPOINT,
  );
};

const editLearningPath = ({ id, ...learningPath }) => {
  // return new Promise((resolve, reject) => {setTimeout(()=>resolve({isSuccess: true, data: {data: true}}))});
  return sendRequest(
    {
      url: learningPathEndpoints.EDIT_LEARNING_PATH,
      method: httpMethod.PUT,
      data: learningPath,
      params: { id }, //TODO: this should be in the request body itself, API change required
    },
    process.env.REACT_APP_CONTROL_PANEL_SS_ENDPOINT,
  );
};

const getAssignedUserLP = (filters) => {
  // return getMockAssignedUserLP();
  return sendRequest(
    {
      url: learningPathEndpoints.GET_ASSIGNED_USERS_LP,
      method: httpMethod.GET,
      params: filters,
    },
    process.env.REACT_APP_CONTROL_PANEL_SS_ENDPOINT,
  );
};

const unAssignLPToUser = (reqData) => {
  // return new Promise(resolve => setTimeout(() => resolve({isSuccess: true, data: true}), 500));
  return sendRequest(
    {
      url: learningPathEndpoints.UN_ASSIGNED_USERS_LP,
      method: httpMethod.DELETE,
      data: reqData,
    },
    process.env.REACT_APP_CONTROL_PANEL_SS_ENDPOINT,
  );
};

const getAssignedOrganizationLP = (filters) => {
  // return getMockAssignedOrganizationLP();
  return sendRequest(
    {
      url: learningPathEndpoints.GET_ASSIGNED_ORGS_LP,
      method: httpMethod.GET,
      params: filters,
    },
    process.env.REACT_APP_CONTROL_PANEL_SS_ENDPOINT,
  );
};

const unAssignLPToOrganization = (reqData) => {
  // return new Promise(resolve => setTimeout(() => resolve({isSuccess: true, data: true}), 500));
  return sendRequest(
    {
      url: learningPathEndpoints.UN_ASSIGNED_ORGS_LP,
      method: httpMethod.DELETE,
      data: reqData,
    },
    process.env.REACT_APP_CONTROL_PANEL_SS_ENDPOINT,
  );
};

export default {
  getLearningPathList,
  getGroupOptionList,
  addLearningPath,
  editLearningPath,
  getAssignedUserLP,
  getAssignedOrganizationLP,
  unAssignLPToUser,
  unAssignLPToOrganization,
};

const getMockLearningPathList = () =>
  new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [''],
            data: {
              totalRecords: 2,
              currentPage: 1,
              pageSize: 10,
              records: [
                {
                  id: 1,
                  fullName: 'Mock LP 1',
                  shortName: 'MLP1',
                  imageUrl: '',
                  group: { id: 1, name: 'Group 1' },
                  isMain: false,
                  summary: 'some summary',
                  tagline: 'some tagline',
                  description: 'some description',
                  moduleList: [{ id: 2, name: 'Module 2', sortOrder: 1, isOptional: true }],
                },
                {
                  id: 2,
                  fullName: 'Mock LP 2',
                  shortName: 'MLP2',
                  imageUrl:
                    'https://images.squarespace-cdn.com/content/v1/60b6fd136dae8570d704e0d7/1625024934442-M9UBJJVLY4D4PA0HQGYF/CADS_Learning+Path+Banner_EDS.png',
                  group: { id: 2, name: 'Group 2' },
                  isMain: true,
                  summary: 'some summary',
                  tagline: 'some tagline',
                  description: 'some description',
                  moduleList: [{ id: 1, name: 'Module 1', sortOrder: 1, isOptional: false }],
                },
              ],
            },
          },
        }),
      500,
    );
  });

const getMockGroupOptionList = () =>
  new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [''],
            data: [
              { id: 1, name: 'Group 1' },
              { id: 2, name: 'Group 2' },
            ],
          },
        }),
      500,
    );
  });

const getMockAssignedUserLP = () =>
  new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [''],
            data: {
              totalRecords: 2,
              currentPage: 1,
              pageSize: 10,
              records: [
                {
                  learningPathRecomId: 1,
                  picUrl:
                    'https://general-assets.sgp1.digitaloceanspaces.com/assets/dummy_profile.png',
                  userFullName: 'User 1',
                  email: 'user@email.com',
                  jobseekerId: 100,
                  learningPathList: [
                    { id: 1, fullName: 'Mock LP 1', imageUrl: '', tagline: '' },
                    { id: 2, fullName: 'Mock LP 2', imageUrl: '', tagline: '' },
                  ],
                },
                {
                  learningPathRecomId: 2,
                  picUrl:
                    'https://general-assets.sgp1.digitaloceanspaces.com/assets/dummy_profile.png',
                  userFullName: 'User 2',
                  jobseekerId: 200,
                  email: 'user@email.com',
                  learningPathList: [
                    { id: 1, fullName: 'Mock LP 1', imageUrl: '', tagline: '' },
                    { id: 2, fullName: 'Mock LP 2', imageUrl: '', tagline: '' },
                  ],
                },
              ],
            },
          },
        }),
      500,
    );
  });

const getMockAssignedOrganizationLP = () =>
  new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [''],
            data: {
              totalRecords: 2,
              currentPage: 1,
              pageSize: 10,
              records: [
                {
                  tenantId: 1,
                  learningPathRecomId: 10,
                  picUrl:
                    'https://general-assets.sgp1.digitaloceanspaces.com/assets/dummy_profile.png',
                  orgName: 'Mock Module Org 1',
                  orgEmail: 'org@gmail.com',
                  learningPathList: [
                    { id: 1, fullName: 'Mock LP 1', imageUrl: '', tagline: '' },
                    { id: 2, fullName: 'Mock LP 2', imageUrl: '', tagline: '' },
                  ],
                },
                {
                  tenantId: 2,
                  learningPathRecomId: 20,
                  picUrl:
                    'https://general-assets.sgp1.digitaloceanspaces.com/assets/dummy_profile.png',
                  orgName: 'Mock Module Org 2',
                  orgEmail: 'org@gmail.com',
                  learningPathList: [
                    { id: 1, fullName: 'Mock LP 1', imageUrl: '', tagline: '' },
                    { id: 2, fullName: 'Mock LP 2', imageUrl: '', tagline: '' },
                  ],
                },
              ],
            },
          },
        }),
      500,
    );
  });
