import React, { useState } from 'react';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import moment from 'moment';
import SanitizeHTML from '@/shared/components/SanitizeHTML';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableCell } from '@material-ui/core';
import Table from '@material-ui/core/Table';

const renderProgramDetails = (program) => (
  <table className="project-summary__info">
    <tr>
      <th>Title</th>
      <td>{program?.title}</td>
    </tr>
    <tr>
      <th>Tags</th>
      <td>{program?.tags ?? '-'}</td>
    </tr>
    <tr>
      <th>Code</th>
      <td>{program?.code ?? '-'}</td>
    </tr>
    <tr>
      <th style={{ minWidth: 80 }}>Description</th>
      <div className="question-extra-html">
        <SanitizeHTML html={program?.description} />
      </div>
    </tr>
    <tr>
      <th>Start Date</th>
      <td>{program?.startDate ? moment(program?.startDate).format('DD/MM/yyyy') : '-'}</td>
    </tr>
    <tr>
      <th>End Date</th>
      <td>{program?.endDate ? moment(program?.endDate).format('DD/MM/yyyy') : '-'}</td>
    </tr>
  </table>
);

const renderAssessmentView = ({ assessmentList }) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell style={{ minWidth: 160 }}>Name</TableCell>
        <TableCell style={{ minWidth: 100 }}>Code</TableCell>
        <TableCell style={{ minWidth: 70 }}>Type</TableCell>
        <TableCell style={{ minWidth: 100 }}>Sort Order</TableCell>
        <TableCell>Description</TableCell>
      </TableRow>
    </TableHead>
    {!assessmentList.length && (
      <TableRow>
        <TableCell colSpan={6} align="center">
          <p className="mt-3 mb-3">There are no assessments.</p>
        </TableCell>
      </TableRow>
    )}
    {assessmentList
      .sort((a1, a2) => +(a1?.sortOrder ?? 0) - (a2?.sortOrder ?? 0))
      .map((a) => (
        <TableRow>
          <TableCell>{a?.assessment?.title ?? '-'}</TableCell>
          <TableCell>{a?.code ?? '-'}</TableCell>
          <TableCell>{a?.prePostStatus === 'pre' ? 'baseline' : a?.prePostStatus ?? '-'}</TableCell>
          <TableCell>{a.sortOrder ?? '-'}</TableCell>
          <TableCell>
            {a?.extraDescription?.trim() === '' ? '-' : a?.extraDescription?.trim() ?? '-'}
          </TableCell>
        </TableRow>
      ))}
  </Table>
);

const renderLearningDetails = ({ learningList }) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell style={{ minWidth: 100 }}>Learning Path</TableCell>
        <TableCell style={{ minWidth: 100 }}>Course</TableCell>
        <TableCell style={{ minWidth: 100 }}>Module</TableCell>
        <TableCell style={{ minWidth: 70 }}>Is Mandatory</TableCell>
        <TableCell style={{ minWidth: 100 }}>Sort Order</TableCell>
        <TableCell>Description</TableCell>
      </TableRow>
    </TableHead>
    {!learningList?.length && (
      <TableRow>
        <TableCell colSpan={7} align="center">
          <p className="mt-3 mb-3">There are no learnings.</p>
        </TableCell>
      </TableRow>
    )}
    {learningList
      ?.sort((l1, l2) => +(l1?.sortNumber ?? 0) - (l2?.sortNumber ?? 0))
      .map((ll) => (
        <TableRow>
          <TableCell>{ll.learningPath?.title ?? '-'}</TableCell>
          <TableCell>{ll.course?.title ?? '-'}</TableCell>
          <TableCell>{ll?.module?.title ?? '-'}</TableCell>
          <TableCell>{ll?.isMandatory ? 'Yes' : 'No'}</TableCell>
          <TableCell>{ll?.sortNumber ?? '-'}</TableCell>
          <TableCell>
            {ll?.description?.trim() === '' ? '-' : ll?.description?.trim() ?? '-'}
          </TableCell>
        </TableRow>
      ))}
  </Table>
);

const renderSponsorDetails = ({ sponsorList }) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell style={{ minWidth: 100 }}>Sponsor Name</TableCell>
        <TableCell style={{ minWidth: 100 }}>Sort Order</TableCell>
        <TableCell style={{ minWidth: 70 }}>Is Main</TableCell>
        <TableCell>Description</TableCell>
      </TableRow>
    </TableHead>
    {!sponsorList.length && (
      <TableRow>
        <TableCell colSpan={7} align="center">
          <p className="mt-3 mb-3">There are no sponsors added.</p>
        </TableCell>
      </TableRow>
    )}
    {sponsorList
      .sort((s1, s2) => +(s1?.sortOrder ?? 0) - (s2?.sortOrder ?? 0))
      .map((sponsor) => (
        <TableRow>
          <TableCell>{sponsor?.title ?? '-'}</TableCell>
          <TableCell>{sponsor?.sortOrder ?? '-'}</TableCell>
          <TableCell>{sponsor?.isMain ? 'Yes' : 'No'}</TableCell>
          <TableCell>
            {sponsor?.description?.trim() === '' ? '-' : sponsor?.description?.trim() ?? '-'}
          </TableCell>
        </TableRow>
      ))}
  </Table>
);

export default function ({ program }) {
  const tabs = React.useMemo(
    () => ({
      PROGRAM_DETAILS: {
        id: 'PROGRAM_DETAILS',
        label: 'Program Details',
        renderTab: () => renderProgramDetails(program),
      },
      ASSESSMENT_DETAILS: {
        id: 'ASSESSMENT_DETAILS',
        label: 'Assessment Details',
        renderTab: () => renderAssessmentView(program),
      },
      LEARNING_DETAILS: {
        id: 'LEARNING_DETAILS',
        label: 'Learning Details',
        renderTab: () => renderLearningDetails(program),
      },
      SPONSOR_DETAILS: {
        id: 'SPONSOR_DETAILS',
        label: 'Sponsor Details',
        renderTab: () => renderSponsorDetails(program),
      },
    }),
    [program],
  );

  const [activeTab, setActiveTab] = useState(tabs.PROGRAM_DETAILS.id);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Row className="p-1">
      <Col>
        <div className="tabs tabs--bordered-bottom">
          <div className="tabs__wrap">
            <Nav tabs>
              {Object.keys(tabs).map((key) => (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === tabs[key].id })}
                    onClick={() => toggle(tabs[key].id)}
                  >
                    {tabs[key].label}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={activeTab}>{tabs[activeTab].renderTab()}</TabPane>
            </TabContent>
          </div>
        </div>
      </Col>
    </Row>
  );
}
