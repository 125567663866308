import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import EmployeesTableWrapper from './table/EmployeesTableWrapper';

export function EmployeesCard() {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Employees</h3>
        </Col>
      </Row>
      <Row>
        <EmployeesTableWrapper />
      </Row>
    </Container>
  );
}
