import React from 'react';
import {
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import UserAssignedLPTable from './UserAssignedLPTable';
import CohortAssignedLPTable from './CohortAssignedLPTable';
import OrganizationAssignedLPTable from './OrganizationAssignedLPTable';
import { useLocation } from 'react-router-dom';
import { USERS, ORGANIZATION, COHORT, assignedCourseORLPTabs } from '@/shared/helpers/constants';

const AssignedLPTabs = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = React.useState(USERS);

  const toggle = (tab) => {
    setActiveTab(tab);
  };

  React.useEffect(() => {
    const activeTabParam = new URLSearchParams(location.search)?.get('activeTab');
    if (
      activeTabParam &&
      activeTabParam !== activeTab &&
      [USERS, ORGANIZATION].includes(activeTab)
    ) {
      toggle(activeTabParam);
    }
  }, [location.search]);

  return (
    <Col className="card-body">
      <div className="tabs tabs--bordered-bottom">
        <div className="tabs__wrap">
          <Nav tabs>
            {Object.keys(assignedCourseORLPTabs).map((key) => (
              <NavItem key={assignedCourseORLPTabs[key].id}>
                <NavLink
                  className={classnames({ active: activeTab === assignedCourseORLPTabs[key].id })}
                  onClick={() => toggle(assignedCourseORLPTabs[key].id)}
                >
                  {assignedCourseORLPTabs[key].label}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={activeTab}>
              <Container>
                <Row>
                  <CardBody>
                    {activeTab === USERS && <UserAssignedLPTable />}
                    {activeTab === COHORT && <CohortAssignedLPTable />}
                    {activeTab === ORGANIZATION && <OrganizationAssignedLPTable />}
                  </CardBody>
                </Row>
              </Container>
            </TabPane>
          </TabContent>
        </div>
      </div>
    </Col>
  );
};

export default AssignedLPTabs;
