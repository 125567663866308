import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const fetchApplicantList = createAction('CADS_AI_FETCH_APPLICANT_LIST');
export const setApplicantList = createAction('CADS_AI_SET_APPLICANT_LIST');
export const setApplicantCount = createAction('CADS_AI_SET_APPLICANT_COUNT');
export const setApplicantError = createAction('CADS_AI_SET_APPLICANTM_ERROR');
export const setApplicantFilters = createAction('CADS_AI_SET_APPLICANT_FILTERS');
export const toggleCheckedApplicants = createAction('CADS_AI_TOGGLE_CHECKED_APPLICANT');
export const uncheckAllApplicants = createAction('CADS_AI_UNCHECK_ALL_APPLICANT');
export const setApplicantOptionsList = createAction('CADS_AI_SET_APPLICANT_OPTIONS_LIST');
export const setApplicantStageId = createAction('CADS_AI_SET_APPLICANT_STAGE_ID');
export const setProgramId = createAction('CADS_AI_APPLICANT_LIST_PROGRAM_ID');

export const toggleCheckedItemById = (applicantId) => (dispatch) => {
  dispatch(toggleCheckedApplicants(applicantId));
};

export const getApplicantList = (filter) => async (dispatch) => {
  const defaultError = `Error occurred getting applicant list`;
  dispatch(fetchApplicantList());
  try {
    const { data, isSuccess } = await api.cadsAIApplicant.getApplicantList(filter);
    if (isSuccess) dispatch(setApplicantList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setApplicantError(error.message));
    console.error(defaultError, error.message);
  }
};

export const setSelectedApplicantStageId = (stageId) => (dispatch) => {
  dispatch(setApplicantStageId(stageId));
};

export const setSelectedProgramId = (statusId) => (dispatch) => {
  dispatch(setProgramId(statusId));
};
