import React, { Fragment } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import { Button } from 'reactstrap';
import EyeIcon from 'mdi-react/EyeIcon';
import SettingsIcon from 'mdi-react/SettingsIcon';
import PencilIcon from 'mdi-react/PencilIcon';
import BinIcon from 'mdi-react/BinIcon';
import { useDomainUIContext } from '../DomainUIProvider';
import DomainTableColumns, { DomainColumns } from './DomainTableColumns';
import { DomainTableSearchBar } from './DomainTableSearchBar';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getDomainsList, setDomainFilters } from '../../redux/actions';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { defaultPaginationProps } from '@/shared/helpers';

const DomainTable = () => {
  const domainUIContext = useDomainUIContext();
  const dispatch = useDispatch();
  const {
    data: domains,
    error,
    filters,
    loading,
    totalRecords,
  } = useSelector((state) => state.domains, shallowEqual);
  const {
    pageSize: rowsPerPage,
    pageNumber: page,
    sortBy: orderBy,
    isSortOrderDescending,
  } = filters;

  React.useEffect(() => {
    dispatch(getDomainsList(filters));
  }, [filters]);

  const handleRequestSort = (event, property) => {
    dispatch(setDomainFilters({ sortBy: property, isSortOrderDescending: !isSortOrderDescending }));
  };

  const handleChangePage = (event, currentPage) => {
    dispatch(setDomainFilters({ pageSize: rowsPerPage, pageNumber: currentPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setDomainFilters({ pageSize: +event.target.value, pageNumber: 1 }));
  };

  const emptyRows =
    page === Math.ceil(totalRecords / rowsPerPage) ? rowsPerPage - domains.length : 0;

  const renderActionButtons = ({ id: domainId }) => (
    <div className="actions">
      <Button
        className="icon mb-2"
        color="secondary"
        outline
        onClick={() => domainUIContext.openManageSubcategoriesDialog(domainId)}
      >
        <p>
          <SettingsIcon />
          Manage Subcategories
        </p>
      </Button>
      <Button
        className="icon mb-2"
        color="success"
        outline
        onClick={() => domainUIContext.openViewDomainDialog(domainId)}
      >
        <p>
          <EyeIcon /> View
        </p>
      </Button>
      <Button
        className="icon mb-2"
        color="primary"
        outline
        onClick={() => domainUIContext.openEditDomainDialog(domainId)}
      >
        <p>
          <PencilIcon /> Edit
        </p>
      </Button>
      <Button
        className="icon mb-2"
        color="danger"
        outline
        onClick={() => domainUIContext.openDeleteDomainDialog(domainId)}
      >
        <p>
          <BinIcon /> Delete
        </p>
      </Button>
    </div>
  );

  return (
    <Fragment>
      <div className="material-table__wrap">
        <DomainTableSearchBar />
        <Table className="material-table">
          <DomainTableColumns
            order={isSortOrderDescending ? 'desc' : 'asc'}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rowsPerPage}
          />
          <TableBody>
            {(loading || !!error) && (
              <TableRow>
                <TableCell colSpan={DomainColumns.length}>
                  {loading && (
                    <div className={'text-center overflow-hidden'}>
                      <div className="panel__refresh position-relative" style={{ height: 75 }}>
                        <LoadingIcon />
                      </div>
                    </div>
                  )}
                  <div className={'text-center'}>
                    <p className={!!error ? 'm-5' : ''}>{error || 'Loading domains...'}</p>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {!!!domains.length && !loading && !error && (
              <TableRow style={{ height: 120 }}>
                <TableCell colSpan={DomainColumns.length} align="center">
                  {filters.status === null && filters.search === ''
                    ? 'No domains'
                    : 'No domains with applied filters'}
                </TableCell>
              </TableRow>
            )}
            {domains?.map((domain) => {
              return (
                <TableRow className="material-table" tabIndex={-1} key={domain.id}>
                  <TableCell
                    className="material-table__cell"
                    component="th"
                    align="center"
                    scope="row"
                  >
                    {domain?.name}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {domain?.description?.length > 65
                      ? domain?.description.substr(0, 65) + '...'
                      : domain?.description}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell pr-5">
                    {domain?.subcategories}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {domain?.skills}
                  </TableCell>
                  <TableCell
                    align="center"
                    className="material-table__cell"
                    style={{ minWidth: '295px' }}
                  >
                    {renderActionButtons(domain)}
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={DomainColumns.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        component={defaultPaginationProps.component}
        backIconButtonProps={defaultPaginationProps.backIconButton}
        nextIconButtonProps={defaultPaginationProps.nextIconButton}
        rowsPerPageOptions={defaultPaginationProps.rowsPerPage}
        className={defaultPaginationProps.className}
        dir={defaultPaginationProps.dir}
      />
    </Fragment>
  );
};

export default DomainTable;
