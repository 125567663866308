import React from 'react';
import { Col, Row } from 'reactstrap';
import moment from 'moment';

export default function ({ applicant }) {
  return (
    <Row className="p-1">
      <Col>
        <table className="project-summary__info">
          {/* <tr>
            <th>id</th>
            <td>{applicant?.id}</td>
          </tr>
          <tr>
            <th>user_id</th>
            <td>{applicant?.userId}</td>
          </tr> */}
          <tr>
            <th>User Name</th>
            <td>{applicant?.userName}</td>
          </tr>
          <tr>
            <th>User Email</th>
            <td>{applicant?.userEmail}</td>
          </tr>
          <tr>
            <th>IC Number</th>
            <td>{applicant?.icNumber}</td>
          </tr>
          <tr>
            <th>Bumi / Citizen</th>
            <td>{applicant?.isBhumiputra}</td>
          </tr>
          <tr>
            <th>Mobile Number</th>
            <td>{applicant?.mobileNumber}</td>
          </tr>
          <tr>
            <th>Highest Qualification</th>
            <td>{applicant?.highestQualification}</td>
          </tr>
          <tr>
            <th>University Attended</th>
            <td>{applicant?.university}</td>
          </tr>
          <tr>
            <th>Program referenced by</th>
            <td>{applicant?.programReferencedBy?.title}</td>
          </tr>
          <tr>
            <th>Work Exp Yrs</th>
            <td>{applicant?.workingExp}</td>
          </tr>
          <tr>
            <th>Age 28 or below</th>
            <td>{applicant?.isAgeLessOrEqual28 ? 'Yes' : 'No'}</td>
          </tr>
          <tr>
            <th>Application Status</th>
            <td>{applicant?.applicantStatus?.name}</td>
          </tr>
          <tr>
            <th>Application Stage</th>
            <td>{applicant?.applicantStage?.name}</td>
          </tr>
          <tr>
            <th>Cohort</th>
            <td>{applicant?.applicantCohort?.name}</td>
          </tr>
          <tr>
            <th>Program Name</th>
            <td>{applicant?.program?.programName}</td>
          </tr>
          <tr>
            <th>Program Start Date</th>
            <td>{moment(applicant?.program?.startDate).format('DD/MM/yyyy')}</td>
          </tr>
          <tr>
            <th>Program End Date</th>
            <td>{moment(applicant?.program?.endDate).format('DD/MM/yyyy')}</td>
          </tr>
          <tr>
            <th>Date Applied</th>
            <td>{moment(applicant?.program?.appliedDate).format('DD/MM/yyyy')}</td>
          </tr>
          {/* <tr>
            <th>Program_id</th>
            <td>{applicant?.program_id}</td>
          </tr>*/}
        </table>
      </Col>
    </Row>
  );
}
