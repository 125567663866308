import { handleActions } from 'redux-actions';
import {
  setCourseFetching,
  setCourseAssignList,
  setCourseError,
  setCadsAiCourseFilters,
  toggleCheckedCourse,
  clearCheckedCourse,
  clearCourseFilters,
  updateCourseInList,
  toggleCheckedCadsAiCourse,
  uncheckAllCourses,
  setAssignModuleId,
  setCourseOptions,
} from './actions';

const defaultState = {
  loading: false,
  checkedCourseIds: [],
  courseOptions: [],
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    search: '',
    isSortOrderDescending: false,
    moduleId: null,
  },
  totalRecords: 0,
  assignCourses: [],
  error: null,
  checkedCourse: { id: -1, name: 'Select Course' }, //setting to -1 if no module is selected
};

export default handleActions(
  {
    [setCourseFetching](state) {
      return {
        ...state,
        assignCourses: [],
        totalRecords: 0,
        loading: true,
        error: null,
      };
    },
    [setCourseError](state, { payload }) {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    },
    [setCourseAssignList](state, { payload }) {
      const assignModuleList = payload.data;
      return {
        ...state,
        assignCourses: assignModuleList?.records,
        totalRecords: assignModuleList?.totalRecords,
        loading: false,
        error: null,
      };
    },
    [setCadsAiCourseFilters](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
        checkedCourseIds: [],
      };
    },
    [setCourseOptions](state, { payload }) {
      return {
        ...state,
        courseOptions: payload.data,
      };
    },
    [toggleCheckedCadsAiCourse](state, { payload: itemId }) {
      const checkedIds = state.checkedCourseIds;
      return {
        ...state,
        checkedCourseIds: checkedIds.includes(itemId)
          ? checkedIds.filter((id) => id !== itemId)
          : [...checkedIds, itemId],
      };
    },
    [uncheckAllCourses](state) {
      return {
        ...state,
        checkedCourseIds: [],
      };
    },
    [toggleCheckedCourse](state, { payload }) {
      return {
        ...state,
        checkedCourse: payload ?? defaultState.checkedCourse,
      };
    },
    [clearCheckedCourse](state) {
      return {
        ...state,
        checkedCourse: defaultState.checkedCourse,
      };
    },
    [setAssignModuleId](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          moduleId: payload,
        },
      };
    },
    [clearCourseFilters](state) {
      return {
        ...state,
        filters: defaultState.filters,
      };
    },
    [updateCourseInList](state, { payload }) {
      return {
        ...state,
        assignCourses: state.assignCourses.map((m) =>
          m.id === payload.id ? { ...m, ...payload } : m,
        ),
      };
    },
  },
  defaultState,
);
