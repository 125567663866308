import React, { Fragment } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import { Button, FormGroup, Input } from 'reactstrap';

import PencilIcon from 'mdi-react/PencilIcon';
import { useCourseUIContext } from '../CourseUIProvider';
import CourseTableColumns, { getCourseColumns } from './CourseTableColumns';
import { CourseTableSearchBar } from './CourseTableSearchBar';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getCadsAiCoursesList,
  setCadsAiCourseFilters,
  toggleCheckedItemById,
  toggleCheckedCourse,
} from '../../redux/actions';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { defaultPaginationProps } from '@/shared/helpers';
import EyeIcon from 'mdi-react/EyeIcon';
import ViewModuleIcon from 'mdi-react/ViewModuleIcon';

const ModuleTable = () => {
  const courseUIContext = useCourseUIContext();
  const dispatch = useDispatch();
  const {
    loading,
    filters,
    totalRecords,
    assignCourses: courses,
    checkedCourseIds,
    error,
    checkedCourse,
  } = useSelector((state) => state.cadsAICourse, shallowEqual);
  const {
    pageSize: rowsPerPage,
    pageNumber: page,
    sortBy: orderBy,
    isSortOrderDescending,
  } = filters;

  React.useEffect(() => {
    dispatch(getCadsAiCoursesList(filters));
  }, [filters]);

  const handleRequestSort = (event, property) => {
    dispatch(
      setCadsAiCourseFilters({ sortBy: property, isSortOrderDescending: !isSortOrderDescending }),
    );
  };

  const handleChangePage = (event, currentPage) => {
    dispatch(setCadsAiCourseFilters({ pageSize: rowsPerPage, pageNumber: currentPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setCadsAiCourseFilters({ pageSize: +event.target.value, pageNumber: 1 }));
  };

  const emptyRows =
    page === Math.ceil(totalRecords / rowsPerPage) ? rowsPerPage - courses.length : 0;

  const CourseColumns = getCourseColumns(courseUIContext.isForAssignment);

  const renderActionButtons = ({ id: moduleId }) => (
    <div className="actions">
      <Button
        className="icon mb-0"
        color="success"
        outline
        onClick={() => courseUIContext.openViewCourseDialog(moduleId)}
      >
        <p>
          <EyeIcon /> View
        </p>
      </Button>
      <Button
        className="icon mb-0"
        color="primary"
        outline
        onClick={() => courseUIContext.openEditCourseDialog(moduleId)}
      >
        <p>
          <PencilIcon /> Edit
        </p>
      </Button>
    </div>
  );

  return (
    <Fragment>
      <div className="material-table__wrap">
        <CourseTableSearchBar />
        <Table className="material-table">
          <CourseTableColumns
            isForAssignment={courseUIContext.isForAssignment}
            order={isSortOrderDescending ? 'desc' : 'asc'}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rowsPerPage}
          />
          <TableBody>
            {(loading || !!error) && (
              <TableRow>
                <TableCell colSpan={CourseColumns.length}>
                  {loading && (
                    <div className={'text-center overflow-hidden'}>
                      <div className="panel__refresh position-relative" style={{ height: 75 }}>
                        <LoadingIcon />
                      </div>
                    </div>
                  )}
                  <div className={'text-center'}>
                    <p className={!!error ? 'm-5' : ''}>{error || 'Loading courses...'}</p>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {!!!courses.length && !loading && !error && (
              <TableRow style={{ height: 120 }}>
                <TableCell colSpan={CourseColumns.length} align="center">
                  {filters.status === null && filters.search === ''
                    ? 'No courses'
                    : 'No courses with applied filters'}
                </TableCell>
              </TableRow>
            )}
            {courses?.map((course) => {
              return (
                <TableRow className="material-table" tabIndex={-1} key={course.id}>
                  {courseUIContext.isForAssignment && (
                    <TableCell align="center" className="material-table__cell">
                      <FormGroup>
                        <Input
                          className="table-checkbox"
                          type="checkbox"
                          value={course.id}
                          checked={checkedCourse.id === course.id}
                          onChange={() => dispatch(toggleCheckedCourse(course))}
                        />
                      </FormGroup>
                    </TableCell>
                  )}
                  {!courseUIContext.isForAssignment && (
                    <TableCell align="center" className="material-table__cell">
                      <FormGroup>
                        <Input
                          type="checkbox"
                          value={course?.id}
                          defaultChecked={checkedCourseIds.includes(course?.id)}
                          onChange={(e) => dispatch(toggleCheckedItemById(+e.target.value))}
                        />
                      </FormGroup>
                    </TableCell>
                  )}
                  <TableCell align="center" className="material-table__cell">
                    {course?.imageUrl?.startsWith('http') ? (
                      <img className="module_logo_image" src={course?.imageUrl} alt="course logo" />
                    ) : (
                      <span className="lp_default_logo">
                        <ViewModuleIcon />
                      </span>
                    )}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {course?.title}
                  </TableCell>
                  {!courseUIContext.isForAssignment && (
                    <TableCell
                      align="center"
                      className="material-table__cell"
                      style={{ minWidth: 256 }}
                    >
                      {renderActionButtons(course)}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={CourseColumns.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        component={defaultPaginationProps.component}
        backIconButtonProps={defaultPaginationProps.backIconButton}
        nextIconButtonProps={defaultPaginationProps.nextIconButton}
        rowsPerPageOptions={defaultPaginationProps.rowsPerPage}
        className={defaultPaginationProps.className}
        dir={defaultPaginationProps.dir}
      />
    </Fragment>
  );
};

export default ModuleTable;
