import { paths } from '@/shared/helpers/paths';
import React, { Suspense } from 'react';
import Loading from '@/shared/components/Loading';
import { Redirect, Route, Switch } from 'react-router-dom';
import Modules from './Module';
import Courses from './Course';
import Assign from './Assign';
import Assigned from './Assigned';
import LearningPaths from './LearningPath';
import TrainingProviders from './TrainingProviders';

export default function ManageLearning() {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        {
          <Redirect
            exact
            from={paths.CADS_AI.LEARNING.BASE}
            to={paths.CADS_AI.LEARNING.LEARNING_PATH}
          />
        }
        <Route path={paths.CADS_AI.LEARNING.LEARNING_PATH} component={LearningPaths} />
        <Route path={paths.CADS_AI.LEARNING.MODULE} component={Modules} />
        <Route path={paths.CADS_AI.LEARNING.COURSE} component={Courses} />
        <Route path={paths.CADS_AI.LEARNING.TRAINING_PROVIDERS} component={TrainingProviders} />
        <Route path={paths.CADS_AI.LEARNING.ASSIGN} component={Assign} />
        <Route path={paths.CADS_AI.LEARNING.ASSIGNED} component={Assigned} />
      </Switch>
    </Suspense>
  );
}
