import React, { useState } from 'react';
import { Button } from 'reactstrap';
import TableHead from '@material-ui/core/TableHead';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setQAFilters } from '../../redux/actions';
import Select from "react-select";
import api from "@/utils/apiClient";

export function QATableSearchBar() {
  const dispatch = useDispatch();
  const totalQACount = useSelector((state) => state.cadsAIQAData?.data?.length, shallowEqual);

  const [isSearched, setIsSearched] = useState(false);
  const [postedByOptions, setPostedByOptions] = React.useState([{value: -1, label: "All"}]);
  const [filters, setFilters] = React.useState({
    questionText: '',
    postedBy: {
      value: -1,
      label: "All"
    }
  });

  React.useEffect(() => {
    const postedById = filters.postedBy.value;
    dispatch(
        setQAFilters({
          postedBy: postedById === -1 ? null : postedById,
        }),
    );
  }, [filters.postedBy]);

  React.useEffect(() => {
    getPostedByUserList();
  }, []);

  const getPostedByUserList = async () => {
    try {
      const {data: res, isSuccess} = await api.cadsAIQA.getPostedByUserList();
      if (isSuccess) {
        const postedByList = res.data.map(postedBy => ({value: postedBy?.autherId, label: postedBy?.autherName}));
        setPostedByOptions([{value: -1, label: 'All'}, ...postedByList]);
      } else {
        throw new Error(res?.messages?.[0] ?? 'Error while getting list of users who posted questions');
      }
    } catch ({message}) {
      setPostedByOptions([{value: -1, label: 'All'}]);
      console.error(message);
    }
  }

  const handleSearch = () => {
    const postedById = filters.postedBy.value;
    dispatch(
        setQAFilters({
          searchText: filters.questionText,
          postedBy: postedById === -1 ? null : postedById,
        }),
    );
  };

  const handleClear = () => {
    setFilters({
      ...filters,
      questionText: '',
      postedBy: {
        value: -1,
        label: "All"
      }
    });
    setIsSearched(false);
    dispatch(setQAFilters({searchText: '', postedBy: null}));
  };

  return (
    <TableHead>
      <div className="table__search">
        <div className="table__search_item">
          <b>Question</b>
          <div className="form" style={{width: 300}}>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <div className="form__form-group-icon" style={{height: 37}}>
                  <MagnifyIcon/>
                </div>
                <input
                    value={filters.questionText}
                    onChange={(e) => setFilters({...filters, questionText: e.target.value})}
                    className="search-bar-search-text"
                    name="searchText"
                    type="text"
                    placeholder="Search By Question"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table__search_item">
          <b>Posted By</b>
          <div className="form" style={{width: 200}}>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Select
                    name="skill"
                    value={filters.postedBy}
                    onChange={postedBy => setFilters({...filters, postedBy})}
                    autosize={true}
                    options={postedByOptions}
                    clearable={false}
                    className="react-select"
                    placeholder={'Select Posted By'}
                    classNamePrefix="react-select"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table__search_item mt-3">
          <Button color="primary" onClick={handleSearch}>
            {' '}
            Search{' '}
          </Button>
        </div>
        <div className="table__search_item mt-3">
          <Button color="secondary" onClick={handleClear} outline>
            Clear
          </Button>
        </div>
      </div>

      {isSearched && (
        <div className="ml-1">
          <span>Found {totalQACount} matches</span>
        </div>
      )}
    </TableHead>
  );
}
