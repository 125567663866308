import Table from "@material-ui/core/Table";
import { TableCell } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import { Button, ButtonToolbar } from "reactstrap";
import PinOffIcon from "mdi-react/PinOffIcon";
import React from "react";
import PinIcon from "mdi-react/PinIcon";
import { useQAUIContext } from "@/containers/Cads-AI/ManageHome/Q&A/components/QAUIProvider";
import { cadsAiQABaseUrl } from "@/containers/Cads-AI/ManageHome/Q&A";

const PinnedQuestionsTable = ({pinnedQuestions}) => {

  const questionUIContext = useQAUIContext();

  return (
      <Table className="material-table">
        {pinnedQuestions?.length === 0 ?
            <p style={{fontSize: 22}}>There are no pinned questions at the moment.</p>
            :
            <TableRow>
              <TableCell className="text-center" style={{width: 70}}><b>Order</b></TableCell>
              <TableCell className="text-center"><b>Question</b></TableCell>
              <TableCell className="text-center"><b>Posted By</b></TableCell>
              <TableCell className="text-center" style={{minWidth: 200}}><b>Actions</b></TableCell>
            </TableRow>
        }
        {pinnedQuestions.map((question, idx) =>
            <TableRow key={question.id}>
              <TableCell className="text-center">{idx + 1}</TableCell>
              <TableCell
                  className="text-center qa-row"
                  onClick={() => window.open(`${cadsAiQABaseUrl}/${question?.id}/view`, '_blank', 'noopener,noreferrer')}
              >
                {question.title}
              </TableCell>
              <TableCell className="text-center">{question.userFullName}</TableCell>
              <TableCell className="text-center">
                <Button className="icon" color="primary" outline onClick={() => questionUIContext.openUpdatePinnedQuestions(question.id, idx + 1)}>
                  <p><PinIcon/> Update</p>
                </Button>
                <Button className="icon" color="danger" outline onClick={() => questionUIContext.openUnpinQuestion(question.id)}>
                  <p><PinOffIcon/> Unpin</p>
                </Button>
              </TableCell>
            </TableRow>
        )}
        <ButtonToolbar>
          <Button color="primary" outline onClick={questionUIContext.openNewPinnedQuestions}>
            <p style={{width: "max-content"}}>Pin new question</p>
          </Button>
        </ButtonToolbar>
      </Table>
  );
}

export default PinnedQuestionsTable;
