import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { shallowEqual, useSelector } from 'react-redux';
import { TrainingProviderPage } from '@/containers/Control-Panel/TrainingProvider/components/TrainingProviderPage';

const StepOne = ({ onSubmit }) => {
  const { checkedTrainingProvider } = useSelector(
    (state) => state.cpTrainingProviders,
    shallowEqual,
  );
  return (
    <div className="step-one">
      <br />

      <TrainingProviderPage isForAssignment={true} />

      <br />

      <ButtonToolbar className="form__button-toolbar wizard__toolbar">
        <Button color="primary" disabled className="previous">
          Back
        </Button>
        <Button
          color="primary"
          onClick={onSubmit}
          className="next"
          disabled={checkedTrainingProvider.id === -1}
        >
          Next
        </Button>
      </ButtonToolbar>
    </div>
  );
};

export default StepOne;
