import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const toggleCheckedOrgs = createAction('BT_TOGGLE_CHECKED_ORGS');
export const uncheckAllBTOrgs = createAction('BT_UNCHECK_ALL_ORGS');
export const setBTOrganizationFilter = createAction('BT_ORG_SET_LIST_FILTERS');
export const fetchOrganizationListForBT = createAction('BT_ORG_FETCH_LIST');
export const setOrganizationListForBT = createAction('BT_ORG_SET_LIST');
export const setBTOrganizationError = createAction('BT_SET_ORG_LIST_ERROR');

export const toggleCheckedItemById = (orgId) => (dispatch) => {
  dispatch(toggleCheckedOrgs(orgId));
};

export const uncheckAllSelectedOrgsForBT = () => (dispatch) => {
  dispatch(uncheckAllBTOrgs());
};

export const setBaselineThresholdFilters = (filter) => (dispatch) => {
  dispatch(setBTOrganizationFilter(filter));
};

export const getOrganizationListForBT = (filter) => async (dispatch) => {
  const defaultError = 'Error occurred getting baseline threshold list';
  try {
    dispatch(fetchOrganizationListForBT());
    const { data, isSuccess } = await api.baselineThreshold.getOrganizationsWithBaselineThreshold(
      filter,
    );
    if (isSuccess) dispatch(setOrganizationListForBT(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setBTOrganizationError(error));
    console.error(defaultError, error.message);
  }
};
