import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const setOrganizationOptionsList = createAction('SET_ORG_OPTIONS_LIST');
export const setOrganizationFetching = createAction('SET_ORGANIZATION_FETCHING');
export const setOrganizationError = createAction('SET_ORGANIZATION_ERROR');
export const setAssignOrganizationList = createAction('SET_ASSIGN_ORGANIZATION_LIST');
export const setOrganizationFilters = createAction('SET_ORGANIZATION_LIST_FILTER');
export const setOrgState = createAction('SET_ORGANIZATION_STATE');

export const getCPOrganizationsList = (filter) => async (dispatch, getState) => {
  const isFromLP = getState().assignPage.isFromLearningPath;
  const isFromTP = getState().assignPage.isFromTrainingProvider;
  const defaultError = `Error occurred getting ${
    filter.assigned ? 'Assigned' : 'Assign'
  } organization list`;
  dispatch(setOrganizationFetching());
  try {
    const { data, isSuccess } = await api.organization.getOrganizationList(filter);
    if (isSuccess) {
      dispatch(setAssignOrganizationList({ ...data, isFromLP, isFromTP }));
    } else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setOrganizationError(error.message));
    console.error(defaultError, error.message);
  }
};

export const getOrganizationOptionsList = (filters) => async (dispatch) => {
  const defaultError = 'Error occurred getting organization options list';
  try {
    const { data, isSuccess } = await api.organization.getOrganizationOptionsList(filters);
    if (isSuccess) dispatch(setOrganizationOptionsList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    console.error(defaultError, error.message);
  }
};
