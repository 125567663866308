import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loading from '@/shared/components/Loading';
import { paths } from '@/shared/helpers/paths';
import { EmployeesPage } from './components/EmployeesPage';
import './index.scss';

export const cadsAiEmployeesBaseUrl = paths.CADS_AI.EMPLOYEES;

const Employees = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path={cadsAiEmployeesBaseUrl} component={EmployeesPage} />
      </Switch>
    </Suspense>
  );
};

export default Employees;
