import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { cadsAiArticlesBaseUrl } from '../index';
import { ArticlesUIProvider } from './ArticlesUIProvider';
import { ArticlesCard } from './ArticlesCard';
import ArticleEditDialog from './article-add-edit-dialog/ArticleEditDialog';
import ArticleViewDialog from './article-view-dialog/ArticleViewDialog';
import { setArticleFilters, uncheckAllArticle } from '../redux/actions';
import { useDispatch } from 'react-redux';
import ArticleDeactivateDialog from './article-deactivate-dialog/ArticleDeactivateDialog';

export function ArticlesPage({ history, match }) {
  const dispatch = useDispatch();

  //clean up
  React.useEffect(() => () => dispatch(uncheckAllArticle()), []);

  const articlesUIEvents = {
    openViewArticlesDialog: (id) => {
      history.push(`${cadsAiArticlesBaseUrl}/${id}/view`);
    },
    openNewArticlesDialog: () => {
      history.push(`${cadsAiArticlesBaseUrl}/new`);
    },
    openEditArticlesDialog: (id) => {
      history.push(`${cadsAiArticlesBaseUrl}/${id}/edit`);
    },
    openDeactivateArticlesDialog: (id) => {
      history.push(`${cadsAiArticlesBaseUrl}/${id}/deactivate`);
    },
  };

  return (
    <ArticlesUIProvider articlesUIEvents={articlesUIEvents}>
      <Switch>
        <Route path={`${cadsAiArticlesBaseUrl}/new`}>
          {({ history, match }) => (
            <ArticleEditDialog
              show={match != null}
              isEdit={false}
              onHide={() => {
                dispatch(setArticleFilters());
                history.push(cadsAiArticlesBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${cadsAiArticlesBaseUrl}/:id/edit`}>
          {({ history, match }) => (
            <ArticleEditDialog
              show={match != null}
              isEdit={true}
              id={match?.params?.id}
              onHide={() => {
                dispatch(setArticleFilters());
                history.push(cadsAiArticlesBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${cadsAiArticlesBaseUrl}/:id/view`}>
          {({ history, match }) => (
            <ArticleViewDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                history.push(cadsAiArticlesBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${cadsAiArticlesBaseUrl}/:id/deactivate`}>
          {({ history, match }) => (
            <ArticleDeactivateDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                dispatch(setArticleFilters());
                history.push(cadsAiArticlesBaseUrl);
              }}
            />
          )}
        </Route>
      </Switch>
      <ArticlesCard />
    </ArticlesUIProvider>
  );
}
