import React, { createContext, useContext } from 'react';

const AssessmentUIContext = createContext();

export function useAssessmentUIContext() {
  return useContext(AssessmentUIContext);
}

export function AssessmentUIProvider({ assessmentUIEvents, children }) {
  return (
    <AssessmentUIContext.Provider value={assessmentUIEvents}>
      {children}
    </AssessmentUIContext.Provider>
  );
}
