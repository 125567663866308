import React from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import EmployeesTable from './EmployeesTable';
import { useEmployeesUIContext } from '../EmployeesUIProvider';

const EmployeesTableWrapper = () => {
  const employeUIContext = useEmployeesUIContext();

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="products-list">
          <div className="card__title">
            <h5 className="bold-text">Data Employees List</h5>
            <ButtonToolbar className="products-list__btn-toolbar-top">
              <Button color="primary" onClick={employeUIContext.openNewEmployeesDialog}>
                Add new
              </Button>
              <Button color="success" outline onClick={() => null}>
                Export Employees
              </Button>
            </ButtonToolbar>
          </div>
          <EmployeesTable />
        </CardBody>
      </Card>
    </Col>
  );
};

export default EmployeesTableWrapper;
