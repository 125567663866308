import React, { useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import Progress from './Progress';
import SkillsLearn from './SkillsLearn';
import ArrowRightIcon from 'mdi-react/ArrowRightIcon';

export const LearningPath = ({ skillList, learningPaths }) => {
  const [page, setPage] = useState(1);

  const handleLoadMore = () => {
    setPage(page + 1);
  };

  const loadMoreDisabled = 4 * page > learningPaths?.length;

  return (
    <div className="skills-wrapper">
      <Row>
        <Col lg={6}>
          <div className={'card-body'}>
            <b className={'mt-4 ml-3 learning-skills font-color-for-common'}>
              Selected Learning Path
            </b>
            {skillList.map((skill, index) => {
              return (
                <div style={{ display: 'flex', flexWrap: 'wrap' }} key={index}>
                  {skill.skills.map((skillName, skillIndex) => {
                    return <SkillsLearn index={skillIndex} skill={skillName} />;
                  })}
                  <br />
                </div>
              );
            })}
          </div>
        </Col>
        <Col lg={6}>
          <Progress />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <div className={'card-body'}>
            <b className={'ml-3 learning-skills font-color-for-common'}>Skills Added to Learn</b>
            {skillList.map((skill, index) => {
              return (
                <div style={{ display: 'flex', flexWrap: 'wrap' }} key={index}>
                  {skill.skills.map((skillName, skillIndex) => {
                    return <SkillsLearn index={skillIndex} skill={skillName} />;
                  })}
                  <br />
                </div>
              );
            })}
          </div>
          <div className={'ml-4 learning-skills font-color-for-common'}>
            <b>Recommended Learnings</b>
          </div>
        </Col>
        <div className="skills-learn-wrapper row mt-5">
          {learningPaths.slice(0, 4 * page).map((item) => (
            <div className="skill-card col-lg-3 col-md-6">
              <div className="learn-item">
                <div className="continue-item">
                  <div className="image">
                    <img src={item.imageUrl} />
                  </div>
                  <h3 className="sub-title item-title">{item?.title}</h3>
                  <h4 className="item-desc">{item?.description}</h4>
                </div>
                <div className="next">
                  <Button className="btn continue-item">
                    View More
                    <ArrowRightIcon />
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="load-more">
          <Button disabled={loadMoreDisabled} color="primary" onClick={handleLoadMore}>
            Load More{' '}
          </Button>
        </div>
      </Row>
    </div>
  );
};
