import React from 'react';
import api from '@/utils/apiClient';
import { Field } from '@/shared/components/Field';
import Panel from '@/shared/components/Panel';
import { Col, Row } from 'reactstrap';

const AssignedAssessment = ({ id }) => {
  const [state, setState] = React.useState({
    loading: false,
    assignedAssessment: [],
    error: '',
  });

  const getUsersAssessmentList = async (id) => {
    try {
      setState({ ...state, loading: true });
      const { data: res, isSuccess } = await api.cadsAIUsers.getUsersAssessmentList(id);
      if (isSuccess) {
        setState({ ...state, loading: false, assignedAssessment: res.data, error: '' });
      } else {
        throw new Error(res?.messages?.[0] ?? 'Error while getting assigned assessment details');
      }
    } catch ({ message }) {
      setState({ ...state, loading: false, assignedAssessment: [], error: message });
      console.error(message);
    }
  };

  React.useEffect(() => {
    getUsersAssessmentList(id);
  }, [id]);

  const { assignedAssessment } = state;

  return (
    <div className="applicant_panel_card">
      <h4 className="pl-4 mb-4 mt-2">Assigned Assessment Details</h4>
      {assignedAssessment?.map?.((assessment, idx) => (
        <Panel title={assessment?.title} key={idx}>
          <Row className="pt-2">
            <Col md={4} xl={4} lg={4}>
              <Field
                title={'Assessment Name'}
                iconDisable={true}
                fieldValue={assessment?.title ?? '-'}
              />
            </Col>
            <Col md={4} xl={4} lg={4}>
              <Field
                title={'Assessment Type Title'}
                iconDisable={true}
                fieldValue={assessment?.assessmentTypeTitle}
              />
            </Col>
            <Col md={4} xl={4} lg={4}>
              <Field title={'Category'} iconDisable={true} fieldValue={assessment?.categoery} />
            </Col>
          </Row>
        </Panel>
      ))}
    </div>
  );
};

export default AssignedAssessment;
