import { sendRequest } from '../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const emailBaseUrl = process.env.REACT_APP_CADS_AI_API + process.env.REACT_APP_BASE_INFO_ENDPOINT;

export const emailEndpoints = {
  GET_ALL_EMAILS: '/',
  GET_EMAIL_BY_ID: '/',
  ADD_EMAIL: '/',
};

const getEmailList = (filter) => {
  return getMockEmailList();
  return sendRequest({
    fullUrl: emailEndpoints.GET_ALL_EMAILS,
    method: httpMethod.GET,
    params: filter,
  });
};

const addEmail = (data) => {
  return addMockEmail();
  return sendRequest({
    fullUrl: emailEndpoints.ADD_EMAIL,
    method: httpMethod.POST,
    data: data,
  });
};

const getEmailById = (id) => {
  return getMockEmailById();
  return sendRequest({
    fullUrl: emailEndpoints.GET_EMAIL_BY_ID,
    method: httpMethod.GET,
    params: { id: id },
  });
};

const getMockEmailById = () => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [],
            data: {
              id: 1,
              subject: 'Subject 1',
              body: '<p>Mock body</p>',
              to: 'Mock 1',
              from: 'Mock 2',
              date: '2025-01-27T00:00:00',
            },
          },
        }),
      500,
    );
  });
};

const addMockEmail = (data) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          status: 200,
          messages: ['Email added successfully'],
        },
      });
    }, 500);
  });
};

const getMockEmailList = () => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [],
            data: {
              totalRecords: 3,
              records: [
                {
                  id: 1,
                  subject: 'Subject 1',
                  status: 'draft',
                  date: '2025-01-27T00:00:00',
                },
                {
                  id: 2,
                  subject: 'Subject 2',
                  status: 'sent',
                  date: '2025-04-27T00:00:00',
                },
                {
                  id: 3,
                  subject: 'Subject 3',
                  status: 'draft',
                  date: '2025-06-27T00:00:00',
                },
              ],
            },
          },
        }),
      500,
    );
  });
};

export default {
  getEmailList,
  getEmailById,
  addEmail,
};
