import React, { createContext, useContext } from 'react';

const QuestionUIContext = createContext();

export function useQuestionUIContext() {
  return useContext(QuestionUIContext);
}

export function QuestionUIProvider({ questionUIEvents, children }) {
  const value = {
    openViewQuestionDialog: questionUIEvents.openViewQuestionDialog,
    openNewQuestionDialog: questionUIEvents.openNewQuestionDialog,
    openEditQuestionDialog: questionUIEvents.openEditQuestionDialog,
    openDeleteQuestionDialog: questionUIEvents.openDeleteQuestionDialog,
  };

  return <QuestionUIContext.Provider value={value}>{children}</QuestionUIContext.Provider>;
}
