import React, { useState } from 'react';
import LoadingIcon from 'mdi-react/LoadingIcon';
import api from '@/utils/apiClient';
import CustomerWizardForm from './CustomerWizardForm';

const CustomerEditForm = ({ modal, isEdit, id }) => {
  const initialValues = {
    userDetailsId: null,
    companyName: '',
    regNo: '',
    address: '',
    country: null,
    noOfEmp: '',
    email: '',
    subdomain: '',
    firstName: '',
    lastName: '',
    role: { value: 'admin', label: 'Admin' }, //TODO: remove default value when we have multiple values for role
    companyOfficePhoneNo: '',
    companyOfficeMobileNo: '',
    notes: '',
  };

  const [data, setData] = useState(initialValues);

  const [state, setState] = React.useState({
    loading: false,
    fetchingCustomer: false,
    newCustomerAdded: false,
    errorMessage: '',
  });

  React.useEffect(() => {
    if (isEdit && id) {
      setDefaultData(+id);
    }
  }, [isEdit, id]);

  const setDefaultData = async (customerId) => {
    try {
      const { data: res, isSuccess } = await api.customers.getCustomer(customerId);
      const {
        userDetailsId,
        companyName,
        address,
        noOfEmp,
        email,
        subdomain,
        firstName,
        lastName,
        notes,
        phoneNumberOffice,
        phoneNumberMobile,
        country,
        registrationNumber,
      } = res.data;
      if (isSuccess) {
        setData({
          userDetailsId,
          companyName,
          address,
          noOfEmp,
          email,
          subdomain,
          firstName,
          lastName,
          notes,
          regNo: registrationNumber,
          country: { value: country?.id, label: country?.name },
          role: { value: 'admin', label: 'Admin' }, //TODO: remove default value when we have multiple values for role
          companyOfficePhoneNo: phoneNumberOffice,
          companyOfficeMobileNo: phoneNumberMobile,
        });
      } else {
        throw new Error(res?.messages?.[0]);
      }
    } catch ({ message }) {
      console.error('Customer edit error : ', message);
      setState({ ...state, errorMessage: 'Error while getting existing customer details' });
    }
  };

  const constructApiRequestData = ({
    companyName,
    address,
    noOfEmp,
    email,
    subdomain,
    firstName,
    lastName,
    notes,
    ...data
  }) => ({
    companyName,
    address,
    noOfEmp,
    email,
    subdomain,
    firstName,
    lastName,
    notes,
    organizationId: id ?? 0,
    registrationNumber: data.regNo,
    countryId: data.country?.value,
    phoneNumberOffice: data.companyOfficePhoneNo,
    phoneNumberMobile: data.companyOfficeMobileNo,
  });

  const handleAddEditCustomer = async (customerData, clearForm) => {
    setState({ ...state, loading: true });
    const newCustomerData = constructApiRequestData(customerData);

    const defaultError = `Error occurred while ${isEdit ? 'Updating' : 'Adding new'} customer`;
    try {
      const { data, isSuccess } = isEdit
        ? await api.customers.updateCustomer(newCustomerData)
        : await api.customers.addCustomer(newCustomerData);
      if (isSuccess) {
        setState({ ...state, newCustomerAdded: true, loading: false });
        if (!isEdit) clearForm();
      } else {
        throw new Error(data?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({ ...state, loading: false, newCustomerAdded: false, errorMessage: error.message });
    }
  };

  return (
    <div>
      {/*Add/Edit Customer Form Status*/}
      <div className="ml-4">
        {state.loading && (
          <div>
            <div className="panel__refresh position-relative" style={{ height: 75, width: 20 }}>
              <LoadingIcon />
              <div style={{ width: 200, padding: '23px 40px' }}>
                {isEdit ? 'Updating' : 'Adding new'} customer...
              </div>
            </div>
          </div>
        )}
        {!state.loading && (state.newCustomerAdded || !!state.errorMessage) && (
          <table className="mt-3">
            <tr>
              <td>
                {state.newCustomerAdded ? (
                  <span className="lnr lnr-thumbs-up modal__title-icon success" />
                ) : (
                  <span className="lnr lnr-cross-circle modal__title-icon error" />
                )}
              </td>
              <td className="pl-2">
                {state.newCustomerAdded
                  ? `${isEdit ? 'Customer Updated' : 'New Customer Added'} successfully`
                  : state.errorMessage}
              </td>
            </tr>
          </table>
        )}
      </div>

      {/*Add/Edit Customer Form*/}
      <CustomerWizardForm
        onSubmit={handleAddEditCustomer}
        initialValues={initialValues}
        data={data}
        setData={setData}
      />
    </div>
  );
};

export default CustomerEditForm;
