import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const fetchQAList = createAction('CADS_AI_FETCH_QA_LIST');
export const setQAList = createAction('CADS_AI_SET_QA_LIST');
export const setQAError = createAction('CADS_AI_SET_QA_ERROR');
export const setQAFilters = createAction('CADS_AI_SET_QA_FILTERS');
export const toggleCheckedQA = createAction('CADS_AI_TOGGLE_CHECKED_QA');
export const uncheckAllQA = createAction('CADS_AI_UNCHECK_ALL_QA');

export const toggleCheckedItemById = (qaId) => (dispatch) => {
  dispatch(toggleCheckedQA(qaId));
};

export const getQAList = (filter) => async (dispatch) => {
  const defaultError = `Error occurred getting QA list`;
  dispatch(fetchQAList());
  try {
    const { data, isSuccess } = await api.cadsAIQA.getQAList(filter);
    if (isSuccess) dispatch(setQAList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setQAError(error.message));
    console.error(defaultError, error.message);
  }
};
