import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const setUserFetching = createAction('CADS_AI_SET_USER_FETCHING');
export const setUserError = createAction('CADS_AI_SET_USER_ERROR');
export const setAssignUserList = createAction('CADS_AI_SET_ASSIGN_USER_LIST');
export const setUserFilters = createAction('CADS_AI_SET_USER_LIST_FILTER');
export const setUserState = createAction('CADS_AI_SET_USER_STATE');

export const getCadsAIUsersList = (filter) => async (dispatch) => {
  const defaultError = `Error occurred getting ${
    filter.assigned ? 'Assigned' : 'Assign'
  } user list`;
  dispatch(setUserFetching());
  try {
    const { data, isSuccess } = await api.cadsAIUser.getCadsAIUserList(filter);
    if (isSuccess) dispatch(setAssignUserList({ ...data }));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setUserError(error.message));
    console.error(defaultError, error.message);
  }
};
