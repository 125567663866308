import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const fetchJobList = createAction('FETCH_JOB_LIST');
export const setJobList = createAction('SET_JOB_LIST');
export const updateInJobList = createAction('UPDATE_JOB_IN_LIST');
export const setJobError = createAction('SET_JOB_ERROR');
export const setJobFilters = createAction('SET_JOB_FILTERS');
export const deleteJobFromList = createAction('DELETE_JOB_FROM_LIST');

export const getJobsList = (filter) => async (dispatch) => {
  const defaultError = 'Error occurred getting jobs list';
  try {
    dispatch(fetchJobList());
    const { data, isSuccess } = await api.cadsAIJob.getJobsList(filter);
    if (isSuccess) dispatch(setJobList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setJobError(error));
    console.error(defaultError, error.message);
  }
};

export const updateJobInList = (job) => (dispatch) => {
  try {
    dispatch(updateInJobList(job.data));
  } catch (error) {
    console.error('Unable to update the job in job list');
  }
};

export const deleteJob = (jobId) => async (dispatch) => {
  try {
    const { data } = await api.cadsAIJob.deleteJob(jobId);
    if (data) dispatch(deleteJobFromList(jobId));
  } catch (error) {
    console.error('Unable to remove the job from job list');
  }
};
