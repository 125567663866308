import React from 'react';
import { Button } from 'reactstrap';
import Select from 'react-select';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getOrganizationOptionsList,
  setOrgState,
} from '@/containers/Control-Panel/redux/organization/actions';
import { setUserState } from '@/containers/Control-Panel/redux/user/actions';
import { setSelectedOrganizationId } from '@/containers/Control-Panel/redux/actions';
import { setCohortState } from '@/containers/Control-Panel/redux/cohort/actions';
import { COHORT, USERS, ORGANIZATION } from '@/shared/helpers/constants';
import { defaultUserState } from '@/containers/Control-Panel/redux/user/reducer';
import { defaultCohortState } from '@/containers/Control-Panel/redux/cohort/reducer';
import { defaultOrgState } from '@/containers/Control-Panel/redux/organization/reducer';

const AssignedTableFilter = () => {
  const dispatch = useDispatch();

  const { activeTab } = useSelector((state) => state.assignedPage, shallowEqual);
  const { assignedProduct } = useSelector((state) => state.controlPanel, shallowEqual);
  const { options: organizationOpts } = useSelector((state) => state.cpOrgs, shallowEqual);
  const { totalRecords: totalUser } = useSelector((state) => state.cpUsers, shallowEqual);
  const { totalRecords: totalCohort } = useSelector((state) => state.cpCohorts, shallowEqual);
  const { totalRecords: totalOrg } = useSelector((state) => state.cpOrgs, shallowEqual);

  const [isSearched, setIsSearched] = React.useState(false);
  const [selectedOrgOpt, setSelectedOrgOpt] = React.useState({});
  const [organizationOptions, setOrganizationOptions] = React.useState([]);

  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');

  React.useEffect(() => {
    dispatch(getOrganizationOptionsList());
  }, []);

  React.useEffect(() => {
    dispatch(setSelectedOrganizationId(selectedOrgOpt?.value));
  }, [selectedOrgOpt]);

  React.useEffect(() => {
    const firstOrg = organizationOpts?.[0] ?? null;
    if (firstOrg) {
      setSelectedOrgOpt({ value: firstOrg.id, label: firstOrg.title, ...firstOrg });
      dispatch(setSelectedOrganizationId(firstOrg.id));
      setOrganizationOptions(
        organizationOpts?.map((org) => ({ value: org.id, label: org.title })) ?? [],
      );
    }
  }, [organizationOpts]);

  React.useEffect(() => {
    setName('');
    setEmail('');
  }, [assignedProduct]);

  const filterData = (isClear = false) => {
    const orgId = selectedOrgOpt.value;
    const searchAssigned = isClear
      ? { ...defaultUserState.searchAssigned, orgId }
      : { searchText: name, email, orgId };
    const filter = { assigned: true, feature: assignedProduct.id, searchAssigned };
    switch (activeTab) {
      case USERS:
        return dispatch(setUserState({ ...defaultUserState, ...filter }));
      case COHORT:
        return dispatch(setCohortState({ ...defaultCohortState, ...filter }));
      case ORGANIZATION:
        return dispatch(setOrgState({ ...defaultOrgState, ...filter }));
      default:
        return false;
    }
  };

  React.useEffect(() => {
    return () => handleClear();
  }, []);

  const handleSearch = () => {
    filterData(false);
    setIsSearched(true);
  };

  const handleClear = () => {
    setIsSearched(false);
    setName('');
    setEmail('');
    filterData(true);
  };

  return (
    <>
      <div className="table__search">
        <div className="table__search_item">
          <b>Name</b>
          <div className="form">
            <div className="form__form-group">
              <div className="form__form-group-field">
                {/*TODO: refactor common inline search input style to css class*/}
                <input
                  style={{ height: 36, minWidth: 200 }}
                  name="searchText"
                  type="text"
                  placeholder="Search by Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        {activeTab === USERS && (
          <div className="table__search_item">
            <b>Email</b>
            <div className="form">
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <input
                    style={{ height: 36, minWidth: 200 }}
                    name="searchText"
                    type="email"
                    placeholder="Search by Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {activeTab !== ORGANIZATION && (
          <div className="table__search_item">
            <b>Organization</b>
            <div style={{ minWidth: 200 }}>
              <Select
                name="organization"
                options={organizationOptions}
                value={selectedOrgOpt}
                onChange={setSelectedOrgOpt}
                autosize={true}
                clearable={false}
                className="react-select"
                placeholder={'Select organization'}
                classNamePrefix="react-select"
              />
            </div>
          </div>
        )}
        <div className="table__search_item mt-3">
          <Button color="primary" onClick={handleSearch}>
            Search{' '}
          </Button>
        </div>
        <div className="table__search_item mt-3">
          <Button color="secondary" onClick={handleClear} outline>
            Clear
          </Button>
        </div>
      </div>
      {isSearched && (
        <div className="ml-1">
          <span>
            Found {activeTab === USERS ? totalUser : activeTab === COHORT ? totalCohort : totalOrg}{' '}
            matches
          </span>
        </div>
      )}
    </>
  );
};

export default AssignedTableFilter;
