import React from 'react';
import Panel from '@/shared/components/Panel';
import { Col, Row } from 'reactstrap';
import { Field } from '@/shared/components/Field';

const learningTypes = {
  LEARNING_PATH: 'Learning Path',
  COURSE: 'Course',
  Module: 'Module',
};
const LearningDetails = ({ learningDetails }) => {
  return (
    <>
      <div className="pt-5 applicant_panel_card">
        <h4 className="pl-2 mb-4">Learning Details</h4>
        {learningDetails?.map((learning) => {
          const learningTitle = learning.learningPath ?? learning.course ?? learning.module;
          const learningType = learning.learningPath
            ? learningTypes.LEARNING_PATH
            : learning.course
            ? learningTypes.COURSE
            : learningTypes.Module;

          return (
            <Panel title={learningTitle} subhead={learningType} className="border border-dark-grey">
              <Row>
                <Col md={3} xl={3} lg={3}>
                  <Field
                    title={'Is Mandatory'}
                    iconDisable={true}
                    fieldValue={learning?.isMandatory ? 'Yes' : 'No'}
                  />
                </Col>
                <Col md={3} xl={3} lg={3}>
                  <Field
                    iconDisable={true}
                    title={'Sort Order'}
                    fieldValue={learning?.sortOrder ? learning?.sortOrder : '-'}
                  />
                </Col>
                <Col md={6} xl={6} lg={6}>
                  <Field
                    title={'Description'}
                    iconDisable={true}
                    fieldValue={learning?.description ? learning?.description : '-'}
                  />
                </Col>
              </Row>
            </Panel>
          );
        })}
      </div>
    </>
  );
};
export default LearningDetails;
