import { handleActions } from 'redux-actions';
import {
  setOrganizationOptionsList,
  setOrganizationFetching,
  setOrganizationError,
  setAssignOrganizationList,
  setOrganizationFilters,
  setOrgState,
} from './actions';
import { CP_PRODUCTS } from '@/shared/helpers/constants';

export const defaultOrgState = {
  loading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    isSortOrderDescending: false,
  },
  searchAssign: {
    searchText: '',
  },
  searchAssigned: {
    searchText: '',
  },
  totalRecords: 0,
  assignOrgs: [],
  assigned: false,
  feature: CP_PRODUCTS.ACCELTIC.id,
  options: [], // list of active organizations with minimum details (Array<{id: number, title: string}>)
  error: null,
};

export default handleActions(
  {
    [setOrganizationFetching](state) {
      return {
        ...state,
        assignOrgs: [],
        loading: true,
        error: null,
      };
    },
    [setOrganizationError](state, { payload }) {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    },
    [setOrganizationOptionsList](state, { payload }) {
      return {
        ...state,
        options: payload.data,
      };
    },
    [setAssignOrganizationList](state, { payload }) {
      let assignOrganizationsList;
      if (payload.isFromLP) {
        assignOrganizationsList = {
          records: payload?.data,
          totalRecords: payload?.totalElements,
        };
      } else if (payload.isFromTP) {
        assignOrganizationsList = {
          records: payload?.data.records,
          totalRecords: payload?.data?.totalRecords,
        };
      } else {
        assignOrganizationsList = payload.data;
      }
      return {
        ...state,
        assignOrgs: assignOrganizationsList?.records,
        totalRecords: assignOrganizationsList?.totalRecords,
        loading: false,
        error: null,
      };
    },
    [setOrganizationFilters](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
      };
    },
    [setOrgState](state, { payload }) {
      return payload;
    },
  },
  defaultOrgState,
);
