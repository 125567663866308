import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { cadsAiQABaseUrl } from '../index';
import { QAUIProvider } from './QAUIProvider';
import { QACard } from './QACard';
import { setQAFilters, uncheckAllQA } from '../redux/actions';
import { useDispatch } from 'react-redux';
import QADeleteDialog from './qa-delete-dialog/QADeleteDialog';
import ReplyDeactivateDialog from './reply-deactivate-dialog/ReplyDeactivateDialog';

export function QAPage({ history, match }) {
  const dispatch = useDispatch();

  //clean up
  React.useEffect(() => () => dispatch(uncheckAllQA()), []);

  const qaUIEvents = {
    openViewQADialog: (id) => {
      history.push(`${cadsAiQABaseUrl}/${id}/view`);
    },
    openDeleteQADialog: () => {
      history.push(`${cadsAiQABaseUrl}/deactivate`);
    },
    openDeactivateReplyDialog: (id, replyId) => {
      history.push(`${cadsAiQABaseUrl}/${id}/view/deactivate/${replyId}`);
    },
    openQA: () => {
      history.push(`${cadsAiQABaseUrl}`);
    },
    openViewPinnedQuestions: () => {
      history.push(`${cadsAiQABaseUrl}/pins`)
    },
    openNewPinnedQuestions: () => {
      history.push(`${cadsAiQABaseUrl}/pins/new`)
    },
    openUpdatePinnedQuestions: (id, order) => {
      history.push({
        pathname: `${cadsAiQABaseUrl}/pins/${id}/update`,
        state: {order}
      });
    },
    openUnpinQuestion: (id) => {
      history.push(`${cadsAiQABaseUrl}/pins/${id}/unpin`)
    }
  };

  return (
    <QAUIProvider qaUIEvents={qaUIEvents}>
      <Switch>
        <Route path={`${cadsAiQABaseUrl}/deactivate`}>
          {({ history, match }) => (
            <QADeleteDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                dispatch(setQAFilters());
                history.push(cadsAiQABaseUrl);
              }}
            />
          )}
        </Route>
        <Route exact path={`${cadsAiQABaseUrl}/:id/view/deactivate/:replyId`}>
          {({ history, match }) => (
            <ReplyDeactivateDialog
              show={match != null}
              replyId={match?.params?.replyId}
              id={match?.params?.id}
              onHide={() => {
                dispatch(setQAFilters());
                history.push(`${cadsAiQABaseUrl}/${match?.params?.id}/view`);
              }}
            />
          )}
        </Route>
      </Switch>
      <QACard />
    </QAUIProvider>
  );
}
