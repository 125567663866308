import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import HelpViewDialogHeader from './HelpViewDialogHeader';
import api from '@/utils/apiClient';
import LoadingIcon from 'mdi-react/LoadingIcon';
import moment from 'moment';

const HelpViewDialog = ({ show, onHide, id, rtl }) => {
  const [modal] = useState({
    isOpen: show,
    title: 'View Help',
    hide: onHide,
  });

  const [state, setState] = useState({
    loading: true,
    help: {},
    error: '',
  });

  useEffect(() => {
    if (id) getHelpById(+id);
  }, [id]);

  const getHelpById = async (helpId) => {
    try {
      const { data: res, isSuccess } = await api.cadsAIHelp.getHelpById(helpId);
      if (id) {
        setState({ ...state, loading: false, help: res.data, error: '' });
      } else {
        throw new Error(res?.messages?.[0] ?? 'Error while getting help details');
      }
    } catch ({ message }) {
      setState({ ...state, loading: false, help: {}, error: message });
      console.error(message);
    }
  };

  const { error, loading, help } = state;

  return (
    <Modal
      isOpen={show}
      toggle={modal.hide}
      modalClassName={`${rtl.direction}-support`}
      className="modal-dialog--primary modal-dialog--header modal-view-dialog"
    >
      <HelpViewDialogHeader modal={modal} help={help} />
      {/* Help Modal Body */}
      <div style={{ padding: 15 }}>
        {loading || !!error ? (
          <>
            {loading && (
              <div className="panel__refresh position-relative" style={{ height: 75 }}>
                <LoadingIcon />
              </div>
            )}
            <div className="text-center">
              {error && (
                <div>
                  <span className="lnr lnr-cross-circle modal__title-icon error" />
                </div>
              )}
              {loading ? 'Getting help details...' : error}
            </div>
          </>
        ) : (
          <table className="project-summary__info">
            <tr>
              <th>Name</th>
              <td>{help?.name ?? '-'}</td>
            </tr>
            <tr>
              <th>Email</th>
              <td>{help?.email ?? '-'}</td>
            </tr>
            <tr>
              <th>Subject</th>
              <td>{help?.subject ?? '-'}</td>
            </tr>
            <tr>
              <th className="w-25">Inquired On</th>
              <td>{help?.inquiredOn ? moment(help?.inquiredOn).format('DD/MM/yyyy') : '-'}</td>
            </tr>
            <tr>
              <th className="w-25">Status</th>
              <td>{help?.status}</td>
            </tr>
            <tr>
              <th>Message</th>
              <td>{help?.message ?? '-'}</td>
            </tr>
          </table>
        )}
      </div>
    </Modal>
  );
};

export default connect((state) => ({
  rtl: state.rtl,
}))(HelpViewDialog);
