import React, { useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import JobDetails from './JobDetails';
import AppliedUsers from './AppliedUsers';
import FollowedUsers from './FollowedUsers';

const JobTabs = ({ jobDetails }) => {
  const [activeTab, setActiveTab] = useState(1);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="tabs tabs--justify tabs--bordered-top overflow-hidden">
      <div className="tabs__wrap">
        <Nav tabs>
          <NavItem>
            <NavLink className={classnames({ active: activeTab === 1 })} onClick={() => toggle(1)}>
              Job Details
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames({ active: activeTab === 2 })} onClick={() => toggle(2)}>
              Applied Users
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames({ active: activeTab === 3 })} onClick={() => toggle(3)}>
              Followed Users
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={1}>
            <div className="row border-bottom pb-4">
              <JobDetails jobDetails={jobDetails} />
            </div>
          </TabPane>
          <TabPane tabId={2}>
            <AppliedUsers appliedUsers={jobDetails?.userJodApply} />
          </TabPane>
          <TabPane tabId={3}>
            <FollowedUsers followedUsers={jobDetails?.userJobFollow} />
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default JobTabs;
