import { handleActions } from 'redux-actions';
import {
  setCustomerList,
  fetchCustomerList,
  setCustomerError,
  setCustomerFilters,
  setCustomerOptionsList,
} from './actions';

const defaultState = {
  loading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    search: '',
    isSortOrderDescending: false,
    status: null,
  },
  totalRecords: 0,
  data: [],
  options: [],
  error: null,
};

export default handleActions(
  {
    [fetchCustomerList](state) {
      return {
        ...state,
        loading: true,
        data: [],
      };
    },
    [setCustomerList](state, { payload }) {
      return {
        ...state,
        loading: false,
        data: payload.data.records,
        totalRecords: payload.data.totalRecords,
        error: null,
      };
    },
    [setCustomerOptionsList](state, { payload }) {
      return {
        ...state,
        options: payload.data,
      };
    },
    [setCustomerError](state, { payload }) {
      return {
        ...state,
        data: [],
        loading: false,
        totalPages: defaultState.totalPages,
        error: payload?.message,
      };
    },
    [setCustomerFilters](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
      };
    },
  },
  defaultState,
);
