import React from 'react';
import { ACCESS_TOKEN, REFRESH_TOKEN, TOKEN_TYPE } from '@/shared/helpers/auth.enum';
import { isEmpty } from 'lodash';
import { getAppItem, removeUserData, setAppItem } from '@/shared/helpers';
import { authEndpoints } from '../auth/authApi';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';
import { paths } from '@/shared/helpers/paths';

const axios = require('axios');

const API_BASE_URL = process.env.REACT_APP_ROOT_URL + process.env.REACT_APP_ADMIN_ENDPOINT;
let BASE_URL = process.env.REACT_APP_ROOT_URL;

const tryTokenRefresh = (options) => {
  return axios
    .request({
      headers: {
        'Content-Type': 'application/json',
        refreshToken: getAppItem(REFRESH_TOKEN),
        Authorization: `${TOKEN_TYPE} ${getAppItem(ACCESS_TOKEN)}`,
      },
      url: API_BASE_URL + authEndpoints.REFRESH_TOKEN,
      method: httpMethod.POST,
    })
    .then(async (response) => {
      const {
        status,
        data: { token = '', refreshToken = '' },
      } = response?.data ?? { data: {} };
      if (response.request.status === 200 && status === 200 && token && refreshToken) {
        setAppItem(ACCESS_TOKEN, token);
        setAppItem(REFRESH_TOKEN, refreshToken);
        return sendRequest(options);
      } else {
        return false;
      }
    })
    .catch((error) => {
      removeUserData();
      window.location.href = paths.LOGIN;
      return false;
    });
};

const appendQueryParams = (url, params) => {
  if (!!url && !isEmpty(params)) {
    let sanitizedParams = {};
    Object.keys(params)
      .filter((param) => !['', null, undefined].includes(params[param]))
      .forEach((param) => (sanitizedParams[param] = params[param]));
    return `${url}?${new URLSearchParams(sanitizedParams)}`;
  }
  return url;
};

export function sendRequest(options, endPoint = process.env.REACT_APP_ADMIN_ENDPOINT) {
  let URL = BASE_URL + endPoint;

  const config = {
    headers: options['headers'] ? options['headers'] : { 'Content-Type': 'application/json' },
    url: appendQueryParams(options['fullUrl'] ?? URL + options['url'], options['params']),
    method: options['method'],
    data: options['data'],
  };

  const accessToken = getAppItem(ACCESS_TOKEN);
  if (accessToken) {
    config['headers']['Authorization'] = `${TOKEN_TYPE} ${accessToken}`;
  }

  return axios
    .request(config)
    .then((response) => {
      if (response.request.status === 200 && response.data?.status === 200)
        return { data: response.data, isSuccess: true };
      else if (response.status === 200 && response.headers['content-type'] === 'text/csv') {
        return response.data;
      } else if (options?.fullUrl && response.request.status === 200) {
        return response.data;
      } else throw new Error(response.data?.messages?.[0] ?? 'Something went wrong !');
    })
    .catch(async (error) => {
      const status = error?.response?.status ?? -1;
      if (status === 401 || status === 403) {
        // const retryResponse = await tryTokenRefresh(options);
        // if (retryResponse === false) {
        removeUserData();
        window.location.href = paths.LOGIN;
        // } else if (retryResponse?.data?.status === 200) {
        //   return { data: retryResponse.data, isSuccess: true };
        // }
      } else if (status === 404) {
        // TODO: handle not found error
      } else if (status === 500) {
        // TODO: handle internal server error
      }

      throw error;
    });
}
