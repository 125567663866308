import React from 'react';
import { Col } from 'reactstrap';
import { Field } from '@/shared/components/Field';
import ApplicationDetails from '../ApplicationDetails';
import LearningDetails from '../LearningDetails';
import AssessmentDetails from '../AssessmentDetails';
import AccountCircleOutlineIcon from 'mdi-react/AccountCircleOutlineIcon';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import BriefcaseOutlineIcon from 'mdi-react/BriefcaseOutlineIcon';
import PhoneOutlineIcon from 'mdi-react/PhoneOutlineIcon';
import MapMarkerOutlineIcon from 'mdi-react/MapMarkerOutlineIcon';

const ApplicantsCardInfo = ({ userDetail, state, toggle }) => {
  return (
    <>
      <Col md={4} xl={4} lg={4} className="orgprofile__avatar-no border-bottom pb-4">
        <div className="orgprofile__avatar">
          <img
            className="orgprofile__avatar-img"
            src={
              userDetail?.profilePhoto
                ? userDetail?.profilePhoto
                : 'https://i.stack.imgur.com/l60Hf.png'
            }
            alt="avatar"
          />
        </div>
      </Col>
      <Col md={4} xl={4} lg={4} className="border-bottom pb-4">
        <Field
          IconComponent={<AccountCircleOutlineIcon size={'20'} />}
          title={'First Name'}
          fieldValue={userDetail?.userFName ? userDetail?.userFName : '-'}
        />
        <Field
          IconComponent={<AccountCircleOutlineIcon size={'20'} />}
          title={'Last Name'}
          fieldValue={userDetail?.userLName ? userDetail?.userLName : '-'}
        />
        <Field
          IconComponent={<EmailOutlineIcon size={'20'} />}
          title={'Email'}
          fieldValue={userDetail?.userEmail ? userDetail?.userEmail : '-'}
        />
        <Field
          IconComponent={<BriefcaseOutlineIcon size={'20'} />}
          title={'Designation'}
          fieldValue={userDetail?.designation ? userDetail?.designation : '-'}
        />
      </Col>
      <Col md={4} xl={4} lg={4} className="border-bottom pb-4">
        <Field
          IconComponent={<PhoneOutlineIcon size={'20'} />}
          title={'Mobile Phone'}
          fieldValue={userDetail?.phoneNo ? userDetail?.phoneNo : '-'}
          errorMessage={'Phone Number is required'}
        />
        <Field
          IconComponent={<MapMarkerOutlineIcon size={'20'} />}
          title={'Country'}
          fieldValue={userDetail?.country ? userDetail?.country : '-'}
        />
        {/*{RoleOptions.length > 0 && (*/}
        <Field
          IconComponent={<BriefcaseOutlineIcon size={'20'} />}
          title={'Bio'}
          fieldValue={userDetail?.identityNumber ? userDetail?.identityNumber : '-'}
        />
        {/*)}*/}
        <Field
          IconComponent={<MapMarkerOutlineIcon size={'20'} />}
          title={'City'}
          fieldValue={userDetail?.city ? userDetail?.city : '-'}
        />
      </Col>
      <Col md={12} lg={12}>
        <div className="border-bottom pb-4">
          <ApplicationDetails applicantDetail={state?.applicant} />
        </div>
        <div className="border-bottom pb-4">
          <AssessmentDetails
            assessmentDetail={state?.applicant?.assesmentDetails}
            toggle={toggle}
          />
        </div>
        <LearningDetails learningDetails={state?.applicant?.learningDetails} />
      </Col>
    </>
  );
};

export default ApplicantsCardInfo;
