import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const setCadsAiModuleFetching = createAction('SET_CADS_AI_MODULE_FETCHING');
export const setCadsAiModuleError = createAction('SET_CADS_AI_MODULE_ERROR');
export const setCadsAiModuleFilters = createAction('SET_CADS_AI_MODULE_LIST_FILTER');
export const setCadsAiModuleList = createAction('SET_CADS_AI_MODULE_LIST');
export const clearCadsAiModuleFilters = createAction('CLEAR_CADS_AI_MODULE_FILTERS');
export const updateCadsAiModuleInList = createAction('UPDATE_CADS_AI_MODULE_IN_LIST');
export const removeModuleFromList = createAction('UPDATE_CADS_AI_MODULE_IN_LIST');
export const toggleCheckedCadsAiModule = createAction('TOGGLE_CHECKED_CADS_AI_MODULE');
export const uncheckAllModule = createAction('UNCHECK_CADS_AI_ALL_MODULE');
export const setModuleOptions = createAction('CADS_AI_SET_MODULE_OPTIONS');

export const toggleCheckedItemById = (itemId) => (dispatch) => {
  dispatch(toggleCheckedCadsAiModule(itemId));
};

export const uncheckAllSelectedModule = () => (dispatch) => {
  dispatch(uncheckAllModule());
};

export const getCadsAiModulesList = (filter) => async (dispatch) => {
  const defaultError = `Error occurred getting module list`;
  dispatch(setCadsAiModuleFetching());
  try {
    const { data, isSuccess } = await api.cadsAIModule.getCadsAiModules(filter);
    if (isSuccess) dispatch(setCadsAiModuleList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setCadsAiModuleError(error.message));
    console.error(defaultError, error.message);
  }
};

export const getAllModuleOptions = () => async (dispatch) => {
  const defaultError = `Error occurred getting module options list`;
  try {
    const { data, isSuccess } = await api.cadsAIPrograms.getModuleOptions();
    if (isSuccess) dispatch(setModuleOptions(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    console.error(defaultError, error.message);
  }
};
