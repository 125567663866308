import { handleActions } from 'redux-actions';
import {
  getDefaultContactInfoTypeProps,
  getDefaultFileUploadTypeProps,
  getDefaultMCQMultiSelectTypeProps,
  getDefaultPageProps,
  getDefaultScaleRatingQuestionProps,
  getDefaultMatrixRatingQuestionProps,
} from './util';
import {
  addNewConfigPage,
  addNewConfigPageQuestion,
  deleteConfigPageQuestion,
  setAssessmentConfig,
  setConfigPageQuestionTypeData,
  updateConfigPageDetails,
  updateConfigPageQuestionDetails,
} from './actions';
import { questionTypes } from '../options';

export const defaultState = {
  timeLimit: 1,
  logo: {
    image: [], // arrays of image file (only one image)
    imageBase64: '',
    size: { value: '', label: 'Select logo size' },
    position: { value: '', label: 'Select logo position' },
  },
  pages: [getDefaultPageProps()],
};

function updatePayloadTypeDataByQuestionType(payload) {
  switch (payload.data.questionType.value) {
    case questionTypes.MCQ_MULTI_SELECT.value:
      payload.data.typeData = getDefaultMCQMultiSelectTypeProps();
      break;
    case questionTypes.MCQ_SINGLE_SELECT.value:
      payload.data.typeData = getDefaultMCQMultiSelectTypeProps();
      break; //reusing multiselect
    case questionTypes.DROPDOWN.value:
      payload.data.typeData = getDefaultMCQMultiSelectTypeProps();
      break; //reusing multiselect
    case questionTypes.LIKERT.value:
      payload.data.typeData = getDefaultMCQMultiSelectTypeProps();
      break; //reusing multiselect
    case questionTypes.FILE_UPLOAD.value:
      payload.data.typeData = getDefaultFileUploadTypeProps();
      break;
    case questionTypes.CONTACT_INFO.value:
      payload.data.typeData = getDefaultContactInfoTypeProps();
      break;
    case questionTypes.SCALE_RATING_QUESTION.value:
      payload.data.typeData = getDefaultScaleRatingQuestionProps();
      break;
    case questionTypes.MATRIX_RATING_QUESTION.value:
      payload.data.typeData = getDefaultMatrixRatingQuestionProps();
      break;
    default:
      payload.data.typeData = {};
  }
}

export default handleActions(
  {
    [setAssessmentConfig](state, { payload }) {
      return { ...state, ...payload };
    },
    [addNewConfigPage](state, { payload }) {
      return {
        ...state,
        pages: [...state.pages, payload],
      };
    },
    [updateConfigPageDetails](state, { payload }) {
      return {
        ...state,
        pages: state.pages.map((page) => (page.id !== payload.id ? page : { ...page, ...payload })),
      };
    },
    [setConfigPageQuestionTypeData](state, { payload }) {
      return {
        ...state,
        pages: state.pages.map((page) => {
          if (page.id !== payload.pageId) return page;
          return {
            ...page,
            questions: page.questions.map((question) => {
              if (question.id !== payload.questionId) return question;
              return {
                ...question,
                typeData: payload.typeData,
              };
            }),
          };
        }),
      };
    },
    [addNewConfigPageQuestion](state, { payload }) {
      return {
        ...state,
        pages: state.pages.map((page) => {
          if (page.id !== payload.pageId) return page;
          return {
            ...page,
            questions: [...page.questions, payload],
          };
        }),
      };
    },

    [deleteConfigPageQuestion](state, { payload }) {
      return {
        ...state,
        pages: state.pages.map((page) => {
          if (page.id !== payload.pageId) return page;
          return {
            ...page,
            questions: page.questions.filter((question) => question.id !== payload.questionId),
          };
        }),
      };
    },

    //To update the question details inside a configuration page
    [updateConfigPageQuestionDetails](state, { payload }) {
      return {
        ...state,
        // find page where the question is located
        pages: state.pages.map((page) => {
          // return the page as it is since this is not the one that we are looking to update
          if (page.id !== payload.pageId) return page;
          // we found the page to be updated so now, return the updated page with new/updated questions details
          return {
            ...page,
            // now find the question to be updated inside page
            questions: page.questions.map((question) => {
              // return the question as it is since this is not the one that we are looking to update
              if (question.id !== payload.questionId) return question;

              //check if we are updating question type. If yes, we need to modify type data as well
              // for example if the question type is changed to mcq multi select we need to store data regarding answer, points, etc...
              // like wise if the question type is changed to dropdown we need to store data regarding what are the dropdown options
              if (payload.data.questionType) {
                updatePayloadTypeDataByQuestionType(payload);
              }
              return { ...question, ...payload.data };
            }),
          };
        }),
      };
    },
  },
  defaultState,
);
