import React from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import { useSponsorUIContext } from '../SponsorsUIProvider';
import SponsorsTable from './SponsorsTable';
import api from '@/utils/apiClient';
import { shallowEqual, useSelector } from 'react-redux';

const SponsorsTableWrapper = () => {
  const sponsorUIEvent = useSponsorUIContext();

  const { filters } = useSelector((state) => state.cadsAISponsor, shallowEqual);

  const downloadCSV = async () => {
    const csvData = await api.cadsAISponsors.getSponsorListCSV(filters);
    require('js-file-download')(
      csvData,
      `CADS_AI_Sponsor_List__${new Date().toLocaleString().replace(/[\/:,\s]/gi, '_')}.csv`,
    );
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="products-list">
          <div className="card__title">
            <ButtonToolbar className="products-list__btn-toolbar-top">
              <Button color="primary" onClick={sponsorUIEvent.openNewSponsorDialog} outline>
                Add New
              </Button>
              <Button color="success" onClick={downloadCSV} outline>
                Export Sponsors
              </Button>
            </ButtonToolbar>
          </div>
          <SponsorsTable />
        </CardBody>
      </Card>
    </Col>
  );
};

export default SponsorsTableWrapper;
