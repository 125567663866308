import React, { useRef } from 'react';
import { Button } from 'reactstrap';
import Select from 'react-select';
import TableHead from '@material-ui/core/TableHead';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setSkillFilters } from '../../redux/actions';
import { getDomainOptionsList } from '@/containers/Admin/SkillBank/Domains/redux/actions';
import { getSubCategoriesOptionsList } from '@/containers/Admin/SkillBank/SubCategories/redux/actions';

export function SkillTableSearchBar() {
  const dispatch = useDispatch();
  const totalSkillCount = useSelector((state) => state.skills?.data?.length, shallowEqual);
  const { options: domainOpts } = useSelector((state) => state.domains, shallowEqual);
  const { options: subcategoryOpts } = useSelector((state) => state.subCategories, shallowEqual);
  const [isSearched, setIsSearched] = React.useState(false);
  const searchRef = useRef(null);

  /*Default Options*/
  const defaultDomainsOptions = [{ value: null, label: 'All Domains' }];
  const defaultSubcategoryOptions = [{ value: null, label: 'All Subcategories' }];

  const [domainOptions, setDomainOptions] = React.useState(defaultDomainsOptions);
  const [selectedDomain, setSelectedDomain] = React.useState(defaultDomainsOptions[0]);
  const [subcategoryOptions, setSubcategoryOptions] = React.useState(defaultSubcategoryOptions);
  const [selectedSubcategory, setSelectedSubcategory] = React.useState(
    defaultSubcategoryOptions[0],
  );

  React.useEffect(() => {
    dispatch(getDomainOptionsList());
    dispatch(getSubCategoriesOptionsList());
  }, []);

  React.useEffect(() => {
    setDomainOptions([
      defaultDomainsOptions[0],
      ...(domainOpts?.map((domain) => ({ value: domain.id, label: domain.title })) ?? []),
    ]);
  }, [domainOpts]);

  React.useEffect(() => {
    setSubcategoryOptions([
      defaultSubcategoryOptions[0],
      ...(subcategoryOpts?.map((subcategory) => ({
        value: subcategory.id,
        label: subcategory.title,
      })) ?? []),
    ]);
  }, [subcategoryOpts]);

  const handleSearch = () => {
    setIsSearched(true);
    dispatch(
      setSkillFilters({
        search: searchRef?.current?.value,
        pageNumber: 1,
        domainId: selectedDomain.value,
        subcategoryId: selectedSubcategory.value,
      }),
    );
  };

  const handleClear = () => {
    if (searchRef?.current?.value) searchRef.current.value = '';
    setIsSearched(false);
    dispatch(setSkillFilters({ search: '' }));
  };

  return (
    <TableHead>
      <div className="table__search">
        <div className="table__search_item">
          <b>Skill Name</b>
          <div className="form">
            <div className="form__form-group">
              <div className="form__form-group-field">
                <div className="form__form-group-icon" style={{ height: 37 }}>
                  <MagnifyIcon />
                </div>
                <input
                  ref={searchRef}
                  className="search-bar-search-text"
                  name="searchText"
                  type="text"
                  placeholder="Search by Skill Name"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table__search_item">
          <b>Subcategory</b>
          <div style={{ minWidth: 200 }}>
            <Select
              name="subcategory"
              value={selectedSubcategory}
              onChange={setSelectedSubcategory}
              autosize={true}
              options={subcategoryOptions}
              clearable={false}
              className="react-select"
              placeholder={'Select Subcategory'}
              classNamePrefix="react-select"
            />
          </div>
        </div>
        <div className="table__search_item">
          <b>Domain</b>
          <div style={{ minWidth: 200 }}>
            <Select
              name="domains"
              value={selectedDomain}
              onChange={setSelectedDomain}
              autosize={true}
              options={domainOptions}
              clearable={false}
              className="react-select"
              placeholder={'Select Domain'}
              classNamePrefix="react-select"
            />
          </div>
        </div>
        <div className="table__search_item mt-3">
          <Button color="primary" onClick={handleSearch}>
            {' '}
            Search{' '}
          </Button>
        </div>
        <div className="table__search_item mt-3">
          <Button color="secondary" onClick={handleClear} outline>
            Clear
          </Button>
        </div>
      </div>

      {isSearched && (
        <div className="ml-1">
          <span>Found {totalSkillCount} matches</span>
        </div>
      )}
    </TableHead>
  );
}
