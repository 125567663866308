import { createAction } from 'redux-actions';
import { uncheckAllItem as uncheckAllItemAssign } from '@/containers/Control-Panel/Assign/redux/actions';
import { uncheckAllItem as uncheckAllItemAssigned } from '@/containers/Control-Panel/Assigned/redux/actions';

export const setAssignProduct = createAction('SET_CP_ASSIGN_PRODUCT');
export const setAssignOrganizationId = createAction('SET_ASSIGN_ORG_ID');
export const setAssignTenantId = createAction('SET_ASSIGN_TENANT_ID');

export const setSelectedAssignProduct = (product) => (dispatch) => {
  dispatch(setAssignProduct(product));
  dispatch(uncheckAllItemAssign());
  dispatch(uncheckAllItemAssigned());
};

export const setSelectedOrganizationId = (orgId) => (dispatch) => {
  dispatch(setAssignOrganizationId(orgId));
  dispatch(uncheckAllItemAssign());
  dispatch(uncheckAllItemAssigned());
};
