import React, { Fragment } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import AssignTableColumns, {
  CohortColumns,
  OrganizationColumns,
  UsersColumns,
} from './AssignTableColumns';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { defaultPaginationProps } from '@/shared/helpers';
import * as Constants from '@/shared/helpers/constants';
import AssignTableRow from './AssignTableRow';
import { getCPUsersList, setUserFilters } from '@/containers/Control-Panel/redux/user/actions';
import {
  getCPCohortsList,
  setCohortFilters,
} from '@/containers/Control-Panel/redux/cohort/actions';
import {
  getCPOrganizationsList,
  setOrganizationFilters,
} from '@/containers/Control-Panel/redux/organization/actions';

const AssignTable = () => {
  const dispatch = useDispatch();
  const { assignedProduct, assignedOrgId, tenantId } = useSelector(
    (state) => state.controlPanel,
    shallowEqual,
  );
  const { assignUsers, ...userExtras } = useSelector((state) => state.cpUsers, shallowEqual);
  const { assignCohorts, ...cohortExtras } = useSelector((state) => state.cpCohorts, shallowEqual);
  const { assignOrgs, ...orgExtras } = useSelector((state) => state.cpOrgs, shallowEqual);
  const { activeTab, isFromModule, isFromLearningPath, isFromTrainingProvider } = useSelector(
    (state) => state.assignPage,
    shallowEqual,
  );
  const { checkedModule } = useSelector((state) => state.cpModules, shallowEqual);
  const { checkedLearningPath } = useSelector((state) => state.cpLearningPaths, shallowEqual);
  const { checkedTrainingProvider } = useSelector(
    (state) => state.cpTrainingProviders,
    shallowEqual,
  );

  const currentRecordsData = React.useMemo(() => {
    const { USERS, COHORT, ORGANIZATION } = Constants;
    switch (activeTab) {
      case USERS:
        return {
          list: assignUsers,
          ...userExtras,
          colLength: UsersColumns.length,
          setFilter: setUserFilters,
        };
      case COHORT:
        return {
          list: assignCohorts,
          ...cohortExtras,
          colLength: CohortColumns.length,
          setFilter: setCohortFilters,
        };
      case ORGANIZATION:
        return {
          list: assignOrgs,
          ...orgExtras,
          colLength: OrganizationColumns.length,
          setFilter: setOrganizationFilters,
        };
      default:
        return 0;
    }
  }, [activeTab, assignUsers, userExtras, assignCohorts, cohortExtras, assignOrgs, orgExtras]);

  const { setFilter, filters, searchAssign } = currentRecordsData;

  React.useEffect(() => {
    if (assignedOrgId && assignedProduct) fetchData(activeTab);
  }, [
    activeTab,
    filters,
    searchAssign,
    isFromModule,
    isFromLearningPath,
    isFromTrainingProvider,
    assignedOrgId,
    assignedProduct,
  ]);

  const fetchData = (tab) => {
    const filter = {
      ...filters,
      ...searchAssign,
      assigned: false,
      feature: filters?.feature ?? assignedProduct?.id,
      orgId: assignedOrgId,
    };

    // check if rendering from module or learning path to add extra filters
    if (isFromModule || isFromLearningPath || isFromTrainingProvider) {
      if (isFromModule) filter.moduleId = checkedModule.id;
      if (isFromLearningPath) filter.learningPathId = checkedLearningPath.id;
      if (isFromTrainingProvider) filter.trainingProviderId = checkedTrainingProvider.id;
      filter.tenantId = tenantId;
    }

    if (tab === Constants.USERS) {
      dispatch(getCPUsersList(filter));
    } else if (tab === Constants.COHORT) {
      dispatch(getCPCohortsList(filter));
    } else if (tab === Constants.ORGANIZATION) {
      dispatch(getCPOrganizationsList(filter));
    }
  };

  const handleRequestSort = (e, sortBy) => {
    dispatch(
      setFilter({ ...filters, sortBy, isSortOrderDescending: !filters.isSortOrderDescending }),
    );
  };

  const handleChangePage = (event, currentPage) => {
    dispatch(setFilter({ ...filters, pageNumber: currentPage + 1 }));
  };

  const handleChangeRowsPerPage = (e) => {
    dispatch(setFilter({ ...filters, pageNumber: 1, pageSize: e.target.value }));
  };

  const {
    filters: { pageNumber: page, pageSize: rowsPerPage, sortBy, isSortOrderDescending },
    totalRecords,
    colLength,
    error,
    loading,
  } = currentRecordsData;
  const emptyRows =
    page === Math.ceil(totalRecords / rowsPerPage)
      ? rowsPerPage - currentRecordsData.list.length
      : 0;

  const renderTableStatus = () =>
    (loading || !!error || totalRecords === 0) && (
      <TableRow>
        <TableCell colSpan={colLength}>
          {loading && (
            <div className={'text-center overflow-hidden'}>
              <div className="panel__refresh position-relative" style={{ height: 75 }}>
                <LoadingIcon />
              </div>
            </div>
          )}
          {totalRecords === 0 && !error && !loading && (
            <div className={'text-center'}>
              <p className={'m-5'}>No records found with applied filter.</p>
            </div>
          )}
          {(!!error || loading) && (
            <div className={'text-center'}>
              <p className={!!error ? 'm-5 error' : ''}>{error || 'Loading...'}</p>
            </div>
          )}
        </TableCell>
      </TableRow>
    );

  return (
    <Fragment>
      <div className="material-table__wrap">
        <Table className="material-table">
          <TableBody>
            <AssignTableColumns
              order={isSortOrderDescending ? 'desc' : 'asc'}
              orderBy={sortBy}
              onRequestSort={handleRequestSort}
              rowCount={rowsPerPage}
            />

            {renderTableStatus()}
            <AssignTableRow data={currentRecordsData.list} />

            {/*Rendering empty rows for better UI*/}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={colLength} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        component={defaultPaginationProps.component}
        backIconButtonProps={defaultPaginationProps.backIconButton}
        nextIconButtonProps={defaultPaginationProps.nextIconButton}
        rowsPerPageOptions={defaultPaginationProps.rowsPerPage}
        className={defaultPaginationProps.className}
        dir={defaultPaginationProps.dir}
      />
    </Fragment>
  );
};

export default AssignTable;
