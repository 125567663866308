import React from 'react';
import { Col } from 'reactstrap';
import { Field } from '@/shared/components/Field';

const OtherDetails = ({ organization }) => {
  console.log(organization);
  return (
    <>
      <div className="pt-5">
        <h4 className="pl-2">Other Details</h4>
        <div className="row pt-2">
          <Col md={6} xl={4} lg={6}>
            <Field
              title={'Short Description'}
              iconDisable={true}
              fieldValue={organization?.shortDescription ?? '-'}
            />
            <Field title={'Story'} iconDisable={true} fieldValue={organization?.story ?? '-'} />
          </Col>
        </div>
      </div>
    </>
  );
};

export default OtherDetails;
