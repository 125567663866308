import React, { useState } from 'react';
import { Col, Card, Row, CardBody } from 'reactstrap';
import StepOne from './steps/StepOne';
import StepTwo from './steps/StepTwo';
import { useDispatch } from 'react-redux';
import { clearCheckedLearningPath } from '@/containers/Control-Panel/LearningPath/redux/actions';
import {
  setIsFromLearningPath,
  uncheckAllItem,
} from '@/containers/Control-Panel/Assign/redux/actions';

const AssignLearningPathWizard = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    // initialization
    dispatch(setIsFromLearningPath(true));
    // destruction
    return () => {
      dispatch(setIsFromLearningPath(false));
      dispatch(clearCheckedLearningPath());
      dispatch(uncheckAllItem());
    };
  }, []);

  const [page, setPage] = useState(1);

  const nextPage = () => {
    setPage(page + 1);
  };

  const previousPage = () => {
    setPage(page - 1);
  };

  return (
    <Row>
      <Col md={12} lg={12}>
        <Card className="pb-0">
          <CardBody className="wizard">
            <div className="wizard__steps">
              <div className={`wizard__step${page === 1 ? ' wizard__step--active' : ''}`}>
                <p>Learning Paths</p>
              </div>
              <div className={`wizard__step${page === 2 ? ' wizard__step--active' : ''}`}>
                <p>Assign Users,Cohort OR Organizations</p>
              </div>
            </div>
            <div className="">
              {page === 1 && <StepOne onSubmit={nextPage} />}
              {page === 2 && <StepTwo previousPage={previousPage} />}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default AssignLearningPathWizard;
