import classnames from 'classnames';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Card, Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import BehavioralTab from './BehavioralTab';
import CompetencyTab from './CompetencyTab';

const OverviewTabs = ({ behavioralAnalysis, competenciesAnalysis, id }) => {
  const [activeTab, setActiveTab] = useState('behavioral');

  useEffect(() => {
    setActiveTab('behavioral');
  }, []);

  const overviewTabs = [
    {
      name: 'Behavioral Implications',
      value: 'behavioral',
    },
    {
      name: 'Competency Analysis',
      value: 'competency',
    },
  ];

  const toggle = (tab) => {
    if (activeTab !== tab) {
      let update = { activeTab: tab };
      if (tab === 'behavioral') {
        update.isProfileRefresh = true;
      } else {
        update.isSkillsRefresh = true;
      }
      setActiveTab(update.activeTab);
    }
  };

  return (
    <Row className={'mt-3'}>
      <Col md={12} lg={12} xl={12}>
        <Card>
          <div className="profile__card tabs tabs--bordered-bottom">
            <div className="tabs__wrap">
              <Nav tabs className={'p-0'} style={{ border: '0px' }}>
                {overviewTabs.map((profileTab, index) => {
                  return (
                    <NavItem key={index}>
                      <NavLink
                        className={classnames({
                          active: activeTab === profileTab.value,
                        })}
                        onClick={() => {
                          toggle(profileTab.value);
                        }}
                      >
                        {profileTab.name}
                      </NavLink>
                    </NavItem>
                  );
                })}
              </Nav>
              {activeTab === 'behavioral' ? (
                <BehavioralTab id={id} behavioralAnalysis={behavioralAnalysis} />
              ) : activeTab === 'competency' ? (
                <CompetencyTab id={id} competenciesAnalysis={competenciesAnalysis} />
              ) : null}
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default OverviewTabs;
