import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, ButtonToolbar, Col, Row } from 'reactstrap';
import { emptyStringPattern } from '@/shared/helpers';
import { FormField } from '@/shared/components/form/hook/FormField';
import { SelectField } from '@/shared/components/form/Select';
import { ErrorMessage } from '@hookform/error-message';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableCell } from '@material-ui/core';
import DeleteIcon from 'mdi-react/DeleteIcon';
import Table from '@material-ui/core/Table';
import EyeIcon from "mdi-react/EyeIcon";
import EyeOffIcon from "mdi-react/EyeOffIcon";

const FieldType = {
  Text: {value: 'text', label: 'Text'},
  Radio: {value: 'radio', label: 'Radio'},
  Dropdown: {value: 'dropdown', label: 'Dropdown'}
}

const renderFieldOptions = (fields, setFields, selectedFieldType, formProps, optionList, setOptionList) => {
  const {control, watch, setValue, getValues, formState: {errors}} = formProps;

  //no need to render options if field type is text
  if (!selectedFieldType || selectedFieldType.value === FieldType.Text.value) {
    return null;
  }


  const addNewOption = () => {
    setValue(`option${optionList.length + 3}`, '');
    setOptionList([...optionList, {name: `option${optionList.length + 3}`, label: `Option ${optionList.length + 3}`, value: ''}]);
  }

  const removeOption = (optionIdx) => {
    const options = [];
    for (let i = 0; i < optionList.length; i++) {
      if (i < optionIdx) {
        options.push(optionList[i]);
      } else if (i > optionIdx) {
        setValue(`option${i - 1 + 3}`, getValues(`option${i + 3}`));
        options.push({
          name: `option${i - 1 + 3}`,
          label: `Option ${i - 1 + 3}`,
          value: getValues(`option${i + 3}`)
        })
      }
    }
    setOptionList(options);
  }

  return (
      <form className={`form w-100 ds-option-form`} onSubmit={null}>
        <Row>
          <Col md={6}>
            <div className="form__form-group">
              <span className="form__form-group-label">Option 1</span>
              <div className="form__form-group-field">
                <FormField
                    name="option1"
                    control={control}
                    component="input"
                    defaultValue=""
                    rules={{
                      required: 'This is required field',
                      pattern: emptyStringPattern,
                    }}
                    errors={errors}
                    isAboveError
                    placeholder="Enter Option 1"
                />
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="form__form-group">
              <span className="form__form-group-label">Option 2</span>
              <div className="form__form-group-field">
                <FormField
                    name="option2"
                    control={control}
                    component="input"
                    defaultValue=""
                    rules={{
                      required: 'This is required field',
                      pattern: emptyStringPattern,
                    }}
                    errors={errors}
                    isAboveError
                    placeholder="Enter Option 2"
                />
              </div>
            </div>
          </Col>
          {optionList.map((option, optionIdx) => (
              <Col md={6}>
                <div className="form__form-group">
                  <span className="form__form-group-label">{option.label}</span>
                  <div className="form__form-group-field">
                    <FormField
                        name={option.name}
                        control={control}
                        component="input"
                        defaultValue=""
                        rules={{
                          required: 'This is required field',
                          pattern: emptyStringPattern,
                        }}
                        errors={errors}
                        isAboveError
                        placeholder={`Enter ${option.label}`}
                    />
                    <DeleteIcon
                        style={{color: '#ff4861', selfAlign: 'center'}}
                        role="button"
                        onClick={() => removeOption(optionIdx)}
                    />
                  </div>
                </div>
              </Col>
          ))}
        </Row>
        <Row>
          <Col>
            <ButtonToolbar className="form__button-toolbar modal__footer w-100 justify-content-around">
              <Button disabled={false} color="success" outline style={{marginLeft: 45}} type="button" onClick={addNewOption}>
                Add new option
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>
      </form>
  );
}

const renderDefaultFields = (defaultFields, setDefaultFields) => {
  return (
      <>
        <h4 className="mt-2">Default Fields</h4>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{minWidth: 100}}>Field Title</TableCell>
              <TableCell style={{minWidth: 100}}>Field Type</TableCell>
              <TableCell style={{width: 190}}>Visibility</TableCell>
            </TableRow>
          </TableHead>
          {defaultFields.map(field => (
              <TableRow>
                <TableCell>{field.fieldTitle}</TableCell>
                <TableCell>{field.type.value}</TableCell>
                <TableCell>
                  {field.isVisible ?
                      <p role="button"
                         onClick={() => setDefaultFields(defaultFields.map(df => df.id === field.id ? ({...field, isVisible: false}) : df))}
                      >
                        <EyeIcon style={{color: '#70bbfd'}}/> Visible
                      </p>
                      :
                      <p role="button"
                         onClick={() => setDefaultFields(defaultFields.map(df => df.id === field.id ? ({...field, isVisible: true}) : df))}
                      >
                        <EyeOffIcon style={{color: '#ff4861'}}/> Hidden
                      </p>
                  }
                </TableCell>
              </TableRow>
          ))}
        </Table>
      </>
  );
}

const renderAddedFields = (fields, removeField) => {
  if (!fields.length) {
    return null;
  }
  return (
      <>
        <h4>New Fields</h4>
        <Table className="program-from-field-options">
          <TableHead>
            <TableRow>
              <TableCell style={{minWidth: 100}}>Field Title</TableCell>
              <TableCell style={{minWidth: 100}}>Field Type</TableCell>
              <TableCell style={{minWidth: 70}}>Option(s)</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          {fields.map(field => (
              <TableRow>
                <TableCell>{field.fieldTitle}</TableCell>
                <TableCell>{field.type}</TableCell>
                <TableCell>{field.type === FieldType.Text.value ? '-' : field.options.map(option => option.value).join(', ')}</TableCell>
                <TableCell>
                  <DeleteIcon
                      style={{color: '#ff4861'}}
                      role="button"
                      onClick={() => removeField(field.id)}
                  />
                </TableCell>
              </TableRow>
          ))}
        </Table>
      </>
  );
}

const ProgramFieldForm = ({formProps, defaultFields, setDefaultFields, fields, setFields}) => {
  const {
    handleSubmit,
    control,
    getValues,
    formState: {errors},
    watch,
    setValue,
    trigger
  } = formProps;

  const fieldType = watch('fieldType');

  const [optionList, setOptionList] = React.useState([]);

  const getOptions = (data) => {
    const options = []
    if (![FieldType.Radio.value, FieldType.Dropdown.value].includes(data.fieldType?.value)) {
      return options;
    }
    const optionFields = Object.keys(data).filter(field => field.startsWith('option') && data[field]?.trim());
    for (let i = 1; i <= optionFields.length; i++) {
      options.push({order: i, value: data[`option${i}`]})
      setValue(`option${i}`, '');
      setOptionList([])
    }
    return options;
  }

  const addField = async () => {
    const isValid = await trigger();
    if (!isValid) {
      return;
    }
    const data = getValues();
    let field = {
      id: Date.now(),
      hidden: false,
      fieldTitle: data.fieldTitle,
      type: data.fieldType?.value,
      options: getOptions(data)
    }
    setValue('fieldTitle', '');
    setFields([...fields, field]);
  }

  const removeField = id => {
    setFields(fields.filter(field => field.id !== id));
  }


  return (
      <form className={`form`} onSubmit={handleSubmit(data => {
      })}>
        <ErrorMessage
            errors={errors}
            name="formFields"
            render={({message}) => <span className="form__form-group-error lp-error">{message}</span>}
        />
        <Row>
          <Col>
            <div className="form__form-group">
              <span className="form__form-group-label">Field Title</span>
              <div className="form__form-group-field">
                <FormField
                    name="fieldTitle"
                    control={control}
                    component="input"
                    rules={{
                      required: 'This is required field',
                      pattern: emptyStringPattern,
                    }}
                    errors={errors}
                    isAboveError
                    defaultValue=""
                    placeholder="Enter Field Title"
                />
              </div>
            </div>
          </Col>
          <Col>
            <div className="form__form-group">
              <span className="form__form-group-label">Field Type</span>
              <div className="form__form-group-field">
                <FormField
                    control={control}
                    name="fieldType"
                    options={[FieldType.Text, FieldType.Radio, FieldType.Dropdown]}
                    component={SelectField}
                    placeholder="Select Field Type"
                    defaultValue={{value: 'text', label: 'Text'}}
                />
              </div>
            </div>
          </Col>
        </Row>
        {renderFieldOptions(fields, setFields, fieldType, formProps, optionList, setOptionList)}
        <Row>
          <Col>
            <ButtonToolbar className="form__button-toolbar modal__footer w-100">
              <Button disabled={false} onClick={addField} color="success" outline type="button">
                Add Field
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>
        {renderAddedFields(fields, removeField)}
        {renderDefaultFields(defaultFields, setDefaultFields)}
      </form>
  );
};


const StepFive = ({onSubmit, page, data, previousPage, isEdit}) => {

  const [defaultFields, setDefaultFields] = React.useState([
    {id: 'f_name', fieldTitle: 'First Name', type: FieldType.Text, isVisible: false},
    {id: 'l_name', fieldTitle: 'Last Name', type: FieldType.Text, isVisible: false},
    {id: 'h_qualification', fieldTitle: 'Highest Qualification', type: FieldType.Dropdown, isVisible: false},
    {id: 'university', fieldTitle: 'University', type: FieldType.Dropdown, isVisible: false},
    {id: 'ic_number', fieldTitle: 'IC Number', type: FieldType.Text, isVisible: false},
    {id: 'm_number', fieldTitle: 'Mobile Number', type: FieldType.Text, isVisible: false},
    {id: 'y_of_w_exp', fieldTitle: 'Year of work experience', type: FieldType.Dropdown, isVisible: false},
    {id: 'h_hear_abt_prog', fieldTitle: 'How did you hear about program?', type: FieldType.Dropdown, isVisible: false},
    {id: 'bumiputera_status', fieldTitle: 'Bumiputera Status', type: FieldType.Radio, isVisible: false},
    {id: 'age_28_or_below', fieldTitle: 'Are you aged 28 or below?', type: FieldType.Radio, isVisible: false},
  ]);

  const [fields, setFields] = React.useState([]);

  React.useEffect(() => {
    if (data?.programFields) {
      // set default fields
      const defaultFields = data.programFields?.defaultFields ?? [];
      if (defaultFields.length === 10) {
        setDefaultFields(defaultFields);
      }

      // set custom fields
      setFields(data.programFields?.fields ?? [])
    }
  }, [data?.programFields])


  const formProps = useForm({reValidateMode: 'onChange'});

  const submitData = () => {
    onSubmit({programFields: {fields, defaultFields}});
  }

  return (
      <div className="modal__body add-edit-program-modal">
        <ProgramFieldForm
            formProps={formProps}
            defaultFields={defaultFields}
            setDefaultFields={setDefaultFields}
            fields={fields}
            setFields={setFields}
        />

        <ButtonToolbar className="form__button-toolbar modal__footer">
          <Button type="button" className="previous" onClick={() => previousPage()}>
            Back
          </Button>
          <Button
              disabled={false}
              color="primary"
              type="button"
              onClick={submitData}
          >
            Next
          </Button>
        </ButtonToolbar>
      </div>
  );
};

export default StepFive;
