import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { shallowEqual, useSelector } from 'react-redux';
import { LearningPathPage } from '@/containers/Control-Panel/LearningPath/components/LearningPathPage';

const StepOne = ({ onSubmit }) => {
  const { checkedLearningPath } = useSelector((state) => state.cpLearningPaths, shallowEqual);
  return (
    <div className="step-one">
      {/*LearningPath Table*/}
      <br />

      <LearningPathPage isForAssignment={true} />

      <br />

      <ButtonToolbar className="form__button-toolbar wizard__toolbar">
        <Button color="primary" disabled className="previous">
          Back
        </Button>
        <Button
          color="primary"
          onClick={onSubmit}
          className="next"
          disabled={checkedLearningPath.id === -1}
        >
          Next
        </Button>
      </ButtonToolbar>
    </div>
  );
};

export default StepOne;
