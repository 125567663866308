import { sendRequest } from '../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const spamRequestBaseUrl =
  process.env.REACT_APP_CADS_AI_API + process.env.REACT_APP_BASE_INFO_ENDPOINT;

export const spamRequestEndpoints = {
  GET_ALL_SPAM_REQUESTS: '/',
  UPDATE_SPAM_REQUESTS: '/',
  GET_SPAM_REQUEST_BY_ID: '/',
};

const getSpamRequestList = (filter) => {
    return getMockSpamRequestList();
    return sendRequest({
      fullUrl: spamRequestEndpoints.GET_ALL_SPAM_REQUESTS,
      method: httpMethod.GET,
      params: filter,
    });
  };

  const updateSpamRequest = (id) => {
    return updateMockSpamRequest();
    return sendRequest({
      fullUrl: spamRequestEndpoints.UPDATE_SPAM_REQUESTS,
      method: httpMethod.DELETE,
      params: id
    });
  };

  const getSpamRequestById = (id) => {
    return getMockSpamRequestById();
    return sendRequest({
      fullUrl: spamRequestEndpoints.GET_SPAM_REQUEST_BY_ID,
      method: httpMethod.GET,
      params: { id: id },
    });
  };

  const getMockSpamRequestList = () => {
    return new Promise((resolve, reject) => {
      setTimeout(
        () =>
          resolve({
            isSuccess: true,
            data: {
              status: 200,
              messages: [],
              data: {
                totalRecords: 3,
                records: [
                  {
                    id: 1,
                    reportedEmail: 'tony@test.com',
                    reportedDate: '2022-04-13T16:14:20.753Z',
                    type: 'Question',
                    details: 'Mock Content of the spam 1'
                  },
                  {
                    id: 2,
                    reportedEmail: 'tony2@test.com',
                    reportedDate: '2022-06-23T16:14:20.753Z',
                    type: 'Question',
                    details: 'Mock Content of the spam 2'
                  },
                  {
                    id: 3,
                    reportedEmail: 'tony3@test.com',
                    reportedDate: '2022-08-29T16:14:20.753Z',
                    type: 'Answer',
                    details: 'Mock Content of the spam 3'
                  },
                ],
              },
            },
          }),
        500,
      );
    });
  };

  const getMockSpamRequestById = () => {
    return new Promise((resolve, reject) => {
      setTimeout(
        () =>
          resolve({
            isSuccess: true,
            data: {
              status: 200,
              messages: [],
              data:{
                id: 1,
                reportedEmail: 'tony@test.com',
                reportedDate: '2022-04-13T16:14:20.753Z',
                type: 'Question',
                details: 'Mock Content of the spam 1'
              },
            },
          }),
        500,
      );
    });
  };

  const updateMockSpamRequest = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: ['Spam Request updated successfully'],
          },
        });
      }, 500);
    });
  };

  export default {
      getSpamRequestList,
      updateSpamRequest,
      getSpamRequestById,
  }