import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loading from '@/shared/components/Loading';
import { paths } from '@/shared/helpers/paths';
import { AnnouncementsPage } from './components/AnnouncementsPage';

export const cadsAiAnnouncementsBaseUrl = paths.CADS_AI.HOME.ANNOUNCEMENT;

const Announcements = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path={cadsAiAnnouncementsBaseUrl} component={AnnouncementsPage} />
      </Switch>
    </Suspense>
  );
};

export default Announcements;
