import React, { createContext, useContext } from 'react';

const BaselineThresholdUIContext = createContext();

export function useBaselineThresholdUIContext() {
  return useContext(BaselineThresholdUIContext);
}

export function BaselineThresholdUIProvider({ baselineThresholdUIEvents, children }) {
  const value = {
    openBaselineThresholdConfigDialog: baselineThresholdUIEvents.openBaselineThresholdConfigDialog,
  };

  return (
    <BaselineThresholdUIContext.Provider value={value}>
      {children}
    </BaselineThresholdUIContext.Provider>
  );
}
