import { sendRequest } from '../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const USER_BASE_URL = process.env.REACT_APP_CADS_AI_API + '/user';
const ASSESSMENT_BASE_URL = process.env.REACT_APP_CADS_AI_API + '/assessment';

const cadsAIUserEndpoint = {
  GET_ALL_USERS: `${USER_BASE_URL}/getAllUsers`,
  GET_ALL_USERS_CSV: `${USER_BASE_URL}/exportAllUsers`,
  GET_USER_BY_ID: `${USER_BASE_URL}/getUserDetailsByIdAdmin`,
  GET_USER_ASSESSMENT_LIST: `${ASSESSMENT_BASE_URL}/getUserAssessmentList`,
  GET_USER_COMPLETED_ASSESSMENT_LIST: `${ASSESSMENT_BASE_URL}/getUserCompletedAssessmentList`,
  EXPORT_USER_RESUME_BY_ID: `${USER_BASE_URL}/exportUserResumeById`,
  GET_USER_SMART_SKILLS_RESULT: `${ASSESSMENT_BASE_URL}/getUserSmartSkillsResult`,
};

const getCadsAIUsersList = (filter) => {
  return sendRequest({
    fullUrl: cadsAIUserEndpoint.GET_ALL_USERS,
    method: httpMethod.GET,
    params: filter,
  });
};

const getCadsAIUsersById = (id) => {
  return sendRequest({
    fullUrl: cadsAIUserEndpoint.GET_USER_BY_ID,
    method: httpMethod.GET,
    params: { id },
  });
};

const getCadsAIUsersListCSV = (filter) => {
  return sendRequest({
    fullUrl: cadsAIUserEndpoint.GET_ALL_USERS_CSV,
    method: httpMethod.GET,
    params: filter,
  });
};

const getUserSmartSkillsResult = (id) => {
  return sendRequest({
    fullUrl: cadsAIUserEndpoint.GET_USER_SMART_SKILLS_RESULT,
    method: httpMethod.GET,
    params: { id: id },
  });
};

const getCadsAIExportResume = (id) => {
  return sendRequest({
    fullUrl: cadsAIUserEndpoint.EXPORT_USER_RESUME_BY_ID,
    method: httpMethod.GET,
    params: { id: id },
  });
};

const getUsersAssessmentList = (id) => {
  return sendRequest({
    fullUrl: cadsAIUserEndpoint.GET_USER_ASSESSMENT_LIST,
    method: httpMethod.GET,
    params: { userId: id },
  });
};

const getUsersCompletedAssessmentList = (id) => {
  return sendRequest({
    fullUrl: cadsAIUserEndpoint.GET_USER_COMPLETED_ASSESSMENT_LIST,
    method: httpMethod.GET,
    params: { userId: id },
  });
};

export default {
  getCadsAIUsersList,
  getCadsAIUsersListCSV,
  getCadsAIUsersById,
  getUsersAssessmentList,
  getUsersCompletedAssessmentList,
  getCadsAIExportResume,
  getUserSmartSkillsResult,
};
