import React, { useState, useEffect } from 'react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
} from 'recharts';
import { Button } from 'reactstrap';
import api from '@/utils/apiClient';
import Select from 'react-select';

const DataStarChart = ({
  programTypeId,
  searchProgram,
  status,
  searchEvaluation,
  programs,
  evaluationOpts,
  handleChange,
  setSearchEvaluation,
  setSearchProgram,
  setStatus,
  setDataStarAnalytics,
  dataStarAnalytics,
  values,
  searchAssessment,
}) => {
  const [isSearched, setIsSearched] = useState(false);
  const [assessmentOpts, setAssessmentOpts] = useState([]);
  const today = new Date();
  const month = today.getMonth() > 9 ? today.getMonth() + 1 : '0' + (today.getMonth() + 1);
  const year = today.getFullYear();
  const totalDaysInMonth = new Date(year, month, 0).getDate();
  const [fromDate, setFromDate] = useState(`${year}-${month}-01`);
  const [toDate, setToDate] = useState(`${year}-${month}-${totalDaysInMonth}`);

  const sortedDataStarAnalytics = dataStarAnalytics?.sort((a, b) => {
    return new Date(a.date) - new Date(b.date);
  });

  useEffect(() => {
    getDataStarAnalytics({
      programTypeId: programTypeId,
      programId: searchProgram?.value,
      startDate: fromDate,
      endDate: toDate,
      status: status?.value,
      evaluationId: searchEvaluation?.value,
    });
  }, [programTypeId, searchProgram?.value, status?.value, searchEvaluation?.value]);

  const getDataStarAnalytics = async (filters) => {
    try {
      const { data: res, isSuccess } = await api.cadsAIDSDashboard.getProgramDailyCountGraph(
        filters,
      );
      if (isSuccess) {
        res.data.forEach(function (item) {
          item.date = item.date.slice(0, 10);
        });
        setDataStarAnalytics(res?.data);
      } else {
        throw new Error('error while fetching datastar graph data');
      }
    } catch (error) {
      console.error('Error inside dashboard datastar graph', error.message);
    }
  };

  const getAllAssessmentOptions = async () => {
    try {
      const { data: res, isSuccess } = await api.cadsAIDSDashboard.getAssessmentOptions();
      if (isSuccess) {
        setAssessmentOpts([{ value: null, label: 'All' }]);
        res.data?.map((item) => {
          setAssessmentOpts((assessment) => [...assessment, { value: item.id, label: item.title }]);
        });
      } else {
        throw new Error('error while fetching all assessments');
      }
    } catch (error) {
      console.error('Error inside dashboard all assessments', error.message);
    }
  };

  React.useEffect(() => {
    getAllAssessmentOptions();
  }, []);

  const handleSearch = () => {
    setIsSearched(true);
    getDataStarAnalytics({
      programTypeId: programTypeId,
      programId: searchProgram?.value,
      evaluationId: searchEvaluation?.value,
      startDate: fromDate,
      endDate: toDate,
      status: status?.value,
    });
  };

  const handleClear = () => {
    setIsSearched(false);
    setFromDate('');
    setToDate('');
    setStatus({ value: null, label: 'Select Status' });
    setSearchEvaluation({ value: null, label: 'All' });
    setSearchProgram({ value: null, label: 'Select Program' });
    getDataStarAnalytics({
      programTypeId: programTypeId,
      programId: searchProgram?.value,
      status: status,
    });
  };

  return (
    <>
      <div style={{ margin: '60px 0' }}>
        <div className="d-flex flex-wrap mb-4">
          <div className="table__search_item">
            Program
            <div style={{ minWidth: 200 }}>
              <Select
                name="program"
                value={searchProgram}
                onChange={(e) => {
                  handleChange(e, 'program');
                }}
                autosize={true}
                options={programs}
                clearable={false}
                className="react-select"
                placeholder={'Select Program'}
                classNamePrefix="react-select"
              />
            </div>
          </div>
          {values.evaluationDropdownVisible && (
            <div className="table__search_item">
              Evaluation
              <div style={{ minWidth: 200 }}>
                <Select
                  name="evaluation"
                  value={searchEvaluation}
                  onChange={(e) => {
                    handleChange(e, 'evaluation');
                  }}
                  autosize={true}
                  options={evaluationOpts}
                  clearable={false}
                  className="react-select"
                  placeholder={'Select Evaluation'}
                  classNamePrefix="react-select"
                />
              </div>
            </div>
          )}
          {values.assessmentDropdownVisible && (
            <div className="table__search_item">
              Assessments
              <div style={{ minWidth: 200 }}>
                <Select
                  name="assessment"
                  value={searchAssessment}
                  onChange={(e) => {
                    handleChange(e, 'assessment');
                  }}
                  autosize={true}
                  options={assessmentOpts}
                  clearable={false}
                  className="react-select"
                  placeholder={'Select Assessments'}
                  classNamePrefix="react-select"
                />
              </div>
            </div>
          )}
          <div className="table__search_item">
            Status
            <div style={{ minWidth: 200 }}>
              <Select
                name="status"
                value={status}
                onChange={(e) => handleChange(e, 'status')}
                autosize={true}
                options={[
                  { value: true, label: 'Active' },
                  { value: false, label: 'Deactive' },
                ]}
                clearable={false}
                className="react-select"
                placeholder={'Select Status'}
                classNamePrefix="react-select"
              />
            </div>
          </div>
        </div>
        <div className="d-flex flex-wrap">
          <div className="table__search_item" style={{ width: 200 }}>
            <b>From Date</b>
            <div className="form">
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <input
                    type="date"
                    className="search-bar-search-text"
                    name="from_date"
                    placeholder="From date"
                    onChange={(e) => setFromDate(e.target.value)}
                    value={fromDate}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="table__search_item" style={{ width: 200 }}>
            <b>To Date</b>
            <div className="form">
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <input
                    type="date"
                    className="search-bar-search-text"
                    name="to_date"
                    placeholder="To date"
                    min={fromDate}
                    onChange={(e) => setToDate(e.target.value)}
                    value={toDate}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="table__search_item mt-3">
            <Button color="primary" onClick={handleSearch}>
              {' '}
              Search{' '}
            </Button>
          </div>
          <div className="table__search_item mt-3">
            <Button color="secondary" onClick={handleClear} outline>
              Clear
            </Button>
          </div>
        </div>
      </div>

      <div dir="ltr">
        <ResponsiveContainer height={220} className="dashboard__area">
          <AreaChart
            data={sortedDataStarAnalytics}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Area
              name={`${values.checked ? values?.title : 'No of applicant'}`}
              dataKey="noOfApplicants"
              fill="#4ce1b6"
              stroke="#4ce1b6"
              fillOpacity={0.2}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default DataStarChart;
