import moment from "moment"

export const renderDdoDetail = (data) => {
    return [
        {
            label: 'Program Name',
            name: data?.ddoProgramTitle
        },
        {
            label: 'Description',
            name: data?.description ?? '-'
        },
        {
            label: 'Organization',
            name: data?.organization?.title ?? '-'
        },
        {
            label: 'Start Date',
            name: data?.ddoStartdate ? moment(data?.ddoStartdate).format("DD/MM/yyyy") : '-'
        },
        {
            label: 'End Date',
            name: data?.ddoEnddate ? moment(data?.ddoEnddate).format("DD/MM/yyyy") : '-'
        },
        {
            label: 'Report URL',
            name: data?.ddoReportUrl ?? '-'
        },
        {
            label: 'Max Participant',
            name: data?.ddoPlanMaxUsersCount
        },
        {
            label: 'Status',
            name: (data?.status === true || data?.status === false) ? (data.status ? "Active" : "De-Activated") : '-'
        },
        {
            label: 'Is Purchased ?',
            name: data?.isPurchased ? 'Yes' : 'NO'
        },
        {
            label: 'Purchase Date',
            name: data?.purchaseDate ? moment(data?.purchaseDate).format("DD/MM/yyyy") : '-'
        },
        {
            label: 'Finance Payment Id',
            name: data?.financePaymentId ?? '-'
        },
    ]
}
