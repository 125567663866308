import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import ProgramEditDialogHeader from './ProgramViewDialogHeader';
import api from '@/utils/apiClient';
import ProgramViewDetails from './ProgramViewDetails';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { useSelector, shallowEqual } from 'react-redux';

const ProgramViewDialog = ({ show, onHide, id, rtl }) => {
  const [modal, setModal] = useState({
    isOpen: show,
    title: 'View Program',
    hide: onHide,
  });

  const { data: programs } = useSelector((state) => state.cadsAIPrograms, shallowEqual);

  const [state, setState] = useState({
    loading: false,
    program: {
      name: 'Test Program 1',
      description: 'any',
    },
    error: '',
  });

  useEffect(() => {
    if (id) getProgramById(+id);
  }, [id]);

  const getProgramById = async (programId) => {
    try {
      setState({ ...state, loading: true });
      const { data: res, isSuccess } = await api.cadsAIPrograms.getProgramById(programId);
      if (id && isSuccess) {
        setState({ ...state, loading: false, program: res.data, error: '' });
      } else {
        throw new Error(res?.messages?.[0] ?? 'Error while getting program details');
      }
    } catch ({ message }) {
      setState({ ...state, loading: false, program: {}, error: message });
      console.error(message);
    }
  };

  const { error, loading, program } = state;

  return (
    <Modal
      isOpen={show}
      toggle={modal.hide}
      modalClassName={`${rtl.direction}-support`}
      className="modal-dialog--primary modal-dialog--header modal-view-dialog"
    >
      <ProgramEditDialogHeader modal={modal} program={program} />
      {/* Program Modal Body */}
      <div style={{ padding: 15 }}>
        {loading || !!error ? (
          <>
            {loading && (
              <div className="panel__refresh position-relative" style={{ height: 75 }}>
                <LoadingIcon />
              </div>
            )}
            <div className="text-center">
              {error && (
                <div>
                  <span className="lnr lnr-cross-circle modal__title-icon error" />
                </div>
              )}
              {loading ? 'Getting program details...' : error}
            </div>
          </>
        ) : (
          <ProgramViewDetails program={state.program} />
        )}
      </div>
    </Modal>
  );
};

export default connect((state) => ({
  rtl: state.rtl,
}))(ProgramViewDialog);
