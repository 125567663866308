import React from 'react';
import { Col } from 'reactstrap';
import AssignAssessmentWizard from '@/containers/Admin/Assignments/assign-assessment-wizard';

const AssignWizardPage = ({ selectedProduct }) => {
  return (
    <Col className="card-body">
      <AssignAssessmentWizard />
    </Col>
  );
};

export default AssignWizardPage;
