import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

export const getLearningPathColumns = (isForAssignment) => {
  if (isForAssignment) {
    const cols = [
      {
        id: 'imageurl',
        disablePadding: true,
        label: 'Learning Path Image',
      },
      {
        id: 'fullTitle',
        disablePadding: true,
        label: 'Full Name',
      },
      {
        id: 'shortTitle',
        disablePadding: true,
        label: 'Short Name',
      },
      {
        id: 'learningPathGroup.title',
        disablePadding: true,
        label: 'Group',
      },
    ];
    return isForAssignment
      ? [{ id: 'checkbox', disablePadding: true, label: '' }, ...cols]
      : [...cols, { id: 'Action', disablePadding: true, label: 'Action' }];
  } else {
    const cols = [
      {
        id: 'learningPathSelect',
        label: 'Select',
      },
      {
        id: 'imageurl',
        disablePadding: true,
        label: 'Learning Path Image',
      },
      {
        id: 'fullTitle',
        disablePadding: true,
        label: 'Full Name',
      },
      {
        id: 'shortTitle',
        disablePadding: true,
        label: 'Short Name',
      },
      {
        id: 'learningPathGroup.title',
        disablePadding: true,
        label: 'Group',
      },
      // {
      //     id: 'isMain', disablePadding: true, label: 'Is Main?',
      // }
    ];
    return isForAssignment
      ? [{ id: 'checkbox', disablePadding: true, label: '' }, ...cols]
      : [...cols, { id: 'Action', disablePadding: true, label: 'Action' }];
  }
};

const createSortHandler = (property, onRequestSort) => (event) => {
  onRequestSort(event, property);
};

const LearningPathTableColumns = ({ order, orderBy, onRequestSort, isForAssignment }) => (
  <Fragment>
    <TableRow>
      {getLearningPathColumns(isForAssignment).map((row) => (
        <TableCell
          className="material-table__cell material-table__cell--sort material-table__cell-center"
          key={row.id}
          align={'center'}
          padding={row.disablePadding ? 'none' : 'default'}
          sortDirection={orderBy === row.id ? order : false}
        >
          <TableSortLabel
            active={orderBy === row.id}
            direction={order}
            onClick={createSortHandler(row.id, onRequestSort)}
            className="material-table__sort-label"
            dir="ltr"
          >
            <b>{row.label}</b>
          </TableSortLabel>
        </TableCell>
      ))}
    </TableRow>
  </Fragment>
);

LearningPathTableColumns.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default connect((state) => ({
  rtl: state.rtl,
}))(LearningPathTableColumns);
