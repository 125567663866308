import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const fetchSpamRequestList = createAction('CADS_AI_SPAM_REQUESTS_LIST');
export const setSpamRequestList = createAction('CADS_AI_SET_SPAM_REQUESTS_LIST');
export const setSpamRequestError = createAction('CADS_AI_SET_SPAM_REQUESTS_ERROR');
export const setSpamRequestFilters = createAction('CADS_AI_SET_SPAM_REQUESTS_FILTERS');

export const getSpamRequestList = (filter) => async (dispatch) => {
  const defaultError = `Error occurred getting spam request list`;
  dispatch(fetchSpamRequestList());
  try {
    const { data, isSuccess } = await api.cadsAISpamRequest.getSpamRequestList(filter);
    if (isSuccess) dispatch(setSpamRequestList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setSpamRequestError(error.message));
    console.error(defaultError, error.message);
  }
};
