import React from 'react';
import UserOnboardCard from '@/containers/Cads-AI/Dashboard/components/user/UserOnboardCard';
import { Row } from 'reactstrap';
import Panel from '@/shared/components/Panel';
import ActiveUserCard from '@/containers/Cads-AI/Dashboard/components/user/ActiveUserCard';

const UserSection = () => {
  return (
    <Row className="mt-3">
      <Panel lg={6} xl={6} title={'Users Onboarding'}>
        <UserOnboardCard />
      </Panel>
      <Panel lg={6} xl={6} title={'Active users'}>
        <ActiveUserCard />
      </Panel>
    </Row>
  );
};

export default UserSection;
