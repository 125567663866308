import { handleActions } from 'redux-actions';
import {
  fetchArticleList,
  setArticleList,
  setArticleCount,
  setArticleError,
  setArticleFilters,
  toggleCheckedArticle,
  uncheckAllArticle,
} from './actions';

const defaultState = {
  loading: false,
  checkedArticleIds: [],
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    search: '',
    isSortOrderDescending: false,
    status: null,
  },
  totalRecords: 0,
  data: [],
  options: [],
  articleCount: 0,
  error: null,
};

export default handleActions(
  {
    [fetchArticleList](state) {
      return {
        ...state,
        loading: true,
        data: [],
      };
    },
    [setArticleList](state, { payload }) {
      return {
        ...state,
        loading: false,
        data: payload.data.records,
        totalRecords: payload.data.totalRecords,
        error: null,
      };
    },
    [setArticleError](state, { payload }) {
      return {
        ...state,
        data: [],
        loading: false,
        totalPages: defaultState.totalPages,
        error: payload?.message,
      };
    },
    [setArticleFilters](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
        checkedArticleIds: [],
      };
    },
    [toggleCheckedArticle](state, { payload: articleId }) {
      const checkedIds = state.checkedArticleIds;
      return {
        ...state,
        checkedArticleIds: checkedIds.includes(articleId)
          ? checkedIds.filter((id) => id !== articleId)
          : [...checkedIds, articleId],
      };
    },
    [uncheckAllArticle](state) {
      return {
        ...state,
        checkedArticleIds: [],
      };
    },
    [setArticleCount](state, { payload }) {
      return {
        ...state,
        loading: false,
        articleCount: payload,
        error: null,
      };
    },
  },
  defaultState,
);
