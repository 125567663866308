import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import CourseEditForm from './CourseEditForm';
import CourseEditDialogHeader from './CourseEditDialogHeader';

const CourseEditDialog = ({ show, onHide, id, isEdit, rtl }) => {
  const [modal, setModal] = useState({
    isOpen: show,
    title: `${isEdit ? 'Edit' : 'Add'} Course`,
    hide: onHide,
  });

  return (
    <Modal
      isOpen={show}
      toggle={modal.hide}
      modalClassName={`${rtl.direction}-support`}
      className="modal-dialog--primary modal-dialog--header modal-edit-dialog"
    >
      <CourseEditDialogHeader modal={modal} />
      <CourseEditForm modal={modal} isEdit={isEdit} id={id ? +id : ''} />
    </Modal>
  );
};

export default connect((state) => ({
  rtl: state.rtl,
}))(CourseEditDialog);
