import { sendRequest } from '../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const applicantsEndpoints = {
  ADD_APPLICANT: '/addApplicant',
  EDIT_APPLICANT: '/updateStatusForProgramApplicantList',
  GET_APPLICANT_LIST: '/getAllProgramApplicantDetails',
  GET_APPLICANT_LIST_CSV: '/exportAllProgramApplicantDtlsToCsv',
  GET_APPLICANT_STATUS_OPTION: '/getProgramApplicationStatusOptions',
  UPDATE_COHORT_FOR_APPLICANTS: '/updateCohortForProgramApplicantList',
  GET_PROGRAM_APPLICATION_STAGE_OPTIONS: '/getProgramApplicationStageOptions',
  UPDATE_STAGE_FOR_PROGRAM_APPLICANT_LIST: '/updateStageForProgramApplicantList',
};

const addApplicant = (applicantData) => {
  return sendRequest({
    url: applicantsEndpoints.ADD_APPLICANT,
    method: httpMethod.POST,
    data: applicantData,
  });
};

const updateStatusForProgramApplicant = (applicantIds, applicantStatusId) => {
  return sendRequest(
    {
      url: applicantsEndpoints.EDIT_APPLICANT,
      method: httpMethod.PUT,
      data: {
        applicantIds,
        applicantStatusId,
      },
    },
    process.env.REACT_APP_PROGRAM_ADMIN_ENDPOINT,
  );
};

const updateCohortForProgramApplicants = (applicantIds, programId, cohortId) => {
  return sendRequest(
    {
      url: applicantsEndpoints.UPDATE_COHORT_FOR_APPLICANTS,
      method: httpMethod.PUT,
      data: { applicantIds, programId, cohortId },
    },
    process.env.REACT_APP_PROGRAM_ADMIN_ENDPOINT,
  );
};

const updateStageForProgramApplicant = (applicantIds, applicantStageId) => {
  return sendRequest(
    {
      url: applicantsEndpoints.UPDATE_STAGE_FOR_PROGRAM_APPLICANT_LIST,
      method: httpMethod.PUT,
      data: { applicantIds, applicantStageId },
    },
    process.env.REACT_APP_PROGRAM_ADMIN_ENDPOINT,
  );
};

const getApplicantsList = (filter) => {
  return sendRequest(
    {
      url: applicantsEndpoints.GET_APPLICANT_LIST,
      method: httpMethod.GET,
      params: { ...filter, searchText: filter?.searchByName ?? '' },
    },
    process.env.REACT_APP_PROGRAM_ADMIN_ENDPOINT,
  );
};

const getApplicantListCSV = (programId) => {
  return sendRequest(
    {
      url: applicantsEndpoints.GET_APPLICANT_LIST_CSV,
      method: httpMethod.GET,
      params: programId,
    },
    process.env.REACT_APP_PROGRAM_ADMIN_ENDPOINT,
  );
};

const getProgramApplicationStatusOptions = () => {
  return sendRequest(
    {
      url: applicantsEndpoints.GET_APPLICANT_STATUS_OPTION,
      method: httpMethod.GET,
    },
    process.env.REACT_APP_PROGRAM_ADMIN_ENDPOINT,
  );
};

const getProgramApplicationStageOptions = () => {
  return sendRequest(
    {
      url: applicantsEndpoints.GET_PROGRAM_APPLICATION_STAGE_OPTIONS,
      method: httpMethod.GET,
    },
    process.env.REACT_APP_PROGRAM_ADMIN_ENDPOINT,
  );
};

export default {
  addApplicant,
  updateStatusForProgramApplicant,
  updateStageForProgramApplicant,
  getApplicantListCSV,
  getApplicantsList,
  getProgramApplicationStatusOptions,
  getProgramApplicationStageOptions,
  updateCohortForProgramApplicants,
};
