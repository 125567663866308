import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Card, Row, CardBody } from 'reactstrap';
import StepOne from './steps/StepOne';
import StepTwo from './steps/StepTwo';
import StepThree from './steps/StepThree';

export function getStepFields(page) {
  switch (page) {
    case 1:
      return ['skill', 'questionLevel', 'questionType', 'tags'];
    case 2:
      return ['question', 'additionalText', 'image'];
    case 3:
      return ['allowSkip', 'allowOther', 'answers'];
    default:
      return [];
  }
}

export function extractFieldData(page, data) {
  const obj = {};
  getStepFields(page).forEach((key) => (obj[key] = data[key]));
  return obj;
}

const WizardForm = ({ onSubmit, data, setData, initialValues, isEdit, questionId }) => {
  const [page, setPage] = useState(1);
  const [answerData, setAnswerData] = React.useState([]);

  const nextPage = (newData) => {
    setData({ ...data, ...newData });
    setPage(page + 1);
  };

  const previousPage = (newData) => {
    setData({ ...data, ...newData });
    setPage(page - 1);
  };

  const clearForm = () => {
    setData(initialValues);
    setPage(1);
  };

  //TODO: try finding a better way to handle dynamic answers from step 3
  const formatAnswers = (newData) => {
    const keys = Object.keys(newData).filter((key) => key.startsWith('answer-'));
    if (!keys.length) return newData;
    const ansDataKeys = answerData.map((ans) => `${ans.id}`);
    const answers = ansDataKeys.map((key) => {
      const ans = { text: newData[`answer-${key}`], isCorrect: newData[`correct-${key}`] ?? false };
      if (isEdit) ans.id = key.startsWith('c-') ? null : +key;
      return ans;
    });
    keys.forEach((key) => {
      delete newData[key];
      delete newData[`correct-${key.split('-')[1]}`];
    });
    newData.answers = answers;
  };

  const submitHandler = (newData) => {
    formatAnswers(newData);
    const dataToSubmit = { ...data, ...newData };
    setData(dataToSubmit);
    onSubmit(dataToSubmit, clearForm);
  };

  return (
    <Row>
      <Col md={12} lg={12}>
        <Card className="pb-0">
          <CardBody className="wizard">
            <div className="wizard__steps">
              <div className={`wizard__step${page === 1 ? ' wizard__step--active' : ''}`}>
                <p>Meta</p>
              </div>
              <div className={`wizard__step${page === 2 ? ' wizard__step--active' : ''}`}>
                <p>Question</p>
              </div>
              <div className={`wizard__step${page === 3 ? ' wizard__step--active' : ''}`}>
                <p>Answers</p>
              </div>
            </div>
            <div className="wizard__form-wrapper">
              {page === 1 && <StepOne page={page} onSubmit={nextPage} data={data} />}
              {page === 2 && (
                <StepTwo page={page} previousPage={previousPage} onSubmit={nextPage} data={data} />
              )}
              {page === 3 && (
                <StepThree
                  page={page}
                  previousPage={previousPage}
                  onSubmit={submitHandler}
                  onClear={clearForm}
                  data={data}
                  answerData={answerData}
                  setAnswerData={setAnswerData}
                  isEdit={isEdit}
                  questionId={questionId}
                />
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

WizardForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default WizardForm;
