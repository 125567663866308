import React, { useState } from 'react';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';

const ModuleViewBasicDetails = (module) => {
  return (
    <table className="project-summary__info">
      <tbody>
        <tr>
          <th className="w-25">Image</th>
          <td>
            <div className="module_image">
              <img src={module?.imageUrl} />
            </div>
          </td>
        </tr>
        <tr>
          <th>Title</th>
          <td>{module?.title ?? '-'}</td>
        </tr>
        <tr>
          <th>Description</th>
          <td>{module?.description ?? '-'}</td>
        </tr>
        <tr>
          <th>Summary</th>
          <td>{module?.summary ?? '-'}</td>
        </tr>
        <tr>
          <th>Code</th>
          <td>{module?.code ?? '-'}</td>
        </tr>
        <tr>
          <th>Module Type</th>
          <td>{module?.moduleType?.title ?? '-'}</td>
        </tr>
        <tr>
          <th>Platform Wide</th>
          <td>{module?.isPlatformWide ? 'Yes' : 'No'}</td>
        </tr>
        <tr>
          <th>Data Skills</th>
          <td>{module?.dataSkills?.map((ds) => ds.title)?.join(', ')}</td>
        </tr>
        <tr>
          <th className="w-25">Data Other Skills</th>
          <td>{module?.dataOtherSkills?.map((ds) => ds.title)?.join(', ')}</td>
        </tr>
      </tbody>
    </table>
  );
};

const ModuleCoursesDetails = (module) => {
  return (
    <table className="project-summary__info table">
      <tr>
        <th>Order</th>
        <th className="pl-5">Courses</th>
      </tr>
      {module?.courses.map((item) => (
        <>
          <tr>
            <td>{item?.id}</td>
            <td className="pl-5">{item.title}</td>
          </tr>
        </>
      ))}
    </table>
  );
};

export default function ModuleViewTabs({ module }) {
  const tabs = React.useMemo(
    () => ({
      BASIC_DETAILS: {
        id: 'BASIC_DETAILS',
        label: 'Basic Details',
        renderTab: () => ModuleViewBasicDetails(module),
      },
      COURSES_DETAILS: {
        id: 'COURSES_DETAILS',
        label: 'Courses',
        renderTab: () => ModuleCoursesDetails(module),
      },
    }),
    [module],
  );

  const [activeTab, setActiveTab] = useState(tabs.BASIC_DETAILS.id);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Row className="p-1">
      <Col>
        <div className="tabs tabs--bordered-bottom">
          <div className="tabs__wrap">
            <Nav tabs>
              {Object.keys(tabs).map((key) => (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === tabs[key].id })}
                    onClick={() => toggle(tabs[key].id)}
                  >
                    {tabs[key].label}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={activeTab}>{tabs[activeTab]?.renderTab()}</TabPane>
            </TabContent>
          </div>
        </div>
      </Col>
    </Row>
  );
}
