import { sendRequest } from '../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const dashboardEndpoints = {
  GET_TOTAL_CUSTOMER_COUNT: '/getTotalCustomerCount',
  GET_TOTAL_INDIVIDUALS_COUNT: '/getAllIndividuals',
  GET_TOTAL_ASSESSMENT_COMPLETED: `/getDSCompletedAssessmentCount`,
  GET_PROGRAM_DAILY_COUNT_GRAPH: '/getProgramDailyCountGraph',
  GET_APPLICANT_COUNT: '/getApplicantCount',
  GET_ACCEPTED_APPLICANT_COUNT: '/getAcceptedApplicantCount',
  GET_PROGRAM_APPLICANT_STAGE_COUNT: '/getProgramApplicantStageCount',
};

/**
 * Returns promise which will eventually resolve in active and inactive customer count data
 * @returns {Promise<{data: {activeCount: number, inactiveCount: number}, isSuccess: boolean} | undefined>}
 */
const getTotalCustomerCount = () => {
  return sendRequest({
    url: dashboardEndpoints.GET_TOTAL_CUSTOMER_COUNT,
    method: httpMethod.GET,
  });
};

/**
 * Returns promise which will eventually resolve in data star graph
 */
const getProgramDailyCountGraph = (filters) => {
  return sendRequest(
    {
      url: dashboardEndpoints.GET_PROGRAM_DAILY_COUNT_GRAPH,
      method: httpMethod.GET,
      params: filters,
    },
    process.env.REACT_APP_PROGRAM_ADMIN_ENDPOINT,
  );
};

/**
 * Returns promise which will eventually resolve in active and inactive individual count data
 * @returns {Promise<{data: {activeCount: number, inactiveCount: number}, isSuccess: boolean} | undefined>}
 */
const getTotalIndividualCount = () => {
  return sendRequest({
    url: dashboardEndpoints.GET_TOTAL_INDIVIDUALS_COUNT,
    method: httpMethod.GET,
  });
};

/**
 * Returns promise which will eventually resolve in total assessment count data
 * @returns {Promise<{data: number, isSuccess: boolean} | undefined>}
 */
const getTotalAssessmentCount = (filters) => {
  return sendRequest(
    {
      url: dashboardEndpoints.GET_TOTAL_ASSESSMENT_COMPLETED,
      method: httpMethod.GET,
      params: filters,
    },
    process.env.REACT_APP_PROGRAM_ADMIN_ENDPOINT,
  );
};

const getApplicantCount = (filters) => {
  return sendRequest(
    {
      url: dashboardEndpoints.GET_APPLICANT_COUNT,
      method: httpMethod.GET,
      params: filters,
    },
    process.env.REACT_APP_PROGRAM_ADMIN_ENDPOINT,
  );
};

const getAcceptedApplicantCount = (filters) => {
  return sendRequest(
    {
      url: dashboardEndpoints.GET_ACCEPTED_APPLICANT_COUNT,
      method: httpMethod.GET,
      params: filters,
    },
    process.env.REACT_APP_PROGRAM_ADMIN_ENDPOINT,
  );
};

const getProgramApplicantStageCount = (filters, stageId) => {
  return sendRequest(
    {
      url: dashboardEndpoints.GET_PROGRAM_APPLICANT_STAGE_COUNT,
      method: httpMethod.GET,
      params: { ...filters, stageId },
    },
    process.env.REACT_APP_PROGRAM_ADMIN_ENDPOINT,
  );
};

export default {
  getTotalCustomerCount,
  getTotalIndividualCount,
  getTotalAssessmentCount,
  getProgramDailyCountGraph,
  getAcceptedApplicantCount,
  getApplicantCount,
  getProgramApplicantStageCount,
};
