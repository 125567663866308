import { handleActions } from 'redux-actions';
import {
  deleteJobFromList,
  fetchJobList,
  setJobError,
  setJobFilters,
  setJobList,
  updateInJobList,
} from './actions';

const defaultState = {
  loading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    searchText: '',
    status: null,
    isSortOrderDescending: false,
  },
  totalRecords: 0,
  data: [],
  options: [],
  error: null,
};

export default handleActions(
  {
    [fetchJobList](state) {
      return {
        ...state,
        loading: true,
        data: [],
      };
    },
    [setJobList](state, { payload }) {
      return {
        ...state,
        loading: false,
        data: payload.data.records,
        totalRecords: payload.data.totalRecords,
        error: null,
      };
    },
    [updateInJobList](state, { payload }) {
      return {
        ...state,
        data: state.data.map((job) =>
          +job.id === +payload.id
            ? { ...job, name: payload.name, description: payload.description }
            : job,
        ),
      };
    },
    [deleteJobFromList](state, { payload }) {
      return {
        ...state,
        data: state.data.filter((job) => job.id !== payload),
        totalRecords: state.totalRecords - 1,
      };
    },
    [setJobError](state, { payload }) {
      return {
        ...state,
        data: [],
        loading: false,
        error: payload?.message,
      };
    },
    [setJobFilters](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
      };
    },
  },
  defaultState,
);
