import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import OrderHistoryTableWrapper from './table/OrderHistoryTableWrapper';
import { Route, Switch } from 'react-router-dom';
import { cadsAiOrderHistoryBaseUrl } from '..';
import OrderHistoryView from './order-history-view-dialog/OrderHistoryView';

export function OrderHistoryCard() {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Order History</h3>
        </Col>
      </Row>
      <Row>
        <Switch>
          <Route path={`${cadsAiOrderHistoryBaseUrl}/:id/view`}>
            {({ history, match }) => (
              <div className="order-history-view-page w-100">
                <OrderHistoryView
                  show={match != null}
                  id={match?.params?.id ? +match?.params?.id : ''}
                  onHide={() => {
                    history.push(cadsAiOrderHistoryBaseUrl);
                  }}
                />
              </div>
            )}
          </Route>
          <Route path="">
            <OrderHistoryTableWrapper />
          </Route>
        </Switch>
      </Row>
    </Container>
  );
}
