import { createAction } from 'redux-actions';
import { getDefaultPageProps, getDefaultQuestionProps } from './util';

export const setAssessmentConfig = createAction('SET_ASSESSMENT_CONFIG');
export const addNewConfigPage = createAction('ADD_NEW_CONFIG_PAGE');
export const addNewConfigPageQuestion = createAction('ADD_NEW_CONFIG_PAGE_QUESTION');
export const updateConfigPageDetails = createAction('ADD_CONFIG_PAGE_DETAILS');
export const updateConfigPageQuestionDetails = createAction('ADD_CONFIG_PAGE_QUESTION_DETAILS');
export const deleteConfigPageQuestion = createAction('DELETE_CONFIG_PAGE_QUESTION');
export const setConfigPageQuestionTypeData = createAction('SET_CONFIG_PAGE_QUESTION_TYPE_DATA');

export const addNewAssessmentConfigPage = () => (dispatch) => {
  dispatch(addNewConfigPage(getDefaultPageProps()));
};

export const addNewAssessmentConfigPageQuestion = (props) => (dispatch) => {
  dispatch(addNewConfigPageQuestion({ ...props, ...getDefaultQuestionProps() }));
};
