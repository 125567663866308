import React from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import TrainingProviderTable from './TrainingProviderTable';
import { useTrainingProviderUIContext } from '../TrainingProviderUIProvider';

const TrainingProviderTableWrapper = () => {
  const trainingProviderUIContext = useTrainingProviderUIContext();

  return trainingProviderUIContext.isForAssignment ? (
    <TrainingProviderTable />
  ) : (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="products-list">
          <div className="card__title">
            <h5 className="bold-text">Training Provider List</h5>
            <ButtonToolbar className="products-list__btn-toolbar-top">
              <Button
                color="primary"
                onClick={trainingProviderUIContext.openNewTrainingProviderDialog}
              >
                Add New Training Path
              </Button>
            </ButtonToolbar>
          </div>
          <TrainingProviderTable />
        </CardBody>
      </Card>
    </Col>
  );
};

export default TrainingProviderTableWrapper;
