import React, { useEffect, useState } from 'react';
import LoadingIcon from 'mdi-react/LoadingIcon';
import api from '@/utils/apiClient';
import TextEditor from '@/shared/components/text-editor/TextEditor';
import { Button, ButtonToolbar } from 'reactstrap';
import { getSanitizeHTML } from '@/shared/components/SanitizeHTML';
import { emptyStringPattern } from '@/shared/helpers';
import FormField from '@/shared/components/form/hook/FormField';
import { useForm } from 'react-hook-form';

const NotificationEditDialogBody = ({ modal, isEdit, id }) => {
  const initialValues = {
    description: '',
    actions: '',
    title: '',
  };

  const [data, setData] = useState(initialValues);

  const [state, setState] = useState({
    loading: false,
    fetchingNotification: false,
    newNotificationAdded: false,
    errorMessage: '',
  });

  useEffect(() => {
    if (isEdit && id) {
      setDefaultData(+id);
    }
  }, [isEdit, id]);

  const setDefaultData = async (notificationId) => {
    try {
      const { data: res, isSuccess } = await api.cadsAINotification.getNotificationById(
        notificationId,
      );
      const notificationData = res.data;
      if (id) {
        setData({
          ...notificationData,
        });
        ['title', 'actions', 'description'].forEach((field) =>
          setValue(field, notificationData?.[field]),
        );
      }
    } catch ({ message }) {
      console.error('Notification edit error : ', message);
      setState({
        ...state,
        errorMessage: 'Error while getting existing notification details',
      });
    }
  };

  const constructApiRequestData = async ({ title, actions }) => {
    return {
      description: data?.description,
      title,
      actions,
      id: id,
    };
  };
  const handleAddEditNotification = async (notificationData) => {
    setState({ ...state, loading: true });
    const newNotificationData = await constructApiRequestData(notificationData);
    const defaultError = `Error occurred while ${isEdit ? 'Updating' : 'Adding new'} notification`;
    try {
      const { data: res, isSuccess } = isEdit
        ? await api.cadsAINotification.updateNotification(newNotificationData)
        : await api.cadsAINotification.addNotification(newNotificationData);
      if (isSuccess) {
        setState({ ...state, newNotificationAdded: true, loading: false });
        if (!isEdit) setData(initialValues);
      } else {
        throw new Error(data?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({
        ...state,
        loading: false,
        newNotificationAdded: false,
        errorMessage: error.message,
      });
    }
  };

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: initialValues });

  return (
    <div>
      {/*Add/Edit Notification Form Status*/}
      <div className="ml-4">
        {state.loading && (
          <div>
            <div className="panel__refresh position-relative" style={{ height: 75, width: 20 }}>
              <LoadingIcon />
              <div style={{ width: 200, padding: '23px 30px' }}>
                {isEdit ? 'Updating' : 'Adding new'} notification...
              </div>
            </div>
          </div>
        )}
        {(state.newNotificationAdded || !!state.errorMessage) && (
          <>
            <table className="mt-3">
              <tr>
                <td>
                  {state.newNotificationAdded ? (
                    <span className="lnr lnr-thumbs-up modal__title-icon success" />
                  ) : (
                    <span className="lnr lnr-cross-circle modal__title-icon error" />
                  )}
                </td>
                <td className="pl-2">
                  {state.newNotificationAdded
                    ? `${isEdit ? 'Notification Updated' : 'New Notification Added'} Successfully`
                    : state.errorMessage}
                </td>
              </tr>
            </table>
          </>
        )}
      </div>
      <div className="modal__body add-edit-learning-path dropzone-border">
        <form
          className={`form form--horizontal`}
          onSubmit={handleSubmit(handleAddEditNotification)}
        >
          {!state.newNotificationAdded ? (
            <div className={'w-100 p-3'}>
              <div className="form__form-group">
                <span className="form__form-group-label">Title</span>
                <div className="form__form-group-field">
                  <FormField
                    name="title"
                    control={control}
                    component="input"
                    errors={errors}
                    rules={{
                      required: 'This is required field',
                      pattern: emptyStringPattern,
                    }}
                    defaultValue=""
                    isAboveError={true}
                    placeholder="Enter Title"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Description</span>
                <div className="form__form-group-field">
                  {/*TODO: set html after sanitizing for all text editor*/}
                  {(!isEdit || data.description) && (
                    <FormField
                      control={control}
                      name="description"
                      component={() => (
                        <TextEditor
                          defaultContent={getSanitizeHTML(data.description)}
                          onChange={(value) => setValue('description', value)}
                        />
                      )}
                    />
                  )}
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Actions</span>
                <div className="form__form-group-field">
                  <FormField
                    name="actions"
                    control={control}
                    component="input"
                    defaultValue=""
                    placeholder="Enter Actions"
                  />
                </div>
              </div>
              <ButtonToolbar className="modal__footer btn-toolbar">
                <>
                  <Button
                    color="primary"
                    type="submit"
                    onClick={() => handleAddEditNotification(data)}
                  >
                    Submit
                  </Button>
                  <Button
                    color="secondary"
                    type="button"
                    className="modal_cancel btn btn-secondary"
                    onClick={modal.hide}
                  >
                    Close
                  </Button>
                </>
              </ButtonToolbar>
            </div>
          ) : (
            <div className={'p-3'}>
              <ButtonToolbar className="modal__footer btn-toolbar">
                <Button color="primary" type="button" onClick={modal.hide}>
                  Okay
                </Button>
              </ButtonToolbar>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default NotificationEditDialogBody;
