import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonToolbar } from 'reactstrap';
import api from '@/utils/apiClient';
import { emptyStringPattern, getColorForWeight, YesNoOptions } from '@/shared/helpers';
import { FormField } from '@/shared/components/form/hook/FormField';
import { getLearningPathGroupOptionList } from '../../redux/actions';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { SelectField } from '@/shared/components/form/Select';
import { CreatableSelectField } from '@/shared/components/form/CreatableSelectField';
import { isEmpty } from 'lodash/lang';
import { getCPModulesList } from '@/containers/Control-Panel/Module/redux/actions';

const LearningPathEditForm = ({ modal, isEdit, id }) => {
  const dispatch = useDispatch();

  //states
  const [state, setState] = React.useState({
    loading: false,
    fetchingLearningPath: false,
    newLearningPathAdded: false,
    errorMessage: '',
  });
  const [groupOptions, setGroupOptions] = React.useState([]);
  const [moduleOptions, setModuleOptions] = React.useState([]);
  const [selectedModuleList, setSelectedModuleList] = React.useState([]);
  const { learningPathGroupOptions: groups, assignLearningPaths } = useSelector(
    (state) => state.cpLearningPaths,
  );
  const { assignModules: moduleList } = useSelector((state) => state.cpModules);
  const selectedLP = React.useMemo(
    () => (id ? assignLearningPaths.find((lp) => lp.id === +id) : {}),
    [id],
  );

  // learningPath form related functions
  const {
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({ reValidateMode: 'onChange' });

  //useEffects
  React.useEffect(() => {
    dispatch(getLearningPathGroupOptionList());
    dispatch(getCPModulesList({ pageSize: 1000 })); //TODO: modify BE to have specific api for options
  }, []);

  React.useEffect(() => {
    setGroupOptions(groups);
  }, [groups]);

  React.useEffect(() => {
    if (id) {
      setSelectedModuleList(
        selectedLP?.moduleList?.map((module) => ({
          value: module.id,
          label: module.name,
          ...module,
        })) ?? [],
      );
      const selectedModuleIds = selectedLP?.moduleList?.map((module) => module.id) ?? [];
      setModuleOptions(
        moduleList
          ?.filter((m) => !selectedModuleIds.includes(m.id))
          .map((module) => ({ label: module.name, value: module.id, ...module })),
      );
    } else {
      setModuleOptions(
        moduleList?.map((module) => ({ label: module.name, value: module.id, ...module })),
      );
    }
  }, [moduleList, selectedLP]);

  // prefill the form in case of edit learningPath
  React.useEffect(() => {
    if (isEdit && id) {
      setDefaultData(+id);
    }
  }, [isEdit, id, assignLearningPaths]);

  React.useEffect(() => {
    trigger('module');
  }, [selectedModuleList]);

  const setDefaultData = (learningPathId) => {
    ['id', 'fullName', 'shortName', 'description', 'tagline', 'summary', 'imageUrl'].forEach(
      (field) => setValue(field, selectedLP?.[field]),
    );
    setValue('isMain', YesNoOptions[selectedLP?.isMain ? 0 : 1]);
    setValue('group', { value: selectedLP?.group.id, label: selectedLP?.group.name });
  };

  const resetForm = () => {
    reset({
      id: 0,
      fullName: '',
      shortName: '',
      description: '',
      tagline: '',
      summary: '',
      imageUrl: '',
      isMain: false,
      group: [],
    });
    setModuleOptions([...moduleOptions, ...selectedModuleList]);
    setSelectedModuleList([]);
  };

  const addToModuleList = async () => {
    const { module, isOptional } = getValues();
    if (isEmpty(isOptional) || isEmpty(module)) return;
    setSelectedModuleList([...selectedModuleList, { ...module, isOptional: isOptional?.value }]);
    setValue('module', '');
    setValue('isOptional', { value: false, label: 'No' });
    setModuleOptions(moduleOptions.filter((opt) => opt.value !== module?.value));
  };

  const removeModuleFromList = (module) => {
    setModuleOptions([...moduleOptions, module]);
    setSelectedModuleList([...selectedModuleList.filter(({ value }) => value !== module.value)]);
  };

  const onSubmit = async (
    { fullName, shortName, description, tagline, summary, imageUrl, isMain, group },
    e,
  ) => {
    setState({ ...state, loading: true });
    const reqData = {
      learningPath: {
        fullName,
        shortName,
        description,
        tagline,
        summary,
        imageUrl,
        isMain: isEdit ? isMain.value : false,
        group: { id: group.__isNew__ ? null : group.value, name: group.label },
      },
      moduleList: selectedModuleList.map((module) => ({
        id: module.value,
        name: module.label,
        isOptional: module.isOptional,
      })),
    };
    const defaultError = `Error occurred while ${isEdit ? 'Updating' : 'Adding new'} learningPath`;
    try {
      const { data, isSuccess } = isEdit
        ? await api.learningPaths.editLearningPath({ id: +id, ...reqData })
        : await api.learningPaths.addLearningPath(reqData);
      if (isSuccess) {
        setState({ ...state, newLearningPathAdded: true, loading: false });

        if (!isEdit) resetForm();
      } else {
        throw new Error(data?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({
        ...state,
        loading: false,
        newLearningPathAdded: false,
        errorMessage: error.message,
      });
    }
  };

  const onCancel = () => {
    resetForm();
    modal.hide();
  };

  return (
    <div>
      {/*Add/Edit LearningPath Form Status*/}
      <div className="ml-4">
        {state.loading && (
          <div>
            <div className="panel__refresh position-relative" style={{ height: 75, width: 20 }}>
              <LoadingIcon />
              <div style={{ width: 200, padding: '23px 40px' }}>
                {isEdit ? 'Updating' : 'Adding new'} learningPath...
              </div>
            </div>
          </div>
        )}
        {(state.newLearningPathAdded || !!state.errorMessage) && (
          <table className="mt-3">
            <tr>
              <td>
                {state.newLearningPathAdded ? (
                  <span className="lnr lnr-thumbs-up modal__title-icon success" />
                ) : (
                  <span className="lnr lnr-cross-circle modal__title-icon error" />
                )}
              </td>
              <td className="pl-2">
                {state.newLearningPathAdded
                  ? `${isEdit ? 'LearningPath Updated' : 'New LearningPath Added'} successfully`
                  : state.errorMessage}
              </td>
            </tr>
          </table>
        )}
      </div>

      {/*Add/Edit LearningPath Form*/}
      <div className="modal__body add-edit-learning-path">
        <form className={`form form--horizontal`} onSubmit={handleSubmit(onSubmit)}>
          <div className="form__form-group">
            <span className="form__form-group-label">Full Name</span>
            <div className="form__form-group-field">
              <FormField
                name="fullName"
                control={control}
                component="input"
                errors={errors}
                rules={{
                  required: 'This is required field',
                  pattern: emptyStringPattern,
                }}
                defaultValue=""
                isAboveError={true}
                placeholder="Full Name of learning path"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Short Name</span>
            <div className="form__form-group-field">
              <FormField
                name="shortName"
                control={control}
                component="input"
                errors={errors}
                rules={{
                  required: 'This is required field',
                  pattern: emptyStringPattern,
                }}
                defaultValue=""
                isAboveError={true}
                placeholder="Short Name of learning path"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Description</span>
            <div className="form__form-group-field">
              <FormField
                name="description"
                control={control}
                component="textarea"
                errors={errors}
                defaultValue=""
                isAboveError={true}
                placeholder="Write description about learning path"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Tagline</span>
            <div className="form__form-group-field">
              <FormField
                name="tagline"
                control={control}
                component="input"
                errors={errors}
                defaultValue=""
                isAboveError={true}
                placeholder="Tag line for learning path"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Summary</span>
            <div className="form__form-group-field">
              <FormField
                name="summary"
                control={control}
                component="textarea"
                errors={errors}
                defaultValue=""
                isAboveError={true}
                placeholder="Write summary about learning path"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Image URL</span>
            <div className="form__form-group-field">
              <FormField
                name="imageUrl"
                as="input"
                placeholder="Enter image url"
                control={control}
                defaultValue=""
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Create/Select Group</span>
            <div className="form__form-group-field">
              <FormField
                control={control}
                name="group"
                component={CreatableSelectField}
                isMulti={false}
                options={groupOptions}
                placeholder="Create Or Select Group"
                rules={{ required: 'This is required field' }}
                errors={errors}
                isAboveError
              />
            </div>
          </div>

          <div className="form__form-group lp_edit_module_error" style={{ width: '60%' }}>
            <span className="form__form-group-label">Module</span>
            <FormField
              style={{ height: 40 }}
              name="module"
              control={control}
              component={SelectField}
              errors={errors}
              rules={{
                validate: () =>
                  selectedModuleList?.length === 0 ? 'Please add at least one module' : true,
              }}
              options={moduleOptions}
              isAboveError
              placeholder="Select module"
            />
          </div>
          <div className="form__form-group ml-2" style={{ width: '24%' }}>
            <span className="form__form-group-label">Is Optional?</span>
            <FormField
              name="isOptional"
              style={{ height: 40 }}
              control={control}
              component={SelectField}
              errors={errors}
              options={[
                { value: true, label: 'Yes' },
                { value: false, label: 'No' },
              ]}
              defaultValue={{ value: false, label: 'No' }}
              isAboveError
            />
          </div>
          <div className="form__form-group" style={{ width: '10%' }}>
            <div className="competency_add_button">
              <Button
                color="success"
                type="button"
                outline
                style={{ marginLeft: 20, height: 38 }}
                onClick={addToModuleList}
              >
                Add
              </Button>
            </div>
          </div>
          <div className="competency_add_section">
            {selectedModuleList.map((module) => (
              <p key={module.value}>
                <span
                  onClick={() => removeModuleFromList(module)}
                  onMouseEnter={(e) => (e.target.className = 'lnr lnr-trash')}
                  onMouseOut={(e) => (e.target.className = 'lnr lnr-checkmark-circle')}
                  style={{ color: getColorForWeight({ random: true }), cursor: 'pointer' }}
                  className="lnr lnr-checkmark-circle"
                />{' '}
                {module.label} {module?.isOptional && '(optional)'}
              </p>
            ))}
          </div>

          <ButtonToolbar className="form__button-toolbar modal__footer">
            <Button disabled={state.loading} color="primary" type="submit">
              Submit
            </Button>
            <Button type="button" className="modal_cancel" onClick={onCancel}>
              Close
            </Button>
          </ButtonToolbar>
        </form>
      </div>
    </div>
  );
};

export default LearningPathEditForm;
