import { Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import OverviewTabs from './OverviewTabs';

const OverviewDescription = ({
  color,
  title,
  description,
  id,
  behavioralAnalysis,
  competenciesAnalysis,
}) => {
  return (
    <Fragment>
      <Row className={'mt-3'}>
        <Col md={'12'}>
          <div style={{ color: color }}>
            <b>{title}</b>
          </div>
          <div style={{ color: color }}>{description}</div>
        </Col>
        <br />
      </Row>
      <OverviewTabs
        id={id}
        behavioralAnalysis={behavioralAnalysis}
        competenciesAnalysis={competenciesAnalysis}
      />
    </Fragment>
  );
};

export default OverviewDescription;
