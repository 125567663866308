import { sendRequest } from '@/utils/api/base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const CP_ENDPOINT = process.env.REACT_APP_ROOT_URL + process.env.REACT_APP_CONTROL_PANEL_ENDPOINT;

const cpCohortEndpoints = {
  GET_COHORT_LIST_BY_ORG_ID: `${CP_ENDPOINT}/getAllControlledCohortsByOrgId`,
};

const getCohortListByOrgId = ({ orgId, assigned, feature, ...otherFilters }) => {
  // return getMockCohortListByOrgId();
  return sendRequest({
    fullUrl: cpCohortEndpoints.GET_COHORT_LIST_BY_ORG_ID,
    method: httpMethod.GET,
    params: {
      orgId,
      accessStatus: assigned ? 0 : 1, // Assigned=0/Unassigned=1
      feature, // Chatbot=0/DDO=1/Acceltic=2
      ...otherFilters,
    },
  });
};

export default {
  getCohortListByOrgId,
};

const getMockCohortListByOrgId = () =>
  new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [''],
            data: [
              {
                cohortId: 1,
                cohortName: 'Mock Cohort 1',
                hasChatBot: true,
                hasDDo: false,
                hasAcceltic: false,
              },
              {
                cohortId: 2,
                cohortName: 'Mock Cohort 2',
                hasChatBot: true,
                hasDDo: false,
                hasAcceltic: false,
              },
            ],
          },
        }),
      500,
    );
  });
