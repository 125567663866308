import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import api from '@/utils/apiClient';
import LoadingIcon from 'mdi-react/LoadingIcon';
import EmployeeQRDialogHeader from "./EmployeeQRDialogHeader";
import { QRCode } from "react-qrcode-logo";
import LogoImage from '@/shared/img/logo/cads_qr_logo.png';

const EmployeeQRDialog = ({show, onHide, slug, rtl}) => {
  const [modal] = useState({
    isOpen: show,
    title: 'View Employees',
    hide: onHide,
  });

  const [state, setState] = useState({
    loading: true,
    employee: {},
    error: '',
  });

  useEffect(() => {
    if (slug) getEmployeeById(slug);
  }, [slug]);

  const getEmployeeById = async (slug) => {
    try {
      const {data: res, isSuccess} = await api.cadsAIEmployee.getEmployeeById(slug);
      if (slug) {
        setState({...state, loading: false, employee: res.data, error: ''});
      } else {
        throw new Error(res?.messages?.[0] ?? 'Error while getting employee details');
      }
    } catch ({message}) {
      setState({...state, loading: false, employee: {}, error: message});
      console.error(message);
    }
  };

  const {error, loading, employee} = state;

  return (
      <Modal
          isOpen={show}
          toggle={modal.hide}
          modalClassName={`${rtl.direction}-support`}
          className="modal-dialog--primary modal-dialog--header"
      >
        <EmployeeQRDialogHeader modal={modal} employee={employee}/>
        {/* Event Modal Body */}
        <div style={{padding: 15}}>
          {loading || !!error ? (
              <>
                {loading && (
                    <div className="panel__refresh position-relative" style={{height: 75}}>
                      <LoadingIcon/>
                    </div>
                )}
                <div className="text-center">
                  {error && (
                      <div>
                        <span className="lnr lnr-cross-circle modal__title-icon error"/>
                      </div>
                  )}
                  {loading ? 'Generating QR Code...' : error}
                </div>
              </>
          ) : (
              <div className="table-wrapper text-center">
                <QRCode value={`${process.env.REACT_APP_VCARD_URL}/${employee.slug}`} logoImage={LogoImage} fgColor={'#70bbfd'} logoHeight={30}
                        logoWidth={30}/>
              </div>
          )}
        </div>
      </Modal>
  );
};

export default connect((state) => ({
  rtl: state.rtl,
}))(EmployeeQRDialog);
