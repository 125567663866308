import { createAction } from 'redux-actions';

export const toggleCheckedItem = createAction('CADS_AI_ASSIGNED_TOGGLE_CHECKED_ITEM');
export const uncheckAllItem = createAction('CADS_AI_ASSIGNED_UNCHECK_ALL_ITEM');
export const setAssignedActiveTab = createAction('CADS_AI_SET_ASSIGNED_ACTIVE_TAB');

export const toggleCheckedItemById = (itemId) => (dispatch) => {
  dispatch(toggleCheckedItem(itemId));
};

export const uncheckAllItems = () => (dispatch) => {
  dispatch(uncheckAllItem());
};

export const setActiveTab = (tab) => (dispatch) => {
  dispatch(setAssignedActiveTab(tab));
  dispatch(uncheckAllItem());
};
