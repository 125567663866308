import { httpMethod } from '@/shared/helpers/httpMethod.enum';
import { sendRequest } from '../base/ApiService';

const CADS_AI_COHORT_API =
  process.env.REACT_APP_CADS_AI_API + process.env.REACT_APP_CADS_AI_LEARNING_PATH_ENDPOINT;

const cohortEndpoints = {
  GET_ALL_COHORTS: '/getAllCohortsOfOrg',
  GET_COHORTS_OPTIONS: '/getCohortOptions',
};

const getCadsAICohortList = (filter) => {
  return sendRequest({
    fullUrl: CADS_AI_COHORT_API + cohortEndpoints.GET_ALL_COHORTS,
    method: httpMethod.GET,
    params: filter,
  });
};

const getCohortOptions = (id) => {
  return sendRequest({
    fullUrl: CADS_AI_COHORT_API + cohortEndpoints.GET_COHORTS_OPTIONS,
    method: httpMethod.GET,
    params: { organizationId: id },
  });
};

const getMockCohortList = () => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [],
            data: {
              totalRecords: 3,
              records: [
                {
                  id: 1,
                  organizationId: 45,
                  title: 'Mock Cohort 1',
                  description: 'description',
                },
                {
                  id: 3,
                  organizationId: 20,
                  title: 'Mock Cohort 2',
                  description: 'description',
                },
                {
                  id: 5,
                  organizationId: 30,
                  title: 'Mock Cohort 3',
                  description: 'description',
                },
              ],
            },
          },
        }),
      500,
    );
  });
};

const getMockCohortOptions = () => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [],
            data: {
              totalRecords: 3,
              records: [
                {
                  id: 1,
                  title: 'Mock Cohort 1',
                },
                {
                  id: 3,
                  title: 'Mock Cohort 2',
                },
                {
                  id: 5,
                  title: 'Mock Cohort 3',
                },
              ],
            },
          },
        }),
      500,
    );
  });
};

export default {
  getCadsAICohortList,
  getCohortOptions,
};
