import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import ChangePasswordForm from './ChangePasswordForm';

const ChangePassword = () => {
  const { t } = useTranslation('common');

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Change password</h3>
          <h3 className="page-subhead subhead">
            You can submit details in below form to change you password
          </h3>
        </Col>
        <ChangePasswordForm />
      </Row>
    </Container>
  );
};

export default ChangePassword;
