import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import EmployeeViewDialogHeader from './EmployeeViewDialogHeader';
import api from '@/utils/apiClient';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { renderEmployeeDetail } from './helper';

const EmployeeViewDialog = ({ show, onHide, slug, rtl }) => {
  const [modal] = useState({
    isOpen: show,
    title: 'View Employees',
    hide: onHide,
  });

  const [state, setState] = useState({
    loading: true,
    employee: { },
    error: '',
  });

  useEffect(() => {
    if (slug) getEmployeeById(slug);
  }, [slug]);

  const getEmployeeById = async (slug) => {
    try {
      const { data: res, isSuccess } = await api.cadsAIEmployee.getEmployeeById(slug);
      if (slug) {
        setState({ ...state, loading: false, employee: res.data, error: '' });
      } else {
        throw new Error(res?.messages?.[0] ?? 'Error while getting employee details');
      }
    } catch ({ message }) {
      setState({ ...state, loading: false, employee: {}, error: message });
      console.error(message);
    }
  };

  const { error, loading, employee } = state;

  return (
    <Modal
      isOpen={show}
      toggle={modal.hide}
      modalClassName={`${rtl.direction}-support`}
      className="modal-dialog--primary modal-dialog--header modal-view-dialog"
    >
      <EmployeeViewDialogHeader modal={modal} employee={employee} />
      {/* Event Modal Body */}
      <div style={{ padding: 15 }}>
        {loading || !!error ? (
          <>
            {loading && (
              <div className="panel__refresh position-relative" style={{ height: 75 }}>
                <LoadingIcon />
              </div>
            )}
            <div className="text-center">
              {error && (
                <div>
                  <span className="lnr lnr-cross-circle modal__title-icon error" />
                </div>
              )}
              {loading ? 'Getting employee details...' : error}
            </div>
          </>
        ) : (
          <div className="table-wrapper">
            <table className="event-summary__info">
              {renderEmployeeDetail(employee).map(item => 
               <tr>
               <th className="w-50">{item.label}</th>
               <td>{item?.name}</td>
             </tr>
                )}
            </table>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default connect((state) => ({
  rtl: state.rtl,
}))(EmployeeViewDialog);
