import React, { memo } from 'react';
import FormField from '@/shared/components/form/hook/FormField';
import { SelectField } from '@/shared/components/form/Select';
import { Button } from 'reactstrap';
import { questionTypeOptions } from '../options';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { deleteConfigPageQuestion, updateConfigPageQuestionDetails } from '../redux/actions';
import { CheckBoxField } from '@/shared/components/form/CheckBox';
import QuestionTypes from '@/containers/Admin/Assessments/components/assessment-config-card/general-assessment-config/pages/question-types';
import Collapse from '@/shared/components/Collapse';
import ReactQuill from 'react-quill';
import { toolbarOptions } from '@/shared/helpers/constants';

const QuestionCollapse = ({
  position,
  pageId,
  id: questionId,
  questionText,
  questionType,
  skipAllowed,
  collapse,
  ...extras
}) => {
  const dispatch = useDispatch();
  const { control, errors, setValue } = useForm();
  React.useEffect(() => {
    setValue('questionType', questionType);
    setValue('skipAllowed', skipAllowed);
  }, [pageId]);

  const handleChange = (fieldName, fieldValue) => {
    setValue(fieldName, fieldValue);
    dispatch(
      updateConfigPageQuestionDetails({ pageId, questionId, data: { [fieldName]: fieldValue } }),
    );
  };

  const deleteQuestionFromPage = () => {
    dispatch(deleteConfigPageQuestion({ pageId, questionId }));
  };

  return (
    <Collapse title={`Question ${position}`} defaultCollapse={collapse}>
      <div className="form">
        <div className="form__form-group w-75 pr-2">
          <span className="form__form-group-label">Question</span>
          <div className="form__form-group-field shorter-text-editor">
            <ReactQuill
              key={pageId + '-' + questionId}
              value={questionText}
              modules={toolbarOptions}
              onChange={(questionText) => handleChange('questionText', questionText)}
            />
          </div>
        </div>
        <div className="form__form-group w-25 pl-2" style={{ minWidth: 200 }}>
          <span className="form__form-group-label">Question Type</span>
          <div className="form__form-group-field">
            <FormField
              control={control}
              name="questionType"
              component={SelectField}
              options={questionTypeOptions}
              onChange={(questionType) => handleChange('questionType', questionType)}
              placeholder="Select Question Type"
              rules={{ required: 'This is required field' }}
              errors={errors}
              defaultValue={questionType}
              isAboveError
            />
          </div>
          <div className="form__form-group-field mt-2">
            <CheckBoxField
              className="colored-click"
              onChange={() => handleChange('skipAllowed', !skipAllowed)}
              checked={skipAllowed}
              name={`skipAllowed-${questionId}`}
              label={'Skip Allowed ?'}
            />
          </div>
          <div className="form__form-group-field justify-content-center">
            <Button color="danger mt-3" onClick={deleteQuestionFromPage} outline>
              Delete
            </Button>
          </div>
        </div>
      </div>
      <QuestionTypes
        questionType={questionType}
        pageId={pageId}
        questionId={questionId}
        {...extras}
      />
    </Collapse>
  );
};

export default memo(QuestionCollapse);
