import React, { Fragment } from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Layout from '@/containers/App/Layout/index';
import Dashboard from '@/containers/Admin/Dashboard';
import Customers from '@/containers/Admin/Customers';
import Assessments from '@/containers/Admin/Assessments';
import SkillBank from '@/containers/Admin/SkillBank';
import { paths } from '@/shared/helpers/paths';
import ControlPanel from '@/containers/Control-Panel';
import Assignments from '@/containers/Admin/Assignments';
import CadsAI from '@/containers/Cads-AI';
import DataStar from '@/containers/Data-Star';
import Campaigns from '@/containers/Campaign';

export default ({ props }) => (
  <Fragment>
    <Layout />
    <div className="container__wrap">
      <Switch>
        <PrivateRoute exact path={paths.DASHBOARD} component={Dashboard} />
        <PrivateRoute path={paths.CUSTOMER} component={Customers} />
        <PrivateRoute path={paths.CADS_AI.BASE} component={CadsAI} />
        <PrivateRoute path={paths.ASSESSMENTS.BASE} component={Assessments} />
        <PrivateRoute path={paths.ASSIGNMENTS.BASE} component={Assignments} />
        <PrivateRoute path={paths.SKILL_BANK.BASE} component={SkillBank} />
        <PrivateRoute path={paths.CONTROL_PANEL.BASE} component={ControlPanel} />
        <PrivateRoute path={paths.CAMPAIGN.BASE} component={Campaigns} />
        <PrivateRoute path={paths.DATA_STAR.BASE} component={DataStar} />
      </Switch>
    </div>
  </Fragment>
);
