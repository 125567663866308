import { handleActions } from 'redux-actions';
import {
  setUserFetching,
  setAssignUserList,
  setUserError,
  setUserFilters,
  setUserState,
} from './actions';
import { CP_PRODUCTS } from '@/shared/helpers/constants';

export const defaultUserState = {
  loading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    isSortOrderDescending: false,
  },
  searchAssign: {
    searchText: '',
    email: '',
    orgId: '',
  },
  searchAssigned: {
    searchText: '',
    email: '',
    orgId: '',
  },
  totalRecords: 0,
  assignUsers: [],
  assigned: false,
  feature: CP_PRODUCTS.ACCELTIC.id,
  error: null,
};

export default handleActions(
  {
    [setUserFetching](state) {
      return {
        ...state,
        assignUsers: [],
        totalRecords: 0,
        loading: true,
        error: null,
      };
    },
    [setUserError](state, { payload }) {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    },
    [setAssignUserList](state, { payload }) {
      let assignUserList;
      if (payload.isFromLP) {
        assignUserList = {
          records: payload?.data,
          totalRecords: payload?.totalElements,
        };
      } else if (payload.isFromTP) {
        assignUserList = {
          records: payload?.data,
          totalRecords: payload?.totalElements,
        };
      } else {
        assignUserList = payload.data;
      }
      return {
        ...state,
        assignUsers: assignUserList?.records,
        totalRecords: assignUserList?.totalRecords,
        loading: false,
        error: null,
      };
    },
    [setUserFilters](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
      };
    },
    [setUserState](state, { payload }) {
      return payload;
    },
  },
  defaultUserState,
);
