import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Card, Row, CardBody } from 'reactstrap';
import StepOne from './steps/StepOne';
import StepTwo from './steps/StepTwo';
import StepThree from './steps/StepThree';
import StepFour from './steps/StepFour';
import { useEffect } from 'react';

export function getStepFields(page) {
  switch (page) {
    case 1:
      return ['title', 'description', 'jobLevel', 'jobType', 'organization'];
    case 2:
      return ['salaryBegin', 'salaryEnd', 'currency', 'terms', 'isSalaryNegotiable'];
    case 3:
      return ['location', 'locationStatus', 'country', 'industry'];
    case 4:
      return [
        'requiredCgpa',
        'educationAttainment',
        'isSmartSkillsRequired',
        'requiredSkills',
        'otherSkills',
      ];
    default:
      return [];
  }
}

export function extractFieldData(page, data) {
  const obj = {};
  getStepFields(page).forEach((key) => (obj[key] = data[key]));
  return obj;
}

const JobWizardForm = ({ onSubmit, data, setData, initialValues, id, state }) => {
  const [page, setPage] = useState(1);
  const [isSubmitData, setIsSubmitData] = useState(false);

  const nextPage = (newData) => {
    setData({ ...data, ...newData });
    setPage(page + 1);
  };

  const previousPage = (newData) => {
    setData({ ...data, ...newData });
    setPage(page - 1);
  };

  const clearForm = () => {
    setData(initialValues);
    setPage(1);
  };

  const submitHandler = (newData) => {
    const dataToSubmit = { ...data, ...newData };
    setData(dataToSubmit);
    setIsSubmitData(true);
  };

  useEffect(() => {
    if (isSubmitData) {
      onSubmit(data);
    }
  }, [isSubmitData]);

  return (
    <Row>
      <Col md={12} lg={12}>
        <Card className="pb-0">
          <CardBody className="wizard">
            <div className="wizard__steps">
              <div className={`wizard__step${page === 1 ? ' wizard__step--active' : ''}`}>
                <p>Basic</p>
              </div>
              <div className={`wizard__step${page === 2 ? ' wizard__step--active' : ''}`}>
                <p>Salary</p>
              </div>
              <div className={`wizard__step${page === 3 ? ' wizard__step--active' : ''}`}>
                <p>Location</p>
              </div>
              <div className={`wizard__step${page === 4 ? ' wizard__step--active' : ''}`}>
                <p>Education & Skills</p>
              </div>
            </div>
            <div className="wizard__form-wrapper">
              {page === 1 && <StepOne page={page} onSubmit={nextPage} data={data} id={id} />}
              {page === 2 && (
                <StepTwo
                  page={page}
                  previousPage={previousPage}
                  onSubmit={nextPage}
                  data={data}
                  onClear={clearForm}
                />
              )}
              {page === 3 && (
                <StepThree
                  page={page}
                  previousPage={previousPage}
                  onSubmit={nextPage}
                  data={data}
                />
              )}
              {page === 4 && (
                <StepFour
                  page={page}
                  previousPage={previousPage}
                  onSubmit={submitHandler}
                  data={data}
                  onClear={clearForm}
                  state={state}
                />
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

JobWizardForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default JobWizardForm;
