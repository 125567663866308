import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, ButtonToolbar } from 'reactstrap';
import api from '@/utils/apiClient';
import { emptyStringPattern, YesNoOptions } from '@/shared/helpers';
import { FormField } from '@/shared/components/form/hook/FormField';
import { extractFieldData, getStepFields } from '../index';
import { SelectField } from '@/shared/components/form/Select';

const StepOne = ({ onSubmit, page, data }) => {
  //states
  const [moduleTypeOptions, setModuleTypeOptions] = React.useState([]);
  const [dataSkillOptions, setDataSkillOptions] = React.useState([]);
  const [dataOtherSkillOptions, setDataOtherSkillOptions] = React.useState([]);

  const getDataSkillOptions = async () => {
    const { data: res, isSuccess } = await api.cadsAILearningPathApi.getCadsAiLearningPathSkills();
    if (isSuccess) {
      setDataSkillOptions(res.data.map((skill) => ({ value: skill.id, label: skill.title })));
    }
  };

  const getOtherDataSkillOptions = async () => {
    const { data: res, isSuccess } = await api.cadsAILearningPathApi.getLearningPathOtherSkills();
    if (isSuccess) {
      setDataOtherSkillOptions(res.data.map((skill) => ({ value: skill.id, label: skill.name })));
    }
  };

  const getModuleTypeOptions = async () => {
    const { data: res, isSuccess } = await api.cadsAICourse.getCadsAiCoursesSkills();
    if (isSuccess) {
      setModuleTypeOptions(res.data.map((type) => ({ value: type.id, label: type.title })));
    }
  };

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm(extractFieldData(page, data));

  React.useEffect(() => {
    getModuleTypeOptions();
    getDataSkillOptions();
    getOtherDataSkillOptions();
  }, []);

  React.useEffect(() => {
    getStepFields(page).forEach((key) => {
      setValue(key, data[key]);
    });
  }, [data]);

  return (
    <div className="modal__body add-edit-learning-path">
      <form className={`form form--horizontal`} onSubmit={handleSubmit(onSubmit)}>
        <div className="form__form-group">
          <span className="form__form-group-label">Name</span>
          <div className="form__form-group-field">
            <FormField
              name="title"
              control={control}
              component="input"
              errors={errors}
              rules={{
                required: 'This is required field',
                pattern: emptyStringPattern,
              }}
              defaultValue=""
              isAboveError={true}
              placeholder="Name of module"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Description</span>
          <div className="form__form-group-field">
            <FormField
              name="description"
              control={control}
              component="textarea"
              errors={errors}
              rules={{
                required: 'This is required field',
                pattern: emptyStringPattern,
              }}
              defaultValue=""
              isAboveError={true}
              placeholder="Write description about module"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Summary</span>
          <div className="form__form-group-field">
            <FormField
              name="summary"
              control={control}
              component="textarea"
              placeholder="Write summary about module"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Code</span>
          <div className="form__form-group-field">
            <FormField
              name="code"
              control={control}
              component="input"
              defaultValue=""
              placeholder="Enter module code"
            />
          </div>
        </div>
        <div className="form__form-group mt-2">
          <span className="form__form-group-label">Module Type</span>
          <div className="form__form-group-field">
            <FormField
              control={control}
              name="moduleType"
              errors={errors}
              component={SelectField}
              isAboveError={true}
              rules={{ required: 'This is required field' }}
              options={moduleTypeOptions}
              placeholder="Select module type"
            />
          </div>
        </div>
        <div className="form__form-group mt-3">
          <span className="form__form-group-label">Is platform wide</span>
          <div className="form__form-group-field">
            <FormField
              control={control}
              name="isPlatformWide"
              component={SelectField}
              options={YesNoOptions}
              defaultValue={YesNoOptions[0]}
            />
          </div>
        </div>
        <div className="form__form-group mt-3">
          <span className="form__form-group-label">Data Skills</span>
          <div className="form__form-group-field">
            <FormField
              control={control}
              name="dataSkills"
              component={SelectField}
              isMulti
              options={dataSkillOptions}
              placeholder="Select data skills"
              rules={{ required: 'This is required field' }}
              errors={errors}
              isAboveError
            />
          </div>
        </div>
        <div className="form__form-group mt-3">
          <span className="form__form-group-label">Data Other Skills</span>
          <div className="form__form-group-field">
            <FormField
              control={control}
              name="dataOtherSkills"
              component={SelectField}
              isMulti
              options={dataOtherSkillOptions}
              placeholder="Select data other skills"
            />
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar modal__footer">
          <Button color="primary" type="submit">
            Next
          </Button>
        </ButtonToolbar>
      </form>
    </div>
  );
};

export default StepOne;
