import React from 'react';
import Panel from '@/shared/components/Panel';
import moment from 'moment';
import { calulateYearsAndMonths } from '@/shared/helpers';

const educationDetails = [
  {
    title: 'Bachelor',
    university: 'ABC',
    description:
      'While creative writing does not necessarily follow the traditional paragraph structure, it’s more about scene building and continuing a narrative. Efficient, well-written paragraphs are a staple of good flash fiction and short fiction writing, as short stories need to stay more focused on a central idea. ',
    city: 'singapore',
    startDate: '2019-05-01T00:00:00',
    endDate: '2019-07-03T00:00:00',
  },
  {
    title: 'Bachelor',
    university: 'ABC',
    description:
      'While creative writing does not necessarily follow the traditional paragraph structure, it’s more about scene building and continuing a narrative. Efficient, well-written paragraphs are a staple of good flash fiction and short fiction writing, as short stories need to stay more focused on a central idea. ',
    city: 'singapore',
    startDate: '2005-01-01T00:00:00',
    endDate: '2006-12-03T00:00:00',
  },
  {
    title: 'Bachelor',
    university: 'ABC',
    description:
      'While creative writing does not necessarily follow the traditional paragraph structure, it’s more about scene building and continuing a narrative. Efficient, well-written paragraphs are a staple of good flash fiction and short fiction writing, as short stories need to stay more focused on a central idea. ',
    city: 'singapore',
    startDate: '2020-06-01T00:00:00',
    endDate: '2024-07-03T00:00:00',
  },
];

export const EducationDetails = () => {
  return (
    <div className="pt-5 applicant_panel_card">
      <h4 className="pl-4 mb-4">Education Details</h4>
      {educationDetails?.map?.((exp, idx) => (
        <Panel title={exp.title} key={idx}>
          <div className="d-flex">
            <div className="img_thumb">
              <img src="https://cdn0.iconfinder.com/data/icons/education-71/92/icon15-93-512.png" />
            </div>
            <h5 className="panel_details">
              {exp?.title}
              <br />
              {exp?.university}
              <span> • </span>
              {exp?.city} {exp?.country ?? '-'}
              <span> • </span>
              {moment(exp?.startDate).format('MMMM YYYY')} -{' '}
              {moment(exp?.endDate).format('MMMM YYYY')} (
              {calulateYearsAndMonths(exp?.startDate, exp?.endDate)}) <br />
              <div className="mt-2">{exp?.description}</div>
            </h5>
          </div>
        </Panel>
      ))}
    </div>
  );
};
