import { sendRequest } from '../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const unifiedAPIServiceBase = process.env.REACT_APP_CADS_AI_API + '/Opportunity';
const industryBaseURL =
  process.env.REACT_APP_CADS_AI_API + process.env.REACT_APP_BASE_INFO_ENDPOINT;

const jobEndpoints = {
  GET_JOBS_LIST: `${unifiedAPIServiceBase}/getAllJobsOpen`,
  GET_JOB_BY_ID: `${unifiedAPIServiceBase}/getJobDetailsById`,
  GET_ALL_INDUSTRY_LIST: '/getAllIndustry',
  ADD_JOB: `${unifiedAPIServiceBase}/createJob`,
  EDIT_JOB: `${unifiedAPIServiceBase}/editJob`,
  DELETE_JOB: `${unifiedAPIServiceBase}/deleteJob`,
};

const getJobsList = (filter) => {
  // return getMockJobsList();
  return sendRequest({
    fullUrl: jobEndpoints.GET_JOBS_LIST,
    method: httpMethod.GET,
    params: filter,
  });
};

const getJobById = (jobId) => {
  // return getMockJobById(jobId);
  return sendRequest({
    fullUrl: jobEndpoints.GET_JOB_BY_ID,
    method: httpMethod.GET,
    params: { id: jobId },
  });
};

const getIndustryList = () => {
  return sendRequest({
    fullUrl: industryBaseURL + jobEndpoints.GET_ALL_INDUSTRY_LIST,
    method: httpMethod.GET,
  });
};

const getJobStageOptions = () => {
  //TODO: to create API for this in BE
  return getMockJobStageOptions();
  return sendRequest({
    fullUrl: jobEndpoints.GET_JOB_BY_ID,
    method: httpMethod.GET,
  });
};

const getJobLevelOptions = () => {
  //TODO: to create API for this in BE
  return getMockJobLevelOptions();
  return sendRequest({
    fullUrl: jobEndpoints.GET_JOB_BY_ID,
    method: httpMethod.GET,
  });
};

const getJobCurrencyOptions = () => {
  //TODO: to create API for this in BE
  return getMockJobCurrencyOptions();
  return sendRequest({
    fullUrl: jobEndpoints.GET_JOB_BY_ID,
    method: httpMethod.GET,
  });
};

const getJobIndustryOptions = () => {
  //TODO: to create API for this in BE
  return getMockJobIndustryOptions();
  return sendRequest({
    fullUrl: jobEndpoints.GET_JOB_BY_ID,
    method: httpMethod.GET,
  });
};

const getEducationAttainmentOptions = () => {
  //TODO: to create API for this in BE
  return getMockEducationAttainmentOptions();
  return sendRequest({
    fullUrl: jobEndpoints.GET_JOB_BY_ID,
    method: httpMethod.GET,
  });
};

const getJobTypeOptions = () => {
  //TODO: to create API for this in BE
  return getMockJobTypeOptions();
  return sendRequest({
    fullUrl: jobEndpoints.GET_JOB_BY_ID,
    method: httpMethod.GET,
  });
};

const addJob = (job) => {
  return sendRequest({
    fullUrl: jobEndpoints.ADD_JOB,
    method: httpMethod.POST,
    data: job,
  });
};

const editJob = (job) => {
  return sendRequest({
    fullUrl: jobEndpoints.EDIT_JOB,
    method: httpMethod.PUT,
    data: job,
  });
};

const deleteJob = (jobId) => {
  return sendRequest({
    fullUrl: jobEndpoints.DELETE_JOB,
    method: httpMethod.DELETE,
    params: { jobId: jobId },
  });
};

const getMockJobsList = () =>
  new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [''],
            data: {
              totalRecords: 2,
              currentPage: 1,
              pageSize: 10,
              records: [
                {
                  id: 1,
                  jobImgUrl: 'https://unified.dev.cads.ai/image/sample/samsung-logo.png',
                  title: 'Mock Job 1',
                  companyName: 'Samsung',
                  location: 'Tokyo, Japan',
                  postedBy: 'Terry Shaw',
                  postedDate: '2022-05-01T00:00:00',
                },
                {
                  id: 2,
                  jobImgUrl: 'https://unified.dev.cads.ai/image/sample/samsung-logo.png',
                  title: 'Mock Job 2',
                  companyName: 'Samsung',
                  location: 'Tokyo, Japan',
                  postedBy: 'Kacey Turner',
                  postedDate: '2022-05-05T00:00:00',
                },
              ],
            },
          },
        }),
      500,
    );
  });

const getMockJobStageOptions = () =>
  new Promise((resolve, reject) => {
    //TODO: to create API for this in BE
    resolve({
      isSuccess: true,
      data: {
        status: 200,
        messages: [''],
        data: [
          { id: 1, title: 'Open' },
          { id: 8, title: 'Applying' },
          { id: 2, title: 'Shortlisting' },
          { id: 3, title: 'Interviewing' },
          { id: 4, title: 'Offer' },
          { id: 5, title: 'Selection' },
          { id: 6, title: 'Close' },
        ],
      },
    });
  });

const getMockJobLevelOptions = () =>
  new Promise((resolve, reject) => {
    //TODO: to create API for this in BE
    resolve({
      isSuccess: true,
      data: {
        status: 200,
        messages: [''],
        data: [
          { id: 1, title: 'C-Suit' },
          { id: 2, title: 'Middle Management' },
          { id: 3, title: 'Supervisor' },
          { id: 4, title: 'Staff' },
          { id: 5, title: 'Entry Level' },
          { id: 6, title: 'Students/Others' },
        ],
      },
    });
  });

const getMockJobTypeOptions = () =>
  new Promise((resolve, reject) => {
    //TODO: to create API for this in BE
    resolve({
      isSuccess: true,
      data: {
        status: 200,
        messages: [''],
        data: [
          { id: 1, title: 'Full Remote' },
          { id: 2, title: 'Semi Remote' },
          { id: 3, title: 'Full Time' },
        ],
      },
    });
  });

const getMockJobCurrencyOptions = () =>
  new Promise((resolve, reject) => {
    //TODO: to create API for this in BE
    resolve({
      isSuccess: true,
      data: {
        status: 200,
        messages: [''],
        data: [
          { id: 1, title: 'Ringgit' },
          { id: 2, title: 'Us Dollar' },
          { id: 3, title: 'Euro' },
          { id: 4, title: 'Singapore Dollar' },
        ],
      },
    });
  });

const getMockJobIndustryOptions = () =>
  new Promise((resolve, reject) => {
    //TODO: to create API for this in BE
    resolve({
      isSuccess: true,
      data: {
        status: 200,
        messages: [''],
        data: [
          { id: 1, title: 'Mock 1' },
          { id: 2, title: 'Mock 2' },
          { id: 3, title: 'Mock 3' },
          { id: 4, title: 'Mock 4' },
        ],
      },
    });
  });

const getMockEducationAttainmentOptions = () =>
  new Promise((resolve, reject) => {
    //TODO: to create API for this in BE
    resolve({
      isSuccess: true,
      data: {
        status: 200,
        messages: [''],
        data: [
          {
            id: 'Bachelor',
            title: 'Bachelor',
          },
          {
            id: 'Masters',
            title: 'Masters',
          },
          {
            id: 'PhD',
            title: 'PHD',
          },
        ],
      },
    });
  });

export default {
  addJob,
  editJob,
  deleteJob,
  getJobById,
  getJobsList,
  getJobStageOptions,
  getJobLevelOptions,
  getJobTypeOptions,
  getJobCurrencyOptions,
  getJobIndustryOptions,
  getEducationAttainmentOptions,
  getIndustryList,
};
