import { sendRequest } from '../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const employeeBaseUrl = process.env.REACT_APP_CADS_AI_API + '/card'

export const employeeEndpoints = {
  GET_ALL_EMPLOYEES: `${employeeBaseUrl}/getAllContacts`,
  GET_EMPLOYEES_BY_ID: `${employeeBaseUrl}/data`,
  DELETE_EMPLOYEE: `${employeeBaseUrl}/deleteContact`,
  ADD_EMPLOYEE: `${employeeBaseUrl}/insertContact`,
  EDIT_EMPLOYEE: `${employeeBaseUrl}/editContact`,
};

const getEmployeeList = (filter) => {
  // return getMockEmployeeList();
  return sendRequest({
    fullUrl: employeeEndpoints.GET_ALL_EMPLOYEES,
    method: httpMethod.GET,
    params: filter,
  });
};

const deleteEmployee = (id) => {
  // return deleteMockEmployee();
  return sendRequest({
    fullUrl: employeeEndpoints.DELETE_EMPLOYEE,
    method: httpMethod.DELETE,
    params: {id: +id},
  });
};

const addEmployee = (data) => {
  // return addMockEmployee();
  return sendRequest({
    fullUrl: employeeEndpoints.ADD_EMPLOYEE,
    method: httpMethod.POST,
    data: data,
  });
};

const editEmployee = (data) => {
  // return editMockEmployee();
  return sendRequest({
    fullUrl: employeeEndpoints.EDIT_EMPLOYEE,
    method: httpMethod.PUT,
    data: data,
  });
};

const getEmployeeById = (slug) => {
  // return getMockEmployeeById();
  return sendRequest({
    fullUrl: `${employeeEndpoints.GET_EMPLOYEES_BY_ID}/${slug}`,
    method: httpMethod.GET,
    // params: {slug: slug},
  });
};

const getMockEmployeeById = () => {
  return new Promise((resolve, reject) => {
    setTimeout(
        () =>
            resolve({
              isSuccess: true,
              data: {
                status: 200,
                messages: [],
                data:  {
                  email: "sharala@thecads.com",
                  faceBook: "https://www.facebook.com/shaaxryd/",
                  firstName: "Sharala",
                  instagram: "https://www.instagram.com/shaaxryd/?hl=en",
                  lastName: "Axryd",
                  linkedIn: null,
                  mobile: "+6 012 721 27 42",
                  organizationLevel: "Founder & Chief Executive Officer",
                  phone: "+603 2732 2751",
                  photoUrl: "https://unified.cads.ai/image/vCard/sharala_axyrd.jpg",
                  webSite: "https://www.cads.ai",
                  youTube: "https://www.youtube.com/watch?v=O_8wfocf0PI"
                },
              },
            }),
        500,
    );
  });
};

const getMockEmployeeList = () => {
  return new Promise((resolve, reject) => {
    setTimeout(
        () =>
            resolve({
              isSuccess: true,
              data: {
                status: 200,
                messages: [],
                data: {
                  totalRecords: 3,
                  records: [
                    {
                      id: 1,
                      email: "sharala@thecads.com",
                      faceBook: "https://www.facebook.com/shaaxryd/",
                      firstName: "Sharala",
                      instagram: "https://www.instagram.com/shaaxryd/?hl=en",
                      lastName: "Axryd",
                      linkedIn: null,
                      mobile: "+6 012 721 27 42",
                      organizationLevel: "Founder & Chief Executive Officer",
                      phone: "+603 2732 2751",
                      photoUrl: "https://unified.cads.ai/image/vCard/sharala_axyrd.jpg",
                      webSite: "https://www.cads.ai",
                      youTube: "https://www.youtube.com/watch?v=O_8wfocf0PI"
                    },
                    {
                      id: 2,
                      email: "sharala@thecads.com",
                      faceBook: "https://www.facebook.com/shaaxryd/",
                      firstName: "Sharala",
                      instagram: "https://www.instagram.com/shaaxryd/?hl=en",
                      lastName: "Axryd",
                      linkedIn: null,
                      mobile: "+6 012 721 27 42",
                      organizationLevel: "Founder & Chief Executive Officer",
                      phone: "+603 2732 2751",
                      photoUrl: "https://unified.cads.ai/image/vCard/sharala_axyrd.jpg",
                      webSite: "https://www.cads.ai",
                      youTube: "https://www.youtube.com/watch?v=O_8wfocf0PI"
                    }, {
                      id: 3,
                      email: "sharala@thecads.com",
                      faceBook: "https://www.facebook.com/shaaxryd/",
                      firstName: "Sharala",
                      instagram: "https://www.instagram.com/shaaxryd/?hl=en",
                      lastName: "Axryd",
                      linkedIn: null,
                      mobile: "+6 012 721 27 42",
                      organizationLevel: "Founder & Chief Executive Officer",
                      phone: "+603 2732 2751",
                      photoUrl: "https://unified.cads.ai/image/vCard/sharala_axyrd.jpg",
                      webSite: "https://www.cads.ai",
                      youTube: "https://www.youtube.com/watch?v=O_8wfocf0PI"
                    },
                  ],
                },
              },
            }),
        500,
    );
  });
};

const deleteMockEmployee = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          status: 200,
          messages: ['Employee deleted successfully'],
        },
      });
    }, 500);
  });
};

const addMockEmployee = (data) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          status: 200,
          messages: ['Employee added successfully'],
        },
      });
    }, 500);
  });
};

const editMockEmployee = (data) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          status: 200,
          messages: ['Employee updated successfully'],
        },
      });
    }, 500);
  });
};

export default {
  getEmployeeList,
  getEmployeeById,
  deleteEmployee,
  addEmployee,
  editEmployee
}
