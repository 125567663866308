import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import UsersTableWrapper from './table/UsersTableWrapper';
import { Route, Switch } from 'react-router-dom';
import { cadsAiUserBaseUrl } from '../index';
import UserView from './users-config/UserView';

export function UsersCard() {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Users</h3>
        </Col>
      </Row>
      <Row>
        <Switch>
          <Route path={`${cadsAiUserBaseUrl}/:id/view`}>
            {({ history, match }) => (
              <div className="users-view-page w-100">
                <UserView
                  show={match != null}
                  id={match?.params?.id ? +match?.params?.id : ''}
                  onHide={() => {
                    history.push(cadsAiUserBaseUrl);
                  }}
                />
              </div>
            )}
          </Route>
          <Route path="">
            <UsersTableWrapper />
          </Route>
        </Switch>
      </Row>
    </Container>
  );
}
