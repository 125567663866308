import React, { useState } from 'react';
import { connect, shallowEqual, useSelector } from 'react-redux';
import { Modal } from 'reactstrap';
import ApplicantViewDialogHeader from './ApplicantViewDialogHeader';
import ApplicantViewDetails from './ApplicantViewDetails';
import LoadingIcon from 'mdi-react/LoadingIcon';

const ApplicantViewDialog = ({ show, onHide, id, rtl }) => {
  const [modal, setModal] = useState({
    isOpen: show,
    title: 'View Applicant',
    hide: onHide,
  });

  const [state, setState] = React.useState({
    loading: false,
    applicant: {
      name: 'Test Applicant 1',
      description: 'any',
    },
    error: '',
  });

  const { data: applicants } = useSelector((state) => state.applicants, shallowEqual);

  React.useEffect(() => {
    if (id) getApplicantById(+id);
  }, [id]);

  const getApplicant = async (applicantId) => {
    return applicants.filter((applicant) => applicant.id == applicantId);
  };

  const getApplicantById = async (applicantId) => {
    try {
      setState({ ...state, loading: true });
      const data = await getApplicant(applicantId);
      setState({ ...state, loading: false, applicant: data, error: '' });
    } catch ({ message }) {
      setState({ ...state, loading: false, applicant: {}, error: message });
      console.error(message);
    }
  };

  const { error, loading, applicant } = state;

  return (
    <Modal
      isOpen={show}
      toggle={modal.hide}
      modalClassName={`${rtl.direction}-support`}
      className="modal-dialog--primary modal-dialog--header modal-view-dialog"
    >
      <ApplicantViewDialogHeader modal={modal} applicant={applicant} />
      {/*Applicant Modal Body*/}
      <div style={{ padding: 15 }}>
        {loading || !!error ? (
          <>
            {loading && (
              <div className="panel__refresh position-relative" style={{ height: 75 }}>
                <LoadingIcon />
              </div>
            )}
            <div className="text-center">
              {error && (
                <div>
                  <span className="lnr lnr-cross-circle modal__title-icon error" />
                </div>
              )}
              {loading ? 'Getting applicant details...' : error}
            </div>
          </>
        ) : (
          <ApplicantViewDetails applicant={state.applicant[0]} />
        )}
      </div>
    </Modal>
  );
};

export default connect((state) => ({
  rtl: state.rtl,
}))(ApplicantViewDialog);
