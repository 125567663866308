import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loading from '@/shared/components/Loading';
import { paths } from '@/shared/helpers/paths';
import { CustomersPage } from './components/CustomerPage';

export const customersBaseUrl = paths.CUSTOMER;

export default function Customers() {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path={customersBaseUrl} component={CustomersPage} />
      </Switch>
    </Suspense>
  );
}
