import { handleActions } from 'redux-actions';
import {
  fetchEventList,
  setEventList,
  setEventError,
  setEventFilters,
  toggleCheckedEvent,
  uncheckAllEvent,
} from './actions';

const defaultState = {
  loading: false,
  checkedEventsIds: [],
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    search: '',
    isSortOrderDescending: false,
    status: null,
  },
  totalRecords: 0,
  data: [],
  options: [],
  error: null,
};

export default handleActions(
  {
    [fetchEventList](state) {
      return {
        ...state,
        loading: true,
        data: [],
      };
    },
    [setEventList](state, { payload }) {
      return {
        ...state,
        loading: false,
        data: payload.data.records,
        totalRecords: payload.data.totalRecords,
        error: null,
      };
    },
    [setEventError](state, { payload }) {
      return {
        ...state,
        data: [],
        loading: false,
        totalPages: defaultState.totalPages,
        error: payload?.message,
      };
    },
    [setEventFilters](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
        checkedEventsIds: [],
      };
    },
    [toggleCheckedEvent](state, { payload: eventId }) {
      const checkedIds = state.checkedEventsIds;
      return {
        ...state,
        checkedEventsIds: checkedIds.includes(eventId)
          ? checkedIds.filter((id) => id !== eventId)
          : [...checkedIds, eventId],
      };
    },
    [uncheckAllEvent](state) {
      return {
        ...state,
        checkedEventsIds: [],
      };
    },
  },
  defaultState,
);
