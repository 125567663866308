import React, { useRef, useState, useEffect } from 'react';
import { Button } from 'reactstrap';
// import Select from "react-select";
import TableHead from '@material-ui/core/TableHead';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setProgramFilters, setProgramStatusOptionsList } from '../../redux/actions';
import Select from 'react-select';
import api from '@/utils/apiClient';

export function ProgramsTableSearchBar({ history }) {
  const dispatch = useDispatch();
  const program = useSelector((state) => state.cadsAIPrograms, shallowEqual);
  const totalProgramCount = program?.data?.length;
  const programOptions = program?.statusOptions;

  const [isSearched, setIsSearched] = useState(false);
  const [programStatus, setProgramStatus] = useState(null);
  const [programActivationStatus, setProgramActivationStatus] = useState(null);

  const getProgramOptions = async () => {
    const { data, isSuccess } = await api.cadsAIPrograms.getProgramStatusOptions();
    if (isSuccess) {
      dispatch(setProgramStatusOptionsList(data));
    }
  };

  React.useEffect(() => {
    getProgramOptions();
  }, []);

  const searchNameRef = useRef(null);
  const handleSearch = () => {
    dispatch(
      setProgramFilters({
        searchText: searchNameRef?.current?.value,
        status: programActivationStatus?.value,
        programStatusId: programStatus?.value,
      }),
    );
  };

  // const getProgramTypeOptions = async () => {
  //   try {
  //     const { data: data, isSuccess } =
  //       await api.programs.getProgramTypeOptions();
  //     if (isSuccess) {
  //       setProgramTypeOptions([]);
  //       data.data.map((item) => {
  //         setProgramTypeOptions((programTypeOptions) => [
  //           ...programTypeOptions,
  //           { value: item.id, label: item.title },
  //         ]);
  //       });
  //     } else {
  //       throw new Error("error while fetching program type options");
  //     }
  //   } catch (error) {
  //     console.error("Error inside program type options", error.message);
  //   }
  // };

  // useEffect(() => {
  //   getProgramTypeOptions();
  // }, []);

  const handleClear = () => {
    if (searchNameRef?.current?.value) searchNameRef.current.value = '';
    setIsSearched(false);
    setProgramStatus(null);
    setProgramActivationStatus(null);
    dispatch(setProgramFilters({ searchText: '', status: null, programStatusId: null }));
  };

  return (
    <TableHead>
      <div className="table__search mt-4">
        <div className="table__search_item">
          <b>Program Name</b>
          <div className="form" style={{ width: 300 }}>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <div className="form__form-group-icon" style={{ height: 37 }}>
                  <MagnifyIcon />
                </div>
                <input
                  ref={searchNameRef}
                  className="search-bar-search-text"
                  name="searchText"
                  type="text"
                  placeholder="Search by Program Name"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table__search_item">
          <b>Program Status</b>
          <div style={{ minWidth: 200 }}>
            <Select
              name="programStatus"
              value={programStatus}
              onChange={(selectedOption) => setProgramStatus(selectedOption)}
              autosize={true}
              options={programOptions.map((p) => ({ value: p.id, label: p.title }))}
              clearable={false}
              className="react-select"
              placeholder={'Select Program Status'}
              classNamePrefix="react-select"
            />
          </div>
        </div>
        <div className="table__search_item">
          <b>Program Activation Status</b>
          <div style={{ minWidth: 240 }}>
            <Select
              name="programActivationStatus"
              value={programActivationStatus}
              onChange={(selectedOption) => setProgramActivationStatus(selectedOption)}
              autosize={true}
              options={[
                { label: 'Deactivated', value: false },
                { label: 'Activated', value: true },
              ]}
              clearable={false}
              className="react-select"
              placeholder={'Select Program Activation Status'}
              classNamePrefix="react-select"
            />
          </div>
        </div>
        <div className="table__search_item mt-3">
          <Button color="primary" onClick={handleSearch}>
            {' '}
            Search{' '}
          </Button>
        </div>
        <div className="table__search_item mt-3">
          <Button color="secondary" onClick={handleClear} outline>
            Clear
          </Button>
        </div>
      </div>

      {isSearched && (
        <div className="ml-1">
          <span>Found {totalProgramCount} matches</span>
        </div>
      )}
    </TableHead>
  );
}
