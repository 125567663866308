import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col, Modal, Row } from 'reactstrap';
import LoadingIcon from 'mdi-react/LoadingIcon';
import Select from 'react-select';
import { uncheckAllSelectedApplicants } from '../../redux/actions';
import api from '@/utils/apiClient';

export const ApplicantUpdateCohortDialog = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const { rtl, applicants } = useSelector((state) => state, shallowEqual);
  const checkedApplicantIds = applicants.checkedApplicantIds;
  const programId = applicants.filters?.programId ?? null;

  const [cohort, setCohort] = useState({
    value: null,
    label: 'Select Cohort',
  });
  const [modal, setModal] = useState({
    isOpen: show,
    hide: onHide,
    loading: false,
    updateCohortSuccess: false,
    updateCohortError: false,
  });

  const handleUpdateStatus = async () => {
    setModal({ ...modal, loading: true });

    const response = await api.applicants.updateCohortForProgramApplicants(
      checkedApplicantIds,
      programId,
      cohort.value,
    );
    let updatedModal = {};
    if (response.isSuccess) {
      updatedModal = { loading: false, updateCohortSuccess: true };
    } else {
      updatedModal = { loading: false, updateCohortError: true };
    }
    setModal({ ...modal, ...updatedModal });
  };

  const [applicantsCohortOptions, setApplicantsCohortOptions] = useState([]);

  const getProgramApplicationCohortOptions = async () => {
    try {
      if (!programId) throw new Error('Program is not selected');
      const { data: res, isSuccess } = await api.dsCohort.getCohortsByProgramId(programId);
      if (isSuccess) {
        const cohortOptions = res.data?.map((cohort) => ({
          value: cohort.id,
          label: cohort.title,
        }));
        setApplicantsCohortOptions(cohortOptions ?? []);
      } else {
        throw new Error('error while fetching applicant cohorts');
      }
    } catch (error) {
      console.error('Error inside applicant type', error.message);
    }
  };

  useEffect(() => {
    getProgramApplicationCohortOptions();
  }, []);

  return (
    <Modal
      isOpen={show}
      toggle={modal.hide}
      onExit={() => dispatch(uncheckAllSelectedApplicants())}
      keyboard={false}
      backdrop={'static'}
      modalClassName={`${rtl.direction}-support`}
      className="modal-dialog--primary modal-dialog--header"
    >
      <div className="modal__header">
        <button
          className="lnr lnr-cross modal__close-btn"
          aria-label="modal__close-btn"
          type="button"
          onClick={modal.hide}
        />
        <h4 className="text-modal  modal__title">Edit cohort</h4>
      </div>
      <div>
        <div className="ml-4">
          {modal.loading && (
            <div>
              <div className="panel__refresh position-relative" style={{ height: 75, width: 20 }}>
                <LoadingIcon />
                <div style={{ width: 200, padding: '23px 30px' }}>Updating cohort...</div>
              </div>
            </div>
          )}
          {(modal.updateCohortSuccess || !!modal.updateCohortError) && (
            <>
              <table className="mt-3">
                <tr>
                  <td>
                    {modal.updateCohortSuccess ? (
                      <span className="lnr lnr-thumbs-up modal__title-icon success" />
                    ) : (
                      <span className="lnr lnr-cross-circle modal__title-icon error" />
                    )}
                  </td>
                  <td className="pl-2">
                    {modal.updateCohortSuccess
                      ? 'Applicant Cohort Updated successfully'
                      : 'Error Performing Applicant Cohort Update!'}
                  </td>
                </tr>
              </table>
            </>
          )}
        </div>
        <Row>
          <Col md={12} lg={12}>
            <Card className="pb-0">
              <CardBody>
                <div className="form-wrapper">
                  <div className="form">
                    <div className="form__form-group">
                      <span className="form__form-group-label">Cohort</span>
                      <div className="form__form-group-field">
                        <Select
                          name="cohort"
                          value={cohort}
                          onChange={setCohort}
                          autosize={true}
                          options={applicantsCohortOptions}
                          clearable={false}
                          className="react-select"
                          placeholder={'Select Status'}
                          classNamePrefix="react-select"
                        />
                      </div>
                    </div>
                    <ButtonToolbar className="modal__footer btn-toolbar">
                      <>
                        <Button
                          color="primary"
                          type="submit"
                          onClick={handleUpdateStatus}
                          disabled={!cohort.value}
                        >
                          Submit
                        </Button>
                        <Button
                          color="secondary"
                          type="button"
                          className="modal_cancel btn btn-secondary"
                          onClick={() => modal.hide()}
                        >
                          Close
                        </Button>
                      </>
                    </ButtonToolbar>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};
