import React, { memo } from 'react';
import { emptyStringPattern } from '@/shared/helpers';
import { FormField } from '@/shared/components/form/hook/FormField';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { setConfigPageQuestionTypeData } from '../../redux/actions';

const ScaleRatingQuestion = ({ pageId, questionId, typeData }) => {
  const dispatch = useDispatch();
  const { ratingStartFrom, ratingEndAt } = typeData.scalingRatingQuestion ?? {
    ratingStartFrom: 0,
    ratingEndAt: 0,
  };
  const { control, errors, setValue } = useForm();

  const handleChange = (fieldName, fieldValue) => {
    setValue(fieldName, fieldValue);
    dispatch(
      setConfigPageQuestionTypeData({
        pageId,
        questionId,
        typeData: {
          scalingRatingQuestion: {
            ...typeData.scalingRatingQuestion,
            [fieldName]: fieldValue,
          },
        },
      }),
    );
  };

  return (
    <div className="form scaling_rating_question">
      <div className="form__form-group w-50 pr-2">
        <span className="form__form-group-label">Rating Start From</span>
        <div className="form__form-group-field">
          <FormField
            name="ratingStartFrom"
            control={control}
            component="input"
            type="number"
            min={0}
            errors={errors}
            onChange={({ target: { value } }) => handleChange('ratingStartFrom', +value)}
            rules={{
              required: 'This is required field',
              pattern: emptyStringPattern,
            }}
            defaultValue={ratingStartFrom}
            isAboveError={true}
            placeholder="Enter contact field name"
          />
        </div>
      </div>
      <div className="form__form-group w-50 pl-2">
        <span className="form__form-group-label">Rating End At</span>
        <div className="form__form-group-field">
          <FormField
            name="ratingEndAt"
            control={control}
            component="input"
            type="number"
            min={ratingStartFrom + 1}
            errors={errors}
            onChange={({ target: { value } }) => handleChange('ratingEndAt', +value)}
            rules={{
              required: 'This is required field',
              pattern: emptyStringPattern,
            }}
            defaultValue={ratingEndAt}
            isAboveError={true}
            placeholder="Enter contact field name"
          />
        </div>
      </div>
    </div>
  );
};

export default memo(ScaleRatingQuestion);
