import moment from 'moment'
import React from 'react'

const OrderDetailHeader = ({orderHistory}) => {
    return (
        <div className='container'>
            <div>
            <p>{orderHistory?.status}</p>
            <p>{orderHistory?.email}</p>
            </div>
            <div className='right-container'>
              <p> Invoice:  {orderHistory?.orderId}</p>
              <p>{orderHistory?.purchaseDate ? moment(orderHistory?.purchaseDate).format('DD/MM/yyyy') : '-'}</p>
            </div>
        </div>
    )
}

export default OrderDetailHeader