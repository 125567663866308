import { httpMethod } from '@/shared/helpers/httpMethod.enum';
import { sendRequest } from '../base/ApiService';

const CADS_API_ENDPOINT =
  process.env.REACT_APP_CADS_AI_API + process.env.REACT_APP_CADS_AI_LEARNING_PATH_ENDPOINT;

const courseEndpoints = {
  GET_ALL_COURSES: '/getAllCourses',
  GET_COURSE_BY_ID: '/getCourseById',
  ADD_COURSE: '/addCourse',
  GET_COURSE_CONTENT_TYPE_OPTIONS: '/getCourseContentTypeOptions',
  UPDATE_COURSE_BY_ID: '/updateCourseById',
  DELETE_COURSE_BY_ID: '/deleteCoursesById',
};

const moduleTypeEndpoint = {
  GET_MODULE_TYPE_OPTIONS: '/getModuleTypeOptions',
};

const deliveryMethodEndpoint = {
  GET_DELIVERY_METHOD_OPTIONS: '/getDeliveryMethodOptions',
};

const trainingProviderEndpoint = {
  GET_TRAINING_PROVIDER_OPTIONS: '/getTrainingProviderOptions',
};

const getCadsAiCourses = (filters) => {
  return sendRequest({
    fullUrl: CADS_API_ENDPOINT + courseEndpoints.GET_ALL_COURSES,
    method: httpMethod.GET,
    params: filters,
  });
};

const getAssignedCourseUsers = (filter) => {
  return getAssignedMockUsersCourse(filter);
  return sendRequest({
    url: '',
    method: httpMethod.GET,
    params: filter,
  });
};

const unAssignCourseToUsers = (reqData) => {
  return unAssignMockUserCourse(reqData);
  return sendRequest({
    url: '',
    method: httpMethod.PUT,
    data: reqData,
  });
};

const unAssignCourseToOrg = (reqData) => {
  return unAssignMockOrgCourse(reqData);
  return sendRequest({
    url: '',
    method: httpMethod.PUT,
    data: reqData,
  });
};

const getAssignedCourseOrg = (filter) => {
  return getAssignedMockOrgCourse(filter);
  return sendRequest({
    url: '',
    method: httpMethod.GET,
    params: filter,
  });
};

const getCourseById = (courseId) => {
  // return getMockCourseById(filter);
  return sendRequest({
    fullUrl: CADS_API_ENDPOINT + courseEndpoints.GET_COURSE_BY_ID,
    method: httpMethod.GET,
    params: { id: courseId },
  });
};

const getDeliveryMethodOptions = (filter) => {
  return sendRequest({
    fullUrl: CADS_API_ENDPOINT + deliveryMethodEndpoint.GET_DELIVERY_METHOD_OPTIONS,
    method: httpMethod.GET,
    params: filter,
  });
};

const getTrainingProviderOptions = (filter) => {
  return sendRequest({
    fullUrl: CADS_API_ENDPOINT + trainingProviderEndpoint.GET_TRAINING_PROVIDER_OPTIONS,
    method: httpMethod.GET,
    params: filter,
  });
};

const getCourseContentOptions = (filter) => {
  return sendRequest({
    fullUrl: CADS_API_ENDPOINT + courseEndpoints.GET_COURSE_CONTENT_TYPE_OPTIONS,
    method: httpMethod.GET,
    params: filter,
  });
};

const getCadsAiCoursesSkills = (filter) => {
  return sendRequest({
    fullUrl: CADS_API_ENDPOINT + moduleTypeEndpoint.GET_MODULE_TYPE_OPTIONS,
    method: httpMethod.GET,
    params: filter,
  });
};

const deleteCourseState = (courseIds, state) => {
  return sendRequest({
    fullUrl: CADS_API_ENDPOINT + courseEndpoints.DELETE_COURSE_BY_ID,
    method: httpMethod.DELETE,
    params: { id: courseIds },
  });
};

const getCadsAiAssignedCohortCourse = (filter) => {
  return getMockCadsAiAssignedCohortCourse(filter);
  return sendRequest({
    url: '',
    method: httpMethod.GET,
    params: filter,
  });
};

const unAssignCourseToCohort = (reqData) => {
  return unAssignMockCohortCourse(reqData);
  return sendRequest({
    url: '',
    method: httpMethod.PUT,
    data: data,
  });
};

const cadsAIAddCourse = (courseData) => {
  return sendRequest({
    fullUrl: CADS_API_ENDPOINT + courseEndpoints.ADD_COURSE,
    method: httpMethod.POST,
    data: courseData,
  });
};

const cadsAIEditCourse = (courseData) => {
  return sendRequest({
    fullUrl: CADS_API_ENDPOINT + courseEndpoints.UPDATE_COURSE_BY_ID,
    method: httpMethod.PUT,
    data: courseData,
  });
};

const getMockCadsAiCourses = () => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [],
            data: {
              totalRecords: 3,
              records: [
                {
                  id: 1,
                  contentTypeId: 4,
                  courseLink: 'test',
                  deliveryMethodId: 6,
                  description: 'description',
                  durationInMinutes: '59',
                  module: { id: 5, label: 'python' },
                  endDate: '2022-04-03',
                  isForSale: false,
                  isMainVariant: true,
                  isMeeting: true,
                  isPlatformWide: true,
                  isProvidedByCads: null,
                  isVerified: null,
                  materialsUrl: 'test',
                  moduleId: 6,
                  participantsMaterialLink: 'any',
                  saleDiscount: '5',
                  saleFinalPrice: '95',
                  salePrice: '20',
                  startDate: '2022-03-21',
                  summary: 'summary',
                  title: 'test',
                },
                {
                  id: 2,
                  contentTypeId: 4,
                  courseLink: 'test',
                  deliveryMethodId: 6,
                  description: 'description',
                  durationInMinutes: '59',
                  endDate: '2022-04-03',
                  isForSale: false,
                  module: { id: 5, label: 'python' },
                  isMainVariant: true,
                  isMeeting: true,
                  isPlatformWide: true,
                  isProvidedByCads: null,
                  isVerified: null,
                  materialsUrl: 'test',
                  moduleId: 6,
                  participantsMaterialLink: 'any',
                  saleDiscount: '5',
                  saleFinalPrice: '95',
                  salePrice: '20',
                  startDate: '2022-03-21',
                  summary: 'summary',
                  title: 'test 2 ',
                },
              ],
            },
          },
        }),
      500,
    );
  });
};

const unAssignMockUserCourse = (responseData) => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          body: responseData,
        }),
      500,
    );
  });
};

const unAssignMockCohortCourse = (responseData) => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          body: responseData,
        }),
      500,
    );
  });
};

const unAssignMockOrgCourse = (responseData) => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          body: responseData,
        }),
      500,
    );
  });
};

const getCadsAIMockSkills = () => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [],
            data: {
              totalRecords: 5,
              records: [
                {
                  id: 43,
                  name: 'Linear Algebra',
                  parentSkill: null,
                  isCoreSkill: null,
                  description: null,
                },
                {
                  id: 44,
                  name: 'Basic Calculus',
                  parentSkill: null,
                  isCoreSkill: null,
                  description: null,
                },
                {
                  id: 11,
                  name: 'ETL',
                  parentSkill: null,
                  isCoreSkill: true,
                  description: null,
                },
                {
                  id: 10,
                  name: 'Spark',
                  parentSkill: null,
                  isCoreSkill: true,
                  description: null,
                },
                {
                  id: 6,
                  name: 'AB Testing',
                  parentSkill: null,
                  isCoreSkill: true,
                  description: null,
                },
              ],
            },
          },
        }),
      500,
    );
  });
};

const getAssignedMockUsersCourse = () => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [],
            data: {
              totalRecords: 2,
              records: [
                {
                  email: 'mock-dnescobar.rubiqe@yopmail.com',
                  jobseekerId: 1223,
                  moduleRecoms: [
                    {
                      allowRecommendation: true,
                      module: {
                        clientModuleId: 0,
                        id: 76,
                        imageUrl:
                            'https://general-assets.sgp1.digitaloceanspaces.com/learning-path-images/SWE/SWE-10%20TypeScript.png',
                        name: 'Learning TypeScript',
                        tagline: null,
                        moduleRecomId: 104,
                      },
                    },
                  ],
                  picureUrl: null,
                  userFullName: 'Mock user',
                },
                {
                  email: 'mock-dnescobar.rubiqe@yopmail.com',
                  jobseekerId: 123,
                  moduleRecoms: [
                    {
                      allowRecommendation: true,
                      module: {
                        clientModuleId: 0,
                        id: 85,
                        imageUrl:
                            'https://general-assets.sgp1.digitaloceanspaces.com/learning-path-images/SWE/SWE-10%20TypeScript.png',
                        name: 'Learning TypeScript',
                        tagline: null,
                        moduleRecomId: 107,
                      },
                    },
                  ],
                  picureUrl: null,
                  userFullName: 'Mock user 2',
                },
              ],
            },
          },
        }),
      500,
    );
  });
};

const getAssignedMockOrgCourse = () => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [],
            data: {
              totalRecords: 2,
              records: [
                {
                  email: 'mock-dnescobar.rubiqe@yopmail.com',
                  jobseekerId: 1223,
                  modules: [
                    {
                      allowRecommendation: true,
                      clientModuleId: 5,
                      id: 29,
                      imageUrl:
                          'https://general-assets.sgp1.digitaloceanspaces.com/training-icons/ede_graph_database_wbg.png',
                      name: 'Graph Databases',
                      tagline: 'Let’s Dive in the World of Connections',
                    },
                  ],
                  picureUrl: null,
                  name: 'Mock Org 1',
                  organizationId: 9447,
                },
                {
                  email: 'mock-dnescobar.rubiqe@yopmail.com',
                  jobseekerId: 123,
                  modules: [
                    {
                      allowRecommendation: true,
                      clientModuleId: 5,
                      id: 29,
                      imageUrl:
                          'https://general-assets.sgp1.digitaloceanspaces.com/training-icons/ede_graph_database_wbg.png',
                      name: 'Graph Databases',
                      tagline: 'Let’s Dive in the World of Connections',
                    },
                  ],
                  name: 'Mock Org 2',
                  picureUrl: null,
                  organizationId: 9447,
                },
              ],
            },
          },
        }),
      500,
    );
  });
};

const getMockCadsAiAssignedCohortCourse = () => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [],
            data: {
              totalRecords: 2,
              records: [
                {
                  email: 'mock1@gmail.com',
                  jobseekerId: 425,
                  userFullName: 'Mock Cohort',
                  courseList: [
                    {
                      allowRecommendation: true,
                      fullName: 'Mock 1',
                      group: { id: 2, name: 'EDA' },
                      imageUrl:
                        'https://general-assets.sgp1.digitaloceanspaces.com/training-icons/eda_210616.png',
                      courseRecomId: 12,
                      lpId: 3,
                      main: true,
                      shortName: 'EDA',
                      summary: 'test1',
                    },
                  ],
                  picUrl:
                    'https://general-assets.sgp1.digitaloceanspaces.com/skillstreets/561/e9a1a904-b438-4d7e-b5de-56617fe9ad64-56a264cd-c0cf-4082-a829-abdf73abfc6b.png',
                },
                {
                  email: 'mock2@gmail.com',
                  jobseekerId: 425,
                  userFullName: 'Mock2 Cohort',
                  courseList: [
                    {
                      allowRecommendation: true,
                      fullName: 'Mock 2',
                      group: { id: 2, name: 'EDA' },
                      imageUrl:
                        'https://general-assets.sgp1.digitaloceanspaces.com/training-icons/eda_210616.png',
                      courseRecomId: 12,
                      lpId: 3,
                      main: true,
                      shortName: 'EDA',
                      summary: 'test1',
                    },
                  ],
                  picUrl:
                    'https://general-assets.sgp1.digitaloceanspaces.com/skillstreets/561/e9a1a904-b438-4d7e-b5de-56617fe9ad64-56a264cd-c0cf-4082-a829-abdf73abfc6b.png',
                },
              ],
            },
          },
        }),
      500,
    );
  });
};

const cadsAiMockAddCourses = (responseData) => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          body: responseData,
        }),
      500,
    );
  });
};

const cadsAiMockEditCourses = (responseData) => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          body: responseData,
        }),
      500,
    );
  });
};

const delteMockCourseState = (responseData) => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          body: responseData,
        }),
      500,
    );
  });
};

const getMockDeliveryMethodOptions = () => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [],
            data: {
              totalRecords: 5,
              records: [
                {
                  id: 4,
                  title: 'Mock 1',
                  description: null,
                },
                {
                  id: 7,
                  title: 'Mock 2',
                  description: null,
                },
                {
                  id: 12,
                  title: 'Mock 3',
                  description: null,
                },
                {
                  id: 13,
                  title: 'Mock 4',
                  description: null,
                },
                {
                  id: 6,
                  title: 'Mock 5',
                  description: null,
                },
              ],
            },
          },
        }),
      500,
    );
  });
};

const getMockContentTypeOptions = () => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [],
            data: {
              totalRecords: 5,
              records: [
                {
                  id: 4,
                  title: 'Mock 1',
                  description: null,
                },
                {
                  id: 7,
                  title: 'Mock 2',
                  description: null,
                },
                {
                  id: 12,
                  title: 'Mock 3',
                  description: null,
                },
                {
                  id: 13,
                  title: 'Mock 4',
                  description: null,
                },
                {
                  id: 6,
                  title: 'Mock 5',
                  description: null,
                },
              ],
            },
          },
        }),
      500,
    );
  });
};

const getMockCourseById = () => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [],
            data: {
              totalRecords: 5,
              records: {
                contentTypeId: 4,
                courseLink: 'test',
                deliveryMethodId: 6,
                description: 'description',
                durationInMinutes: '59',
                endDate: '2022-04-03',
                isForSale: false,
                isMainVariant: true,
                isMeeting: true,
                isPlatformWide: true,
                isProvidedByCads: null,
                isVerified: null,
                materialsUrl: 'test',
                moduleId: 6,
                participantsMaterialLink: 'any',
                saleDiscount: '5',
                saleFinalPrice: '95',
                salePrice: '20',
                startDate: '2022-03-21',
                summary: 'summary',
                title: 'test',
              },
            },
          },
        }),
      500,
    );
  });
};
export default {
  getCadsAiCourses,
  deleteCourseState,
  cadsAIAddCourse,
  cadsAIEditCourse,
  getCadsAiCoursesSkills,
  getDeliveryMethodOptions,
  getCourseContentOptions,
  getCourseById,
  getTrainingProviderOptions,
  getAssignedCourseUsers,
  getAssignedCourseOrg,
  unAssignCourseToUsers,
  unAssignCourseToOrg,
  getCadsAiAssignedCohortCourse,
  unAssignCourseToCohort,
};
