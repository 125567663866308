import React from 'react';
import { Field, Form } from 'react-final-form';
import { connect, useDispatch } from 'react-redux';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { NavLink } from 'react-router-dom';
import { Alert, Button } from 'reactstrap';
import PasswordField from '@/shared/components/form/Password';
import { login, logout } from '../redux/actions';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '@/shared/helpers/auth.enum';
import { paths } from '@/shared/helpers/paths';
import { withRouter } from 'react-router';
import LoadingIcon from 'mdi-react/LoadingIcon';
import {
  extractUserDataFromToken,
  isAllowedToAccess,
  getAppItem,
  setAppItem,
} from '@/shared/helpers';
// import renderCheckBoxField from '../form/CheckBox';

const LogInForm = ({ user, loginUser, history }) => {
  const onSubmit = async (loginData) => {
    try {
      await loginUser(loginData);
    } catch (error) {
      console.error('Error occurred while logging in user : ', error);
    }
  };

  const dispatch = useDispatch();

  const redirectUser = (userData) => {
    if (isAllowedToAccess(userData, paths.CADS_AI.DASHBOARD)) history.push(paths.CADS_AI.DASHBOARD);
    else if (isAllowedToAccess(userData, paths.DATA_STAR.DASHBOARD))
      history.push(paths.DATA_STAR.DASHBOARD);
    else if (isAllowedToAccess(userData, paths.CONTROL_PANEL.ASSIGN))
      history.push(paths.CONTROL_PANEL.ASSIGN);
    else if (isAllowedToAccess(userData, paths.CAMPAIGN.BASE)) history.push(paths.CAMPAIGN.BASE);
  };

  React.useEffect(() => {
    // if user is logged in already sent back dashboard
    const accessToken = getAppItem(ACCESS_TOKEN);
    if (accessToken && user.data) {
      redirectUser(user.data);
    } else {
      // not logged in, so clear out any previous data by executing logout
      dispatch(logout());
    }
  }, []);

  React.useEffect(() => {
    // login user after storing access and refresh token in cookie
    if (user.isLoginSuccess && user.loginResponse) {
      const { token: accessToken, refreshToken } = user.loginResponse?.data;
      setAppItem(ACCESS_TOKEN, accessToken);
      setAppItem(REFRESH_TOKEN, refreshToken);
      const userData = extractUserDataFromToken(accessToken);
      redirectUser(userData);
    }
  }, [user]);

  const { isLoggingIn } = user;

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <form className="form login-form" onSubmit={handleSubmit}>
          <Alert color="danger" isOpen={user.isLoginFailed}>
            {user.error || 'Error occurred while logging in'}
          </Alert>
          <div className="form__form-group">
            <span className="form__form-group-label">Username</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <AccountOutlineIcon />
              </div>
              <Field
                name="userName"
                component="input"
                type="text"
                placeholder="Username"
                className="input-without-border-radius"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Password</span>
            <div className="form__form-group-field">
              <Field
                name="password"
                component={PasswordField}
                placeholder="Password"
                className="input-without-border-radius"
                keyIcon
              />
              <div className="account__forgot-password">
                <NavLink to={paths.FORGOT_PASSWORD}>Forgot a password?</NavLink>
              </div>
            </div>
          </div>
          <div className="form__form-group">
            {/*<div className="form__form-group form__form-group-field">
                        <Field
                          name="remember_me"
                          render={renderCheckBoxField}
                          label="Remember me"
                          type="checkbox"
                        />
                      </div>*/}
          </div>
          <div className="account__btns">
            <Button className="account__btn" type="submit" color="primary" disabled={isLoggingIn}>
              {isLoggingIn && <LoadingIcon className="spinning" />}
              Sign{isLoggingIn && 'ing'} In
            </Button>
            {/*<NavLink className="btn btn-outline-primary account__btn" to="/register">Create Account </NavLink>*/}
          </div>
        </form>
      )}
    </Form>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginUser: login,
  logoutUser: logout,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogInForm));
