import React, { useMemo } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { useDomainUIContext } from './DomainUIProvider';
import DomainTableWrapper from './table/DomainTableWrapper';

export function DomainCard() {
  const domainUIContext = useDomainUIContext();
  const domainUIProps = useMemo(() => {
    return {
      openNewDomainDialog: domainUIContext.openNewDomainDialog,
    };
  }, [domainUIContext]);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Domain List</h3>
          <h3 className="page-subhead subhead">
            Here you can manage all the domains under base skills.
          </h3>
        </Col>
      </Row>
      <Row>
        <DomainTableWrapper />
      </Row>
    </Container>
  );
}
