import { handleActions } from 'redux-actions';
import {
  setTrainingProviderFetching,
  setAssignTrainingProviderList,
  setTrainingProviderError,
  setTrainingProviderFilters,
  toggleCheckedTrainingProvider,
  clearCheckedTrainingProvider,
  setTrainingProviderGroupOptions,
  clearTrainingProviderFilters,
  updateTrainingProviderInList,
} from './actions';

const defaultState = {
  loading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    search: '',
    isSortOrderDescending: false,
  },
  totalRecords: 0,
  assignTrainingProviders: [],
  error: null,
  checkedTrainingProvider: { id: -1, name: 'Select Training Provider' }, //setting to -1 if no trainingProvider is selected
};

export default handleActions(
  {
    [setTrainingProviderFetching](state) {
      return {
        ...state,
        assignTrainingProviders: [],
        totalRecords: 0,
        loading: true,
        error: null,
      };
    },
    [setTrainingProviderError](state, { payload }) {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    },
    [setAssignTrainingProviderList](state, { payload }) {
      const assignTrainingProviderList = payload.data;
      return {
        ...state,
        assignTrainingProviders: assignTrainingProviderList,
        totalRecords: assignTrainingProviderList?.length,
        loading: false,
        error: null,
      };
    },
    [setTrainingProviderFilters](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
      };
    },
    [toggleCheckedTrainingProvider](state, { payload }) {
      return {
        ...state,
        checkedTrainingProvider: payload ?? defaultState.checkedTrainingProvider,
      };
    },
    [clearCheckedTrainingProvider](state) {
      return {
        ...state,
        checkedTrainingProvider: defaultState.checkedTrainingProvider,
      };
    },
    [clearTrainingProviderFilters](state) {
      return {
        ...state,
        filters: { ...defaultState.filters },
      };
    },
    [updateTrainingProviderInList](state, { payload }) {
      return {
        ...state,
        assignTrainingProviders: state.assignTrainingProviders?.map((tp) =>
          tp.id === payload.id ? { ...tp, ...payload } : tp,
        ),
      };
    },
  },
  defaultState,
);
