import React from 'react';
import { useSelector } from 'react-redux';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import api from '@/utils/apiClient';
import CustomerTable from './CustomerTable';
import { useCustomerUIContext } from '../CustomerUIProvider';

const CustomerTableWrapper = () => {
  const customerUIContext = useCustomerUIContext();

  const customerFilter = useSelector((state) => state.customers.filters);

  const downloadCSV = async () => {
    const customerCsvFilter = { ...customerFilter, pageSize: null, pageNumber: null };
    const csvData = await api.customers.getCustomerListCSV(customerCsvFilter);
    require('js-file-download')(
      csvData,
      `Customer_List__${new Date().toLocaleString().replace(/[\/:,\s]/gi, '_')}.csv`,
    );
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="products-list">
          <div className="card__title">
            <h5 className="bold-text">Customers List</h5>
            <ButtonToolbar className="products-list__btn-toolbar-top">
              <Button color="success" onClick={downloadCSV} outline>
                Export Customers
              </Button>
              <Button color="primary" onClick={customerUIContext.openNewCustomerDialog}>
                Add new Customers
              </Button>
            </ButtonToolbar>
          </div>
          <CustomerTable />
        </CardBody>
      </Card>
    </Col>
  );
};

export default CustomerTableWrapper;
