import { handleActions } from 'redux-actions';
import {
  setSkillList,
  fetchSkillList,
  setSkillError,
  setSkillFilters,
  updateSkillInList,
  deleteSkillFromList,
} from './actions';

const defaultState = {
  loading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    search: '',
    isSortOrderDescending: false,
    domainId: null,
    subcategoryId: null,
  },
  totalRecords: 0,
  data: [],
  error: null,
};

export default handleActions(
  {
    [fetchSkillList](state) {
      return {
        ...state,
        loading: true,
        data: [],
      };
    },
    [setSkillList](state, { payload }) {
      return {
        ...state,
        loading: false,
        data: payload.data.records,
        totalRecords: payload.data.totalRecords,
        error: null,
      };
    },
    [updateSkillInList](state, { payload }) {
      return {
        ...state,
        data: state.data.map((skill) => (skill.id === payload.id ? payload : skill)),
      };
    },
    [deleteSkillFromList](state, { payload }) {
      return {
        ...state,
        data: state.data.filter((skill) => skill.id !== payload),
        totalRecords: state.totalRecords - 1,
      };
    },
    [setSkillError](state, { payload }) {
      return {
        ...state,
        data: [],
        loading: false,
        error: payload?.message,
      };
    },
    [setSkillFilters](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
      };
    },
  },
  defaultState,
);
