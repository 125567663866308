import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

export const UsersColumns = [
  {
    id: 'name',
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'email',
    disablePadding: true,
    label: 'Email',
  },
  {
    id: 'contact',
    disablePadding: true,
    label: 'Contact',
  },
  {
    id: 'literacy_skills',
    disablePadding: true,
    label: 'Literacy Skills',
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'Action',
  },
];

const createSortHandler = (property, onRequestSort) => (event) => {
  onRequestSort(event, property);
};

const UserTableColumns = ({ order, orderBy, onRequestSort }) => {
  return (
    <Fragment>
      <TableRow>
        {UsersColumns.map((row) => (
          <TableCell
            className="material-table__cell material-table__cell--sort material-table__cell-center"
            key={row.id}
            align={'center'}
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id, onRequestSort)}
              className="material-table__sort-label"
              dir="ltr"
            >
              <b>{row.label}</b>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </Fragment>
  );
};

UserTableColumns.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default UserTableColumns;
