import React from 'react';
import TimeLineItem from '@/shared/components/TimeLineItem';
import api from '@/utils/apiClient';
import moment from 'moment';
import ApplicationDetails from '../ApplicationDetails';
import LearningDetails from '../LearningDetails';
import AssessmentDetails from '../AssessmentDetails';
import { Col } from 'reactstrap';

const UserJourney = ({ enrollmentId: id, firstStage, state, toggle }) => {
  const [applicantHistory, setApplicantHistory] = React.useState([]);

  React.useEffect(() => {
    if (id && firstStage) getApplicantHistory(id);
  }, [id, firstStage]);

  const getApplicantHistory = async (id) => {
    try {
      const { data: res, isSuccess } = await api.cadsAIApplicant.getApplicantHistory(id);
      const history = res.data?.history;
      if (isSuccess) {
        const historyArr = [];
        history.forEach((change, idx) => {
          historyArr.push({
            id: idx,
            type: 'work',
            title: change?.toStage?.title ?? '-',
            date: moment(change?.changeDate)?.format('DD MMMM YYYY | hh:mm A') ?? '-',
            status: change?.toStatus?.title ?? '-',
            img: '',
          });
        });
        setApplicantHistory(historyArr);
      } else {
        throw new Error('Applicant history API error');
      }
    } catch (e) {
      console.error('Error while fetching applicant history', e);
    }
  };

  return (
    <>
      <div className="timeline">
        {applicantHistory.map(({ id, status, ...props }, idx) => (
          <TimeLineItem key={id} {...props}>
            <p>{status}</p>
          </TimeLineItem>
        ))}
      </div>
      <Col md={12} lg={12}>
        <div className="border-bottom pb-4">
          <ApplicationDetails applicantDetail={state?.applicant} />
        </div>
        <div className="border-bottom pb-4">
          <AssessmentDetails
            assessmentDetail={state?.applicant?.assesmentDetails}
            toggle={toggle}
          />
        </div>
        <LearningDetails learningDetails={state?.applicant?.learningDetails} />
      </Col>
    </>
  );
};

export default UserJourney;
