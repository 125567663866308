import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import TableRow from '@material-ui/core/TableRow';
import { toggleCheckedItemById } from '../../redux/actions';
import TableCell from '@material-ui/core/TableCell';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import * as Constants from '@/shared/helpers/constants';

const AssignedTableRow = ({ data }) => {
  const { activeTab, checkedItemIds } = useSelector((state) => state.assignedPage, shallowEqual);
  const dispatch = useDispatch();
  const handleOnChange = (itemId) => {
    dispatch(toggleCheckedItemById(itemId));
  };

  const renderUserCells = (user) => (
    <>
      <TableCell align="center" className="material-table__cell">
        <FormGroup>
          <Input
            type="checkbox"
            value={user?.userDetailId}
            checked={checkedItemIds.includes(user?.userDetailId)}
            onChange={() => handleOnChange(user?.userDetailId)}
          />
        </FormGroup>
      </TableCell>
      <TableCell align="center" className="material-table__cell">
        {user?.userFullName}{' '}
      </TableCell>
      <TableCell align="center" className="material-table__cell">
        {user?.email}{' '}
      </TableCell>
      {/*TODO: Ask BE people to fix typo in user?.persmissionTitle*/}
      {/*<TableCell align="center" className="material-table__cell">{user?.persmissionTitle} </TableCell>*/}
    </>
  );

  const renderCohortCells = (cohort) => (
    <>
      <TableCell align="center" className="material-table__cell">
        <FormGroup>
          <Input
            type="checkbox"
            value={cohort?.cohortId}
            checked={checkedItemIds.includes(cohort?.cohortId)}
            onChange={() => handleOnChange(cohort?.cohortId)}
          />
        </FormGroup>
      </TableCell>
      <TableCell align="center" className="material-table__cell">
        {cohort?.cohortName}{' '}
      </TableCell>
    </>
  );

  const renderOrgCells = (organization) => (
    <>
      <TableCell align="center" className="material-table__cell">
        <FormGroup>
          <Input
            type="checkbox"
            value={organization?.organizationId}
            checked={checkedItemIds.includes(organization?.organizationId)}
            onChange={() => handleOnChange(organization?.organizationId)}
          />
        </FormGroup>
      </TableCell>
      <TableCell align="center" className="material-table__cell">
        {organization?.organizationName}{' '}
      </TableCell>
    </>
  );

  return (
    <>
      {
        {
          [Constants.USERS]: data?.map((d) => (
            <TableRow className="material-table" tabIndex={-1} key={d.userDetailId}>
              {renderUserCells(d)}
            </TableRow>
          )),
          [Constants.COHORT]: data?.map((d) => (
            <TableRow className="material-table" tabIndex={-1} key={d.cohortId}>
              {renderCohortCells(d)}
            </TableRow>
          )),
          [Constants.ORGANIZATION]: data?.map((d) => (
            <TableRow className="material-table" tabIndex={-1} key={d.organizationId}>
              {renderOrgCells(d)}
            </TableRow>
          )),
        }[activeTab]
      }
    </>
  );
};

export default AssignedTableRow;
