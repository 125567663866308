import React from 'react';
import { useDispatch } from 'react-redux';
import api from '@/utils/apiClient';
import { getCadsAiLearningPathSkills, updateCadsAiLearningPathInList } from '../../redux/actions';
import LoadingIcon from 'mdi-react/LoadingIcon';
import LearningPathWizardForm from './LearningPath-Wizard-Form';
import { convertImageFileToBase64 } from '@/shared/helpers';

const LearningPathEditForm = ({ isEdit, id }) => {
  const dispatch = useDispatch();
  const initialValues = {
    fullTitle: '',
    shortTitle: '',
    description: '',
    tagJson: '',
    imageUrl: '',
    group: '',
    dataSkills: [],
    module: [],
    otherDataSkills: [],
    isOptional: 'No',
    learningPathType: [],
  };

  //states
  const [state, setState] = React.useState({
    loading: false,
    fetchingLearningPath: false,
    newLearningPathAdded: false,
    errorMessage: '',
  });
  const [data, setData] = React.useState(initialValues);
  const [selectedModuleLists, setSelectedModuleLists] = React.useState([]);
  const [moduleOptions, setModuleOptions] = React.useState([]);
  const [selectedLP, setSelectedLP] = React.useState({});

  React.useEffect(() => {
    if (isEdit && id) {
      setDefaultData(+id);
    }
  }, [isEdit, id, selectedLP]);

  const getCadsAiLPById = async (id) => {
    setSelectedLP({ ...selectedLP });
    const { data: res, isSuccess } = await api.cadsAILearningPathApi.getCadsAiLearningPath(id);
    if (isSuccess) {
      setSelectedLP(res?.data);
    } else {
      throw new Error(res?.messages?.[0] ?? 'Error while getting learning path details');
    }
  };

  React.useEffect(() => {
    if (isEdit) {
      getCadsAiLPById(id);
    }
  }, []);

  const getSkillsOptions = React.useCallback(() => {
    // TODO: implement proper solution
    dispatch(getCadsAiLearningPathSkills({ pageSize: 1000, pageNumber: 1 })); // assuming there won't be more than 1000 learningPathSkills
  }, []);

  React.useEffect(() => {
    getSkillsOptions();
  }, []);

  const setDefaultData = () => {
    setData(selectedLP);
  };

  const resetForm = () => {
    setData({});
    setModuleOptions([...moduleOptions, ...selectedModuleLists]);
    setSelectedModuleLists([]);
  };
  const onSubmit = async (
    {
      fullTitle,
      shortTitle,
      description,
      tagsJson,
      imageUrl,
      isMain,
      group,
      learningPathType,
      dataSkills,
      otherDataSkills,
    },
    e,
  ) => {
    setState({ ...state, loading: true });
    const base64Img = await convertImageFileToBase64(imageUrl?.[0]);
    const reqData = {
      fullTitle,
      shortTitle,
      description,
      tagsJson: null,
      image: base64Img?.split(',')?.[1],
      isMain: isEdit ? isMain?.value : false,
      learningPathGroup: {
        id: group?.__isNew__ ? null : group?.value,
        name: group?.label,
        code: group?.code,
      },
      code: group.code,
      learningPathTypeId: learningPathType?.value,
      moduleIds: selectedModuleLists?.map((module) => ({
        id: module.value,
        name: module.label,
        isOptional: module.isOptional,
      })),
      otherDataSkillIds: otherDataSkills?.map((skill) => skill?.value),
      dataSkillsIds: dataSkills?.map((skill) => skill?.value),
    };
    const defaultError = `Error occurred while ${isEdit ? 'Updating' : 'Adding new'} learning path`;
    try {
      const { data, isSuccess } = isEdit
        ? await api.cadsAILearningPathApi.cadsAiUpdateLearningPath({ id: +id, ...reqData })
        : await api.cadsAILearningPathApi.cadsAiAddLearningPath(reqData);
      if (isSuccess) {
        setState({ ...state, newLearningPathAdded: true, loading: false });

        if (isEdit) dispatch(updateCadsAiLearningPathInList({ id: +id, ...reqData }));
        else resetForm();
      } else {
        throw new Error(data?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({
        ...state,
        loading: false,
        newLearningPathAdded: false,
        errorMessage: error.message,
      });
    }
  };

  return (
    <div>
      {/*Add/Edit LearningPath Form Status*/}
      <div className="ml-4">
        {state.loading && (
          <div>
            <div className="panel__refresh position-relative" style={{ height: 75, width: 20 }}>
              <LoadingIcon />
              <div style={{ width: 200, padding: '23px 40px' }}>
                {isEdit ? 'Updating' : 'Adding new'} learning path...
              </div>
            </div>
          </div>
        )}
        {(state.newLearningPathAdded || !!state.errorMessage) && (
          <table className="mt-3">
            <tr>
              <td>
                {state.newLearningPathAdded ? (
                  <span className="lnr lnr-thumbs-up modal__title-icon success" />
                ) : (
                  <span className="lnr lnr-cross-circle modal__title-icon error" />
                )}
              </td>
              <td className="pl-2">
                {state.newLearningPathAdded
                  ? `${isEdit ? 'Learning path updated' : 'New learning path added'} successfully`
                  : state.errorMessage}
              </td>
            </tr>
          </table>
        )}
      </div>

      <LearningPathWizardForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        data={data}
        setData={setData}
        id={id}
        setSelectedModuleLists={setSelectedModuleLists}
        selectedModuleLists={selectedModuleLists}
        selectedLP={selectedLP}
        state={state}
      />
    </div>
  );
};

export default LearningPathEditForm;
