import React from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import { useAssessmentUIContext } from '../AssessmentUIProvider';
import KnowledgeBaseAssessmentConfigForm from './knowledge-base-assessment-config/KnowledgeBaseAssessmentConfigForm';
import './index.scss';
import { useSelector } from 'react-redux';
import { ASSESSMENT_TYPES } from '@/shared/helpers/constants';
import GeneralAssessmentConfigForm from './general-assessment-config/GeneralAssessmentConfigForm';
import api from '@/utils/apiClient';
import LoadingIcon from 'mdi-react/LoadingIcon';

const AssessmentConfigCard = ({ id }) => {
  const assessmentUIContext = useAssessmentUIContext();

  const assessmentList = useSelector((state) => state.assessments.data);

  const [state, setState] = React.useState({
    loading: false,
    assessment: {},
    error: '',
  });

  React.useEffect(() => {
    if (id && assessmentList?.length > 0) {
      const assessment = assessmentList.filter((assessment) => assessment.id === +id)?.[0];
      setState({ ...state, assessment });
    } else if (id) {
      getAssessmentById(id);
    }
  }, [id]);

  const getAssessmentById = async (assessmentId) => {
    try {
      setState({ ...state, loading: true, error: '' });
      const { data: res, isSuccess } = await api.assessments.getAssessmentById(assessmentId);
      if (isSuccess) {
        setState({ ...state, loading: false, assessment: res.data, error: '' });
      } else {
        throw new Error(res?.messages?.[0] ?? 'Error while getting assessment details');
      }
    } catch ({ message }) {
      setState({ ...state, loading: false, assessment: {}, error: message });
      console.error(message);
    }
  };

  const { assessment, loading, error } = state;
  const assessmentType = assessment.assessmentTypeName ?? assessment?.assessmentType?.title ?? '';
  return (
    <Col md={12} lg={12} className="assessment_config">
      <Card>
        <CardBody className="products-list">
          <div className="card__title">
            <h5 className="bold-text">Configure Assessment</h5>
            <ButtonToolbar className="products-list__btn-toolbar-top">
              <Button color="primary" outline onClick={assessmentUIContext.openViewAssessmentList}>
                Back To Assessment List
              </Button>
            </ButtonToolbar>
          </div>
          {(loading || !!error) && (
            <>
              {loading && (
                <div className="panel__refresh position-relative" style={{ height: 75 }}>
                  <LoadingIcon />
                </div>
              )}
              <div className="text-center">
                {error && (
                    <div>
                      <span className="lnr lnr-cross-circle modal__title-icon error"/>
                    </div>
                )}
                {loading ? 'Getting assessment details...' : error}
              </div>
            </>
          )}
          {[ASSESSMENT_TYPES.KNOWLEDGE_BASE_ASSESSMENT, ASSESSMENT_TYPES.DATA_LITERACY_ASSESSMENT].includes(assessmentType) && (
              <KnowledgeBaseAssessmentConfigForm
                  assessmentId={!!id ? +id : 0}
                  backToListView={assessmentUIContext.openViewAssessmentList}
              />
          )}
          {[ASSESSMENT_TYPES.GENERAL_ASSESSMENT, ASSESSMENT_TYPES.SMART_SKILLS, ASSESSMENT_TYPES.DDO].includes(assessmentType) && (
              <GeneralAssessmentConfigForm assessmentId={!!id ? +id : 0}/>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default AssessmentConfigCard;
