import { sendRequest } from '@/utils/api/base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const CP_ENDPOINT = process.env.REACT_APP_ROOT_URL + process.env.REACT_APP_CONTROL_PANEL_ENDPOINT;

const cpUserEndpoints = {
  GET_USER_LIST_BY_ORG_ID: `${CP_ENDPOINT}/getAllControlledUsersByOrgId`,
  GET_USER_LIST_BY_MODULE_ID: `/getUsersByModule`,
  GET_USER_LIST_BY_LEARNING_PATH_ID: `/getUsersByLearningPath`,
};

const getUserListByOrgId = ({
  orgId,
  assigned,
  feature,
  moduleId,
  learningPathId,
  trainingProviderId,
  tenantId,
  isSortOrderDescending,
  pageNumber,
  pageSize,
  searchText,
  email,
  sortBy,
  ...otherFilter
}) => {
  const commonFilters = {
    isSortOrderDescending,
    pageNumber,
    pageSize,
    searchText,
    sortBy,
    email,
  };
  if (
    (moduleId || learningPathId || trainingProviderId) &&
    moduleId !== -1 &&
    learningPathId !== -1 &&
    trainingProviderId !== -1
  ) {
    // return getMockUserListByOrgId();
    //if module selected get users for module
    if (moduleId && moduleId !== -1)
      return getUserListByModuleId({
        tenantId,
        assigned: false,
        moduleId,
        ...commonFilters,
      });
    if (learningPathId && learningPathId !== -1)
      return getUserListByLearningPathId({
        tenantId,
        lpId: learningPathId,
        assigned: false,
        ...commonFilters,
      });
    if (trainingProviderId && trainingProviderId !== -1)
      return getUserListByTrainingProviderId({
        tenantId,
        tpId: trainingProviderId,
        assigned: false,
        ...commonFilters,
      });
  }
  // Assignment : Assigned=0/Unassigned=1
  // Feature : Chatbot=0/DDO=1/Acceltic=2
  return getUserList({
    orgId,
    accessStatus: assigned ? 0 : 1,
    feature,
    ...commonFilters,
    ...otherFilter,
  });
};

export default {
  getUserListByOrgId,
};

/************* PRIVATE *****************/

const getUserList = (filters) =>
  sendRequest({
    fullUrl: cpUserEndpoints.GET_USER_LIST_BY_ORG_ID,
    method: httpMethod.GET,
    params: filters,
  });

const getUserListByModuleId = (filters) =>
  sendRequest(
    {
      url: cpUserEndpoints.GET_USER_LIST_BY_MODULE_ID,
      method: httpMethod.GET,
      params: filters,
    },
    process.env.REACT_APP_CONTROL_PANEL_ENDPOINT,
  );

const getUserListByLearningPathId = (filters) =>
  sendRequest(
    {
      url: cpUserEndpoints.GET_USER_LIST_BY_LEARNING_PATH_ID,
      method: httpMethod.GET,
      params: {
        ...filters,
        pageNo: filters.pageNumber - 1,
        search: filters.searchText,
      },
    },
    process.env.REACT_APP_CONTROL_PANEL_SS_ENDPOINT,
  );

const getUserListByTrainingProviderId = (filters) =>
  new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          status: 200,
          messages: [''],
          data: {
            currentPage: 1,
            pageSize: 10,
            totalRecords: 12,
            data: [
              { email: 'mockuser1@gmail.com', jobseekerId: 671, userFullName: 'Mock user1' },
              { email: 'mockuser2@gmail.com', jobseekerId: 672, userFullName: 'Mock user2' },
              { email: 'mockuser3@gmail.com', jobseekerId: 673, userFullName: 'Mock user3' },
            ],
          },
        }),
      500,
    );
  });

const getMockUserListByOrgId = () =>
  new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [''],
            data: {
              currentPage: 1,
              pageSize: 10,
              totalRecords: 12,
              records: [
                {
                  organizationName: 'Mock Org 1',
                  userFullName: 'Mock Module User 1',
                  userDetailId: 1,
                  email: 'mock@email.com',
                  persmissionTitle: 'User',
                },
                {
                  organizationName: 'Mock Org 2',
                  userFullName: 'Mock Module User 2',
                  userDetailId: 2,
                  email: 'mock2@email.com',
                  persmissionTitle: 'User',
                },
                {
                  organizationName: 'Mock Org 3',
                  userFullName: 'Mock Module User 3',
                  userDetailId: 3,
                  email: 'mock3@email.com',
                  persmissionTitle: 'User',
                },
                {
                  organizationName: 'Mock Org 4',
                  userFullName: 'Mock Module User 4',
                  userDetailId: 4,
                  email: 'mock4@email.com',
                  persmissionTitle: 'User',
                },
                {
                  organizationName: 'Mock Org 5',
                  userFullName: 'Mock Module User 5',
                  userDetailId: 5,
                  email: 'mock5@email.com',
                  persmissionTitle: 'User',
                },
                {
                  organizationName: 'Mock Org 6',
                  userFullName: 'Mock Module User 6',
                  userDetailId: 6,
                  email: 'mock6@email.com',
                  persmissionTitle: 'User',
                },
                {
                  organizationName: 'Mock Org 7',
                  userFullName: 'Mock Module User 7',
                  userDetailId: 7,
                  email: 'mock7@email.com',
                  persmissionTitle: 'User',
                },
                {
                  organizationName: 'Mock Org 8',
                  userFullName: 'Mock Module User 8',
                  userDetailId: 8,
                  email: 'mock8@email.com',
                  persmissionTitle: 'User',
                },
                {
                  organizationName: 'Mock Org 9',
                  userFullName: 'Mock Module User 9',
                  userDetailId: 9,
                  email: 'mock9@email.com',
                  persmissionTitle: 'User',
                },
                {
                  organizationName: 'Mock Org 10',
                  userFullName: 'Mock Module User 10',
                  userDetailId: 10,
                  email: 'mock10@email.com',
                  persmissionTitle: 'User',
                },
                {
                  organizationName: 'Mock Org 11',
                  userFullName: 'Mock Module User 11',
                  userDetailId: 11,
                  email: 'mock11@email.com',
                  persmissionTitle: 'User',
                },
                {
                  organizationName: 'Mock Org 12',
                  userFullName: 'Mock Module User 12',
                  userDetailId: 12,
                  email: 'mock12@email.com',
                  persmissionTitle: 'User',
                },
              ],
            },
          },
        }),
      500,
    );
  });
