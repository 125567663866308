import React from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import EventsTable from './EventsTable';
import { useEventUIContext } from '../EventsUIProvider';

const EventsTableWrapper = () => {
  const eventsUIContext = useEventUIContext();

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="products-list">
          <div className="card__title">
            <h5 className="bold-text">Data Events List</h5>
            <ButtonToolbar className="products-list__btn-toolbar-top">
              <Button color="primary" onClick={eventsUIContext.openNewEventDialog}>
                Add new
              </Button>
              <Button color="success" outline onClick={() => null}>
                Export Events
              </Button>
            </ButtonToolbar>
          </div>
          <EventsTable />
        </CardBody>
      </Card>
    </Col>
  );
};

export default EventsTableWrapper;
