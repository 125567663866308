import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, ButtonToolbar } from 'reactstrap';
import api from '@/utils/apiClient';
import { emptyStringPattern } from '@/shared/helpers';
import { RadioButtonField } from '@/shared/components/form/RadioButton';
import { FormField } from '@/shared/components/form/hook/FormField';
import { extractFieldData, getStepFields } from '../index';
import { SelectField } from '@/shared/components/form/Select';

const StepThree = ({ onSubmit, page, data, previousPage }) => {
  //states
  const [countryOptions, setCountryOptions] = React.useState([]);
  const [industryOptions, setIndustryOptions] = React.useState([]);
  const [locationStatus, setLocationStatus] = React.useState('');

  const getCountryOptions = async () => {
    const { data: res, isSuccess } = await api.cadsAICountry.getCountryList();
    if (isSuccess) {
      setCountryOptions(res.data.map((country) => ({ value: country.id, label: country.title })));
    }
  };

  const getJobIndustryOptions = async () => {
    const { data: res, isSuccess } = await api.cadsAIJob.getIndustryList();
    if (isSuccess) {
      setIndustryOptions(
        res.data.map((industry) => ({ value: industry.id, label: industry.title })),
      );
    }
  };

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm(extractFieldData(page, data));

  React.useEffect(() => {
    getCountryOptions();
    getJobIndustryOptions();
  }, []);

  React.useEffect(() => {
    getStepFields(page).forEach((key) => {
      setValue(key, data[key]);
    });
    setLocationStatus(data?.locationStatus);
  }, [data]);

  return (
    <div className="modal__body add-edit-learning-path">
      <form
        className={`form form--horizontal`}
        onSubmit={handleSubmit((data) => onSubmit({ ...data, locationStatus: locationStatus }))}
      >
        <div className="form__form-group">
          <span className="form__form-group-label">Location</span>
          <div className="form__form-group-field">
            <FormField
              name="location"
              control={control}
              errors={errors}
              rules={{
                required: 'This is required field',
                pattern: emptyStringPattern,
              }}
              defaultValue=""
              isAboveError={true}
              placeholder="Enter Location"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Location Requirement</span>
          <div className="form__form-group-field mt-2">
            <RadioButtonField
              radioValue={'onSite'}
              name={`onSite`}
              onChange={setLocationStatus}
              value={locationStatus}
              className="colored-click mr-4"
              label={'Onsite'}
            />
            <RadioButtonField
              radioValue={'remote'}
              name={`remote`}
              onChange={setLocationStatus}
              value={locationStatus}
              className="colored-click mr-4"
              label={'Remote'}
            />
            <RadioButtonField
              radioValue={'both'}
              name={`both`}
              onChange={setLocationStatus}
              value={locationStatus}
              className="colored-click mr-4"
              label={'Both'}
            />
          </div>
        </div>
        <div className="form__form-group mt-3">
          <span className="form__form-group-label">Select Country</span>
          <div className="form__form-group-field">
            <FormField
              control={control}
              name="country"
              component={SelectField}
              options={countryOptions}
              placeholder="Select Country"
              rules={{ required: 'This is required field' }}
              errors={errors}
              isAboveError
            />
          </div>
        </div>
        <div className="form__form-group mt-3">
          <span className="form__form-group-label">Select Industry</span>
          <div className="form__form-group-field">
            <FormField
              control={control}
              name="industry"
              component={SelectField}
              options={industryOptions}
              placeholder="Select Industry"
              rules={{ required: 'This is required field' }}
              errors={errors}
              isAboveError
            />
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar modal__footer">
          <Button onClick={previousPage} type="button" className="previous">
            Back
          </Button>
          <Button color="primary" type="submit">
            Next
          </Button>
        </ButtonToolbar>
      </form>
    </div>
  );
};

export default StepThree;
