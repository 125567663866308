import React, { Fragment } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import BaselineThresholdTableColumns, {
  BaselineThresholdColumns,
} from './BaselineThresholdTableColumns';
import { BaselineThresholdTableSearchBar } from './BaselineThresholdTableSearchBar';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getOrganizationListForBT,
  setBaselineThresholdFilters,
  toggleCheckedItemById,
} from '../../redux/actions';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { defaultPaginationProps } from '@/shared/helpers';
import { FormGroup, Input } from 'reactstrap';

const BaselineThresholdTable = () => {
  const dispatch = useDispatch();
  const {
    data: orgListWithBT,
    error,
    filters,
    loading,
    totalRecords,
    checkedOrgIds,
  } = useSelector((state) => state.baselineThreshold, shallowEqual);
  const {
    pageSize: rowsPerPage,
    pageNumber: page,
    sortBy: orderBy,
    isSortOrderDescending,
  } = filters;

  React.useEffect(() => {
    dispatch(getOrganizationListForBT(filters));
  }, [filters]);

  const handleRequestSort = (event, property) => {
    dispatch(
      setBaselineThresholdFilters({
        sortBy: property,
        isSortOrderDescending: !isSortOrderDescending,
      }),
    );
  };

  const handleChangePage = (event, currentPage) => {
    dispatch(setBaselineThresholdFilters({ pageSize: rowsPerPage, pageNumber: currentPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setBaselineThresholdFilters({ pageSize: +event.target.value, pageNumber: 1 }));
  };

  const emptyRows =
    page === Math.ceil(totalRecords / rowsPerPage) ? rowsPerPage - orgListWithBT.length : 0;

  return (
    <Fragment>
      <div className="material-table__wrap">
        <BaselineThresholdTableSearchBar />
        <Table className="material-table">
          <BaselineThresholdTableColumns
            order={isSortOrderDescending ? 'desc' : 'asc'}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rowsPerPage}
          />
          <TableBody>
            {(loading || !!error) && (
              <TableRow>
                <TableCell colSpan={BaselineThresholdColumns.length}>
                  {loading && (
                    <div className={'text-center overflow-hidden'}>
                      <div className="panel__refresh position-relative" style={{ height: 75 }}>
                        <LoadingIcon />
                      </div>
                    </div>
                  )}
                  <div className={'text-center'}>
                    <p className={!!error ? 'm-5' : ''}>
                      {error || 'Loading organization list with baseline Thresholds...'}
                    </p>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {!!!orgListWithBT.length && !loading && !error && (
              <TableRow style={{ height: 120 }}>
                <TableCell colSpan={BaselineThresholdColumns.length} align="center">
                  {filters.status === null && filters.search === ''
                    ? 'No organization'
                    : 'No organization with applied filters'}
                </TableCell>
              </TableRow>
            )}
            {orgListWithBT.map((baselineThreshold) => {
              return (
                <TableRow
                  className="material-table"
                  tabIndex={-1}
                  key={baselineThreshold?.organizationId}
                >
                  <TableCell align="center" className="material-table__cell">
                    <FormGroup>
                      <Input
                        type="checkbox"
                        value={baselineThreshold?.organizationId}
                        defaultChecked={checkedOrgIds.includes(baselineThreshold?.organizationId)}
                        onChange={(e) => dispatch(toggleCheckedItemById(+e.target.value))}
                      />
                    </FormGroup>
                  </TableCell>
                  <TableCell
                    className="material-table__cell"
                    component="th"
                    align="center"
                    scope="row"
                  >
                    {baselineThreshold?.organizationName}
                  </TableCell>
                  <TableCell
                    className="material-table__cell"
                    component="th"
                    align="center"
                    scope="row"
                  >
                    {baselineThreshold?.organizationEmail}
                  </TableCell>
                  <TableCell
                    className="material-table__cell"
                    component="th"
                    align="center"
                    scope="row"
                  >
                    {baselineThreshold?.baselineThreshold}
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={BaselineThresholdColumns.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        component={defaultPaginationProps.component}
        backIconButtonProps={defaultPaginationProps.backIconButton}
        nextIconButtonProps={defaultPaginationProps.nextIconButton}
        rowsPerPageOptions={defaultPaginationProps.rowsPerPage}
        className={defaultPaginationProps.className}
        dir={defaultPaginationProps.dir}
      />
    </Fragment>
  );
};

export default BaselineThresholdTable;
