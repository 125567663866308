import React from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import DomainTable from './DomainTable';
import { useDomainUIContext } from '../DomainUIProvider';

const DomainTableWrapper = () => {
  const domainUIContext = useDomainUIContext();

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="products-list">
          <div className="card__title">
            <h5 className="bold-text">Domain List</h5>
            <ButtonToolbar className="products-list__btn-toolbar-top">
              <Button color="primary" onClick={domainUIContext.openNewDomainDialog}>
                Add new Domain
              </Button>
            </ButtonToolbar>
          </div>
          <DomainTable />
        </CardBody>
      </Card>
    </Col>
  );
};

export default DomainTableWrapper;
