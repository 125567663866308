import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import QuestionEditDialogHeader from './QuestionViewDialogHeader';
import api from '@/utils/apiClient';
import QuestionViewTabs from './QuestionViewTabs';
import LoadingIcon from 'mdi-react/LoadingIcon';

const QuestionViewDialog = ({ show, onHide, id, rtl }) => {
  const [modal, setModal] = useState({
    isOpen: show,
    title: 'View Question',
    hide: onHide,
  });

  const [state, setState] = React.useState({
    loading: false,
    question: {
      name: 'Test Question 1',
      description: 'any',
    },
    error: '',
  });

  React.useEffect(() => {
    if (id) getQuestionById(+id);
  }, [id]);

  const getQuestionById = async (questionId) => {
    try {
      setState({ ...state, loading: true });
      const { data: res, isSuccess } = await api.questions.getQuestion(questionId);
      if (isSuccess) {
        setState({ ...state, loading: false, question: res.data, error: '' });
      } else {
        throw new Error(res?.messages?.[0] ?? 'Error while getting question details');
      }
    } catch ({ message }) {
      setState({ ...state, loading: false, question: {}, error: message });
      console.error(message);
    }
  };

  const { error, loading, question } = state;

  return (
    <Modal
      isOpen={show}
      toggle={modal.hide}
      modalClassName={`${rtl.direction}-support`}
      className="modal-dialog--primary modal-dialog--header modal-view-dialog"
    >
      <QuestionEditDialogHeader modal={modal} question={question} />
      {/*Question Modal Body*/}
      <div style={{ padding: 15 }}>
        {loading || !!error ? (
          <>
            {loading && (
              <div className="panel__refresh position-relative" style={{ height: 75 }}>
                <LoadingIcon />
              </div>
            )}
            <div className="text-center">
              {error && (
                <div>
                  <span className="lnr lnr-cross-circle modal__title-icon error" />
                </div>
              )}
              {loading ? 'Getting question details...' : error}
            </div>
          </>
        ) : (
          <QuestionViewTabs question={state.question} />
        )}
      </div>
    </Modal>
  );
};

export default connect((state) => ({
  rtl: state.rtl,
}))(QuestionViewDialog);
