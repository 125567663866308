import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col, Modal, Row } from 'reactstrap';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { uncheckAllSelectedAnnouncement } from '../../redux/actions';
import api from '@/utils/apiClient';

export const AnnouncementDeactivateDialog = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state, shallowEqual);
  const checkedAnnouncementIds = useSelector(
    (state) => state.cadsAIAnnouncements.checkedAnnouncementIds,
    shallowEqual,
  );

  const [modal, setModal] = React.useState({});

  React.useEffect(() => {
    setModal({
      isOpen: show,
      message: '',
      hide: onHide,
      loading: false,
      updateStatusSuccess: false,
      updateStatusError: false,
    });
  }, [show, onHide]);

  const handleUpdateStatus = async () => {
    setModal({
      ...modal,
      loading: true,
      message: 'Deactivating Announcement...',
    });

    let updatedModal = {};
    try {
      const response = await api.cadsAIAnnouncements.deactivateAnnouncement(checkedAnnouncementIds);
      if (response.isSuccess) {
        updatedModal = {
          loading: false,
          updateStatusSuccess: true,
          updateStatusError: false,
          message: 'Announcement Deactivated Successfully!',
        };
        dispatch(uncheckAllSelectedAnnouncement());
      } else {
        throw new Error('Error deactivating announcement');
      }
    } catch (e) {
      updatedModal = {
        loading: false,
        updateStatusSuccess: false,
        updateStatusError: true,
        message: 'Error Performing Announcement Status Update!',
      };
      console.error(e.message, e);
    } finally {
      setModal({ ...modal, ...updatedModal });
    }
  };

  return (
    <Modal
      isOpen={show}
      toggle={modal.hide}
      keyboard={false}
      backdrop={'static'}
      modalClassName={`${rtl.direction}-support`}
      className="modal-dialog--primary modal-dialog"
    >
      <div className="modal__header">
        {modal.updateStatusSuccess || <span className="lnr lnr-unlink modal__title-icon" />}
        {modal.updateStatusSuccess && (
          <span className="lnr lnr-thumbs-up modal__title-icon success" />
        )}
        <button
          className="lnr lnr-cross modal__close-btn"
          aria-label="modal__close-btn"
          type="button"
          onClick={modal.hide}
        />
        {modal.updateStatusSuccess && <h4 className="text-modal  modal__title">Done</h4>}
        {modal.updateStatusSuccess || (
          <h4 className="text-modal  modal__title">Deactivate Announcement</h4>
        )}
      </div>
      <div>
        <div className="ml-4">
          {modal.loading && (
            <div>
              <div className="panel__refresh position-relative" style={{ height: 75, width: 20 }}>
                <LoadingIcon />
                <div style={{ width: 200, padding: '23px 30px' }}>{modal.message}</div>
              </div>
            </div>
          )}
          {(modal.updateStatusSuccess || !modal.updateStatusError) && !modal.loading && (
            <>
              <table className="mt-3">
                <tr>
                  <td className="pl-3">
                    {modal.updateStatusSuccess
                      ? 'Announcement Deactivated Successfully'
                      : modal.message}
                  </td>
                </tr>
              </table>
            </>
          )}
        </div>
        <Row>
          <Col md={12} lg={12}>
            <Card className="pb-0">
              <CardBody>
                <div className="form-wrapper">
                  <div className="form">
                    <div className="form__form-group text-center">
                      {!modal.updateStatusSuccess && !modal.updateStatusError && !modal.loading ? (
                        <span className="form__form-group-label">
                          Are you sure you want to deactivate selected Announcement?
                        </span>
                      ) : (
                        ''
                      )}
                      {modal.updateStatusError && (
                        <span className="form__form-group-label">{modal.message}</span>
                      )}
                    </div>
                    {!modal.loading && (
                      <ButtonToolbar className="modal__footer m-auto">
                        <>
                          {(modal.updateStatusSuccess || modal.updateStatusError) && (
                            <Button
                              color="secondary"
                              outline
                              type="button"
                              onClick={() => modal.hide()}
                            >
                              Okay
                            </Button>
                          )}
                          {!modal.updateStatusSuccess && !modal.updateStatusError ? (
                            <>
                              <Button
                                color="secondary"
                                type="button"
                                className="modal_cancel btn btn-secondary"
                                onClick={() => modal.hide()}
                              >
                                Cancel
                              </Button>
                              <Button
                                color="danger"
                                outline
                                type="submit"
                                onClick={handleUpdateStatus}
                              >
                                Yes, Deactivate
                              </Button>
                            </>
                          ) : (
                            ''
                          )}
                        </>
                      </ButtonToolbar>
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};
