import React, { createContext, useContext } from 'react';

const ApplicantUIContext = createContext();

export function useApplicantUIContext() {
  return useContext(ApplicantUIContext);
}

export function ApplicantsUIProvider({ applicantsUIEvents, children }) {
  const value = {
    openViewApplicants: applicantsUIEvents.openViewApplicants,
    openViewApplicantDialog: applicantsUIEvents.openViewApplicantDialog,
    openUpdateStageApplicants: applicantsUIEvents.openUpdateStageApplicants,
    openUpdateStatusApplicants: applicantsUIEvents.openUpdateStatusApplicants,
  };

  return <ApplicantUIContext.Provider value={value}>{children}</ApplicantUIContext.Provider>;
}
