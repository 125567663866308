import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, ButtonToolbar } from 'reactstrap';
import api from '@/utils/apiClient';
import { YesNoOptions } from '@/shared/helpers';
import { RadioButtonField } from '@/shared/components/form/RadioButton';
import renderDropZoneField from '@/shared/components/form/DropZone';
import { FormField } from '@/shared/components/form/hook/FormField';
import { extractFieldData, getStepFields } from '../index';
import { SelectField } from '@/shared/components/form/Select';

const StepTwo = ({ onSubmit, page, data, previousPage }) => {
  //states
  const [skillOptions, setSkillOptions] = React.useState([]);
  const [deliveryOptions, setDeliveryOptions] = React.useState([]);
  const [contentTypeOptions, setContentTypeOptions] = React.useState([]);

  const getSkillOptions = async () => {
    const { data: res, isSuccess } = await api.cadsAICourse.getTrainingProviderOptions();
    if (isSuccess) {
      setSkillOptions(res?.data?.map((skill) => ({ value: skill.id, label: skill.title })));
    }
  };

  const getDeliveryOptions = async () => {
    const { data: res, isSuccess } = await api.cadsAICourse.getDeliveryMethodOptions();
    if (isSuccess) {
      setDeliveryOptions(
        res?.data?.map((delivery) => ({ value: delivery.id, label: delivery.title })),
      );
    }
  };

  const getCourseContentOptions = async () => {
    const { data: res, isSuccess } = await api.cadsAICourse.getCourseContentOptions();
    if (isSuccess) {
      setContentTypeOptions(res?.data?.map((a) => ({ value: a.id, label: a.title, ...a })));
    }
  };

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm(extractFieldData(page, data));

  const forSale = watch('forSale');
  const setForSale = (value) => setValue('forSale', value);

  React.useEffect(() => {
    getSkillOptions();
    getCourseContentOptions();
    getDeliveryOptions();
  }, []);

  React.useEffect(() => {
    getStepFields(page).forEach((key) => {
      setValue(key, data[key]);
      setValue('forSale', data?.isForSale ? 'true' : 'false')
    });
  }, [data]);

  return (
    <div className="modal__body add-edit-learning-path">
      <form className={`form form--horizontal`} onSubmit={handleSubmit(onSubmit)}>
        <div className="form__form-group mt-1">
          <span className="form__form-group-label">Image</span>
          <div className="form__form-group-field">
            <FormField
              name="imageUrl"
              as="input"
              placeholder="Enter Image"
              control={control}
              component={renderDropZoneField}
              defaultValue=""
            />
          </div>
        </div>
        <div className="form__form-group mt-1">
          <span className="form__form-group-label">Course Link</span>
          <div className="form__form-group-field">
            <FormField
              name="courseLink"
              control={control}
              component="input"
              defaultValue=""
              placeholder="Enter Course Link"
            />
          </div>
        </div>
        <div className="form__form-group">
                <span className="form__form-group-label">Is For Sale</span>
                <div className="form__form-group-field mt-2">
                  <RadioButtonField
                    radioValue={`${YesNoOptions[0].value}`}
                    name={'ForSale'}
                    onChange={setForSale}
                    value={forSale}
                    className="colored-click mr-5"
                    label={YesNoOptions[0].label}
                  />
                  <RadioButtonField
                    radioValue={`${YesNoOptions[1].value}`}
                    name={'ForSale'}
                    onChange={setForSale}
                    value={forSale}
                    className="colored-click mr-5"
                    label={YesNoOptions[1].label}
                  />
                </div>
              </div>
        <div className="form__form-group mt-1">
          <span className="form__form-group-label">Price</span>
          <div className="form__form-group-field">
            <FormField
              name="salePrice"
              control={control}
              component="input"
              type="number"
              defaultValue=""
              placeholder="Enter Price"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Is Meeting</span>
          <div className="form__form-group-field">
            <FormField
              name="isMeeting"
              control={control}
              component={SelectField}
              errors={errors}
              options={YesNoOptions}
              defaultValue={YesNoOptions[1]}
              isAboveError
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Start Date</span>
          <div className="form__form-group-field">
            <FormField
              name="startDate"
              type="date"
              placeholder="Enter Start Date"
              control={control}
              defaultValue=""
              isAboveError
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">End Date</span>
          <div className="form__form-group-field">
            <FormField
              name="endDate"
              type="date"
              placeholder="Enter End Date"
              control={control}
              defaultValue=""
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Location</span>
          <div className="form__form-group-field">
            <FormField
              name="location"
              as="input"
              placeholder="Enter Location"
              control={control}
              defaultValue=""
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Course Content</span>
          <div className="form__form-group-field">
            <FormField
              control={control}
              name="contentType"
              component={SelectField}
              clearable
              options={contentTypeOptions}
              placeholder="Select Course Content"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label mt-2">Delivery Method</span>
          <div className="form__form-group-field">
            <FormField
              control={control}
              name="deliveryMethod"
              component={SelectField}
              clearable
              options={deliveryOptions}
              placeholder="Select Delivery Method"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label mt-2">Training Provider</span>
          <div className="form__form-group-field">
            <FormField
              control={control}
              name="trainingProvider"
              component={SelectField}
              options={skillOptions}
              placeholder="Select Training Provider"
              rules={{ required: 'This is required field' }}
              errors={errors}
              isAboveError
            />
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar modal__footer">
          <Button onClick={previousPage} type="button" className="previous">
            Back
          </Button>
          <Button color="primary" type="submit">
            Next
          </Button>
        </ButtonToolbar>
      </form>
    </div>
  );
};

export default StepTwo;
