import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import AnnouncementViewDialogHeader from './AnnouncementViewDialogHeader';
import api from '@/utils/apiClient';
import LoadingIcon from 'mdi-react/LoadingIcon';
import SanitizeHTML from '@/shared/components/SanitizeHTML';
import moment from 'moment';

const AnnouncementViewDialog = ({ show, onHide, id, rtl }) => {
  const [modal] = useState({
    isOpen: show,
    title: 'View Announcement',
    hide: onHide,
  });

  const [state, setState] = useState({
    loading: true,
    announcement: {
      announcementHtml: '',
    },
    error: '',
  });

  useEffect(() => {
    if (id) getAnnouncementById(+id);
  }, [id]);

  const getAnnouncementById = async (announcementId) => {
    try {
      const { data: res, isSuccess } = await api.cadsAIAnnouncements.getAnnouncementById(
        announcementId,
      );
      if (id) {
        setState({ ...state, loading: false, announcement: res.data, error: '' });
      } else {
        throw new Error(res?.messages?.[0] ?? 'Error while getting announcement details');
      }
    } catch ({ message }) {
      setState({ ...state, loading: false, announcement: {}, error: message });
      console.error(message);
    }
  };

  const { error, loading, announcement } = state;

  return (
    <Modal
      isOpen={show}
      toggle={modal.hide}
      modalClassName={`${rtl.direction}-support`}
      className="modal-dialog--primary modal-dialog--header modal-view-dialog"
    >
      <AnnouncementViewDialogHeader modal={modal} announcement={announcement} />
      {/* Announcement Modal Body */}
      <div style={{ padding: 15 }}>
        {loading || !!error ? (
          <>
            {loading && (
              <div className="panel__refresh position-relative" style={{ height: 75 }}>
                <LoadingIcon />
              </div>
            )}
            <div className="text-center">
              {error && (
                <div>
                  <span className="lnr lnr-cross-circle modal__title-icon error" />
                </div>
              )}
              {loading ? 'Getting announcement details...' : error}
            </div>
          </>
        ) : (
          <>
            <table className="project-summary__info">
              <tbody>
                <tr>
                  <th>Title</th>
                  <td className="pl-3 w-100">
                    {announcement?.title ? (
                      <div className={'p-3 html_description'}>
                        <SanitizeHTML html={announcement?.title} />
                      </div>
                    ) : (
                      '-'
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Description</th>
                  <td className="pl-3 w-100">
                    {announcement?.description ? (
                      <div className={'p-3 html_description'}>
                        <SanitizeHTML html={announcement?.description} />
                      </div>
                    ) : (
                      '-'
                    )}
                  </td>
                </tr>
                <tr>
                  <th className="pt-4">From Date</th>
                  <td className="pt-4">
                    {announcement?.activeFromDate
                      ? moment(announcement?.activeFromDate).format('DD/MM/YY')
                      : '-'}
                  </td>
                </tr>
                <tr>
                  <th className="pt-4">To Date</th>
                  <td className="pt-4">
                    {announcement?.activeToDate
                      ? moment(announcement?.activeToDate).format('DD/MM/YY')
                      : '-'}
                  </td>
                </tr>
                <tr>
                  <th className="pt-4">Action Url</th>
                  {announcement?.actionUrl ? (
                    <td>
                      <a
                        href={`${announcement?.actionUrl?.startsWith('http') ? '' : 'https://'}${
                          announcement?.actionUrl
                        }`}
                        target="_blank"
                      >
                        {announcement?.actionUrl}
                      </a>
                    </td>
                  ) : (
                    <td>-</td>
                  )}
                </tr>
                <tr>
                  <th className="pt-4">Is Public</th>
                  <td className="pt-4">
                    {announcement?.isPublic ? (announcement?.isPublic ? 'Yes' : 'No') : '-'}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
      </div>
    </Modal>
  );
};

export default connect((state) => ({
  rtl: state.rtl,
}))(AnnouncementViewDialog);
