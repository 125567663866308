import React, { memo } from 'react';
import Collapse from '@/shared/components/Collapse';
import { useDispatch } from 'react-redux';
import { updateConfigPageDetails } from '../redux/actions';
import ReactQuill from 'react-quill';
import { toolbarOptions } from '@/shared/helpers/constants';

const PageFooterCollapse = ({ id, footer }) => {
  const dispatch = useDispatch();

  const handleChange = (fieldName, fieldValue) => {
    dispatch(updateConfigPageDetails({ id, [fieldName]: fieldValue }));
  };

  return (
    <Collapse title="Footer">
      <div className="form">
        <div className="form__form-group w-100">
          <span className="form__form-group-label">Page Footer</span>
          <div className="form__form-group-field">
            <ReactQuill
              key={'footer-' + id}
              value={footer}
              modules={toolbarOptions}
              onChange={(footer) => handleChange('footer', footer)}
            />
          </div>
        </div>
      </div>
    </Collapse>
  );
};

export default memo(PageFooterCollapse);
