import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

export const SubCategoryColumns = [
  {
    id: 'name',
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'description',
    disablePadding: false,
    label: 'Description',
  },
  {
    id: 'domains',
    disablePadding: false,
    label: 'Domains',
  },
  {
    id: 'skills',
    disablePadding: false,
    label: 'Skills',
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'Action',
  },
];

const createSortHandler = (property, onRequestSort) => (event) => {
  onRequestSort(event, property);
};

const SubcategoryTableColumns = ({ order, orderBy, rtl, onRequestSort }) => (
  <Fragment>
    <TableRow>
      {SubCategoryColumns.map((row) => (
        <TableCell
          className="material-table__cell material-table__cell--sort material-table__cell-center"
          key={row.id}
          align={'center'}
          padding={row.disablePadding ? 'none' : 'default'}
          sortDirection={orderBy === row.id ? order : false}
        >
          <TableSortLabel
            active={orderBy === row.id}
            direction={order}
            onClick={createSortHandler(row.id, onRequestSort)}
            className="material-table__sort-label"
            dir="ltr"
          >
            <b>{row.label}</b>
          </TableSortLabel>
        </TableCell>
      ))}
    </TableRow>
  </Fragment>
);

SubcategoryTableColumns.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default connect((state) => ({
  rtl: state.rtl,
}))(SubcategoryTableColumns);
