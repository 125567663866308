import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';
import { removeUserData } from '@/shared/helpers';

export const loginUserInitiate = createAction('LOGIN_PROCESS_INITIATED');
export const loginUserSuccess = createAction('LOGIN_USER_SUCCESS');
export const loginUserFailure = createAction('LOGIN_USER_FAILURE');
export const logoutUser = createAction('LOG_OUT_USER');

export const login = (loginData) => async (dispatch) => {
  try {
    dispatch(loginUserInitiate());
    const { data: loginResponse, isSuccess } = await api.auth.login(loginData);
    if (isSuccess) dispatch(loginUserSuccess(loginResponse));
    else dispatch(loginUserFailure(loginResponse));
  } catch (error) {
    dispatch(loginUserFailure({ message: 'error while logging in' }));
    throw error;
  }
};

export const logout = () => (dispatch) => {
  removeUserData();
  dispatch(logoutUser());
};
