import React, { Fragment } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import { Button, FormGroup, Input } from 'reactstrap';
import ViewModuleIcon from 'mdi-react/ViewModuleIcon';
import PencilIcon from 'mdi-react/PencilIcon';
import { useLearningPathUIContext } from '../LearningPathUIProvider';
import LearningPathTableColumns, { getLearningPathColumns } from './LearningPathTableColumns';
import { LearningPathTableSearchBar } from './LearningPathTableSearchBar';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getCPLearningPathsList,
  setLearningPathFilters,
  toggleCheckedLearningPath,
} from '../../redux/actions';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { defaultPaginationProps } from '@/shared/helpers';

const LearningPathTable = () => {
  const learningPathUIContext = useLearningPathUIContext();
  const dispatch = useDispatch();
  const {
    loading,
    filters,
    totalRecords,
    assignLearningPaths: learningPaths,
    checkedLearningPath,
    error,
  } = useSelector((state) => state.cpLearningPaths, shallowEqual);
  const {
    pageSize: rowsPerPage,
    pageNumber: page,
    sortBy: orderBy,
    isSortOrderDescending,
  } = filters;

  React.useEffect(() => {
    dispatch(getCPLearningPathsList(filters));
  }, [filters]);

  const handleRequestSort = (event, property) => {
    dispatch(
      setLearningPathFilters({ sortBy: property, isSortOrderDescending: !isSortOrderDescending }),
    );
  };

  const handleChangePage = (event, currentPage) => {
    dispatch(setLearningPathFilters({ pageSize: rowsPerPage, pageNumber: currentPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setLearningPathFilters({ pageSize: +event.target.value, pageNumber: 1 }));
  };

  const emptyRows =
    page === Math.ceil(totalRecords / rowsPerPage) ? rowsPerPage - learningPaths.length : 0;

  const LearningPathColumns = getLearningPathColumns(learningPathUIContext.isForAssignment);

  const renderActionButtons = ({ id: learningPathId }) => (
    <div className="actions">
      <Button
        className="icon mb-0"
        color="primary"
        outline
        onClick={() => learningPathUIContext.openEditLearningPathDialog(learningPathId)}
      >
        <p>
          <PencilIcon /> Edit
        </p>
      </Button>
    </div>
  );

  return (
    <Fragment>
      <div className="material-table__wrap">
        <LearningPathTableSearchBar />
        <Table className="material-table">
          <LearningPathTableColumns
            isForAssignment={learningPathUIContext.isForAssignment}
            order={isSortOrderDescending ? 'desc' : 'asc'}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rowsPerPage}
          />
          <TableBody>
            {(loading || !!error) && (
              <TableRow>
                <TableCell colSpan={LearningPathColumns.length}>
                  {loading && (
                    <div className={'text-center overflow-hidden'}>
                      <div className="panel__refresh position-relative" style={{ height: 75 }}>
                        <LoadingIcon />
                      </div>
                    </div>
                  )}
                  <div className={'text-center'}>
                    <p className={!!error ? 'm-5' : ''}>{error || 'Loading learningPaths...'}</p>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {!!!learningPaths.length && !loading && !error && (
              <TableRow style={{ height: 120 }}>
                <TableCell colSpan={LearningPathColumns.length} align="center">
                  {filters.status === null && filters.search === ''
                    ? 'No learning Paths'
                    : 'No learning Paths with applied filters'}
                </TableCell>
              </TableRow>
            )}
            {learningPaths?.map((learningPath) => {
              return (
                <TableRow className="material-table" tabIndex={-1} key={learningPath.id}>
                  {learningPathUIContext.isForAssignment && (
                    <TableCell align="center" className="material-table__cell">
                      <FormGroup>
                        <Input
                          className="table-checkbox"
                          type="checkbox"
                          value={learningPath.id}
                          checked={checkedLearningPath.id === learningPath.id}
                          onChange={() => dispatch(toggleCheckedLearningPath(learningPath))}
                        />
                      </FormGroup>
                    </TableCell>
                  )}
                  <TableCell align="center" className="material-table__cell">
                    {learningPath?.imageUrl?.startsWith('http') ? (
                      <img
                        className="lp_logo_image"
                        src={learningPath?.imageUrl}
                        alt="learning path logo"
                      />
                    ) : (
                      <span className="lp_default_logo">
                        <ViewModuleIcon />
                      </span>
                    )}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {learningPath?.fullName}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {learningPath?.shortName}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {learningPath?.group?.name}
                  </TableCell>
                  {/* <TableCell align="center" className="material-table__cell">{learningPath?.isMain ? 'Yes' : 'No'}</TableCell> */}
                  <TableCell align="center" className="material-table__cell">
                    {!learningPathUIContext.isForAssignment && renderActionButtons(learningPath)}
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={LearningPathColumns.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        component={defaultPaginationProps.component}
        backIconButtonProps={defaultPaginationProps.backIconButton}
        nextIconButtonProps={defaultPaginationProps.nextIconButton}
        rowsPerPageOptions={defaultPaginationProps.rowsPerPage}
        className={defaultPaginationProps.className}
        dir={defaultPaginationProps.dir}
      />
    </Fragment>
  );
};

export default LearningPathTable;
