import React from 'react';
import { paths } from '@/shared/helpers/paths';
import { Route, Switch } from 'react-router-dom';
import { ModuleCard } from './ModuleCard';
import { setCadsAiModuleFilters, uncheckAllSelectedModule } from '../redux/actions';
import { ModuleUIProvider } from './ModuleUIProvider';
import ModuleEditDialog from './module-add-edit-dialog/ModuleEditDialog';
import { useDispatch } from 'react-redux';
import ModuleDeleteDialog from './module-delete-dialog/ModuleDeleteDialog';
import ModuleViewDialog from './module-view-dialog/ModuleViewDialog';
import { ModuleUpdateStatusDialog } from './module-updates-status-dialog/ModuleUpdateStatusDialog';

const moduleBaseUrl = paths.CADS_AI.LEARNING.MODULE;

export function ModulePage({ history }) {
  const dispatch = useDispatch();

  const moduleUIEvents = {
    openViewModuleDialog: (id) => {
      history.push(`${moduleBaseUrl}/${id}/view`);
    },
    openNewModuleDialog: () => {
      history.push(`${moduleBaseUrl}/new`);
    },
    openEditModuleDialog: (id) => {
      history.push(`${moduleBaseUrl}/${id}/edit`);
    },
    openDeleteModuleDialog: (id) => {
      history.push(`${moduleBaseUrl}/${id}/delete`);
    },
    openUpdateStatusDialog: () => {
      history.push(`${moduleBaseUrl}/update-status`);
    },
  };

  React.useEffect(() => {
    dispatch(uncheckAllSelectedModule());
  }, []);

  return (
    <ModuleUIProvider moduleUIEvents={moduleUIEvents}>
      <Switch>
        <Route path={`${moduleBaseUrl}/new`}>
          {({ history, match }) => (
            <ModuleEditDialog
              show={match != null}
              isEdit={false}
              onHide={() => {
                dispatch(setCadsAiModuleFilters());
                history.push(moduleBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${moduleBaseUrl}/:id/edit`}>
          {({ history, match }) => (
            <ModuleEditDialog
              show={match != null}
              isEdit={true}
              id={match?.params?.id}
              onHide={() => {
                dispatch(setCadsAiModuleFilters());
                history.push(moduleBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${moduleBaseUrl}/:id/delete`}>
          {({ history, match }) => (
            <ModuleDeleteDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                history.push(moduleBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${moduleBaseUrl}/:id/view`}>
          {({ history, match }) => (
            <ModuleViewDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                history.push(moduleBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${moduleBaseUrl}/update-status`}>
          {({ history, match }) => (
            <ModuleUpdateStatusDialog
              show={match != null}
              isEdit={false}
              onHide={() => {
                dispatch(setCadsAiModuleFilters());
                history.push(moduleBaseUrl);
              }}
            />
          )}
        </Route>
      </Switch>
      <ModuleCard />
    </ModuleUIProvider>
  );
}
