import React, { createContext, useContext } from 'react';

const SponsorshipUIContext = createContext();

export function useSponsorshipUIContext() {
  return useContext(SponsorshipUIContext);
}

export function SponsorshipUIProvider({ sponsorshipUIEvents, children }) {
  const value = {
    openViewSponsorshipDialog: sponsorshipUIEvents.openViewSponsorshipDialog,
    openNewSponsorshipDialog: sponsorshipUIEvents.openNewSponsorshipDialog,
    openEditSponsorshipDialog: sponsorshipUIEvents.openEditSponsorshipDialog,
    openDeleteSponsorshipDialog: sponsorshipUIEvents.openDeleteSponsorshipDialog,
  };

  return <SponsorshipUIContext.Provider value={value}>{children}</SponsorshipUIContext.Provider>;
}
