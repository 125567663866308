import { handleActions } from 'redux-actions';
import {
  setCourseFetching,
  setAssignCourseList,
  setAssignedCourseList,
  setCourseError,
  toggleCheckedCourse,
  uncheckAllCourse,
} from './actions';

const defaultState = {
  loading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    searchText: '',
    status: null,
    isSortOrderDescending: false,
  },
  totalRecords: 0,
  assignCourses: [],
  assignedCourses: [],
  checkedCourseIds: [],
  error: null,
};

export default handleActions(
  {
    [setCourseFetching](state) {
      return {
        ...state,
        assignCourses: [],
        assignedCourses: [],
        totalRecords: 0,
        loading: true,
        error: null,
      };
    },
    [setCourseError](state, { payload }) {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    },
    [setAssignCourseList](state, { payload }) {
      const assignCourseList = payload;
      return {
        ...state,
        assignCourses: assignCourseList,
        totalRecords: assignCourseList.length,
        loading: false,
        error: null,
      };
    },
    [setAssignedCourseList](state, { payload }) {
      const assignedCourseList = payload;
      return {
        ...state,
        assignedCourses: assignedCourseList,
        totalRecords: assignedCourseList.length,
        loading: false,
        error: null,
      };
    },
    [toggleCheckedCourse](state, { payload: courseId }) {
      const checkedIds = state.checkedCourseIds;
      return {
        ...state,
        checkedCourseIds: checkedIds.includes(courseId)
          ? checkedIds.filter((id) => id !== courseId)
          : [...checkedIds, courseId],
      };
    },
    [uncheckAllCourse](state) {
      return {
        ...state,
        checkedCourseIds: [],
      };
    },
  },
  defaultState,
);
