import React from 'react';
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon';
import SanitizeHTML from '@/shared/components/SanitizeHTML';

const Preview = ({setTooglePreviewPage, data}) => {
  return (
      <div>
        <ArrowLeftIcon className="preview-back-icon" onClick={() => setTooglePreviewPage(false)}/>
        <div className="preview-image">
          <div className="preview-bg">
            <img src={`${data?.backgroundUrl[0]?.preview}`}/>
          </div>
          <div className="preview-logo">
            <img src={`${data?.logoUrl[0]?.preview}`}/>
          </div>
      </div>
      <div className="preview-title">{data?.title}</div>
      <h4 className="preview-description-heading mt-2">Accelerator Details</h4>
      <SanitizeHTML className="preview-description" html={data?.description} />
      <h4 className="preview-description-heading mt-2">Sponsors</h4>
      <div>
        {data?.sponsorList?.map((item) => (
            <img className="logo-image" src={item.sponsor?.logoUrl ?? item?.logoUrl}/>
        ))}
      </div>
    </div>
  );
};

export default Preview;
