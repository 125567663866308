import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import SubcategoryManageSkillForm from './SubcategoryManageSkillForm';
import SubcategoryManageSkillDialogHeader from './SubcategoryManageSkillDialogHeader';

const SubcategoryManageSkillDialog = ({ show, onHide, id, rtl }) => {
  const [modal, setModal] = useState({
    isOpen: show,
    title: 'Manage Skills',
    hide: onHide,
  });

  return (
    <Modal
      isOpen={show}
      toggle={modal.hide}
      modalClassName={`${rtl.direction}-support`}
      className="modal-dialog--primary modal-dialog--header modal-edit-dialog"
    >
      <SubcategoryManageSkillDialogHeader modal={modal} />
      <SubcategoryManageSkillForm modal={modal} id={id} />
    </Modal>
  );
};

export default connect((state) => ({
  rtl: state.rtl,
}))(SubcategoryManageSkillDialog);
