import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, ButtonToolbar } from 'reactstrap';
import api from '@/utils/apiClient';
import { emptyStringPattern } from '@/shared/helpers';
import { FormField } from '@/shared/components/form/hook/FormField';
import { extractFieldData, getStepFields } from '../index';
import { SelectField } from '@/shared/components/form/Select';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { getOrganizationOptionsList } from '@/containers/Cads-AI/redux/organization/actions';

const StepOne = ({ onSubmit, page, data }) => {
  const dispatch = useDispatch();
  //states
  const [jobLevelOptions, setJobLevelOptions] = React.useState([]);
  const [jobTypeOptions, setJobTypeOptions] = React.useState([]);
  const [organizationOptions, setOrganizationOptions] = React.useState([]);
  const { options: organizationOpts } = useSelector((state) => state.cadsAIOrgs, shallowEqual);

  const getJobLevelOptions = async () => {
    const { data: res, isSuccess } = await api.cadsAIJob.getJobLevelOptions();
    if (isSuccess) {
      setJobLevelOptions(res.data.map((skill) => ({ value: skill.id, label: skill.title })));
    }
  };

  const getJobTypeOptions = async () => {
    const { data: res, isSuccess } = await api.cadsAIJob.getJobTypeOptions();
    if (isSuccess) {
      setJobTypeOptions(res.data.map((skill) => ({ value: skill.id, label: skill.title })));
    }
  };

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm(extractFieldData(page, data));

  React.useEffect(() => {
    dispatch(getOrganizationOptionsList());
    getJobLevelOptions();
    getJobTypeOptions();
  }, []);

  React.useEffect(() => {
    getStepFields(page).forEach((key) => {
      setValue(key, data[key]);
    });
  }, [data]);

  React.useMemo(() => {
    setOrganizationOptions(
      organizationOpts?.map((org) => ({ value: org.id, label: org.title })) ?? [],
    );
  }, [organizationOpts]);

  return (
    <div className="modal__body add-edit-learning-path">
      <form className={`form form--horizontal`} onSubmit={handleSubmit(onSubmit)}>
        <div className="form__form-group">
          <span className="form__form-group-label">Job Title</span>
          <div className="form__form-group-field">
            <FormField
              name="title"
              control={control}
              component="input"
              errors={errors}
              rules={{
                required: 'This is required field',
                pattern: emptyStringPattern,
              }}
              defaultValue=""
              isAboveError={true}
              placeholder="Name of job"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Job Description</span>
          <div className="form__form-group-field">
            <FormField
              name="description"
              control={control}
              component="textarea"
              errors={errors}
              rules={{
                required: 'This is required field',
                pattern: emptyStringPattern,
              }}
              defaultValue=""
              isAboveError={true}
              placeholder="Write description about job"
            />
          </div>
        </div>
        <div className="form__form-group mt-3">
          <span className="form__form-group-label">Select Organization</span>
          <div className="form__form-group-field">
            <FormField
              control={control}
              name="organization"
              component={SelectField}
              options={organizationOptions}
              placeholder="Select Organization"
              errors={errors}
              clearable={true}
              isAboveError
            />
          </div>
        </div>
        <div className="form__form-group mt-3">
          <span className="form__form-group-label">Select Level</span>
          <div className="form__form-group-field">
            <FormField
              control={control}
              name="jobLevel"
              component={SelectField}
              options={jobLevelOptions}
              placeholder="Select Job Level"
              rules={{ required: 'This is required field' }}
              errors={errors}
              isAboveError
            />
          </div>
        </div>
        <div className="form__form-group mt-3">
          <span className="form__form-group-label">Select Type</span>
          <div className="form__form-group-field">
            <FormField
              control={control}
              name="jobType"
              component={SelectField}
              options={jobTypeOptions}
              placeholder="Select Job Type"
              rules={{ required: 'This is required field' }}
              errors={errors}
              isAboveError
            />
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar modal__footer">
          <Button color="primary" type="submit">
            Next
          </Button>
        </ButtonToolbar>
      </form>
    </div>
  );
};

export default StepOne;
