import React from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import LearningPathTable from './LearningPathTable';
import { useLearningPathUIContext } from '../LearningPathUIProvider';
import { useSelector, shallowEqual } from 'react-redux';

const LearningPathTableWrapper = () => {
  const learningPathUIContext = useLearningPathUIContext();
  const checkedLPIds = useSelector((state) => state.cadsAILearningPaths.checkedLPIds, shallowEqual);

  return learningPathUIContext.isForAssignment ? (
    <LearningPathTable />
  ) : (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="products-list">
          <div className="card__title">
            <h5 className="bold-text">Learning Path List</h5>
            <ButtonToolbar className="products-list__btn-toolbar-top">
              <Button color="primary" onClick={learningPathUIContext.openNewLearningPathDialog}>
                Add new Learning Path
              </Button>
              <Button
                color="danger"
                outline
                onClick={learningPathUIContext.openUpdateStatusDialog}
                outline
                disabled={checkedLPIds.length === 0}
              >
                Deactivate
              </Button>
            </ButtonToolbar>
          </div>
          <LearningPathTable />
        </CardBody>
      </Card>
    </Col>
  );
};

export default LearningPathTableWrapper;
