import React, { useState } from 'react';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import moment from 'moment';

const renderBasicDetails = (courses) => (
  <table className="project-summary__info">
    <tr>
      <th>Name</th>
      <td>{courses?.title}</td>
    </tr>
    <tr>
      <th>Description</th>
      <td>{courses?.description}</td>
    </tr>
    <tr>
      <th>Summary</th>
      <td>{courses?.summary}</td>
    </tr>
    <tr>
      <th>Is Verified</th>
      <td>{courses?.isVerified ? 'Yes' : 'No'}</td>
    </tr>
    <tr>
      <th>Is Provided By Cads</th>
      <td>{courses?.isProvidedByCads ? 'Yes' : 'No'}</td>
    </tr>
    <tr>
      <th>Is Platform Wide</th>
      <td>{courses?.isPlatformWide ? 'Yes' : 'No'}</td>
    </tr>
    <tr>
      <th>Duration</th>
      <td>{courses?.durationInMinutes}</td>
    </tr>
    <tr>
      <th className="w-25">Confirm Date</th>
      <td>{courses?.confirmDate ? moment(courses?.confirmDate).format('DD/MM/yyyy') : '-'}</td>
    </tr>
  </table>
);

const renderOtherDetails = (courses) => (
  <table className="project-summary__info">
    <tr>
      <th>Image</th>
      <td>
        <img
          className="course_logo_image"
          src={courses?.imageUrl ?? 'https://unified.dev.cads.ai/image/sample/rectangle-34.png'}
        />
      </td>
    </tr>
    <tr>
      <th>Location</th>
      <td>{courses?.location ?? '-'}</td>
    </tr>
    <tr>
      <th>Is For Sale</th>
      <td>{courses?.isForSale ? 'Yes' : 'No'}</td>
    </tr>
    <tr>
      <th>Price</th>
      <td>{courses?.salePrice ?? '-'}</td>
    </tr>
    <tr>
      <th>Is Meeting</th>
      <td>{courses?.isMeeting ? 'Yes' : 'No'}</td>
    </tr>
    <tr>
      <th>Start Date</th>
      <td>{courses?.startDate ? moment(courses?.startDate).format('DD/MM/yyyy') : '-'}</td>
    </tr>
    <tr>
      <th>End Date</th>
      <td>{courses?.endDate ? moment(courses?.endDate).format('DD/MM/yyyy') : '-'}</td>
    </tr>
    <tr>
      <th>Delivery Method</th>
      <td>{courses?.deliveryMethod?.title}</td>
    </tr>
    <tr>
      <th>Content Type</th>
      <td>{courses?.contentType?.title}</td>
    </tr>
    <tr>
      <th>Training Provider</th>
      <td>{courses?.trainingProvider?.title}</td>
    </tr>
    <tr>
      <th className="w-25">Course Link</th>
      <td>{courses?.courseLink}</td>
    </tr>
  </table>
);

const renderLinksDetails = (courses) => (
  <table className="project-summary__info">
    <tr>
      <th>Material Link</th>
      <td>
        <a
          href={`${courses?.masterMaterialsLink?.startsWith('http') ? '' : 'https://'}${
            courses?.masterMaterialsLink
          }`}
          target="_blank"
        >
          {courses?.masterMaterialsLink}
        </a>
      </td>
    </tr>
    <tr>
      <th>Participtants Link</th>
      <td>
        <a
          href={`${courses?.participantsMaterialsLink?.startsWith('http') ? '-' : 'https://'}${
            courses?.masterMaterialsLink
          }`}
          target="_blank"
        >
          {courses?.participantsMaterialLink}
        </a>
      </td>
    </tr>
  </table>
);

export default function ({ courses }) {
  const tabs = React.useMemo(
    () => ({
      BASIC_DETAILS: {
        id: 'BASIC_DETAILS',
        label: 'Basic Details',
        renderTab: () => renderBasicDetails(courses),
      },
      OTHER_DETAILS: {
        id: 'OTHER_DETAILS',
        label: 'Other Details',
        renderTab: () => renderOtherDetails(courses),
      },
      LINK_DETAILS: {
        id: 'LINK_DETAILS',
        label: 'Links',
        renderTab: () => renderLinksDetails(courses),
      },
    }),
    [courses],
  );

  const [activeTab, setActiveTab] = useState(tabs.BASIC_DETAILS.id);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Row className="p-1">
      <Col>
        <div className="tabs tabs--bordered-bottom">
          <div className="tabs__wrap">
            <Nav tabs>
              {Object.keys(tabs).map((key) => (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === tabs[key].id })}
                    onClick={() => toggle(tabs[key].id)}
                  >
                    {tabs[key].label}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={activeTab}>{tabs[activeTab]?.renderTab()}</TabPane>
            </TabContent>
          </div>
        </div>
      </Col>
    </Row>
  );
}
