import React from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ControlPanelAssignUIProvider from './ControlPanelAssignUIProvider';
import ControlPanelAssignTabs from './ControlPanelAssignTabs';
import { Col, Container, FormGroup, Input, Row } from 'reactstrap';
import AssignDialog from './assign-dialog/AssignDialog';
import { findDataInArray } from '@/shared/helpers';
import { setSelectedAssignProduct } from '@/containers/Control-Panel/redux/actions';
import { setUserState } from '@/containers/Control-Panel/redux/user/actions';
import { setActiveTab } from '@/containers/Control-Panel/Assign/redux/actions';
import { CP_PRODUCTS } from '@/shared/helpers/constants';
import * as Constants from '@/shared/helpers/constants';
import { isEmpty } from 'lodash/lang';
import AssignWizardPage from './assign-wizard/AssignWizardPage';
import { paths } from '@/shared/helpers/paths';
import { setCohortState } from '@/containers/Control-Panel/redux/cohort/actions';
import { setOrgState } from '@/containers/Control-Panel/redux/organization/actions';
import { defaultUserState } from '@/containers/Control-Panel/redux/user/reducer';
import { defaultCohortState } from '@/containers/Control-Panel/redux/cohort/reducer';
import { defaultOrgState } from '@/containers/Control-Panel/redux/organization/reducer';

const { CHATBOT, ACCELTIC, DDO, MODULE, LEARNING_PATH, TRAINING_PROVIDER } = CP_PRODUCTS;
const { COHORT, ORGANIZATION, USERS } = Constants;
const productList = [ACCELTIC, CHATBOT, DDO, MODULE, LEARNING_PATH, TRAINING_PROVIDER];
const controlPanelAssignBaseUrl = paths.CONTROL_PANEL.ASSIGN;
const AssignPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { activeTab } = useSelector((state) => state.assignPage, shallowEqual);
  const { checkedItemIds } = useSelector((state) => state.assignPage, shallowEqual);
  const { assignedOrgId, assignedProduct } = useSelector(
    (state) => state.controlPanel,
    shallowEqual,
  );
  const [selectedProduct, setSelectedProduct] = React.useState(assignedProduct);

  React.useEffect(() => {
    const activeTabParam = new URLSearchParams(location.search)?.get('activeTab');
    const unassignedProductId = new URLSearchParams(location.search)?.get('unassignmentFor');

    if (
      activeTabParam &&
      activeTabParam !== activeTab &&
      [USERS, COHORT, ORGANIZATION].includes(activeTab)
    ) {
      dispatch(setActiveTab(activeTab));
    }
    if (unassignedProductId) {
      const productIdx = productList.findIndex((product) => product.id === +unassignedProductId);
      if (productIdx !== -1 && productList[productIdx] !== selectedProduct)
        dispatch(setSelectedAssignProduct(productList[productIdx]));
    }
  }, [location.search]);

  const assignUIEvents = {
    openAssignDialog: () => {
      history.push(`${controlPanelAssignBaseUrl}/confirm-assign`);
    },
  };

  const handleChange = ({ target: { value } }) => {
    const productId = +value;
    setSelectedProduct(productList.find((product) => product.id === productId));
    const product = findDataInArray(productList, productId, 'id');
    dispatch(setSelectedAssignProduct(product));
    if ([ACCELTIC.id, DDO.id, CHATBOT.id].includes(productId)) {
      const filters = { assigned: false, feature: productId };
      switch (activeTab) {
        case USERS:
          return dispatch(
            setUserState({
              ...defaultUserState,
              ...filters,
              searchAssign: { ...defaultUserState.searchAssign, orgId: assignedOrgId },
            }),
          );
        case COHORT:
          return dispatch(
            setCohortState({
              ...defaultCohortState,
              ...filters,
              searchAssign: { ...defaultCohortState.searchAssign, orgId: assignedOrgId },
            }),
          );
        case ORGANIZATION:
          return dispatch(setOrgState({ ...defaultOrgState, ...filters }));
        default:
          return false;
      }
    }
  };

  return (
    <ControlPanelAssignUIProvider assignUIEvents={assignUIEvents}>
      <Switch>
        <Route path={`${controlPanelAssignBaseUrl}/confirm-assign`}>
          {({ history, match }) => {
            if (isEmpty(checkedItemIds)) history.push(controlPanelAssignBaseUrl);
            else
              return (
                <AssignDialog
                  show={match != null}
                  isEdit={false}
                  onHide={() => {
                    history.push(controlPanelAssignBaseUrl);
                  }}
                />
              );
          }}
        </Route>
      </Switch>

      <Col md={12} lg={12}>
        <Container>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Row>
                  <Col sm={9}>
                    <h3 className="page-title">{selectedProduct.name}</h3>
                    <h3 className="page-subhead subhead">
                      From here you can manage the assignment for{' '}
                      {selectedProduct.name?.toLowerCase()}.
                    </h3>
                  </Col>
                  <Col sm={3}>
                    Assignment For
                    <Input
                      id="assignProductSelect"
                      name="select"
                      type="select"
                      value={selectedProduct.id}
                      onChange={handleChange}
                    >
                      {productList.map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </Row>
              </FormGroup>
            </Col>
            {[MODULE, LEARNING_PATH, TRAINING_PROVIDER].includes(selectedProduct) ? (
              <AssignWizardPage selectedProduct={selectedProduct} />
            ) : (
              <ControlPanelAssignTabs />
            )}
          </Row>
        </Container>
      </Col>
    </ControlPanelAssignUIProvider>
  );
};

export default AssignPage;
