import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const fetchSunrise25UserList = createAction('FETCH_SUNRISE_25_USERS_LIST');
export const setSunrise25UserList = createAction('SET_SUNRISE_25_USER_LIST');
export const setSunrise25UserError = createAction('SET_SUNRISE_25_USER_ERROR');

export const getSunrise25UsersList = (filter) => async (dispatch) => {
  const defaultError = 'Error occurred getting sunrise 25 users list';
  try {
    dispatch(fetchSunrise25UserList());
    const { data, isSuccess } = await api.sunrise25Users.getSunrise25CampaignReport(filter);
    if (isSuccess) dispatch(setSunrise25UserList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setSunrise25UserError(error));
    console.error(defaultError, error.message);
  }
};
