import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import ApplicantsTableWrapper from './table/ApplicantTableWrapper';
import { Route, Switch } from 'react-router';
import { cadsAiApplicantsBaseUrl } from '../index';
import ApplicantDetailsPage from './applicant-details-page';

export function ApplicantsCard() {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Applicants</h3>
        </Col>
      </Row>
      <Row>
        <Switch>
          <Route exact path={`${cadsAiApplicantsBaseUrl}/:id/view`}>
            {({ history, match }) => (
              <ApplicantDetailsPage
                show={match != null}
                id={match?.params?.id}
                onHide={() => {
                  history.push(cadsAiApplicantsBaseUrl);
                }}
              />
            )}
          </Route>
          <Route path="">
            <ApplicantsTableWrapper />
          </Route>
        </Switch>
      </Row>
    </Container>
  );
}
