import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { RTLProps } from '@/shared/prop-types/ReducerProps';

export const applicantsColumns = [
  {
    id: 'applicantSelect',
    label: 'Select',
  },
  { id: 'image', label: '' },
  {
    id: 'userName',
    label: 'Name',
  },
  /*  {
    id: "userEmail",
    label: "Email",
  },*/
  {
    id: 'programName',
    label: 'Program Name',
  },
  {
    id: 'programStartDate',
    label: 'Program Start Date',
  },
  {
    id: 'programEndDate',
    label: 'Program End Date',
  },
  {
    id: 'applicantCohort.name',
    label: 'Cohort',
  },
  {
    id: 'isBhumiputra',
    label: 'Bumi/ Citizen',
  },
  {
    id: 'dateApplied',
    label: 'Date Applied',
  },
  {
    id: 'applicantStage.name',
    label: 'Application Stage',
  },
  {
    id: 'applicantStatus.name',
    label: 'Application Status',
  },
];

const createSortHandler = (property, onRequestSort) => (event) => {
  onRequestSort(event, property);
};

const ApplicantsTableColumns = ({ order, orderBy, rtl, onRequestSort }) => (
  <Fragment>
    <TableRow>
      {applicantsColumns.map((row) => (
        <TableCell
          className="material-table__cell material-table__cell--sort material-table__cell-center"
          key={row.id}
          align={row.id === 'user_name' ? 'left' : 'center'}
          padding={row.disablePadding ? 'none' : 'default'}
          sortDirection={orderBy === row.id ? order : false}
        >
          <TableSortLabel
            active={orderBy === row.id}
            direction={order}
            onClick={createSortHandler(row.id, onRequestSort)}
            className="material-table__sort-label"
            dir="ltr"
          >
            <b>{row.label}</b>
          </TableSortLabel>
        </TableCell>
      ))}
    </TableRow>
  </Fragment>
);

ApplicantsTableColumns.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rtl: RTLProps.isRequired,
};

export default connect((state) => ({
  rtl: state.rtl,
}))(ApplicantsTableColumns);
