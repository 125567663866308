import React, { useEffect } from 'react';
import { paths } from '@/shared/helpers/paths';
import { CourseCard } from './CourseCard';
import { CourseUIProvider } from './CourseUIProvider';
import {
  clearCheckedCourse,
  setCadsAiCourseFilters,
  uncheckAllSelectedCourses,
} from '../redux/actions';
import CourseEditDialog from './course-add-edit-dialog/CourseEditDialog';
import { Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CourseUpdateStatusDialog } from './course-update-status-dialog/CourseUpdateStatusDialog';
import CourseViewDialog from './course-view-dialog/CourseViewDialog';

const courseBaseUrl = paths.CADS_AI.LEARNING.COURSE;

export function CoursePage({ history, isForAssignment = false }) {
  //clean up
  const dispatch = useDispatch();
  const courseUIEvents = {
    openViewCourseDialog: (id) => {
      history.push(`${courseBaseUrl}/${id}/view`);
    },
    openEditCourseDialog: (id) => {
      history.push(`${courseBaseUrl}/${id}/edit`);
    },
    openAddCourseDialog: () => {
      history.push(`${courseBaseUrl}/new`);
    },
    openUpdateCourseDialog: () => {
      history.push(`${courseBaseUrl}/update-status`);
    },
  };
  useEffect(() => {
    dispatch(uncheckAllSelectedCourses());
    dispatch(clearCheckedCourse());
  }, []);
  return (
    <CourseUIProvider isForAssignment={isForAssignment} courseUIEvents={courseUIEvents}>
      <Switch>
        <Route path={`${courseBaseUrl}/new`}>
          {({ history, match }) => (
            <CourseEditDialog
              show={match != null}
              isEdit={false}
              onHide={() => {
                dispatch(setCadsAiCourseFilters());
                history.push(courseBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${courseBaseUrl}/:id/edit`}>
          {({ history, match }) => (
            <CourseEditDialog
              show={match != null}
              isEdit={true}
              id={match?.params?.id}
              onHide={() => {
                history.push(courseBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${courseBaseUrl}/update-status`}>
          {({ history, match }) => (
            <CourseUpdateStatusDialog
              show={match != null}
              isEdit={false}
              onHide={() => {
                dispatch(setCadsAiCourseFilters());
                history.push(courseBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${courseBaseUrl}/:id/view`}>
          {({ history, match }) => (
            <CourseViewDialog
              show={match != null}
              isEdit={false}
              id={match?.params?.id}
              onHide={() => {
                dispatch(setCadsAiCourseFilters());
                history.push(courseBaseUrl);
              }}
            />
          )}
        </Route>
      </Switch>
      <CourseCard />
    </CourseUIProvider>
  );
}
