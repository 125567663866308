import React, { useMemo } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { useSubcategoryUIContext } from './SubcategoryUIProvider';
import SubcategoryTableWrapper from './table/SubcategoryTableWrapper';

export function SubcategoryCard() {
  const subCategoryUIContext = useSubcategoryUIContext();
  const subcategoryUIProps = useMemo(() => {
    return {
      openNewSubCategoryDialog: subCategoryUIContext.openNewSubCategoryDialog,
    };
  }, [subCategoryUIContext]);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Subcategory List</h3>
          <h3 className="page-subhead subhead">
            Here you can manage all the subcategories under base skills.
          </h3>
        </Col>
      </Row>
      <Row>
        <SubcategoryTableWrapper />
      </Row>
    </Container>
  );
}
