import React from 'react';
import { CardBody } from 'reactstrap';
import AssignTable from './AssignTable';
import AssignTableFilter from './AssignTableFilter';

const AssignTableWrapper = () => {
  return (
    <CardBody>
      <AssignTableFilter />
      <AssignTable />
    </CardBody>
  );
};

export default AssignTableWrapper;
