import { Row, Col, Container } from 'reactstrap';
import Sunrise25UsersTableWrapper from './table/Sunrise25UsersTableWrapper';

const Sunrise25UsersCard = () => {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Sunrise 25 Users List</h3>
          <h3 className="page-subhead subhead">
            Here you can manage all the sunrise 25 users under campaign.
          </h3>
        </Col>
      </Row>
      <Row>
        <Sunrise25UsersTableWrapper />
      </Row>
    </Container>
  );
};

export default Sunrise25UsersCard;
