import React from 'react';
import Panel from '@/shared/components/Panel';
import HeartIcon from 'mdi-react/HeartIcon';

const benifits = [
  {
    title: 'Benefits',
    description:
      'While creative writing does not necessarily follow the traditional paragraph structure, it’s more about scene building and continuing a narrative. Efficient, well-written paragraphs are a staple of good flash fiction and short fiction writing, as short stories need to stay more focused on a central idea. ',
  },
  {
    title: 'Benefits',
    description:
      'While creative writing does not necessarily follow the traditional paragraph structure, it’s more about scene building and continuing a narrative. Efficient, well-written paragraphs are a staple of good flash fiction and short fiction writing, as short stories need to stay more focused on a central idea. ',
  },
  {
    title: 'Benefits',
    description:
      'While creative writing does not necessarily follow the traditional paragraph structure, it’s more about scene building and continuing a narrative. Efficient, well-written paragraphs are a staple of good flash fiction and short fiction writing, as short stories need to stay more focused on a central idea. ',
  },
];

const CompanyBenefits = () => {
  return (
    <div className="pt-5 applicant_panel_card">
      <h4 className="mb-4">Company Benefits</h4>
      {benifits?.map?.((exp, idx) => (
        <Panel title={exp.title} key={idx}>
          <div className="d-flex">
            <div class="benifits-image">
              <HeartIcon />
            </div>
            <h5 className="ml-3 panel_details">
              {exp?.title}
              <br />
              <div className="mt-2">{exp?.description}</div>
            </h5>
          </div>
        </Panel>
      ))}
    </div>
  );
};

export default CompanyBenefits;
