import React from 'react';
import { Field, Form } from 'react-final-form';
import { Alert, Button } from 'reactstrap';
import { NavLink, withRouter } from 'react-router-dom';
import { paths } from '@/shared/helpers/paths';
import api from '@/utils/apiClient';

const ResetPasswordForm = ({ location }) => {
  const [state, setState] = React.useState({ inProgress: false });
  const [token, setToken] = React.useState('');

  // get password reset token
  React.useEffect(() => {
    const searchStr = location.search;
    const tokenId = '?token=';
    if (searchStr.includes(tokenId)) {
      setToken(searchStr.substring(searchStr.indexOf(tokenId) + tokenId.length));
    }
  }, [location]);

  const onSubmit = async (passResetFormData) => {
    const passResetData = { ...passResetFormData, resetKey: token };
    setState({ ...state, inProgress: true, error: '' });
    try {
      const { data, isSuccess } = await api.auth.resetPassword(passResetData);
      setState({
        isSuccess: isSuccess && data?.data,
        error: data?.messages?.[0],
        inProgress: false,
      });
    } catch (error) {
      console.error('Error occurred while resetting password : ', error);
      setState({ isSuccess: false, inProgress: false, error: error.message });
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <form className="form reset-password-form" onSubmit={handleSubmit}>
          <div className="form login-form w-100">
            <Alert color="danger" isOpen={state.isSuccess === false}>
              {state?.error || 'Error in reset password'}
            </Alert>
            <Alert color="primary" isOpen={state.isSuccess === true}>
              Password reset successful! <br />
              Go back to login page and login with new password.
            </Alert>
          </div>
          {!!state?.isSuccess === false && (
            <>
              <div className="form__form-group">
                <span className="form__form-group-label">New Password</span>
                <div className="form__form-group-field">
                  <Field
                    name="newPassword"
                    id="newPassword"
                    component="input"
                    type="password"
                    defaultValue={''}
                    placeholder="Enter new password"
                    className="input-without-border-radius"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Confirm New Password</span>
                <div className="form__form-group-field">
                  <Field
                    name="renewPassword"
                    id="renewPassword"
                    component="input"
                    defaultValue={''}
                    type="password"
                    placeholder="Retype new password"
                    className="input-without-border-radius"
                  />
                </div>
              </div>
              <Button
                className="account__btn"
                type="submit"
                color="primary"
                disabled={!!state?.inProgress}
              >
                Submit
              </Button>
            </>
          )}
          <div className="account__have-account">
            <p>
              {state?.isSuccess === true ? 'Login with new password ?' : 'Remember your password?'}{' '}
              <NavLink to={paths.LOGIN}>Login</NavLink>
            </p>
          </div>
        </form>
      )}
    </Form>
  );
};

export default withRouter(ResetPasswordForm);
