import React from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import Sunrise25UsersTable from './Sunrise25UsersTable';

const Sunrise25UsersTableWrapper = () => {
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="products-list">
          <div className="card__title">
            <h5 className="bold-text">Sunrise 25 Users List</h5>
            {/* <ButtonToolbar className="products-list__btn-toolbar-top">
                            <Button
                                color="success"
                                outline
                            >
                                Export as CSV
                            </Button>
                        </ButtonToolbar> */}
          </div>
          <Sunrise25UsersTable />
        </CardBody>
      </Card>
    </Col>
  );
};

export default Sunrise25UsersTableWrapper;
