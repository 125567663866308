import React, { memo } from 'react';
import { Button, Table } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { FormField } from '@/shared/components/form/hook/FormField';
import { emptyStringPattern } from '@/shared/helpers';
import DeleteIcon from 'mdi-react/DeleteIcon';
import { setConfigPageQuestionTypeData } from '@/containers/Admin/Assessments/components/assessment-config-card/general-assessment-config/redux/actions';
import { getDefaultRowProp, getDefaultColumnProp } from '../../redux/util';

const Rows = ({ position, row, pageId, questionId, matrixRatingQuestion, dispatch }) => {
  const { control, errors, setValue } = useForm();

  const handleChange = (fieldName, fieldValue) => {
    setValue(fieldName, fieldValue);
    dispatch(
      setConfigPageQuestionTypeData({
        pageId,
        questionId,
        typeData: {
          matrixRatingQuestion: {
            ...matrixRatingQuestion,
            rows: matrixRatingQuestion.rows.map((r) =>
              r.id !== row.id ? r : { ...r, [fieldName]: fieldValue },
            ),
          },
        },
      }),
    );
  };

  const deleteRow = () => {
    dispatch(
      setConfigPageQuestionTypeData({
        pageId,
        questionId,
        typeData: {
          matrixRatingQuestion: {
            ...matrixRatingQuestion,
            rows: matrixRatingQuestion.rows.filter((r) => r.id !== row.id),
          },
        },
      }),
    );
  };

  return (
    <tr key={row.id}>
      <td>{position}</td>
      <td>
        <FormField
          name="text"
          customClassName="form pl-3 pr-3"
          control={control}
          component="input"
          errors={errors}
          onChange={({ target: { value } }) => handleChange('text', value)}
          rules={{
            required: 'This is required field',
            pattern: emptyStringPattern,
          }}
          defaultValue={row.text}
          isAboveError={true}
          placeholder="Enter row name"
        />
      </td>
      <td>
        <DeleteIcon style={{ color: '#ff4861' }} role="button" onClick={deleteRow} />
      </td>
    </tr>
  );
};

const Columns = ({ position, column, pageId, questionId, matrixRatingQuestion, dispatch }) => {
  const { control, errors, setValue } = useForm();

  const handleChange = (fieldName, fieldValue) => {
    setValue(fieldName, fieldValue);
    dispatch(
      setConfigPageQuestionTypeData({
        pageId,
        questionId,
        typeData: {
          matrixRatingQuestion: {
            ...matrixRatingQuestion,
            columns: matrixRatingQuestion.columns.map((c) =>
              c.id !== column.id ? c : { ...c, [fieldName]: fieldValue },
            ),
          },
        },
      }),
    );
  };

  const deleteColumn = () => {
    dispatch(
      setConfigPageQuestionTypeData({
        pageId,
        questionId,
        typeData: {
          matrixRatingQuestion: {
            ...matrixRatingQuestion,
            columns: matrixRatingQuestion.columns.filter((r) => r.id !== column.id),
          },
        },
      }),
    );
  };

  return (
    <tr key={column.id}>
      <td>{position}</td>
      <td>
        <FormField
          name="text"
          customClassName="form pl-3 pr-3"
          control={control}
          component="input"
          errors={errors}
          onChange={({ target: { value } }) => handleChange('text', value)}
          rules={{
            required: 'This is required field',
            pattern: emptyStringPattern,
          }}
          defaultValue={column.text}
          isAboveError={true}
          placeholder="Enter column name"
        />
      </td>
      <td>
        <DeleteIcon style={{ color: '#ff4861' }} role="button" onClick={deleteColumn} />
      </td>
    </tr>
  );
};

const MatrixRatingQuestion = ({ pageId, questionId, typeData }) => {
  const dispatch = useDispatch();
  const { rows, columns } = typeData.matrixRatingQuestion ?? { rows: [], columns: [] };

  const addNewRow = () => {
    dispatch(
      setConfigPageQuestionTypeData({
        pageId,
        questionId,
        typeData: {
          matrixRatingQuestion: {
            ...typeData.matrixRatingQuestion,
            rows: [...rows, getDefaultRowProp()],
          },
        },
      }),
    );
  };
  const addNewColumn = () => {
    dispatch(
      setConfigPageQuestionTypeData({
        pageId,
        questionId,
        typeData: {
          matrixRatingQuestion: {
            ...typeData.matrixRatingQuestion,
            columns: [...columns, getDefaultColumnProp()],
          },
        },
      }),
    );
  };

  return (
    <div className="question_type_field d-flex">
      {/******************** Rows Table ********************/}
      <div className="rows-table">
        <Table striped className="mb-3">
          <thead>
            <tr>
              <th style={{ width: 50 }}>#</th>
              <th>Rows</th>
              <th style={{ width: 110 }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, idx) => (
              <Rows
                position={idx + 1}
                key={row.id}
                row={row}
                pageId={pageId}
                questionId={questionId}
                matrixRatingQuestion={typeData.matrixRatingQuestion}
                dispatch={dispatch}
              />
            ))}
          </tbody>
        </Table>
        {/*TODO: write common css in files*/}
        <Button style={{ padding: '4px 10px' }} color="primary" onClick={addNewRow} outline>
          Add new row
        </Button>
      </div>

      {/******************** Columns Table ********************/}
      <div className="columns-table">
        <Table striped className="mb-3">
          <thead>
            <tr>
              <th style={{ width: 50 }}>#</th>
              <th>Columns</th>
              <th style={{ width: 110 }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {columns.map((column, idx) => (
              <Columns
                position={idx + 1}
                key={column.id}
                column={column}
                pageId={pageId}
                questionId={questionId}
                matrixRatingQuestion={typeData.matrixRatingQuestion}
                dispatch={dispatch}
              />
            ))}
          </tbody>
        </Table>
        {/*TODO: write common css in files*/}
        <Button style={{ padding: '4px 10px' }} color="primary" onClick={addNewColumn} outline>
          Add new column
        </Button>
      </div>
    </div>
  );
};

export default memo(MatrixRatingQuestion);
