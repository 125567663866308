import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { useCourseUIContext } from './CourseUIProvider';
import CourseTableWrapper from './table/CourseTableWrapper';

export function CourseCard() {
  const courseUIContext = useCourseUIContext();

  return courseUIContext.isForAssignment ? (
    <CourseTableWrapper />
  ) : (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Course List</h3>
          <h3 className="page-subhead subhead">From here you can manage all the courses</h3>
        </Col>
      </Row>
      <Row>
        <CourseTableWrapper />
      </Row>
    </Container>
  );
}
