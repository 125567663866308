import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon';
import ThumbUpIcon from 'mdi-react/ThumbUpIcon';
import TagAddIcon from 'mdi-react/TagAddIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import React, { useEffect, useState } from 'react';
import { Card } from 'reactstrap';
import { useQAUIContext } from '../QAUIProvider';
import SanitizeHTML from '@/shared/components/SanitizeHTML';
import DeleteIcon from 'mdi-react/DeleteIcon';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setQAFilters } from '../../redux/actions';
import api from '@/utils/apiClient';
import moment from 'moment';

const QAViewDialog = ({ id }) => {
  const qaUIContext = useQAUIContext();
  const dispatch = useDispatch();
  const {filters: qaFilters} = useSelector((state) => state.cadsAIQAData, shallowEqual);
  const [state, setState] = useState({
    loading: false,
    QA: {},
    error: '',
  });

  const getQAById = async (id) => {
    try {
      setState({ ...state, loading: true });
      const { data: res, isSuccess } = await api.cadsAIQA.getQAById(id);
      if (isSuccess) {
        setState({ ...state, loading: false, QA: res.data, error: '' });
      } else {
        throw new Error(res?.messages?.[0] ?? 'Error while getting QA details');
      }
    } catch ({ message }) {
      setState({ ...state, loading: false, QA: {}, error: message });
      console.error(message);
    }
  };

  useEffect(() => {
    getQAById(id);
  }, [id, qaFilters]);

  const goToQAPage = () => {
    dispatch(setQAFilters());
    qaUIContext.openQA();
  };

  const handleReplyDelete = (replyId) => {
    qaUIContext.openDeactivateReplyDialog(state?.QA?.id, replyId);
  };

  return (
    <Card className="card-body">
      <div className="questions-container">
        <div className="answer-item pt-2 d-flex">
          <div className="qa-arrow-icon">
            <ArrowLeftIcon style={{ width: '34px', height: '34px' }} onClick={() => goToQAPage()} />
          </div>
          <img
            src={
              state.QA?.userImage
                ? state.QA.userImage
                : 'https://www.pngitem.com/pimgs/m/130-1300253_female-user-icon-png-download-user-image-color.png'
            }
            className="qa-question-logo"
          />
          <div className="ml-2">
            <p class="postedby-sub-text pt-2">Posted by</p>
            <p className="postedby-sub-name">{state?.QA?.postedBy}</p>
            <p className="postedby-sub-text mt-0">{state.QA?.postedByEmail}</p>
          </div>
        </div>
        <h4 className="questions pt-3 pl-5">{state.QA?.questionTitle}</h4>
        <div className="qa-item pt-1">
          <SanitizeHTML className="pl-5" html={state.QA?.questionDescription} />
        </div>
        <div className="pt-5 pl-5">
          <div className="question-time">
            <span>{`Posted on ${moment(state.QA?.datePosted).format('DD MMMM YYYY')}`}</span>
          </div>
          <ThumbUpIcon className="qa-icons" />
          <span className="pl-2">{state?.QA?.likeCount}</span>
          <TagAddIcon className="qa-icons ml-3" />
          <span className="pl-2">{state?.QA?.saveCount}</span>
          <EyeIcon className="qa-icons ml-3" />
          <span className="pl-2">{state?.QA?.viewCount}</span>
        </div>
      </div>
      <div className="pl-5 pt-5">
        <h4 className="pb-3">{state?.QA?.replies?.length} Answers</h4>
        {state?.QA?.replies?.map((item) => (
          <div className="mt-4">
            <div className="answer-item d-flex">
              <img
                src={
                  item?.userImage
                    ? item?.userImage
                    : 'https://www.pngitem.com/pimgs/m/130-1300253_female-user-icon-png-download-user-image-color.png'
                }
                className="qa-answer-logo"
              />
              <div className="ml-3">
                <div className="answer-time">
                  <span>{`Answered on ${moment(item?.datePosted).format('DD MMMM YYYY')}`}</span>
                  <div className="delete-icon">
                    <DeleteIcon
                      onClick={() => handleReplyDelete(item?.id)}
                      className="answer-delete-button float-right"
                    />
                  </div>
                </div>
                <span className="postedby-answer">{item?.postedBy}</span>
                <span className="postedby-answer-email mt-0">{item?.postedByEmail}</span>
                <div className="qa-item pt-1">
                  <div className="posted-answer" html={item?.replyDescription} />
                </div>
                <div className="answer-icon-font ">
                  <ThumbUpIcon className="qa-icons" />
                  <span className="pl-2">{item?.numberOfLikes}</span>
                  <TagAddIcon className="qa-icons ml-3" />
                  <span className="pl-2">{item?.numberOfSaves}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default QAViewDialog;
