import { handleActions } from 'redux-actions';
import { setAssignOrganizationId, setAssignProduct, setAssignTenantId } from './actions';
import { CP_PRODUCTS } from '@/shared/helpers/constants';

const defaultState = {
  assignedProduct: CP_PRODUCTS.ACCELTIC,
  assignedOrgId: null,
  tenantId: null,
};

export default handleActions(
  {
    [setAssignProduct](state, { payload }) {
      return {
        ...state,
        assignedProduct: payload,
      };
    },
    [setAssignOrganizationId](state, { payload }) {
      return {
        ...state,
        assignedOrgId: payload,
      };
    },
    [setAssignTenantId](state, { payload }) {
      return {
        ...state,
        tenantId: payload,
      };
    },
  },
  defaultState,
);
