import React, { useEffect, useState } from 'react';
import LoadingIcon from 'mdi-react/LoadingIcon';
import api from '@/utils/apiClient';
import TextEditor from '@/shared/components/text-editor/TextEditor';
import { Button, ButtonToolbar } from 'reactstrap';
import { getSanitizeHTML } from '@/shared/components/SanitizeHTML';
import { SelectField } from '@/shared/components/form/Select';
import { convertImageFileToBase64, convertUrlToFile, emptyStringPattern, YesNoOptions } from '@/shared/helpers';
import FormField from '@/shared/components/form/hook/FormField';
import { useForm } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import renderDropZoneField from '@/shared/components/form/DropZone';
import { RadioButtonField } from '@/shared/components/form/RadioButton';
import { getOrganizationOptionsList } from '../../../../redux/organization/actions';
import { CreatableSelectField } from "@/shared/components/form/CreatableSelectField";

const EventEditDialogBody = ({ modal, isEdit, id }) => {
  const initialValues = {
    description: '',
    isPublished: 'true',
    isExternal: 'false',
  };

  const [data, setData] = useState(initialValues);
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const dispatch = useDispatch();
  const { options: organizationOpts } = useSelector((state) => state.cadsAIOrgs, shallowEqual);

  const [state, setState] = useState({
    loading: false,
    fetchingEvent: false,
    newEventAdded: false,
    errorMessage: '',
  });

  useEffect(() => {
    dispatch(getOrganizationOptionsList());
  }, []);

  useEffect(() => {
    if (isEdit && id) {
      setDefaultData(+id);
    }
  }, [isEdit, id]);

  useEffect(() => {
    setOrganizationOptions(
        organizationOpts?.map((org) => ({value: org.id, label: org.title})) ?? [],
    );
  }, [organizationOpts]);

  const setDefaultData = async (eventId) => {
    try {
      const { data: res, isSuccess } = await api.cadsAIEvent.getEventById(eventId);
      const eventData = res.data;
      const imgFile = await convertUrlToFile(eventData?.imageUrl);
      if (id) {
        setData({
          ...eventData,
        });
        ['title', 'slug', 'link', 'category', 'description', 'tags'].forEach((field) =>
          setValue(field, eventData?.[field]),
        );
        setValue('publishDate', eventData?.publishDate?.substr(0, 10));
        setValue('fromDate', eventData?.fromDate?.substr(0, 10));
        setValue('toDate', eventData?.toDate?.substr(0, 10));
        setValue('isPublished', eventData?.isPublished ? 'true' : 'false');
        setValue('isExternal', eventData?.isExternal ? 'true' : 'false');
        setValue('imageUrl', imgFile ? [imgFile] : '');
      }
    } catch ({ message }) {
      console.error('Event edit error : ', message);
      setState({
        ...state,
        errorMessage: 'Error while getting existing event details',
      });
    }
  };

  const constructApiRequestData = async ({
    description,
    title,
    imageUrl,
    link,
    slug,
    tags,
    category,
    fromDate,
    toDate,
    publishDate,
    organization,
  }) => {
    const base64Img = await convertImageFileToBase64(imageUrl?.[0]);
    let formattedTags = {}
    tags.forEach(tag => formattedTags[tag.label] = tag.value);
    tags = [formattedTags];
    return {
      description,
      title,
      tags,
      category,
      fromDate,
      toDate,
      publishDate,
      imageUrl: base64Img?.split(',')?.[1],
      slug,
      link,
      isPublished: isPublished === 'true',
      isExternal: isExternal === 'true',
      hostOrganizationId: organization?.value,
      id: id,
    };
  };
  const handleAddEditEvent = async (eventData) => {
    setState({ ...state, loading: true });
    const newEventData = await constructApiRequestData(eventData);
    const defaultError = `Error occurred while ${isEdit ? 'Updating' : 'Adding new'} event`;
    try {
      const { data: res, isSuccess } = isEdit
        ? await api.cadsAIEvent.updateEvent(newEventData)
        : await api.cadsAIEvent.addEvent(newEventData);
      if (isSuccess) {
        setState({ ...state, newEventAdded: true, loading: false });
        if (!isEdit) setData(initialValues);
      } else {
        throw new Error(data?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({
        ...state,
        loading: false,
        newEventAdded: false,
        errorMessage: error.message,
      });
    }
  };

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ defaultValues: initialValues });
  const isPublished = watch('isPublished');
  const setIsPublished = (value) => setValue('isPublished', value);

  const isExternal = watch('isExternal');
  const setIsExternal = (value) => setValue('isExternal', value);

  return (
    <div>
      {/*Add/Edit Event Form Status*/}
      <div className="ml-4">
        {state.loading && (
          <div>
            <div className="panel__refresh position-relative" style={{ height: 75, width: 20 }}>
              <LoadingIcon />
              <div style={{ width: 200, padding: '23px 30px' }}>
                {isEdit ? 'Updating' : 'Adding new'} event...
              </div>
            </div>
          </div>
        )}
        {(state.newEventAdded || !!state.errorMessage) && (
          <>
            <table className="mt-3">
              <tr>
                <td>
                  {state.newEventAdded ? (
                    <span className="lnr lnr-thumbs-up modal__title-icon success" />
                  ) : (
                    <span className="lnr lnr-cross-circle modal__title-icon error" />
                  )}
                </td>
                <td className="pl-2">
                  {state.newEventAdded
                    ? `${isEdit ? 'Event Updated' : 'New Event Added'} successfully`
                    : state.errorMessage}
                </td>
              </tr>
            </table>
          </>
        )}
      </div>
      <div className="modal__body add-edit-learning-path dropzone-border">
        <form className={`form form--horizontal`} onSubmit={handleSubmit(handleAddEditEvent)}>
          {!state.newEventAdded ? (
            <div className={'w-100 p-3'}>
              <div className="form__form-group">
                <span className="form__form-group-label">Title</span>
                <div className="form__form-group-field">
                  <FormField
                    name="title"
                    control={control}
                    component="input"
                    errors={errors}
                    rules={{
                      required: 'This is required field',
                      pattern: emptyStringPattern,
                    }}
                    defaultValue=""
                    isAboveError={true}
                    placeholder="Enter Title"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Description</span>
                <div className="form__form-group-field">
                  {/*TODO: set html after sanitizing for all text editor*/}
                  {(!isEdit || data.description) && (
                    <FormField
                      control={control}
                      name="description"
                      component={() => (
                        <TextEditor
                          defaultContent={getSanitizeHTML(data.description)}
                          onChange={(value) => setValue('description', value)}
                        />
                      )}
                    />
                  )}
                </div>
              </div>
              <div className="form__form-group pt-2">
                <span className="form__form-group-label">Image</span>
                <div className="form__form-group-field">
                  <FormField name="imageUrl" control={control} component={renderDropZoneField} />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Tags</span>
                <div className="form__form-group-field">
                  <FormField
                      control={control}
                      name="tags"
                      isMulti={true}
                      component={CreatableSelectField}
                      options={[]}
                      placeholder="Start typing to create tag"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Slug</span>
                <div className="form__form-group-field">
                  <FormField
                    name="slug"
                    control={control}
                    component="input"
                    type="text"
                    defaultValue=""
                    placeholder="Enter Slug"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Category</span>
                <div className="form__form-group-field">
                  <FormField
                    name="category"
                    control={control}
                    component="input"
                    type="text"
                    defaultValue=""
                    placeholder="Enter Category"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Link</span>
                <div className="form__form-group-field">
                  <FormField
                    name="link"
                    control={control}
                    component="input"
                    defaultValue=""
                    placeholder="Enter Link"
                  />
                </div>
              </div>
              <div className="form__form-group mt-3">
                <span className="form__form-group-label">Host Organization</span>
                <div className="form__form-group-field">
                  <FormField
                    control={control}
                    name="organization"
                    component={SelectField}
                    options={organizationOptions}
                    placeholder="Select Organization"
                    errors={errors}
                    clearable={true}
                    isAboveError
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">From Date</span>
                <div className="form__form-group-field">
                  <FormField
                    name="fromDate"
                    type="date"
                    control={control}
                    errors={errors}
                    defaultValue=""
                    isAboveError={true}
                    placeholder="Enter the date"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">To Date</span>
                <div className="form__form-group-field">
                  <FormField
                    name="toDate"
                    type="date"
                    control={control}
                    errors={errors}
                    defaultValue=""
                    isAboveError={true}
                    placeholder="Enter the date"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Publish Date</span>
                <div className="form__form-group-field">
                  <FormField
                    name="publishDate"
                    type="date"
                    control={control}
                    errors={errors}
                    defaultValue=""
                    isAboveError={true}
                    placeholder="Enter the publish date"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Is Published</span>
                <div className="form__form-group-field mt-2">
                  <RadioButtonField
                    radioValue={`${YesNoOptions[0].value}`}
                    name={'IsPublished'}
                    onChange={setIsPublished}
                    value={isPublished}
                    className="colored-click mr-5"
                    label={YesNoOptions[0].label}
                  />
                  <RadioButtonField
                    radioValue={`${YesNoOptions[1].value}`}
                    name={'IsPublished'}
                    onChange={setIsPublished}
                    value={isPublished}
                    className="colored-click mr-5"
                    label={YesNoOptions[1].label}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">is External</span>
                <div className="form__form-group-field mt-2">
                  <RadioButtonField
                    radioValue={`${YesNoOptions[0].value}`}
                    name={YesNoOptions[0].label}
                    onChange={setIsExternal}
                    value={isExternal}
                    className="colored-click mr-5"
                    label={YesNoOptions[0].label}
                  />
                  <RadioButtonField
                    radioValue={`${YesNoOptions[1].value}`}
                    name={YesNoOptions[1].label}
                    onChange={setIsExternal}
                    value={isExternal}
                    className="colored-click mr-5"
                    label={YesNoOptions[1].label}
                  />
                </div>
              </div>
              <ButtonToolbar className="modal__footer btn-toolbar">
                <>
                  <Button color="primary" type="submit">
                    Submit
                  </Button>
                  <Button
                      color="secondary"
                      type="button"
                      className="modal_cancel btn btn-secondary"
                      onClick={() => modal.hide()}
                  >
                    Close
                  </Button>
                </>
              </ButtonToolbar>
            </div>
          ) : (
            <div className={'p-3'}>
              <ButtonToolbar className="modal__footer btn-toolbar">
                <Button color="primary" type="button" onClick={() => modal.hide()}>
                  Okay
                </Button>
              </ButtonToolbar>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default EventEditDialogBody;
