import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { useTrainingProviderUIContext } from './TrainingProviderUIProvider';
import TrainingProviderTableWrapper from './table/TrainingProviderTableWrapper';

export function TrainingProviderCard() {
  const trainingProviderUIContext = useTrainingProviderUIContext();

  return trainingProviderUIContext.isForAssignment ? (
    <TrainingProviderTableWrapper />
  ) : (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Training Provider List</h3>
          <h3 className="page-subhead subhead">
            From here you can manage all the training provider content under control panel.
          </h3>
        </Col>
      </Row>
      <Row>
        <TrainingProviderTableWrapper />
      </Row>
    </Container>
  );
}
