import { useEffect } from 'react';
import Highcharts from 'highcharts';
import Tree from 'highcharts/modules/treemap';
import exporting from 'highcharts/modules/exporting';
import data from 'highcharts/modules/data';
import Heatmap from 'highcharts/modules/heatmap';

Tree(Highcharts);
data(Highcharts);
Heatmap(Highcharts);
exporting(Highcharts);

const JobsTrend = () => {
  const initialChart = () => {
    Highcharts.chart('JobTrend', {
      chart: {
        type: 'column',
      },

      title: {
        text: 'Job seekers rates by cads profile',
      },

      xAxis: {
        categories: ['Entry Level', 'Mid Level', 'Managerial Level'],
      },

      yAxis: {
        allowDecimals: true,
        min: 0,
        stackLabels: {
          enabled: true,
        },
        labels: {
          formatter: function () {
            return this.value + '%';
          },
        },
        title: {
          text: null,
        },
      },

      credits: {
        enabled: false,
      },

      tooltip: { enabled: false },

      colors: ['#75a5e3', '#2976de', '#005cb1'],

      plotOptions: {
        column: {
          stacking: 'normal',
        },
        series: {
          enableMouseTracking: false,
        },
      },

      series: [
        {
          name: 'No Cads Profile',
          data: [7.4, 7.4, 7.8],
          stack: 'noCadsProfile',
        },
        {
          name: 'Partial Cads Profile',
          data: [8.2, 7.2, 8.4],
          stack: 'partialCadsProfile',
        },
        {
          name: 'Complete Cads Profile',
          data: [17.7, 13.1, 15.2],
          stack: 'completeCadsProfile',
        },
      ],
    });
  };

  useEffect(() => {
    initialChart();
  }, []);

  return <div id="JobTrend" />;
};

export default JobsTrend;
