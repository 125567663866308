const PinnedQuestionEditDialogHeader = ({modal}) => {
  return (
      <div className="modal__header">
        <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={modal.hide}
        />
        <h4 className="text-modal modal__title" style={{color: 'white'}}>{modal.title}</h4>
      </div>
  );
}

export default PinnedQuestionEditDialogHeader;
