import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { customersBaseUrl } from '../index';
import { CustomerUIProvider } from './CustomerUIProvider';
import { CustomerCard } from './CustomerCard';
import CustomerEditDialog from './customer-add-edit-dialog/CustomerEditDialog';
// import CustomerDeleteDialog from "./customer-delete-dialog/CustomerDeleteDialog";
import CustomerViewDialog from './customer-view-dialog/CustomerViewDialog';
import { setCustomerFilters } from '../redux/actions';
import { useDispatch } from 'react-redux';

export function CustomersPage({ history, match }) {
  const customerUIEvents = {
    openViewCustomerDialog: (id) => {
      history.push(`${customersBaseUrl}/${id}/view`);
    },
    openNewCustomerDialog: () => {
      history.push(`${customersBaseUrl}/new`);
    },
    openEditCustomerDialog: (id) => {
      history.push(`${customersBaseUrl}/${id}/edit`);
    },
    openDeleteCustomerDialog: (id) => {
      history.push(`${customersBaseUrl}/${id}/delete`);
    },
  };

  //TODO: remove this once we have add and edit api returning newly created data
  const dispatch = useDispatch();

  return (
    <CustomerUIProvider customerUIEvents={customerUIEvents}>
      <Switch>
        <Route path={`${customersBaseUrl}/new`}>
          {({ history, match }) => (
            <CustomerEditDialog
              show={match != null}
              isEdit={false}
              onHide={() => {
                dispatch(setCustomerFilters());
                history.push(customersBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${customersBaseUrl}/:id/edit`}>
          {({ history, match }) => (
            <CustomerEditDialog
              show={match != null}
              isEdit={true}
              id={match?.params?.id}
              onHide={() => {
                dispatch(setCustomerFilters());
                history.push(customersBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${customersBaseUrl}/:id/view`}>
          {({ history, match }) => (
            <CustomerViewDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                history.push(customersBaseUrl);
              }}
            />
          )}
        </Route>
        {/*                <Route path={`${customersBaseUrl}/:id/delete`}>
                    {({history, match}) => (
                        <CustomerDeleteDialog
                            show={match != null}
                            id={match?.params?.id}
                            onHide={() => {
                                history.push(customersBaseUrl);
                            }}
                        />
                    )}
                </Route>*/}
      </Switch>
      <CustomerCard />
    </CustomerUIProvider>
  );
}
