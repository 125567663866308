import React from 'react';
import { useForm } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, ButtonToolbar } from 'reactstrap';
import api from '@/utils/apiClient';
import { emptyStringPattern } from '@/shared/helpers';
import { FormField } from '@/shared/components/form/hook/FormField';
import { updateSubCategoryInList } from '../../redux/actions';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { SelectField } from '@/shared/components/form/Select';
import { important } from 'polished';
import { getSkillsList } from '@/containers/Admin/SkillBank/Skills/redux/actions';

const getColorForWeight = (weight) => {
  switch (weight) {
    case 0.1:
    case 0.2:
      return 'grey';
    case 0.3:
    case 0.4:
      return 'blue';
    case 0.5:
    case 0.6:
      return 'green';
    case 0.7:
    case 0.8:
      return 'orange';
    case 0.9:
    case 1:
      return 'red';
    default:
      return 'green';
  }
};

const getSkillsImportanceOptions = () => [
  { value: '0.1', label: '1 (Lowest)' },
  { value: '0.2', label: '2' },
  { value: '0.3', label: '3' },
  { value: '0.4', label: '4' },
  { value: '0.5', label: '5 (Medium)' },
  { value: '0.6', label: '6' },
  { value: '0.7', label: '7' },
  { value: '0.8', label: '8' },
  { value: '0.9', label: '9' },
  { value: '1', label: '10 (Highest)' },
];

const SubcategoryEditForm = ({ modal, id }) => {
  const dispatch = useDispatch();
  const { data: skills } = useSelector((state) => state.skills, shallowEqual);

  //states
  const initialState = {
    loading: false,
    fetchingSubCategory: false,
    newSkillAdded: false,
    skillDeleted: false,
    errorMessage: '',
  };
  const [state, setState] = React.useState(initialState);
  const [skillsOptions, setSkillsOptions] = React.useState([]);
  const [associatedSkills, setAssociatedSkills] = React.useState([]);

  //fetch data
  const getSkillsOptions = React.useCallback(() => {
    //TODO: implement proper solution by fetching all skills or by passing search text
    dispatch(getSkillsList({ pageSize: 1000, pageNumber: 1 })); // assuming there won't be more than 100 skills
  }, []);

  // prefill the form in case of edit subCategory
  React.useEffect(() => {
    getSkillsOptions();
  }, []);

  React.useEffect(() => {
    setSkillsOptions(skills?.map((skill) => ({ value: skill.id, label: skill.name })) ?? []);
  }, [skills]);

  React.useEffect(() => {
    if (id) {
      setDefaultData(+id);
    }
  }, [id]);

  // removing skill form the options which are already associated
  React.useEffect(() => {
    const associatedSkillsId = associatedSkills.map((skill) => skill.id);
    const skillOptions = skills?.map((skill) => ({ value: skill.id, label: skill.name })) ?? [];
    setSkillsOptions(skillOptions.filter((skill) => !associatedSkillsId.includes(skill.value)));
  }, [associatedSkills]);

  const setDefaultData = async (subCategoryId) => {
    try {
      const { data: res, isSuccess } = await api.subCategory.getSubCategoryById(subCategoryId);
      if (isSuccess) {
        const associatedSkills = res?.data?.associatedSkills ?? [];
        setAssociatedSkills(associatedSkills);
      } else {
        throw new Error(res?.messages?.[0]);
      }
    } catch ({ message }) {
      console.error('Error getting subcategory by id  : ', message);
      setState({ ...state, errorMessage: 'Error while getting attached skills details' });
    }
  };

  // subCategory form related functions
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const resetForm = () => {
    reset({ skill: [], importance: [] });
    setState(initialState);
  };

  const deleteAssociatedSkill = async (skill) => {
    setState({ ...state, loading: true });
    const defaultError = `Error occurred removing skill from subcategory`;
    try {
      const { data, isSuccess } = await api.subCategory.unLinkSkillFromSubcategory(+id, skill.id);
      if (isSuccess) {
        setState({
          ...state,
          skillDeleted: true,
          newSkillAdded: false,
          loading: false,
          errorMessage: '',
        });
        setValue('skill', []); // empty selected skill
        setAssociatedSkills(associatedSkills.filter((aSkill) => aSkill.id !== skill.id)); // remove skill to category's associatedSkill
        setSkillsOptions([...skillsOptions, { value: skill.id, label: skill.name }]);
      } else {
        throw new Error(data?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({ ...state, loading: false, skillDeleted: false, errorMessage: error.message });
    }
  };

  const onSubmit = async ({ skill, importance }) => {
    const skillId = +skill.value;
    const weight = +importance.value;

    setState({ ...state, loading: true });
    const defaultError = `Error occurred while adding new skill to subcategory`;
    try {
      //TODO: modify to add all new skills at once instead of adding skill one by one to subcategory
      const { data, isSuccess } = await api.subCategory.addSkillsToSubCategory({
        subCategoryId: +id,
        skills: [{ id: skillId, weight }],
      });
      if (isSuccess) {
        setState({ ...state, newSkillAdded: true, skillDeleted: false, loading: false });
        setValue('skill', []); // empty selected skill
        const newAssociatedSkills = [
          ...associatedSkills,
          { id: skillId, name: skill.label, weight },
        ];
        setAssociatedSkills(newAssociatedSkills); // add skill to category's associatedSkill
      } else {
        throw new Error(data?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({ ...state, loading: false, newSkillAdded: false, errorMessage: error.message });
    }
  };

  const onCancel = () => {
    resetForm();
    modal.hide();
  };

  return (
    <div style={{ minHeight: 400 }}>
      {/*Add/Edit SubCategory Form Status*/}
      <div className="ml-4">
        {state.loading && (
          <div>
            <div className="panel__refresh position-relative" style={{ height: 75, width: 20 }}>
              <LoadingIcon />
              <div style={{ width: 200, padding: '23px 40px' }}>Adding new skill</div>
            </div>
          </div>
        )}
        {(state.newSkillAdded || state.skillDeleted || !!state.errorMessage) && (
          <table className="mt-3">
            <tr>
              <td>
                {state.newSkillAdded || state.skillDeleted ? (
                  <span className="lnr lnr-thumbs-up modal__title-icon success" />
                ) : (
                  <span className="lnr lnr-cross-circle modal__title-icon error" />
                )}
              </td>
              <td className="pl-2">
                {state.newSkillAdded ? 'New Skill Added Successfully' : state.errorMessage}
                {state.skillDeleted ? 'Skill removed Successfully' : state.errorMessage}
              </td>
            </tr>
          </table>
        )}
      </div>

      {/*Add/Edit SubCategory Form*/}
      <div className="modal__body">
        <form className={`form`} onSubmit={handleSubmit(onSubmit)}>
          <div className="form__form-group" style={{ width: '60%' }}>
            <span className="form__form-group-label">Select Skill</span>
            <FormField
              style={{ height: 40 }}
              control={control}
              name="skill"
              component={SelectField}
              options={skillsOptions}
              rules={{
                required: 'This is required field',
                pattern: emptyStringPattern,
              }}
              errors={errors}
              defaultValue=""
              isAboveError={true}
              placeholder="Search and select skill"
            />
          </div>
          <div className="form__form-group ml-2" style={{ width: '24%' }}>
            <span className="form__form-group-label">Importance</span>
            <FormField
              name="importance"
              style={{ height: 40 }}
              control={control}
              component={SelectField}
              errors={errors}
              options={getSkillsImportanceOptions()}
              defaultValue=""
              isAboveError
              rules={{
                required: 'This is required field',
                pattern: emptyStringPattern,
              }}
              placeholder="Select Importance"
            />
          </div>
          <div className="form__form-group" style={{ width: '10%' }}>
            <div className="skill_add_button">
              <Button color="success" type="submit" outline style={{ marginLeft: 20, height: 38 }}>
                Add
              </Button>
            </div>
          </div>
          <div className="skill_add_section">
            {associatedSkills.map(({ name, weight, id }) => (
              <p key={id}>
                <span
                  onClick={() => deleteAssociatedSkill({ id, name })}
                  onMouseEnter={(e) => (e.target.className = 'lnr lnr-trash')}
                  onMouseOut={(e) => (e.target.className = 'lnr lnr-checkmark-circle')}
                  style={{ color: getColorForWeight(+weight), cursor: 'pointer' }}
                  className="lnr lnr-checkmark-circle"
                />{' '}
                {name} ({(weight ?? 0) * 100}%)
              </p>
            ))}
          </div>
        </form>
      </div>
    </div>
  );
};

export default SubcategoryEditForm;
