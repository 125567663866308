import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { organizationsBaseUrl } from '../index';
import { OrganizationUIProvider } from './OrganizationUIProvider';
import { OrganizationCard } from './OrganizationCard';
import OrganizationEditDialog from './organization-add-edit-dialog/OrganizationEditDialog';
import { useDispatch } from 'react-redux';
import OrganizationDeactivateDialog from './organization-deactivate-dialog/OrganizationDeactivateDialog';
import { setOrganizationFilters, unCheckAllOrganizations } from '../redux/actions';

export function OrganizationsPage({ history, match }) {
  const organizationUIEvents = {
    openViewOrganizationDialog: (id) => {
      history.push(`${organizationsBaseUrl}/${id}/view`);
    },
    openViewAllOrganizationList: () => {
      history.push(`${organizationsBaseUrl}`);
    },
    openNewOrganizationDialog: () => {
      history.push(`${organizationsBaseUrl}/new`);
    },
    openEditOrganizationDialog: (id) => {
      history.push(`${organizationsBaseUrl}/${id}/edit`);
    },
    openDeleteOrganizationDialog: (id) => {
      history.push(`${organizationsBaseUrl}/${id}/delete`);
    },
    openOrganizationTypeDialog: () => {
      history.push(`${organizationsBaseUrl}/type`);
    },
    openDeactivateOrganizationDialog: () => {
      history.push(`${organizationsBaseUrl}/deactivate`);
    },
  };

  //TODO: remove this once we have add and edit api returning newly created data
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(unCheckAllOrganizations());
  }, []);

  return (
    <OrganizationUIProvider organizationUIEvents={organizationUIEvents}>
      <Switch>
        <Route path={`${organizationsBaseUrl}/new`}>
          {({history, match}) => (
              <OrganizationEditDialog
                  show={match != null}
                  isEdit={false}
                  onHide={() => {
                    dispatch(setOrganizationFilters());
                    history.push(organizationsBaseUrl);
                  }}
              />
          )}
        </Route>
        <Route path={`${organizationsBaseUrl}/:id/edit`}>
          {({history, match}) => (
              <OrganizationEditDialog
                  show={match != null}
                  isEdit={true}
                  id={match?.params?.id}
                  onHide={() => {
                    dispatch(setOrganizationFilters());
                    history.push(organizationsBaseUrl);
                  }}
            />
          )}
        </Route>
        {/* Commented as organization type is very critical to change and not required as of now.
        <Route path={`${organizationsBaseUrl}/type`}>
          {({ history, match }) => (
            <OrganizationTypeSelectDialog
              show={match != null}
              onHide={() => {
                history.push(organizationsBaseUrl);
              }}
            />
          )}
        </Route>
        */}
        <Route path={`${organizationsBaseUrl}/deactivate`}>
          {({ history, match }) => (
            <OrganizationDeactivateDialog
              show={match != null}
              onHide={() => {
                dispatch(setOrganizationFilters());
                history.push(organizationsBaseUrl);
              }}
            />
          )}
        </Route>
      </Switch>
      <OrganizationCard />
    </OrganizationUIProvider>
  );
}
