import React, { createContext, useContext } from 'react';

const TrainingProviderUIContext = createContext();

export function useTrainingProviderUIContext() {
  return useContext(TrainingProviderUIContext);
}

export function TrainingProviderUIProvider({ trainingProviderUIEvents, children }) {
  const value = {
    openViewTrainingProviderDialog: trainingProviderUIEvents.openViewTrainingProviderDialog,
    openNewTrainingProviderDialog: trainingProviderUIEvents.openNewTrainingProviderDialog,
    openEditTrainingProviderDialog: trainingProviderUIEvents.openEditTrainingProviderDialog,
    openDeactivateTrainingProviderDialog:
      trainingProviderUIEvents.openDeactivateTrainingProviderDialog,
  };

  return (
    <TrainingProviderUIContext.Provider value={value}>
      {children}
    </TrainingProviderUIContext.Provider>
  );
}
