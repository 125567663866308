import React from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import DdoTable from './DdoTable';
import { useDdoUIContext } from '../DdoUIProvider';

const DdoTableWrapper = () => {
  const ddoUIContext = useDdoUIContext();

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="products-list">
          <div className="card__title">
            <h5 className="bold-text">DDO List</h5>
            <ButtonToolbar className="products-list__btn-toolbar-top">
              <Button color="primary" onClick={ddoUIContext.openNewDdoDialog}>
                Add new
              </Button>
            </ButtonToolbar>
          </div>
          <DdoTable />
        </CardBody>
      </Card>
    </Col>
  );
};

export default DdoTableWrapper;
