import MagnifyIcon from "mdi-react/MagnifyIcon";
import Select from "react-select";
import React, { Fragment } from "react";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import { TableFooter } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import QuestionsTableColumns, { QuestionColumns } from "./QuestionsTableColumns";
import { useQAUIContext } from "@/containers/Cads-AI/ManageHome/Q&A/components/QAUIProvider";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getQAList, setQAFilters } from "@/containers/Cads-AI/ManageHome/Q&A/redux/actions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import LoadingIcon from "mdi-react/LoadingIcon";
import { Button, FormGroup, Input } from "reactstrap";
import { defaultPaginationProps } from "@/shared/helpers";
import TablePagination from "@material-ui/core/TablePagination";
import { cadsAiQABaseUrl } from "@/containers/Cads-AI/ManageHome/Q&A";
import api from "@/utils/apiClient";
import { useLocation } from "react-router-dom";

const PinnedQuestionEditForm = ({isEdit, id, modal}) => {

  const location = useLocation();

  const [postedByOptions, setPostedByOptions] = React.useState([{value: -1, label: "All"}]);

  const [filters, setFilters] = React.useState({
    questionText: '',
    postedBy: {
      value: -1,
      label: "All"
    }
  });

  const qaUIContext = useQAUIContext();
  const dispatch = useDispatch();

  const [pinState, setPinState] = React.useState({
    loading: false,
    error: '',
    msg: '',
  });
  const [pinnedQuestions, setPinnedQuestions] = React.useState([]);

  const {
    data: qaData,
    error,
    filters: QAFilters,
    loading,
    totalRecords
  } = useSelector((state) => state.cadsAIQAData, shallowEqual);

  const {
    pageSize: rowsPerPage,
    pageNumber: page,
    sortBy: orderBy,
    isSortOrderDescending,
  } = QAFilters;

  React.useEffect(() => {
    dispatch(getQAList({...QAFilters, isPinned: false}));
    getPostedByUserList();
  }, [QAFilters]);

  React.useEffect(() => {
    const postedById = filters.postedBy.value;
    dispatch(
        setQAFilters({
          searchText: filters.questionText,
          postedBy: postedById === -1 ? null : postedById,
        }),
    );

    return () => {
      dispatch(
          setQAFilters({
            searchText: '',
            postedBy: null,
            pageSize: 10,
            pageNumber: 1,
            sortBy: '',
            isPinned: null
          })
      )
    }
  }, [filters]);

  React.useEffect(() => {
    if (isEdit) {
      setPinnedQuestions([{id: +id}]);
    }
  }, [])

  const togglePinnedId = (questionToToggle) => {
    if (isEdit) {
      setPinnedQuestions([questionToToggle]);
    } else {
      if (pinnedQuestions.map(question => question.id).includes(questionToToggle.id)) {
        setPinnedQuestions(pinnedQuestions.filter(question => question.id !== questionToToggle.id));
      } else {
        setPinnedQuestions([...pinnedQuestions, questionToToggle]);
      }
    }
  }

  const handleRequestSort = (property) => {
    dispatch(
        setQAFilters({
          sortBy: property,
          isSortOrderDescending: !isSortOrderDescending,
        }),
    );
  };

  const handleChangePage = (event, currentPage) => {
    dispatch(setQAFilters({pageSize: rowsPerPage, pageNumber: currentPage + 1}));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setQAFilters({pageSize: +event.target.value, pageNumber: 1}));
  };

  const emptyRows =
      page === Math.ceil(totalRecords / rowsPerPage) ? rowsPerPage - qaData.length : 0;

  const getPostedByUserList = async () => {
    try {
      const {data: res, isSuccess} = await api.cadsAIQA.getPostedByUserList();
      if (isSuccess) {
        const postedByList = res.data.map(postedBy => ({value: postedBy?.autherId, label: postedBy?.autherName}));
        setPostedByOptions([{value: -1, label: 'All'}, ...postedByList]);
      } else {
        throw new Error(res?.messages?.[0] ?? 'Error while getting list of users who posted questions');
      }
    } catch ({message}) {
      setPostedByOptions([{value: -1, label: 'All'}]);
      console.error(message);
    }
  }

  const pinSelectedQuestions = async () => {
    try {
      const questionIdsToPin = pinnedQuestions.map(q => q.id);
      setPinState({...pinState, loading: true, error: '', msg: "Pinning Selected Question(S)...", isSuccess: false});
      const {data: res, isSuccess} = await api.cadsAIQA.pinNewQuestions(questionIdsToPin);
      if (isSuccess) {
        setPinState({...pinState, loading: false, msg: "Selected Question(s) Pinned Successfully.", isSuccess: true});
      } else {
        throw new Error(res?.messages?.[0] ?? 'Error while pinning question(s)');
      }
    } catch ({message}) {
      setPinState({...pinState, loading: false, error: message, msg: '', isSuccess: false});
      console.error(message);
    }
  }

  const updatePinnedQuestion = async () => {
    try {
      const oldQueId = +id;
      const newQueId = pinnedQuestions?.[0]?.id;
      const order = location?.state?.order;
      setPinState({...pinState, loading: true, error: '', msg: "Updating pinned question...", isSuccess: false});
      const {data: res, isSuccess} = await api.cadsAIQA.updatePinnedQuestion(oldQueId, newQueId, order);
      if (isSuccess) {
        setPinState({...pinState, loading: false, msg: "Pinned Question Updated Successfully.", isSuccess: true});
      } else {
        throw new Error(res?.messages?.[0] ?? 'Error while updating pinned question(s)');
      }
    } catch ({message}) {
      setPinState({...pinState, loading: false, error: message, msg: '', isSuccess: false});
      console.error(message);
    }
  }

  const renderQuestionPinningUI = () => {
    return (
        <Fragment>
          <div className="material-table__wrap align-self-center">
            <TableHead className="pinned-question-search-header">
              <div className="table__search align-items-center justify-content-center">
                <div className="table__search_item">
                  <b>Question</b>
                  <div className="form">
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon" style={{height: 37}}>
                          <MagnifyIcon/>
                        </div>
                        <input
                            style={{height: 36, width: 420}}
                            name="questionText"
                            value={filters.questionText}
                            onChange={(e) => setFilters({...filters, questionText: e.target.value})}
                            type="text"
                            placeholder="Start typing to search by question..."
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table__search_item">
                  <b>Posted By</b>
                  <div className="form" style={{width: 200}}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Select
                            name="skill"
                            value={filters.postedBy}
                            onChange={postedBy => setFilters({...filters, postedBy})}
                            autosize={true}
                            options={postedByOptions}
                            clearable={false}
                            className="react-select"
                            placeholder={'Select Posted By'}
                            classNamePrefix="react-select"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TableHead>
            <TableBody>
              <div style={{padding: '0 7px'}}>
                <Table className="material-table">
                  <QuestionsTableColumns
                      order={isSortOrderDescending ? 'desc' : 'asc'}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={rowsPerPage}
                  />
                  <TableBody>
                    {(loading || !!error) && (
                        <TableRow>
                          <TableCell colSpan={QuestionColumns.length}>
                            {loading && (
                                <div className={'text-center overflow-hidden'}>
                                  <div className="panel__refresh position-relative" style={{height: 75}}>
                                    <LoadingIcon/>
                                  </div>
                                </div>
                            )}
                            <div className={'text-center'}>
                              <p className={!!error ? 'm-5' : ''}>{error || 'Loading Q&A...'}</p>
                            </div>
                          </TableCell>
                        </TableRow>
                    )}
                    {!!!qaData.length && !loading && !error && (
                        <TableRow style={{height: 120}}>
                          <TableCell colSpan={QuestionColumns.length} align="center">
                            {filters.status === null && filters.search === ''
                                ? 'No Q&A'
                                : 'No Q&A with applied filters'}
                          </TableCell>
                        </TableRow>
                    )}
                    {qaData.map((item) => {
                      return (
                          <TableRow className="qa-row material-table" tabIndex={-1} key={item?.id}>
                            <TableCell align="center" className="material-table__cell">
                              <FormGroup>
                                <Input
                                    type={`${isEdit ? 'radio' : 'checkbox'}`}
                                    name="pinnedQuestion"
                                    value={item?.id}
                                    checked={pinnedQuestions.map(question => question.id).includes(item?.id)}
                                    onChange={(e) => togglePinnedId(item)}
                                />
                              </FormGroup>
                            </TableCell>
                            <TableCell
                                align="left"
                                className="material-table__cell"
                                onClick={() => window.open(`${cadsAiQABaseUrl}/${item?.id}/view`, '_blank', 'noopener,noreferrer')}
                            >
                              {item.questionTitle}
                            </TableCell>
                            <TableCell
                                className="material-table__cell"
                                align="center"
                                style={{width: 140}}
                                onClick={() => qaUIContext.openViewQADialog(item?.id)}
                            >
                              {item.postedBy}
                            </TableCell>
                          </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                        <TableRow style={{height: 49 * emptyRows}}>
                          <TableCell colSpan={QuestionColumns.length}/>
                        </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
            </TableBody>
            <TableFooter>
              <TablePagination
                  count={totalRecords}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  component={defaultPaginationProps.component}
                  backIconButtonProps={defaultPaginationProps.backIconButton}
                  nextIconButtonProps={defaultPaginationProps.nextIconButton}
                  rowsPerPageOptions={defaultPaginationProps.rowsPerPage}
                  className={defaultPaginationProps.className}
                  dir={defaultPaginationProps.dir}
              />
            </TableFooter>
          </div>
          {/* Selected Questions */}
          {!!pinnedQuestions.length && !isEdit && <h4 style={{marginLeft: 15, marginBottom: 10}}>Selected Questions</h4>}
          {!isEdit &&
              <div className="material-table__wrap align-self-center">
                <Table style={{padding: "0 7px"}}>
                  {pinnedQuestions.map(item =>
                      <TableRow className="qa-row material-table" tabIndex={-1} key={item?.id}>
                        <TableCell align="center" style={{width: 80}} className="material-table__cell">
                          <FormGroup>
                            <Input
                                type={'checkbox'}
                                name="selectedQuestionToPin"
                                value={item?.id}
                                defaultChecked={pinnedQuestions.map(question => question.id).includes(item?.id)}
                                onChange={(e) => togglePinnedId(item)}
                            />
                          </FormGroup>
                        </TableCell>
                        <TableCell
                            style={{width: 450}}
                            align="left"
                            className="material-table__cell"
                            onClick={() => window.open(`${cadsAiQABaseUrl}/${item?.id}/view`, '_blank', 'noopener,noreferrer')}
                        >
                          {item.questionTitle}
                        </TableCell>
                        <TableCell
                            className="material-table__cell"
                            align="center"
                            style={{width: 140}}
                            onClick={() => qaUIContext.openViewQADialog(item?.id)}
                        >
                          {item.postedBy}
                        </TableCell>
                      </TableRow>
                  )}
                </Table>
              </div>
          }
          {!isEdit && !!pinnedQuestions.length &&
              <div style={{marginLeft: 10, marginTop: 10, width: "100%"}}>
                <Button color="primary" outline onClick={pinSelectedQuestions}>
                  Pin Selected Question(s)
                </Button>
              </div>
          }
          {isEdit && +id !== pinnedQuestions?.[0]?.id &&
              <div style={{marginLeft: 10, marginTop: 10, width: "100%"}}>
                <Button color="primary" outline onClick={updatePinnedQuestion}>
                  Update Pinned Question
                </Button>
              </div>
          }
        </Fragment>
    );
  }

  return (
      <Fragment>
        {/* All Questions*/}
        <div className="ml-4">
          {pinState.loading && (
              <div>
                <div className="panel__refresh position-relative" style={{height: 75, width: 20, margin: "0 auto"}}>
                  <LoadingIcon/>
                </div>
                <div style={{width: 500, padding: '23px 30px', fontSize: 22, margin: "0 auto", textAlign: "center"}}>
                  {pinState.msg}
                </div>
              </div>
          )}
          {!pinState.loading && (pinState.msg || !!pinState.error) && (
              <Fragment>
                <div className="mt-3 text-center" style={{margin: "0 auto"}}>
                  <div style={{marginBottom: 5}}>
                    {pinState.isSuccess ? (
                        <span style={{fontSize: 50}} className="lnr lnr-thumbs-up modal__title-icon success"/>
                    ) : (
                        <span style={{fontSize: 50}} className="lnr lnr-cross-circle modal__title-icon error"/>
                    )}
                  </div>
                  <div style={{marginBottom: 26, fontSize: 22}}>
                    {pinState.msg}
                  </div>
                </div>
                <div className="w-100 text-center">
                  <Button color="success" outline onClick={modal.hide}>
                    Okay
                  </Button>
                </div>
              </Fragment>
          )}
        </div>
        {!pinState.loading && !pinState.msg && !pinState.error && renderQuestionPinningUI()}
      </Fragment>

  );
}

export default PinnedQuestionEditForm;
