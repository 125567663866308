import React from "react";
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableCell } from '@material-ui/core';
import Table from '@material-ui/core/Table';

const OrderHistoryTable = ({ orders }) => {
    const total = (acc, item) => {
        return acc + item?.amount
    }
    const totalAmount = orders?.reduce(total,0)
    return (
        <div className="container">
        <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{width:"33%"}}>ID</TableCell>
            <TableCell style={{width:"33%"}} align="center">Item Name</TableCell>
            <TableCell style={{width:"33%"}} align="right">Amount</TableCell>
          </TableRow>
        </TableHead>
        {!orders?.length && (
          <TableRow>
            <TableCell colSpan={6} align="center">
              <p className="mt-3 mb-3">There are no orders</p>
            </TableCell>
          </TableRow>
        )}
        {orders?.map((a) => (
          <TableRow>
            <TableCell>{a?.id ?? '-'}</TableCell>
            <TableCell align="center">{a?.itemName ?? '-'}</TableCell>
            <TableCell align="right">
              $ {a?.amount}
            </TableCell>
          </TableRow>
        ))}
      </Table>
      <p className="grand-total">Grand Total : ${totalAmount}</p>
      </div>
    )
}

export default OrderHistoryTable