import { httpMethod } from '@/shared/helpers/httpMethod.enum';
import { sendRequest } from '../base/ApiService';

const CADS_API_ENDPOINT =
  process.env.REACT_APP_CADS_AI_API + process.env.REACT_APP_CADS_AI_LEARNING_PATH_ENDPOINT;

const learningPathEndpoints = {
  GET_ALL_LEARNING_PATH: `/getAllLearningPaths`,
  GET_LEARNING_PATH_BY_ID: `/getLearningPathById`,
  UPDATE_LEARNING_PATH_BY_ID: `/updateLearningPathById`,
  ADD_LEARNING_PATH: `/addLearningPath`,
  GET_LEARNING_PATH_TYPE_OPTIONS: `/getLearningPathTypeOptions`,
  GET_LEARNING_PATH_GROUP_OPTIONS: `/getLearningPathGroupOptions`,
  DELETE_LEARNING_PATH: `/deleteLearningPaths`,
  GET_ASSIGNED_LP_FOR_ORG: `/getLearningPathOrganizations`,
};

const moduleEndpoint = {
  GET_MODULE_OPTIONS: `/getModuleOptions`,
};

const dataSkillsEndpoints = {
  GET_DATA_SKILLS: `/getDataSkillOptions`,
  GET_OTHER_DATA_SKILLS: `/getOtherDataSkillOptions`,
};

const getCadsAiLearningPathList = (filter) => {
  return sendRequest({
    fullUrl: CADS_API_ENDPOINT + learningPathEndpoints.GET_ALL_LEARNING_PATH,
    method: httpMethod.GET,
    params: filter,
  });
};

const getLearningPathTypeOptions = (filter) => {
  return sendRequest({
    fullUrl: CADS_API_ENDPOINT + learningPathEndpoints.GET_LEARNING_PATH_TYPE_OPTIONS,
    method: httpMethod.GET,
    params: filter,
  });
};

const getLearningPathModuleOptions = (filter) => {
  return sendRequest({
    fullUrl: CADS_API_ENDPOINT + moduleEndpoint.GET_MODULE_OPTIONS,
    method: httpMethod.GET,
    params: filter,
  });
};

const getLearningPathOtherSkills = (filter) => {
  return sendRequest({
    fullUrl: CADS_API_ENDPOINT + dataSkillsEndpoints.GET_OTHER_DATA_SKILLS,
    method: httpMethod.GET,
    params: filter,
  });
};

const unAssignLPToUser = (reqData) => {
  return unAssignMockUserLearningPath(reqData);
  return sendRequest({
    url: '',
    method: httpMethod.PUT,
    data: data,
  });
};

const unAssignLPToCohort = (reqData) => {
  return unAssignMockCohortLP(reqData);
  return sendRequest({
    url: '',
    method: httpMethod.PUT,
    data: data,
  });
};

const unAssignLPToOrg = (reqData) => {
  return unAssignMockOrgLearningPath(reqData);
  return sendRequest({
    url: '',
    method: httpMethod.PUT,
    data: data,
  });
};

const getCadsAiLearningPathOption = (filter) => {
  return sendRequest({
    fullUrl: CADS_API_ENDPOINT + learningPathEndpoints.GET_LEARNING_PATH_GROUP_OPTIONS,
    method: httpMethod.GET,
    params: filter,
  });
};

const getCadsAiAssignedOrgLearningPathList = (filter) => {
  // return getMockAssignedOrgLP(filter);
  return sendRequest({
    fullUrl: CADS_API_ENDPOINT + learningPathEndpoints.GET_ASSIGNED_LP_FOR_ORG,
    method: httpMethod.GET,
    params: filter,
  });
};

const getCadsAiAssignedCohortLP = (filter) => {
  return getMockCadsAiAssignedCohortLP(filter);
  return sendRequest({
    url: '',
    method: httpMethod.GET,
    params: filter,
  });
};

const getCadsAiAssignedUserLearningPathList = (filter) => {
  return getMockCadsAiAssignedLearningPath(filter);
  return sendRequest({
    url: '',
    method: httpMethod.GET,
    params: filter,
  });
};

const getCadsAiLearningPathSkills = (filter) => {
  return sendRequest({
    fullUrl: CADS_API_ENDPOINT + dataSkillsEndpoints.GET_DATA_SKILLS,
    method: httpMethod.GET,
    params: filter,
  });
};

const getCadsAiLearningPath = (id) => {
  return sendRequest({
    fullUrl: CADS_API_ENDPOINT + learningPathEndpoints.GET_LEARNING_PATH_BY_ID,
    method: httpMethod.GET,
    params: { id: id },
  });
};

const cadsAiAddLearningPath = (learningPathData) => {
  return sendRequest({
    fullUrl: CADS_API_ENDPOINT + learningPathEndpoints.ADD_LEARNING_PATH,
    method: httpMethod.POST,
    data: learningPathData,
  });
};

const cadsAiUpdateLearningPath = (learningPathData) => {
  return sendRequest({
    fullUrl: CADS_API_ENDPOINT + learningPathEndpoints.UPDATE_LEARNING_PATH_BY_ID,
    method: httpMethod.PUT,
    data: learningPathData,
  });
};

const changeLearningPathState = (learningPathIds) => {
  return sendRequest({
    fullUrl: CADS_API_ENDPOINT + learningPathEndpoints.DELETE_LEARNING_PATH,
    method: httpMethod.DELETE,
    params: { id: learningPathIds },
  });
};

const unAssignMockUserLearningPath = (responseData) => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          body: responseData,
        }),
      500,
    );
  });
};

const unAssignMockOrgLearningPath = (responseData) => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          body: responseData,
        }),
      500,
    );
  });
};

const unAssignMockCohortLP = (responseData) => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          body: responseData,
        }),
      500,
    );
  });
};

const getMockCadsAiAssignedLearningPath = () => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [],
            data: {
              totalRecords: 2,
              records: [
                {
                  email: 'mock1@gmail.com',
                  jobseekerId: 425,
                  userFullName: 'Mock user name',
                  learningPathList: [
                    {
                      allowRecommendation: true,
                      fullName: 'Mock 1',
                      group: { id: 2, name: 'EDA' },
                      imageUrl:
                        'https://general-assets.sgp1.digitaloceanspaces.com/training-icons/eda_210616.png',
                      learningPathRecomId: 12,
                      lpId: 3,
                      main: true,
                      shortName: 'EDA',
                      summary: 'test1',
                    },
                  ],
                  picUrl:
                    'https://general-assets.sgp1.digitaloceanspaces.com/skillstreets/561/e9a1a904-b438-4d7e-b5de-56617fe9ad64-56a264cd-c0cf-4082-a829-abdf73abfc6b.png',
                },
                {
                  email: 'mock2@gmail.com',
                  jobseekerId: 425,
                  userFullName: 'Mock2 user name',
                  learningPathList: [
                    {
                      allowRecommendation: true,
                      fullName: 'Mock 2',
                      group: { id: 2, name: 'EDA' },
                      imageUrl:
                        'https://general-assets.sgp1.digitaloceanspaces.com/training-icons/eda_210616.png',
                      learningPathRecomId: 12,
                      lpId: 3,
                      main: true,
                      shortName: 'EDA',
                      summary: 'test1',
                    },
                  ],
                  picUrl:
                    'https://general-assets.sgp1.digitaloceanspaces.com/skillstreets/561/e9a1a904-b438-4d7e-b5de-56617fe9ad64-56a264cd-c0cf-4082-a829-abdf73abfc6b.png',
                },
              ],
            },
          },
        }),
      500,
    );
  });
};

const getMockCadsAiAssignedCohortLP = () => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [],
            data: {
              totalRecords: 2,
              records: [
                {
                  email: 'mock1@gmail.com',
                  jobseekerId: 425,
                  userFullName: 'Mock Cohort',
                  learningPathList: [
                    {
                      allowRecommendation: true,
                      fullName: 'Mock 1',
                      group: { id: 2, name: 'EDA' },
                      imageUrl:
                        'https://general-assets.sgp1.digitaloceanspaces.com/training-icons/eda_210616.png',
                      learningPathRecomId: 12,
                      lpId: 3,
                      main: true,
                      shortName: 'EDA',
                      summary: 'test1',
                    },
                  ],
                  picUrl:
                    'https://general-assets.sgp1.digitaloceanspaces.com/skillstreets/561/e9a1a904-b438-4d7e-b5de-56617fe9ad64-56a264cd-c0cf-4082-a829-abdf73abfc6b.png',
                },
                {
                  email: 'mock2@gmail.com',
                  jobseekerId: 425,
                  userFullName: 'Mock2 Cohort',
                  learningPathList: [
                    {
                      allowRecommendation: true,
                      fullName: 'Mock 2',
                      group: { id: 2, name: 'EDA' },
                      imageUrl:
                        'https://general-assets.sgp1.digitaloceanspaces.com/training-icons/eda_210616.png',
                      learningPathRecomId: 12,
                      lpId: 3,
                      main: true,
                      shortName: 'EDA',
                      summary: 'test1',
                    },
                  ],
                  picUrl:
                    'https://general-assets.sgp1.digitaloceanspaces.com/skillstreets/561/e9a1a904-b438-4d7e-b5de-56617fe9ad64-56a264cd-c0cf-4082-a829-abdf73abfc6b.png',
                },
              ],
            },
          },
        }),
      500,
    );
  });
};

const getMockAssignedOrgLP = () => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [],
            data: {
              totalRecords: 2,
              records: [
                {
                  learningPaths: [
                    {
                      learningPathId: 83,
                      learningPathTitle: "Mock ORG LP 1"
                    },
                    {
                      learningPathId: 84,
                      learningPathTitle: "Mock ORG LP 10"
                    }
                  ],
                  learningPathTitle: "Mock ORG LP 1",
                  organizationId: 11,
                  organizationName: "MOCK ORG 1",
                  organizaionLogoUrl: "https://www.simplilearn.com/ice9/free_resources_article_thumb/Data-Science-vs.-Big-Data-vs.jpg",
                  id: 11
                },
                {
                  learningPaths: [
                    {
                      learningPathId: 84,
                      learningPathTitle: "Mock ORG LP 2"
                    }
                  ],
                  learningPathTitle: "Mock ORG LP 2",
                  organizationId: 12,
                  organizationName: "MOCK ORG 2",
                  organizaionLogoUrl: "https://www.simplilearn.com/ice9/free_resources_article_thumb/Data-Science-vs.-Big-Data-vs.jpg",
                  id: 12
                },
              ],
            },
          },
        }),
      500,
    );
  });
};

export default {
  getCadsAiLearningPathList,
  cadsAiAddLearningPath,
  cadsAiUpdateLearningPath,
  getCadsAiLearningPath,
  getCadsAiLearningPathOption,
  changeLearningPathState,
  getCadsAiLearningPathSkills,
  getLearningPathTypeOptions,
  getLearningPathOtherSkills,
  getLearningPathModuleOptions,
  getCadsAiAssignedUserLearningPathList,
  getCadsAiAssignedOrgLearningPathList,
  getCadsAiAssignedCohortLP,
  unAssignLPToUser,
  unAssignLPToCohort,
  unAssignLPToOrg,
};
