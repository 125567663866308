import React from 'react';
import { Row } from 'reactstrap';
import StatsCard from './StatsCard';
import FlashIcon from 'mdi-react/FlashIcon';
import WalkIcon from 'mdi-react/WalkIcon';
import api from '@/utils/apiClient';

export default function StatsSection() {
  const [customerStats, setCustomerStats] = React.useState(null);
  const [individualStats, setIndividualStats] = React.useState(null);
  const [assessmentStats, setAssessmentStats] = React.useState(null);

  React.useEffect(() => {
    getCustomerStats();
    getIndividualStats();
    // getAssessmentStats();
  }, []);

  const getCustomerStats = async () => {
    try {
      const { data: res, isSuccess } = await api.dashboard.getTotalCustomerCount();
      if (isSuccess) {
        const activeCount = res?.data?.activeCount ?? 0;
        const inactiveCount = res?.data?.inactiveCount ?? 0;
        const totalCount = activeCount + inactiveCount;
        const activePercent = +((activeCount / totalCount) * 100).toFixed(2);
        setCustomerStats({
          title: 'Customer',
          subTitle: 'Active',
          colorFill: '#ff4861',
          stats: activePercent,
          statsTitle: `${activePercent}%`,
          footerText: [`Active : ${activeCount}`, `Inactive : ${inactiveCount}`],
        });
      } else {
        throw new Error('error while fetching customer dashboard status');
      }
    } catch (error) {
      console.error('Error inside dashboard customer stats', error.message);
    }
  };

  const getIndividualStats = async () => {
    try {
      const { data: res, isSuccess } = await api.dashboard.getTotalIndividualCount();
      if (isSuccess) {
        const activeCount = res?.data?.activeCount ?? 0;
        const inactiveCount = res?.data?.inactiveCount ?? 0;
        const totalCount = activeCount + inactiveCount;
        const activePercent = +((activeCount / totalCount) * 100).toFixed(2);
        setIndividualStats({
          title: 'Individual',
          subTitle: 'Active',
          colorFill: '#f6da6e',
          icon: FlashIcon,
          stats: activePercent,
          statsTitle: `${activePercent}%`,
          footerText: [`Active : ${activeCount}`, `Inactive : ${inactiveCount}`],
        });
      } else {
        throw new Error('error while fetching individual dashboard status');
      }
    } catch (error) {
      console.error('Error inside dashboard individual stats', error.message);
    }
  };

  const getAssessmentStats = async () => {
    try {
      const { data: res, isSuccess } = await api.dashboard.getTotalAssessmentCount();
      if (isSuccess) {
        const assessmentCount = res?.data ?? 0;
        setAssessmentStats({
          title: 'Assessments',
          subTitle: 'Total',
          colorFill: '#4ce1b6',
          icon: WalkIcon,
          stats: assessmentCount?.totalCount,
          statsTitle: `${assessmentCount?.totalCount}`,
          footerText: [
            `Data-Star Pre Assessment : ${assessmentCount?.datastarPreAssessmentCount}`,
            `Smart SKill Assessments : ${assessmentCount?.smartSkillCount}`,
          ],
        });
      } else {
        throw new Error('error while fetching assessment dashboard status');
      }
    } catch (error) {
      console.error('Error inside dashboard assessment stats', error.message);
    }
  };

  return (
    <Row>
      {!!customerStats && <StatsCard {...customerStats} />}
      {!!individualStats && <StatsCard {...individualStats} />}
      {!!assessmentStats && <StatsCard {...assessmentStats} />}
    </Row>
  );
}
