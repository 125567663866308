import React from 'react';
// import { Route, Switch } from "react-router-dom";
import { campaignSunrise25UsersBaseUrl } from '../index';
import Sunrise25UsersUIProvider from './Sunrise25UsersUIProvider';
import Sunrise25UsersCard from './Sunrise25UsersCard';

const Sunrise25UsersPage = ({ history, match }) => {
  const sunrise25UsersUIEvents = {
    openViewSunrise25UserDialog: (id) => {
      history.push(`${campaignSunrise25UsersBaseUrl}/${id}/view`);
    },
  };

  return (
    <Sunrise25UsersUIProvider sunrise25UsersUIEvents={sunrise25UsersUIEvents}>
      {/* <Switch></Switch> */}
      <Sunrise25UsersCard />
    </Sunrise25UsersUIProvider>
  );
};

export default Sunrise25UsersPage;
