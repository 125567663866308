import React from 'react';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import api from '@/utils/apiClient';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { uncheckAllQA } from '../../../redux/actions';
import PinOffOutlineIcon from "mdi-react/PinOffOutlineIcon";

const UnpinQuestionDialog = ({show, onHide, id}) => {
  const dispatch = useDispatch();
  const {rtl} = useSelector((state) => state, shallowEqual);
  const [modal, setModal] = React.useState({
    isOpen: show,
    title: '',
    message: 'Are you sure you want to unpin question?',
    hide: onHide,
    loading: false,
    deleteSuccess: false,
    deleteError: false,
    isError: false,
  });

  const handleUnpinQuestion = async () => {
    const {isSuccess} = await api.cadsAIQA.unpinQuestion(id);
    if (isSuccess) {
      dispatch(uncheckAllQA());
      setModal({
        ...modal,
        loading: false,
        deleteSuccess: true,
        message: 'Question unpinned successfully.',
      });
    } else {
      setModal({
        ...modal,
        loading: false,
        message: 'Failed to unpin question.',
        isError: true,
        deleteError: true,
      });
    }
  };

  return (
      <Modal
          isOpen={show}
          toggle={modal.hide}
          keyboard={false}
          backdrop={'static'}
          modalClassName={`${rtl.direction}-support`}
          className="modal-dialog--primary modal-dialog"
      >
        <div className="modal__header">
          <button
              className="lnr lnr-cross modal__close-btn"
              aria-label="modal__close-btn"
              type="button"
              onClick={modal.hide}
          />
          {!(modal.loading || modal.deleteSuccess || modal.deleteError) && (
              <PinOffOutlineIcon style={{height: 50, width: 50}} color="#ff4861"/>
          )}
          {modal.deleteSuccess && <span style={{fontSize: 50}} className="lnr lnr-thumbs-up modal__title-icon success"/>}
          {modal.deleteError && <span style={{fontSize: 50}} className="lnr lnr-cross-circle modal__title-icon error"/>}
          <h4 className="text-modal  modal__title">{modal.title}</h4>
          <div className="modal__body">
            {modal.loading && (
                <div className="panel__refresh position-relative" style={{height: 75}}>
                  <LoadingIcon/>
                </div>
            )}
            <span style={{fontSize: 22}}>{modal.message}</span>
          </div>
          <ButtonToolbar className="modal__footer">
            {modal.deleteError && (
                <Button className="modal_cancel" onClick={onHide} outline>
                  Close
                </Button>
            )}
            {modal.deleteSuccess && (
                <>
                  <Button className="modal_cancel" onClick={onHide} outline>
                    Okay
                  </Button>
                </>
            )}
            {!(modal.deleteSuccess || modal.deleteError || modal.loading) && (
                <>
                  <Button className="modal_cancel" onClick={onHide}>
                    Cancel
                  </Button>
                  <Button className="modal_ok" outline color="danger" onClick={handleUnpinQuestion}>
                    Yes, Unpin
                  </Button>
                </>
            )}
          </ButtonToolbar>
        </div>
      </Modal>
  );
};

export default UnpinQuestionDialog;
