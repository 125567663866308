import { handleActions } from 'redux-actions';
import {
  setDomainList,
  fetchDomainList,
  setDomainError,
  setDomainFilters,
  updateInDomainList,
  deleteDomainFromList,
  setDomainOptionsList,
} from './actions';

const defaultState = {
  loading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    searchText: '',
    status: null,
    isSortOrderDescending: false,
  },
  totalRecords: 0,
  data: [],
  options: [],
  error: null,
};

export default handleActions(
  {
    [fetchDomainList](state) {
      return {
        ...state,
        loading: true,
        data: [],
      };
    },
    [setDomainList](state, { payload }) {
      return {
        ...state,
        loading: false,
        data: payload.data.records,
        totalRecords: payload.data.totalRecords,
        error: null,
      };
    },
    [setDomainOptionsList](state, { payload }) {
      return {
        ...state,
        options: payload.data,
      };
    },
    [updateInDomainList](state, { payload }) {
      return {
        ...state,
        data: state.data.map((domain) =>
          +domain.id === +payload.id
            ? { ...domain, name: payload.name, description: payload.description }
            : domain,
        ),
      };
    },
    [deleteDomainFromList](state, { payload }) {
      return {
        ...state,
        data: state.data.filter((domain) => domain.id !== payload),
        totalRecords: state.totalRecords - 1,
      };
    },
    [setDomainError](state, { payload }) {
      return {
        ...state,
        data: [],
        loading: false,
        error: payload?.message,
      };
    },
    [setDomainFilters](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
      };
    },
  },
  defaultState,
);
