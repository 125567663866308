import { useEffect } from 'react';
import Highcharts from 'highcharts/highstock';
import Tree from 'highcharts/modules/treemap';
import exporting from 'highcharts/modules/exporting';
import data from 'highcharts/modules/data';
import Heatmap from 'highcharts/modules/heatmap';

Tree(Highcharts);
data(Highcharts);
Heatmap(Highcharts);
exporting(Highcharts);

const SkillListGraph = ({ otherSkills, dataSkills }) => {
  const initialChart = () => {
    Highcharts.chart('SkillListGraph', {
      chart: {
        type: 'bar',
      },
      title: {
        text: '',
      },
      xAxis: {
        lineWidth: 0,
        type: 'category',
        min: 0,
        max: 6,
        labels: {
          align: 'left',
          x: 5,
          y: 4,
          style: {
            color: '#fff',
            fontSize: '12px',
            fontWeight: 'bold',
          },
        },
        scrollbar: {
          enabled: true,
        },
      },
      yAxis: {
        visible: false,
      },
      colors: ['#42bd99', '#589bd6'],
      plotOptions: {
        bar: {
          grouping: false,
          dataLabels: {
            enabled: true,
          },
        },
        column: { colorByPoint: true },
      },

      legend: {
        enabled: true,
        labelFormatter: function () {
          return this.name;
        },
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      series: [
        {
          name: 'Data skills',
          data: dataSkills,
        },
        {
          name: 'Other skills',
          data: otherSkills,
        },
      ],
    });
  };

  useEffect(() => {
    initialChart();
  }, []);

  return <div id="SkillListGraph" className="SkillsTrend" />;
};

export default SkillListGraph;
