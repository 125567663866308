import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router';
import { cadsAiSponsorsBaseUrl } from '../index';
import { setSponsorFilters } from '../redux/actions';
import { SponsorsCard } from './SponsorsCard';
import { SponsorUIProvider } from './SponsorsUIProvider';
import SponsorsEditDialog from './sponsors-add-edit-dialog/SponsorsEditDialog';

export function SponsorsPage({ history, match }) {
  const dispatch = useDispatch();
  const sponsorUIEvents = {
    openNewSponsorDialog: () => {
      history.push(`${cadsAiSponsorsBaseUrl}/new`);
    },
    openEditSponsorDialog: (id) => {
      history.push(`${cadsAiSponsorsBaseUrl}/${id}/edit`);
    },
  };

  return (
    <SponsorUIProvider sponsorUIEvents={sponsorUIEvents}>
      <Switch>
        <Route path={`${cadsAiSponsorsBaseUrl}/new`}>
          {({ history, match }) => (
            <SponsorsEditDialog
              show={match != null}
              isEdit={false}
              onHide={() => {
                dispatch(setSponsorFilters());
                history.push(cadsAiSponsorsBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${cadsAiSponsorsBaseUrl}/:id/edit`}>
          {({ history, match }) => (
            <SponsorsEditDialog
              show={match != null}
              isEdit={true}
              id={match?.params?.id}
              onHide={() => {
                dispatch(setSponsorFilters());
                history.push(cadsAiSponsorsBaseUrl);
              }}
            />
          )}
        </Route>
      </Switch>
      <SponsorsCard />
    </SponsorUIProvider>
  );
}
