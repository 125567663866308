import { sendRequest } from '../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const programsEndpoints = {
  ADD_PROGRAM: '/addProgram',
  EDIT_PROGRAM: '/editProgram',
  GET_PROGRAM: '/getProgramById',
  GET_PROGRAM_LIST: '/getAllPrograms',
  GET_PROGRAM_LIST_CSV: '/exportAllProgramsToCsv',
  GET_PROGRAM_TYPE_OPTIONS: '/getProgramTypeOptions',
  GET_PROGRAM_REFERENCE_OPTIONS: '/getProgramReferenceOptions',
  GET_PROGRAM_COUNT: '/getProgramCount',
  EDIT_PROGRAM_LIST_STATE: '/changeProgramListState',
  GET_ALL_PROGRAM_OPTIONS: '/getAllProgramOptions',
  ATTACH_COHORT_TO_PROGRAM: '/attachCohortToProgram',
  DETACH_COHORT_FROM_PROGRAM: '/detachCohortFromProgram',
};

/**
 * @param programData Information required for adding programs.
 * @param {string} programData.program_type_id
 * @param {string} programData.title
 * @param {string} programData.headline
 * @param {number} programData.description
 * @param {number} programData.start_date
 * @param {string} programData.end_date
 * @param {string} programData.tags
 * @param {string} programData.location
 * @param {string} programData.country_name
 * @param {string} programData.bg_img_url
 * @param {string} programData.logo_img_url
 * @param {string} programData.main_img_url
 * @param {string} programData.organization_id
 */
const addProgram = (programData) => {
  return sendRequest(
    {
      url: programsEndpoints.ADD_PROGRAM,
      method: httpMethod.POST,
      data: programData,
    },
    process.env.REACT_APP_PROGRAM_ADMIN_ENDPOINT,
  );
};

const updateProgram = (programData) => {
  return sendRequest(
    {
      url: programsEndpoints.EDIT_PROGRAM,
      method: httpMethod.PUT,
      data: programData,
    },
    process.env.REACT_APP_PROGRAM_ADMIN_ENDPOINT,
  );
};

/**
 * @param {number} programId id of program to fetch more details about the program
 */
const getProgram = (programId) => {
  return sendRequest(
    {
      url: programsEndpoints.GET_PROGRAM,
      method: httpMethod.GET,
      params: { id: programId },
    },
    process.env.REACT_APP_PROGRAM_ADMIN_ENDPOINT,
  );
};

const getProgramList = (filter) => {
  return sendRequest(
    {
      url: programsEndpoints.GET_PROGRAM_LIST,
      method: httpMethod.GET,
      params: filter,
    },
    process.env.REACT_APP_PROGRAM_ADMIN_ENDPOINT,
  );
};

/**
 * @param filter to filter data based on some parameters
 * @param {string} filter.search to filter programs by search text
 * @param {string} filter.sortBy to sort based on any field
 * @param {boolean} filter.isSortOrderDescending true if search direction is descending
 **/
const getProgramListCSV = (filter) => {
  return sendRequest(
    {
      url: programsEndpoints.GET_PROGRAM_LIST_CSV,
      method: httpMethod.GET,
      params: filter,
    },
    process.env.REACT_APP_PROGRAM_ADMIN_ENDPOINT,
  );
};

const getProgramTypeOptions = () => {
  return sendRequest(
    {
      url: programsEndpoints.GET_PROGRAM_TYPE_OPTIONS,
      method: httpMethod.GET,
    },
    process.env.REACT_APP_PROGRAM_ADMIN_ENDPOINT,
  );
};

const getProgramReferenceOptions = () => {
  return sendRequest(
    {
      url: programsEndpoints.GET_PROGRAM_REFERENCE_OPTIONS,
      method: httpMethod.GET,
    },
    process.env.REACT_APP_PROGRAM_ADMIN_ENDPOINT,
  );
};

const getProgramCount = (filters) => {
  return sendRequest(
    {
      url: programsEndpoints.GET_PROGRAM_COUNT,
      method: httpMethod.GET,
      params: filters,
    },
    process.env.REACT_APP_PROGRAM_ADMIN_ENDPOINT,
  );
};

const getAllProgramOptions = () => {
  return sendRequest(
    {
      url: programsEndpoints.GET_ALL_PROGRAM_OPTIONS,
      method: httpMethod.GET,
    },
    process.env.REACT_APP_PROGRAM_ADMIN_ENDPOINT,
  );
};

const changeProgramListState = (programIds, state) => {
  return sendRequest(
    {
      url: programsEndpoints.EDIT_PROGRAM_LIST_STATE,
      method: httpMethod.PUT,
      data: { programIds, state },
    },
    process.env.REACT_APP_PROGRAM_ADMIN_ENDPOINT,
  );
};

const attachCohortToProgram = (programId, cohortDetails) => {
  return sendRequest(
    {
      url: programsEndpoints.ATTACH_COHORT_TO_PROGRAM,
      method: httpMethod.POST,
      data: {
        programId,
        cohortDetails,
      },
    },
    process.env.REACT_APP_PROGRAM_ADMIN_ENDPOINT,
  );
};

const detachCohortFromProgram = (programId, cohortId) => {
  return sendRequest(
    {
      url: programsEndpoints.DETACH_COHORT_FROM_PROGRAM,
      method: httpMethod.DELETE,
      data: {
        programId,
        cohortDetails: { id: cohortId, title: '' },
      },
    },
    process.env.REACT_APP_PROGRAM_ADMIN_ENDPOINT,
  );
};

export default {
  addProgram,
  updateProgram,
  getProgram,
  getProgramListCSV,
  getProgramList,
  getProgramTypeOptions,
  getProgramReferenceOptions,
  getProgramCount,
  changeProgramListState,
  getAllProgramOptions,
  attachCohortToProgram,
  detachCohortFromProgram,
};
