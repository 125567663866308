import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'reactstrap';
import TableHead from '@material-ui/core/TableHead';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  setApplicantFilters,
  setSelectedApplicantStageId,
  setSelectedProgramId,
} from '../../redux/actions';
import Select from 'react-select';
import api from '@/utils/apiClient';

export function ApplicantsTableSearchBar({ history }) {
  const dispatch = useDispatch();
  const {data: applicant,filters} = useSelector(
    (state) => state.cadsAIApplicants,
    shallowEqual,
  );

  const [isSearched, setIsSearched] = useState(false);
  const [selectedStage, setSelectedStage] = useState(null);
  const [applicantStageOpts, setApplicantStageOpts] = useState([]);
  const [programOpts, setProgramOpts] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState(null);

  const getStageOptions = async () => {
    const { data: res, isSuccess } = await api.cadsAIApplicant.getApplicantStageOptions();
    if (isSuccess) {
      setApplicantStageOpts(res?.data?.map((i) => ({ value: i.id, label: i.title })));
    }
  };

  const getProgramOptions = async () => {
    const { data: res, isSuccess } = await api.cadsAIPrograms.getProgramOptionsList();
    if (isSuccess) {
      const programOptions = res.data?.map((program) => ({
        value: program.id,
        label: program.title,
      }));
      setProgramOpts(programOptions);
    }
  };

  useEffect(() => {
    getStageOptions();
    getProgramOptions();
  }, []);

  useEffect(() => {
    dispatch(setSelectedApplicantStageId(selectedStage?.value));
  }, [selectedStage]);

  useEffect(() => {
    dispatch(setSelectedProgramId(selectedProgram?.value));
  }, [selectedProgram]);

  useEffect(() => {
    if (selectedProgram?.value) handleSearch();
  }, [selectedProgram?.value]);

  const searchNameRef = useRef(null);
  const searchEmailRef = useRef(null);

  const handleSearch = () => {
    dispatch(
      setApplicantFilters({
        searchByName: searchNameRef?.current?.value,
        searchByEmail: searchEmailRef?.current?.value,
        programId: selectedProgram?.value,
        applicantStageId: selectedStage?.value,
      }),
    );
  };

  const handleClear = () => {
    if (searchNameRef?.current?.value) searchNameRef.current.value = '';
    if (searchEmailRef?.current?.value) searchEmailRef.current.value = '';
    setIsSearched(false);
    dispatch(setApplicantFilters({ searchByName: '', searchByEmail: '' }));
    setSelectedProgram(null);
    setSelectedStage(null);
  };

  return (
    <TableHead>
      <div className="table__search mt-4">
        <div className="table__search_item">
          <b>Applicant Name</b>
          <div className="form" style={{ width: 200 }}>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <div className="form__form-group-icon" style={{ height: 37 }}>
                  <MagnifyIcon />
                </div>
                <input
                  ref={searchNameRef}
                  className="search-bar-search-text-small"
                  name="searchText"
                  defaultValue={filters.searchByName}
                  type="text"
                  placeholder="Search by Applicant Name"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table__search_item">
          <b>Applicant Email</b>
          <div className="form" style={{ width: 200 }}>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <div className="form__form-group-icon" style={{ height: 37 }}>
                  <MagnifyIcon />
                </div>
                <input
                  ref={searchEmailRef}
                  className="search-bar-search-text-small"
                  name="searchByEmail"
                  defaultValue={filters.searchByEmail}
                  type="text"
                  placeholder="Search by Applicant Email"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table__search_item">
          <b>Applicant Stage</b>
          <div style={{ minWidth: 170 }}>
            <Select
              name="applicantStage"
              value={selectedStage}
              onChange={setSelectedStage}
              autosize={true}
              options={applicantStageOpts}
              clearable={false}
              className="react-select"
              placeholder={'Select Applicant Stage'}
              classNamePrefix="react-select"
            />
          </div>
        </div>
        <div className="table__search_item">
          <b>Program</b>
          <div style={{ minWidth: 200 }}>
            <Select
              name="program"
              value={selectedProgram}
              onChange={setSelectedProgram}
              autosize={true}
              options={programOpts}
              clearable={false}
              className="react-select"
              placeholder={'Select Program'}
              classNamePrefix="react-select"
            />
          </div>
        </div>
        <div className="table__search_item mt-3">
          <Button color="primary" onClick={handleSearch}>
            {' '}
            Search{' '}
          </Button>
        </div>
        <div className="table__search_item mt-3">
          <Button color="secondary" onClick={handleClear} outline>
            Clear
          </Button>
        </div>
      </div>

      {isSearched && (
        <div className="ml-1">
          <span>Found {applicant?.length} matches</span>
        </div>
      )}
    </TableHead>
  );
}
