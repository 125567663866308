import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const fetchArticleList = createAction('CADS_AI_FETCH_ANNOUNCEMENT_LIST');
export const setArticleList = createAction('CADS_AI_SET_ANNOUNCEMENT_LIST');
export const setArticleCount = createAction('CADS_AI_SET_ANNOUNCEMENT_COUNT');
export const setArticleError = createAction('CADS_AI_SET_ANNOUNCEMENT_ERROR');
export const setArticleFilters = createAction('CADS_AI_SET_ANNOUNCEMENT_FILTERS');
export const toggleCheckedArticle = createAction('CADS_AI_TOGGLE_CHECKED_ANNOUNCEMENTS');
export const uncheckAllArticle = createAction('CADS_AI_UNCHECK_ALL_ANNOUNCEMENTS');

export const toggleCheckedItemById = (articleId) => (dispatch) => {
  dispatch(toggleCheckedArticle(articleId));
};

export const getArticleCount = (filter) => async (dispatch) => {};

export const getArticleList = (filter) => async (dispatch) => {
  const defaultError = `Error occurred getting article list`;
  dispatch(fetchArticleList());
  try {
    const { data, isSuccess } = await api.cadsAIArticle.getArticleList(filter);
    if (isSuccess) dispatch(setArticleList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setArticleError(error.message));
    console.error(defaultError, error.message);
  }
};
