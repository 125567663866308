import { httpMethod } from '@/shared/helpers/httpMethod.enum';
import { sendRequest } from '../base/ApiService';

const CADS_AI_USER_API =
  process.env.REACT_APP_CADS_AI_API + process.env.REACT_APP_CADS_AI_LEARNING_PATH_ENDPOINT;

const getCadsAIUserList = (filter) => {
  return sendRequest({
    fullUrl: CADS_AI_USER_API + '/getAllUsersOfOrg',
    method: httpMethod.GET,
    params: filter,
  });
};

const getMockUserList = () => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [],
            data: {
              totalRecords: 3,
              records: [
                {
                  id: 1,
                  firstName: 'Mock 1',
                  lastName: 'User 1',
                  userName: 'Mock User',
                  email: 'mock@gmail.com',
                  isEmailVerified: true,
                  isDataStar: true,
                  registerIsb2b: true,
                  registerIsCampaign: true,
                },
                {
                  id: 3,
                  firstName: 'Mock 3',
                  lastName: 'User 3',
                  userName: 'Mock User 3',
                  email: 'mock3@gmail.com',
                  isEmailVerified: true,
                  isDataStar: true,
                  registerIsb2b: true,
                  registerIsCampaign: true,
                },
                {
                  id: 5,
                  firstName: 'Mock 2',
                  lastName: 'User 2',
                  userName: 'Mock User 2',
                  email: 'mock2@gmail.com',
                  isEmailVerified: true,
                  isDataStar: true,
                  registerIsb2b: true,
                  registerIsCampaign: true,
                },
              ],
            },
          },
        }),
      500,
    );
  });
};

export default {
  getCadsAIUserList,
};
