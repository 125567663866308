import React from 'react';
import { Button } from 'reactstrap';
import Select from 'react-select';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getOrganizationOptionsList,
  setOrgState,
} from '@/containers/Cads-AI/redux/organization/actions';
import { setUserState } from '@/containers/Cads-AI/redux/user/actions';
import { setAssignTenantId, setSelectedOrganizationId } from '@/containers/Cads-AI/redux/actions';
import { setCohortState } from '@/containers/Cads-AI/redux/cohort/actions';
import { USERS, ORGANIZATION, COHORT } from '@/shared/helpers/constants';
import { defaultUserState } from '@/containers/Cads-AI/redux/user/reducer';
import { defaultCohortState } from '@/containers/Cads-AI/redux/cohort/reducer';
import { defaultOrgState } from '@/containers/Cads-AI/redux/organization/reducer';
import { getCohortOptionsList } from '../../../../redux/cohort/actions';
import { setSelectedCohortId, setSelectedOrganizationTypeId } from '../../../../redux/actions';
import { getOrganizationTypeList } from '../../../../redux/organization/actions';

const AssignTableFilter = () => {
  const dispatch = useDispatch();

  const { activeTab, isFromCourse } = useSelector((state) => state.cadsAIAssignPage, shallowEqual);
  const { assignedProduct } = useSelector((state) => state.cadsAI, shallowEqual);
  const { options: organizationOpts } = useSelector((state) => state.cadsAIOrgs, shallowEqual);
  const { totalRecords: totalUser } = useSelector(
    (state) => state.cadsAIAssignmentUser,
    shallowEqual,
  );
  const { totalRecords: totalCohort, options } = useSelector(
    (state) => state.cadsAIAssignmentCohort,
    shallowEqual,
  );
  const { totalRecords: totalOrg, types: orgTypes } = useSelector(
    (state) => state.cadsAIAssignmentOrgs,
    shallowEqual,
  );
  const { checkedModule } = useSelector((state) => state.cpModules, shallowEqual);

  const [isSearched, setIsSearched] = React.useState(false);
  const [selectedOrgOpt, setSelectedOrgOpt] = React.useState({});
  const [selectedOrgType, setSelectedOrgType] = React.useState({});
  const [organizationOptions, setOrganizationOptions] = React.useState([]);
  const [organizationtypes, setOrganizationTypes] = React.useState([]);
  const [selectedCohortOpt, setSelectedCohortOpt] = React.useState({});
  const [cohortOptions, setCohortOptions] = React.useState([]);

  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');

  React.useEffect(() => {
    dispatch(getOrganizationOptionsList(selectedOrgType?.value));
  }, [selectedOrgType?.value]);

  React.useEffect(() => {
    dispatch(getOrganizationTypeList());
  }, []);

  React.useEffect(() => {
    const firstOrg = organizationOpts?.[0] ?? null;
    if (firstOrg) {
      setSelectedOrgOpt({ value: firstOrg.id, label: firstOrg.title, ...firstOrg });
      dispatch(setSelectedOrganizationId(firstOrg.id));
      setOrganizationOptions(
        organizationOpts?.map((org) => ({ value: org.id, label: org.title })) ?? [],
      );
    }
  }, [organizationOpts]);

  React.useEffect(() => {
    setOrganizationTypes(
      orgTypes?.map((org) => ({ value: org.id, label: org.title, tenantId: org.tenantId })) ?? [],
    );
  }, [orgTypes]);

  React.useEffect(() => {
    dispatch(getCohortOptionsList(selectedOrgOpt.value));
  }, [selectedOrgOpt]);

  React.useEffect(() => {
    if (selectedOrgType?.value) {
      dispatch(setSelectedOrganizationTypeId(selectedOrgType?.value));
    }
  }, [selectedOrgType]);

  React.useEffect(() => {
    setSelectedCohortOpt({});
    dispatch(setSelectedCohortId());
    setCohortOptions(options?.map((cohort) => ({ value: cohort.id, label: cohort.title })) ?? []);
  }, [options]);

  React.useEffect(() => {
    if (selectedCohortOpt.value) dispatch(setSelectedCohortId(selectedCohortOpt.value));
  }, [selectedCohortOpt]);

  React.useEffect(() => {
    if (selectedOrgOpt.tenantId) dispatch(setAssignTenantId(selectedOrgOpt.tenantId));
    dispatch(setSelectedOrganizationId(selectedOrgOpt?.value));
  }, [selectedOrgOpt]);

  React.useEffect(() => {
    setName('');
    setEmail('');
  }, [assignedProduct]);

  const filterData = (isClear = false) => {
    const orgId = selectedOrgOpt.value;
    const searchAssign = isClear
      ? { ...defaultUserState.searchAssign, orgId }
      : { searchText: name, email, orgId };
    const filter = { assigned: false, feature: assignedProduct.id, searchAssign };
    if (isFromCourse) filter.moduleId = checkedModule.id;
    switch (activeTab) {
      case USERS:
        return dispatch(setUserState({ ...defaultUserState, ...filter }));
      case COHORT:
        return dispatch(setCohortState({ ...defaultCohortState, ...filter }));
      case ORGANIZATION:
        return dispatch(setOrgState({ ...defaultOrgState, ...filter }));
      default:
        return false;
    }
  };

  const handleSearch = () => {
    filterData(false);
    setIsSearched(true);
  };

  const handleClear = () => {
    setIsSearched(false);
    setName('');
    setEmail('');
    filterData(true);
  };

  return (
    <>
      <div className="table__search">
        <div className="table__search_item">
          <b>Name</b>
          <div className="form">
            <div className="form__form-group">
              <div className="form__form-group-field">
                <input
                  style={{ height: 36, minWidth: 200 }}
                  name="searchText"
                  type="text"
                  placeholder="Search by Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        {activeTab === USERS && (
          <div className="table__search_item">
            <b>Email</b>
            <div className="form">
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <input
                    style={{ height: 36, minWidth: 200 }}
                    name="searchText"
                    type="email"
                    placeholder="Search by Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {activeTab.includes(USERS) && (
          <div className="table__search_item">
            <b>Cohort</b>
            <div style={{ minWidth: 200 }}>
              <Select
                name="cohort"
                options={cohortOptions}
                value={selectedCohortOpt}
                onChange={setSelectedCohortOpt}
                autosize={true}
                clearable={false}
                className="react-select"
                placeholder={'Select Cohort'}
                classNamePrefix="react-select"
              />
            </div>
          </div>
        )}
        {activeTab && (
          <div className="table__search_item">
            <b>Organization type</b>
            <div style={{ minWidth: 200 }}>
              <Select
                name="cohort"
                options={organizationtypes}
                value={selectedOrgType}
                onChange={setSelectedOrgType}
                autosize={true}
                clearable={false}
                className="react-select"
                placeholder={'Select Cohort'}
                classNamePrefix="react-select"
              />
            </div>
          </div>
        )}
        {activeTab !== ORGANIZATION && (
          <div className="table__search_item">
            <b>Organization</b>
            <div style={{ minWidth: 200 }}>
              <Select
                name="organization"
                options={organizationOptions}
                value={selectedOrgOpt}
                onChange={setSelectedOrgOpt}
                autosize={true}
                clearable={false}
                className="react-select"
                placeholder={'Select organization'}
                classNamePrefix="react-select"
              />
            </div>
          </div>
        )}
        <div className="table__search_item mt-3">
          <Button color="primary" onClick={handleSearch}>
            Search{' '}
          </Button>
        </div>
        <div className="table__search_item mt-3">
          <Button color="secondary" onClick={handleClear} outline>
            Clear
          </Button>
        </div>
      </div>
      {isSearched && (
        <div className="ml-1">
          <span>
            Found {activeTab === USERS ? totalUser : activeTab === COHORT ? totalCohort : totalOrg}{' '}
            matches
          </span>
        </div>
      )}
    </>
  );
};

export default AssignTableFilter;
