import React, { useState } from 'react';
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';

const getColorForWeight = (weight) => {
  switch (weight) {
    case 0.1:
    case 0.2:
      return 'secondary';
    case 0.3:
    case 0.4:
      return 'primary';
    case 0.5:
    case 0.6:
      return 'success';
    case 0.7:
    case 0.8:
      return 'warning';
    case 0.9:
    case 1:
      return 'danger';
    default:
      return 'success';
  }
};

export function DomainViewTabs({ domain }) {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="tabs tabs--justify tabs--bordered-bottom overflow-hidden">
      <div className="tabs__wrap">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => toggle('1')}
            >
              Subcategories
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => toggle('2')}
            >
              Associated Skills
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            {domain?.subCategories?.map((skill) => (
              <Button
                color={getColorForWeight(+(skill?.weight ?? +Math.random().toFixed(1)))}
                size="sm"
              >
                {skill?.name}
              </Button>
            ))}
          </TabPane>
          <TabPane tabId="2">
            {domain?.subCategories
              ?.reduce((prev, cur) => [...prev, ...cur.skills], [])
              ?.map((skill) => (
                <Button color={getColorForWeight(+Math.random().toFixed(1))} size="sm">
                  {skill?.name}
                </Button>
              ))}
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
}
