import { handleActions } from 'redux-actions';
import {
  setDSCohortList,
  fetchDSCohortList,
  setDSCohortError,
  setDSCohortFilters,
  uncheckAllDSCohorts,
  toggleCheckedDSCohorts,
} from './actions';

const defaultState = {
  loading: false,
  checkedDSCohortIds: [],
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    searchText: '',
    isSortOrderDescending: false,
    programStatusId: null,
    status: null,
  },
  totalRecords: 0,
  data: [],
  options: [],
  statusOptions: [],
  programCount: 0,
  error: null,
};

export default handleActions(
  {
    [fetchDSCohortList](state) {
      return {
        ...state,
        loading: true,
        data: [],
      };
    },
    [setDSCohortList](state, { payload }) {
      return {
        ...state,
        loading: false,
        data: payload.data?.records,
        totalRecords: payload.data?.totalRecords,
        error: null,
      };
    },
    [setDSCohortError](state, { payload }) {
      return {
        ...state,
        data: [],
        loading: false,
        totalPages: defaultState.totalPages,
        error: payload?.message,
      };
    },
    [setDSCohortFilters](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
        checkedDSCohortIds: [],
      };
    },
    [toggleCheckedDSCohorts](state, { payload: cohortId }) {
      const checkedIds = state.checkedDSCohortIds;
      return {
        ...state,
        checkedDSCohortIds: checkedIds.includes(cohortId)
          ? checkedIds.filter((id) => id !== cohortId)
          : [...checkedIds, cohortId],
      };
    },
    [uncheckAllDSCohorts](state) {
      return {
        ...state,
        checkedDSCohortIds: [],
      };
    },
  },
  defaultState,
);
