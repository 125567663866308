import { handleActions } from 'redux-actions';
import {
  setProgramList,
  fetchProgramList,
  setProgramError,
  setProgramFilters,
  setProgramOptionsList,
  toggleCheckedPrograms,
  uncheckAllPrograms,
  setProgramCount,
  setProgramStatusOptionsList,
  setAssessmentOptions,
} from './actions';

const defaultState = {
  loading: false,
  checkedProgramIds: [],
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    searchText: '',
    isSortOrderDescending: false,
    programStatusId: null,
    status: null,
  },
  totalRecords: 0,
  data: [],
  assessmentOptions: [],
  options: [],
  statusOptions: [],
  programCount: 0,
  error: null,
};

export default handleActions(
  {
    [fetchProgramList](state) {
      return {
        ...state,
        loading: true,
        data: [],
      };
    },
    [setProgramList](state, { payload }) {
      return {
        ...state,
        loading: false,
        data: payload.data?.programList.records,
        totalRecords: payload.data?.programList?.totalRecords,
        error: null,
      };
    },
    [setProgramOptionsList](state, { payload }) {
      return {
        ...state,
        options: payload.data,
      };
    },
    [setProgramStatusOptionsList](state, { payload }) {
      return {
        ...state,
        statusOptions: payload.data,
      };
    },
    [setProgramError](state, { payload }) {
      return {
        ...state,
        data: [],
        loading: false,
        totalPages: defaultState.totalPages,
        error: payload?.message,
      };
    },
    [setAssessmentOptions](state, { payload }) {
      return {
        ...state,
        assessmentOptions: payload?.data,
      };
    },
    [setProgramFilters](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
        checkedProgramIds: [],
      };
    },
    [toggleCheckedPrograms](state, { payload: programId }) {
      const checkedIds = state.checkedProgramIds;
      return {
        ...state,
        checkedProgramIds: checkedIds.includes(programId)
          ? checkedIds.filter((id) => id !== programId)
          : [...checkedIds, programId],
      };
    },
    [uncheckAllPrograms](state) {
      return {
        ...state,
        checkedProgramIds: [],
      };
    },
    [setProgramCount](state, { payload }) {
      return {
        ...state,
        loading: false,
        programCount: payload,
        error: null,
      };
    },
  },
  defaultState,
);
