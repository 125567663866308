import { LikertViewOptions } from '../options';

export const getDefaultAnswerProps = () => ({
  id: new Date().getTime(),
  answerText: '',
  points: 0,
});

export const getDefaultColumnProp = () => ({
  id: new Date().getTime(),
  text: '',
});

export const getDefaultRowProp = () => ({
  id: new Date().getTime(),
  text: '',
});

export const getDefaultContactFieldProps = () => ({
  id: new Date().getTime(),
  fieldName: '',
  fieldType: { value: '', label: 'Select field type' },
});

export const getDefaultFileUploadTypeProps = () => ({
  fileUpload: {
    image: [], //array of image file (only one image)
    imageBase64: '',
  },
});

export const getDefaultScaleRatingQuestionProps = () => ({
  scalingRatingQuestion: {
    ratingStartFrom: 0,
    ratingEndAt: 0,
  },
});

export const getDefaultMatrixRatingQuestionProps = () => ({
  matrixRatingQuestion: {
    rows: [getDefaultRowProp()],
    columns: [getDefaultColumnProp()],
  },
});

export const getDefaultContactInfoTypeProps = () => ({
  contactInfo: {
    contactFields: [getDefaultContactFieldProps()],
  },
});

export const getDefaultMCQMultiSelectTypeProps = () => ({
  mcqMultiple: {
    allowOther: false,
    view: LikertViewOptions.HORIZONTAL,
    answers: [getDefaultAnswerProps()],
  },
});

export const getDefaultQuestionProps = () => ({
  id: new Date().getTime(),
  questionText: '', //'<p/>'
  questionType: { value: '', label: 'Select question type' },
  skipAllowed: false,
  typeData: {},
});

export const getDefaultPageProps = () => ({
  id: new Date().getTime(),
  title: '<p/>',
  description: '<p/>',
  questions: [getDefaultQuestionProps()],
  footer: '<p/>',
});
