import { sendRequest } from '../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const orderHistoryBaseUrl =
  process.env.REACT_APP_CADS_AI_API + process.env.REACT_APP_BASE_INFO_ENDPOINT;

export const orderHistoryEndpoints = {
  GET_ALL_ORDER_HISTORY: '/',
  GET_ORDER_HISTORY: '/',
};

const getOrderHistoryList = (filter) => {
  return getMockOrderHistoryList();
  return sendRequest({
    fullUrl: orderHistoryBaseUrl.GET_ALL_ORDER_HISTORY,
    method: httpMethod.GET,
    params: filter,
  });
};

const getOrderHistoryById = (id) => {
  return getMockOrderHistory();
  return sendRequest({
    fullUrl: orderHistoryBaseUrl.GET_ORDER_HISTORY,
    method: httpMethod.GET,
    params: id,
  });
};

const getMockOrderHistoryList = () => {
    return new Promise((resolve, reject) => {
      setTimeout(
        () =>
          resolve({
            isSuccess: true,
            data: {
              status: 200,
              messages: [],
              data: {
                totalRecords: 3,
                records: [
                  {
                    paymentId: 1,
                    orderId: '#32123321',
                    amount: '500',
                    purchaseDate: '2022-05-01T00:00:00',
                    status: 'paid'
                  },
                  {
                    paymentId: 2,
                    orderId: '#32123352',
                    amount: '500',
                    purchaseDate: '2022-05-21T00:00:00',
                    status: 'paid'
                  },
                  {
                    paymentId: 3,
                    orderId: '#32123377',
                    amount: '500',
                    purchaseDate: '2022-07-11T00:00:00',
                    status: 'paid'
                  },,
                ],
              },
            },
          }),
        500,
      );
    });
  };

  const getMockOrderHistory = () => {
    return new Promise((resolve, reject) => {
      setTimeout(
        () =>
          resolve({
            isSuccess: true,
            data: {
              status: 200,
              messages: [],
              data: {
              paymentId: 1,
              orderId: '#32123321',
              amount: '500',
              purchaseDate: '2022-05-01T00:00:00',
              status: 'Paid',
              email: 'mock1@gmail.com',
              orders: [
                {
                  id: 1,
                  itemName: 'Mock Item 1',
                  amount: 540,
                },
                {
                  id: 2,
                  itemName: 'Mock Item 2',
                  amount: 1570,
                },
                {
                  id: 3,
                  itemName: 'Mock Item 3',
                  amount: 500,
                },
            ]
              },
            },
          }),
        500,
      );
    });
  };

  export default {
      getOrderHistoryList,
      getOrderHistoryById,
  }