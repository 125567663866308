import { handleActions } from 'redux-actions';
import {
  setCadsAiModuleFetching,
  setCadsAiModuleError,
  setCadsAiModuleFilters,
  setCadsAiModuleList,
  clearCadsAiModuleFilters,
  updateCadsAiModuleInList,
  removeModuleFromList,
  toggleCheckedCadsAiModule,
  uncheckAllModule,
  setModuleOptions,
} from './actions';

const defaultState = {
  loading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    searchText: '',
    isSortOrderDescending: false,
  },
  modules: [],
  moduleOptions: [],
  checkedModuleIds: [],
  totalRecords: 0,
  error: null,
};

export default handleActions(
  {
    [setCadsAiModuleFetching](state) {
      return {
        ...state,
        modules: [],
        totalRecords: 0,
        loading: true,
        error: null,
      };
    },
    [setCadsAiModuleError](state, { payload }) {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    },
    [setCadsAiModuleList](state, { payload }) {
      const moduleList = payload.data;
      return {
        ...state,
        modules: moduleList?.records,
        totalRecords: moduleList?.totalRecords,
        loading: false,
        error: null,
      };
    },
    [setCadsAiModuleFilters](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
        checkedModuleIds: [],
      };
    },
    [clearCadsAiModuleFilters](state) {
      return {
        ...state,
        filters: { ...defaultState.filters },
      };
    },
    [setModuleOptions](state, { payload }) {
      return {
        ...state,
        moduleOptions: payload.data,
      };
    },
    [updateCadsAiModuleInList](state, { payload }) {
      return {
        ...state,
        modules: state.modules?.map((md) => (md.id === payload.id ? { ...md, ...payload } : md)),
      };
    },
    [toggleCheckedCadsAiModule](state, { payload: itemId }) {
      const checkedIds = state.checkedModuleIds;
      return {
        ...state,
        checkedModuleIds: checkedIds.includes(itemId)
          ? checkedIds.filter((id) => id !== itemId)
          : [...checkedIds, itemId],
      };
    },
    [uncheckAllModule](state) {
      return {
        ...state,
        checkedModuleIds: [],
      };
    },
    [removeModuleFromList](state, { payload }) {
      return {
        ...state,
        modules: state.modules?.filter((md) => md.id !== payload),
      };
    },
  },
  defaultState,
);
