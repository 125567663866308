import { handleActions } from 'redux-actions';
import {
  setModuleFetching,
  setAssignModuleList,
  setModuleError,
  setModuleFilters,
  toggleCheckedModule,
  clearCheckedModule,
  clearModuleFilters,
  updateModuleInList,
} from './actions';

const defaultState = {
  loading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    search: '',
    isSortOrderDescending: false,
  },
  totalRecords: 0,
  assignModules: [],
  error: null,
  checkedModule: { id: -1, name: 'Select Module' }, //setting to -1 if no module is selected
};

export default handleActions(
  {
    [setModuleFetching](state) {
      return {
        ...state,
        assignModules: [],
        totalRecords: 0,
        loading: true,
        error: null,
      };
    },
    [setModuleError](state, { payload }) {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    },
    [setAssignModuleList](state, { payload }) {
      const assignModuleList = payload.data;
      return {
        ...state,
        assignModules: assignModuleList?.records,
        totalRecords: assignModuleList?.totalRecords,
        loading: false,
        error: null,
      };
    },
    [setModuleFilters](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
      };
    },
    [toggleCheckedModule](state, { payload }) {
      return {
        ...state,
        checkedModule: payload ?? defaultState.checkedModule,
      };
    },
    [clearCheckedModule](state) {
      return {
        ...state,
        checkedModule: defaultState.checkedModule,
      };
    },
    [clearModuleFilters](state) {
      return {
        ...state,
        filters: defaultState.filters,
      };
    },
    [updateModuleInList](state, { payload }) {
      return {
        ...state,
        assignModules: state.assignModules.map((m) =>
          m.id === payload.id ? { ...m, ...payload } : m,
        ),
      };
    },
  },
  defaultState,
);
