import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { cadsAiEmailBaseUrl } from '../index';
import { EmailUIProvider } from './EmailUIProvider';
import { setEmailFilters, uncheckAllEmail } from '../redux/actions';
import { useDispatch } from 'react-redux';
import { EmailCard } from './EmailCard';
import EmailViewDialog from './email-view-dialog/EmailViewDialog';
import EmailEditDialog from './email-add-edit-dialog/EmailEditDialog';

export function EmailPage({ history, match }) {
  const dispatch = useDispatch();

  //clean up
  React.useEffect(() => () => dispatch(uncheckAllEmail()), []);

  const emailsUIEvents = {
    openViewEmailDialog: (id) => {
      history.push(`${cadsAiEmailBaseUrl}/${id}/view`);
    },
    openNewEmailDialog: () => {
      history.push(`${cadsAiEmailBaseUrl}/new`);
    },
  };

  return (
    <EmailUIProvider emailsUIEvents={emailsUIEvents}>
      <Switch>
        <Route path={`${cadsAiEmailBaseUrl}/new`}>
          {({ history, match }) => (
            <EmailEditDialog
              show={match != null}
              isEdit={false}
              onHide={() => {
                dispatch(setEmailFilters());
                history.push(cadsAiEmailBaseUrl);
              }}
            />
          )}
        </Route>
        <Route exact path={`${cadsAiEmailBaseUrl}/:id/view`}>
          {({ history, match }) => (
            <EmailViewDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                history.push(cadsAiEmailBaseUrl);
              }}
            />
          )}
        </Route>
      </Switch>
      <EmailCard />
    </EmailUIProvider>
  );
}
