import React, { createContext, useContext } from 'react';

const TrainingProviderUIContext = createContext();

export function useTrainingProviderUIContext() {
  return useContext(TrainingProviderUIContext);
}

export function TrainingProviderUIProvider({ isForAssignment, children }) {
  const value = {
    isForAssignment,
  };

  return (
    <TrainingProviderUIContext.Provider value={value}>
      {children}
    </TrainingProviderUIContext.Provider>
  );
}
