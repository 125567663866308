import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import DomainManageSubcategoryDialogHeader from './DomainManageSubcategoryDialogHeader';
import DomainManageSubcategoryEditForm from './DomainManageSubcategoryEditForm';

const DomainManageSubcategoryDialog = ({ show, onHide, id, rtl }) => {
  const [modal, setModal] = useState({
    isOpen: show,
    title: 'Manage Subcategories',
    hide: onHide,
  });

  return (
    <Modal
      isOpen={show}
      toggle={modal.hide}
      modalClassName={`${rtl.direction}-support`}
      className="modal-dialog--primary modal-dialog--header modal-edit-dialog"
    >
      <DomainManageSubcategoryDialogHeader modal={modal} />
      <DomainManageSubcategoryEditForm modal={modal} id={id} />
    </Modal>
  );
};

export default connect((state) => ({
  rtl: state.rtl,
}))(DomainManageSubcategoryDialog);
