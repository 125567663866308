import React from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col } from "reactstrap";
import api from "@/utils/apiClient";
import LoadingIcon from "mdi-react/LoadingIcon";
import PinIcon from "mdi-react/PinIcon";
import PinnedQuestionsTable from "./PinnedQuestionsTable";
import { Route, Switch } from "react-router-dom";
import { paths } from "@/shared/helpers/paths";
import PinnedQuestionEditDialog from "./add-edit-pinned-questions/PinnedQuestionEditDialog";
import "./index.scss";
import UnpinQuestionDialog from "@/containers/Cads-AI/ManageHome/Q&A/components/pinned-questions/unpin-question/UnpinQuestionDialog";

const questionsBaseUrl = paths.CADS_AI.HOME.QA;

const PinnedQuestions = ({onBack}) => {

  const [state, setState] = React.useState({
    loading: false,
    error: '',
    pinnedQuestions: []
  });

  const getPinnedQuestions = async () => {
    try {
      setState({...state, loading: true, error: ''});
      const {data: res, isSuccess} = await api.cadsAIQA.getPinnedQuestions();
      if (isSuccess) {
        setState({...state, loading: false, pinnedQuestions: res.data.records, error: ''});
      } else {
        throw new Error(res?.messages?.[0] ?? 'Error while getting pinned questions');
      }
    } catch ({message}) {
      setState({...state, loading: false, pinnedQuestions: [], error: message});
      console.error(message);
    }
  }

  // get pinned questions
  React.useEffect(() => {
    getPinnedQuestions();
  }, []);

  const {loading, error, pinnedQuestions} = state;

  return (
      <Col md={12} lg={12} className="assessment_config">
        <Switch>
          <Route path={`${questionsBaseUrl}/pins/new`}>
            {({history, match}) => (
                <PinnedQuestionEditDialog
                    show={match != null}
                    isEdit={false}
                    onHide={() => {
                      getPinnedQuestions();
                      history.push(`${questionsBaseUrl}/pins`);
                    }}
                />
            )}
          </Route>
          <Route path={`${questionsBaseUrl}/pins/:id/update`}>
            {({history, match}) => (
                <PinnedQuestionEditDialog
                    show={match != null}
                    isEdit={true}
                    id={match?.params?.id}
                    onHide={() => {
                      getPinnedQuestions();
                      history.push(`${questionsBaseUrl}/pins`);
                    }}
                />
            )}
          </Route>
          <Route path={`${questionsBaseUrl}/pins/:id/unpin`}>
            {({history, match}) => (
                <UnpinQuestionDialog
                    show={match != null}
                    id={match?.params?.id}
                    onHide={() => {
                      getPinnedQuestions();
                      history.push(`${questionsBaseUrl}/pins`);
                    }}
                />
            )}
          </Route>
        </Switch>
        <Card>
          <CardBody className="products-list">
            <div className="card__title">
              <h5 className="bold-text"><PinIcon color="#70bbfd"/>Pinned Questions</h5>
              <ButtonToolbar className="products-list__btn-toolbar-top">
                <Button color="primary" outline onClick={onBack}>
                  Back To Question List
                </Button>
              </ButtonToolbar>
            </div>
            {(loading || !!error) && (
                <>
                  {loading && (
                      <div className="panel__refresh position-relative" style={{height: 75}}>
                        <LoadingIcon/>
                      </div>
                  )}
                  <div className="text-center">
                    {error && (
                        <div>
                          <span className="lnr lnr-cross-circle modal__title-icon error"/>
                        </div>
                    )}
                    {loading ? 'Getting pinned questions...' : error}
                  </div>
                </>
            )}
            {(!loading && error === '') && <PinnedQuestionsTable pinnedQuestions={pinnedQuestions}/>}
          </CardBody>
        </Card>
      </Col>
  );
};

export default PinnedQuestions;
