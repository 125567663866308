import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { QuestionUIProvider } from './QuestionUIProvider';
import { QuestionCard } from './QuestionCard';
import QuestionEditDialog from './question-add-edit-dialog/QuestionEditDialog';
import QuestionDeleteDialog from './question-delete-dialog/QuestionDeleteDialog';
import QuestionViewDialog from './question-view-dialog/QuestionViewDialog';
import { setQuestionFilters } from '../redux/actions';
import { useDispatch } from 'react-redux';
import { paths } from '@/shared/helpers/paths';

const questionsBaseUrl = paths.SKILL_BANK.QUESTIONS;

export function QuestionPage() {
  const history = useHistory();

  const questionUIEvents = {
    openViewQuestionDialog: (id) => {
      history.push(`${questionsBaseUrl}/${id}/view`);
    },
    openNewQuestionDialog: () => {
      history.push(`${questionsBaseUrl}/new`);
    },
    openEditQuestionDialog: (id) => {
      history.push(`${questionsBaseUrl}/${id}/edit`);
    },
    openDeleteQuestionDialog: (id) => {
      history.push(`${questionsBaseUrl}/${id}/delete`);
    },
  };

  const dispatch = useDispatch();

  return (
    <QuestionUIProvider questionUIEvents={questionUIEvents}>
      <Switch>
        <Route path={`${questionsBaseUrl}/new`}>
          {({ history, match }) => (
            <QuestionEditDialog
              show={match != null}
              isEdit={false}
              onHide={() => {
                dispatch(setQuestionFilters());
                history.push(questionsBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${questionsBaseUrl}/:id/edit`}>
          {({ history, match }) => (
            <QuestionEditDialog
              show={match != null}
              isEdit={true}
              id={match?.params?.id}
              onHide={() => {
                dispatch(setQuestionFilters());
                history.push(questionsBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${questionsBaseUrl}/:id/view`}>
          {({ history, match }) => (
            <QuestionViewDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                history.push(questionsBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${questionsBaseUrl}/:id/delete`}>
          {({ history, match }) => (
            <QuestionDeleteDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                history.push(questionsBaseUrl);
              }}
            />
          )}
        </Route>
      </Switch>
      <QuestionCard />
    </QuestionUIProvider>
  );
}
