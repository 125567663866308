import { useEffect } from 'react';
import Highcharts from 'highcharts';
import Tree from 'highcharts/modules/treemap';
import exporting from 'highcharts/modules/exporting';
import data from 'highcharts/modules/data';
import Heatmap from 'highcharts/modules/heatmap';
import { Col } from 'reactstrap';

Tree(Highcharts);
data(Highcharts);
Heatmap(Highcharts);
exporting(Highcharts);

const AssessmentUserStats = () => {
  const initialChart = () => {
    Highcharts.chart('AssessmentUserStats', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
      },
      title: {
        text: '<p style="color:#f2a638">80%</p><br>Score<br><br>Terry Shaw<br>',
        style: {
          fontWeight: 'bold',
          color: 'black',
          fontSize: '25',
        },
        align: 'center',
        verticalAlign: 'middle',
        y: 60,
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      legend: {
        enabled: true,
      },
      credits: {
        enabled: false,
      },
      colors: ['#f2a638', '#bcc8d3'],
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            distance: -80,
          },
          startAngle: 180,
          endAngle: 90,
          center: ['50%', '60%'],
          size: '80%',
          thickness: 10,
        },
      },
      series: [
        {
          type: 'pie',
          name: 'Score',
          innerSize: '50%',
          data: [
            ['', 80],
            {
              name: 'Other',
              y: 20,
              dataLabels: {
                enabled: false,
              },
            },
          ],
        },
      ],
    });
  };

  useEffect(() => {
    initialChart();
  }, []);

  return (
    <Col className="p-0 d-inline-block" lg={5} style={{ borderLeft: '1px solid #8080803d' }}>
      <div id="AssessmentUserStats" />
    </Col>
  );
};

export default AssessmentUserStats;
