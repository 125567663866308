import React, { useState } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import { connect } from 'react-redux';
import { UserProps } from '@/shared/prop-types/ReducerProps';
import TopbarMenuLink from './TopbarMenuLink';
import { logout } from '@/containers/App/Account/Auth/redux/actions';
import { removeUserData } from '@/shared/helpers';
import { paths } from '@/shared/helpers/paths';

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

const TopbarProfile = ({ user, logout }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const toggleProfile = () => {
    setIsCollapsed(!isCollapsed);
  };

  const logoutUser = () => {
    removeUserData();
    logout();
  };

  return (
    <div className="topbar__profile">
      <button className="topbar__avatar" type="button" onClick={toggleProfile}>
        <img className="topbar__avatar-img" src={(user && user.avatar) || Ava} alt="avatar" />
        <p className="topbar__avatar-name">{user && user.fullName}</p>
        <DownIcon className="topbar__icon" />
      </button>
      {isCollapsed && (
        <button
          className="topbar__back"
          type="button"
          aria-label="profile button"
          onClick={toggleProfile}
        />
      )}
      <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">
        <div className="topbar__menu">
          <TopbarMenuLink
            title="Change Password"
            icon="cog"
            path={paths.CHANGE_PASSWORD}
            onClick={toggleProfile}
            user={user}
          />
          <TopbarMenuLink
            title="Log Out"
            icon="exit"
            onClick={logoutUser}
            path={paths.LOGIN}
            user={user}
          />
        </div>
      </Collapse>
    </div>
  );
};

TopbarProfile.propTypes = {
  user: UserProps.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user.data,
});

const mapDispatchToProps = {
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopbarProfile);
