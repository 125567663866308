import { createAction } from 'redux-actions';
import { uncheckAllItem as uncheckAllItemAssign } from '@/containers/Control-Panel/Assign/redux/actions';
import { uncheckAllItem as uncheckAllItemAssigned } from '@/containers/Control-Panel/Assigned/redux/actions';

export const setAssignProduct = createAction('CADS_AI_SET_CP_ASSIGN_PRODUCT');
export const setAssignOrganizationId = createAction('CADS_AI_SET_ASSIGN_ORG_ID');
export const setAssignCohortId = createAction('CADS_AI_SET_ASSIGN_COHORT_ID');
export const setAssignOrgTypeId = createAction('CADS_AI_SET_ASSIGN_ORG_TYPE_ID');
export const setAssignTenantId = createAction('CADS_AI_SET_ASSIGN_TENANT_ID');

export const setSelectedAssignProduct = (product) => (dispatch) => {
  dispatch(setAssignProduct(product));
  dispatch(uncheckAllItemAssign());
  dispatch(uncheckAllItemAssigned());
};

export const setSelectedOrganizationId = (orgId) => (dispatch) => {
  dispatch(setAssignOrganizationId(orgId));
  dispatch(uncheckAllItemAssign());
  dispatch(uncheckAllItemAssigned());
};

export const setSelectedOrganizationTypeId = (orgId) => (dispatch) => {
  dispatch(setAssignOrgTypeId(orgId));
  dispatch(uncheckAllItemAssign());
  dispatch(uncheckAllItemAssigned());
};

export const setSelectedCohortId = (orgId) => (dispatch) => {
  dispatch(setAssignCohortId(orgId));
  dispatch(uncheckAllItemAssign());
  dispatch(uncheckAllItemAssigned());
};
