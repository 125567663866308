import { sendRequest } from '../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const dashboardEndpoints = {
  GET_APPLICANT_COUNT: '/',
  GET_PROGRAM_COUNT: '/',
  GET_PROGRAM_APPLICANT_STAGE_COUNT: '/',
  GET_ACCEPTED_APPLICANT_COUNT: '/',
  GET_TOTAL_ASSESSMENT_COMPLETED: '/',
  GET_PROGRAM_DAILY_GRAPH: '/',
  GET_EVALUATION_OPTIONS: '/',
  GET_ASSESSMENT_OPTIONS: '/',
  GET_ALL_PROGRAMS_DAILY_GRAPH: '/',
  GET_ALL_TALENT_DAILY_GRAPHS: '/',
  GET_ALL_ASSESSMENTS_DAILY_GRAPH: '/',
  GET_ALL_EVALUATION_DAILY_GRAPH: '/',
};

const getApplicantCount = () => {
  return getMockApplicantCount();
  return sendRequest({
    url: dashboardEndpoints.GET_APPLICANT_COUNT,
    method: httpMethod.GET,
  });
};

const getProgramCount = () => {
  return getMockProgramCount();
  return sendRequest({
    url: dashboardEndpoints.GET_PROGRAM_COUNT,
    method: httpMethod.GET,
  });
};

const getProgramApplicantStageCount = () => {
  return getMockProgramApplicantStageCount();
  return sendRequest({
    url: dashboardEndpoints.GET_PROGRAM_APPLICANT_STAGE_COUNT,
    method: httpMethod.GET,
  });
};

const getTotalAssessmentCount = () => {
  return getMockTotalAssessmentCount();
  return sendRequest({
    url: dashboardEndpoints.GET_TOTAL_ASSESSMENT_COMPLETED,
    method: httpMethod.GET,
  });
};

const getAcceptedApplicantCount = () => {
  return getMockAcceptedApplicantCount();
  return sendRequest({
    url: dashboardEndpoints.GET_ACCEPTED_APPLICANT_COUNT,
    method: httpMethod.GET,
  });
};

const getProgramDailyCountGraph = (filter) => {
  return getMockProgramDailyCountGraph();
  return sendRequest({
    url: dashboardEndpoints.GET_PROGRAM_DAILY_GRAPH,
    method: httpMethod.GET,
    params: filter,
  });
};

const getAllProgramDailyCountGraph = (filter) => {
  return getAllMockProgramDailyCountGraph();
  return sendRequest({
    url: dashboardEndpoints.GET_ALL_PROGRAMS_DAILY_GRAPH,
    method: httpMethod.GET,
    params: filter,
  });
};

const getAllTalentDailyCountGraph = (filter) => {
  return getAllMockTalentDailyCountGraph();
  return sendRequest({
    url: dashboardEndpoints.GET_ALL_TALENT_DAILY_GRAPHS,
    method: httpMethod.GET,
    params: filter,
  });
};

const getAllAssessmentDailyCountGraph = (filter) => {
  return getAllMockAssessmentDailyCountGraph();
  return sendRequest({
    url: dashboardEndpoints.GET_ALL_ASSESSMENTS_DAILY_GRAPH,
    method: httpMethod.GET,
    params: filter,
  });
};

const getAllEvaluationDailyCountGraph = (filter) => {
  return getAllMockEvaluationDailyCountGraph();
  return sendRequest({
    url: dashboardEndpoints.GET_ALL_ASSESSMENTS_DAILY_GRAPH,
    method: httpMethod.GET,
    params: filter,
  });
};

const getEvaluationOptions = (filter) => {
  return getMockEvaluationOptions();
  return sendRequest({
    url: dashboardEndpoints.GET_EVALUATION_OPTIONS,
    method: httpMethod.GET,
    params: filter,
  });
};

const getAssessmentOptions = () => {
  return getMockAssessmentOptions();
  return sendRequest({
    url: dashboardEndpoints.GET_ASSESSMENT_OPTIONS,
    method: httpMethod.GET,
    params: filter,
  });
};

const getMockApplicantCount = (data) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          data: { count: 5 },
          status: 200,
          messages: [],
        },
      });
    }, 500);
  });
};

const getMockProgramCount = (data) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          data: { count: 3 },
          status: 200,
          messages: [],
        },
      });
    }, 500);
  });
};

const getMockProgramApplicantStageCount = (data) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          data: 7,
          status: 200,
          messages: [],
        },
      });
    }, 500);
  });
};

const getMockAcceptedApplicantCount = (data) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          data: { count: 9 },
          status: 200,
          messages: [],
        },
      });
    }, 500);
  });
};

const getMockTotalAssessmentCount = (data) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          data: { totalCount: 12, smartSkillCount: 5, datastarPreAssessmentCount: 7 },
          status: 200,
          messages: [],
        },
      });
    }, 500);
  });
};

const getMockProgramDailyCountGraph = (filter) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          data: [
            { date: '2022-04-29T00:00:00', noOfApplicants: 1 },
            { date: '2022-04-22T00:00:00', noOfApplicants: 1 },
            { date: '2022-04-20T00:00:00', noOfApplicants: 1 },
            { date: '2022-04-18T00:00:00', noOfApplicants: 2 },
            { date: '2022-03-29T00:00:00', noOfApplicants: 1 },
            { date: '2022-03-22T00:00:00', noOfApplicants: 1 },
            { date: '2022-03-16T00:00:00', noOfApplicants: 2 },
          ],
          status: 200,
          messages: [],
        },
      });
    }, 500);
  });
};

const getAllMockProgramDailyCountGraph = (filter) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          data: [
            { date: '2022-04-29T00:00:00', noOfApplicants: 4 },
            { date: '2022-04-22T00:00:00', noOfApplicants: 2 },
            { date: '2022-04-20T00:00:00', noOfApplicants: 1 },
            { date: '2022-04-18T00:00:00', noOfApplicants: 1 },
            { date: '2022-03-29T00:00:00', noOfApplicants: 1 },
          ],
          status: 200,
          messages: [],
        },
      });
    }, 500);
  });
};

const getAllMockTalentDailyCountGraph = (filter) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          data: [
            { date: '2022-04-29T00:00:00', noOfApplicants: 1 },
            { date: '2022-06-22T00:00:00', noOfApplicants: 2 },
            { date: '2022-04-20T00:00:00', noOfApplicants: 3 },
            { date: '2022-04-18T00:00:00', noOfApplicants: 4 },
            { date: '2022-03-29T00:00:00', noOfApplicants: 5 },
          ],
          status: 200,
          messages: [],
        },
      });
    }, 500);
  });
};

const getAllMockAssessmentDailyCountGraph = (filter) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          data: [
            { date: '2022-04-29T00:00:00', noOfApplicants: 7 },
            { date: '2022-04-22T00:00:00', noOfApplicants: 5 },
            { date: '2022-04-20T00:00:00', noOfApplicants: 3 },
            { date: '2022-04-18T00:00:00', noOfApplicants: 1 },
            { date: '2022-03-29T00:00:00', noOfApplicants: 1 },
          ],
          status: 200,
          messages: [],
        },
      });
    }, 500);
  });
};

const getAllMockEvaluationDailyCountGraph = (filter) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          data: [
            { date: '2022-04-29T00:00:00', noOfApplicants: 5 },
            { date: '2022-04-22T00:00:00', noOfApplicants: 4 },
            { date: '2022-04-20T00:00:00', noOfApplicants: 2 },
            { date: '2022-04-18T00:00:00', noOfApplicants: 1 },
            { date: '2022-03-29T00:00:00', noOfApplicants: 2 },
          ],
          status: 200,
          messages: [],
        },
      });
    }, 500);
  });
};

const getMockEvaluationOptions = (filter) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          data: [
            { id: 1, title: 'evaluation' },
            { id: 2, title: 'interview' },
            { id: 3, title: 'HR approval' },
            { id: 4, title: 'sponsor selection' },
          ],
          status: 200,
          messages: [],
        },
      });
    }, 500);
  });
};

const getMockAssessmentOptions = (filter) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          data: [
            { id: 1, title: 'All' },
            { id: 2, title: 'Smart Skills' },
            { id: 3, title: 'Pre-Assessment' },
          ],
          status: 200,
          messages: [],
        },
      });
    }, 500);
  });
};

export default {
  getApplicantCount,
  getProgramCount,
  getProgramApplicantStageCount,
  getAcceptedApplicantCount,
  getTotalAssessmentCount,
  getProgramDailyCountGraph,
  getEvaluationOptions,
  getAllProgramDailyCountGraph,
  getAllTalentDailyCountGraph,
  getAllAssessmentDailyCountGraph,
  getAllEvaluationDailyCountGraph,
  getAssessmentOptions,
};
