import React from 'react';

const DdoViewDialogHeader = ({ modal, ddo }) => {
  return (
    <div className="modal__header">
      <button
          className="lnr lnr-cross modal__close-btn"
          aria-label="modal__close-btn"
          type="button"
          onClick={modal.hide}
      />
      <h4 className="text-modal  modal__title">{ddo?.ddoProgramTitle ?? modal.title}</h4>
    </div>
  );
};

export default DdoViewDialogHeader;
