import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Row } from 'reactstrap';
import StepOne from './steps/StepOne';
import StepTwo from './steps/StepTwo';
import StepThree from './steps/StepThree';

export function getStepFields(page) {
  switch (page) {
    case 1:
      return [
        'title',
        'durationInMinutes',
        'description',
        'isPlatformWide',
        'summary',
        'isConfirmed',
        'isProvidedByCads',
        'isVerified',
      ];
    case 2:
      return [
        'imageUrl',
        'courseLink',
        'salePrice',
        'isMeeting',
        'startDate',
        'endDate',
        'location',
        'contentType',
        'deliveryMethod',
        'trainingProvider',
        'forSale',
      ];
    case 3:
      return ['masterMaterialsLink', 'participantsMaterialLink'];
    default:
      return [];
  }
}

export function extractFieldData(page, data) {
  const obj = {};
  getStepFields(page).forEach((key) => (obj[key] = data[key]));
  return obj;
}

const CourseWizardForm = ({ onSubmit, data, setData, initialValues, id, state }) => {
  const [page, setPage] = useState(1);

  const nextPage = (newData) => {
    setData({ ...data, ...newData });
    setPage(page + 1);
  };

  const previousPage = (newData) => {
    setData({ ...data, ...newData });
    setPage(page - 1);
  };

  const clearForm = () => {
    setData(initialValues);
    setPage(1);
  };

  const submitHandler = (newData) => {
    const dataToSubmit = { ...data, ...newData };
    setData(dataToSubmit);
    onSubmit(dataToSubmit, clearForm);
  };

  return (
    <Row>
      <Col md={12} lg={12}>
        <Card className="pb-0">
          <CardBody className="wizard">
            <div className="wizard__steps">
              <div className={`wizard__step${page === 1 ? ' wizard__step--active' : ''}`}>
                <p>Step 1</p>
              </div>
              <div className={`wizard__step${page === 2 ? ' wizard__step--active' : ''}`}>
                <p>Step 2</p>
              </div>
              <div className={`wizard__step${page === 3 ? ' wizard__step--active' : ''}`}>
                <p>Step 3</p>
              </div>
            </div>
            <div className="wizard__form-wrapper">
              {page === 1 && <StepOne page={page} onSubmit={nextPage} data={data} id={id} />}
              {page === 2 && (
                <StepTwo
                  page={page}
                  id={id}
                  previousPage={previousPage}
                  onSubmit={nextPage}
                  data={data}
                  onClear={clearForm}
                />
              )}
              {page === 3 && (
                <StepThree
                  id={id}
                  previousPage={previousPage}
                  onSubmit={submitHandler}
                  data={data}
                  state={state}
                />
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

CourseWizardForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default CourseWizardForm;
