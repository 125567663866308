import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { cadsAiHelpBaseUrl } from '../index';
import { HelpUIProvider } from './HelpUIProvider';
import { HelpCard } from './HelpCard';
import { setHelpFilters, uncheckAllHelp } from '../redux/actions';
import { useDispatch } from 'react-redux';
import HelpUpdateStatusDialog from './help-update-status-dialog/HelpUpdateStatusDialog';
import HelpViewDialog from './help-view-dialog/HelpViewDialog';

export function HelpPage({ history }) {
  const dispatch = useDispatch();

  //clean up
  React.useEffect(() => () => dispatch(uncheckAllHelp()), []);

  const helpUIEvents = {
    openViewHelpDialog: (id) => {
      history.push(`${cadsAiHelpBaseUrl}/${id}/view`);
    },
    openDeactivateHelpDialog: () => {
      history.push(`${cadsAiHelpBaseUrl}/update-status`);
    },
  };

  return (
    <HelpUIProvider helpUIEvents={helpUIEvents}>
      <Switch>
        <Route path={`${cadsAiHelpBaseUrl}/:id/view`}>
          {({ history, match }) => (
            <HelpViewDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                dispatch(setHelpFilters());
                history.push(cadsAiHelpBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${cadsAiHelpBaseUrl}/update-status`}>
          {({ history, match }) => (
            <HelpUpdateStatusDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                dispatch(setHelpFilters());
                history.push(cadsAiHelpBaseUrl);
              }}
            />
          )}
        </Route>
      </Switch>
      <HelpCard />
    </HelpUIProvider>
  );
}
