import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import ArticlesTable from './ArticlesTable';
import { useArticlesUIContext } from '../ArticlesUIProvider';

const ArticlesTableWrapper = () => {
  const articlesUIContext = useArticlesUIContext();

  const articleFilter = useSelector((state) => state.cadsAIArticles.filters, shallowEqual);

  const checkedArticleIds = useSelector(
    (state) => state.cadsAIArticles.checkedArticleIds,
    shallowEqual,
  );

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="products-list">
          <div className="card__title">
            <h5 className="bold-text">Data Article List</h5>
            <ButtonToolbar className="products-list__btn-toolbar-top">
              <Button color="primary" onClick={articlesUIContext.openNewArticlesDialog}>
                Add new
              </Button>
            </ButtonToolbar>
          </div>
          <ArticlesTable />
        </CardBody>
      </Card>
    </Col>
  );
};

export default ArticlesTableWrapper;
