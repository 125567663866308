import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingIcon from 'mdi-react/LoadingIcon';
import api from '@/utils/apiClient';
import { updateCourseInList } from '../../redux/actions';
import { convertImageFileToBase64 } from '@/shared/helpers';
import CourseWizardForm from './course-wizard-form';

const CourseEditForm = ({ isEdit, id }) => {
  const dispatch = useDispatch();

  const initialValues = {
    title: '',
    description: '',
    summary: '',
    imageUrl: '',
    isMeeting: '',
    isPlatformWide: '',
    isVerified: '',
    masterMaterialsLink: '',
    participantsMaterialLink: '',
    courseLink: '',
    durationInMinutes: '',
    startDate: '',
    endDate: '',
    salePrice: '',
    forSale: 'true',
  };
  //states
  const [state, setState] = React.useState({
    loading: false,
    fetchingCourse: false,
    newCourseAdded: false,
    errorMessage: '',
  });
  const [data, setData] = React.useState(initialValues);
  const { filters } = useSelector((state) => state.cadsAICourse);
  const [selectedCourses, setSelectedCourses] = React.useState({});

  const getCadsAiCoursesById = async (id) => {
    setSelectedCourses({ ...selectedCourses });
    const { data: res, isSuccess } = await api.cadsAICourse.getCourseById(id);
    if (isSuccess) {
      setSelectedCourses(res?.data);
    } else {
      throw new Error(res?.messages?.[0] ?? 'Error while getting course details');
    }
  };

  React.useEffect(() => {
    if (isEdit) {
      getCadsAiCoursesById(id);
    }
  }, []);

  React.useEffect(() => {
    if (isEdit && id) {
      setDefaultData(+id);
    }
  }, [isEdit, id, selectedCourses]);

  const setDefaultData = () => {
    setData({
      ...selectedCourses,
      trainingProvider: {
        value: selectedCourses?.trainingProvider?.id,
        label: selectedCourses?.trainingProvider?.title,
      },
      contentType: {
        value: selectedCourses?.contentType?.id,
        label: selectedCourses?.contentType?.title,
      },
      deliveryMethod: {
        value: selectedCourses?.deliveryMethod?.id,
        label: selectedCourses?.deliveryMethod?.title,
      },
      isVerified: {
        value: selectedCourses?.isVerified,
        label: selectedCourses?.isVerified ? 'Yes' : 'No',
      },
      isProvidedByCads: {
        value: selectedCourses?.isProvidedByCads,
        label: selectedCourses?.isProvidedByCads ? 'Yes' : 'No',
      },
      isConfirmed: {
        value: selectedCourses?.isConfirmed,
        label: selectedCourses?.isConfirmed ? 'Yes' : 'No',
      },
    });
  };

  const constructRequest = async ({
    title,
    description,
    summary,
    courseLink,
    startDate,
    endDate,
    salePrice,
    saleFinalPrice,
    imageUrl,
    participantsMaterialLink,
    durationInMinutes,
    saleDiscount,
    materialsUrl,
    trainingProvider,
    contentType,
    deliveryMethod,
    isConfirmed,
    isPlatformWide,
    isProvidedByCads,
    isMeeting,
    isVerified,
    forSale,
  }) => {
    const base64Img = await convertImageFileToBase64(imageUrl?.[0]);
    return {
      title,
      description,
      summary,
      courseLink,
      startDate,
      endDate,
      materialsUrl,
      salePrice: salePrice ? +salePrice : 0,
      saleFinalPrice,
      saleDiscount,
      participantsMaterialLink,
      durationInMinutes,
      isMainVariant: true,
      isForSale: false,
      isConfirmed: isConfirmed?.value,
      isMeeting: isMeeting?.value,
      imageUrl: base64Img?.split(',')?.[1],
      isPlatformWide: isPlatformWide?.value,
      moduleId: filters?.moduleId,
      isVerified: isVerified?.value,
      isProvidedByCads: isProvidedByCads?.value,
      trainingProviderId: trainingProvider?.value,
      contentTypeId: contentType?.value,
      deliveryMethodId: deliveryMethod?.value,
      isForSale: forSale === 'true'
    };
  };

  const onSubmit = async (moduleData, resetForm) => {
    setState({ ...state, loading: true });
    const reqData = await constructRequest(moduleData);
    const defaultError = `Error occurred while ${isEdit ? 'Updating' : 'Adding new'} course`;
    try {
      const { data, isSuccess } = isEdit
        ? await api.cadsAICourse.cadsAIEditCourse({ id, ...reqData })
        : await api.cadsAICourse.cadsAIAddCourse(reqData);
      if (isSuccess) {
        setState({ ...state, newCourseAdded: true, loading: false });

        if (isEdit)
          dispatch(
            updateCourseInList({
              id,
              ...reqData,
              trainingProvider: (moduleData?.trainingProvider ?? {}).value,
            }),
          );
        else resetForm();
      } else {
        throw new Error(data?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({ ...state, loading: false, newCourseAdded: false, errorMessage: error.message });
    }
  };

  return (
    <div>
      {/*Add/Edit Module Form Status*/}
      <div className="ml-4">
        {state.loading && (
          <div>
            <div className="panel__refresh position-relative" style={{ height: 75, width: 20 }}>
              <LoadingIcon />
              <div style={{ width: 200, padding: '23px 40px' }}>
                {isEdit ? 'Updating' : 'Adding new'} course...
              </div>
            </div>
          </div>
        )}
        {(state.newCourseAdded || !!state.errorMessage) && (
          <table className="mt-3">
            <tr>
              <td>
                {state.newCourseAdded ? (
                  <span className="lnr lnr-thumbs-up modal__title-icon success" />
                ) : (
                  <span className="lnr lnr-cross-circle modal__title-icon error" />
                )}
              </td>
              <td className="pl-2">
                {state.newCourseAdded
                  ? `${isEdit ? 'Course Updated' : 'New Course Added'} Successfully`
                  : state.errorMessage}
              </td>
            </tr>
          </table>
        )}
        <CourseWizardForm
          setData={setData}
          data={data}
          id={id}
          initialValues={initialValues}
          onSubmit={onSubmit}
          state={state}
        />
      </div>
    </div>
  );
};

export default CourseEditForm;
