import React, { useState } from 'react';
import { Col, Card, Row, CardBody } from 'reactstrap';
import StepOne from './steps/StepOne';
import StepTwo from './steps/StepTwo';
import StepThree from './steps/StepThree';
import { useDispatch } from 'react-redux';

const AssignAssessmentWizard = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    // destruction
    return () => {
      // dispatch(clearCheckedCustomers());
      // dispatch(clearCheckedModuleSchedule());
      // dispatch(uncheckAllItem());
    };
  }, []);

  const [page, setPage] = useState(1);
  const [checkedCustomer, setCheckedCustomer] = React.useState({});
  const [checkedAssessment, setCheckedAssessment] = React.useState({});
  const [checkedEmployee, setCheckedEmployee] = React.useState([]);

  const nextPage = () => {
    setPage(page + 1);
  };

  const previousPage = () => {
    setPage(page - 1);
  };

  return (
    <Row>
      <Col md={12} lg={12}>
        <Card className="pb-0">
          <CardBody className="wizard">
            <div className="wizard__steps">
              <div className={`wizard__step${page === 1 ? ' wizard__step--active' : ''}`}>
                <p>Customers</p>
              </div>
              <div className={`wizard__step${page === 2 ? ' wizard__step--active' : ''}`}>
                <p>Assessments</p>
              </div>
              <div className={`wizard__step${page === 3 ? ' wizard__step--active' : ''}`}>
                <p>Employees</p>
              </div>
            </div>
            <div className="">
              {page === 1 && (
                <StepOne
                  nextPage={nextPage}
                  checkedCustomer={checkedCustomer}
                  setCheckedCustomer={setCheckedCustomer}
                />
              )}
              {page === 2 && (
                <StepTwo
                  previousPage={previousPage}
                  nextPage={nextPage}
                  checkedCustomer={checkedCustomer}
                  checkedAssessment={checkedAssessment}
                  setCheckedAssessment={setCheckedAssessment}
                />
              )}
              {page === 3 && (
                <StepThree
                  checkedCustomer={checkedCustomer}
                  checkedAssessment={checkedAssessment}
                  checkedEmployee={checkedEmployee}
                  setCheckedEmployee={setCheckedEmployee}
                  previousPage={previousPage}
                />
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default AssignAssessmentWizard;
