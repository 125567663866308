import React from 'react';
import LearningCard from './LearningCard';

const details = [
  {
    title: 'Total Users',
    count: 6,
  },
  {
    title: 'Learning Hours',
    count: 9,
  },
  {
    title: 'Course Taken',
    count: 6,
  },
];
const LearningDetails = () => {
  return (
    <>
      {details?.map((item) => (
        <LearningCard title={item?.title} count={item?.count} />
      ))}
    </>
  );
};

export default LearningDetails;
