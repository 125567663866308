import React, { createContext, useContext } from 'react';

const CourseUIContext = createContext();

export function useCourseUIContext() {
  return useContext(CourseUIContext);
}

export function CourseUIProvider({ courseUIEvents, isForAssignment, children }) {
  const value = {
    openViewCourseDialog: courseUIEvents.openViewCourseDialog,
    openEditCourseDialog: courseUIEvents.openEditCourseDialog,
    openAddCourseDialog: courseUIEvents.openAddCourseDialog,
    openUpdateCourseDialog: courseUIEvents.openUpdateCourseDialog,
    isForAssignment,
  };

  return <CourseUIContext.Provider value={value}>{children}</CourseUIContext.Provider>;
}
