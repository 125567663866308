import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loading from '@/shared/components/Loading';
import { paths } from '@/shared/helpers/paths';
import AssignWizardPage from './AssignWizardPage';

export const assignmentBaseUrl = paths.ASSIGNMENTS.BASE;

export default function Assignments() {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path={assignmentBaseUrl} component={AssignWizardPage} />
      </Switch>
    </Suspense>
  );
}
