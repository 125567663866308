import React, { useEffect, useState } from 'react';
import LoadingIcon from 'mdi-react/LoadingIcon';
import api from '@/utils/apiClient';
import { Button, ButtonToolbar } from 'reactstrap';
import { convertImageFileToBase64, convertUrlToFile, emailPattern, emptyStringPattern, urlPatternWithProtocol } from '@/shared/helpers';
import FormField from '@/shared/components/form/hook/FormField';
import { useForm } from 'react-hook-form';
import renderDropZoneField from '@/shared/components/form/DropZone';

const EmployeeEditDialogBody = ({ modal, isEdit, id, slug }) => {
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    organizationLevel: '',
    photoUrl: '',
    facebook: '',
    instagram: '',
    linkedin: '',
    youtube: '',
    website: '',
    phone: '',
    mobile: '',
    slug: '',
  };

  const [data, setData] = useState(initialValues);

  const [state, setState] = useState({
    loading: false,
    newEmployeeAdded: false,
    errorMessage: '',
  });

  useEffect(() => {
    if (isEdit && slug) {
      setDefaultData(slug);
    }
  }, [isEdit, slug]);

  const setDefaultData = async (slug) => {
    try {
      const { data: res, isSuccess } = await api.cadsAIEmployee.getEmployeeById(slug);
      const employeeData = res.data;
      const imgFile = await convertUrlToFile(employeeData?.photoUrl);
      if (id) {
        setData({
          ...employeeData,
        });
        ['firstName', 'lastName', 'organizationLevel', 'facebook', 'instagram', 'linkedin', 'youtube', 'website', 'email', 'mobile', 'phone', 'slug'].forEach((field) =>
            setValue(field, employeeData?.[field]),
        );
        setValue('photoUrl', imgFile ? [imgFile] : '');    
        }
    } catch ({ message }) {
      console.error('Employee edit error : ', message);
      setState({
        ...state,
        errorMessage: 'Error while getting existing employee details',
      });
    }
  };

  const constructApiRequestData = async ({
                                           firstName,
                                           lastName,
                                           email,
                                           organizationLevel,
                                           photoUrl,
                                           facebook,
                                           instagram,
                                           linkedin,
                                           youtube,
                                           website,
                                           phone,
                                           mobile,
                                           slug,
                                         }) => {
    const base64Img = await convertImageFileToBase64(photoUrl?.[0]);
    return {
      firstName,
      lastName,
      email,
      organizationLevel,
      photoName: firstName + "-" + new Date().getTime(),
      photo: base64Img?.split(',')?.[1],
      facebook,
      instagram,
      linkedin,
      youtube,
      website,
      phone,
      slug,
      mobile,
      id: id,
    };
  };
  const handleAddEditEmployee = async (employeeData) => {
    setState({ ...state, loading: true });
    const newEmployeeData = await constructApiRequestData(employeeData);
    const defaultError = `Error occurred while ${isEdit ? 'Updating' : 'Adding new'} employee`;
    try {
      const { data: res, isSuccess } = isEdit
        ? await api.cadsAIEmployee.editEmployee(newEmployeeData)
        : await api.cadsAIEmployee.addEmployee(newEmployeeData);
      if (isSuccess) {
        setState({ ...state, newEmployeeAdded: true, loading: false });
        if (!isEdit) setData(initialValues);
      } else {
        throw new Error(data?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({
        ...state,
        loading: false,
        newEmployeeAdded: false,
        errorMessage: error.message,
      });
    }
  };

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: {errors},
  } = useForm({defaultValues: initialValues});

  const [firstName, lastName] = watch(['firstName', 'lastName']);

  React.useEffect(() => {
    const first = firstName ? firstName.toLowerCase() : "";
    const last = lastName ? lastName.toLowerCase() : "";
    setValue('slug', first + '-' + last);
  }, [firstName, lastName])

  return (
      <div>
        {/*Add/Edit Employee Form Status*/}
        <div className="ml-4">
          {state.loading && (
              <div>
                <div className="panel__refresh position-relative" style={{height: 75, width: 20}}>
                  <LoadingIcon/>
                  <div style={{width: 200, padding: '23px 30px'}}>
                {isEdit ? 'Updating' : 'Adding new'} employee...
              </div>
            </div>
          </div>
        )}
        {(state.newEmployeeAdded || !!state.errorMessage) && (
          <>
            <table className="mt-3">
              <tr>
                <td>
                  {state.newEmployeeAdded ? (
                    <span className="lnr lnr-thumbs-up modal__title-icon success" />
                  ) : (
                    <span className="lnr lnr-cross-circle modal__title-icon error" />
                  )}
                </td>
                <td className="pl-2">
                  {state.newEmployeeAdded
                    ? `${isEdit ? 'Employee Updated' : 'New Employee Added'} Successfully`
                    : state.errorMessage}
                </td>
              </tr>
            </table>
          </>
        )}
      </div>
      <div className="modal__body add-edit-learning-path dropzone-border">
        <form className={`form form--horizontal`} onSubmit={handleSubmit(handleAddEditEmployee)}>
          {!state.newEmployeeAdded ? (
            <div className={'w-100 p-3'}>
              <div className="form__form-group">
                <span className="form__form-group-label">First Name</span>
                <div className="form__form-group-field">
                  <FormField
                    name="firstName"
                    control={control}
                    component="input"
                    errors={errors}
                    rules={{
                      required: 'This is required field',
                      pattern: emptyStringPattern,
                    }}
                    defaultValue=""
                    isAboveError={true}
                    placeholder="Enter First Name"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Last Name</span>
                <div className="form__form-group-field">
                  <FormField
                    name="lastName"
                    control={control}
                    component="input"
                    errors={errors}
                    rules={{
                      required: 'This is required field',
                      pattern: emptyStringPattern,
                    }}
                    defaultValue=""
                    isAboveError={true}
                    placeholder="Enter Last Name"
                  />
                </div>
              </div>
              <div className="form__form-group pt-2">
                <span className="form__form-group-label">Photo</span>
                <div className="form__form-group-field">
                  <FormField name="photoUrl" control={control} component={renderDropZoneField} />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Email</span>
                <div className="form__form-group-field">
                  <FormField
                    name="email"
                    control={control}
                    component="input"
                    errors={errors}
                    rules={{
                      required: 'Email is required',
                      pattern: emailPattern,
                    }}
                    defaultValue=""
                    isAboveError={true}
                    placeholder="Enter Email"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Organization Level</span>
                <div className="form__form-group-field">
                  <FormField
                      name="organizationLevel"
                      control={control}
                      component="input"
                      errors={errors}
                      rules={{
                        required: 'This is required field',
                        pattern: emptyStringPattern,
                      }}
                      defaultValue=""
                      isAboveError={true}
                      placeholder="Enter Organization Level"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Slug</span>
                <div className="form__form-group-field">
                  <FormField
                      name="slug"
                      control={control}
                      component="input"
                      errors={errors}
                      rules={{
                        required: 'This is required field',
                        pattern: emptyStringPattern,
                      }}
                      defaultValue=""
                      isAboveError={true}
                      placeholder="Enter Slug"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Website Link</span>
                <div className="form__form-group-field">
                  <FormField
                      name="website"
                      control={control}
                      component="input"
                      errors={errors}
                      rules={{
                        pattern: urlPatternWithProtocol
                      }}
                    defaultValue=""
                    isAboveError={true}
                    placeholder="Enter Website Link"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Facebook Link</span>
                <div className="form__form-group-field">
                  <FormField
                    name="facebook"
                    control={control}
                    component="input"
                    errors={errors}
                    rules={{
                      pattern: urlPatternWithProtocol
                    }}
                    defaultValue=""
                    isAboveError={true}
                    placeholder="Enter Facebook Link"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Instagram Link</span>
                <div className="form__form-group-field">
                  <FormField
                      name="instagram"
                      control={control}
                      component="input"
                      errors={errors}
                      rules={{
                        pattern: urlPatternWithProtocol
                      }}
                      defaultValue=""
                      isAboveError={true}
                      placeholder="Enter Instagram Link"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">LinkedIn Link</span>
                <div className="form__form-group-field">
                  <FormField
                      name="linkedin"
                      control={control}
                      component="input"
                      errors={errors}
                      rules={{
                        pattern: urlPatternWithProtocol
                      }}
                      defaultValue=""
                      isAboveError={true}
                      placeholder="Linked in profile link"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">YouTube Link</span>
                <div className="form__form-group-field">
                  <FormField
                      name="youtube"
                      control={control}
                      component="input"
                      errors={errors}
                      rules={{
                        pattern: urlPatternWithProtocol
                      }}
                    defaultValue=""
                    isAboveError={true}
                    placeholder="Enter Youtube Link"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Mobile</span>
                <div className="form__form-group-field">
                  <FormField
                      name="mobile"
                      control={control}
                      component="input"
                      errors={errors}
                      rules={{
                        required: {value: true, message: 'Mobile number is required'},
                        // pattern: mobileNumberPattern
                      }}
                      defaultValue=""
                      isAboveError={true}
                      placeholder="Enter mobile number"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Phone</span>
                <div className="form__form-group-field">
                  <FormField
                      name="phone"
                      control={control}
                      component="input"
                      /*errors={errors}
                      rules={{
                        required: false,
                        pattern: phoneNumberPattern
                      }}*/
                      defaultValue=""
                      isAboveError={true}
                      placeholder="Enter phone number"
                  />
                </div>
              </div>
              <ButtonToolbar className="modal__footer btn-toolbar">
                <>
                  <Button color="primary" type="submit">
                    Submit
                  </Button>
                  <Button
                      color="secondary"
                      type="button"
                      className="modal_cancel btn btn-secondary"
                      onClick={() => modal.hide()}
                  >
                    Close
                  </Button>
                </>
              </ButtonToolbar>
            </div>
          ) : (
            <div className={'p-3'}>
              <ButtonToolbar className="modal__footer btn-toolbar">
                <Button color="primary" type="button" onClick={() => modal.hide()}>
                  Okay
                </Button>
              </ButtonToolbar>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default EmployeeEditDialogBody;
