import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const fetchSponsorshipList = createAction('CADS_AI_FETCH_SPONSORSHIP_LIST');
export const setSponsorshipList = createAction('CADS_AI_SET_SPONSORSHIP_LIST');
export const setSponsorshipError = createAction('CADS_AI_SET_SPONSORSHIP_ERROR');
export const setSponsorshipFilters = createAction('CADS_AI_SET_SPONSORSHIP_FILTERS');
export const toggleCheckedSponsorship = createAction('CADS_AI_TOGGLE_CHECKED_SPONSORSHIPS');
export const uncheckAllSponsorships = createAction('CADS_AI_UNCHECK_ALL_SPONSORSHIPS');

export const toggleCheckedItemById = (sponsorshipId) => (dispatch) => {
  dispatch(toggleCheckedSponsorship(sponsorshipId));
};

export const getSponsorshipList = (filter) => async (dispatch) => {
  const defaultError = `Error occurred getting sponsorship list`;
  dispatch(fetchSponsorshipList());
  try {
    const { data, isSuccess } = await api.cadsAISponsorship.getSponsorshipList(filter);
    if (isSuccess) dispatch(setSponsorshipList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setSponsorshipError(error.message));
    console.error(defaultError, error.message);
  }
};
