import React, { createContext, useContext } from 'react';

const CustomerUIContext = createContext();

export function useCustomerUIContext() {
  return useContext(CustomerUIContext);
}

export function CustomerUIProvider({ customerUIEvents, children }) {
  const value = {
    openViewCustomerDialog: customerUIEvents.openViewCustomerDialog,
    openNewCustomerDialog: customerUIEvents.openNewCustomerDialog,
    openEditCustomerDialog: customerUIEvents.openEditCustomerDialog,
    openDeleteCustomerDialog: customerUIEvents.openDeleteCustomerDialog,
  };

  return <CustomerUIContext.Provider value={value}>{children}</CustomerUIContext.Provider>;
}
