import React, { createContext, useContext } from 'react';

const ModuleUIContext = createContext();

export function useModuleUIContext() {
  return useContext(ModuleUIContext);
}

export function ModuleUIProvider({ moduleUIEvents, isForAssignment, children }) {
  const value = {
    openViewModuleDialog: moduleUIEvents.openViewModuleDialog,
    openNewModuleDialog: moduleUIEvents.openNewModuleDialog,
    openEditModuleDialog: moduleUIEvents.openEditModuleDialog,
    openDeleteModuleDialog: moduleUIEvents.openDeleteModuleDialog,
    openManageSchedulesDialog: moduleUIEvents.openManageSchedulesDialog,
    isForAssignment,
  };

  return <ModuleUIContext.Provider value={value}>{children}</ModuleUIContext.Provider>;
}
