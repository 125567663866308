import { sendRequest } from '../../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const unifiedAPIServiceBase =
  process.env.REACT_APP_CADS_AI_API + process.env.REACT_APP_ADMIN_ENDPOINT;

const subCategoryEndpoints = {
  GET_SUB_CATEGORIES_LIST: `${unifiedAPIServiceBase}/getSubCategoriesList`,
  GET_SUB_CATEGORIES_OPTIONS_LIST: `${unifiedAPIServiceBase}/getAssessmentSubCategoryList`, //TODO: rename this endpoint with proper naming
  GET_SUB_CATEGORY_BY_ID: `${unifiedAPIServiceBase}/getSubCategoryById`,
  DELETE_SKILL_FROM_SUB_CATEGORY: `${unifiedAPIServiceBase}/unlinkSkillFromSubCategory`,
  ADD_SUB_CATEGORY: `${unifiedAPIServiceBase}/addSubCategory`,
  EDIT_SUB_CATEGORY: `${unifiedAPIServiceBase}/editSubCategory`,
  DELETE_SUB_CATEGORY: `${unifiedAPIServiceBase}/deleteSubCategory`,
  ADD_SKILLS_TO_SUB_CATEGORY: `${unifiedAPIServiceBase}/addSkillsToSubCategory`,
};

/**
 * @param filter to filter data based on some parameters
 * @param {number} filter.pageNumber page number
 * @param {number} filter.pageSize page size
 * @param {string} filter.searchText to filter sub-categories by search text
 * @param {string} filter.sortBy to sort based on any field
 * @param {string} filter.status to search based on status
 * @param {boolean} filter.isSortOrderDescending true if search direction is descending
 **/
const getSubCategoriesList = (filter) => {
  return sendRequest({
    fullUrl: subCategoryEndpoints.GET_SUB_CATEGORIES_LIST,
    method: httpMethod.GET,
    params: filter,
  });
};

/**
 * This just retrieves list subcategories with minimum details to populate dropdowns
 * */
const getSubcategoriesOptionsList = () => {
  return sendRequest({
    fullUrl: subCategoryEndpoints.GET_SUB_CATEGORIES_OPTIONS_LIST,
    method: httpMethod.GET,
  });
};

/**
 * @param subCategoryId - id of subcategory to be fetched
 * */
const getSubCategoryById = (subCategoryId) => {
  return sendRequest({
    fullUrl: subCategoryEndpoints.GET_SUB_CATEGORY_BY_ID,
    method: httpMethod.GET,
    params: { id: subCategoryId },
  });
};

/**
 * @param subCategoryId - id of subcategory from which skill will be deleted
 * @param skillId - id of skill to be deleted from sub-categories
 * */
const unLinkSkillFromSubcategory = (subCategoryId, skillId) => {
  return sendRequest({
    fullUrl: subCategoryEndpoints.DELETE_SKILL_FROM_SUB_CATEGORY,
    method: httpMethod.DELETE,
    params: { subCategoryId, skillId },
  });
};

/**
 * @Param subCategory - subcategory to be added
 * @Param {string} subCategory.name - name of subcategory
 * @param {string} subCategory.description - description of subcategory
 * */
const addSubCategory = (subCategory) => {
  return sendRequest({
    fullUrl: subCategoryEndpoints.ADD_SUB_CATEGORY,
    method: httpMethod.POST,
    data: subCategory,
  });
};

/**
 * @Param subCategory - subcategory to be edited
 * @param {number} subCategory.id - id of subcategory
 * @Param {string} subCategory.name - name of subcategory
 * @param {string} subCategory.description - description of subcategory
 * */
const editSubCategory = (subCategory) => {
  return sendRequest({
    fullUrl: subCategoryEndpoints.EDIT_SUB_CATEGORY,
    method: httpMethod.PUT,
    data: subCategory,
  });
};

/**
 * @param subCategoryId - id of subcategory to be deleted
 * */
const deleteSubCategory = (subCategoryId) => {
  return sendRequest({
    fullUrl: subCategoryEndpoints.DELETE_SUB_CATEGORY,
    method: httpMethod.DELETE,
    params: { id: subCategoryId },
  });
};

/**
 * @param subCategoryWithSkills - sub-categories with skills
 * @param {number} subCategoryWithSkills.subCategoryId - id of subcategory in which skill will be added
 * @param {Array<{id: number, weight: number}>} subCategoryWithSkills.skills - list of skills with their respective weights
 * */
const addSkillsToSubCategory = (subCategoryWithSkills) => {
  return sendRequest({
    fullUrl: subCategoryEndpoints.ADD_SKILLS_TO_SUB_CATEGORY,
    method: httpMethod.POST,
    data: subCategoryWithSkills,
  });
};

export default {
  getSubCategoriesList,
  getSubcategoriesOptionsList,
  getSubCategoryById,
  unLinkSkillFromSubcategory,
  addSubCategory,
  editSubCategory,
  deleteSubCategory,
  addSkillsToSubCategory,
};
