import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import * as Constants from '@/shared/helpers/constants';
import { shallowEqual, useSelector } from 'react-redux';

export const UsersColumns = [
  {
    id: 'userDetailId',
    disablePadding: true,
    label: 'Select',
  },
  {
    id: 'userFullName',
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'email',
    disablePadding: true,
    label: 'Email',
  },
  /*    {
        id: 'permissionLevel', disablePadding: true, label: 'Permission Level',
    }*/
];

export const CohortColumns = [
  {
    id: 'cohortId',
    disablePadding: true,
    label: 'Select',
  },
  {
    id: 'cohortName',
    disablePadding: true,
    label: 'Cohort Name',
  },
];

export const OrganizationColumns = [
  {
    id: 'organizationId',
    disablePadding: true,
    label: 'Select',
  },
  {
    id: 'organizationName',
    disablePadding: true,
    label: 'Organization Name',
  },
];

const createSortHandler = (property, onRequestSort) => (event) => {
  onRequestSort(event, property);
};

const AssignedTableColumns = ({ order, orderBy, onRequestSort }) => {
  const { activeTab } = useSelector((state) => state.assignedPage, shallowEqual);
  let columns = UsersColumns;
  if (activeTab === Constants.USERS) {
    columns = UsersColumns;
  } else if (activeTab === Constants.COHORT) {
    columns = CohortColumns;
  } else if (activeTab === Constants.ORGANIZATION) {
    columns = OrganizationColumns;
  }

  return (
    <Fragment>
      <TableRow>
        {columns.map((row, idx) => (
          <TableCell
            className="material-table__cell material-table__cell--sort material-table__cell-center"
            key={row.id}
            align={'center'}
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
          >
            {idx === 0 ? (
              <b>{row.label}</b>
            ) : (
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id, onRequestSort)}
                className="material-table__sort-label"
                dir="ltr"
              >
                <b>{row.label}</b>
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </Fragment>
  );
};

AssignedTableColumns.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default AssignedTableColumns;
