import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { useLearningPathUIContext } from './LearningPathUIProvider';
import LearningPathTableWrapper from './table/LearningPathTableWrapper';

export function LearningPathCard() {
  const learningPathUIContext = useLearningPathUIContext();

  return learningPathUIContext.isForAssignment ? (
    <LearningPathTableWrapper />
  ) : (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Learning Path List</h3>
          <h3 className="page-subhead subhead">
            From here you can manage all the learning Path content under control panel.
          </h3>
        </Col>
      </Row>
      <Row>
        <LearningPathTableWrapper />
      </Row>
    </Container>
  );
}
