import React from 'react';
import api from '@/utils/apiClient';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { SelectField } from '@/shared/components/form/Select';
import { useForm } from 'react-hook-form';
import { FormField } from '@/shared/components/form/hook/FormField';
import { Button, ButtonToolbar } from 'reactstrap';
import { emptyStringPattern } from '@/shared/helpers';
import { useSelector, shallowEqual } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { getProgramsList } from '../../../Programs/redux/actions';

const DSCohortAddEditForm = ({ isEdit, modal, id }) => {
  const { data: dsCohorts } = useSelector((state) => state.cadsAIDSCohorts, shallowEqual);
  const { data: programs, filters } = useSelector((state) => state.cadsAIPrograms, shallowEqual);
  const dispatch = useDispatch();
  //states
  const [state, setState] = React.useState({
    loading: false,
    fetchingDSCohort: false,
    newDSCohortAdded: false,
    errorMessage: '',
  });
  const [selectedDSCohort, setSelectedDSCohort] = useState({});
  const [programOptions, setProgramOptions] = useState([]);
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (id) setSelectedDSCohort(dsCohorts?.find((item) => item?.id === id));
  }, [dsCohorts, id]);

  useEffect(() => {
    dispatch(getProgramsList(filters));
  }, [filters]);

  const onCancel = () => {
    modal.hide();
  };

  useEffect(() => {
    if (isEdit && id && !isEmpty(selectedDSCohort)) {
      setDefaultData(+id);
    }
  }, [isEdit, id, selectedDSCohort]);

  useEffect(() => {
    setProgramOptions(programs?.map((org) => ({ value: org.id, label: org.title })) ?? []);
  }, []);

  const setDefaultData = async () => {
    ['title', 'description'].forEach((field) => setValue(field, selectedDSCohort?.[field]));
    setValue(
      'program',
      !selectedDSCohort?.program
        ? null
        : { value: selectedDSCohort?.program?.id, label: selectedDSCohort?.program?.title },
    );
  };

  const onSubmit = async ({ title, description, program }, e) => {
    setState({ ...state, loading: true });
    const reqData = {
      title,
      description,
      programId: program?.value,
    };
    const defaultError = `Error occurred while ${isEdit ? 'Updating' : 'Adding new'} ds cohort`;
    try {
      const { data, isSuccess } = isEdit
        ? await api.cadsAIDSCohort.updateDSCohort({ ...reqData, id })
        : await api.cadsAIDSCohort.addDSCohort(reqData);
      if (isSuccess) {
        setState({ ...state, newDSCohortAdded: true, loading: false });
      } else {
        throw new Error(data?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({ ...state, loading: false, newDSCohortAdded: false, errorMessage: error.message });
    }
  };

  return (
    <div className="pl-4 pr-4">
      {/*Add/Edit DS Cohort Form Status*/}
      <div className="mb-4">
        {state.loading && (
          <div>
            <div className="panel__refresh position-relative" style={{ height: 75, width: 20 }}>
              <LoadingIcon />
              <div style={{ width: 200, padding: '23px 40px' }}>
                {isEdit ? 'Updating' : 'Adding new'} DS Cohort...
              </div>
            </div>
          </div>
        )}
        {(state.newDSCohortAdded || !!state.errorMessage) && (
          <table className="mt-3">
            <tr>
              <td>
                {state.newDSCohortAdded ? (
                  <span className="lnr lnr-thumbs-up modal__title-icon success" />
                ) : (
                  <span className="lnr lnr-cross-circle modal__title-icon error" />
                )}
              </td>
              <td className="pl-2">
                {state.newDSCohortAdded
                  ? `${isEdit ? 'DS Cohort updated' : 'New DS Cohort Added'} Successfully`
                  : state.errorMessage}
              </td>
            </tr>
          </table>
        )}
      </div>
      <form
        className={`form form--horizontal ds-cohort-add-edit`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="form__form-group">
          <span className="form__form-group-label">Title</span>
          <div className="form__form-group-field">
            <FormField
              name="title"
              control={control}
              component="input"
              errors={errors}
              rules={{
                required: 'This is required field',
                pattern: emptyStringPattern,
              }}
              defaultValue=""
              isAboveError={true}
              placeholder="Name of DS Cohort"
            />
          </div>
        </div>
        <div className="form__form-group mt-3">
          <span className="form__form-group-label">Select Program</span>
          <div className="form__form-group-field">
            <FormField
              control={control}
              name="program"
              component={SelectField}
              options={programOptions}
              placeholder="Select Program"
              errors={errors}
              clearable={true}
              isAboveError
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Description</span>
          <div className="form__form-group-field">
            <FormField
              name="description"
              control={control}
              component="textarea"
              defaultValue=""
              placeholder="Write description about DS Cohort"
            />
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar modal__footer">
          <Button disabled={state.loading} color="primary" type="submit">
            Submit
          </Button>
          <Button type="button" className="modal_cancel" onClick={onCancel}>
            Close
          </Button>
        </ButtonToolbar>
      </form>
    </div>
  );
};

export default DSCohortAddEditForm;
