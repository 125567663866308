import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, ButtonToolbar } from 'reactstrap';
import api from '@/utils/apiClient';
import { emptyStringPattern, YesNoOptions } from '@/shared/helpers';
import { FormField } from '@/shared/components/form/hook/FormField';
import { extractFieldData, getStepFields } from '../index';
import { RadioButtonField } from '@/shared/components/form/RadioButton';
import { SelectField } from '@/shared/components/form/Select';

const StepFour = ({ onSubmit, page, data, previousPage, state }) => {
  //states
  const [dataSkillOptions, setDataSkillOptions] = React.useState([]);
  const [smartSkillsRequired, setSmartSkillsRequired] = React.useState('true');
  const [dataOtherSkillOptions, setDataOtherSkillOptions] = React.useState([]);
  const [educationAttainment, setEducationAttainment] = React.useState([]);

  const getEducationAttainmentOptions = async () => {
    const { data: res, isSuccess } = await api.cadsAIJob.getEducationAttainmentOptions();
    if (isSuccess) {
      setEducationAttainment(res.data.map((edu) => ({ value: edu.id, label: edu.title })));
    }
  };

  const getDataSkillOptions = async () => {
    const { data: res, isSuccess } = await api.cadsAILearningPathApi.getCadsAiLearningPathSkills();
    if (isSuccess) {
      setDataSkillOptions(res.data.map((skill) => ({ value: skill.id, label: skill.title })));
    }
  };

  const getOtherDataSkillOptions = async () => {
    const { data: res, isSuccess } = await api.cadsAILearningPathApi.getLearningPathOtherSkills();
    if (isSuccess) {
      setDataOtherSkillOptions(res.data.map((skill) => ({ value: skill.id, label: skill.name })));
    }
  };

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm(extractFieldData(page, data));

  React.useEffect(() => {
    getDataSkillOptions();
    getOtherDataSkillOptions();
    getEducationAttainmentOptions();
  }, []);

  React.useEffect(() => {
    getStepFields(page).forEach((key) => {
      setValue(key, data[key]);
    });
    setSmartSkillsRequired(data?.smartSkillsRequired);
  }, [data]);

  return (
    <div className="modal__body add-edit-learning-path">
      <form
        className={`form form--horizontal`}
        onSubmit={handleSubmit((data) =>
          onSubmit({ ...data, smartSkillsRequired: smartSkillsRequired }),
        )}
      >
        <div className="form__form-group mt-3">
          <span className="form__form-group-label">Highest Education</span>
          <div className="form__form-group-field">
            <FormField
              control={control}
              name="educationAttainment"
              component={SelectField}
              options={educationAttainment}
              placeholder="Select Education"
              rules={{ required: 'This is required field' }}
              errors={errors}
              isAboveError
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">CGPA</span>
          <div className="form__form-group-field">
            <FormField
              name="requiredCgpa"
              control={control}
              errors={errors}
              type="number"
              min={0}
              rules={{
                required: 'This is required field',
                pattern: emptyStringPattern,
              }}
              defaultValue=""
              isAboveError={true}
              placeholder="Enter CGPA"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Is Smart Skills Required</span>
          <div className="form__form-group-field mt-2">
            <RadioButtonField
              radioValue={`${YesNoOptions[0].value}`}
              name={'isSmartSkillsRequired'}
              onChange={setSmartSkillsRequired}
              value={smartSkillsRequired}
              className="colored-click mr-5"
              label={YesNoOptions[0].label}
            />
            <RadioButtonField
              radioValue={`${YesNoOptions[1].value}`}
              name={'isSmartSkillsRequired'}
              onChange={setSmartSkillsRequired}
              value={smartSkillsRequired}
              className="colored-click mr-5"
              label={YesNoOptions[1].label}
            />
          </div>
        </div>
        <div className="form__form-group mt-3">
          <span className="form__form-group-label">Select Skills</span>
          <div className="form__form-group-field">
            <FormField
              control={control}
              name="requiredSkills"
              isMulti
              component={SelectField}
              options={dataSkillOptions}
              placeholder="Select Skills"
              rules={{ required: 'This is required field' }}
              errors={errors}
              isAboveError
            />
          </div>
        </div>
        <div className="form__form-group mt-3">
          <span className="form__form-group-label">Select Other Skills</span>
          <div className="form__form-group-field">
            <FormField
              control={control}
              name="otherSkills"
              isMulti
              component={SelectField}
              options={dataOtherSkillOptions}
              placeholder="Select Other Skills"
              rules={{ required: 'This is required field' }}
              errors={errors}
              isAboveError
            />
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar modal__footer">
          <Button onClick={previousPage} type="button" className="previous">
            Back
          </Button>
          <Button disabled={state?.loading} color="primary" type="submit">
            Submit
          </Button>
        </ButtonToolbar>
      </form>
    </div>
  );
};

export default StepFour;
