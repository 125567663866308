import React, { useState, useEffect } from 'react';
import LoadingIcon from 'mdi-react/LoadingIcon';
import api from '@/utils/apiClient';
import ProgramAddEditForm from './ProgramAddEditForm';
import { useProgramsUIContext } from '@/containers/Data-Star/Programs/components/ProgramsUIProvider';

const ProgramEditDialogBody = ({ modal, isEdit, id }) => {
  const programsUIContext = useProgramsUIContext();

  const initialValues = {
    title: '',
    headline: '',
    description: '',
    start_date: '',
    end_date: '',
    tags: '',
    location: '',
    country_name: '',
    bg_img_url: '',
    logo_img_url: '',
    main_img_url: '',
    cohortList: [],
  };

  const [data, setData] = useState(initialValues);

  const [state, setState] = useState({
    loading: false,
    fetchingProgram: false,
    newProgramAdded: false,
    errorMessage: '',
  });

  useEffect(() => {
    if (isEdit && id) {
      setDefaultData(+id);
    }
  }, [isEdit, id]);

  const setDefaultData = async (programId) => {
    try {
      const { data: res, isSuccess } = await api.programs.getProgram(programId);
      if (isSuccess) {
        setData({
          ...res.data,
          country_name: res.data?.countryName,
          bg_img_url: res.data?.bgImgUrl,
          logo_img_url: res.data?.logoImgUrl,
          main_img_url: res.data?.mainImgUrl,
          start_date: res.data?.startDate?.substr(0, 10) ?? '',
          end_date: res.data?.endDate?.substr(0, 10) ?? '',
          cohortList: res.data?.cohortList.map((cohort) => ({
            value: cohort.id,
            label: cohort.title,
          })), //TODO: remove when api is ready
        });
      } else {
        throw new Error(res?.messages?.[0]);
      }
    } catch ({ message }) {
      console.error('Program edit error : ', message);
      setState({
        ...state,
        errorMessage: 'Error while getting existing program details',
      });
    }
  };

  const constructApiRequestData = ({
    country_name,
    bg_img_url,
    logo_img_url,
    main_img_url,
    start_date,
    end_date,
    cohortList,
    ...data
  }) => ({
    programTypeId: 1, //TODO: refactor static values in a global constant
    organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
    countryName: country_name,
    bgImgUrl: bg_img_url,
    logoImgUrl: logo_img_url,
    mainImgUrl: main_img_url,
    startDate: start_date,
    endDate: end_date,
    ...(!isEdit
      ? { cohortList: cohortList?.map((cohort) => ({ id: cohort.value, title: cohort.label })) }
      : {}),
    ...data,
  });
  const handleAddEditProgram = async (programData) => {
    setState({ ...state, loading: true });
    const newProgramData = constructApiRequestData(programData);
    const defaultError = `Error occurred while ${isEdit ? 'Updating' : 'Adding new'} program`;
    try {
      const { data: res, isSuccess } = isEdit
        ? await api.programs.updateProgram(newProgramData)
        : await api.programs.addProgram(newProgramData);
      if (isSuccess) {
        setState({ ...state, newProgramAdded: true, loading: false });
        if (!isEdit) setData(initialValues);
        programsUIContext.openViewProgramsDialog(res.data.id);
      } else {
        throw new Error(data?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({
        ...state,
        loading: false,
        newProgramAdded: false,
        errorMessage: error.message,
      });
    }
  };

  return (
    <div>
      {/*Add/Edit Program Form Status*/}
      <div className="ml-4">
        {state.loading && (
          <div>
            <div className="panel__refresh position-relative" style={{ height: 75, width: 20 }}>
              <LoadingIcon />
              <div style={{ width: 200, padding: '23px 30px' }}>
                {isEdit ? 'Updating' : 'Adding new'} program...
              </div>
            </div>
          </div>
        )}
        {(state.newProgramAdded || !!state.errorMessage) && (
          <>
            <table className="mt-3">
              <tr>
                <td>
                  {state.newProgramAdded ? (
                    <span className="lnr lnr-thumbs-up modal__title-icon success" />
                  ) : (
                    <span className="lnr lnr-cross-circle modal__title-icon error" />
                  )}
                </td>
                <td className="pl-2">
                  {state.newProgramAdded
                    ? `${isEdit ? 'Program Updated' : 'New Program Added'} successfully`
                    : state.errorMessage}
                </td>
              </tr>
            </table>
          </>
        )}
      </div>

      {/*Add/Edit Program Form*/}
      <ProgramAddEditForm
        onSubmit={handleAddEditProgram}
        data={data}
        modal={modal}
        isEdit={isEdit}
        programId={id ? +id : null}
      />
    </div>
  );
};

export default ProgramEditDialogBody;
