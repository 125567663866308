import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableCell } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import moment from 'moment';

const AppliedUsers = ({ appliedUsers }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{ minWidth: 160 }}>Title</TableCell>
          <TableCell>Description</TableCell>
          <TableCell style={{ minWidth: 70 }}>Applied Date</TableCell>
        </TableRow>
      </TableHead>
      {!appliedUsers?.length && (
        <TableRow>
          <TableCell colSpan={6} align="center">
            <p className="mt-3 mb-3">There are no applied users.</p>
          </TableCell>
        </TableRow>
      )}
      {appliedUsers?.map((a) => (
        <TableRow>
          {/* <TableCell>
            <img className="job-profile-image" alt="Job Image" src={a?.imageUrl} />
          </TableCell> */}
          <TableCell>{a?.jobApplicationStatusTitle ?? '-'}</TableCell>
          <TableCell>{a?.description ?? '-'}</TableCell>
          <TableCell>
            {a?.appliedDate ? moment(a?.appliedDate).format('DD/MM/yyyy') : '-'}
          </TableCell>
        </TableRow>
      ))}
    </Table>
  );
};

export default AppliedUsers;
